/* istanbul ignore file */
import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormRow from 'shared/layout/form/FormRow';
import { NameInputLayoutType } from 'shared/uibuilder/form/input/NameInput';

const NameInputLayout: NameInputLayoutType = ({
  label,
  children,
}: {
  label: string;
  children: React.ReactElement[];
}) => {
  return (
    <>
      {label && <FormLabel sx={{ marginBottom: '8px', display: 'block', fontWeight: '600' }}>{label}:</FormLabel>}
      <FormRow>{children}</FormRow>
    </>
  );
};

export default NameInputLayout;
