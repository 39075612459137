/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import React from 'react';
import { Collapsible, HtmlField } from 'shared/uibuilder/field';
import TimelineRecordHeader from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordHeader';
import FeedbackFooter from 'erp/employee/Timeline/feedback/shared/layout/FeedbackFooter';
import { FEEDBACKS_ABOUT_ME } from 'erp/employee/feedbacks/EmployeeFeedbacksService';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const FeedbackNoteTimelineRecordLayout = ({ ...props }) => {
  const { data: entity } = useShowContext();
  const feedbackText = entity.getValueBySource('feedbackText');

  return (
    <BaseTimelineRecordLayout
      actionName=""
      permissionToCheck={FEEDBACKS_ABOUT_ME}
      timelineType={TimelineType.FEEDBACK}
      buttons={[]}
      fields={
        feedbackText ? (
          <Collapsible>
            <HtmlField source="feedbackText" label="" />
          </Collapsible>
        ) : null
      }
      timelineHeader={(rest: any) => <TimelineRecordHeader {...rest} />}
      timelineActions={() => null}
      timelineFooter={(rest: any) => <FeedbackFooter {...rest} />}
      {...props}
    />
  );
};

export default FeedbackNoteTimelineRecordLayout;
