/* istanbul ignore file */
import React from 'react';
import { LEAVE_TYPES_ALIASES } from 'erp/leave/shared/leaveService';
import { TextField } from 'shared/uibuilder/field';

const SickDaysBalanceField = ({ value }: { value?: any }) => {
  let result = '0';
  const { type, isExcludedFromBalance } = value;

  if (type === LEAVE_TYPES_ALIASES.SICK_DAY && !isExcludedFromBalance) {
    result = '-1';
  }

  return <TextField value={result} />;
};

export default SickDaysBalanceField;
