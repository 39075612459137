/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useOpportunityService from 'crm/opportunity/shared/opportunityService';

export const OPPORTUNITY_CONTEXT = 'Opportunity';

const OpportunityContext = ({
  permissionToCheck,
  children,
}: {
  permissionToCheck: string | string[];
  children: React.ReactNode;
}) => {
  const { getPermissions } = useOpportunityService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={OPPORTUNITY_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default OpportunityContext;
