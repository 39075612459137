/* istanbul ignore file */
import React from 'react';
import DropDownFilter, { DropDownFilterProps } from 'shared/uibuilder/list/filter/DropDownFilter';
import LeaveStatusDropdown from 'erp/leave/shared/input/LeaveStatusDropdown';

const LeaveStatusFilter = (props: Omit<DropDownFilterProps, 'children'>) => {
  return (
    <DropDownFilter {...props}>
      <LeaveStatusDropdown {...props} />
    </DropDownFilter>
  );
};

export default LeaveStatusFilter;
