/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';

type VacancyByGroupDropdownProps = {
  label?: string;
  source: string;
  vacancyGroupId: string | null;
  placeholder?: string;
  disabled?: boolean;
  value?: Nullable<string>;
  className?: string;
  helpText?: string;
};

const buildLabel = ({ specialization, title }: any) => {
  return !title ? specialization : title;
};

const VacancyByGroupDropdown = ({ label, vacancyGroupId, ...props }: VacancyByGroupDropdownProps) => {
  const [vacancies, setVacancies] = useState<any[]>([]);
  const { getLabel } = useInputHelper({ label, ...props });
  const { searchVacanciesByGroup } = useVacancyGroupService();

  useEffect(() => {
    (async () => {
      if (vacancyGroupId) {
        try {
          const { result } = await searchVacanciesByGroup(vacancyGroupId);
          setVacancies(result);
        } catch (e) {
          setVacancies([]);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dropdown
      label={getLabel()}
      options={vacancies.map(vacancy => ({
        value: vacancy.id,
        label: buildLabel(vacancy),
      }))}
      {...props}
    />
  );
};

export default VacancyByGroupDropdown;
