import React from 'react';
import { Swiper, SwiperProps, SwiperRef, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

interface SwiperContainerProps extends SwiperProps, React.RefAttributes<SwiperRef> {
  children: React.ReactElement | React.ReactElement[];
}

const SwiperContainer = ({ children, ...props }: SwiperContainerProps) => {
  return (
    <Swiper
      // install Swiper modules
      {...props}
      modules={[Pagination]}
      allowTouchMove={false}
      pagination={{ clickable: true }}
    >
      {React.Children.map(children, child => {
        return <SwiperSlide>{child}</SwiperSlide>;
      })}
    </Swiper>
  );
};

export default SwiperContainer;
