/* istanbul ignore file */
// libs
import React from 'react';
// components
import BaseInputLayout from 'uibuilder/layout/form/input/BaseInputLayout';
// styles
import './FileUploadLayout.scss';
import { ErrorMessage } from '../../../../../uibuilder/layout/form/input/BaseInputErrors';

type FileUploadLayoutPros = {
  label: string;
  tooltip: string;
  previews: any;
  fileUploder: any;
  error: Nullable<ErrorMessage[]>;
  disabled: boolean;
  helpText: string;
  source: string;
  isVisible: boolean;
  isRequired: boolean;
};

const FileUploadLayout = ({
  label,
  tooltip,
  previews,
  isRequired = false,
  fileUploder,
  error,
  disabled = false,
  helpText,
  source,
  isVisible,
}: FileUploadLayoutPros) => {
  return (
    <BaseInputLayout
      label={label}
      isRequired={isRequired}
      errorMessages={error}
      tooltip={tooltip}
      source={source}
      isVisible={isVisible}
    >
      <div className="upload-block">
        {previews}
        {fileUploder}
      </div>
      <>{!disabled && <span className="help-block">{helpText}</span>}</>
    </BaseInputLayout>
  );
};

export default FileUploadLayout;
