import React from 'react';
import { Button } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import { useListContext } from 'shared/uibuilder/list/ListContext';

interface StaticFiltersLayoutProps {
  children: React.ReactElement;
  actionButtonName?: string;
  buttons?: React.ReactNode;
}

const resolveButtons = (
  apply: () => void,
  loading?: boolean,
  buttons?: React.ReactNode | ((params: any) => React.ReactNode),
) => {
  return typeof buttons === 'function' ? buttons({ apply, disabled: loading }) : buttons;
};

const StaticFiltersLayout = ({ children, actionButtonName = 'Apply', buttons }: StaticFiltersLayoutProps) => {
  const { apply } = useFilterContext();
  const { loading } = useListContext();

  const overrideButtons = !!buttons;

  const defaultButton = (
    <Button color="primary" sx={{ display: 'flex' }} onClick={() => apply()} disabled={loading}>
      {actionButtonName}
    </Button>
  );

  const applyButton = overrideButtons ? resolveButtons(apply, loading, buttons) : defaultButton;

  return (
    <>
      <Box sx={{ maxWidth: { xs: '100%', lg: '50%', xl: '35%' } }}>{children}</Box>

      {applyButton}
    </>
  );
};

export default StaticFiltersLayout;
