/* istanbul ignore file */
import { cleanAttribute, prepareWithTextileSeparator } from 'shared/textile/textileConverterHelper';

const rules = {} as any;

rules.paragraph = {
  filter: 'p',

  replacement(content: any, node: { parentNode: { nodeName: string; firstChild: any; lastChild: any } }) {
    const isTableData = ['TD', 'TH'].includes(node.parentNode.nodeName);
    const isFirstNode = node.parentNode.firstChild === node;
    const isLastNode = node.parentNode.lastChild === node;

    if (!isTableData) {
      return `\n\n${content}\n\n`;
    }

    if ((isFirstNode && isLastNode) || isLastNode) {
      return content;
    }

    return `${content}\n`;
  },
};

rules.rawPreTag = {
  filter(node: { getAttribute: (arg0: string) => any; nodeName: string }) {
    return !node.getAttribute('class') && !node.getAttribute('id') && node.nodeName === 'PRE';
  },

  replacement(content: any) {
    return `<pre>${content}</pre>`;
  },
};

rules.lineBreak = {
  filter: 'br',

  replacement() {
    return '\n';
  },
};

rules.heading = {
  filter: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],

  replacement(content: any, node: { nodeName: string }) {
    const hLevel = Number(node.nodeName.charAt(1));

    return `\nh${hLevel}. ${content}\n\n`;
  },
};

rules.blockquote = {
  filter: 'blockquote',

  replacement(content: string) {
    const result = content.replace(/^\n+|\n+$/g, '').replace(/^/gm, '> ');

    return `\n\n${result}\n\n`;
  },
};

rules.list = {
  filter: ['ul', 'ol'],

  replacement(content: any, node: { parentNode: any }) {
    const parent = node.parentNode;
    if (parent.nodeName === 'LI' && parent.lastElementChild === node) {
      return `\n${content}`;
    }
    if (['TD', 'TH'].includes(parent.nodeName)) {
      return content;
    }
    return `\n\n${content}\n\n`;
  },
};

rules.listItem = {
  filter: 'li',

  replacement(content: string, node: { parentNode: any; nextSibling: any }) {
    const parent = node.parentNode;
    const bulletListMarker = parent.nodeName === 'OL' ? '#' : '*';

    const reNl = new RegExp(`\n\\${bulletListMarker}`, 'gm');

    const result = content
      .replace(/^\n+/, '')
      .replace(/\n+$/, '\n')
      .replace(reNl, `\n${bulletListMarker}${bulletListMarker}`);

    const prefix = `${bulletListMarker} `;

    return prefix + result + (node.nextSibling && !/\n$/.test(result) ? '\n' : '');
  },
};

rules.inlineLink = {
  filter: ['a'],

  replacement(
    content: string | number,
    node: { getAttribute: (arg0: string) => any; firstChild: { nodeName: string } },
  ) {
    const href = node.getAttribute('href');
    let title = cleanAttribute(node.getAttribute('title'));
    if (title) title = ` "${title}"`;

    const delimiter = node.firstChild.nodeName === 'IMG' ? '' : '"';

    return `${delimiter + content + delimiter}:${href}`;
  },
};

rules.emphasis = {
  filter: ['em', 'i'],

  replacement(content: string, node: any) {
    if (!content.trim()) return '';
    return prepareWithTextileSeparator(content, node, '_');
  },
};

rules.strong = {
  filter: ['strong', 'b'],

  replacement(content: string, node: any) {
    if (!content.trim()) return '';
    return prepareWithTextileSeparator(content, node, '*');
  },
};

rules.del = {
  filter: ['s', 'del'],

  replacement(content: string, node: any) {
    if (!content.trim()) return '';
    return prepareWithTextileSeparator(content, node, '-');
  },
};

rules.underline = {
  filter: ['u', 'ins'],

  replacement(content: string, node: any) {
    if (!content.trim()) return '';
    return prepareWithTextileSeparator(content, node, '+');
  },
};

rules.tableRow = {
  filter: 'tr',

  replacement(content: any) {
    return `${content}\n`;
  },
};

rules.tableCell = {
  filter: 'td',

  replacement(content: any, node: { previousSibling: any }) {
    const tablePrefix = node.previousSibling ? '' : '|';

    return `${tablePrefix}${content}|`;
  },
};

rules.tableHeaderCell = {
  filter: 'th',

  replacement(content: any, node: { previousSibling: any }) {
    const tablePrefix = node.previousSibling ? '' : '|';
    const resultPrefix = `${tablePrefix}*`;
    const resultPostfix = '*|';

    return `${resultPrefix}${content}${resultPostfix}`;
  },
};

rules.tableBody = {
  filter: 'tbody',

  replacement(content: any) {
    return content;
  },
};

rules.tableHead = {
  filter: 'thead',

  replacement(content: any) {
    return content;
  },
};

rules.tableCaption = {
  filter: 'caption',

  replacement(content: string) {
    if (!content.trim()) return '';

    return `${content}\n`;
  },
};

export default rules;
