import EditableFieldLayout from 'uibuilder/layout/form/input/EditableFieldLayout';
import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

import { DateInput } from 'shared/uibuilder/form/input';
import { DATE_FORMAT, useDateTimeService } from 'shared/uibuilder/dateService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

// eslint-disable-next-line react/no-unused-prop-types
const ActualEndDateInput = (props: any) => {
  const { getValue, getSource, getIsRequired, getLabel, getOnChangeCallback, getRawOnChangeCallback, getHelpText } =
    useInputHelper(props as any);
  const { getValue: getCurrentEndDate } = useInputHelper({ source: 'endDate' });
  const { formatDateWithTimezone } = useDateTimeService();

  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});

  const { data, setFormData } = useFormContext();

  const onCancel = () => {
    if (setFormData) {
      setFormData({
        ...data,
        isExtensionCanceled: true,
      });
    }
    rawOnChangeCallback({
      [getSource()]: null,
    });
  };

  const fieldComponent = <b>{formatDateWithTimezone(getCurrentEndDate(), DATE_FORMAT.API_ONLY_DATE)}</b>;

  const inputComponent = (
    <DateInput source={getSource()} value={getValue() || getCurrentEndDate()} label={getLabel()} />
  );

  return (
    <EditableFieldLayout
      fieldComponent={fieldComponent}
      editFieldInput={inputComponent}
      isUserCanChange
      isRequired={getIsRequired()}
      onChangeCallback={getOnChangeCallback()}
      onCancel={onCancel}
      helpText={getHelpText()}
      label={getLabel() as string}
      needToClearAfterModeSwitch={false}
      isEditing={!!getValue()}
    />
  );
};

export default ActualEndDateInput;
