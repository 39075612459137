import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { BaseFilterProps, Filter, FilterCondition, useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import { GREATER_OR_EQUAL, LESS_OR_EQUAL, DATE_FORMAT } from 'shared/uibuilder/dateService';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { Placement } from 'shared/uibuilder/SimpleTooltip';
import useMonthPickerFilter from 'shared/uibuilder/list/filter/useMonthPickerFilter';
import Typography from 'uibuilder/Typography';
import { MonthRangeFilterLayoutType } from 'shared/uibuilder/list/filter/layout/ListFilterLayout/filter/MonthRangeFilterLayout';

// TODO move to shared folder when issue with Typography is fixed

export interface MonthRangeFilterProps extends BaseFilterProps {
  dateFormat?: string;
  canBeInFuture?: boolean;
  label?: string;
  popperPlacement?: Placement;
}

const MonthRangeFilter = ({
  source,
  dateFormat = DATE_FORMAT.API_ONLY_MONTH,
  canBeInFuture = false,
  label,
  popperPlacement,
}: MonthRangeFilterProps) => {
  const { MonthRangeFilterLayout } = useUiTheme<MonthRangeFilterLayoutType>();
  const { filterBy, getValue, filters } = useFilterContext();
  const [filterValue, setFilterValue] = useState(filters[source]);
  const { validateAndShowErrorMessages, getErrors, fromApiToFrontFormat, formatDateForAPI, getMaxDate, yearMonth } =
    useMonthPickerFilter(dateFormat, canBeInFuture, source);
  const MAX_DATE = getMaxDate();

  const hasError = !isEmpty([...getErrors(GREATER_OR_EQUAL), ...getErrors(LESS_OR_EQUAL)]);
  const invalidClass = hasError ? 'is-invalid' : '';

  const onChangeCallback = (filterPair: Dictionary<string>) => {
    const newFilterValue = filterPair;
    if (filterPair) {
      const inputName = Object.keys(filterPair)[0] as FilterCondition;
      const textDate = filterPair[inputName];
      const validationResult = validateAndShowErrorMessages(inputName, textDate);
      if (textDate && !validationResult) {
        if (inputName === GREATER_OR_EQUAL) {
          newFilterValue[inputName] = formatDateForAPI(textDate);
        }
        if (inputName === LESS_OR_EQUAL) {
          newFilterValue[inputName] = formatDateForAPI(textDate);
        }
      }
      setFilterValue(prevFilterValue => ({
        ...prevFilterValue,
        ...newFilterValue,
      }));
    }
  };

  useEffect(() => {
    if (filterValue) {
      validateAndShowErrorMessages(GREATER_OR_EQUAL, fromApiToFrontFormat(filterValue[GREATER_OR_EQUAL]));
      validateAndShowErrorMessages(LESS_OR_EQUAL, fromApiToFrontFormat(filterValue[LESS_OR_EQUAL]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterValueWithoutEmpty: Filter = {};

    Object.entries(filterValue || {}).forEach(entry => {
      const [condition, value] = entry;

      if (value) {
        filterValueWithoutEmpty[condition] = value;
      }
    });

    filterBy({
      [source]: filterValueWithoutEmpty,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  const leValue = getValue(source, LESS_OR_EQUAL);
  const geValue = getValue(source, GREATER_OR_EQUAL);

  return (
    <MonthRangeFilterLayout
      label={label}
      leValue={leValue}
      geValue={geValue}
      onChangeCallback={onChangeCallback}
      source={source}
      className={invalidClass}
      popperPlacement={popperPlacement}
    >
      <DateInput
        toggleButton={
          <>
            <i className="fa fa-calendar mr-2" aria-hidden />
            From:
          </>
        }
        label={label}
        source={GREATER_OR_EQUAL}
        onChangeCallback={onChangeCallback}
        value={fromApiToFrontFormat(geValue)}
        dateFormat={dateFormat}
        maxDate={MAX_DATE}
        minDate={null}
        errorMessages={getErrors(GREATER_OR_EQUAL)}
      />
      <Typography variant="body1" sx={{ margin: '8px 16px 10px', alignSelf: 'center' }}>
        —
      </Typography>
      <DateInput
        toggleButton="To: "
        source={LESS_OR_EQUAL}
        onChangeCallback={onChangeCallback}
        value={fromApiToFrontFormat(leValue)}
        maxDate={MAX_DATE}
        label=" "
        dateFormat={dateFormat}
        minDate={geValue && yearMonth(fromApiToFrontFormat(geValue))}
        errorMessages={getErrors(LESS_OR_EQUAL)}
      />
    </MonthRangeFilterLayout>
  );
};

export default MonthRangeFilter;
