import React from 'react';
import { useListContext, ListItem } from 'shared/uibuilder/list/ListContext';
import Row, { BaseRowProps, RowProps } from './Row';
import MuiTableBody from '@mui/material/TableBody';

export interface TableBodyProps extends BaseRowProps {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  tableRowComponent?: React.FC<RowProps>;
}

const TableBody = ({
  children,
  rowProps = {},
  buttons = [],
  rowKeySource = 'id',
  tableRowComponent: RowComponent = Row,
}: TableBodyProps) => {
  const { data: { items = [] } = {} } = useListContext();

  const body = items.map((item: ListItem) => (
    <RowComponent
      key={`row-${item[rowKeySource]}`}
      data={item}
      template={children}
      buttons={buttons}
      rowProps={rowProps}
      rowKeySource={rowKeySource}
    />
  ));

  return <MuiTableBody>{body}</MuiTableBody>;
};

export default TableBody;
