/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import ProjectBreadcrumbs from 'erp/project/shared/ProjectBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useProjectUrl } from 'erp/project/ProjectRouter';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';

const SingleProjectBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getSingleEntityUrl } = useProjectUrl();
  const { id } = useParams<Dictionary<string>>();

  return (
    <ProjectBreadcrumbs>
      <SingleBreadcrumbItem entity={id!} link={getSingleEntityUrl(id)} />
      {children}
    </ProjectBreadcrumbs>
  );
};

export default SingleProjectBreadcrumbs;
