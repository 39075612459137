// @ts-nocheck
import React from 'react';
import { Route } from 'shared/routing';
import FormBuilderPage from 'shared/uibuilder/form/Builder/__integration-tests__/FormBuilderPage';
import { Routes } from 'react-router-dom';

export const FORM_BUILDER_ROUTES = {
  main: '/form-builder',
};

export default () => (
  <Routes>
    <Route path={FORM_BUILDER_ROUTES.main} exact component={FormBuilderPage} />
  </Routes>
);
