/* istanbul ignore file */
import { StatusContext } from 'privileged/externalaccounts/creation/accountsCreationResponseHandler';
import React from 'react';

const useCreateTeamsResponseHandler = () => {
  const handleCreationStatus = (creationStatus: any, statusContext: StatusContext) => {
    if (creationStatus.isCreated) {
      statusContext.successes.push(<li key="success-creation">Successfully created Team.</li>);
    } else {
      statusContext.errors.push(
        <li key="error-creation">Failed to create Team due to: {creationStatus.errors.join(' ')}.</li>,
      );
    }
  };

  const handleFoundStatus = (foundStatus: any, statusContext: StatusContext) => {
    if (foundStatus.isFound) {
      statusContext.successes.push(<li key="success-creation">Successfully found Team.</li>);
    } else {
      statusContext.errors.push(
        <li key="error-creation">Failed to find Team due to: {foundStatus.errors.join(' ')}.</li>,
      );
    }
  };

  const handleProjectUpdateLinksStatus = (projectUpdateStatus: any, statusContext: StatusContext) => {
    if (projectUpdateStatus.isUpdated) {
      statusContext.successes.push(<li key="success-creation">Successfully updated Project link to the Team.</li>);
    } else {
      statusContext.errors.push(
        <li key="error-creation">
          Failed to update Project link to the Team due to: {projectUpdateStatus.errors.join(' ')}.
        </li>,
      );
    }
  };

  const handleTeamsResponse = (state: any, statusContext: StatusContext) => {
    if (state.teamsCreationStatus) {
      handleCreationStatus(state.teamsCreationStatus, statusContext);
    }
    if (state.teamsFoundStatus) {
      handleFoundStatus(state.teamsFoundStatus, statusContext);
    }
    if (state.projectFieldUpdatedStatus) {
      handleProjectUpdateLinksStatus(state.projectFieldUpdatedStatus, statusContext);
    }
  };

  return {
    handleTeamsResponse,
  };
};

export default useCreateTeamsResponseHandler;
