/* istanbul ignore file */
import React, { useState } from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useLeadTemplatedEnvelopeService, {
  SEND_TEMPLATED_ENVELOPE,
  TEMPLATE_TYPES,
} from 'crm/lead/docusign/templatedEnvelopeLeadService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useMessageService, { SuccessMessage } from 'shared/message/messageService';

const SendLeadTemplatedEnvelopeButton = ({ permissionToCheck }: { permissionToCheck?: string }) => {
  const { sendTemplatedEnvelope } = useLeadTemplatedEnvelopeService();
  const [isVisible, setIsVisible] = useState(true);
  const { addMessage } = useMessageService();
  const { data } = useShowContext();
  const clientName = `${data.getValueBySource('firstName')} ${data.getValueBySource('lastName')}`;
  const clientEmail = `${data.getValueBySource('email')}`;
  const initialFormData = {
    templateType: TEMPLATE_TYPES.LEAD_NDA,
    customerSigner: {
      name: clientName,
      email: clientEmail,
    },
  };

  return isVisible ? (
    <ButtonWithConfirmation
      displayMessage="Please review carefully!"
      submitMethod={() => sendTemplatedEnvelope(initialFormData)}
      outline
      permissionToCheck={SEND_TEMPLATED_ENVELOPE}
      modalChildren={
        <span>
          <p>
            Once you click <b>Send</b> button, the NDA document will be sent to the client at <b>{clientEmail}</b> under
            the name <b>{clientName}</b>.
          </p>
          <p>
            If any of this information is incorrect, close this popup and update the details in the Lead form before
            sending.
          </p>
        </span>
      }
      modalConfirmButtonText="Send"
      afterSubmit={{
        execute: () => {
          addMessage(
            new SuccessMessage(
              'You are awesome! NDA has been sent. Information about the status of the document will appear within a few minutes.',
            ),
          );
          setIsVisible(false);
        },
      }}
      closeOnSubmit={false}
    >
      Send NDA
    </ButtonWithConfirmation>
  ) : null;
};

export default SendLeadTemplatedEnvelopeButton;
