/* istanbul ignore file */
import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useFinanceService from 'erp/employee/finance/shared/financeService';
import { useFinanceUrl, useFinanceId } from 'erp/employee/finance/FinanceRouter';
import { ButtonProps } from 'uibuilder/button/Button';

const deleteMessage = 'Do you really want to delete this compensation package?';

const DeleteFinanceButton = (props: ButtonProps) => {
  const financeId = useFinanceId();
  const { deleteById } = useFinanceService();

  const { getListUrl } = useFinanceUrl();

  return (
    <DeleteButton
      {...props}
      deleteMethod={() => deleteById(financeId)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Compensation package has been successfully deleted.',
        errorMessage: `Can't delete compensation package`,
      }}
    />
  );
};

export default DeleteFinanceButton;
