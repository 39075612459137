import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';
import {
  SERVICE_CATALOG_HEALTH_STATUSES,
} from 'erp/serviceCatalog/useServiceCatalogService';
import {
  SERVICE_HEALTH_ISSUES_IMPORTANCE,
  SERVICE_HEALTH_ISSUES_OPTIONS,
} from 'erp/serviceCatalog/health/useServiceHealthService';

type ServiceCatalogStatusBadgeProps = {
  showNormal?: boolean,
  source: string;
};

const ServiceCatalogHealthBadge = ({ showNormal = false, source, ...props }: ServiceCatalogStatusBadgeProps) => {
  const colors: Record<string, BasicColors> = {
    [SERVICE_CATALOG_HEALTH_STATUSES.NORMAL]: 'neutralLight',
    [SERVICE_CATALOG_HEALTH_STATUSES.WARNING]: 'warning',
    [SERVICE_CATALOG_HEALTH_STATUSES.CRITICAL]: 'error',
  };

  const { getValue } = useFieldHelper<any[]>({ source });

  // TODO: Remove try-catch when health deployed on backend
  let issues: {text: string, color: BasicColors}[];
  try {
    issues = getValue().map((issue: any) => ({
      text: SERVICE_HEALTH_ISSUES_OPTIONS[issue.type],
      color: colors[SERVICE_HEALTH_ISSUES_IMPORTANCE[issue.type]],
    }))
  } catch (TypeError) {
    issues = [];
  }

  return <>
    {issues.map((issue) => <CustomBadge text={issue.text} color={issue.color} {...props} />)}
  </>;
};

export default ServiceCatalogHealthBadge;
