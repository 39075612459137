/* istanbul ignore file */
// libs
import React from 'react';
// layout
import Show from 'shared/uibuilder/show/Show';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import { SensitiveShowPageLayout } from 'shared/uibuilder/sensitiveData';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import SingleFinanceBreadcrumbs from 'erp/employee/finance/shared/SingleFinanceBreadcrumbs';
// fields
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import { TextField, DateField, HtmlField, CapitalizedField } from 'shared/uibuilder/field';
import FinanceProbationReductionField from 'erp/employee/finance/shared/fields/FinanceProbationReductionField';
// buttons
import EditFinanceButton from 'erp/employee/finance/shared/buttons/EditFinanceButton';
import DeleteFinanceButton from 'erp/employee/finance/shared/buttons/DeleteFinanceButton';
// services
import { READ } from 'crm/crmService';
import useFinanceService, {
  FINANCE_READ_EMPLOYEE_COMPENSATION_PACKAGE,
} from 'erp/employee/finance/shared/financeService';
import { useFinanceId, useFinanceUrl } from 'erp/employee/finance/FinanceRouter';
import EmployeeOfficeField from 'erp/employee/shared/field/EmployeeOfficeField';
import useFeatureToggle, { Features } from 'featuretoggle';

const ViewFinance = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const isNewPayrollEnabled = isFeatureEnabled(Features.PAYROLL);

  const financeId = useFinanceId();
  const { getById } = useFinanceService();
  const { getListUrl } = useFinanceUrl();
  const twoContractEnabled = useFeatureToggle().isFeatureEnabled(Features.TWO_CONTRACTS);

  return (
    <SensitiveShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<SingleFinanceBreadcrumbs />}
      buttons={[
        <EditFinanceButton
          source="id"
          key="edit-finance"
          icon={<i className="fa fa-edit" />}
          permissionToCheck={isNewPayrollEnabled ? FINANCE_READ_EMPLOYEE_COMPENSATION_PACKAGE : READ}
        />,
        <DeleteFinanceButton
          key="delete-finance"
          permissionToCheck={isNewPayrollEnabled ? FINANCE_READ_EMPLOYEE_COMPENSATION_PACKAGE : READ}
        />,
      ]}
      timerId={getListUrl()}
    >
      <Show getDataMethod={() => getById(financeId)}>
        <ShowSection title="View: Compensation package">
          <SectionRow>
            <CapitalizedField source="type" label="Compensation package type" />
            <DateField source="effectiveDateFrom" label="Effective from" />
            <DateField
              source="effectiveDateTo"
              label="Effective to (including)"
              isVisible={(data: any) => data.getValueBySource('effectiveDateTo')}
            />
            <CapitalizedField source="baseAmountType" label="Base Amount Type" />
            <TextField source="grossAmount" label="Base Amount Gross" />
            <TextField source="currency" label="Currency" />
            <FinanceProbationReductionField
              source="grossProbationAmount"
              probationAmountSource="grossProbationAmount"
              baseAmountSource="grossAmount"
              label="Probation period reduction"
              isVisible={(data: any) => data.getValueBySource('grossProbationAmount')}
            />
            <TextField
              source="grossProbationAmount"
              label="Probation period absolute value Gross"
              isVisible={(data: any) => data.getValueBySource('grossProbationAmount')}
            />
            <DateField source="firstDay" label="First day of base value application" />
            <EmployeeOfficeField source="officeId" label="Office" isVisible={twoContractEnabled} />
          </SectionRow>
          <BigSectionRow>
            <ReplaceUrlWithLink isVisible={data => data.getValueBySource('additionalExpenses')}>
              <HtmlField
                source="additionalExpenses"
                label="Additional expenses compensated"
                isVisible={(data: any) => data.getValueBySource('additionalExpenses')}
              />
            </ReplaceUrlWithLink>
          </BigSectionRow>
          <BigSectionRow>
            <AttachmentsListField
              source="scansIds"
              label=""
              isVisible={data => data.getValueBySource('scansIds')?.length}
            />
          </BigSectionRow>
        </ShowSection>
      </Show>
    </SensitiveShowPageLayout>
  );
};

export default ViewFinance;
