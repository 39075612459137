import React, { useState } from 'react';
import { SxProps } from '@mui/system';
import { ElementDragType, BaseEventPayload } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types.js';
import Box from 'uibuilder/Box';
import RejectBox from '../RejectBox';
import Header from '../Header';
import EntityHeader from '../EntityHeader';
import Column from './Column';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useDragMonitor from 'shared/dnd/useDragMonitor';
import { columnNames } from '../../constants';
import ExpandButton from '../ExpandButton';
import { REJECT_BOX_ID } from '../../useRecruitingBoardService';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import { ShowContextProvider } from 'shared/uibuilder/show/ShowContext';
import ShowData from 'shared/uibuilder/show/ShowData';
import ListFieldLayout from 'uibuilder/layout/field/ListFieldLayout';

const FIRST_COLUMN_WIDTH = 170;
const COLUMN_WIDTH = 200;
export const GAP = 1.5;

interface BoardProps {
  sx: SxProps;
}

const Board = ({ sx = {} }: BoardProps) => {
  const [activeNode, setActiveNode] = useState<any>(null);
  const [expandedSectionIds, setExpandedSectionIds] = useState<string[]>([]);
  const { data: { items = [] } = {} } = useListContext();

  const handleDragStart = (event: BaseEventPayload<ElementDragType>) => {
    setActiveNode(event.source.data);
  };

  const onDragEnd = (event: BaseEventPayload<ElementDragType>) => {
    setActiveNode(null);
  };

  const onSectionToggle = (sectionId: string) => {
    setExpandedSectionIds(prev =>
      prev.includes(sectionId) ? prev.filter(id => id !== sectionId) : [...prev, sectionId],
    );
  };

  useDragMonitor({
    onDrop: onDragEnd,
    onDragStart: handleDragStart,
  });

  return (
    <UiThemeOverrider
      overriddenLayouts={{
        BaseFieldLayout: ListFieldLayout,
      }}
    >
      <Box sx={{ overflowX: 'auto', paddingBottom: GAP, ...sx }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `${FIRST_COLUMN_WIDTH}px repeat(${columnNames.length}, ${COLUMN_WIDTH}px)`,
            gap: GAP,
            paddingBottom: GAP,
            position: 'sticky',
            top: 0,
            zIndex: 101,
            backgroundColor: '#f5f5f5',
            width: 'fit-content'
          }}
        >
          <Header data={items} />
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `${FIRST_COLUMN_WIDTH}px repeat(${columnNames.length}, ${COLUMN_WIDTH}px)`,
            columnGap: GAP,
          }}
        >
          {items.map((entity: any) => (
            <React.Fragment key={entity.id}>
              <ShowContextProvider
                value={{
                  data: new ShowData(entity),
                }}
              >
                <EntityHeader />
              </ShowContextProvider>
              {columnNames.map(columnSettings => (
                <Column
                  key={`${entity.id}-${columnSettings.name}`}
                  entity={entity}
                  expanded={expandedSectionIds.includes(entity.id)}
                  columnSettings={columnSettings}
                  items={entity?.items?.filter((task: any) => task.stage === columnSettings.stage)}
                />
              ))}
              {entity?.items?.length > 5 ? (
                <ExpandButton
                  expanded={expandedSectionIds.includes(entity.id)}
                  onClick={() => onSectionToggle(entity.id)}
                />
              ) : (
                /* Vacancies divider */
                <Box sx={{ height: 10 * GAP, gridColumn: '1 / -1' }} />
              )}
            </React.Fragment>
          ))}
        </Box>

        <RejectBox id={REJECT_BOX_ID} activeNode={activeNode} />
      </Box>
    </UiThemeOverrider>
  );
};

export default Board;
