/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import Checkbox, { CheckboxProps } from 'shared/uibuilder/form/input/Checkbox';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Box from 'uibuilder/Box';
import { getVisibility } from 'shared/uibuilder/helper';

const ListRowSelectCheckbox = ({
  onChangeCallback,
  isVisible,
  source,
  getDataSource,
  ...props
}: CheckboxProps & { width?: string; getDataSource?: string }) => {
  const [checked, setChecked] = useState(false);
  const { data } = useShowContext();

  useEffect(() => {
    if (onChangeCallback) {
      if (getDataSource) {
        onChangeCallback({
          [getDataSource]: data.getValueBySource(getDataSource),
          checked,
        });
      } else {
        onChangeCallback({
          rowData: data,
          checked,
        });
      }
    }
    // Suppressed warnings because we only need to call useEffect callback only after updating state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  if (!getVisibility(data, isVisible as any)) {
    return null;
  }

  return (
    <Box
      sx={{
        '.checkbox-form-group, label': {
          m: 0,
        },
      }}
    >
      <Checkbox
        {...props}
        value={checked}
        onChangeCallback={() => {
          setChecked(prev => !prev);
        }}
        source={source}
      />
    </Box>
  );
};

export default ListRowSelectCheckbox;
