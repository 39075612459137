// @ts-nocheck
export default {
  name: {
    firstName: 'John',
    lastName: 'Black',
    fullName: 'John Black',
  },
  dateOfBirth: '2020-07-29',
  email: 'test@test.com',
  phone: '3752933333333',
  mainPhotoId: null,
  createdAt: '2020-07-20 07:48:03',
  updatedAt: '2020-09-16 15:08:57',
  updatedByName: 'Test Peredriy',
  createdByName: 'Test Peredriy',
  additionalContacts: [
    { type: 'Skype', value: '123' },
    { type: 'LinkedIn', value: '1234' },
    { type: 'Stackoverflow', value: '213' },
    { type: 'Telegram', value: '213' },
    { type: 'Whatsapp', value: '123123asd' },
    { type: 'Github', value: 'tut.by' },
  ],
  skills: ['JavaScript', 'React', 'Cypress'],
};
