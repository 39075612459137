const ATTRIBUTES_FOR_TEXTILE_HANDLING = ['EM', 'I', 'STRONG', 'B', 'S', 'DEL', 'U', 'INS', '#text'];

// this tag is need for correct Redmine displaying
export const textileSeparator = '<notextile></notextile>';

export const cleanAttribute = (attribute?: string) => (attribute ? attribute.replace(/(\n+\s*)+/g, '\n') : '');

export const prepareWithTextileSeparator = (content: string, node: any, additionSymbol: string) => {
  const parent = node.parentNode;
  let result = `${additionSymbol}${content}${additionSymbol}`;
  // for correct displaying on the Redmine we need to add textile separator between *, +,_ , - symbols tree case
  if (ATTRIBUTES_FOR_TEXTILE_HANDLING.includes(parent.nodeName)) {
    result = `${textileSeparator}${result}${textileSeparator}`;
  }
  // for one line we need add separators too
  const children = Array.from(parent.childNodes);
  const currentNodeIndex = children.indexOf(node);
  const previousNode: any = children[currentNodeIndex - 1];
  if (
    previousNode &&
    ATTRIBUTES_FOR_TEXTILE_HANDLING.includes(previousNode.nodeName) &&
    !result.startsWith(`${textileSeparator}`)
  ) {
    result = `${textileSeparator}${result}${textileSeparator}`;
  }
  return result;
};
