import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { getFullName } from './ownerHelper';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';

type OwnerDropdownProps = {
  label?: string;
  source: string;
  sales: any[];
  onChangeCallback?: (values: FormFieldsData) => void;
  placeholder?: string;
  disabled?: boolean;
  value?: Nullable<string>;
  className?: string;
};

const OwnerDropdown = ({ sales, label, ...props }: OwnerDropdownProps) => {
  const { getLabel, getOnChangeCallback } = useInputHelper({ label, ...props });

  return (
    <Dropdown
      label={getLabel()}
      options={sales.map(employee => ({
        value: employee.alias,
        label: employee.nameEn && getFullName(employee.nameEn),
        disabled: !employee.alias,
      }))}
      onChangeCallback={getOnChangeCallback()}
      {...props}
    />
  );
};

export default OwnerDropdown;
