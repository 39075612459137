/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import TextArea from 'shared/uibuilder/form/input/TextArea';
import BigFormRow from 'shared/layout/form/BigFormRow';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import RequiredResourcesListInput from 'crm/opportunity/presale/output/shared/input/RequiredResourcesListInput';
import RequiredResourcesInputTemplate from 'crm/opportunity/presale/output/shared/input/RequiredResourcesInputTemplate';
import FormRow from 'shared/layout/form/FormRow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import PresaleCoordinatorInput from 'crm/opportunity/presale/output/shared/input/PresaleCoordinatorInput';
import { OPPORTUNITY_PROJECT_TYPE } from 'crm/opportunity/presale/output/shared/presaleOutputService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

const PresaleOutputForm = () => {
  return (
    <div>
      <FormSection title="Presale Artifacts">
        <BigFormRow>
          <TextArea source="descriptionText" label="Description" />
        </BigFormRow>
        <MediumFormRow>
          <PresaleCoordinatorInput
            source="relatedPresaleCoordinatorAlias"
            label="Presale Coordinator"
            placeholder="Select..."
          />
        </MediumFormRow>
        <MediumFormRow>
          <EnumDropdown
            options={OPPORTUNITY_PROJECT_TYPE}
            source="projectType"
            label="Project Type"
            placeholder="Select..."
          />
        </MediumFormRow>
        <MediumFormRow>
          <TextInput source="sharepointLink" label="Sharepoint Link" />
        </MediumFormRow>
        <FormRow>
          <DateInput source="expectedProjectStart" label="Expected Start" />
        </FormRow>
      </FormSection>
      <FormSection title="Team structure">
        <BigFormRow>
          <RequiredResourcesListInput
            source="requiredResources"
            addText="Add Member"
            maxFields={30}
            inputTemplate={RequiredResourcesInputTemplate}
          />
        </BigFormRow>
      </FormSection>
    </div>
  );
};

export default PresaleOutputForm;
