import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowVacanciesGroupPageLayout from './ShowVacanciesGroupPageLayout';
import useVacancyGroupService from '../useVacancyGroupService';
import { useVacancyGroupId } from 'erp/recruitment/newVacancy/routing';
import VacancyGroupContent from './vacancyGroup/VacancyGroupContent';
import VacanciesWrapper from './vacancy/VacanciesWrapper';

const ViewVacancyGroup = () => {
  const id = useVacancyGroupId()!;
  const { getVacancyGroupById } = useVacancyGroupService();

  return (
    <Show getDataMethod={() => getVacancyGroupById(id)}>
      <ShowVacanciesGroupPageLayout>
        <VacancyGroupContent />

        <VacanciesWrapper />
      </ShowVacanciesGroupPageLayout>
    </Show>
  );
};

export default ViewVacancyGroup;
