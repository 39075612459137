/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import useResourcePoolService from 'erp/employee/resourcePool/resourcePoolService';
import TextField from 'shared/uibuilder/field/TextField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';

const EmployeeResourcePoolField = (props: FieldProps) => {
  const { findResourcePoolById } = useResourcePoolService();
  const { getValue } = useFieldHelper(props);
  const [displayedValue, setDisplayedValue] = useState(null);

  useEffect(() => {
    (async () => {
      const resourcePool = await findResourcePoolById(getValue());
      setDisplayedValue(resourcePool.name);
    })();
  });

  return <TextField {...props} value={displayedValue} />;
};

export default EmployeeResourcePoolField;
