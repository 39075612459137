/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { OPPORTUNITY_STAGES } from 'crm/opportunity/shared/opportunityService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const OpportunityStageDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => {
  return <EnumDropdown {...props} options={OPPORTUNITY_STAGES} />;
};

export default OpportunityStageDropdown;
