/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { VIEW_PROJECTS_DASHBOARD, PROJECTS_DASHBOARD_PATH } from 'crm/projectsDashboard/useProjectsDashboardService';
import ProjectsDashboardList from 'crm/projectsDashboard/List';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const useProjectsDashboardRoute = () => useRoute({ listRoute: PROJECTS_DASHBOARD_PATH });

export const useProjectsDashboardUrl = () =>
  useEntityUrl({
    baseLink: PROJECTS_DASHBOARD_PATH,
  });

export const useProjectsDashboardId = () => useParams<Dictionary<string>>().id!;

const ProjectsDashboardRouter = () => {
  const { listRoute } = useProjectsDashboardRoute();

  return (
    <ProtectedRouter basePath={PROJECTS_DASHBOARD_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={VIEW_PROJECTS_DASHBOARD}>
              <ProjectsDashboardList />
            </GlobalProtectedRoute>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

ProjectsDashboardRouter.getRouterPath = () => '/projects-dashboard/*';

export default ProjectsDashboardRouter;
