import CreateForm from 'shared/uibuilder/form/CreateForm';
import React from 'react';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import useKPIService from '../useKPIService';
import ServiceKPIStructureForm from './ServiceBudgetStructureForm';

const CreateKPIStructure = ({ onCancel, initialData, afterSubmit }: any) => {
  const { createStructure, getValidationSchema, serviceCatalogId } = useKPIService();

  return (
    <CreateForm
      submitFormFunc={async req => createStructure(serviceCatalogId, req)}
      afterSubmit={afterSubmit}
      getValidationSchemaFunc={getValidationSchema}
      initialData={initialData}
    >
      <CreateTimelineItemLayout onCancel={onCancel}>
        <ServiceKPIStructureForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateKPIStructure;
