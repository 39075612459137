/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import useOpportunityService, {
  CONTRACT,
  SIGNING,
  UPDATE_OPPORTUNITY,
} from 'crm/opportunity/shared/opportunityService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useForm, { DEFAULT_ERROR_MESSAGE, isEmptyFormErrors } from 'shared/uibuilder/form/formHelper';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';
import presaleOutputValidation from 'crm/opportunity/presale/output/form/presaleOutputValidation';
import PresaleOutputInputForm from 'crm/opportunity/shared/OpportunityPipeline/button/MarkStateAsCompletedButton/PresaleOutputInputForm';
import { FormProvider } from 'shared/uibuilder/form/FormContext';

const MarkAsCurrentStateButton = ({ activeStep }: { activeStep: string }) => {
  const id = useOpportunityId();
  const { update } = useOpportunityService() as any;
  const { getLastPresaleOutput, updateOutput } = usePresaleOutputService();
  const { data, setData = () => {} } = useShowContext();
  const { addMessage } = useMessageService();
  const [isConfirmDisabledForOutput, setIsConfirmDisabledForOutput] = useState(false);

  const isPresaleOutputChangeVisible = activeStep === CONTRACT || activeStep === SIGNING;

  const contextValue = useForm({
    submitFormFunc: () => Promise.resolve({}),
    getValidationSchemaFunc: () => Promise.resolve(presaleOutputValidation),
  });

  useEffect(() => {
    (async () => {
      if (isPresaleOutputChangeVisible) {
        const lastOutput = await getLastPresaleOutput(id);
        const isContractRenewal = data.getValueBySource('isContractRenewal');
        const initialOutputData = lastOutput ? { ...lastOutput, isContractRenewal } : lastOutput;
        contextValue.setFormData(initialOutputData);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (isPresaleOutputChangeVisible && contextValue.data?.id) {
      const outputFormData = contextValue.data;
      const outputFormErrors = {
        ...contextValue.validateForm(),
        isValidExpectedProjectStart: !outputFormData.isValidExpectedProjectStart ? ['Please, confirm!'] : [],
        isValidProjectType: !outputFormData.isValidProjectType ? ['Please, confirm!'] : [],
        isValidRequiredResources: !outputFormData.isValidRequiredResources ? ['Please, confirm!'] : [],
      };
      setIsConfirmDisabledForOutput(!isEmptyFormErrors(outputFormErrors));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresaleOutputChangeVisible, contextValue.data]);

  const handleMarkAsCurrentStep = async () => {
    if (contextValue.data?.id) {
      const outputFormData = contextValue.data;
      try {
        await updateOutput(id, outputFormData.id, outputFormData);
      } catch (e) {
        addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
        return;
      }
    }
    try {
      const response = await update(id, { ...data.getData(), stateStep: activeStep });
      setData(response);
    } catch (e) {
      const result = await (e as any).json();
      addMessage(new ErrorMessage(result?.errors?.stateStep || DEFAULT_ERROR_MESSAGE));
    }
  };

  const getModalChildren = () => {
    return isPresaleOutputChangeVisible && contextValue.data?.id ? (
      <FormProvider value={contextValue}>
        <PresaleOutputInputForm />
      </FormProvider>
    ) : (
      <></>
    );
  };

  const getModalAdditionalMessage = () => {
    return isPresaleOutputChangeVisible && contextValue.data?.id
      ? 'Please, verify that Team structure, Project Type and Expected Project start provided in this form are actual. If not, please, change.'
      : '';
  };

  return (
    <ButtonWithConfirmation
      displayMessage={`Do you really want to mark previous state as completed? ${getModalAdditionalMessage()}`}
      submitMethod={handleMarkAsCurrentStep}
      className="step-button"
      permissionToCheck={UPDATE_OPPORTUNITY}
      modalChildren={getModalChildren()}
      modalSize={isPresaleOutputChangeVisible && contextValue.data?.id ? 'lg' : 'sm'}
      confirmBtnProps={{
        disabled: isConfirmDisabledForOutput,
      }}
    >
      Mark as current state
    </ButtonWithConfirmation>
  );
};

export default MarkAsCurrentStateButton;
