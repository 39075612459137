/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import { DateInput, TextArea, TextInput } from 'shared/uibuilder/form/input/index';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import TransferInput from 'financialAnalytic/journalEntry/shared/input/TransferInput';
import TransferListInput from 'financialAnalytic/journalEntry/shared/input/TransferListInput';
import ExchangeRateFormField from 'financialAnalytic/journalEntry/shared/input/ExchangeRateFormField';
import OfficeDropdown from 'financialAnalytic/shared/input/OfficeDropdown';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';
import JournalEntryProjectDropdown from '../shared/input/JournalEntryProjectDropdown';

const JournalEntryForm = ({
  isDisabled,
  isDisabledOffice = false,
}: {
  isDisabled?: boolean;
  isDisabledOffice?: boolean;
}) => {
  return (
    <>
      <FormSection>
        <FormRow>
          <DateInput label="Date" source="asDateOf" disabled={isDisabled} />
          <OfficeDropdown source="attributes.office" label="Office" disabled={isDisabledOffice || isDisabled} />
        </FormRow>
        <BigFormRow>
          <TextArea source="description" disabled={isDisabled} />
        </BigFormRow>
        <TransferListInput
          source="transfers"
          inputTemplate={TransferInput}
          label="Transfers"
          addText="Add transfer"
          disabled={isDisabled}
          inputProps={{ disabled: isDisabled }}
        />
        <ExchangeRateFormField dateSource="asDateOf" />
      </FormSection>
      <FormSection
        title="Attributes"
        isVisible={(data: any) =>
          data.projectIsRequired || data.employeeAliasIsRequired || data.nonCommercialProjectIsRequired
        }
      >
        <FormRow>
          <JournalEntryProjectDropdown disabled={isDisabled} source="project.projectId" />
          <EmployeeInput
            source="attributes.employeeAlias"
            nameSource="attributes.employeeName"
            label="Employee"
            disabled={isDisabled}
            isVisible={(data: any) => data.employeeAliasIsRequired}
          />
          <TextInput
            source="projectName"
            label="Project Name"
            isVisible={(data: any) => data.nonCommercialProjectIsRequired}
          />
        </FormRow>
      </FormSection>
    </>
  );
};

export default JournalEntryForm;
