import React, { useEffect, useState } from 'react';
import { ButtonWithConfirmation } from 'uibuilder/button';
import useAssetService, { ASSET_STATUS, WRITE_OFF_ASSET } from '../../useAssetService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import AccountInput from 'financialAnalytic/ledger/shared/input/AccountInput';
import BudgetCategoryDropdown from 'financialAnalytic/journalEntry/shared/input/BudgetCategoryDropdown';
import { useDateService } from 'shared/uibuilder/dateService';
import { isBudgetCategoryRequired } from 'erp/serviceCatalog/budgets/shared/service/budgetCatagorySuggestionService';

const WriteOffAssetButton = () => {
  const { writeOffAsset, getExpenseAccounts } = useAssetService();
  const { data, setData = () => {} } = useShowContext();
  const status = data.getValueBySource('status');
  const initExpenseAccountId = data.getValueBySource('attribution.expenseAccountId');
  const initBudgetCategory = data.getValueBySource('attribution.budgetCategory');
  const inventoryNumber = data.getValueBySource('inventoryNumber');
  const [expenseAccountId, setExpenseAccountId] = useState<any>(initExpenseAccountId);
  const [budgetCategory, setBudgetCategory] = useState<any>(initBudgetCategory);
  const [currentDate, setCurrentDate] = useState<any>();
  const { getCurrentDateTime, formatDateForAPI } = useDateService();
  const now = formatDateForAPI(getCurrentDateTime());

  useEffect(() => {
    if (!currentDate) {
      setCurrentDate(now);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status !== ASSET_STATUS.ON_BALANCE && status !== ASSET_STATUS.ON_BALANCE_COST_PRORATED) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      afterSubmit={{
        successMessage: 'The Asset has been successfully write off.',
        errorMessage: `Can't write off Asset.`,
        execute: (newData: any) => {
          setData(newData);
        },
      }}
      submitMethod={() => writeOffAsset({ inventoryNumber, expenseAccountId, budgetCategory })}
      permissionToCheck={WRITE_OFF_ASSET}
      modalConfirmButtonText="Yes"
      displayMessage="Do you really want to write off this Asset?"
      variant="outlined"
      modalChildren={
        <>
          <AccountInput
            onChangeCallback={(account: any) => setExpenseAccountId(account.expenseAccountId)}
            value={expenseAccountId}
            source="expenseAccountId"
            label="Expense Account"
            placeholder="Select Expense Account"
            loadDataMethod={getExpenseAccounts}
            cacheKey="expense-accounts"
          />
          <BudgetCategoryDropdown
            disabled={!isBudgetCategoryRequired(expenseAccountId)}
            onChangeCallback={(dataForm: any) => setBudgetCategory(dataForm.budgetCategory)}
            value={budgetCategory}
            source="budgetCategory"
            label="Budget Category"
            dateValue={currentDate}
            accountIdValue={expenseAccountId}
          />
        </>
      }
      confirmBtnProps={{
        disabled: !expenseAccountId || (isBudgetCategoryRequired(budgetCategory) && !budgetCategory),
      }}
    >
      <span>Write Off</span>
    </ButtonWithConfirmation>
  );
};

export default WriteOffAssetButton;
