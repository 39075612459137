/* istanbul ignore file */
import React from 'react';
import LinkButton, { LinkButtonProps } from 'uibuilder/button/LinkButton';
import { useContractsUrl } from 'erp/contracts/ContractsRouter';

const ViewContractButton = (props: Omit<LinkButtonProps, 'children' | 'url'>) => {
  const { getSingleEntityUrl } = useContractsUrl();

  return (
    <LinkButton {...props} url={id => getSingleEntityUrl(id)} key="view-contract">
      View
    </LinkButton>
  );
};

export default ViewContractButton;
