import React from 'react';
import Box from 'uibuilder/Box';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { EnumField, HtmlField } from 'shared/uibuilder/field';
import { ENGLISH_LEVEL_SHORT, POSITION_STATUS_OPTIONS, POSITION_TYPE_OPTIONS } from '../../constants';
import DateField from 'shared/uibuilder/field/DateField';
import { HIRING_MODE } from '../../../hiring/useHiringService';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import TextField from 'shared/uibuilder/field/TextField';
import VacancyProjectsSwiper from './shared/VacancyProjectsSwiper';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import CommaListField from 'shared/uibuilder/field/CommaListFIeld';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import WorkingConditionsSwiper from './shared/WorkingConditionsSwiper';
import Typography from 'uibuilder/Typography';
import Data from 'shared/uibuilder/Data';
import LabelStatus from '../shared/LabelStatus';

import './ViewVacancyContent.scss';

const ViewVacancyContent = () => {
  const { data } = useShowContext();
  const { positions } = data.getData();

  return (
    <>
      <Box
        sx={{
          display: 'flex',

          gap: '20px',
          alignItems: 'center',

          '& > section': {
            flex: '0 0 49%',
            maxWidth: '49%',
            height: '255px',
          },
        }}
      >
        <ShowSection title="Positions">
          <ControlledList data={{ items: positions, totalElements: positions.length, totalPages: 1 }}>
            <DataGridLayout
              sxPaginationWrapper={{ paddingTop: '15px' }}
              isSmallTable
              tableWrapperClassName="vacancy-positions-table-wrapper"
            >
              <EnumField source="type" label="Type" options={POSITION_TYPE_OPTIONS} width="15%" />
              <EnumField source="status" label="Status" options={POSITION_STATUS_OPTIONS} width="15%" />
              <DateField source="forecastDate" label="Forecast Date" />
              <DateField source="closeDate" label="Close Date" />
              <DateField source="startWorkingDate" label="Start Working Date" />
            </DataGridLayout>
          </ControlledList>
        </ShowSection>

        <ShowSection
          title={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Typography variant="h4">
                {data.specialization} {data.competency}
              </Typography>
              <LabelStatus status={data.status} />
            </Box>
          }
        >
          <SectionRow spacing={{}}>
            <EnumField source="hiringMode" label="Hiring Mode" options={HIRING_MODE} />
            <DateField
              isVisible={(showData: Data) => !showData.getValueBySource('isAsap')}
              source="targetHiringDate"
              label="Target Hiring"
            />
            <TextField
              isVisible={(showData: Data) => showData.getValueBySource('isAsap')}
              value="Asap"
              label="Target Hiring"
            />
            <DateField source="expirationDate" label="Expiration" />
            <EnumField source="mandatoryRequirements.englishLevel" label="English" options={ENGLISH_LEVEL_SHORT} />
          </SectionRow>

          <SectionRow spacing={{}}>
            <TextField source="compensation" label="Compensation" />
            <EmployeeLinkFieldWithNameLoading label="DRI" source="dri" />
            <EmployeeLinkFieldWithNameLoading label="Coordinator" source="coordinator" />
          </SectionRow>
        </ShowSection>
      </Box>

      <Box
        sx={{
          display: 'flex',

          gap: '20px',
          alignItems: 'center',

          '& > section': {
            flex: '0 0 49%',
            maxWidth: '49%',
            height: '270px',
          },
        }}
      >
        <ShowSection title="Mandatory Requirements">
          <Box
            sx={{
              maxHeight: '120px',
              overflow: 'auto',
              mb: '10px',
            }}
          >
            <HtmlField source="mandatoryRequirements.description" label="" />
          </Box>

          <MediumFormRow
            sx={{
              '> .MuiGrid-item': {
                pl: 0,
              },
            }}
          >
            <CommaListField label="Skills" source="mandatoryRequirements.skills" />
            <TextField label="Min Experience (years)" source="mandatoryRequirements.requiredExperienceInYears" />
          </MediumFormRow>
        </ShowSection>

        <ShowSection title="Working Conditions">
          <WorkingConditionsSwiper />
        </ShowSection>
      </Box>

      <Box
        sx={{
          display: 'flex',

          flexWrap: 'wrap',
          gap: '0 20px',
          alignItems: 'center',

          '& > section': {
            flex: '0 0 49%',
            maxWidth: '49%',
            height: '365px',
          },
        }}
      >
        <ShowSection title="Responsibilities">
          <Box
            sx={{
              maxHeight: '265px',
              overflow: 'auto',
            }}
          >
            <HtmlField source="responsibilities" label="" />
          </Box>
        </ShowSection>

        <ShowSection isVisible={showData => showData.getValueBySource('projects')?.length > 0}>
          <VacancyProjectsSwiper />
        </ShowSection>

        <ShowSection
          title="Optional Requirements"
          isVisible={showData => showData.getValueBySource('optionalRequirements')}
        >
          <Box
            sx={{
              maxHeight: '210px',
              overflow: 'auto',
              mb: '10px',
            }}
          >
            <HtmlField source="optionalRequirements.description" label="" />
          </Box>

          <BigSectionRow spacing={{}}>
            <CommaListField label="Skills" source="optionalRequirements.skills" />
          </BigSectionRow>
        </ShowSection>

        <ShowSection title="Notes" isVisible={showData => showData.getValueBySource('notes')}>
          <Box
            sx={{
              maxHeight: '210px',
              overflow: 'auto',
              mb: '10px',
            }}
          >
            <HtmlField source="notes.description" label="" />
          </Box>

          <AttachmentsListField source="attachmentIds" label="Attachments" />
        </ShowSection>
      </Box>
    </>
  );
};

export default ViewVacancyContent;
