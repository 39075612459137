/* istanbul ignore file */
import React from 'react';
import { ARTIFACT_ID } from 'artifact/const';
import ArtifactUploaderInput, { ArtifactUploaderInputProps } from 'artifact/shared/input/ArtifactUploaderInput';

const CandidateResumeUploaderInput = (props: ArtifactUploaderInputProps) => {
  return <ArtifactUploaderInput {...props} artifactTypeId={ARTIFACT_ID.CANDIDATE_RESUME} />;
};

export default CandidateResumeUploaderInput;
