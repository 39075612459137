import React from 'react';
import { Dropdown, TextInput } from 'shared/uibuilder/form/input';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import ZoneDateTimeInput, { ZonedApiDateFormat } from 'shared/uibuilder/form/input/ZoneDateTimeInput';
import { EmployeeInput } from 'erp/employee';
import CandidateResumeUploaderInput from 'artifact/shared/input/CandidateResumeUploaderInput';
import FormSection from 'shared/layout/form/FormSection';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import BigFormRow from 'shared/layout/form/BigFormRow';
import MediumFormRow from 'shared/layout/form/MediumFormRow';

interface AdditionalActivitiesFormProps {
  handleCancelClick: () => void;
}

// TODO: change Select to ui kit select
const AdditionalActivitiesForm = ({ handleCancelClick }: AdditionalActivitiesFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact, onChangeCallback } = useFormContext();

  const onActivityTypeChange = ({ activityType }: FormFieldsData) => {
    if (onChangeCallback) {
      onChangeCallback({
        activityType,
        isInterviewRequired: activityType === 'additionalInterview',
        isReferenceCheckRequired: activityType === 'referenceCheck',
        isTestTaskRequired: activityType === 'testTask',
        isAdditionalMaterialRequired: activityType === 'additionalMaterials',
      });
    }
  };

  return (
    <>
      <FormSection>
        <MediumFormRow>
          <Dropdown
            source="activityType"
            label="Activity Type"
            options={[
              { value: 'additionalInterview', label: 'Additional Interview' },
              { value: 'referenceCheck', label: 'Reference Check' },
              { value: 'additionalMaterials', label: 'Additional Materials' },
              { value: 'testTask', label: 'Test Task' },
            ]}
            isRequired
            onChangeCallback={onActivityTypeChange}
          />
        </MediumFormRow>

        <MediumFormRow>
          <ClearAfterUnmountWrapper isVisible={formData => formData.activityType === 'additionalInterview'}>
            <ZoneDateTimeInput
              label="Interview Date & Time"
              source="interviewDateTime"
              isRequired
              apiDateFormat={ZonedApiDateFormat.ISO_8601_DATETIME_WITH_OFFSET}
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={formData => formData.activityType === 'additionalInterview'}>
            <Dropdown
              source="interviewType"
              label="Interview Type"
              options={[
                { value: 'ROLE_RELATED', label: 'Role-related' },
                { value: 'GENERAL', label: 'General' },
              ]}
              isRequired
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={formData => formData.activityType === 'additionalInterview'}>
            <EmployeeInput label="Interviewer" source="interviewerAlias" isRequired />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={formData => formData.activityType === 'additionalInterview'}>
            <Dropdown
              label="Interview Method"
              source="interviewMethod"
              options={[
                { value: 'online', label: 'Online' },
                { value: 'offline', label: 'Offline' },
              ]}
              isRequired
            />
          </ClearAfterUnmountWrapper>
        </MediumFormRow>

        <BigFormRow>
          <ClearAfterUnmountWrapper isVisible={formData => formData.activityType === 'additionalInterview'}>
            <CandidateResumeUploaderInput label="Candidate's CV" source="resumeId" maxElements={1} isRequired />
          </ClearAfterUnmountWrapper>
        </BigFormRow>

        <BigFormRow>
          <ClearAfterUnmountWrapper isVisible={formData => formData.activityType === 'referenceCheck'}>
            <TextInput
              label="Reference Details"
              source="referenceCheckDetails"
              isRequired
              placeholder="Add reference details"
            />
          </ClearAfterUnmountWrapper>
        </BigFormRow>

        <BigFormRow>
          <ClearAfterUnmountWrapper isVisible={formData => formData.activityType === 'additionalMaterials'}>
            <TextInput
              label="Material Details"
              source="additionalMaterialDetails"
              isRequired
              placeholder="Add details about materials"
            />
          </ClearAfterUnmountWrapper>
        </BigFormRow>

        <BigFormRow>
          <ClearAfterUnmountWrapper isVisible={formData => formData.activityType === 'testTask'}>
            <TextInput
              label="Test Task Details"
              source="testTaskDetails"
              isRequired
              placeholder="Add details about the test task"
            />
          </ClearAfterUnmountWrapper>
        </BigFormRow>
      </FormSection>

      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default AdditionalActivitiesForm;
