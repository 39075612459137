/* istanbul ignore file */
import React from 'react';
import ProfileMenuItem from 'uibuilder/menuitem/ProfileMenuItem';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import SidebarMenuSubItem from 'shared/uibuilder/menu/SidebarMenuSubItem';
import TimelineMenuItem from 'uibuilder/menuitem/TimelineMenuItem';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { SummaryMenuItem } from 'uibuilder/menuitem';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import useAuthorization from 'shared/authorization/authorizationService';
import {
  ASSIGN_MENTOR,
  READ_CONTRACTS_LIST,
  READ_EDUCATIONS_LIST,
  READ_EMPLOYEE_COMMUNICATIONS,
  READ_EMPLOYEE_COMPENSATION_PACKAGES,
  READ_EMPLOYEE_KNOWLEDGE_PACKS,
  READ_EMPLOYEE_TIMELINE,
  READ_EMPLOYEE_ACCOUNTS_AZURE_ID,
  READ_EMPLOYEE_IDENTITY_DOCUMENTS,
  READ_EMPLOYEE_CONTACT_INFORMATION,
  READ_EMPLOYEE_FAMILY_INFORMATION,
  READ_EMPLOYEE_MILITARY_INFO,
  READ_EMPLOYEE_MAIN_HR_INFORMATION,
  READ_EMPLOYEE_ACCOUNTS_1C_ID,
  READ_EMPLOYEE_MAIN_PERSONAL_INFO,
  READ_EMPLOYEE_WORKING_STATUS,
  UPDATE_EMPLOYEE_ACCOUNTS_1C_ID,
  UPDATE_EMPLOYEE_ACCOUNTS_AZURE_ID,
  UPDATE_EMPLOYEE_CONTACT_INFORMATION,
  UPDATE_EMPLOYEE_FAMILY_INFORMATION,
  UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS,
  UPDATE_EMPLOYEE_MAIN_HR_INFORMATION,
  UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO,
  UPDATE_EMPLOYEE_MILITARY_INFO,
  UPDATE_EMPLOYEE_WORKING_STATUS,
  READ_EMPLOYEE_QUALIFICATIONS,
  READ_LIST_EMPLOYEE_SALARY,
  READ_LIST_EMPLOYEE_OFFER,
  READ_LIST_EMPLOYEE_PERFORMANCE,
} from 'erp/employee/employeeService';
import { EMPLOYEE_CONTEXT } from 'erp/employee/EmployeeContext';
import { READ_LEAVES_BALANCES } from 'erp/employee/leavebalance/shared/balancesService';
import { useLeavesBalancesUrl } from 'erp/employee/leavebalance/LeaveBalancesRouter';
import { READ_ACCESS_LOG } from 'erp/employee/AccessLog/accessLogService';
import useFeatureToggle, { Features } from 'featuretoggle';
import { READ_EMPLOYEE_SKILLS } from 'erp/employee/skills/EmployeeSkillsService';
import { FEEDBACKS_ABOUT_ME } from 'erp/employee/feedbacks/EmployeeFeedbacksService';
import { READ_EMPLOYEE_ASSIGNMENTS } from 'erp/assignment/shared/assignmentService';
import { READ_BACKGROUND_CHECK_LIST } from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';
import { READ_PERFORMANCE_REPORT } from 'erp/employee/performanceReport/usePerformanceReportService';
import { READ_EMPLOYEE_COMPANY_ROLES } from '../companyRoles/shared/companyRolesService';

const EmployeeMenu = ({ isEditForm = false }) => {
  const {
    getSingleEntityUrl,
    getMentorsUrl,
    getAccessLogUrl,
    getSummaryUrl,
    getTimelineUrl,
    getEmployeeAssignmentsUrl,
    getEmployeeBackgroundChecksUrl,
    getEmployeePerformanceReportUrl,
  } = useEmployeeUrl();
  const employeeUrl = getSingleEntityUrl();
  const { getListUrl } = useLeavesBalancesUrl();

  const { isGranted: globalIsGranted } = useAuthorization();
  const isGranted = (permission: string | string[]) => globalIsGranted(permission, EMPLOYEE_CONTEXT);

  const { isFeatureEnabled } = useFeatureToggle();

  return (
    <>
      <SidebarStickyMenu>
        <SummaryMenuItem linkUrl={getSummaryUrl()} exact />
        <TimelineMenuItem exact linkUrl={getTimelineUrl()} isVisible={isGranted(READ_EMPLOYEE_TIMELINE)} />
        <ProfileMenuItem linkUrl={`${employeeUrl}/profile${isEditForm ? '/edit' : ''}`} exact>
          <SidebarMenuSubItem
            name="Main Information"
            isVisible={
              isEditForm
                ? isGranted(UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO) || isGranted(UPDATE_EMPLOYEE_WORKING_STATUS)
                : isGranted(READ_EMPLOYEE_MAIN_PERSONAL_INFO) || isGranted(READ_EMPLOYEE_WORKING_STATUS)
            }
          />
          <SidebarMenuSubItem
            name="Identity Documents"
            isVisible={
              isEditForm ? isGranted(UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS) : isGranted(READ_EMPLOYEE_IDENTITY_DOCUMENTS)
            }
          />
          <SidebarMenuSubItem
            name="Contact Information"
            isVisible={
              isEditForm ? isGranted(UPDATE_EMPLOYEE_CONTACT_INFORMATION) : isGranted(READ_EMPLOYEE_CONTACT_INFORMATION)
            }
          />
          <SidebarMenuSubItem
            name="Family"
            isVisible={
              isEditForm ? isGranted(UPDATE_EMPLOYEE_FAMILY_INFORMATION) : isGranted(READ_EMPLOYEE_FAMILY_INFORMATION)
            }
          />
          <SidebarMenuSubItem
            name="Military Information"
            isVisible={isEditForm ? isGranted(UPDATE_EMPLOYEE_MILITARY_INFO) : isGranted(READ_EMPLOYEE_MILITARY_INFO)}
          />
          <SidebarMenuSubItem
            name="HR Information"
            isVisible={
              isEditForm ? isGranted(UPDATE_EMPLOYEE_MAIN_HR_INFORMATION) : isGranted(READ_EMPLOYEE_MAIN_HR_INFORMATION)
            }
          />
          <SidebarMenuSubItem
            name="Accounts"
            isVisible={
              isEditForm
                ? isGranted(UPDATE_EMPLOYEE_ACCOUNTS_AZURE_ID) || isGranted(UPDATE_EMPLOYEE_ACCOUNTS_1C_ID)
                : isGranted(READ_EMPLOYEE_ACCOUNTS_AZURE_ID) || isGranted(READ_EMPLOYEE_ACCOUNTS_1C_ID)
            }
          />
        </ProfileMenuItem>
        <SidebarMenuItem
          name="Feedback"
          icon="comments"
          linkUrl={`${employeeUrl}/feedbacks`}
          isVisible={isFeatureEnabled(Features.INSTANT_FEEDBACK) && isGranted(FEEDBACKS_ABOUT_ME)}
        />
        <SidebarMenuItem
          linkUrl={`${employeeUrl}/education`}
          name="Education"
          icon="university"
          isVisible={isGranted(READ_EDUCATIONS_LIST)}
        />
        <SidebarMenuItem
          linkUrl={`${employeeUrl}/finance`}
          name="Finance"
          icon="credit-card"
          isVisible={isGranted(READ_EMPLOYEE_COMPENSATION_PACKAGES)}
        />
        <SidebarMenuItem
          linkUrl={`${employeeUrl}/salaries`}
          name="Salaries"
          icon="money"
          isVisible={isGranted(READ_LIST_EMPLOYEE_SALARY)}
        />
        <SidebarMenuItem
          linkUrl={`${employeeUrl}/offers`}
          name="Offers"
          icon="briefcase"
          isVisible={isFeatureEnabled(Features.EMPLOYEE_OFFERS) && isGranted(READ_LIST_EMPLOYEE_OFFER)}
        />
        <SidebarMenuItem
          linkUrl={`${employeeUrl}/qualifications`}
          name="Qualification"
          icon="certificate"
          isVisible={isFeatureEnabled(Features.EMPLOYEE_QUALIFICATION) && isGranted(READ_EMPLOYEE_QUALIFICATIONS)}
        />
        <SidebarMenuItem
          linkUrl={`${employeeUrl}/old-qualifications`}
          name="Old Qualification"
          icon="hourglass-start"
          isVisible={isFeatureEnabled(Features.EMPLOYEE_QUALIFICATION) && isGranted(READ_EMPLOYEE_QUALIFICATIONS)}
        />
        <SidebarMenuItem
          name="Skills"
          icon="gears"
          linkUrl={`${employeeUrl}/skills`}
          isVisible={isFeatureEnabled(Features.EMPLOYEE_SKILLS) && isGranted(READ_EMPLOYEE_SKILLS)}
        />
        <SidebarMenuItem
          linkUrl={`${employeeUrl}/assessments`}
          name="Assessment"
          icon="book"
          isVisible={isGranted(READ_EMPLOYEE_KNOWLEDGE_PACKS) || isGranted(READ_EMPLOYEE_COMMUNICATIONS)}
        />
        <SidebarMenuItem
          linkUrl={`${employeeUrl}/contracts`}
          name="Contracts"
          icon="file-o"
          isVisible={isGranted(READ_CONTRACTS_LIST)}
        />
        <SidebarMenuItem name="Leaves" icon="ship" linkUrl={getListUrl()} isVisible={isGranted(READ_LEAVES_BALANCES)} />

        <SidebarMenuItem
          name="Employee's mentors"
          icon="university"
          linkUrl={getMentorsUrl()}
          isVisible={isGranted(ASSIGN_MENTOR)}
        />

        <SidebarMenuItem
          name="Access Log"
          icon="eye"
          linkUrl={getAccessLogUrl()}
          isVisible={isGranted(READ_ACCESS_LOG)}
        />

        <SidebarMenuItem
          name="Assignments"
          icon="file-text"
          linkUrl={getEmployeeAssignmentsUrl()}
          isVisible={isGranted(READ_EMPLOYEE_ASSIGNMENTS)}
        />

        <SidebarMenuItem
          name="Background Checks"
          icon="check-square-o"
          linkUrl={getEmployeeBackgroundChecksUrl()}
          isVisible={isGranted(READ_BACKGROUND_CHECK_LIST)}
        />

        <SidebarMenuItem
          name="Performance Report"
          icon="tasks"
          linkUrl={getEmployeePerformanceReportUrl()}
          isVisible={isFeatureEnabled(Features.PERFORMANCE_REPORT) && isGranted(READ_PERFORMANCE_REPORT)}
        />

        <SidebarMenuItem
          linkUrl={`${employeeUrl}/performanceData`}
          name="Performance Results"
          icon="line-chart"
          isVisible={isGranted(READ_LIST_EMPLOYEE_PERFORMANCE)}
        />

        <SidebarMenuItem
          linkUrl={`${employeeUrl}/roles`}
          name="Company roles"
          icon="shield"
          isVisible={isGranted(READ_EMPLOYEE_COMPANY_ROLES)}
        />
      </SidebarStickyMenu>
    </>
  );
};

export default EmployeeMenu;
