/* istanbul ignore file */
import React from 'react';
import AccountsBreadcrumbs from 'crm/account/shared/AccountsBreadcrumbs';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { SingleBreadcrumbItem, UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAccountUrl } from 'crm/account/index';

const UpdateAccountBreadcrumbs = () => {
  const { data = {} } = useFormContext();
  const { getSingleEntityUrl } = useAccountUrl();

  return (
    <AccountsBreadcrumbs>
      <SingleBreadcrumbItem entity={data.accountName || data.accountId} link={getSingleEntityUrl(data.id)} />
      <UpdateBreadcrumbItem />
    </AccountsBreadcrumbs>
  );
};

export default UpdateAccountBreadcrumbs;
