/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import WysiwygInputTestingRouter, {
  WYSIWYG_ROUTES,
} from 'shared/uibuilder/form/input/__integration-tests__/WysiwygInputTestingRouter';
import useRoute from 'shared/routing/useRoute';
import IntegrationCrudRouter from 'integration-tests/IntegrationCrudRouter';
import FormBuilderTestingRouter, {
  FORM_BUILDER_ROUTES,
} from 'shared/uibuilder/form/Builder/__integration-tests__/FormBuilderTestingRouter';
import OneStopTestingRouter, { ONE_STOP_ROUTES } from 'oneStop/__integration-tests__/OneStopTestingRouter';

export const useIntegrationTestRoutes = () => useRoute({ listRoute: '/crud' });

const IntegrationTestsRouter = () => {
  const { listRoute } = useIntegrationTestRoutes();

  return (
    <Routes>
      <Route path={WYSIWYG_ROUTES.main} element={<WysiwygInputTestingRouter />} />
      <Route path={listRoute} element={<IntegrationCrudRouter />} />
      <Route path={FORM_BUILDER_ROUTES.main} element={<FormBuilderTestingRouter />} />
      <Route path={ONE_STOP_ROUTES.listRoute} element={<OneStopTestingRouter />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default IntegrationTestsRouter;
