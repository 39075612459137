/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import useEntityUrl from 'shared/routing/useEntityUrl';
import {
  CREATE_SERVICE,
  READ_SERVICE,
  READ_SERVICES_LIST,
  SERVICE_PATH,
  UPDATE_SERVICE,
} from 'erp/service/shared/serviceService';
import useRoute from 'shared/routing/useRoute';
import Page404 from 'shared/uibuilder/Page404';
import ServiceList from 'erp/service/List';
import ViewService from 'erp/service/Show';
import ServiceContext from 'erp/service/ServiceContext';
import CreateService from 'erp/service/createupdate/Create';
import UpdateService from 'erp/service/createupdate/Update';

export const useServiceRoute = () => {
  return useRoute({ listRoute: SERVICE_PATH });
};

export const useServiceUrl = () => {
  return useEntityUrl({
    baseLink: SERVICE_PATH,
  });
};

export const useServiceId = () => {
  const { id } = useParams<Dictionary<string>>();

  return id!;
};

const ServiceRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, updateRoute } = useServiceRoute();

  return (
    <ProtectedRouter basePath={SERVICE_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_SERVICES_LIST}>
              <ServiceList />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_SERVICE}>
              <CreateService />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <ServiceContext permissionToCheck={READ_SERVICE}>
              <ViewService />
            </ServiceContext>
          }
        />

        <Route
          path={updateRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={UPDATE_SERVICE}>
              <UpdateService />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

ServiceRouter.getRouterPath = () => `${SERVICE_PATH}/*`;

export default ServiceRouter;
