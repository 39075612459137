/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import CamundaMetricsList from 'camunda/metrics/list';
import { ProtectedRouter } from 'routing';
import { Route, Router } from 'shared/routing';
import { READ_CAMUNDA_METRICS_REPORT } from 'camunda/metrics/shared/camundaMetricsService';

export const CAMUNDA_METRICS_PATH = '/processes/metrics';

export const useCamundaRoute = () => {
  return useRoute({ listRoute: CAMUNDA_METRICS_PATH });
};

export const useCamundaUrl = () => {
  return useEntityUrl({
    baseLink: CAMUNDA_METRICS_PATH,
  });
};

const CamundaMetricsRouter: Router = () => {
  const { listRoute } = useCamundaRoute();

  return (
    <ProtectedRouter basePath={CAMUNDA_METRICS_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_CAMUNDA_METRICS_REPORT}>
              <CamundaMetricsList />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

CamundaMetricsRouter.getRouterPath = () => '/metrics/*';

export default CamundaMetricsRouter;
