import expenseValidation from 'financialAnalytic/expenses/createupdate/expenseValidation';
import { useFinanceApi } from 'api';
import useCrudService from 'shared/crud';
import { OFFICE } from 'financialAnalytic/shared/field/OfficeField';
import { useNavigate } from 'react-router-dom';
import useAuthorization from 'shared/authorization/authorizationService';
import { cloneDeep } from 'lodash';
import { ResourceData } from 'shared/crud/baseCrudService';
import { CONTENT_TYPE } from '../../shared/api/const';

export const READ_EXPENSE_LIST = 'COMPANY_GET_ALL_EMPLOYEE_EXPENSES';
export const READ_EXPENSE = 'COMPANY_GET_EMPLOYEE_EXPENSE';
export const CREATE_EXPENSE = 'COMPANY_CREATE_EMPLOYEE_EXPENSE';
export const MARK_AS_PAID_EXPENSE = 'COMPANY_MARK_EMPLOYEE_EXPENSES_AS_PAID';
export const FIND_EMPLOYEE_EXPENSES_BY_LEAVE_IDS = 'COMPANY_FIND_EMPLOYEE_EXPENSES_BY_LEAVE_IDS';
export const COMPUTE_PERFORMANCE_BASED_BONUS = 'COMPANY_COMPUTE_PERFORMANCE_BASED_BONUS';
export const MARK_PERFORMANCE_BASED_BONUS_AS_PAID = 'COMPANY_MARK_PERFORMANCE_BASED_BONUS_AS_PAID';
export const GET_PERFORMANCE_BASED_BONUS = 'COMPANY_GET_PERFORMANCE_BASED_BONUS';
export const CREATE_PERFORMANCE_BASED_BONUS = 'COMPANY_CREATE_PERFORMANCE_BASED_BONUS';
export const RUN_EXPENSES_MIGRATION = 'COMPANY_CREATE_PERFORMANCE_BASED_BONUS';
export const REJECT_EXPENSE = 'COMPANY_REJECT_EMPLOYEE_EXPENSE';
export const EXPENSE_PATH = '/employee-expenses';
export const RESOURCE_URL = '/employee-expenses';

export const EXPENSE_STATUSES = {
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  REGISTERED: 'REGISTERED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
};

export const EXPENSE_STATUSES_OPTIONS = {
  [EXPENSE_STATUSES.REJECTED]: 'Rejected',
  [EXPENSE_STATUSES.PENDING]: 'Pending',
  [EXPENSE_STATUSES.PAID]: 'Paid',
  [EXPENSE_STATUSES.REGISTERED]: 'Registered',
  [EXPENSE_STATUSES.CANCELLED]: 'Cancelled',
  [EXPENSE_STATUSES.COMPLETED]: 'Completed',
};

export const EXPENSE_TYPES = {
  LEAVE_COMPENSATION: 'LEAVE_COMPENSATION',
  ONE_TIME_PAYMENT: 'ONE_TIME_PAYMENT',
  BENEFITS_EXPENSE: 'BENEFITS_EXPENSE',
  SOCIAL_LIABILITY: 'SOCIAL_LIABILITY',
  OTHER_LIABILITY: 'OTHER_LIABILITY',
  TAX: 'TAX',
  DEDUCTION: 'DEDUCTION',
  PERFORMANCE_BASED_BONUS: 'PERFORMANCE_BASED_BONUS',
};

export const EXPENSE_TYPES_OPTIONS = {
  [EXPENSE_TYPES.LEAVE_COMPENSATION]: 'Leave Compensation',
  [EXPENSE_TYPES.ONE_TIME_PAYMENT]: 'One-time payments',
  [EXPENSE_TYPES.BENEFITS_EXPENSE]: 'Benefits expenses',
  [EXPENSE_TYPES.SOCIAL_LIABILITY]: 'Social liabilities',
  [EXPENSE_TYPES.TAX]: 'Taxes and tax-like payments',
  [EXPENSE_TYPES.DEDUCTION]: 'Deduction',
  [EXPENSE_TYPES.PERFORMANCE_BASED_BONUS]: 'Performance Based Bonus',
  [EXPENSE_TYPES.OTHER_LIABILITY]: 'Other liabilities',
};

export const EXPENSE_SUBTYPES_ALIASES: Dictionary<Dictionary<string>> = {
  [EXPENSE_TYPES.LEAVE_COMPENSATION]: {
    PAID_LEAVE: 'PAID_LEAVE',
    SICK_DAY: 'SICK_DAY',
    SICK_LEAVE: 'SICK_LEAVE',
    MATERNITY_LEAVE: 'MATERNITY_LEAVE',
    MILITARY_LEAVE: 'MILITARY_LEAVE',
  },

  [EXPENSE_TYPES.ONE_TIME_PAYMENT]: {
    SUPPORT_FOR_PERSONAL_EVENTS: 'SUPPORT_FOR_PERSONAL_EVENTS',
    CONTRACT_RENEWAL: 'CONTRACT_RENEWAL',
    REFERRAL_BONUS: 'REFERRAL_BONUS',
    RECRUITING_BONUSES_AND_COMMISSIONS: 'RECRUITING_BONUSES_AND_COMMISSIONS',
    SALES_BONUSES_AND_COMMISSIONS: 'SALES_BONUSES_AND_COMMISSIONS',
    ACCOUNT_MANAGER_BONUSES_AND_COMMISSIONS: 'ACCOUNT_MANAGER_BONUSES_AND_COMMISSIONS',
    REIMBURSEMENTS: 'REIMBURSEMENTS',
    CUSTOMER_EXCELLENCE_BONUS: 'CUSTOMER_EXCELLENCE_BONUS',
  },

  [EXPENSE_TYPES.BENEFITS_EXPENSE]: {
    RELOCATION: 'RELOCATION',
    SPORT: 'SPORT',
    CORRECTIVE_EYEGLASSES: 'CORRECTIVE_EYEGLASSES',
  },

  [EXPENSE_TYPES.DEDUCTION]: {
    BENEFITS: 'BENEFITS',
    OTHER: 'OTHER',
  },

  [EXPENSE_TYPES.TAX]: {
    FSZN: 'FSZN',
    BELGOSSTRAH: 'BELGOSSTRAH',
    ZUS: 'ZUS',
    PPK: 'PPK',
    PFRON: 'PFRON',
    USA_PAYROLL: 'USA_PAYROLL',
  },
};

export const EXPENSE_SUBTYPES: Dictionary<Dictionary<string>> = {
  [EXPENSE_TYPES.LEAVE_COMPENSATION]: {
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.LEAVE_COMPENSATION].PAID_LEAVE]: 'Paid Leave',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.LEAVE_COMPENSATION].SICK_DAY]: 'Sick Day',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.LEAVE_COMPENSATION].SICK_LEAVE]: 'Sick Leave',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.LEAVE_COMPENSATION].MATERNITY_LEAVE]: 'Maternity Leave',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.LEAVE_COMPENSATION].MILITARY_LEAVE]: 'Military Leave',
  },

  [EXPENSE_TYPES.ONE_TIME_PAYMENT]: {
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].SUPPORT_FOR_PERSONAL_EVENTS]:
      'Financial support for major personal events',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].CONTRACT_RENEWAL]: 'Contract renewal',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].REFERRAL_BONUS]: 'Referral bonus',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].RECRUITING_BONUSES_AND_COMMISSIONS]:
      'Recruiting Bonuses and Commissions',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].SALES_BONUSES_AND_COMMISSIONS]:
      'Sales Bonuses and Commissions',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].ACCOUNT_MANAGER_BONUSES_AND_COMMISSIONS]:
      'Account Manager Bonuses and Commissions',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].REIMBURSEMENTS]: 'Reimbursements',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].CUSTOMER_EXCELLENCE_BONUS]:
      'Customer excellence bonus (Customer-initiated)',
  },

  [EXPENSE_TYPES.BENEFITS_EXPENSE]: {
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.BENEFITS_EXPENSE].RELOCATION]: 'Relocation',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.BENEFITS_EXPENSE].SPORT]: 'Sport',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.BENEFITS_EXPENSE].CORRECTIVE_EYEGLASSES]: 'Corrective eyeglasses',
  },

  [EXPENSE_TYPES.DEDUCTION]: {
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.DEDUCTION].BENEFITS]: 'Benefits deductions',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.DEDUCTION].OTHER]: 'Other deductions',
  },

  [EXPENSE_TYPES.TAX]: {
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].FSZN]: 'ФСЗН',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].BELGOSSTRAH]: 'Белгосстрах',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].ZUS]: 'ZUS',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].PPK]: 'PPK',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].PFRON]: 'PFRON',
    [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].USA_PAYROLL]: 'USA payroll tax',
  },
};

export const EXPENSE_TAX_COUNTRIES = {
  [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].FSZN]: OFFICE.MINSK_1,
  [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].BELGOSSTRAH]: OFFICE.MINSK_1,
  [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].ZUS]: OFFICE.KRAKOW_1,
  [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].PPK]: OFFICE.KRAKOW_1,
  [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].PFRON]: OFFICE.KRAKOW_1,
  [EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.TAX].USA_PAYROLL]: OFFICE.AUSTIN_1,
};

export const SUBTYPE_FIELD_LABELS = {
  [EXPENSE_TYPES.LEAVE_COMPENSATION]: 'Leave type',
  [EXPENSE_TYPES.ONE_TIME_PAYMENT]: 'Payment type',
  [EXPENSE_TYPES.BENEFITS_EXPENSE]: 'Benefit type',
  [EXPENSE_TYPES.TAX]: 'Type of tax',
  [EXPENSE_TYPES.DEDUCTION]: 'Deduction type',
};

export const formatBonusData = (data: any) => ({
  ...data,
  registrationInfo: data.registration,
  payoutInfo: data.payout,
});

const useExpenseService = () => {
  const { isGranted } = useAuthorization();
  const navigate = useNavigate();
  const { sendPostRequest, sendGetRequest } = useFinanceApi();
  const {
    create: initialCreate,
    getById: initialGetBuId,
    search: baseSearch,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useFinanceApi,
  });

  const search = (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    const searchString = searchParams.filter?.['specification:search']?.eq || '';

    if (searchString && searchParams.filter) {
      delete searchParams.filter?.['specification:search'];
      searchParams.filter.title = {
        ct: searchString,
      };
    }

    return baseSearch(searchParams);
  };

  const create = async ({
    employeeName,
    compensation: filledCompensation = {},
    type,
    subtype: filledSubtype,
    ...data
  }: any) => {
    let subtype = filledSubtype;
    const compensation = {
      ...filledCompensation,
      currency: filledCompensation.currency === 'BYN' ? 'BYR' : filledCompensation.currency,
    };

    if (type === EXPENSE_TYPES.PERFORMANCE_BASED_BONUS) {
      const response = await sendPostRequest(`${RESOURCE_URL}/performance-based-bonuses`, {
        ...data,
        subtype: 'PERFORMANCE_BASED_BONUS',
        type,
        compensation,
      });
      return response.json();
    }

    if (!subtype) {
      if (type === EXPENSE_TYPES.SOCIAL_LIABILITY) {
        subtype = 'SOCIAL_LIABILITY';
      }

      if (type === EXPENSE_TYPES.OTHER_LIABILITY) {
        subtype = 'OTHER_LIABILITY';
      }
    }

    return initialCreate({
      ...data,
      subtype,
      type,
      compensation,
    });
  };

  const getById = async (id: string) => {
    if (id.startsWith('PBB-')) {
      if (!isGranted(GET_PERFORMANCE_BASED_BONUS)) {
        navigate('/access-denied', { replace: true });
        return {};
      }

      const response = await sendGetRequest(`${RESOURCE_URL}/performance-based-bonuses/${id}`);
      const data = await response.json();

      return formatBonusData(data);
    }

    return initialGetBuId(id);
  };

  const markAsPaidExpense = async (ids: string[], payoutDate: string, bankTransactionId?: string) => {
    const idsWithErrors: string[] = [];
    const data: any[] = [];

    for (let i = 0; i < ids.length; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await sendPostRequest(`${RESOURCE_URL}/${ids[i]}/mark-as-paid`, {
          payoutDate,
          bankTransactionId,
        });

        data.push(response.json());
      } catch (e) {
        idsWithErrors.push(ids[i]);
      }
    }

    if (!idsWithErrors.length) {
      return Promise.all(data);
    }

    // eslint-disable-next-line no-throw-literal
    throw {
      json: () => ({
        message: `Can't mark ${idsWithErrors.length > 1 ? 'these expenses' : 'this expense'} (${idsWithErrors.join(
          ', ',
        )}) as paid`,
      }),
    };
  };

  const markBonusAsPaid = async (
    id: string,
    payoutDate: string,
    actualBonusSize: string | number,
    bankTransactionId?: string,
  ) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/performance-based-bonuses/${id}/mark-as-paid`, {
      payoutDate,
      bankTransactionId,
      actualBonusSize,
    });
    return result.json();
  };

  const rejectExpense = async (ids: string[], rejectReason: string) => {
    const idsWithErrors: string[] = [];
    const data: any[] = [];

    for (let i = 0; i < ids.length; i += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await sendPostRequest(`${RESOURCE_URL}/${ids[i]}/reject`, {
          rejectReason,
        });

        data.push(response.json());
      } catch (e) {
        idsWithErrors.push(ids[i]);
      }
    }

    if (!idsWithErrors.length) {
      return Promise.all(data);
    }

    // eslint-disable-next-line no-throw-literal
    throw {
      json: () => ({
        message: `Can't reject ${idsWithErrors.length > 1 ? 'these expenses.' : 'this expense.'} (${idsWithErrors.join(
          ', ',
        )})`,
      }),
    };
  };

  const cancelExpense = async (id: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/cancel-payment`);
    return result.json();
  };

  const getValidationSchema = () => Promise.resolve(expenseValidation);

  const checkLeaveCompensations = async (leaveIds: string[]) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/leaves/search`, { leaveIds });
    return result.json();
  };

  const computeBonuses = async (computeDate: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/performance-based-bonuses/compute`, { computeDate });
    return result;
  };

  const runMigration = async (data: Dictionary<string>) => {
    return sendPostRequest(`${RESOURCE_URL}/migration`, data);
  };

  const uploadExpenses = async (data: FormData) => {
    const res = await sendPostRequest(`${RESOURCE_URL}/upload`, data, CONTENT_TYPE.MULTIPART);
    return res.json();
  };

  const submitListOfExpenses = async (data: any) => {
    return sendPostRequest(`${RESOURCE_URL}`, data);
  };

  const markPayrollExpensesPaid = async (data: any) => {
    return sendPostRequest(`/payroll/expenses/mark-as-paid`, data);
  };

  return {
    ...baseCrud,
    create,
    getValidationSchema,
    markAsPaidExpense,
    rejectExpense,
    cancelExpense,
    checkLeaveCompensations,
    getById,
    computeBonuses,
    markBonusAsPaid,
    search,
    runMigration,
    uploadExpenses,
    submitListOfExpenses,
    markPayrollExpensesPaid,
  };
};

export default useExpenseService;
