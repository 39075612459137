import SecurityContextTemplate, {
  SecurityContextCommonProps
} from 'shared/authorization/SecurityContextTemplate';
import React from 'react';
import useServiceCatalogService from 'erp/serviceCatalog/useServiceCatalogService';

const ServiceCatalogContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getServiceCatalogPermissions } = useServiceCatalogService() as any;

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getServiceCatalogPermissions}
      permissionToCheck={permissionToCheck}
      contextName="Service"
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default ServiceCatalogContext;
