import React from 'react';
import { CommonDateInputProps } from 'shared/uibuilder/form/input/dateHelper';
import { DateInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const LocationUpdateDateInput = (props: CommonDateInputProps) => {
  const { getLabel } = useInputHelper(props);

  return (
    <>
      <h3 className="copyable-header">{getLabel() as string}</h3>
      <DateInput {...props} label="" />
    </>
  );
};

export default LocationUpdateDateInput;
