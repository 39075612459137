/* istanbul ignore file */
import { UpdateForm } from 'shared/uibuilder/form';
import React from 'react';
import useEmployeeFeedbackService from 'erp/employee/Timeline/feedback/employeeFeedbackService';
import FeedbackForm from 'erp/employee/Timeline/feedback/form/FeedbackForm';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';

const UpdateFeedbackForm = ({ getDataFunc, onCancel, ...props }: any) => {
  const { getValidationSchema, update } = useEmployeeFeedbackService();

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      getValidationSchemaFunc={getValidationSchema}
      {...props}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <FeedbackForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

export default UpdateFeedbackForm;
