/* istanbul ignore file */
import Timeline from 'shared/uibuilder/timeline/Timeline';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import React, { useEffect } from 'react';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import useEmployeeService, { CREATE_EMPLOYEE_OFFER } from 'erp/employee/employeeService';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useEmployeeNoteItem from 'erp/employee/Timeline/note/employeeNoteRegistry';
import useEmployeeFeedbackItem from 'erp/employee/Timeline/feedback/employeeFeedbackRegistry';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeTimelineRecordTypeFilter from 'erp/employee/Timeline/shared/filter/EmployeeTimelineRecordTypeFilter';
import EmployeeTimelineFeedbackFlagTypeFilter from 'erp/employee/Timeline/shared/filter/EmployeeTimelineFeedbackFlagTypeFilter';
import { EMPLOYEE_TIMELINE_RECORDS_ALIAS } from 'erp/employee/Timeline/shared/input/EmployeeTimelineRecordTypeDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import EmployeeTimelineBreadcrumbs from 'erp/employee/Timeline/Breadcrumbs';
import EmployeeTimelineNoteTagFilter from './shared/filter/EmployeeTimelineNoteTagFilter';
import CreateButton from 'uibuilder/button/CreateButton';
import { useEmployeeOfferUrl } from 'erp/employee/offers/EmployeeOfferRouter';

const oldHashEntry = '#entry';
const FEEDBACK_TYPE_SOURCE = 'feedback.inappropriateContentType';
const NOTE_TAG_SOURCE = 'note.tag';

const EmployeeTimelinePage = () => {
  const employeeId = useEmployeeId();
  const { searchTimelineItems, createTimelineItem, getTimelinePageById } = useEmployeeService();

  const noteRegistry = useEmployeeNoteItem();
  const feedbackRegistry = useEmployeeFeedbackItem();
  const { getTimelineSingleEntityUrl } = useEmployeeUrl();
  const navigate = useNavigate();
  const location = useLocation();
  const { getCreateUrl } = useEmployeeOfferUrl();

  useEffect(() => {
    const { hash } = location;
    const entryId = hash && hash.includes(oldHashEntry) && hash.replace(oldHashEntry, '');

    if (entryId) {
      navigate(getTimelineSingleEntityUrl(employeeId!, entryId), { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filters = (
    <Filters>
      <FiltersLayout>
        <EmployeeTimelineRecordTypeFilter
          label="Entity"
          source="type"
          cleanableFilters={[FEEDBACK_TYPE_SOURCE, NOTE_TAG_SOURCE]}
        />
        <EmployeeTimelineFeedbackFlagTypeFilter
          disabled={({ type = {} }: any) => !type.in || type.in[0] !== EMPLOYEE_TIMELINE_RECORDS_ALIAS.FEEDBACK_CREATED}
          source={FEEDBACK_TYPE_SOURCE}
          label="Flagged entries"
          labelInDropdown="Inappropriate flag (feedbacks only)"
        />
        <EmployeeTimelineNoteTagFilter
          disabled={({ type = {} }: any) => !type.in || type.in[0] !== EMPLOYEE_TIMELINE_RECORDS_ALIAS.NOTE_CREATED}
          source={NOTE_TAG_SOURCE}
          label="Tag"
          labelInDropdown="Tag (notes only)"
        />
      </FiltersLayout>
    </Filters>
  );

  return (
    <Timeline
      registries={[noteRegistry, feedbackRegistry] as any}
      getDataMethod={request => searchTimelineItems(employeeId!, request)}
      createTimelineItemMethod={(itemType, request) => createTimelineItem(employeeId!, itemType, request) as any}
      getPageByEntryIdMethod={id => getTimelinePageById(employeeId!, id)}
    >
      <TimelinePageLayout
        menu={<EmployeeMenu />}
        breadcrumbs={<EmployeeTimelineBreadcrumbs />}
        entityHeader={<EmployeeEntityHeader />}
        filter={filters}
        additionalButtons={[
          <CreateButton
            url={getCreateUrl}
            label="Offer"
            key="add-offer"
            permissionToCheck={CREATE_EMPLOYEE_OFFER}
            variant="contained"
          />,
        ]}
      >
        <TimelineLayout noEntriesMessage="No entries in this employee timeline yet." />
      </TimelinePageLayout>
    </Timeline>
  );
};

export default EmployeeTimelinePage;
