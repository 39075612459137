/* istanbul ignore file */
import React from 'react';
import { WysiwygInput } from 'shared/uibuilder/form/input';
import WysiwygInputPasteArtifactDecorator, {
  WysiwygInputPasteArtifactDecoratorProps,
} from 'artifact/shared/input/WysiwygInputPasteArtifactDecorator';
import { ARTIFACT_ID } from 'artifact/const';
import { WysiwygInputProps } from 'shared/uibuilder/form/input/WysiwygInput';

const TimelineFeedbackWysiwygInput = ({
  artifactsSource,
  ...props
}: WysiwygInputProps & Omit<WysiwygInputPasteArtifactDecoratorProps, 'children'>) => {
  return (
    <WysiwygInputPasteArtifactDecorator
      artifactsSource={artifactsSource}
      artifactTypeId={ARTIFACT_ID.FEEDBACK_ATTACHMENT}
    >
      <WysiwygInput {...props} />
    </WysiwygInputPasteArtifactDecorator>
  );
};

export default TimelineFeedbackWysiwygInput;
