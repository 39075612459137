/* eslint-disable import/prefer-default-export */
const getWithDecimalDigits = (amount: string) => {
  let i = parseFloat(amount);
  if (Number.isNaN(i)) {
    i = 0.0;
  }
  let minus = '';
  if (i < 0) {
    minus = '-';
  }
  i = Math.abs(i);
  i = parseInt(String((i + 0.005) * 100), 10);
  i /= 100;
  let s = i.toString();
  if (s.indexOf('.') < 0) {
    s += '.00';
  }
  if (s.indexOf('.') === s.length - 2) {
    s += '0';
  }
  s = minus + s;
  return s;
};

export const getWithThousandsSeparator = (amount: string | number) => {
  const x = getWithDecimalDigits(String(amount));
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertCurrency = (value: number, exchangeRate: number, shouldConvertTo = true) => {
  if (shouldConvertTo) {
    return +(value / exchangeRate).toFixed(2);
  }

  return +(value * exchangeRate).toFixed(2);
};

export const updateCurrencyName = (value: string) => value.replace(/BYR/g, 'BYN');
