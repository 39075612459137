import React from 'react';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import Collapsible from 'shared/uibuilder/field/Collapsible';
import DateField from 'shared/uibuilder/field/DateField';
import { KPI_STRUCTURE_ADD, KPI_STRUCTURE_UPDATE } from './useKPIService';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import CopyKpiStructureButton from './shared/buttons/CopyKPIStructureButton';
import KpiParametersListField from './shared/fields/KPIParametersListField';
import BadgeField from 'shared/uibuilder/field/BadgeField';
import KpiStatusBadge from './shared/fields/KPIStatusBadge';

const ServiceKPIStructureRecordLayout = ({ onEdit, ...props }: any) => {
  const { data } = useShowContext();

  const buttons = [
    <EditTimelineRecordButton onEdit={onEdit} permissionToCheck={KPI_STRUCTURE_UPDATE} />,
    <CopyKpiStructureButton permissionToCheck={KPI_STRUCTURE_ADD} />,
  ];

  return (
    <BaseTimelineRecordLayout
      timelineType={TimelineType.NOTE}
      buttons={buttons}
      entity={data}
      {...props}
      fields={
        <Collapsible>
          <ShowSection>
            <BadgeField
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
              widht="1000px"
              badges={[<KpiStatusBadge label="Status" source="status" />]}
            >
              <DateField label="Effective Date" source="effectiveDate" dateFormat={DATE_FORMAT.API_ONLY_MONTH} />
            </BadgeField>
            <KpiParametersListField source="kpiParameters" dateSource="effectiveDate" />
          </ShowSection>
        </Collapsible>
      }
    />
  );
};

export default ServiceKPIStructureRecordLayout;
