export default {
  type: {
    required: true,
    type: 'string',
  },
  relatedAccount: {
    required: {
      value: true,
      when: {
        $type: {
          ne: 'COMPANY_SERVICE_BUDGET',
        },
        $_PERMISSIONS: {
          ct: 'ACCOUNT_ATTACH_ARTIFACT',
        },
      },
    },
    type: 'string',
  },
  artifactId: {
    required: true,
    type: 'array',
    maxSize: 1,
  },
  sowNumber: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          eq: 'STATEMENT_OF_WORK',
        },
      },
    },
  },
  msaNumber: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          eq: 'MSA',
        },
      },
    },
  },
  changeRequestNumber: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          eq: 'CHANGE_REQUEST',
        },
      },
    },
  },
  amendmentNumber: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          eq: 'AMENDMENT',
        },
      },
    },
  },
  acceptanceFromDate: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          eq: 'ACCEPTANCE',
        },
      },
    },
    maxDate: {
      value: '$acceptanceToDate',
      message: "From date can't be later than to date",
      when: {
        $type: {
          eq: 'ACCEPTANCE',
        },
      },
    },
  },
  acceptanceToDate: {
    type: 'date',
    required: {
      value: true,
      when: {
        $type: {
          eq: 'ACCEPTANCE',
        },
      },
    },
    minDate: {
      value: '$acceptanceFromDate',
      message: "To date can't be earlier than from date",
      when: {
        $type: {
          eq: 'ACCEPTANCE',
        },
      },
    },
  },
  sow: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          in: ['ACCEPTANCE', 'CHANGE_REQUEST', 'AMENDMENT'],
        },
      },
    },
  },
  relatedService: {
    required: {
      value: true,
      when: {
        $type: {
          in: ['COMPANY_SERVICE_BUDGET'],
        },
      },
    },
    type: 'string',
  },
  budgetName: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          in: ['COMPANY_SERVICE_BUDGET'],
        },
      },
    },
  },
};
