/* istanbul ignore file */
import { StatusContext } from 'privileged/externalaccounts/creation/accountsCreationResponseHandler';
import React from 'react';
import { AzureGroupsProvisionStepStatus, ExecutionStatus } from './useCreateOnePasswordResponseHandler';

export type CreateProjectAzureGroupsResponse = {
  groupsStepStatus?: AzureGroupsProvisionStepStatus;
};

const useCreateAzureGroupsResponseHandler = () => {
  const handleAzureGroupsProvisionStep = (
    groupStatus: AzureGroupsProvisionStepStatus,
    statusContext: StatusContext,
  ) => {
    if (groupStatus.status === ExecutionStatus.SUCCESS) {
      statusContext.successes.push(<li key="success-creation">Role Specific Azure Groups have been created!</li>);
    } else {
      let isProblemRelatedToProjectUpdate = true;
      groupStatus.groups?.forEach(group => {
        if (group?.status === ExecutionStatus.FAIL) {
          statusContext.errors.push(
            <li key="error-creation">
              Failed to create Role Specific Azure Group with key {group?.key} due to: {group?.errors?.join(' ')}.
            </li>,
          );
          isProblemRelatedToProjectUpdate = false;
        }
      });

      if (isProblemRelatedToProjectUpdate) {
        statusContext.errors.push(
          <li key="error-creation">
            Role Specific Azure Groups have been created, but system cannot update related values for Project Entity.
            Please contact DaVinci Support.
          </li>,
        );
      }
    }
  };

  const handleAzureGroupsResponse = (state: CreateProjectAzureGroupsResponse, statusContext: StatusContext) => {
    if (state.groupsStepStatus) {
      handleAzureGroupsProvisionStep(state.groupsStepStatus, statusContext);
    }
  };

  return {
    handleAzureGroupsResponse,
  };
};

export default useCreateAzureGroupsResponseHandler;
