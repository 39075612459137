/* istanbul ignore file */
import { READ_PROJECT_STATUS_LIST, StatusTypes } from 'erp/project/statuses/ProjectStatusesService';
import StatusPlanningRecordLayout from './layout/StatusPlanningRecordLayout';
import UpdateProjectPlanningStatusForm from './form/UpdateProjectPlanningStatusForm';

const useProjectPlanningStatusRegistry = () => ({
  UpdateForm: UpdateProjectPlanningStatusForm,
  isApplicable: (item: { projectStatusType: StatusTypes }) => item.projectStatusType === StatusTypes.PLANNING,
  source: 'status',
  urlPostfix: 'status',
  RecordLayout: StatusPlanningRecordLayout,
  name: 'Project Status',
  hasFullData: true,
  permissionToCheck: READ_PROJECT_STATUS_LIST,
});

export default useProjectPlanningStatusRegistry;
