/* istanbul ignore file */
// @ts-nocheck
// create migration every time when:
// 1. permissions update
// 2. change store structure
const migrations = {
  0: state => ({
    ...state,
    auth: {},
  }),
  1: state => ({
    ...state,
    auth: {},
  }),
  2: state => ({
    ...state,
    auth: {},
  }),
  3: state => ({
    ...state,
    filtering: {
      ...state.filtering,
      employee: {},
    },
  }),
  4: state => ({
    ...state,
    auth: {},
  }),
  5: state => ({
    ...state,
    auth: {},
  }),
  6: state => ({
    ...state,
    auth: {},
  }),
  7: state => ({
    ...state,
    auth: {},
  }),
  8: state => ({
    ...state,
    filtering: {},
  }),
  9: state => ({
    ...state,
    filtering: {},
  }),
  10: state => ({
    ...state,
    filtering: {},
  }),
  11: state => ({
    ...state,
    auth: {},
  }),
  12: state => ({
    ...state,
    auth: {},
  }),
  13: state => ({
    ...state,
    auth: {},
    authToken: {},
  }),
  14: state => ({
    ...state,
    auth: {},
  }),
  15: state => ({
    ...state,
    auth: {},
    authToken: {},
  }),
  16: state => ({
    ...state,
    auth: {},
    authToken: {},
  }),
  17: state => ({
    ...state,
    auth: {},
    authToken: {},
  }),
  18: state => ({
    ...state,
    artifacts: {},
  }),
};

const lastVersion = Object.keys(migrations)[Object.keys(migrations).length - 1];

export { migrations, lastVersion };
