/* istanbul ignore file */
import React from 'react';
import Dropdown from '../../../../../shared/uibuilder/form/input/Dropdown';
import { DefaultDropdownProps } from '../../../../../shared/uibuilder/form/input/dropdownHelper';

export const EMPLOYEE_CONTRACT_POSITIONS = {
  CEO: 'Director / Директор',
  CTO: 'Deputy Director for Technical Issues - Technical Director / Заместитель директора по техническим вопросам - технический директор',
  CFO: 'Deputy Director for Financial Affairs / Заместитель директора по финансовым вопросам',
  COO: 'Deputy Director of Operations / Заместитель директора по операционной деятельности',
  CHRO: 'Deputy HR Director / Заместитель директора по персоналу',
  MAIN_ACCOUNTANT: 'Chief Accountant / Главный бухгалтер',
  PM: 'Project Manager / Менеджер проектов',
  DEV_ENGINEER: 'Software Engineer / Инженер-программист',
  BA: 'Business Analyst / Бизнес-аналитик',
  ECONOMIST: 'Economist / Экономист',
  QA_ENGINEER: 'Quality Assurance Engineer / Тестировщик программного обеспечения',
  DEV_LEAD: 'Lead Software Engineer / Ведущий инженер-программист',
  HR_MANAGER: 'Personnel Accounting Specialist / Специалист по кадрам',
  PR_MANAGER: 'Public Relations Specialist / Специалист по связям с общественностью',
  TRANSLATOR: 'Interpreter / Переводчик',
  DEV_TECHNICIAN: 'Technical Engineer / Техник-программист',
  DESIGNER_CONSTRUCTOR: 'UX/UI Designer / Художник-конструктор',
  RECRUITER: 'HR Manager / Менеджер по персоналу',
  HR_SPECIALIST: 'HR Specialist / Специалист по персоналу',
  SALES_SPECIALIST: 'Sales Manager / Специалист по продажам',
  ACCOUNTANT: 'Accountant / Бухгалтер',
  MARKETING_SPECIALIST: 'Marketing Specialist / Маркетолог',
  SECRETARY_ASSISTANT: 'Assistant Secretary / Секретарь-референт',
  OTHER: 'Other / Другое',
};

const EMPLOYEE_CONTRACT_POSITIONS_SORTED_ENTRIES = Object.entries(EMPLOYEE_CONTRACT_POSITIONS).sort((a, b) =>
  a[1].localeCompare(b[1]),
);

const EmployeeContractPositionDropdown = (props: DefaultDropdownProps) => (
  <Dropdown
    {...props}
    options={EMPLOYEE_CONTRACT_POSITIONS_SORTED_ENTRIES.map(entry => {
      const [value, label] = entry;

      return {
        value,
        label,
      };
    })}
    placeholder="Select contract position"
  />
);

export default EmployeeContractPositionDropdown;
