import React from 'react';
import { Button, Box } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface ExpandButtonProps {
  expanded: boolean;
  onClick: () => void;
}

const ExpandButton: React.FC<ExpandButtonProps> = ({ expanded, onClick }) => {
  return (
    <Box
      sx={{
        gridColumn: '1 / -1',
        textAlign: 'center',
      }}
    >
      <Button variant="outlined" color="primary" sx={{ p: 0 }} onClick={onClick} fullWidth>
        <ExpandLessIcon
          sx={{
            height: '10px',
            color: (theme: any) => (theme.vars.palette.neutral as any)[0],
            transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: 'transform 0.2s ease-in-out',
          }}
        />
      </Button>
    </Box>
  );
};

export default ExpandButton;
