import React, { useEffect, useState } from 'react';
import { AuthenticationToken } from 'authentication/DefaultTokenProvider';
import useAuthenticationService from 'authentication/authenticationService';
import { AuthenticationProvider } from 'authentication/AuthenticationProvider';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAuthenticationTokenRepository from 'authentication/tokenprovider/authenticationTokenRepository';
import Loading from 'shared/uibuilder/Loading';
import useAsyncValue from 'shared/asyncHelper';

const AuthenticationProviderWrapper = ({ children }: { children: React.ReactNode }) => {
  const [userAlias, setUserAlias] = useState<Nullable<string | undefined>>(undefined);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean | undefined>(undefined);
  const { isAuthenticated } = useAuthenticationService();
  const { getUserAlias } = useAuthenticatedUserService();
  const { getToken } = useAuthenticationTokenRepository();
  const token = useAsyncValue(getToken, [getToken]);

  useEffect(() => {
    (async () => {
      const alias = await getUserAlias();
      const isCurrentUserAuthenticated = await isAuthenticated();

      setIsUserAuthenticated(isCurrentUserAuthenticated);
      setUserAlias(alias);
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getAccessToken = (): AuthenticationToken => token?.token || null;

  if (userAlias === undefined) {
    return <Loading />;
  }

  return (
    <AuthenticationProvider
      getToken={getAccessToken}
      isAuthenticated={isUserAuthenticated || false}
      userAlias={userAlias}
    >
      {children}
    </AuthenticationProvider>
  );
};

export default AuthenticationProviderWrapper;
