import { API_FILE_TYPE } from 'artifact/const';
import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import withLightbox from 'artifact/shared/Lightbox/withLightbox';
import AttachmentFieldLayout from 'artifact/shared/field/layout/AttachmentFieldLayout';

import './ArtifactsLayout.scss';

type AttachmentsListLayoutProsp = {
  attachments?: { id: string; title: string; type: string }[];
  label?: Nullable<string>;
  openLightbox: (
    images: any[],
    index: number,
    func: (id: number | string, title: string) => void,
    isDownload: boolean,
  ) => void;
  downloadFile: (id: number | string, fileName?: string) => void;
  getThumbnail: (id: number | string, size: string) => void;
  openPreview: (id: number | string) => void;
  isDownloadAvailable?: boolean;
  isPhotos?: boolean;
};

const AttachmentsListLayout = ({
  attachments = [],
  label = null,
  openLightbox,
  downloadFile,
  getThumbnail,
  openPreview,
  isDownloadAvailable = true,
  isPhotos = false,
}: AttachmentsListLayoutProsp) => {
  const { BaseFieldLayout } = useUiTheme();

  const images = attachments && attachments.filter(file => file.type === API_FILE_TYPE.image.alias);

  const files = attachments && attachments.filter(file => file.type !== API_FILE_TYPE.image.alias);

  const isPhotosLabel = isPhotos || label === 'Photos';

  return (
    <BaseFieldLayout
      isColumnDirection={!!attachments.length}
      label={label}
      value={
        attachments &&
        !!attachments.length && (
          <>
            <ul className={isPhotosLabel ? `files-list__photos` : `files-list__files`}>
              {!!images?.length &&
                images.map(img => (
                  <li key={img.id} className="files-item">
                    <AttachmentFieldLayout
                      value={img}
                      getThumbnail={getThumbnail}
                      handleDownload={() => downloadFile(img.id, img.title)}
                      handleClick={() =>
                        openLightbox(
                          images,
                          images.indexOf(img),
                          (id, title) => downloadFile(id || img.id, title || img.title),
                          isDownloadAvailable,
                        )
                      }
                    />
                  </li>
                ))}
              {!!files?.length &&
                files.map(
                  file =>
                    file && (
                      <li key={file.id} className="files-item">
                        <AttachmentFieldLayout
                          value={file}
                          handleClick={() => downloadFile(file.id, file.title)}
                          handleOpenPreview={() => openPreview(file.id)}
                          isDownloadAvailable={isDownloadAvailable}
                        />
                      </li>
                    ),
                )}
            </ul>
          </>
        )
      }
    />
  );
};

export default withLightbox(AttachmentsListLayout);
