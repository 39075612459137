import React from 'react';
import RowGroupInputsWrapper from 'uibuilder/form/RowGropInputsWrapper/RowGroupInputsWrapper';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import { Checkbox, DateInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const TargetHireDateInput = () => {
  const { getValue } = useInputHelper({ source: 'isAsap' });
  const isAsap = getValue();

  return (
    <RowGroupInputsWrapper
      isRequired
      wrapperLabel="Target hiring date"
      sxProps={{
        '& > div:first-of-type': {
          flexBasis: '25%',
          flexGrow: 1,
          maxWidth: '25%',
        },

        '& > div:not(:first-of-type)': {
          flexBasis: '75%',
          maxWidth: '75%',
        },
      }}
    >
      <Checkbox source="isAsap" defaultValue={false} label="Asap" />

      {isAsap ? null : (
        <ClearAfterUnmountWrapper>
          <DateInput source="targetHiringDate" label="" />
        </ClearAfterUnmountWrapper>
      )}
    </RowGroupInputsWrapper>
  );
};

export default TargetHireDateInput;
