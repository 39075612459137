/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import React from 'react';
import { Collapsible, LinkField, TextField } from 'shared/uibuilder/field';
import useProjectStatusesService, {
  StatusTypesLabels,
  StatusTypes,
  UPDATE_PROJECT_STATUS,
} from 'erp/project/statuses/ProjectStatusesService';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import StatusPeriodField from '../../shared/field/StatusPeriodField';
import TicketsListField from '../../shared/field/TicketsListField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import FormSection from 'shared/layout/form/FormSection';

const StatusPlanningRecordLayout = ({ onEdit, ...props }: any) => {
  const { data: entity } = useShowContext();
  const { checkIsEditable } = useProjectStatusesService();
  const isEditable = checkIsEditable(new Date(entity.to), 7);

  const buttons = isEditable
    ? [<EditTimelineRecordButton permissionToCheck={UPDATE_PROJECT_STATUS} key="edit" onEdit={onEdit} />]
    : [];

  return (
    <BaseTimelineRecordLayout
      timelineType={StatusTypesLabels[StatusTypes.PLANNING]}
      buttons={buttons}
      fields={
        <Collapsible>
          <>
            <StatusPeriodField label="Weekly cycle period" source="from" toSource="to" />
            <LinkField source="recordLink" label="Link to the planning record" />
            <TextField source="scopeDescription" label="Scope description" />
            {entity.tickets && entity.tickets.length > 0 && (
              <FormSection title="Planned work items" titleVariant="h6">
                <TicketsListField source="tickets" label="" />
              </FormSection>
            )}
          </>
        </Collapsible>
      }
      {...props}
    />
  );
};

export default StatusPlanningRecordLayout;
