/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

import { DOCUMENT_TYPES } from 'erp/employee/Education/shared/educationService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const DocumentTypeInput = (props: Omit<DefaultDropdownProps, 'options'>) => {
  return <EnumDropdown {...props} options={DOCUMENT_TYPES} />;
};

export default DocumentTypeInput;
