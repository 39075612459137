/* istanbul ignore file */
import useEmployeeService from 'erp/employee/employeeService';
import Show from 'shared/uibuilder/show/Show';
import React from 'react';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';

const ShowEmployee = ({ children = null }: { children?: React.ReactNode }) => {
  const { getById } = useEmployeeService();
  const currentAlias = useEmployeeId()!;

  return <Show getDataMethod={() => getById(currentAlias)}>{children}</Show>;
};

export default ShowEmployee;
