import { groupBy } from 'lodash';
import useCacheService from 'shared/cache/cacheService';
import useSingletonPromise from 'shared/useSingletonPromise';
import { useListContext, LoadingParams } from 'shared/uibuilder/list/ListContext';
import { useBusinessEngineApi } from 'api';
import useHandbookStructureService, {
  HANDBOOK_INDEX_FILE_PATH,
  HandbookItemStructure,
} from './handbookStructureService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';
import { escapeRegex } from 'shared/uibuilder/field/SearchResultField';

export const RESOURCE_URL = '/handbook';
const CACHE_INDEX_PAGE_NAME = 'handbookIndexPage';
const CACHE_INDEX_PAGE_ID = 'indexPageData';

const MARKDOWN_API_URL = '/handbook/markdown';
const HANDBOOK_SEARCH_API_URL = '/handbook/search';

export interface HandbookTableOfContentItem {
  title: string;
  hash: string;
  children?: HandbookTableOfContentItem[] | null;
}

interface HandbookData {
  html: string;
  tableOfContent: HandbookTableOfContentItem[];
  rootFilePath: string;
}

interface GitlabSearchResultItem {
  data: string;
  path: string;
}

type SearchResultItem = {
  data: string;
  numberOfMatches: number;
};

interface SearchResults extends HandbookItemStructure {
  searchResults: {
    firstResult: Nullable<SearchResultItem>;
    numberOfMatches: number;
  };
}

export const MIN_THRESHOLD = 1;

export const countMatches: (rawText: string, searchString: string) => number = (rawText = '', searchString = '') => {
  try {
    const regex = new RegExp(escapeRegex(searchString), 'ig');
    return (`${rawText}`.match(regex) || []).length;
  } catch (error) {
    return 0;
  }
};

const removeMarkdown = (markdownText: string) => {
  try {
    const htmlText = markdownText
      .trim()
      .replace(/^(####|###|##|#|>) (.*$)/gim, '$2') // headers and blockquotes
      .replace(/\*\*(.*)\*\*/gim, '$1') // bold text
      .replace(/\*(.*)\*/gim, '$1') // italic text
      .replace(/!\[(.*?)\]\((.*?)\)/gim, '') // images
      .replace(/\[(.*?)\]\((.*?)\)/gim, '$1'); // links

    return htmlText.trim();
  } catch (error) {
    return markdownText;
  }
};

const useHandbookService = () => {
  const { addMessage } = useMessageService();
  const { sendGetRequest, sendPostRequest } = useBusinessEngineApi();
  const { loading: searching, search: searchStringInContext = '' } = useListContext();
  const { getHandbookStructure } = useHandbookStructureService();
  const { getValue: getCahedIndexPageValue, addToCache: addToIndexPageCache } = useCacheService(CACHE_INDEX_PAGE_NAME);

  const isSearchMode = searchStringInContext.length >= MIN_THRESHOLD;

  const getIndexPage = useSingletonPromise('getIndexPage', async () => {
    try {
      const cachedValue = getCahedIndexPageValue(CACHE_INDEX_PAGE_ID);

      if (cachedValue) {
        return cachedValue;
      } else {
        const res = await sendGetRequest(`${MARKDOWN_API_URL}?filePath=${HANDBOOK_INDEX_FILE_PATH}`);
        const handbookData = await res.json();

        addToIndexPageCache(CACHE_INDEX_PAGE_ID, handbookData);

        return handbookData;
      }
    } catch (e) {
      addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));

      return null;
    }
  });

  const getFullProjectPath = async (pagePathname: string) => {
    const cachedValue = getCahedIndexPageValue(CACHE_INDEX_PAGE_ID);
    let handbookData: HandbookData = cachedValue;

    if (!cachedValue) {
      handbookData = await getIndexPage();
    }

    return `${handbookData.rootFilePath}${pagePathname.startsWith('/') ? '' : '/'}${pagePathname}`;
  };

  const getPage = async (path: string) => {
    try {
      const res = await sendGetRequest(`${MARKDOWN_API_URL}?filePath=${path}`);
      const data = await res.json();

      return data;
    } catch (e) {
      if ((e as Response).status !== 404) {
        addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
      }

      return null;
    }
  };

  const parseSearchResults = (searchResults: GitlabSearchResultItem[], searchString: string, pageTitle: string) => {
    let paragraphs: string[] = [];

    searchResults.forEach(({ data: foundedString }) => {
      paragraphs.push(...foundedString.split('\n'));
    });

    paragraphs = paragraphs.map(str => removeMarkdown(str));

    let firstResult: Nullable<SearchResultItem> = null;
    let numberOfMatches = 0;

    paragraphs.forEach(paragraph => {
      const matchesLength = countMatches(paragraph, searchString);

      if (!firstResult && matchesLength && paragraph !== pageTitle) {
        firstResult = {
          data: paragraph,
          numberOfMatches: matchesLength,
        };
      }

      numberOfMatches += matchesLength;
    });

    return {
      firstResult,
      numberOfMatches,
    };
  };

  const getSearchData = (
    searchResults: Dictionary<GitlabSearchResultItem[]>,
    requests: HandbookItemStructure[],
    searchString: string,
  ) => {
    const inputValue = searchString.trim().toLowerCase();
    const inputLength = inputValue.length;
    let foundedSuggestions: SearchResults[] = [];

    if (inputLength >= MIN_THRESHOLD) {
      requests.forEach(searchableItem => {
        const { subPages, pagePathname, pageTitle } = searchableItem;
        const searchResult = searchResults[pagePathname.replace(/^\//, '')] || [];

        const parsedSearchResult = parseSearchResults(searchResult, searchString, pageTitle);

        if (countMatches(pageTitle, searchString) || parsedSearchResult.numberOfMatches) {
          foundedSuggestions.push({
            ...searchableItem,
            searchResults: {
              ...parsedSearchResult,
            },
          });
        }

        foundedSuggestions = [...foundedSuggestions, ...getSearchData(searchResults, subPages || [], searchString)];
      });
    }

    return foundedSuggestions;
  };

  const search = async ({ filter }: LoadingParams) => {
    try {
      const searchString = filter?.['specification:search']?.eq || '';
      const handbookStructure = await getHandbookStructure();

      let filteredData;

      if (searchString && searchString.length) {
        const res = await sendPostRequest(HANDBOOK_SEARCH_API_URL, {
          filter: {
            searchString: { ct: searchString },
          },
        });

        const { data } = await res.json();
        const groupedData = groupBy(data, 'path') as Dictionary<GitlabSearchResultItem[]>;

        filteredData = getSearchData(groupedData, handbookStructure, searchString).sort(
          (a, b) => (b.searchResults?.numberOfMatches || 0) - (a.searchResults?.numberOfMatches || 0),
        );
      } else {
        filteredData = handbookStructure;
      }

      return {
        result: filteredData,
      };
    } catch {
      return {
        result: [],
      };
    }
  };

  return {
    getPage,
    search,
    getIndexPage,
    isSearchMode,
    searching,
    getFullProjectPath,
  };
};

export default useHandbookService;
