import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useSensitiveDataContext } from 'shared/uibuilder/sensitiveData/SensitiveDataContext';
import getLabel, { isNotNullValue } from 'shared/uibuilder/helper';
import Data from 'shared/uibuilder/Data';
import { FieldProps } from 'shared/uibuilder/field';

interface FieldHelperData<ValueType> {
  getValue: () => ValueType;
  getLabel: () => string | JSX.Element | (() => string | JSX.Element) | null | undefined;
  getData: () => Data | object;
}

const useFieldHelper: <ValueType = string>(props?: FieldProps<ValueType>) => FieldHelperData<ValueType> = (
  props = { source: '' },
) => {
  const data = useShowContext()?.data;
  const sensitiveDataContext = useSensitiveDataContext();

  const getValue = () => {
    const { value, source = '' } = props;

    const getValueFromContext = () => {
      if (!data) {
        return undefined;
      }

      const showContextValue = data.getValueBySource(source!);

      if (isNotNullValue(showContextValue)) {
        return showContextValue;
      }

      if (!sensitiveDataContext || !sensitiveDataContext.getValueByIdAndSource) {
        return undefined;
      }

      const sensitiveId = data.getValueBySource(sensitiveDataContext.sensitiveDataIdSource);

      return sensitiveDataContext.getValueByIdAndSource(sensitiveId, source);
    };

    return isNotNullValue(value) ? value : getValueFromContext();
  };

  const getFieldLabel = () => {
    const { label, source } = props;

    return getLabel(label, source!);
  };

  const getData = () => data && data.getData();

  return {
    getValue,
    getLabel: getFieldLabel,
    getData,
  };
};
export default useFieldHelper;
