import React from 'react';
import { EmployeeLink, EmployeeLinkWithNameLoading } from 'erp/employee';
import Box from 'uibuilder/Box';

const DEFAULT_NAME = 'Leonardo DaVinci';

type TimelineRecordAuthorProps = {
  name: string;
  id: string;
};

const TimelineRecordAuthor = ({ name, id }: TimelineRecordAuthorProps) => {
  if (name) {
    return <EmployeeLink id={id} name={name} />;
  }

  return id ? (
    <Box sx={{ display: ' inline-flex', '.input-loader': { transform: 'translateY(3px)', marginTop: '-2px' } }}>
      <EmployeeLinkWithNameLoading id={id} defaultValue={DEFAULT_NAME} />
    </Box>
  ) : (
    <span>{DEFAULT_NAME}</span>
  );
};

export default TimelineRecordAuthor;
