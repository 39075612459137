import React, { useEffect } from 'react';
import { BeforeSubmitModalProps } from 'crm/opportunity/presale/request/shared/modal/BeforeSubmitModal';
import { FormProvider, useFormContext } from 'shared/uibuilder/form/FormContext';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import usePresaleOutputService from 'crm/opportunity/presale/output/shared/presaleOutputService';
import { Button } from 'uibuilder/button';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import useForm, { getSubmissionErrorsFromResponse, isEmptyFormErrors } from 'shared/uibuilder/form/formHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import PresaleOutputInputForm from 'crm/opportunity/shared/OpportunityPipeline/button/MarkStateAsCompletedButton/PresaleOutputInputForm';
import presaleOutputValidation from 'crm/opportunity/presale/output/form/presaleOutputValidation';

const BeforeSubmitContractRequestModal = ({ modalOpen, setModalOpen }: BeforeSubmitModalProps) => {
  const id = useOpportunityId();
  const { submitForm = () => {} } = useFormContext();
  const { getLastPresaleOutput, updateOutput } = usePresaleOutputService();
  const { data: showData } = useShowContext();

  const contextValue = useForm({
    submitFormFunc: () => Promise.resolve({}),
    getValidationSchemaFunc: () => Promise.resolve(presaleOutputValidation),
  });

  useEffect(() => {
    (async () => {
      const lastOutput = await getLastPresaleOutput(id);
      const isContractRenewal = showData.getValueBySource('isContractRenewal');
      const initialOutputData = lastOutput ? { ...lastOutput, isContractRenewal } : lastOutput;
      contextValue.setFormData(initialOutputData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen, id]);

  const handleComplete = async () => {
    if (contextValue.data) {
      contextValue.setFormErrors({});
      const formData = contextValue.data;
      const formErrors = {
        ...contextValue.validateForm(),
        isValidExpectedProjectStart: !formData.isValidExpectedProjectStart ? ['Please, confirm!'] : [],
        isValidProjectType: !formData.isValidProjectType ? ['Please, confirm!'] : [],
        isValidRequiredResources: !formData.isValidRequiredResources ? ['Please, confirm!'] : [],
      };
      contextValue.setFormErrors(formErrors);
      if (!isEmptyFormErrors(formErrors)) {
        return;
      }
      try {
        const outputData = contextValue.data;
        await updateOutput(id, outputData.id, outputData);
      } catch (e) {
        const errors = await getSubmissionErrorsFromResponse(e as Response);
        contextValue.setFormErrors(errors);
        return;
      }
    }
    submitForm();
    setModalOpen(false);
  };

  const submit = (
    <Button data-testid="btn-success" key="modal-submit-button" onClick={handleComplete}>
      Submit
    </Button>
  );

  return (
    <>
      <ModalWindow
        modalSize="lg"
        isOpen={modalOpen}
        title="Please ensure that the Team Structure and Expected Project Start Date are up to date."
        hasCancelButton={false}
        buttons={[submit]}
        onToggle={() => {
          setModalOpen(false);
        }}
      >
        <span>
          Check the Team Structure, Project Type and Expected Project Start Date. If the data is not up to date, please
          update it using the form below and click the Submit button.
        </span>
        <div className="mt-5">
          <FormProvider value={contextValue}>
            <PresaleOutputInputForm />
          </FormProvider>
        </div>
      </ModalWindow>
    </>
  );
};

export default BeforeSubmitContractRequestModal;
