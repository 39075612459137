/* istanbul ignore file */
import React from 'react';

type OfficeLeavesBalanceColumnLayoutProps = {
  totalField: React.ReactElement;
  [p: string]: any;
};

const OfficeLeavesBalanceColumnLayout = ({ totalField }: OfficeLeavesBalanceColumnLayoutProps) => {
  return <>{totalField}</>;
};

export default OfficeLeavesBalanceColumnLayout;
