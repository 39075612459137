// libraries
import React from 'react';
// components
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
// services
import { useCompanyRolesUrl } from './companyRolesService';

const CompanyRolesBreadCrumbs = ({ children }: any) => {
  const { getListUrl } = useCompanyRolesUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Company roles" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default CompanyRolesBreadCrumbs;
