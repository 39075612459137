/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const FinanceListBreadcrumbs = () => {
  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Finance" />
    </SingleEmployeeBreadcrumbs>
  );
};

export default FinanceListBreadcrumbs;
