/* istanbul ignore file */
import React from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';

const ElectronicOptionAgreementField = (props: FieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);

  return <TextField label={getLabel()} value={getValue() ? 'Yes' : 'No'} />;
};

export default ElectronicOptionAgreementField;
