/* istanbul ignore file */
import { useArtifactStorageId, useArtifactStorageUrl } from 'artifactStorage/ArtifactStorageRouter';
import React from 'react';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { SummaryMenuItem } from 'uibuilder/menuitem';

const ViewArtifactStoragePageMenu = () => {
  const id = useArtifactStorageId();
  const { getSingleEntityUrl } = useArtifactStorageUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl(id)} exact />
    </SidebarStickyMenu>
  );
};

export default ViewArtifactStoragePageMenu;
