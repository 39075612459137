import React from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import VacancyInput from 'erp/candidate/shared/input/VacancyInput';
import FormRow from 'shared/layout/form/FormRow';

const excludeByAlias = (objectsArray: any, aliasesToExclude: any) => {
  return objectsArray.filter((obj: any) => !aliasesToExclude.includes(obj.alias));
};

const HiringVacanciesInput = () => {
  const { data = {} } = useFormContext();
  const vacancies = data.vacancies || [];

  return (
    <FormRow>
      <VacancyInput
        additionalMapData={(optionsData: any) => {
          return excludeByAlias(optionsData, vacancies);
        }}
        source="vacancies"
        label="Add vacancies"
      />
    </FormRow>
  );
};

export default HiringVacanciesInput;
