import { APPEND_ARTIFACTS, CLEAR_ARTIFACTS, REMOVE_ARTIFACTS } from './artifactService';

export const appendArtifacts = (artifacts: any) => ({
  type: APPEND_ARTIFACTS,
  artifacts,
});

export const removeArtifacts = (artifactIds: any) => ({
  type: REMOVE_ARTIFACTS,
  artifactIds,
});

export const clearArtifacts = () => ({
  type: CLEAR_ARTIFACTS,
});
