/* istanbul ignore file */
import React from 'react';
import { RadioButtonGroup } from 'shared/uibuilder/form/input';
import { RadioButtonGroupProps } from 'shared/uibuilder/form/input/RadioButtonGroup';

const MARITAL_STATUSES = [
  { label: 'Single', value: 'NOT_MARRIED' },
  { label: 'Married', value: 'MARRIED' },
];

const MaritalStatusInput = (props: RadioButtonGroupProps) => {
  return <RadioButtonGroup options={MARITAL_STATUSES} {...props} />;
};

export default MaritalStatusInput;
