import React from 'react';
import moment from 'moment';
import useDateHelper, {
  COMMON_DATE_INPUT_DEFAULT_PROPS,
  CommonDateInputProps,
} from 'shared/uibuilder/form/input/dateHelper';
import { DateInput } from './index';

const TIME_FORMAT = 'HH:mm:ss';
const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const DateTimeInput = ({
  disabled = COMMON_DATE_INPUT_DEFAULT_PROPS.disabled,
  dateFormat = COMMON_DATE_INPUT_DEFAULT_PROPS.dateFormat,
  minDate = COMMON_DATE_INPUT_DEFAULT_PROPS.minDate,
  maxDate = COMMON_DATE_INPUT_DEFAULT_PROPS.maxDate,
  timeFormat = COMMON_DATE_INPUT_DEFAULT_PROPS.timeFormat,
  ...other
}: CommonDateInputProps) => {
  const props = {
    disabled,
    dateFormat,
    minDate,
    maxDate,
    timeFormat,
    ...other,
  };
  const { getTimeFormat, getDateFormat } = useDateHelper(props);

  const formatTime = getTimeFormat() || TIME_FORMAT;

  const getDateTimeFormat = () => {
    return `${getDateFormat()} ${formatTime}` || DATETIME_FORMAT;
  };

  const dateTimeFormat = getDateTimeFormat();

  const formatDate = (textDate: string) => {
    let result = textDate;
    if (moment(textDate, dateTimeFormat, true).isValid()) {
      result = moment(textDate).format(dateTimeFormat);
    }
    return result;
  };

  return <DateInput {...props} timeFormat={timeFormat} formatSourceDate={formatDate} />;
};

export default DateTimeInput;
