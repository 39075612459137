import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { OPPORTUNITY_PROJECT_TYPE } from 'crm/opportunity/presale/output/shared/presaleOutputService';
import BigFormRow from 'shared/layout/form/BigFormRow';
import RequiredResourcesListInput from 'crm/opportunity/presale/output/shared/input/RequiredResourcesListInput';
import RequiredResourcesInputTemplate from 'crm/opportunity/presale/output/shared/input/RequiredResourcesInputTemplate';
import { Checkbox } from 'shared/uibuilder/form/input';

const PresaleOutputInputForm = ({ inputErrors }: { inputErrors?: Dictionary<any> }) => {
  return (
    <>
      <FormSection title="Last Presale Output Info">
        <FormRow>
          <DateInput
            source="expectedProjectStart"
            label="Expected Start"
            helpText="Should be in the future. Please, mark as valid before submit!"
          />
          <Checkbox source="isValidExpectedProjectStart" label="Valid?" />
        </FormRow>
        <FormRow>
          <EnumDropdown
            options={OPPORTUNITY_PROJECT_TYPE}
            source="projectType"
            label="Project Type"
            placeholder="Select..."
            helpText="Please, mark as valid before submit!"
          />
          <Checkbox source="isValidProjectType" label="Valid?" />
        </FormRow>
      </FormSection>
      <FormSection title="Team structure">
        <BigFormRow>
          <RequiredResourcesListInput
            source="requiredResources"
            addText="Add Member"
            maxFields={30}
            inputTemplate={RequiredResourcesInputTemplate}
            helpText="Please, mark as valid before submit!"
          />
        </BigFormRow>
        <FormRow>
          <Checkbox source="isValidRequiredResources" label="Valid?" />
        </FormRow>
      </FormSection>
    </>
  );
};

export default PresaleOutputInputForm;
