/* istanbul ignore file */
import { Routes } from 'react-router-dom';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import React from 'react';
import { READ_LEAVES_BALANCES } from 'erp/employee/leavebalance/shared/balancesService';
import EmployeePaidLeavesBalancesList from 'erp/employee/leavebalance/BalancesList';

const BALANCES_PATH = 'leaves';

export const useLeavesBalancesRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();

  return useRoute({
    listRoute: `${employeeRoute}/leaves`,
  });
};

export const useLeavesBalancesUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();
  return useEntityUrl({
    baseLink: `${employeeLink}/${BALANCES_PATH}`,
  });
};

const LeaveBalanceRouter = () => {
  const { listRoute } = useLeavesBalancesRoute();

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <ProtectedRoute permissionToCheck={READ_LEAVES_BALANCES}>
            <EmployeePaidLeavesBalancesList />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default LeaveBalanceRouter;
