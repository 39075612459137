/* istanbul ignore file */
import React from 'react';
import { TextArea } from 'shared/uibuilder/form/input';
import FormRow from 'shared/layout/form/FormRow';
import {
  RELOCATION_OPTIONS,
  VACANCY_EMPLOYEE_CONTRACT_TYPES_NO_INTERNSHIP,
  VACANCY_HAS_PROBATION_OPTIONS,
  WORKING_SCHEDULE,
} from 'erp/recruitment/vacancy/shared/vacancyService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { get } from 'lodash';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import VacancyOfficeDropdown from 'erp/recruitment/vacancy/shared/input/VacancyOfficeDropdown';
import { EMPLOYEE_CONTRACT_ASSIGMENT_TYPES } from 'erp/employee/contracts/shared/input/EmployeeContractAssignmentTypeRadios';
import { EMPLOYEE_WORKPLACE_LOCATION } from 'erp/employee/employeeService';

interface VacancyQualificationInputProps {
  source: string;
}

const VacancyDetailsInput = ({ source, ...props }: VacancyQualificationInputProps) => {
  return (
    <div className="vacancy-details-field__wrapper">
      <FormRow>
        <VacancyOfficeDropdown source={`${source}.office`} label="Location (Office)" isAnywhereAvailable />
      </FormRow>
      <FormRow>
        <EnumDropdown
          options={EMPLOYEE_CONTRACT_ASSIGMENT_TYPES}
          source={`${source}.assignmentType`}
          label="Employment Type"
          multiple
          placeholder="Choose Employement Types"
        />
        <EnumDropdown
          source={`${source}.relocationOption`}
          label="Relocation Option"
          options={RELOCATION_OPTIONS}
          placeholder="Choose Relocation Option"
        />
        <EnumDropdown
          source={`${source}.hasProbation`}
          label="Probation"
          options={VACANCY_HAS_PROBATION_OPTIONS}
          placeholder="Choose Option"
        />
      </FormRow>
      <FormRow>
        <EnumDropdown
          options={VACANCY_EMPLOYEE_CONTRACT_TYPES_NO_INTERNSHIP}
          source={`${source}.contractType`}
          label="Contract Type"
          multiple
          placeholder="Choose Contract Types"
        />
        <EnumDropdown
          options={EMPLOYEE_WORKPLACE_LOCATION}
          source={`${source}.workplaceLocation`}
          label="Working Mode"
          multiple
          placeholder="Choose Working Modes"
        />
        <EnumDropdown
          source={`${source}.workingSchedule`}
          label="Working Schedule"
          options={WORKING_SCHEDULE}
          placeholder="Choose Working Schedule"
        />
        <ClearAfterUnmountWrapper isVisible={vacancy => get(vacancy, source).workingSchedule === 'NON_STANDARD'}>
          <TextArea rows={2} source={`${source}.workingScheduleDescription`} />
        </ClearAfterUnmountWrapper>
      </FormRow>
    </div>
  );
};

export default VacancyDetailsInput;
