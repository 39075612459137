import { useKernelApi } from 'api';
import skillBasedQueueValidation from 'erp/skillbasedqueue/createupdate/skillBasedQueueValidation';
import useBaseCrudService, { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import { FormFieldsData } from '../../../shared/uibuilder/form/FormContext';

const SKILL_BASED_QUEUE_API_PATH = '/skillBasedQueues';

export const READ_SKILL_BASED_QUEUES_LIST = 'SKILL_BASED_QUEUE_READ_LIST';
export const READ_SKILL_BASED_QUEUE = 'READ';
export const CREATE_SKILL_BASED_QUEUE = 'SKILL_BASED_QUEUE_CREATE';
export const UPDATE_SKILL_BASED_QUEUE = 'UPDATE';
export const DELETE_SKILL_BASED_QUEUE = 'DELETE';

const useSkillBasedQueueService = () => {
  const singleResourceUrl = `${SKILL_BASED_QUEUE_API_PATH}/:id`;
  const { sendPatchRequest } = useKernelApi();
  const {
    search: baseSearch,
    update: baseUpdate,
    ...baseCrudRequests
  } = useBaseCrudService({
    singleResourceUrl,
    listResourceUrl: SKILL_BASED_QUEUE_API_PATH,
    apiService: useKernelApi,
  });

  const getValidationSchema = () => Promise.resolve(skillBasedQueueValidation);

  const search = (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    const searchString = searchParams.filter?.['specification:search']?.eq || '';

    if (searchString && searchParams.filter) {
      delete searchParams.filter?.['specification:search'];
      searchParams.filter['skill.name'] = {
        ct: searchString,
      };
    }

    return baseSearch(searchParams);
  };

  const update = async (skillBasedQueueId: StringOrNumber, data: FormFieldsData) => {
    const result = await sendPatchRequest(`${SKILL_BASED_QUEUE_API_PATH}/${skillBasedQueueId}`, data);
    return result.json();
  };

  return {
    getValidationSchema,
    ...baseCrudRequests,
    search,
    update,
  };
};

export default useSkillBasedQueueService;
