/* istanbul ignore file */
// libs
import React from 'react';
// components
import Show from 'shared/uibuilder/show/Show';
// services
import useContactService from 'crm/contact/shared/contactService';
import { useContactId } from 'crm/contact/ContactRouter';

const ShowContact = ({ children = null }: { children?: React.ReactNode }) => {
  const id = useContactId();
  const { getById } = useContactService();

  return <Show getDataMethod={() => getById(id)}>{children}</Show>;
};

export default ShowContact;
