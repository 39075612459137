import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useRecruitingDashboardUrl } from 'erp/recruitment/dashboard/RecruitingDashboardRouter';

const HiringBreadcrumbs = ({ children }: any) => {
  const { getListUrl } = useRecruitingDashboardUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Hiring requests" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default HiringBreadcrumbs;
