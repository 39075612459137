import { SET_HEADER_MENU, HeaderMenuItemProps } from 'shared/uibuilder/menu/HeaderMenu/headerMenuService';
import { Action } from 'redux';

export interface HeaderMenuState {
  menuData?: HeaderMenuItemProps[];
  pathname?: string;
}

interface HeaderMenuActions extends Action {
  payload: HeaderMenuState;
}

const initialState: HeaderMenuState[] = [];

export default (state = initialState, action: HeaderMenuActions = {} as HeaderMenuActions) => {
  if (action.type === SET_HEADER_MENU) {
    const pathname = action?.payload?.pathname;
    const newState = [...state.filter(m => m.pathname !== pathname), action.payload];

    return newState;
  }

  return state;
};
