import CtCondition from 'shared/uibuilder/list/filter/condition/CtCondition';
import React from 'react';
import CandidateSpecializationDropdown from 'erp/candidate/shared/CandidateSpecializationDropdown';

type CandidateSpecializationFilterProps = {
  source: string;
  label: string;
};

const CandidateSpecializationsFilter = ({ source, label } : CandidateSpecializationFilterProps) => {
  return <CtCondition source={source}>
    <CandidateSpecializationDropdown label={label}/>
  </CtCondition>
};

export default CandidateSpecializationsFilter;
