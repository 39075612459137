import { useBusinessEngineApi, usePrivilegedAccessApi } from 'api';
import { ResourceId } from 'shared/crud/baseCrudService';
import useCreateRedmineProjectResponseHandler from 'erp/project/redmineproject/useCreateRedmineProjectResponseHandler';
import useFeatureToggle, { Features } from 'featuretoggle';
import useCreateTeamsResponseHandler from 'erp/project/redmineproject/useCreateTeamsResponseHandler';
import useCreateGitlabResponseHandler from 'erp/project/redmineproject/useCreateGitlabResponseHandler';
import useCreateSharepointResponseHandler from 'erp/project/redmineproject/useCreateSharepointResponseHandler';
import { StatusContext } from 'privileged/externalaccounts/creation/accountsCreationResponseHandler';
import useResponseMessageHelper from 'erp/project/redmineproject/useResponseMessageHelper';
import redmineProjectValidation from './redmineProjectValidation';
import { useState } from 'react';
import useCreateOnePasswordResponseHandler from './useCreateOnePasswordResponseHandler';
import useCreateAzureGroupsResponseHandler from './useCreateAzureGroupsResponseHandler';

export const CREATE_REDMINE_PROJECT = 'CREATE_REDMINE_PROJECT';

export const STEPS = {
  VALIDATION: 'VALIDATION',
  CREATION_REDMINE_PROJECT: 'CREATION_REDMINE_PROJECT',
};

const useRedmineProjectService = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const isProjectInfrastructureEnabled = isFeatureEnabled(Features.PROJECT_INFRASTRUCTURE_PAM);
  const { sendGetRequest } = useBusinessEngineApi();
  const { sendPostRequest } = usePrivilegedAccessApi();
  const { handleCreateProjectResponse } = useCreateRedmineProjectResponseHandler();
  const { handleTeamsResponse } = useCreateTeamsResponseHandler();
  const { handleGitlabResponse } = useCreateGitlabResponseHandler();
  const { handleSharepointResponse } = useCreateSharepointResponseHandler();
  const { handleOnePasswordResponse } = useCreateOnePasswordResponseHandler();
  const { handleAzureGroupsResponse } = useCreateAzureGroupsResponseHandler();
  const { handleStatusContext } = useResponseMessageHelper();
  const [serviceManager, setServiceManager] = useState({
    step: STEPS.VALIDATION,
    isValidationPassed: false,
    isProjectCreated: false,
    projectResponse: {},
    isTeamsCreated: false,
    teamsResponse: {},
    isGitlabCreated: false,
    gitlabResponse: {},
    isSharepointCreated: false,
    sharepointResponse: {},
    isOnePasswordCreated: false,
    onePasswordResponse: {},
    isAzureGroupsCreated: false,
    azureGroupsResponse: {},
  });

  const setStep = (newValue: any) => {
    serviceManager.step = newValue;
  };
  const setIsValidationPassed = (newValue: boolean) => {
    serviceManager.isValidationPassed = newValue;
  };
  const setIsProjectCreated = (newValue: boolean) => {
    serviceManager.isProjectCreated = newValue;
  };
  const setProjectResponse = (newValue: any) => {
    serviceManager.projectResponse = newValue;
  };
  const setIsTeamsCreated = (newValue: boolean) => {
    serviceManager.isTeamsCreated = newValue;
  };
  const setTeamsResponse = (newValue: any) => {
    serviceManager.teamsResponse = newValue;
  };
  const setIsGitlabCreated = (newValue: boolean) => {
    serviceManager.isGitlabCreated = newValue;
  };
  const setGitlabResponse = (newValue: any) => {
    serviceManager.gitlabResponse = newValue;
  };
  const setIsSharepointCreated = (newValue: boolean) => {
    serviceManager.isSharepointCreated = newValue;
  };
  const setSharepointResponse = (newValue: any) => {
    serviceManager.sharepointResponse = newValue;
  };
  const setIsOnePasswordCreated = (newValue: boolean) => {
    serviceManager.isOnePasswordCreated = newValue;
  };
  const setOnePasswordResponse = (newValue: any) => {
    serviceManager.onePasswordResponse = newValue;
  };
  const setIsAzureGroupsCreated = (newValue: boolean) => {
    serviceManager.isAzureGroupsCreated = newValue;
  };
  const setAzureGroupsResponse = (newValue: any) => {
    serviceManager.azureGroupsResponse = newValue;
  };

  const getPrefilledData = async (id: ResourceId) => {
    const response = await sendGetRequest(`/projects/${id}/redmine`);
    return response.json();
  };

  const create = async (request: any) => {
    const mappedRequest = {
      ...request,
      parentName: request.accountName || request.parentName,
    };
    const statusContext: StatusContext = {
      successes: [],
      errors: [],
      instructions: [],
    };

    if (serviceManager.step === STEPS.VALIDATION && !serviceManager.isValidationPassed) {
      await validationStep(mappedRequest);
    }
    if (serviceManager.step === STEPS.CREATION_REDMINE_PROJECT) {
      if (serviceManager.isValidationPassed && !serviceManager.isProjectCreated) {
        await creationStep(mappedRequest, statusContext);
      } else {
        handleCreateProjectResponse(serviceManager.projectResponse, statusContext);
      }
    }

    if (isProjectInfrastructureEnabled) {
      if (serviceManager.isProjectCreated && !serviceManager.isTeamsCreated) {
        await createTeams(mappedRequest, statusContext);
      } else {
        handleTeamsResponse(serviceManager.teamsResponse, statusContext);
      }
      if (serviceManager.isProjectCreated && !serviceManager.isGitlabCreated) {
        await createGitlab(mappedRequest, statusContext);
      } else {
        handleGitlabResponse(serviceManager.gitlabResponse, statusContext);
      }
      if (serviceManager.isProjectCreated && !serviceManager.isSharepointCreated) {
        await createSharepoint(mappedRequest, statusContext);
      } else {
        handleSharepointResponse(serviceManager.sharepointResponse, statusContext);
      }
      if (serviceManager.isProjectCreated && !serviceManager.isOnePasswordCreated) {
        await createOnePassword(mappedRequest, statusContext);
      } else {
        handleOnePasswordResponse(serviceManager.onePasswordResponse, statusContext);
      }
      if (serviceManager.isProjectCreated && !serviceManager.isAzureGroupsCreated) {
        await createAzureGroups(mappedRequest, statusContext);
      } else {
        handleAzureGroupsResponse(serviceManager.azureGroupsResponse, statusContext);
      }
    }
    setServiceManager(serviceManager);
    handleStatusContext(statusContext);
  };

  const validationStep = async (request: any) => {
    const mappedRequest = {
      ...request,
      step: STEPS.VALIDATION,
    };
    const response = await sendPostRequest(`/redmine/project`, mappedRequest);
    const body = await response.json();
    setStep(STEPS.CREATION_REDMINE_PROJECT);
    if (body.validationPassed) {
      setIsValidationPassed(true);
    }

    return body;
  };

  const creationStep = async (request: any, statusContext: StatusContext) => {
    const mappedRequest = {
      ...request,
      step: STEPS.CREATION_REDMINE_PROJECT,
    };
    const response = await sendPostRequest(`/redmine/project`, mappedRequest);
    const body = await response.json();

    if (body?.projectCreationStatus?.status === 'OK') {
      setIsProjectCreated(true);
      setProjectResponse(body);
    }
    handleCreateProjectResponse(body, statusContext);
    return body;
  };

  const createTeams = async (request: any, statusContext: StatusContext) => {
    const mappedRequest = {
      projectCodeName: request.teamsProjectCodeName,
      projectId: request.davinciProjectId,
      existedTeamsWebUrl: request.existedTeamsWebUrl,
    };
    const response = await sendPostRequest(`/azure/teams`, mappedRequest);
    const body = await response.json();

    if (body?.teamsCreationStatus?.isCreated) {
      setIsTeamsCreated(true);
      setTeamsResponse(body);
    }

    handleTeamsResponse(body, statusContext);
    return body;
  };

  const createSharepoint = async (request: any, statusContext: StatusContext) => {
    const mappedRequest = {
      projectCodeName: request.teamsProjectCodeName,
      projectId: request.davinciProjectId,
      existedSharepointWebUrl: request.existedSharepointWebUrl,
    };
    const response = await sendPostRequest(`/azure/sharepoint`, mappedRequest);
    const body = await response.json();

    if (body?.sharepointCreationStatus?.isCreated) {
      setIsSharepointCreated(true);
      setSharepointResponse(body);
    }
    handleSharepointResponse(body, statusContext);

    return body;
  };

  const createGitlab = async (request: any, statusContext: StatusContext) => {
    const mappedRequest = {
      accountAlias: request.gitlabGroupAccountAlias,
      projectCodeName: request.teamsProjectCodeName,
      projectId: request.davinciProjectId,
      existedGitlabWebUrl: request.existedGitlabWebUrl,
    };
    const response = await sendPostRequest(`/azure/gitlab`, mappedRequest);
    const body = await response.json();

    if (body?.projectGroupCreationStatus?.isCreated) {
      setIsGitlabCreated(true);
      setGitlabResponse(body);
    }
    handleGitlabResponse(body, statusContext);

    return body;
  };

  const createOnePassword = async (request: any, statusContext: StatusContext) => {
    const mappedRequest = {
      projectCodeName: request.name,
      projectId: request.davinciProjectId,
      needToCreateOnePassword: request.isNeedToCreateOnePassword,
    };
    const response = await sendPostRequest(`/project/one-password`, mappedRequest);
    const body = await response.json();
    const isAzureGroupsCreated = body?.groupsStepStatus?.status === 'SUCCESS';
    const isVaultsCreated = body?.vaultStepStatus?.status === 'SUCCESS';
    const isAccessProvisioned = body?.accessStepStatus?.status === 'SUCCESS';
    if (isAzureGroupsCreated && isVaultsCreated && isAccessProvisioned) {
      setIsOnePasswordCreated(true);
      setOnePasswordResponse(body);
    }
    handleOnePasswordResponse(body, statusContext);

    return body;
  };

  const createAzureGroups = async (request: any, statusContext: StatusContext) => {
    const mappedRequest = {
      projectCodeName: request.name,
      projectId: request.davinciProjectId,
    };
    const response = await sendPostRequest(`/project/azure-groups`, mappedRequest);
    const body = await response.json();
    const isAzureGroupsCreated = body?.groupsStepStatus?.status === 'SUCCESS';
    if (isAzureGroupsCreated) {
      setIsAzureGroupsCreated(true);
      setAzureGroupsResponse(body);
    }
    handleAzureGroupsResponse(body, statusContext);
  };

  const getValidationSchema = async () => {
    return Promise.resolve(redmineProjectValidation);
  };

  return {
    getPrefilledData,
    create,
    getValidationSchema,
  };
};

export default useRedmineProjectService;
