/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import React from 'react';
import { Collapsible, LinkField, TextField } from 'shared/uibuilder/field';
import useProjectStatusesService, {
  StatusTypesLabels,
  StatusTypes,
  UPDATE_PROJECT_STATUS,
} from 'erp/project/statuses/ProjectStatusesService';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import StatusPeriodField from 'erp/project/statuses/shared/field/StatusPeriodField';
import TicketsListField from 'erp/project/statuses/shared/field/TicketsListField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import TimelineRecordActions from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordActions';
import FormSection from 'shared/layout/form/FormSection';

const StatusDemoRecordLayout = ({ onEdit, ...props }: any) => {
  const { data: entity } = useShowContext();
  const isSkippedDemo = !!entity.skipReason;
  const { checkIsEditable } = useProjectStatusesService();
  const isEditable = checkIsEditable(new Date(entity.to), 7);

  const buttons = isEditable
    ? [<EditTimelineRecordButton permissionToCheck={UPDATE_PROJECT_STATUS} key="edit" onEdit={onEdit} />]
    : [];

  return (
    <BaseTimelineRecordLayout
      timelineType={isSkippedDemo ? StatusTypesLabels[StatusTypes.SKIPPED] : StatusTypesLabels[StatusTypes.DEMO]}
      buttons={buttons}
      fields={
        isSkippedDemo ? (
          <Collapsible>
            <>
              <StatusPeriodField label="Weekly cycle period" source="from" toSource="to" />
              <b>Demo was skipped</b>
              <TextField label="Reason" source="skipReason" />
            </>
          </Collapsible>
        ) : (
          <Collapsible>
            <>
              <StatusPeriodField label="Weekly cycle period" source="from" toSource="to" />
              <TextField source="deliveryFrequency" label="Delivery frequency" />
              <LinkField source="recordLink" label="Link to the demo record" />
              <TextField source="scopeDescription" label="Scope description" />

              {entity.tickets && entity.tickets.length > 0 && (
                <FormSection title="Delivered work items" titleVariant="h6">
                  {entity.plannedButNotDeliveredWorkItems.length > 0 ||
                  entity.unplannedButDeliveredWorkItems.length > 0 ||
                  entity.unplannedButDeliveredWorkItems.length > 0 ||
                  entity.unplannedAndNotDeliveredWorkItems.length > 0 ? (
                    <>
                      <TicketsListField
                        source="plannedAndDeliveredWorkItems"
                        label="Planned and delivered"
                        isDemoTickets
                        className="planned-delivered"
                      />
                      <TicketsListField
                        source="plannedButNotDeliveredWorkItems"
                        label="Planned but not delivered"
                        isDemoTickets
                        className="planned-undelivered"
                      />
                      <TicketsListField
                        source="unplannedButDeliveredWorkItems"
                        label="Unplanned but delivered"
                        isDemoTickets
                        className="unplanned-delivered"
                      />
                      <TicketsListField
                        source="unplannedAndNotDeliveredWorkItems"
                        label="Unplanned and not delivered"
                        isDemoTickets
                        className="unplanned-undelivered"
                      />
                    </>
                  ) : (
                    <TicketsListField source="tickets" label="" isDemoTickets />
                  )}
                </FormSection>
              )}
            </>
          </Collapsible>
        )
      }
      timelineActions={rest => <TimelineRecordActions {...rest} />}
      {...props}
    />
  );
};

export default StatusDemoRecordLayout;
