/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { ACCOUNT_TYPES } from 'crm/account/accountService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const AccountTypeDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => {
  return <EnumDropdown options={ACCOUNT_TYPES} placeholder="Choose Account Type" {...props} />;
};

export default AccountTypeDropdown;
