import React from 'react';
import { Routes } from 'react-router-dom';
import ProtectedRouter from 'routing/ProtectedRouter';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_VACANCIES_LIST } from '../vacancy/shared/vacancyService';
import { Route } from 'shared/routing';
import ViewVacancyGroup from './show/ViewVacancyGroup';
import { useVacancyGroupRoute, VACANCY_GROUP_PATH } from 'erp/recruitment/newVacancy/routing';

const VacancyGroupRouter = () => {
  const { singleEntityRoute } = useVacancyGroupRoute();

  return (
    <ProtectedRouter basePath={VACANCY_GROUP_PATH}>
      <Routes>
        <Route
          path={singleEntityRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_VACANCIES_LIST}>
              <ViewVacancyGroup />
            </GlobalProtectedRoute>
          }
        />
      </Routes>
    </ProtectedRouter>
  );
};

VacancyGroupRouter.getRouterPath = () => `${VACANCY_GROUP_PATH}/*`;

export default VacancyGroupRouter;
