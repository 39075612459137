import useStorageService from 'shared/uibuilder/storageService';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';

const useRoleService = () => {
  const { findAll: findAllFromApi } = useBaseCrudService({
    singleResourceUrl: '',
    listResourceUrl: '/roles-on-project',
    apiService: useKernelApi,
  });

  const { getDataFromStorage } = useStorageService('roleOnProject', findAllFromApi, '1.0');

  return {
    findAll: getDataFromStorage,
  };
};

export default useRoleService;
