/* istanbul ignore file */
import React from 'react';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useRoute from 'shared/routing/useRoute';
import { EMPLOYEE_PERFORMANCE_URL } from 'erp/employee/performanceEvaluation/employeePerformance/shared/employeePerformanceService';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Routes, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { CREATE_EMPLOYEE_PERFORMANCE, READ_LIST_EMPLOYEE_PERFORMANCE } from 'erp/employee/employeeService';
import { Route } from 'react-router';
import EmployeePerformanceContext from 'erp/employee/performanceEvaluation/employeePerformance/EmployeePerformanceContext';
import { READ, UPDATE } from 'crm/crmService';
import UpdateEmployeePerformancePage from 'erp/employee/performanceEvaluation/employeePerformance/createupdate/Update';
import ShowEmployeePerformancePage from 'erp/employee/performanceEvaluation/employeePerformance/Show/ShowEmployeePerformancePage';
import EmployeePerformanceListPage from 'erp/employee/performanceEvaluation/employeePerformance/List';
import Page404 from 'shared/uibuilder/Page404';
import CreateEmployeePerformancePage from 'erp/employee/performanceEvaluation/employeePerformance/createupdate/Create';

export const EMPLOYEE_PERFORMANCE_ID = 'employeePerformanceId';

export const useEmployeePerformanceRoute = () => {
  return useRoute({
    listRoute: `${EMPLOYEE_PERFORMANCE_URL}`,
    singleEntityRoute: `/:${EMPLOYEE_PERFORMANCE_ID}`,
  });
};

export const useEmployeePerformanceUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${employeeLink}${EMPLOYEE_PERFORMANCE_URL}`,
    idSource: EMPLOYEE_PERFORMANCE_ID,
  });
};

export const useEmployeePerformanceId = () => {
  const params: any = useParams<Dictionary<string>>();
  return params.employeePerformanceId;
};

const EmployeePerformanceRouter = () => {
  const { listRoute, createRoute, updateRoute, singleEntityRoute } = useEmployeePerformanceRoute();
  return (
    <Routes>
      <Route
        path={createRoute}
        element={
          <GlobalProtectedRoute globalPermissionToCheck={CREATE_EMPLOYEE_PERFORMANCE}>
            <CreateEmployeePerformancePage />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={listRoute}
        element={
          <GlobalProtectedRoute path={listRoute} globalPermissionToCheck={READ_LIST_EMPLOYEE_PERFORMANCE}>
            <EmployeePerformanceListPage />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={updateRoute}
        element={
          <EmployeePerformanceContext permissionToCheck={UPDATE}>
            <UpdateEmployeePerformancePage />
          </EmployeePerformanceContext>
        }
      />

      <Route
        path={singleEntityRoute}
        element={
          <EmployeePerformanceContext permissionToCheck={READ}>
            <ShowEmployeePerformancePage />
          </EmployeePerformanceContext>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default EmployeePerformanceRouter;
