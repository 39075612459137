/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import EmployeeEmployeeTypeDropdown from 'erp/employee/shared/input/EmployeeTypeDropdown';
import { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';

const EmployeeTypeFilter = (props: Omit<EnumDropdownProps, 'options'>) => {
  return (
    <DropDownFilter {...props}>
      <EmployeeEmployeeTypeDropdown {...props} />
    </DropDownFilter>
  );
};

export default EmployeeTypeFilter;
