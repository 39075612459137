import React, { useEffect, useState } from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import usePositionService from 'erp/position/positionService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const PositionDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => {
  const { findAll } = usePositionService();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const positions = await findAll();
      const data = positions.map((position: any) => ({
        value: position.id.toString(),
        label: position.name,
      }));
      setOptions(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Dropdown {...props} placeholder="Select position" options={options} />;
};

export default PositionDropdown;
