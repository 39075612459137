/* istanbul ignore file */
export default {
  effectiveDate: {
    type: 'string',
    required: true,
  },
  objectives: {
    type: 'array',
    maxSize: 50,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        name: {
          type: 'string',
          required: true,
        },
        unit: {
          type: 'string',
          required: true,
        },
      },
    },
  },
  categories: {
    type: 'array',
    maxSize: 50,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        name: {
          type: 'string',
          required: true,
        },
      },
    },
  },
};
