import { SET_GLOBAL_PERMISSIONS } from './authorizationActions';
import { Action } from 'redux';
import { PermissionsMap } from '../utils';

interface AuthAction extends Action {
  payload: PermissionsMap;
}

const initialState = {
  globalPermissions: false,
};

const Authorization = (state = initialState, action: AuthAction = {} as AuthAction) => {
  switch (action.type) {
    case SET_GLOBAL_PERMISSIONS:
      return {
        ...state,
        globalPermissions: action.payload,
      };
    default:
      return state;
  }
};

export default Authorization;
