import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { ANYWHERE } from 'shared/uibuilder/form/input/CountryDropdown';

type OfficeAndAccommodationIconsProps = {
  registrations: string[];
  accommodations: string[];
};

const OfficeAndAccommodationIcons = ({ accommodations, registrations }: OfficeAndAccommodationIconsProps) => {
  return (
    <>
      <i className="fa fa-building" style={{ fontSize: '15px', color: '#888' }} aria-hidden="true" />
      {registrations.map((item: string) => (
        <ReactCountryFlag
          key={`registration-${item}`}
          countryCode={item}
          style={{ width: '24px', height: '24px' }}
          svg
        />
      ))}

      <i className="fa fa-map-marker" style={{ fontSize: '15px', color: '#888' }} aria-hidden="true" />
      {accommodations.map((item: string) => (
        <React.Fragment key={`accommodation-${item}`}>
          {item === ANYWHERE.code ? (
            <i className="fa fa-globe" style={{ fontSize: '20px', color: '#5b75c8' }} aria-hidden="true" />
          ) : (
            <ReactCountryFlag countryCode={item} style={{ width: '24px' }} svg />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default OfficeAndAccommodationIcons;
