/* istanbul ignore file */
import React from 'react';
// layout
import { CreateForm } from 'shared/uibuilder/form';
import FormSection from 'shared/layout/form/FormSection';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateEducationBreadcrumbs from 'erp/employee/Education/createupdate/Create/Breadcrumbs';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
// input
import EducationListInput from 'erp/employee/Education/shared/input/EducationListInput';
// service
import { useEducationUrl } from 'erp/employee/Education/EducationRouter';
import useEducationService from 'erp/employee/Education/shared/educationService';
import Prepopulation from 'erp/employee/prepopulation/Prepopulation';
import CreateTitle from 'erp/employee/Education/createupdate/Create/CreateTitle';
import useArtifactService from 'artifact/artifactService';

const CreateEducationPage = () => {
  const { create, getCreateValidationSchema } = useEducationService();
  const { setArtifactsToStore } = useArtifactService();
  const { getListUrl } = useEducationUrl();

  const mapPrepopulationForms = ({ artifacts, info }: any) => {
    setArtifactsToStore(artifacts);
    const result = info.data.educations.map((education: any) => ({
      ...education,
      haveDocument: education.document && !!education.document.type,
    }));

    return { request: result };
  };

  return (
    <CreateForm
      submitFormFunc={create}
      initialData={{
        request: [
          {
            haveDocument: false,
            document: null,
          },
        ],
      }}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The Education has been successfully created.',
      }}
      getValidationSchemaFunc={getCreateValidationSchema}
    >
      <Prepopulation mapResponse={mapPrepopulationForms}>
        <CreatePageLayout
          title={<CreateTitle />}
          breadcrumbs={<CreateEducationBreadcrumbs />}
          entityHeader={<EmployeeEntityHeader />}
          menu={<EmployeeMenu />}
        >
          <FormSection title="Educations" hasAnchorLink>
            <EducationListInput source="request" />
          </FormSection>
        </CreatePageLayout>
      </Prepopulation>
    </CreateForm>
  );
};

export default CreateEducationPage;
