import React from 'react';
import CopyableInput from 'shared/uibuilder/form/input/CopyableInput/index';
import { LocationInput } from 'shared/uibuilder/form/input';

const COPY_SOURCES = ['city', 'country'];

interface LocationInputProps {
  source: string;
  buttonText?: string;
  copySource?: any;
  tooltipMessage?: string;
  countryLabel?: string;
  cityLabel?: string;
  className?: TypeOrFunction<string>;
  label?: string | React.ReactElement;
}

const CopyableLocationInput = ({ copySource, source, ...props }: LocationInputProps) => {
  return (
    <CopyableInput source={source} copySource={copySource} copySourceList={COPY_SOURCES} {...props}>
      <LocationInput source={source} {...props} />
    </CopyableInput>
  );
};

export default CopyableLocationInput;
