/* istanbul ignore file */
import React from 'react';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { SummaryMenuItem } from 'uibuilder/menuitem';
import { useSkillBasedQueueUrl } from 'erp/skillbasedqueue/SkillBasedQueueRouter';

const ViewSkillBasedQueuePageMenu = () => {
  const { getSingleEntityUrl } = useSkillBasedQueueUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl()} />
    </SidebarStickyMenu>
  );
};

export default ViewSkillBasedQueuePageMenu;
