/* istanbul ignore file */
import React from 'react';
import { FieldProps, LinkField } from 'shared/uibuilder/field/index';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useVacancyGroupUrl } from 'erp/recruitment/newVacancy/routing';

const VacancyGroupLinkField = ({ source, nameSource, value, nameValue, getLinkText, ...props }: FieldProps) => {
  const { getValue: getId } = useFieldHelper({ source, value });
  const { getValue: getName } = useFieldHelper({ source: nameSource, value: nameValue });
  const { getSingleEntityUrl } = useVacancyGroupUrl();

  const id = getId();
  const name = getName();
  const linkUrl = id && getSingleEntityUrl(id);
  const linkText = nameSource || nameValue ? name : id;

  return (
    <LinkField {...props} linkText={getLinkText ? getLinkText({ id, name }) : linkText} value={linkUrl as string} />
  );
};

export default VacancyGroupLinkField;
