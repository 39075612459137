/* istanbul ignore file */
import React from 'react';
import { LIST_PAGE_SIZE } from 'shared/uibuilder/list/List';
import SimpleList, { SimpleListProps } from 'shared/uibuilder/list/SimpleList';

const RelatedList = ({ children, pageSize = LIST_PAGE_SIZE, ...props }: SimpleListProps) => {
  return (
    <SimpleList {...props} pageSize={pageSize}>
      {children}
    </SimpleList>
  );
};

export default RelatedList;
