import React from 'react';
import Badge from '../../../../../shared/uibuilder/badge/Badge';
import { VACANCY_STATUS, VACANCY_STATUS_OPTIONS } from '../../constants';

type LabelStatusProps = {
  status: keyof typeof VACANCY_STATUS;
};

const LabelStatus = ({ status }: LabelStatusProps) => {
  const colorFunc = () => {
    if (status === VACANCY_STATUS.OPEN) {
      return 'success';
    }

    if (status === VACANCY_STATUS.REQUIRES_REVIEW || VACANCY_STATUS.CANCELLATION_REQUESTED) {
      return 'warning';
    }

    if (status === VACANCY_STATUS.EXPIRED) {
      return 'error';
    }

    return 'neutral';
  };

  return (
    <Badge sx={{ marginLeft: '0' }} color={colorFunc()}>
      {VACANCY_STATUS_OPTIONS[status]}
    </Badge>
  );
};

export default LabelStatus;
