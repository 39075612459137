/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import AccountsBreadcrumbs from 'crm/account/shared/AccountsBreadcrumbs';
import useAccountUrl from './accountUrl';

const SingleAccountBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useAccountUrl();
  const accountName = data.getValueBySource('accountName');
  const id = data.getValueBySource('accountId');
  const singleAccountUrl = getSingleEntityUrl(id);

  return (
    <AccountsBreadcrumbs>
      <SingleBreadcrumbItem entity={accountName || id} link={singleAccountUrl} />
      {children}
    </AccountsBreadcrumbs>
  );
};

export default SingleAccountBreadcrumbs;
