import React from 'react';
import useKPIService from '../useKPIService';
import ServiceKPIStructureForm from '../Create/ServiceBudgetStructureForm';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';

const UpdateKPIStructure = ({ getDataFunc, entityId, onCancel, afterSubmit }: any) => {
  const { getValidationSchema, updateStructure, serviceCatalogId } = useKPIService();

  return (
    <UpdateForm
      submitFormFunc={async (_id, values) => updateStructure(entityId, serviceCatalogId, values)}
      afterSubmit={afterSubmit}
      getValidationSchemaFunc={getValidationSchema}
      getDataFunc={getDataFunc}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <ServiceKPIStructureForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

export default UpdateKPIStructure;
