/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAccountUrl } from 'crm/account/index';

const AccountsBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useAccountUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Accounts" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default AccountsBreadcrumbs;
