/* istanbul ignore file */
import React from 'react';
import WysiwygInputPasteArtifactDecorator from 'artifact/shared/input/WysiwygInputPasteArtifactDecorator';
import { ARTIFACT_ID } from 'artifact/const';
import { WysiwygInput } from 'shared/uibuilder/form/input';

type CrmNoteWysiwygInputProps = {
  artifactsSource: string;
  source: string;
};

const CrmNoteWysiwygInput = ({ artifactsSource, ...props }: CrmNoteWysiwygInputProps) => {
  return (
    <WysiwygInputPasteArtifactDecorator artifactsSource={artifactsSource} artifactTypeId={ARTIFACT_ID.CRM_ATTACHMENT}>
      <WysiwygInput {...props} />
    </WysiwygInputPasteArtifactDecorator>
  );
};

export default CrmNoteWysiwygInput;
