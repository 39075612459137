import EnumDropdown, { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';
import React from 'react';
import { KPI_TYPES_UNIT_OPTIONS } from '../../useKPIService';

const CategoryUnitDropdown = (props: Omit<EnumDropdownProps, 'options'> & { options?: Dictionary<string> }) => {
  const options = { ...KPI_TYPES_UNIT_OPTIONS };

  return <EnumDropdown options={options} placeholder="Select type" {...props} />;
};

export default CategoryUnitDropdown;
