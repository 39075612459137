import getAzureDomain from 'erp/config/emailConfiguration';

const domainName = getAzureDomain();
const emailPattern = `^.+@((disabled)?\\d+\\.)?${domainName}$`;

/* istanbul ignore file */
export default {
  nameLocal: {
    type: 'object',
    required: {
      value: true,
      when: [
        {
          $_PERMISSIONS: {
            ct: 'UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO',
          },
        },
        {
          $_IS_CREATE_REQUEST: {
            eq: true,
          },
        },
      ],
    },
    _divisible: false,
    properties: {
      firstName: {
        type: 'string',
        required: true,
      },
      middleName: {
        type: 'string',
      },
      lastName: {
        type: 'string',
        required: true,
      },
      maidenName: {
        type: 'string',
        maxSize: 48,
      },
    },
  },

  nameEn: {
    type: 'object',
    required: {
      value: true,
      when: [
        {
          $_PERMISSIONS: {
            ct: 'UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO',
          },
        },
        {
          $_IS_CREATE_REQUEST: {
            eq: true,
          },
        },
      ],
    },
    _divisible: false,
    properties: {
      firstName: {
        type: 'string',
        required: true,
        regex: {
          value: '^[a-zA-Z-]+$',
          message: 'Only latin characters and hyphen',
        },
      },
      middleName: {
        type: 'string',
        regex: {
          value: '^[a-zA-Z-]+$',
          message: 'Only latin characters and hyphen',
        },
      },
      lastName: {
        type: 'string',
        required: true,
        regex: {
          value: '^[a-zA-Z-]+$',
          message: 'Only latin characters and hyphen',
        },
      },
      maidenName: {
        type: 'string',
        maxSize: 48,
        regex: {
          value: '^[a-zA-Z-]+$',
          message: 'Only latin characters and hyphen',
        },
      },
    },
  },

  gender: {
    type: 'enum',
    required: {
      value: true,
      when: [
        {
          $_PERMISSIONS: {
            ct: 'UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO',
          },
        },
        {
          $_IS_CREATE_REQUEST: {
            eq: true,
          },
        },
      ],
    },
  },
  resourcePoolId: {
    type: 'enum',
    required: {
      value: true,
      when: [
        {
          $_PERMISSIONS: {
            ct: 'UPDATE_EMPLOYEE_MAIN_HR_INFORMATION',
          },
        },
        {
          $_IS_CREATE_REQUEST: {
            eq: true,
          },
        },
      ],
    },
  },
  positionId: {
    type: 'enum',
    required: {
      value: true,
      when: [
        {
          $_PERMISSIONS: {
            ct: 'UPDATE_EMPLOYEE_MAIN_HR_INFORMATION',
          },
        },
        {
          $_IS_CREATE_REQUEST: {
            eq: true,
          },
        },
      ],
    },
  },

  extendedInformation: {
    type: 'object',
    required: true,
    properties: {
      photosIds: {
        type: 'array',
        maxSize: 3,
        required: {
          value: true,
          when: [
            {
              $initializationState: {
                eq: 'FINALIZED',
              },
              $_PERMISSIONS: {
                ct: 'UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO',
              },
            },
            {
              $_PERMISSIONS: {
                ct: 'UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO',
              },
              $employeeType: {
                eq: 'CONTRACTOR',
              },
            },
            {
              $_IS_CREATE_REQUEST: {
                eq: true,
              },
              $employeeType: {
                eq: 'CONTRACTOR',
              },
            },
          ],
        },
      },
      dateOfBirth: {
        type: {
          value: 'date',
          message: 'Please, enter a valid date',
        },
        required: {
          value: true,
          when: [
            {
              $_PERMISSIONS: {
                ct: 'UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO',
              },
            },
            {
              $_IS_CREATE_REQUEST: {
                eq: true,
              },
            },
          ],
        },
        maxDate: {
          value: '$NOW',
          message: 'Must be in the past',
        },
      },
      identityDocuments: {
        type: 'array',
        required: true,
        maxSize: 10,
        minSize: 1,
        forEach: {
          type: 'object',
          _divisible: false,
          properties: {
            type: {
              type: 'enum',
              required: true,
            },
            number: {
              type: {
                value: 'int',
                message: 'Only digits are allowed',
              },
              required: true,
              regex: {
                value: '^[0-9]{11}$',
                when: {
                  '$.type': {
                    eq: 'PESEL',
                  },
                },
                message: 'Invalid PESEL number. The format is 11 digits number',
              },
              maxSize: [
                {
                  value: 20,
                  when: {
                    '$.type': {
                      ne: 'PESEL',
                    },
                  },
                },
              ],
            },
            expiredAt: {
              required: {
                value: true,
                when: [
                  {
                    '$.type': {
                      ne: 'PESEL',
                    },
                    $_PERMISSIONS: {
                      ct: 'UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS',
                    },
                  },
                  {
                    '$.type': {
                      ne: 'PESEL',
                    },
                    $_IS_CREATE_REQUEST: {
                      eq: true,
                    },
                  },
                ],
              },
              type: {
                value: 'date',
                message: 'Please, enter a valid date',
              },
              minDate: '$NOW',
            },
            scansIds: {
              type: 'array',
              maxSize: 5,
              required: {
                value: true,
                when: [
                  {
                    '$.type': {
                      ne: 'PESEL',
                    },
                    $_PERMISSIONS: {
                      ct: 'UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS',
                    },
                  },
                  {
                    '$.type': {
                      ne: 'PESEL',
                    },
                    $_IS_CREATE_REQUEST: {
                      eq: true,
                    },
                  },
                ],
              },
            },
            documentDetails: {
              type: 'object',
              properties: {
                series: {
                  type: 'string',
                  required: {
                    value: true,
                    when: [
                      {
                        '$.type': {
                          in: ['PASSPORT', 'RESIDENCE_PERMIT'],
                        },
                        $_PERMISSIONS: {
                          ct: 'UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS',
                        },
                      },
                      {
                        '$.type': {
                          in: ['PASSPORT', 'RESIDENCE_PERMIT'],
                        },
                        $_IS_CREATE_REQUEST: {
                          eq: true,
                        },
                      },
                    ],
                  },
                  maxSize: {
                    value: 8,
                    message: 'Max length is 8 symbols',
                  },
                },
                citizenship: {
                  type: 'enum',
                  required: true,
                },
                givenName: {
                  type: 'string',
                  required: {
                    value: true,
                    when: [
                      {
                        '$.type': {
                          ne: 'PESEL',
                        },
                        $_PERMISSIONS: {
                          ct: 'UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS',
                        },
                      },
                      {
                        '$.type': {
                          ne: 'PESEL',
                        },
                        $_IS_CREATE_REQUEST: {
                          eq: true,
                        },
                      },
                    ],
                  },
                  maxSize: {
                    value: 52,
                    message: 'Max length is 52 symbols',
                  },
                  regex: {
                    value: '^[a-zA-Z-]+$',
                    message: 'Only latin characters and hyphen',
                  },
                },
                surname: {
                  type: 'string',
                  required: {
                    value: true,
                    when: [
                      {
                        '$.type': {
                          ne: 'PESEL',
                        },
                        $_PERMISSIONS: {
                          ct: 'UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS',
                        },
                      },
                      {
                        '$.type': {
                          ne: 'PESEL',
                        },
                        $_IS_CREATE_REQUEST: {
                          eq: true,
                        },
                      },
                    ],
                  },
                  maxSize: {
                    value: 52,
                    message: 'Max length is 52 symbols',
                  },
                  regex: {
                    value: '^[a-zA-Z-]+$',
                    message: 'Only latin characters and hyphen',
                  },
                },
                issuedBy: {
                  required: {
                    value: true,
                    when: [
                      {
                        '$.type': {
                          ne: 'PESEL',
                        },
                        $_PERMISSIONS: {
                          ct: 'UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS',
                        },
                      },
                      {
                        '$.type': {
                          ne: 'PESEL',
                        },
                        $_IS_CREATE_REQUEST: {
                          eq: true,
                        },
                      },
                    ],
                  },
                  type: 'string',
                  maxSize: {
                    value: 64,
                    message: 'Max length is 64 symbols',
                  },
                },
                issuedAt: {
                  required: {
                    value: true,
                    when: [
                      {
                        '$.type': {
                          ne: 'PESEL',
                        },
                        $_PERMISSIONS: {
                          ct: 'UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS',
                        },
                      },
                      {
                        '$.type': {
                          ne: 'PESEL',
                        },
                        $_IS_CREATE_REQUEST: {
                          eq: true,
                        },
                      },
                    ],
                  },
                  type: {
                    value: 'date',
                    message: 'Please, enter a valid date',
                  },
                  maxDate: '$NOW',
                },
                personalIdNumber: {
                  type: 'string',
                  required: {
                    value: true,
                    when: [
                      {
                        '$.type': {
                          ne: 'PESEL',
                        },
                        $_PERMISSIONS: {
                          ct: 'UPDATE_EMPLOYEE_IDENTITY_DOCUMENTS',
                        },
                      },
                      {
                        '$.type': {
                          ne: 'PESEL',
                        },
                        $_IS_CREATE_REQUEST: {
                          eq: true,
                        },
                      },
                    ],
                  },
                  maxSize: 32,
                  regex: {
                    value: '^[^ ]+$',
                    message: 'Spaces are not allowed',
                  },
                },
              },
            },
          },
        },
      },
      personalPhoneNumber: {
        type: 'string',
        phone: true,
        required: {
          value: true,
          when: [
            {
              $_PERMISSIONS: {
                ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
              },
            },
            {
              $_IS_CREATE_REQUEST: {
                eq: true,
              },
            },
          ],
        },
      },
      otherPhoneNumber: {
        type: 'string',
        phone: true,
      },
      personalEmail: {
        type: 'string',
        email: true,
        maxSize: 255,
        required: {
          value: true,
          when: [
            {
              $_PERMISSIONS: {
                ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
              },
            },
            {
              $_IS_CREATE_REQUEST: {
                eq: true,
              },
            },
          ],
        },
      },

      homeAddress: {
        type: 'object',
        required: {
          value: true,
          when: {
            $initializationState: {
              eq: 'FINALIZED',
            },
            $_PERMISSIONS: {
              ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
            },
            $employeeType: {
              ne: 'CONTRACTOR',
            },
          },
        },
        properties: {
          country: {
            type: 'enum',
            required: {
              value: true,
              when: {
                $initializationState: {
                  eq: 'FINALIZED',
                },
                $_PERMISSIONS: {
                  ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
                },
                $employeeType: {
                  ne: 'CONTRACTOR',
                },
              },
            },
          },
          city: {
            type: 'string',
            required: {
              value: true,
              when: {
                $initializationState: {
                  eq: 'FINALIZED',
                },
                $_PERMISSIONS: {
                  ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
                },
                $employeeType: {
                  ne: 'CONTRACTOR',
                },
              },
            },
            maxSize: 64,
          },
          zipCode: {
            type: 'string',
            required: {
              value: true,
              when: {
                $initializationState: {
                  eq: 'FINALIZED',
                },
                $_PERMISSIONS: {
                  ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
                },
                $employeeType: {
                  ne: 'CONTRACTOR',
                },
              },
            },
            maxSize: 64,
          },
          addressLine1: {
            type: 'string',
            required: {
              value: true,
              when: {
                $initializationState: {
                  eq: 'FINALIZED',
                },
                $_PERMISSIONS: {
                  ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
                },
                $employeeType: {
                  ne: 'CONTRACTOR',
                },
              },
            },
            maxSize: 64,
          },
          addressLine2: {
            type: 'string',
            maxSize: 64,
          },
        },
      },

      registrationAddress: {
        type: 'object',
        required: {
          value: true,
          when: [
            {
              $_PERMISSIONS: {
                ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
              },
            },
            {
              $_IS_CREATE_REQUEST: {
                eq: true,
              },
            },
          ],
        },
        properties: {
          country: {
            type: 'enum',
            required: {
              value: true,
              when: [
                {
                  $_PERMISSIONS: {
                    ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
                  },
                },
                {
                  $_IS_CREATE_REQUEST: {
                    eq: true,
                  },
                },
              ],
            },
          },
          city: {
            type: 'string',
            required: {
              value: true,
              when: [
                {
                  $_PERMISSIONS: {
                    ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
                  },
                },
                {
                  $_IS_CREATE_REQUEST: {
                    eq: true,
                  },
                },
              ],
            },
            maxSize: 64,
          },
          zipCode: {
            type: 'string',
            required: {
              value: true,
              when: [
                {
                  $_PERMISSIONS: {
                    ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
                  },
                },
                {
                  $_IS_CREATE_REQUEST: {
                    eq: true,
                  },
                },
              ],
            },
            maxSize: 64,
          },
          addressLine1: {
            type: 'string',
            required: {
              value: true,
              when: [
                {
                  $_PERMISSIONS: {
                    ct: 'UPDATE_EMPLOYEE_CONTACT_INFORMATION',
                  },
                },
                {
                  $_IS_CREATE_REQUEST: {
                    eq: true,
                  },
                },
              ],
            },
            maxSize: 64,
          },
          addressLine2: {
            type: 'string',
            maxSize: 64,
          },
        },
      },

      plannedMidTermLocation: {
        type: 'object',
        properties: {
          country: {
            type: 'enum',
          },
          city: {
            type: 'string',
            maxSize: 32,
          },
        },
      },

      plannedPermLocation: {
        type: 'object',
        properties: {
          country: {
            type: 'enum',
          },
          city: {
            type: 'string',
            maxSize: 32,
          },
        },
      },

      locationUpdateDate: {
        type: {
          value: 'date',
          message: 'Please, enter a valid date',
        },
        maxDate: {
          value: '$NOW',
          message: 'Must be in the past',
        },
      },

      additionalContactInfo: {
        type: 'array',
        maxSize: 5,
        forEach: {
          type: 'object',
          _divisible: false,
          properties: {
            type: {
              required: true,
              maxSize: 128,
            },
            value: {
              required: true,
              maxSize: 255,
            },
          },
        },
      },

      other: {
        type: 'array',
        maxSize: 10,
        forEach: {
          type: 'object',
          _divisible: false,
          properties: {
            dateOfBirth: {
              type: {
                value: 'date',
                message: 'Please, enter a valid date',
              },
              required: true,
              maxDate: {
                value: '$NOW',
                message: 'Must be in the past',
              },
            },
            relation: {
              type: 'string',
              required: true,
              maxSize: 24,
            },
            name: {
              type: 'object',
              _divisible: false,
              properties: {
                firstName: {
                  type: 'string',
                  required: true,
                  maxSize: 64,
                },
                lastName: {
                  type: 'string',
                  required: true,
                  maxSize: 64,
                },
                middleName: {
                  type: 'string',
                  maxSize: 64,
                },
              },
            },
          },
        },
      },

      children: {
        type: 'array',
        maxSize: 10,
        required: {
          value: true,
          when: {
            $haveChildren: {
              eq: 'YES',
            },
          },
          message: 'You must add at least one child or select No option',
        },
        forEach: {
          type: 'object',
          _divisible: false,
          properties: {
            dateOfBirth: {
              type: {
                value: 'date',
                message: 'Please, enter a valid date',
              },
              required: true,
              maxDate: {
                value: '$NOW',
                message: 'Must be in the past',
              },
            },
            relation: {
              type: 'string',
              required: true,
              maxSize: 24,
            },
            name: {
              type: 'object',
              _divisible: false,
              properties: {
                firstName: {
                  type: 'string',
                  required: true,
                  maxSize: 64,
                },
                lastName: {
                  type: 'string',
                  required: true,
                  maxSize: 64,
                },
                middleName: {
                  type: 'string',
                  maxSize: 64,
                },
              },
            },
          },
        },
      },

      hrInformation: {
        type: 'object',
        _divisible: false,
        properties: {
          workplaceLocation: {
            type: 'string',
            maxSize: 255,
            required: true,
          },
          roomNumber: {
            type: 'string',
            maxSize: 8,
          },
          deskLocation: {
            type: 'string',
            maxSize: 48,
          },
          accessCardId: {
            type: 'string',
            maxSize: 12,
          },
        },
      },

      militaryRecord: {
        type: 'object',
        _divisible: false,
        properties: {
          registrationGroup: {
            type: 'string',
            maxSize: 24,
          },
          registrationCategory: {
            type: 'enum',
          },
          militaryContingent: {
            type: 'enum',
          },
          title: {
            type: 'string',
            maxSize: 24,
          },
          specialization: {
            type: 'string',
            maxSize: 24,
          },
          militarySuitability: {
            type: 'string',
            maxSize: 24,
          },
          militaryCommissariat: {
            type: 'string',
            maxSize: 64,
          },
          registrationNumber: {
            type: 'string',
            maxSize: 64,
          },
        },
      },

      financialInformation: {
        type: 'object',
        _divisible: false,
        properties: {
          financialOneCId: {
            type: 'string',
            maxSize: 64,
          },
        },
      },
      requisitesInformation: {
        type: 'object',
        required: {
          value: true,
          when: {
            $employeeType: {
              eq: 'CONTRACTOR',
            },
          },
        },
        _divisible: false,
        properties: {
          requisites: {
            type: 'string',
            maxSize: '60000',
            required: {
              value: true,
              when: [
                {
                  $employeeType: {
                    eq: 'CONTRACTOR',
                  },
                  $_PERMISSIONS: {
                    ct: 'UPDATE_EMPLOYEE_REQUISITES_INFORMATION',
                  },
                },
                {
                  $employeeType: {
                    eq: 'CONTRACTOR',
                  },
                  $_IS_CREATE_REQUEST: {
                    eq: true,
                  },
                },
              ],
            },
          },
          scansIds: {
            type: 'array',
            maxSize: '5',
            required: {
              value: true,
              when: [
                {
                  $employeeType: {
                    eq: 'CONTRACTOR',
                  },
                  $_PERMISSIONS: {
                    ct: 'UPDATE_EMPLOYEE_REQUISITES_INFORMATION',
                  },
                },
                {
                  $employeeType: {
                    eq: 'CONTRACTOR',
                  },
                  $_IS_CREATE_REQUEST: {
                    eq: true,
                  },
                },
              ],
            },
          },
        },
      },
    },
  },

  azureId: {
    type: 'string',
    maxSize: 48,
  },

  workingEmail: {
    type: 'string',
    email: true,
    required: {
      value: true,
      when: [
        {
          $initializationState: {
            eq: 'FINALIZED',
          },
          $_PERMISSIONS: {
            ct: 'UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO',
          },
        },
        {
          $employeeType: {
            eq: 'CONTRACTOR',
          },
          $_PERMISSIONS: {
            ct: 'UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO',
          },
        },
        {
          $employeeType: {
            eq: 'CONTRACTOR',
          },
          $_PERMISSIONS: {
            ct: 'UPDATE_EMPLOYEE_MAIN_PERSONAL_INFO',
          },
        },
        {
          $employeeType: {
            eq: 'CONTRACTOR',
          },
          $_IS_CREATE_REQUEST: {
            eq: true,
          },
        },
      ],
    },
    regex: {
      value: `${emailPattern}`,
      message: `Email must be within ${domainName} domain`,
    },
  },

  workingStatus: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $_IS_CREATE_REQUEST: {
          eq: false,
        },
        $_PERMISSIONS: {
          ct: 'UPDATE_EMPLOYEE_WORKING_STATUS',
        },
      },
    },
  },

  employeeType: {
    type: 'enum',
    required: {
      value: true,
      when: [
        {
          $_PERMISSIONS: {
            ct: 'UPDATE_EMPLOYEE_MAIN_HR_INFORMATION',
          },
        },
        {
          $_IS_CREATE_REQUEST: {
            eq: true,
          },
        },
      ],
    },
  },
};

export const CAREER_REVIEW_VALIDATION = {
  careerDevelopmentEvaluationMode: {
    type: 'string',
    required: true,
  },
};
