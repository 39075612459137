/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';
import ExtensionPageLayout from 'erp/candidate/applications/createupdate/Create/layout/ExtensionPageLayout';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { CANDIDATE_APPLICATION_CREATE } from 'erp/candidate/applications/shared/candidateApplicationService';
import CreateCandidateApplicationFromJobBoardPage from 'erp/candidate/applications/createupdate/Create';

export const EXTENSION_PATH = '/create-application-from-extension';

const SidepanelExtensionRouter: Router = () => {
  return (
    <ProtectedRouter basePath={EXTENSION_PATH}>
      <Routes>
        <Route
          path=""
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CANDIDATE_APPLICATION_CREATE}>
              <ExtensionPageLayout>
                <CreateCandidateApplicationFromJobBoardPage showBreadcrumbs={false} />
              </ExtensionPageLayout>
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

SidepanelExtensionRouter.getRouterPath = () => `${EXTENSION_PATH}/*`;

export default SidepanelExtensionRouter;
