/* istanbul ignore file */
/* eslint-disable */
// This is the local copy of 'react-datetime' v2.16.2 component
// Decided to keep local copy due to poor support of the module (many unfixed issues)
// All local changes (locally changed code) in the file are marked with comments /* local change */
// TODO: investigate the ways for module replace

import useFeatureToggle, { Features } from 'featuretoggle';

const assign = require('object-assign');

const PropTypes = require('prop-types');

const createClass = require('create-react-class');

const moment = require('moment');

const React = require('react');

const CalendarContainer = require('shared/lib/DateTime/src/CalendarContainer');

import 'shared/lib/DateTime/css/react-datetime.css';

const onClickOutside = require('react-onclickoutside').default;

const viewModes = Object.freeze({
  YEARS: 'years',
  MONTHS: 'months',
  DAYS: 'days',
  TIME: 'time',
});

const TYPES = PropTypes;
const Datetime = createClass({
  displayName: 'DateTime',
  propTypes: {
    // value: TYPES.object | TYPES.string,
    // defaultValue: TYPES.object | TYPES.string,
    // viewDate: TYPES.object | TYPES.string,
    onFocus: TYPES.func,
    onBlur: TYPES.func,
    onChange: TYPES.func,
    onViewModeChange: TYPES.func,
    onNavigateBack: TYPES.func,
    onNavigateForward: TYPES.func,
    locale: TYPES.string,
    utc: TYPES.bool,
    displayTimeZone: TYPES.string,
    input: TYPES.bool,
    // dateFormat: TYPES.string | TYPES.bool,
    // timeFormat: TYPES.string | TYPES.bool,
    inputProps: TYPES.object,
    timeConstraints: TYPES.object,
    viewMode: TYPES.oneOf([viewModes.YEARS, viewModes.MONTHS, viewModes.DAYS, viewModes.TIME]),
    isValidDate: TYPES.func,
    open: TYPES.bool,
    strictParsing: TYPES.bool,
    closeOnSelect: TYPES.bool,
    closeOnTab: TYPES.bool,
    hasTodayButton: TYPES.bool,
    todayButtonLabel: TYPES.string,
  },

  getInitialState() {
    this.checkTZ(this.props);

    const state = this.getStateFromProps(this.props);

    if (state.open === undefined) state.open = !this.props.input;

    state.currentView = this.props.dateFormat
      ? this.props.viewMode || state.updateOn || viewModes.DAYS
      : viewModes.TIME;

    return state;
  },

  parseDate(date, formats) {
    let parsedDate;

    if (date && typeof date === 'string') parsedDate = this.localMoment(date, formats.datetime);
    else if (date) parsedDate = this.localMoment(date);

    if (parsedDate && !parsedDate.isValid()) parsedDate = null;

    return parsedDate;
  },

  getStateFromProps(props) {
    const formats = this.getFormats(props);

    const date = props.value || props.defaultValue;

    let selectedDate;
    let viewDate;
    let updateOn;
    let inputValue;
    selectedDate = this.parseDate(date, formats);

    viewDate = this.parseDate(props.viewDate, formats);

    viewDate = selectedDate
      ? selectedDate.clone().startOf('month')
      : viewDate
        ? viewDate.clone().startOf('month')
        : this.localMoment().startOf('month');

    updateOn = this.getUpdateOn(formats);

    if (selectedDate) inputValue = selectedDate.format(formats.datetime);
    else if (date.isValid && !date.isValid()) inputValue = '';
    else inputValue = date || '';

    return {
      updateOn,
      inputFormat: formats.datetime,
      viewDate,
      selectedDate,
      inputValue,
      open: props.open,
    };
  },

  getUpdateOn(formats) {
    if (formats.date.match(/[lLD]/)) {
      return viewModes.DAYS;
    }
    if (formats.date.indexOf('M') !== -1) {
      return viewModes.MONTHS;
    }
    if (formats.date.indexOf('Y') !== -1) {
      return viewModes.YEARS;
    }

    return viewModes.DAYS;
  },

  getFormats(props) {
    const formats = {
      date: props.dateFormat || '',
      time: props.timeFormat || '',
    };

    const locale = this.localMoment(props.date, null, props).localeData();
    if (formats.date === true) {
      formats.date = locale.longDateFormat('L');
    } else if (this.getUpdateOn(formats) !== viewModes.DAYS) {
      formats.time = '';
    }

    if (formats.time === true) {
      formats.time = locale.longDateFormat('LT');
    }

    formats.datetime = formats.date && formats.time ? `${formats.date} ${formats.time}` : formats.date || formats.time;
    return formats;
  },

  componentWillReceiveProps(nextProps) {
    const formats = this.getFormats(nextProps);

    let updatedState = {};
    if (nextProps.value !== this.props.value || formats.datetime !== this.getFormats(this.props).datetime) {
      updatedState = this.getStateFromProps(nextProps);
    }

    if (updatedState.open === undefined) {
      if (typeof nextProps.open !== 'undefined') {
        updatedState.open = nextProps.open;
      } else if (this.props.closeOnSelect && this.state.currentView !== viewModes.TIME) {
        updatedState.open = false;
      } else {
        updatedState.open = this.state.open;
      }
    }

    if (nextProps.viewMode !== this.props.viewMode) {
      updatedState.currentView = nextProps.viewMode;
    }

    if (nextProps.locale !== this.props.locale) {
      if (this.state.viewDate) {
        const updatedViewDate = this.state.viewDate.clone().locale(nextProps.locale);
        updatedState.viewDate = updatedViewDate;
      }
      if (this.state.selectedDate) {
        const updatedSelectedDate = this.state.selectedDate.clone().locale(nextProps.locale);
        updatedState.selectedDate = updatedSelectedDate;
        updatedState.inputValue = updatedSelectedDate.format(formats.datetime);
      }
    }

    if (nextProps.utc !== this.props.utc || nextProps.displayTimeZone !== this.props.displayTimeZone) {
      if (nextProps.utc) {
        if (this.state.viewDate) updatedState.viewDate = this.state.viewDate.clone().utc();
        if (this.state.selectedDate) {
          updatedState.selectedDate = this.state.selectedDate.clone().utc();
          updatedState.inputValue = updatedState.selectedDate.format(formats.datetime);
        }
      } else if (nextProps.displayTimeZone) {
        if (this.state.viewDate) {
          updatedState.viewDate = this.state.viewDate.clone().tz(nextProps.displayTimeZone);
        }
        if (this.state.selectedDate) {
          updatedState.selectedDate = this.state.selectedDate.clone().tz(nextProps.displayTimeZone);
          updatedState.inputValue = updatedState.selectedDate.tz(nextProps.displayTimeZone).format(formats.datetime);
        }
      } else {
        if (this.state.viewDate) updatedState.viewDate = this.state.viewDate.clone().local();
        if (this.state.selectedDate) {
          updatedState.selectedDate = this.state.selectedDate.clone().local();
          updatedState.inputValue = updatedState.selectedDate.format(formats.datetime);
        }
      }
    }

    if (nextProps.viewDate !== this.props.viewDate) {
      updatedState.viewDate = moment(nextProps.viewDate);
    }

    this.checkTZ(nextProps);
    this.setState(updatedState);
  },

  onInputChange(e) {
    const value = e.target === null ? e : e.target.value;

    const localMoment = this.localMoment(value, this.state.inputFormat);

    const update = { inputValue: value };
    if (localMoment.isValid() && !this.props.value) {
      update.selectedDate = localMoment;
      update.viewDate = localMoment.clone().startOf('month');
    } else {
      update.selectedDate = null;
    }

    return this.setState(update, function () {
      return this.props.onChange(this.state.inputValue);
    });
  },

  onInputKey(e) {
    if (e.which === 9 && this.props.closeOnTab) {
      this.closeCalendar();
    }
  },

  showView(view) {
    const me = this;
    return function () {
      me.state.currentView !== view && me.props.onViewModeChange(view);
      me.setState({ currentView: view });
    };
  },

  setDate(type) {
    const me = this;

    const nextViews = {
      month: viewModes.DAYS,
      year: viewModes.MONTHS,
    };
    return function (e) {
      me.setState({
        viewDate: me.state.viewDate
          .clone()
          [type](parseInt(e.target.getAttribute('data-value'), 10))
          .startOf(type),
        currentView: nextViews[type],
      });
      me.props.onViewModeChange(nextViews[type]);
    };
  },

  subtractTime(amount, type, toSelected) {
    const me = this;
    return function () {
      me.props.onNavigateBack(amount, type);
      me.updateTime('subtract', amount, type, toSelected);
    };
  },

  addTime(amount, type, toSelected) {
    const me = this;
    return function () {
      me.props.onNavigateForward(amount, type);
      me.updateTime('add', amount, type, toSelected);
    };
  },

  updateTime(op, amount, type, toSelected) {
    const update = {};

    const date = toSelected ? 'selectedDate' : 'viewDate';

    update[date] = this.state[date].clone()[op](amount, type);

    this.setState(update);
  },

  allowedSetTime: ['hours', 'minutes', 'seconds', 'milliseconds'],
  setTime(type, value) {
    let index = this.allowedSetTime.indexOf(type) + 1;

    const state = this.state;

    const date = (state.selectedDate || state.viewDate).clone();

    let nextType;

    // It is needed to set all the time properties
    // to not to reset the time
    date[type](value);
    for (; index < this.allowedSetTime.length; index++) {
      nextType = this.allowedSetTime[index];
      date[nextType](date[nextType]());
    }

    if (!this.props.value) {
      this.setState({
        selectedDate: date,
        inputValue: date.format(state.inputFormat),
      });
    }
    this.props.onChange(date);
  },

  updateSelectedDate(e, close) {
    const target = e.currentTarget;

    let modifier = 0;

    const viewDate = this.state.viewDate;

    const currentDate = this.state.selectedDate || viewDate;

    let date;
    if (target.className.indexOf('rdtDay') !== -1) {
      if (target.className.indexOf('rdtNew') !== -1) modifier = 1;
      else if (target.className.indexOf('rdtOld') !== -1) modifier = -1;

      date = viewDate
        .clone()
        .month(viewDate.month() + modifier)
        .date(parseInt(target.getAttribute('data-value'), 10));
    } else if (target.className.indexOf('rdtMonth') !== -1) {
      date = viewDate
        .clone()
        .month(parseInt(target.getAttribute('data-value'), 10))
        .date(currentDate.date());
    } else if (target.className.indexOf('rdtYear') !== -1) {
      date = viewDate
        .clone()
        .month(currentDate.month())
        .date(currentDate.date())
        .year(parseInt(target.getAttribute('data-value'), 10));
    }

    date
      .hours(currentDate.hours())
      .minutes(currentDate.minutes())
      .seconds(currentDate.seconds())
      .milliseconds(currentDate.milliseconds());

    if (!this.props.value) {
      const open = !(this.props.closeOnSelect && close);
      if (!open) {
        this.props.onBlur(date);
      }

      this.setState({
        selectedDate: date,
        viewDate: date.clone().startOf('month'),
        inputValue: date.format(this.state.inputFormat),
        open,
      });
    } else if (this.props.closeOnSelect && close) {
      this.closeCalendar();
    }

    this.props.onChange(date);
  },

  openCalendar(e) {
    if (!this.state.open) {
      this.setState({ open: true }, function () {
        this.props.onFocus(e);
      });
    }
  },

  closeCalendar() {
    this.setState({ open: false }, function () {
      this.props.onBlur(this.state.selectedDate || this.state.inputValue);
    });
  },

  handleClickOutside() {
    if (
      this.props.input &&
      this.state.open &&
      this.props.open === undefined &&
      !this.props.disableCloseOnClickOutside
    ) {
      this.setState({ open: false }, function () {
        this.props.onBlur(this.state.selectedDate || this.state.inputValue);
      });
    }
  },

  localMoment(date, format, props) {
    props = props || this.props;
    let m = null;

    if (props.utc) {
      m = moment.utc(date, format, props.strictParsing);
    } else {
      m = moment(date, format, props.strictParsing);
    }

    if (props.locale) m.locale(props.locale);
    return m;
  },

  checkTZ(props) {
    const con = console;

    if (props.displayTimeZone && !this.tzWarning && !moment.tz) {
      this.tzWarning = true;
      con &&
        con.error(
          `react-datetime: displayTimeZone prop with value "${props.displayTimeZone}" is used but moment.js timezone is not loaded.`,
        );
    }
  },

  componentProps: {
    fromProps: ['value', 'isValidDate', 'renderDay', 'renderMonth', 'renderYear', 'timeConstraints'],
    fromState: ['viewDate', 'selectedDate', 'updateOn'],
    fromThis: [
      'setDate',
      'setTime',
      'showView',
      'addTime',
      'subtractTime',
      'updateSelectedDate',
      'localMoment',
      'handleClickOutside',
    ],
  },

  getComponentProps() {
    const me = this;

    const formats = this.getFormats(this.props);

    const props = { dateFormat: formats.date, timeFormat: formats.time };
    this.componentProps.fromProps.forEach(name => {
      props[name] = me.props[name];
    });
    this.componentProps.fromState.forEach(name => {
      props[name] = me.state[name];
    });
    this.componentProps.fromThis.forEach(name => {
      props[name] = me[name];
    });

    return props;
  },

  overrideEvent(handler, action) {
    if (!this.overridenEvents) {
      this.overridenEvents = {};
    }

    if (!this.overridenEvents[handler]) {
      const me = this;
      this.overridenEvents[handler] = function (e) {
        let result;
        if (me.props.inputProps && me.props.inputProps[handler]) {
          result = me.props.inputProps[handler](e);
        }
        if (result !== false) {
          action(e);
        }
      };
    }

    return this.overridenEvents[handler];
  },

  render() {
    // TODO: Make a function or clean up this code,
    // logic right now is really hard to follow
    let className = `rdt${
      this.props.className
        ? Array.isArray(this.props.className)
          ? ` ${this.props.className.join(' ')}`
          : ` ${this.props.className}`
        : ''
    }`;

    let children = [];

    if (this.props.input) {
      const finalInputProps = assign(
        { type: 'text', className: 'form-control', value: this.state.inputValue },
        this.props.inputProps,
        {
          /* local change */
          name: (this.props && this.props.name) || '',
          /* end local change */
          autocomplete: 'off',
          onClick: this.overrideEvent('onClick', this.openCalendar),
          onFocus: this.overrideEvent('onFocus', this.openCalendar),
          onChange: this.overrideEvent('onChange', this.onInputChange),
          onKeyDown: this.overrideEvent('onKeyDown', this.onInputKey),
          onBlur: this.props.afterBlurCallback,
        },
      );

      if (this.props.renderInput) {
        children = [
          React.createElement(
            'div',
            { key: 'i' },
            this.props.renderInput(finalInputProps, this.openCalendar, this.closeCalendar),
          ),
        ];
      } else {
        children = [React.createElement('input', assign({ key: 'i' }, finalInputProps))];
      }
    } else {
      className += ' rdtStatic';
    }

    if (this.props.open || (this.props.open === undefined && this.state.open)) className += ' rdtOpen';

    const timezoneBlock = [];

    const { isFeatureEnabled } = useFeatureToggle();
    if (
      isFeatureEnabled(Features.SHOW_TIMEZONE_IN_DATETIME_INPUT) &&
      this.props.displayTimeZone &&
      this.props.timeFormat
    ) {
      const timezoneFormattedOffset = moment().tz(this.props.displayTimeZone).format('Z');
      const fullTimezone = `${this.props.displayTimeZone} (${timezoneFormattedOffset})`;
      timezoneBlock.push(
        React.createElement(
          'span',
          { class: 'rdtTimezone' },
          React.createElement('p', {}, `Timezone: ${fullTimezone}`),
        ),
      );
    }

    return React.createElement(
      ClickableWrapper,
      { className, onClickOut: this.handleClickOutside },
      children.concat(
        React.createElement(
          'div',
          { key: 'dt', className: 'rdtPicker' },
          React.createElement(CalendarContainer, { view: this.state.currentView, viewProps: this.getComponentProps() }),
          ...timezoneBlock,

          this.props.hasTodayButton
            ? React.createElement(
                'button',
                {
                  class: 'rdtTodayBtn',
                  onClick: () => {
                    const date = moment().startOf('month');

                    this.props.onBlur(date);

                    this.setState({
                      selectedDate: date,
                      viewDate: date.clone().startOf('month'),
                      inputValue: date.format(this.state.inputFormat),
                      currentView: this.props.viewMode || this.state.updateOn || viewModes.DAYS,
                    });

                    this.props.onChange(date);
                  },
                },
                this.props.todayButtonLabel || 'Today',
              )
            : null,
        ),
      ),
    );
  },
});

var ClickableWrapper = onClickOutside(
  createClass({
    render() {
      return React.createElement('div', { className: this.props.className }, this.props.children);
    },
    handleClickOutside(e) {
      this.props.onClickOut(e);
    },
  }),
);

Datetime.defaultProps = {
  className: '',
  defaultValue: '',
  inputProps: {},
  input: true,
  onFocus() {},
  onBlur() {},
  onChange() {},
  onViewModeChange() {},
  onNavigateBack() {},
  onNavigateForward() {},
  timeFormat: true,
  timeConstraints: {},
  dateFormat: true,
  strictParsing: true,
  closeOnSelect: false,
  closeOnTab: true,
  utc: false,
};

// Make moment accessible through the Datetime class
Datetime.moment = moment;

export default Datetime;
