/* istanbul ignore file */
// libs
import React from 'react';
// components
import { RelatedOpportunitiesList } from 'crm/opportunity';
import ContactsMenu from 'crm/contact/shared/ContactsMenu';
import ContactRelatedOpportunitiesBreadcrumbs from 'crm/contact/Show/ContactRelatedOpportunitiesBreadcrumbs';
import ContactEntityHeader from 'crm/contact/shared/ContactEntityHeader';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
// services
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const ContactOpportunities = () => {
  const { data } = useShowContext();

  const getDataMethod = async () => {
    return {
      result: data.getValueBySource('opportunities'),
    };
  };

  return (
    <ShowPageLayout
      menu={<ContactsMenu />}
      breadcrumbs={<ContactRelatedOpportunitiesBreadcrumbs />}
      entityHeader={<ContactEntityHeader />}
    >
      <RelatedOpportunitiesList getDataMethod={getDataMethod} />
    </ShowPageLayout>
  );
};

export default ContactOpportunities;
