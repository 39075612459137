import React from 'react';
import { IconButton } from 'uibuilder/button';
import Row, { RowProps } from 'shared/uibuilder/list/layout/DataGridLayout/Row';
import useCollapsibleRowService, {
  CollapsibleRowStorageProps,
} from 'shared/uibuilder/list/layout/DataGridLayout/collapsibleRowService';
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import './CollapsibleRow.scss';
import ShowData from 'shared/uibuilder/show/ShowData';
import { ShowContextProvider } from 'shared/uibuilder/show/ShowContext';

interface CollapsibleRowProps extends RowProps {
  children: Nullable<React.ReactElement | React.ReactElement[] | React.ReactNode>;
  toggleSource?: string;
  toggleProp?: string;
  level?: number;
  collapsibleRowStorageProps?: CollapsibleRowStorageProps;
}

const CollapsibleRow = ({
  children,
  template,
  toggleSource = 'id',
  toggleProp = 'source',
  level = 0,
  ...props
}: CollapsibleRowProps) => {
  const dataInstance = new ShowData(props.data);
  const style = { '--level': level } as React.CSSProperties;
  const { collapsed, handleToggleClick } = useCollapsibleRowService(props);

  return (
    <>
      <Row
        {...props}
        template={React.Children.map(template as any, (item: React.ReactElement) => {
          let toggle = null;

          if (!item) {
            return null;
          }

          if ((!children && !level) || item.props[toggleProp] !== toggleSource) {
            return item;
          }

          if (children) {
            toggle = (
              <IconButton className="data-row-toggle" type="button" onClick={handleToggleClick} size="small">
                {collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            );
          }

          return (
            <span data-level={level} className="data-cell-wrapper" style={style}>
              {toggle}
              {item}
            </span>
          );
        })}
      />
      {collapsed ? null : (
        <ShowContextProvider
          value={{
            data: dataInstance,
          }}
        >
          {children}
        </ShowContextProvider>
      )}
    </>
  );
};

export default CollapsibleRow;
