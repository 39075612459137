/* istanbul ignore file */
import React from 'react';
import { useEducationUrl } from 'erp/employee/Education/EducationRouter';
import LinkButton, { LinkButtonProps } from 'uibuilder/button/LinkButton';

const ViewEducationButton = (props: Omit<LinkButtonProps, 'children' | 'url'>) => {
  const { getSingleEntityUrl } = useEducationUrl();

  return (
    <LinkButton {...props} url={getSingleEntityUrl} outline>
      View
    </LinkButton>
  );
};

export default ViewEducationButton;
