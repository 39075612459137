/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import useLeaveService, { REJECT_LEAVE } from 'erp/leave/shared/leaveService';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';

const rejectLeaveMessage = 'Are you sure you want to cancel this leave request? This action cannot be undone.';

const RejectLeaveButton = (props: any) => {
  const { id: leaveId } = useParams<Dictionary<string>>();
  const { rejectById } = useLeaveService();
  const { getListUrl } = useLeaveUrl();

  return (
    <DeleteButton
      deleteMethod={() => rejectById(leaveId!)}
      buttonText="Cancel request"
      displayMessage={rejectLeaveMessage}
      permissionToCheck={REJECT_LEAVE}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Leave request will be canceled. It may take up to 2 days.',
        errorMessage: 'This Leave request cannot be canceled.',
      }}
      {...props}
    />
  );
};

export default RejectLeaveButton;
