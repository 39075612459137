/* istanbul ignore file */
import React from 'react';

type DayOnDemandsBalanceFieldProps = {
  useTooltip?: boolean;
  dayOnDemandField?: React.ReactElement | number;
};

const DayOnDemandsBalanceField = ({ useTooltip = false, dayOnDemandField = 0 }: DayOnDemandsBalanceFieldProps) => {
  return useTooltip ? (
    <sub className="single-entry-info-block description-info">including {dayOnDemandField} days on demand</sub>
  ) : (
    <sub />
  );
};

export default DayOnDemandsBalanceField;
