/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem, { SidebarMenuItemProps } from 'shared/uibuilder/menu/SidebarMenuItem';

interface NotesMenuItemProps extends Omit<SidebarMenuItemProps, 'name'> {
  linkUrl: string;
  name?: string;
}

const NotesMenuItem = ({ linkUrl, ...props }: NotesMenuItemProps) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Notes" icon="sticky-note" {...props} />
);

NotesMenuItem.displayName = 'Notes';

export default NotesMenuItem;
