/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LEAVE_PAYOUT_OPTIONS } from '../leaveService';
import { FieldProps } from 'shared/uibuilder/field';

const LeavePayoutOptionField = ({ value: inputValue, source, label }: FieldProps) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });

  const value = inputValue || (getValue && getValue());

  return <EnumField label={label} value={value} options={LEAVE_PAYOUT_OPTIONS} />;
};

export default LeavePayoutOptionField;
