/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import SearchLinkField from 'shared/uibuilder/field/SearchLinkField';
import { useAccountUrl } from 'crm/account/index';
import { SearchResultFieldProps } from '../../../../shared/uibuilder/field/SearchResultField';

interface AccountSearchLinkFieldProps extends SearchResultFieldProps {
  idValue?: string;
  idSource?: string;
}

const AccountSearchLinkField = ({ idValue = '', idSource = undefined, ...props }: AccountSearchLinkFieldProps) => {
  const { getSingleEntityUrl } = useAccountUrl();
  const { getValue: getId } = useFieldHelper({ source: idSource, value: idValue });
  const { getValue } = useFieldHelper(props);

  const id = getId();
  const name = getValue();
  const linkText = name || id;

  return <SearchLinkField {...props} isInternal value={linkText} url={getSingleEntityUrl(id)} />;
};

export default AccountSearchLinkField;
