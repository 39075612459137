/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import useAccountService, {
  DELETE_ACCOUNT,
  READ_SENSITIVE_ACCOUNT_NAME,
  UPDATE_ACCOUNT,
} from 'crm/account/accountService';
import ShowAccountPageMenu from 'crm/account/Show/ShowAccountPageMenu';
import AccountEntityHeader from 'crm/account/shared/AccountEntityHeader';
import SingleAccountBreadcrumbs from 'crm/account/shared/SingleAccountBreadcrumbs';
import { useAccountId } from 'crm/account/AccountRouter';
import useAccountUrl from '../shared/accountUrl';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { AddressField, HtmlField, PhoneField, TextField } from 'shared/uibuilder/field';
import AccountTypeField from 'crm/account/shared/field/AccountTypeField';
import IndustryField from 'crm/shared/field/IndustryField';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import EditAccountButton from 'crm/account/shared/Button/EditAccountButton';
import DeleteButton from 'uibuilder/button/DeleteButton';
import AccountLinkField from 'crm/account/shared/field/AccountLinkField';
import { LeadLinkField } from 'crm/lead';
import { HTTP } from '../../../shared/api/const';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useAuthorization from 'shared/authorization/authorizationService';

const AccountViewPage = ({ isSensitiveDataIsShown = false, toggleSensitiveDataButton = <></> }) => {
  const { deleteById } = useAccountService();
  const { getListUrl } = useAccountUrl();
  const id = useAccountId();
  const { isGranted } = useAuthorization();
  const { getValue: getTitle } = useFieldHelper({ source: isSensitiveDataIsShown ? 'realAccountName' : 'accountName' });

  const buttons = [
    isGranted(READ_SENSITIVE_ACCOUNT_NAME) && toggleSensitiveDataButton,
    <EditAccountButton permissionToCheck={UPDATE_ACCOUNT} />,
    <DeleteButton
      deleteMethod={() => deleteById(id)}
      displayMessage="Do you really want to delete this account?"
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Account has been successfully deleted.',
        errorMessage: `Can't delete account`,
        errorStatusesMessages: {
          [HTTP.BAD_REQUEST]: 'The Account cannot be deleted as it has related Opportunity or Contact',
        },
      }}
      permissionToCheck={DELETE_ACCOUNT}
    />,
  ].filter(Boolean) as React.ReactElement[];

  return (
    <ShowPageLayout
      headerButtons={buttons}
      menu={<ShowAccountPageMenu />}
      entityHeader={<AccountEntityHeader entityTitle={getTitle() || '-'} />}
      breadcrumbs={<SingleAccountBreadcrumbs />}
      hasSeparateSections
    >
      <ShowSection title="Account Information">
        <SectionRow>
          <PhoneField label="Phone" source="phoneNumber" />
          <AccountTypeField label="Account Type" source="accountType" />
          <ReplaceUrlWithLink>
            <HtmlField label="Website" source="website" />
          </ReplaceUrlWithLink>
          <IndustryField label="Industry" source="industry" />
          <TextField label="No. of Employees" source="employeesNumber" />
          <AccountLinkField label="Parent Account" idSource="parentAccountId" source="parentAccountName" />
          <TextField label="Account Owner" source="ownerName" />
          <LeadLinkField
            label="Converted From"
            idSource="convertedFromId"
            nameSource="convertedFromName"
            isVisible={(data: any) => data.getValueBySource('convertedFromId')}
          />
        </SectionRow>
      </ShowSection>

      <ShowSection title="Address Information">
        <BigSectionRow>
          <AddressField source="address" />
        </BigSectionRow>
      </ShowSection>

      <ShowSection title="Description Information">
        <BigSectionRow>
          <ReplaceUrlWithLink>
            <HtmlField label="" source="accountDescription" />
          </ReplaceUrlWithLink>
        </BigSectionRow>
      </ShowSection>

      <ShowSection title="Attachments">
        <BigSectionRow>
          <AttachmentsListField label="" source="attachmentsIds" />
        </BigSectionRow>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default AccountViewPage;
