/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  CREATE_SERVICE_CATALOG,
  READ_SERVICE_CATALOG,
  READ_SERVICE_CATALOG_LIST,
  SERVICE_CATALOG_PATH,
} from 'erp/serviceCatalog/useServiceCatalogService';
import ServiceCatalogListPage from 'erp/serviceCatalog/List';
import ServiceCatalogViewPage from 'erp/serviceCatalog/Show';
import ServiceCatalogCreatePage from 'erp/serviceCatalog/createupdate/Create';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import { BUDGETING_CREATE, BUDGETING_READ } from 'erp/serviceCatalog/budgets/budgetingService';
import ViewServiceBudgets from 'erp/serviceCatalog/budgets/List/viewServiceBudgets';
import CreateServiceBudget from 'erp/serviceCatalog/budgets/Create';
import ViewServiceBudget from 'erp/serviceCatalog/budgets/Show/ViewServiceBudget';
import ViewServiceBudgetStructure from 'erp/serviceCatalog/budgets/structure/Show/ViewServiceBudgetStructure';
import useFeatureToggle, { Features } from 'featuretoggle';
import ServiceBudgetContext from 'erp/serviceCatalog/ServiceBudgetContext';
import ServiceCatalogContext from 'erp/serviceCatalog/ServiceCatalogContext';
import { KPI_STRUCTURE_READ, KPI_VALUES_READ_LIST } from './kpi/useKPIService';
import ViewKPIStructure from './kpi/Show/ViewKPIStructure';
import UpdateServiceBudget from 'erp/serviceCatalog/budgets/Update';
import KpiValuesListPage from './kpi/List/KpiValuesListPage';

export const useServiceCatalogRoute = () => {
  const routes = useRoute({ listRoute: SERVICE_CATALOG_PATH });

  return {
    ...routes,
    budgetsRoute: `${routes.singleEntityRoute}/budgets`,
    createBudgetRoute: `${routes.singleEntityRoute}/budgets/add`,
    updateBudgetRoute: `${routes.singleEntityRoute}/budgets/:budgetId/edit`,
    budgetRoute: `${routes.singleEntityRoute}/budgets/:budgetId`,
    budgetStructuresRoute: `${routes.singleEntityRoute}/budgets/structures`,
    kpiStructureRoute: `${routes.singleEntityRoute}/kpi/structures/:timelineEntryId?`,
    kpiValueRoute: `${routes.singleEntityRoute}/kpi/values`,
  };
};

export const useServiceCatalogUrl = () => {
  const urls = useEntityUrl({
    baseLink: SERVICE_CATALOG_PATH,
  });

  return {
    ...urls,
    getBudgetsUrl: (id: string) => `${urls.getSingleEntityUrl(id)}/budgets`,
    getCreateBudgetUrl: (id: string) => `${urls.getSingleEntityUrl(id)}/budgets/add`,
    getUpdateBudgetUrl: (id: string, budgetId: string) => `${urls.getSingleEntityUrl(id)}/budgets/${budgetId}/edit`,
    getBudgetUrl: (id: string, budgetId: string) => `${urls.getSingleEntityUrl(id)}/budgets/${budgetId}`,
    getBudgetStructuresUrl: (id: string) => `${urls.getSingleEntityUrl(id)}/budgets/structures`,
    getKPIStructuresUrl: (id: string) => `${urls.getSingleEntityUrl(id)}/kpi/structures`,
    getKPIValuesUrl: (id: string) => `${urls.getSingleEntityUrl(id)}/kpi/values`,
  };
};

export const useServiceCatalogId = () => useParams<Dictionary<string>>().id!;

const ServiceCatalogRouter = () => {
  const { isFeatureEnabled } = useFeatureToggle();

  const {
    listRoute,
    singleEntityRoute,
    createRoute,
    budgetsRoute,
    createBudgetRoute,
    updateBudgetRoute,
    budgetRoute,
    budgetStructuresRoute,
    kpiStructureRoute,
    kpiValueRoute,
  } = useServiceCatalogRoute();

  return (
    <ProtectedRouter basePath={SERVICE_CATALOG_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_SERVICE_CATALOG_LIST}>
              <ServiceCatalogListPage />
            </GlobalProtectedRoute>
          }
        />
        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_SERVICE_CATALOG}>
              <ServiceCatalogCreatePage />
            </GlobalProtectedRoute>
          }
        />
        {isFeatureEnabled(Features.BUDGETING) ? (
          <>
            <Route
              path={createBudgetRoute}
              element={
                <ServiceBudgetContext permissionToCheck={BUDGETING_CREATE}>
                  <CreateServiceBudget />
                </ServiceBudgetContext>
              }
            />
            <Route
              path={updateBudgetRoute}
              element={
                <ServiceBudgetContext permissionToCheck={BUDGETING_CREATE}>
                  <UpdateServiceBudget />
                </ServiceBudgetContext>
              }
            />
            <Route
              path={budgetStructuresRoute}
              element={
                <ServiceBudgetContext permissionToCheck={BUDGETING_READ}>
                  <ViewServiceBudgetStructure />
                </ServiceBudgetContext>
              }
            />
            <Route
              path={budgetRoute}
              element={
                <ServiceBudgetContext permissionToCheck={BUDGETING_READ}>
                  <ViewServiceBudget />
                </ServiceBudgetContext>
              }
            />
            <Route
              path={budgetsRoute}
              element={
                <ServiceBudgetContext permissionToCheck={BUDGETING_READ}>
                  <ViewServiceBudgets />
                </ServiceBudgetContext>
              }
            />
          </>
        ) : (
          <></>
        )}
        <Route
          path={singleEntityRoute}
          element={
            <ServiceCatalogContext permissionToCheck={READ_SERVICE_CATALOG}>
              <ServiceCatalogViewPage />
            </ServiceCatalogContext>
          }
        />
        <Route
          path={kpiStructureRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={KPI_STRUCTURE_READ}>
              <ViewKPIStructure />
            </GlobalProtectedRoute>
          }
        />
        <Route
          path={kpiValueRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={KPI_VALUES_READ_LIST}>
              <KpiValuesListPage />
            </GlobalProtectedRoute>
          }
        />
        <Route path="*" element={<Page404 />} />,
      </Routes>
    </ProtectedRouter>
  );
};

ServiceCatalogRouter.getRouterPath = () => `${SERVICE_CATALOG_PATH}/*`;

export default ServiceCatalogRouter;
