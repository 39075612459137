// @ts-nocheck
import React from 'react';
import { Route } from 'shared/routing';
import OneStopListPage from 'oneStop/List';
import OneStopCreatePage from 'oneStop/Create';
import { Routes } from 'react-router-dom';

export const ONE_STOP_ROUTES = {
  listRoute: '/one-stop',
  createRoute: '/one-stop/:id',
};

export default () => (
  <Routes>
    <Route path={ONE_STOP_ROUTES.createRoute} component={OneStopCreatePage} />
    <Route path={ONE_STOP_ROUTES.listRoute} component={OneStopListPage} />
  </Routes>
);
