/* istanbul ignore file */
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { isArray, isFunction } from 'lodash';
import { ExpandingFieldProps } from 'shared/uibuilder/field/ExpandingField';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

type ExpandingTooltipFieldProps = Omit<ExpandingFieldProps, 'value'> & {
  value?: string | ((data: any) => string);
};

const ExpandingTooltipField = ({ maxValueLength = 78, children, ...props }: ExpandingTooltipFieldProps) => {
  const { data } = useShowContext();
  const { getValue } = useFieldHelper(props);
  const value = getValue();
  const handlingValue = isFunction(value) ? value(data) : value;
  const shortValue = isArray(handlingValue)
    ? handlingValue?.slice(0, maxValueLength)
    : `${handlingValue?.slice(0, maxValueLength).trim()}...`;

  const isFullValue = !handlingValue || handlingValue?.length <= maxValueLength;
  const resultProps = isFullValue
    ? props
    : {
        ...props,
        value: shortValue,
        realValue: handlingValue,
      };
  const resultElement = React.cloneElement(children, resultProps);

  return (
    <Tooltip title={handlingValue} disableFocusListener arrow>
      <span>{resultElement}</span>
    </Tooltip>
  );
};

export default ExpandingTooltipField;
