import EnumDropdown, { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';
import React from 'react';
import { BUDGET_OBJECTIVE_UNIT_OPTIONS } from 'erp/serviceCatalog/budgets/budgetVersionService';

const CategoryUnitDropdown = (props: Omit<EnumDropdownProps, 'options'> & { options?: Dictionary<string> }) => {
  const options = { ...BUDGET_OBJECTIVE_UNIT_OPTIONS };

  return <EnumDropdown options={options} placeholder="Select currency" {...props} />;
};

export default CategoryUnitDropdown;
