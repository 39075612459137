import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import SwiperContainer from 'shared/Swiper/SwiperContainer';
import { WorkingCondition } from '../../../types';
import Box from 'uibuilder/Box';
import { EnumField, ListField } from 'shared/uibuilder/field';
import { EMPLOYEE_CONTRACT_ASSIGMENT_TYPES } from 'erp/employee/contracts/shared/input/EmployeeContractAssignmentTypeRadios';
import { EMPLOYEE_CONTRACT_TYPES, RELOCATION_OPTION_NEW } from '../../../constants';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import OfficeAndAccommodationIcons from '../../shared/OfficeAndAccommodationIcons';

const WorkingConditionsSwiper = () => {
  const { data } = useShowContext();
  const { workingConditions } = data.getData();

  return (
    <SwiperContainer>
      {workingConditions.map((workingCondition: WorkingCondition & { id: string }) => {
        const [office, accommodation] = workingCondition.hiringLocation.split('_', 2);

        return (
          <Box sx={{ height: '195px' }}>
            <MediumFormRow
              sx={{
                mb: '20px',
                '> .MuiGrid-item': {
                  pl: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <OfficeAndAccommodationIcons registrations={[office]} accommodations={[accommodation]} />
              </Box>

              <ListField value={workingCondition.employmentTypes as any} label="Employment Type">
                <EnumField options={EMPLOYEE_CONTRACT_ASSIGMENT_TYPES} />
              </ListField>
            </MediumFormRow>

            <MediumFormRow
              sx={{
                '> .MuiGrid-item': {
                  pl: 0,
                },
              }}
            >
              <ListField value={workingCondition.contractTypes as any} label="Contract Type">
                <EnumField options={EMPLOYEE_CONTRACT_TYPES} />
              </ListField>
              <EnumField
                options={RELOCATION_OPTION_NEW}
                value={workingCondition.isRelocationAvailable as any}
                label="Relocation Options"
              />
            </MediumFormRow>
          </Box>
        );
      })}
    </SwiperContainer>
  );
};

export default WorkingConditionsSwiper;
