import React from 'react';
import Page403 from './Page403';
import * as PropTypes from 'prop-types';
import useAuthorization from 'shared/authorization/authorizationService';
import { Permission } from 'shared/authorization/utils';

const GlobalSecurityContext = ({
  children,
  permissionToCheck,
}: {
  children: React.ReactElement;
  permissionToCheck: Permission | Permission[];
}) => {
  const { isGranted } = useAuthorization();

  if (!isGranted(permissionToCheck)) {
    return <Page403 />;
  }

  return children;
};

GlobalSecurityContext.propTypes = {
  children: PropTypes.element.isRequired,
  permissionToCheck: PropTypes.string.isRequired,
};

export default GlobalSecurityContext;
