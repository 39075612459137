/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { ENTITY_TYPE, ENTITY_TYPE_ALIAS } from 'crm/shared/timeline/activity/shared/transferActivityService';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import AccountInput from 'crm/account/shared/input/AccountNameInput';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import LeadSearchInput from 'crm/lead/shared/input/LeadSearchInput';
import { ACCOUNT_PATH, useAccountId } from 'crm/account/AccountRouter';
import { OPPORTUNITY_PATH, useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import OpportunitySearchInput from 'crm/opportunity/shared/input/OpportunitySearchInput';
import { useLocation } from 'react-router-dom';
import { LEADS_PATH, useLeadId } from 'crm/lead/LeadRouter';

const TransferActivityFormSection = () => {
  const location = useLocation();
  const rawLeadId = useLeadId();
  const currentLeadId = location?.pathname?.includes(LEADS_PATH) ? rawLeadId : null;
  const rawAccountId = useAccountId();
  const currentAccountId = location?.pathname?.includes(ACCOUNT_PATH) ? rawAccountId : null;
  const rawOpportunityId = useOpportunityId();
  const currentOpportunityId = location?.pathname?.includes(OPPORTUNITY_PATH) ? rawOpportunityId : null;
  const leadFilter = currentLeadId
    ? {
        id: {
          ne: currentLeadId,
        },
      }
    : {};
  const accountFilter = currentAccountId
    ? {
        alias: {
          ne: currentAccountId,
        },
      }
    : {};
  const opportunityFilter = currentOpportunityId
    ? {
        id: {
          ne: currentOpportunityId,
        },
      }
    : {};

  return (
    <>
      <FormSection>
        <MediumFormRow>
          <EnumDropdown options={ENTITY_TYPE} source="entityType" placeholder="Select..." className="input-group" />
        </MediumFormRow>
        <MediumFormRow>
          <ClearAfterUnmountWrapper isVisible={data => data.entityType === ENTITY_TYPE_ALIAS.ACCOUNT}>
            <AccountInput
              source="accountId"
              label="Target Account"
              defaultFilter={accountFilter}
              customMapResponse={account => {
                return {
                  accountId: account.accountId,
                  text: account.accountName,
                };
              }}
            />
          </ClearAfterUnmountWrapper>
        </MediumFormRow>
        <MediumFormRow>
          <ClearAfterUnmountWrapper isVisible={data => data.entityType === ENTITY_TYPE_ALIAS.LEAD}>
            <LeadSearchInput source="leadId" label="Target Lead" defaultFilter={leadFilter} />
          </ClearAfterUnmountWrapper>
        </MediumFormRow>
        <MediumFormRow>
          <ClearAfterUnmountWrapper isVisible={data => data.entityType === ENTITY_TYPE_ALIAS.OPPORTUNITY}>
            <OpportunitySearchInput
              source="opportunityId"
              label="Target Opportunity"
              defaultFilter={opportunityFilter}
            />
          </ClearAfterUnmountWrapper>
        </MediumFormRow>
      </FormSection>
    </>
  );
};

export default TransferActivityFormSection;
