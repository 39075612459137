/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form/index';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import usePurchaseOrderService, { PURCHASE_ORDER_TYPES } from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import { usePurchaseOrderUrl, usePurchaseOrderId } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import Breadcrumbs from 'financialAnalytic/purchaseOrder/createupdate/Update/Breadcrumbs';
import PurchaseOrderForm from 'financialAnalytic/purchaseOrder/createupdate/PurchaseOrderForm';

const UpdatePurchaseOrderPage = () => {
  const { update, getValidationSchema, getById, getSensitiveDataByIds } = usePurchaseOrderService();
  const { getSingleEntityUrl } = usePurchaseOrderUrl();

  const id = usePurchaseOrderId();

  const getDataFunc = async () => {
    const data = await getById(id);
    const sensitiveData = await getSensitiveDataByIds([id]);
    const { purchaseSubTotals } = sensitiveData[id] || {};

    return {
      type: data.type,
      vendor: data.vendor,
      title: data.title,
      office: data.office,
      account: data.account,
      budgetCategory: data.budgetCategory,
      purchasePurpose: data.purchasePurpose,
      requiredDeliveryDate: data.requiredDeliveryDate,
      expirationDate: data.expirationDate,
      requestor: data.requestor,
      requestorName: `${data.requestor} ${data.requestorFullName}`,
      currency: (purchaseSubTotals?.[data.purchases[0].id] as any)?.currency,
      purchases: data.purchases.map((item: any) => ({
        ...item,
        cost: purchaseSubTotals?.[item.id] || {},
      })),
    };
  };

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getDataFunc}
      afterSubmit={{
        redirect: () => getSingleEntityUrl(id),
        message: 'You are awesome! The Purchase Order has been successfully updated.',
      }}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        currency: 'USD',
        purchases: [{}],
        type: PURCHASE_ORDER_TYPES.STANDARD,
      }}
    >
      <UpdatePageLayout breadcrumbs={<Breadcrumbs />} title="Edit: Purchase Order">
        <PurchaseOrderForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdatePurchaseOrderPage;
