/* istanbul ignore file */
import React from 'react';
import AmountField from 'shared/uibuilder/field/AmountField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { PROBABILITY_MAP } from 'crm/opportunity/shared/opportunityService';

type OpportunityExpectedRevenueFieldProps = {
  label: string;
  amountSource?: string;
  amountValue?: string;
  stageSource?: string;
  stageValue?: string;
};

const OpportunityExpectedRevenueField = ({
  amountSource,
  amountValue,
  stageSource,
  stageValue,
  ...props
}: OpportunityExpectedRevenueFieldProps) => {
  const { getValue: getAmountValue } = useFieldHelper<any>({ source: amountSource, value: amountValue });
  const { getValue: getStageValue } = useFieldHelper({ source: stageSource, value: stageValue });

  const expectedRevenue = +((PROBABILITY_MAP[getStageValue()] * getAmountValue()) / 100).toFixed(2);

  return <AmountField {...props} value={expectedRevenue} />;
};

export default OpportunityExpectedRevenueField;
