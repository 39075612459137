// libs
import React from 'react';
// components
import Page403 from 'shared/authorization//Page403';
// services
import useAuthorization from 'shared/authorization/authorizationService';
import { Permission } from 'shared/authorization/utils';

type GlobalProtectedRouteProps = {
  path?: string;
  exact?: boolean;
  globalPermissionToCheck: Permission | Permission[];
  children: React.ReactNode;
};

const GlobalProtectedRoute = (props: GlobalProtectedRouteProps) => {
  const { isGranted } = useAuthorization();
  const { globalPermissionToCheck, children } = props;

  return isGranted(globalPermissionToCheck) ? children : <Page403 />;
};

export default GlobalProtectedRoute;
