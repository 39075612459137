import { useCallback } from 'react';
// services
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { useKernelApi } from 'api';
import useCacheService from 'shared/cache/cacheService';

// constants
export const READ_EMPLOYEE_COMMON_SKILL_LIST = 'READ_EMPLOYEE_COMMON_SKILL_LIST';
export const DELETE_EMPLOYEE_COMMON_SKILL = 'DELETE_EMPLOYEE_COMMON_SKILL';
export const UPDATE_EMPLOYEE_COMMON_SKILL = 'UPDATE_EMPLOYEE_COMMON_SKILL';
export const CREATE_EMPLOYEE_COMMON_SKILL = 'CREATE_EMPLOYEE_COMMON_SKILL';
const CACHE_NAME = 'employee-common-skills';

export enum EmployeeCommonSkillLevel {
  EXPERT = 'EXPERT',
  CLAIMED = 'CLAIMED',
  USED = 'USED',
  MASTERED = 'MASTERED',
}

export const EmployeeCommonSkillLevelLabels = {
  [EmployeeCommonSkillLevel.EXPERT]: 'Expert',
  [EmployeeCommonSkillLevel.CLAIMED]: 'Claimed',
  [EmployeeCommonSkillLevel.USED]: 'Used',
  [EmployeeCommonSkillLevel.MASTERED]: 'Mastered',
};

export enum EmployeeCommonSkillExperienceType {
  COMMERCIAL = 'COMMERCIAL',
  NON_COMMERCIAL = 'NON_COMMERCIAL',
}

export const EmployeeCommonSkillExperienceTypeLabels = {
  [EmployeeCommonSkillExperienceType.COMMERCIAL]: 'Commercial',
  [EmployeeCommonSkillExperienceType.NON_COMMERCIAL]: 'Non-commercial',
};

export enum EmployeeCommonSkillExperienceDuration {
  LESS_THAN_YEAR = 'LESS_THAN_YEAR',
  MORE_THAN_YEAR_LESS_THAN_TREE = 'MORE_THAN_YEAR_LESS_THAN_TREE',
  MORE_THAN_THREE_YEARS_LESS_THAN_FIVE_YEARS = 'MORE_THAN_THREE_YEARS_LESS_THAN_FIVE_YEARS',
  MORE_THAN_FIVE_YEARS = 'MORE_THAN_FIVE_YEARS',
}

export const EmployeeCommonSkillExperienceDurationLabels = {
  [EmployeeCommonSkillExperienceDuration.LESS_THAN_YEAR]: 'Less than a year',
  [EmployeeCommonSkillExperienceDuration.MORE_THAN_YEAR_LESS_THAN_TREE]: 'More than a year, less than 3 years',
  [EmployeeCommonSkillExperienceDuration.MORE_THAN_THREE_YEARS_LESS_THAN_FIVE_YEARS]:
    'More than 3 years, less than 5 years',
  [EmployeeCommonSkillExperienceDuration.MORE_THAN_FIVE_YEARS]: 'More than 5 years',
};

export const useEmployeeCommonSkillsService = () => {
  const { sendPostRequest, sendPutRequest, sendDeleteRequest, sendGetRequest } = useKernelApi();
  const currentAlias = useEmployeeId();
  const { addToCache, getValue, cache, invalidateCache } = useCacheService(CACHE_NAME);
  const getCacheId = (employeeId: Nullable<string>) => `employee-${employeeId}-domains`;

  const findAllEmployeeCommSkills = useCallback(
    async () => {
      const cacheId = getCacheId(currentAlias);
      const cachedValue = getValue(cacheId);

      if (cachedValue) {
        return cachedValue;
      } else {
        const url = `/employees/${currentAlias}/common-skills/search`;
        const response = await sendPostRequest(url, {
          pageSize: 30,
        });
        const json = await response.json();

        const result = json.result?.map((item: any) => ({ commonSkillId: item.commonSkill?.id, ...item }));

        addToCache(cacheId, { ...json, result });

        return { ...json, result };
      }
    },
    // Suppressed warnings because we only need to call useCallback callback if cache is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache],
  );

  const getById = async (id: string) => {
    const url = `/employees/${currentAlias}/common-skills/${id}`;
    const response = await sendGetRequest(url);

    return response.json();
  };

  const addEmployeeCommonSkill = async (data: any) => {
    const url = `/employees/${currentAlias}/common-skills`;
    const response = await sendPostRequest(url, data);
    const json = await response.json();

    invalidateCache();

    return json;
  };

  const editEmployeeCommSkill = async (id: StringOrNumber, request: any) => {
    const url = `/employees/${currentAlias}/common-skills/${id}`;
    const response = await sendPutRequest(url, request);
    const json = await response.json();

    invalidateCache();

    return json;
  };

  const deleteEmployeeCommSkill = async (id: string) => {
    const response = await sendDeleteRequest(`/employees/${currentAlias}/common-skills/${id}`);

    invalidateCache();
    return response.json();
  };

  return {
    findAllEmployeeCommSkills,
    addEmployeeCommonSkill,
    getById,
    editEmployeeCommSkill,
    deleteEmployeeCommSkill,
  };
};
