/* istanbul ignore file */
export default {
  startDate: {
    type: 'string',
    required: true,
  },
  endDate: {
    type: 'string',
    required: true,
  },
  objectives: {
    type: 'array',
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        value: {
          type: 'string',
          required: true,
        },
      },
    },
  },
  categories: {
    type: 'array',
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        amount: {
          type: 'string',
          required: true,
        },
      },
    },
  },
};
