import React, { useEffect } from 'react';
import Typography from 'uibuilder/Typography';
import Grid from 'uibuilder/Grid';
import Box from 'uibuilder/Box';
import InputList from 'shared/uibuilder/form/input/InputList/InputList';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import ObjectiveInput from './ObjectiveInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const CategoriesInputTemplate = (props: any) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const source = getSource();

  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TextInput source={`${source}.name`} label="Category Name" />
    </Box>
  );
};

const BudgetStructureDetailsSection = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Box>
          <>
            <Typography variant="h4" mb="20px">
              Categories
            </Typography>
            <InputList
              source="categories"
              inputTemplate={CategoriesInputTemplate}
              addText="Add category"
              maxFields={50}
            />
          </>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <>
          <Typography variant="h4" mb="20px">
            Objectives
          </Typography>
          <InputList source="objectives" inputTemplate={ObjectiveInput} addText="Add objective" maxFields={50} />
        </>
      </Grid>
    </Grid>
  );
};

export default BudgetStructureDetailsSection;
