import React, { useEffect } from 'react';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getLastSource } from 'shared/uibuilder/helper';
import FormRow from 'shared/layout/form/FormRow';
import KPITypeDropdown from './KPITypeDropdown';

const KpiParameterInputs = (props: DefaultInputPropTypes<any>) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const objective = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});

  const source = getSource();

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCallback = (value: { [x: string]: any }) => {
    rawOnChangeCallback({
      [source]: {
        ...objective,
        ...getLastSource(value),
      },
    });
  };

  return (
    <FormRow>
      <TextInput label="Parameter Name" source={`${source}.name`} onChangeCallback={onChangeCallback} />
      <KPITypeDropdown
        label="Unit"
        placeholder="Select unit"
        source={`${source}.type`}
        onChangeCallback={onChangeCallback}
      />
    </FormRow>
  );
};

export default KpiParameterInputs;
