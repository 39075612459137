import { useKernelApi } from 'api';
import { ResourceData, ResourceId } from 'shared/crud/baseCrudService';

export const ENTITY_TYPE_ALIAS = {
  LEAD: 'LEAD',
  ACCOUNT: 'ACCOUNT',
  OPPORTUNITY: 'OPPORTUNITY',
};

export const ENTITY_TYPE = {
  [ENTITY_TYPE_ALIAS.LEAD]: 'Lead',
  [ENTITY_TYPE_ALIAS.ACCOUNT]: 'Account',
  [ENTITY_TYPE_ALIAS.OPPORTUNITY]: 'Opportunity',
};

const useTransferActivityService = () => {
  const { sendPostRequest } = useKernelApi();

  const transferActivity = async (id: ResourceId, data: ResourceData) => {
    const url = `/crm/activities/${id}/transfer`;
    const response = await sendPostRequest(url, data);
    return response.json();
  };

  return {
    transferActivity,
  };
};

export default useTransferActivityService;
