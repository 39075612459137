/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { UPDATE_EMPLOYEE_CONTRACT } from 'erp/employee/contracts/shared/employeeContractsService';
import { useEmployeeContractId, useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';

const EditEmployeeContractButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useEmployeeContractUrl();
  const id = useEmployeeContractId();

  return (
    <EditButton
      path={getUpdateEntityUrl(id)}
      permissionToCheck={UPDATE_EMPLOYEE_CONTRACT}
      key="edit-employee-contract"
      icon={<i className="fa fa-edit" />}
      {...props}
    />
  );
};

export default EditEmployeeContractButton;
