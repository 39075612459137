import React from 'react';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';
import { ResourceData } from 'shared/crud/baseCrudService';
import Data from 'shared/uibuilder/Data';

export type TimelineRegistry = {
  name: string;
  AddForm: ReactComponent<{
    onCancel?: () => void;
    submitFormFunc?: (data: ResourceData) => void;
    afterSubmit?: {
      execute: (data: TimelineItem) => void;
    };
    [key: string]: any;
  }>;

  UpdateForm: React.ReactElement;
  RecordLayout: React.ReactElement;
  isApplicable: (arg: TimelineItem) => boolean;
  source: string;
  urlPostfix: string;
  permissionToCheck?: string;
  withoutAddFunctionality?: boolean;
  additionalAfterAddSubmit?: (arg: TimelineItem, parentContext?: Data, reloadData?: () => void) => void;
  additionalAfterUpdateSubmit?: (arg: TimelineItem, parentContext?: Data) => void;
};

const useTimelineRegistryHelper = (registries: TimelineRegistry[]) => {
  const getRegistry = (item: TimelineItem) => {
    return registries.find(registry => registry.isApplicable(item));
  };

  return {
    getRegistry,
  };
};

export default useTimelineRegistryHelper;
