import React from 'react';
import { CREATE_WASTE, READ_WASTE, READ_WASTE_LIST, WASTE_PATH } from 'erp/costaccounting/waste/useWasteService';
import { Routes, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import WasteList from './List';
import CreateWastePage from './createupdate/Create';
import WasteViewPage from './show/WasteViewPage';

export const useWasteRoute = () => {
  return useRoute({
    listRoute: WASTE_PATH,
  });
};

export const useWasteId = () => useParams<Dictionary<string>>().id!;

const WasteRouter = () => {
  const { listRoute, createRoute, singleEntityRoute } = useWasteRoute();

  return (
    <ProtectedRouter basePath={WASTE_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_WASTE_LIST}>
              <WasteList />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_WASTE}>
              <CreateWastePage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_WASTE}>
              <WasteViewPage />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

WasteRouter.getRouterPath = () => `${WASTE_PATH}/*`;

export default WasteRouter;
