/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { LEAD_SOURCE } from 'crm/lead/shared/leadService';
import { FieldProps } from 'shared/uibuilder/field';

const LeadSourceField = ({ value: inputValue = null, source = null }: FieldProps) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));

  return <EnumField label="Lead Source" value={value} options={LEAD_SOURCE} />;
};

export default LeadSourceField;
