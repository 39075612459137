import React, { useEffect } from 'react';
import { FormProvider } from '../FormContext';
import Button from 'uibuilder/button/Button';
import useFormList, { FormListData } from './useFormList';
import useForm, { AfterSubmitProps } from '../formHelper';
import { ValidationSchema } from 'validation-schema-library';
import Box from 'uibuilder/Box';
import FormListButtonsLayout from './FormListButtonsLayout';
import Typography from 'uibuilder/Typography';
import Loading from '../../Loading';
import AlertErrorsForFormList from './AlertErrorsForFormList';
import Prompt from 'uibuilder/prompt/Prompt';

// TODO RECHECK

interface FormListTemplateProps {
  isBatchUpload: boolean;
  isBatchUploadBySize: boolean;
  batchUploadSize?: number;
  initialListData: any[];
  children: React.ReactNode;
  getValidationSchemaFunc: () => Promise<ValidationSchema>;
  isShowAdditionalInformation?: boolean;
  additionalInfoFunc?: (data: any[]) => string;
  submitFormFunc: (data: any[] | any) => Promise<object | void>;
  afterSubmit?: AfterSubmitProps<(response: any) => any, string | ((response: any) => any)>;
}

const FormListTemplate: React.FC<FormListTemplateProps> = ({
  isBatchUpload,
  initialListData,
  children,
  getValidationSchemaFunc,
  isShowAdditionalInformation,
  additionalInfoFunc,
  submitFormFunc,
  afterSubmit,
  isBatchUploadBySize,
  batchUploadSize,
}) => {
  const {
    clearFormError,
    errorsAfterSubmit,
    isLoading,
    formState,
    hasErrors,
    showError,
    formList,
    removeForm,
    setFormContext,
    handleSubmit,
    updateFormState,
  } = useFormList({
    isBatchUploadBySize,
    batchUploadSize,
    initialListData,
    submitFormFunc,
    afterSubmit,
    isBatchUpload,
  });

  return (
    <>
      {isLoading && <Loading style={{ zIndex: 1300 }} hasBackground hasPositionFixed />}
      <Prompt
        when={({ currentLocation: { pathname }, nextLocation: { pathname: newPathname } }) => {
          return formList.length > 0 || pathname !== newPathname;
        }}
        message="Are you sure you want to go away? Unsaved data will be lost."

        // when={formList.length > 0}
        // message={newLocation =>
        //   isPathnameChanged(pathname, newLocation.pathname)
        //     ? 'Are you sure you want to go away? Unsaved data will be lost.'
        //     : false
        // }
      />
      {formList.map(form => (
        <div key={form.customId}>
          <AlertErrorsForFormList
            onClose={() => clearFormError(form.customId)}
            isShow={showError && errorsAfterSubmit.find(item => item.customId === form.customId)?.error?.message}
            textContent={errorsAfterSubmit.find(item => item.customId === form.customId)?.error?.message}
          />
          <FormWrapper
            formData={form}
            setFormContext={setFormContext}
            removeForm={removeForm}
            getValidationSchemaFunc={getValidationSchemaFunc}
            updateFormState={updateFormState}
          >
            {children}
          </FormWrapper>
        </div>
      ))}
      {isShowAdditionalInformation && additionalInfoFunc && (
        <Typography>{additionalInfoFunc(formState.formsData)}</Typography>
      )}
      <FormListButtonsLayout isShowError={hasErrors && showError} isSubmitEnabled submitAction={handleSubmit} />
    </>
  );
};

interface FormWrapperProps {
  formData: FormListData;
  setFormContext: (id: string, context: any) => void;
  removeForm: (id: string) => void;
  getValidationSchemaFunc: () => Promise<ValidationSchema>;
  updateFormState: (id: string, data: any, type: 'data' | 'errors') => void;
  children: React.ReactNode;
}

const FormWrapper: React.FC<FormWrapperProps> = React.memo(
  ({ formData, setFormContext, removeForm, getValidationSchemaFunc, updateFormState, children }) => {
    const formContext = useForm({
      submitFormFunc: () => Promise.resolve(undefined),
      initialData: formData.data,
      getValidationSchemaFunc,
    });

    useEffect(() => {
      setFormContext(formData.customId, formContext);
    }, [formContext, formData.customId, setFormContext]);

    useEffect(() => {
      updateFormState(formData.customId, { data: formContext.data }, 'data');
    }, [formContext.data, formData.customId, updateFormState]);

    useEffect(() => {
      updateFormState(formData.customId, { errors: formContext.formErrors }, 'errors');
    }, [formContext.formErrors, formData.customId, updateFormState]);

    return (
      <>
        <Box sx={{ position: 'relative' }}>
          <FormProvider value={formContext}>
            <Button
              sx={{ zIndex: 1, position: 'absolute', top: '8px', right: '20px', padding: 0 }}
              color="error"
              variant="text"
              onClick={() => removeForm(formData.customId)}
            >
              Remove
            </Button>
            {children}
          </FormProvider>
        </Box>
      </>
    );
  },
);

export default FormListTemplate;
