/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import EmployeeAccountsCreationForm from './externalaccounts/creation/EmployeeAccountsCreationForm';
import AccountCreationContext from './externalaccounts/AccountCreationContext';
import { RUN_COMMAND_PERMISSION } from './externalaccounts/externalAccountsService';
import InternAccountsCreationForm from './externalaccounts/creation/InternAccountsCreationForm';
import EmployeeAccountsDeprovisionForm from './externalaccounts/deprovision/EmployeeAccountsDeprovisionForm';
import InternAccountsDeprovisionForm from './externalaccounts/deprovision/InternAccountsDeprovisionForm';
import useRoute from '../shared/routing/useRoute';

export const PRIVILEGED_ROUTES_PATH = '/privileged';
export const CREATE_USER_ACCOUNTS_PATH = '/employees/:employeeId/external-accounts';
export const CREATE_CANDIDATE_ACCOUNTS_PATH = '/candidates/:candidateId/external-accounts';
export const DEPROVISION_CANDIDATE_ACCOUNTS_PATH = '/candidates/:candidateId/external-accounts/deprovision';
export const DEPROVISION_USER_ACCOUNTS_PATH = '/employees/:employeeId/external-accounts/deprovision';

export const usePrivilegedEmployeeId = () => useParams<Dictionary<string>>().employeeId;
export const usePrivilegedCandidateId = () => useParams<Dictionary<string>>().candidateId as unknown as number;

const usePrivilegedRouts = () => {
  const baseRoute = useRoute({
    listRoute: PRIVILEGED_ROUTES_PATH,
  });

  return {
    ...baseRoute,
    createUserAccountsRoute: `${baseRoute.listRoute}${CREATE_USER_ACCOUNTS_PATH}`,
    createCandidateAccountsRoute: `${baseRoute.listRoute}${CREATE_CANDIDATE_ACCOUNTS_PATH}`,
    deprovisionCandidateAccountsRoute: `${baseRoute.listRoute}${DEPROVISION_CANDIDATE_ACCOUNTS_PATH}`,
    deprovisionUserAccountsRoute: `${baseRoute.listRoute}${DEPROVISION_USER_ACCOUNTS_PATH}`,
  };
};

const PrivilegedRouter = () => {
  const {
    createUserAccountsRoute,
    createCandidateAccountsRoute,
    deprovisionCandidateAccountsRoute,
    deprovisionUserAccountsRoute,
  } = usePrivilegedRouts();

  return (
    <ProtectedRouter basePath={PRIVILEGED_ROUTES_PATH}>
      <Routes>
        <Route
          path={createUserAccountsRoute}
          element={
            <AccountCreationContext permissionToCheck={RUN_COMMAND_PERMISSION}>
              <EmployeeAccountsCreationForm />
            </AccountCreationContext>
          }
        />

        <Route
          path={deprovisionUserAccountsRoute}
          element={
            <AccountCreationContext permissionToCheck={RUN_COMMAND_PERMISSION}>
              <EmployeeAccountsDeprovisionForm />
            </AccountCreationContext>
          }
        />

        <Route
          path={createCandidateAccountsRoute}
          element={
            <AccountCreationContext permissionToCheck={RUN_COMMAND_PERMISSION}>
              <InternAccountsCreationForm />
            </AccountCreationContext>
          }
        />

        <Route
          path={deprovisionCandidateAccountsRoute}
          element={
            <AccountCreationContext permissionToCheck={RUN_COMMAND_PERMISSION}>
              <InternAccountsDeprovisionForm />
            </AccountCreationContext>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

PrivilegedRouter.getRouterPath = () => `${PRIVILEGED_ROUTES_PATH}/*`;

export default PrivilegedRouter;
