/* istanbul ignore file */
import React from 'react';

import useCandidateNoteService from '../candidateNoteService';
import UpdateForm from '../../shared/form/UpdateForm';

const UpdateNoteForm = (props: any) => {
  return <UpdateForm service={useCandidateNoteService} {...props} />;
};

export default UpdateNoteForm;
