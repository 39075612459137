/* istanbul ignore file */
import React from 'react';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { Link } from 'react-router-dom';
import { useLeadUrl } from 'crm/lead/LeadRouter';

const LeadLinkField = ({
  idSource = undefined,
  idValue = undefined,
  nameValue = undefined,
  nameSource = undefined,
  ...props
}: FieldProps) => {
  const { getValue: getIdValue } = useFieldHelper({ value: idValue, source: idSource });
  const { getValue: getNameValue } = useFieldHelper({ value: nameValue, source: nameSource });
  const { getSingleEntityUrl } = useLeadUrl();

  const url = getSingleEntityUrl(getIdValue());
  const name = getNameValue();

  return (
    <TextField
      {...props}
      value={
        <Link to={url} target="_blank">
          {name}
        </Link>
      }
    />
  );
};

export default LeadLinkField;
