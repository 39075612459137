import React from 'react';
import CustomBadge from '../../../../recruitment/vacancy/shared/field/CustomBadge';
import useFieldHelper from '../../../../../shared/uibuilder/field/fieldHelper';
import { FieldProps } from '../../../../../shared/uibuilder/field';
import { KPI_STATUS, KPI_STATUS_OPTIONS } from '../../useKPIService';

const KpiStatusBadge = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const value = getValue() as any;

  const text = KPI_STATUS_OPTIONS[value];

  const getColor = () => {
    if (value === KPI_STATUS.INACTIVE) {
      return 'error';
    }

    return 'success';
  };

  return <CustomBadge text={text} color={getColor()} {...props} />;
};

export default KpiStatusBadge;
