/* istanbul ignore file */
import React from 'react';
import EnumDropdown, { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';

export const FEEDBACK_RELATIONSHIP_ALIAS = {
  EMPLOYEE_TO_YOU: 'EMPLOYEE_TO_YOU',
  YOU_TO_EMPLOYEE: 'YOU_TO_EMPLOYEE',
  SAME_PROJECT: 'SAME_PROJECT',
  NO_RELATION: 'NO_RELATION',
};

export const FEEDBACK_RELATIONSHIP = {
  [FEEDBACK_RELATIONSHIP_ALIAS.EMPLOYEE_TO_YOU]: 'The employee reported directly to you',
  [FEEDBACK_RELATIONSHIP_ALIAS.YOU_TO_EMPLOYEE]: 'You reported directly to the employee',
  [FEEDBACK_RELATIONSHIP_ALIAS.SAME_PROJECT]: 'You worked on the same project or client',
  [FEEDBACK_RELATIONSHIP_ALIAS.NO_RELATION]: 'No specific working relation',
};

const FeedbackRelationshipDropdown = (props: Omit<EnumDropdownProps, 'options'>) => {
  return <EnumDropdown {...props} options={FEEDBACK_RELATIONSHIP} />;
};

export default FeedbackRelationshipDropdown;
