import React from 'react';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { TextField } from 'shared/uibuilder/field';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import Show from 'shared/uibuilder/show/Show';
import useSowService, { BILLING_TYPE, BILLING_TYPE_OPTIONS } from '../useSowService';
import DateField from 'shared/uibuilder/field/DateField';
import EnumField from 'shared/uibuilder/field/EnumField';
import InvoiceTotalField from 'financialAnalytic/invoice/shared/field/InvoiceTotalField';
import MilestonesListFields from '../shared/fields/MilestonesListFields';
import Data from 'shared/uibuilder/Data';
import DeleteSowButton from '../shared/buttons/DeleteSowButton';
import EditSowButton from '../shared/buttons/EditSowButton';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const ViewSowProjectContent = () => {
  const { data: projectData } = useShowContext();
  const currentSowId = projectData.getValueBySource('sow.id');
  const { getDataMethod } = useSowService();

  return (
    <Show getDataMethod={() => getDataMethod(currentSowId)} isSmallLoader>
      <ShowSection
        justifyContent="space-between"
        title="SOW Details"
        actions={[<EditSowButton source="id" />, <DeleteSowButton />]}
      >
        <SectionRow>
          <TextField source="id" label="SOW ID" />

          <TextField source="projectName" label="Project Name" />

          <EnumField options={BILLING_TYPE_OPTIONS} source="billingType" label="Billing Type" />

          <TextField label="Account" source="accountId" />
        </SectionRow>

        <SectionRow>
          <TextField
            isVisible={(data: Data) => data.getValueBySource('billingType') === BILLING_TYPE.MILESTONES}
            source="estimatedHours"
            label="Estimated Hours"
          />

          <InvoiceTotalField label="Amount" source="totalAmount" currencySource="currency" />
        </SectionRow>

        <SectionRow>
          <DateField source="startDate" label="Start Date" />
          <DateField source="endDate" label="End Date" />
        </SectionRow>
      </ShowSection>

      <ShowSection
        title="Milestones"
        isVisible={(data: Data) => data.getValueBySource('billingType') === BILLING_TYPE.MILESTONES}
      >
        <BigSectionRow>
          <MilestonesListFields source="milestones" />
        </BigSectionRow>
      </ShowSection>
    </Show>
  );
};

export default ViewSowProjectContent;
