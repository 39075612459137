/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { VacancyEnumFieldProps } from './VacancyEnumUtils';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';

const VacancyStatusField = ({ value: inputValue, source, ...props }: VacancyEnumFieldProps) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });

  const value = inputValue || getValue();

  const getColor = () => {
    let result: BasicColors = 'neutralLight';
    if (value === 'OPEN') {
      result = 'success';
    } else if (value === 'DRAFT') {
      result = 'primary';
    }

    return result;
  };

  return <CustomBadge text={value} color={getColor()} {...props} />;
};

export default VacancyStatusField;
