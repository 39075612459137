/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import AccountTypeDropdown from 'crm/account/shared/input/AccountTypeDropdown';

const AccountTypeFilter = ({ source, ...props }: any) => {
  return (
    <DropDownFilter source={source} {...props}>
      <AccountTypeDropdown source={source} />
    </DropDownFilter>
  );
};

export default AccountTypeFilter;
