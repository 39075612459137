/* istanbul ignore file */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import useArtifactInputHelper from 'artifact/shared/input/ArtifactUploaderInput/artifactsInputHelper';
import { unFlattenObject } from 'shared/uibuilder/form/formHelper';
import { isArray } from 'lodash';
import ArtifactUploaderInput from 'artifact/shared/input/ArtifactUploaderInput';

const MAX_SIZE = 134217728; // 128MB
const ACCEPT_FORMATS = 'txt,xlsx';

const UploadInputWithoutArtifactID = ({ formats, ...props }: any) => {
  const { getValue, getSource, getCollectionOnChangeCallback } = useArtifactInputHelper({
    ...props,
    fileExtensions: formats || ACCEPT_FORMATS,
    maximumSize: MAX_SIZE,
  });
  const collectionOnChangeCallback = getCollectionOnChangeCallback() as any;

  const artifactsIdsList = isArray(getValue()) ? getValue().map((artifact: any) => artifact.id) : [];

  const addArtifactToContext = (id: string, file: File) => {
    if (collectionOnChangeCallback) {
      collectionOnChangeCallback((prev: any) => {
        return unFlattenObject({ [getSource()]: [...(prev || []), { id, file }] });
      });
    }
  };

  const removeArtifactFromContext = (id: string) => {
    const source = getSource();

    if (collectionOnChangeCallback) {
      collectionOnChangeCallback((prev: any) => {
        const newValue =
          prev?.filter((artifact: any) => {
            return artifact.id !== id;
          }) || [];

        return unFlattenObject({ [source]: newValue });
      });
    }
  };

  const uploadFile = async ({ data }: any) => {
    const fileName = data.get('file')?.name || '';
    const fileExtension = fileName.split('.').pop();

    return Promise.resolve({
      response: JSON.stringify({
        id: uuidv4(),
        type: ['png', 'jpeg', 'gif', 'jpg'].includes(fileExtension) ? `image/${fileExtension}` : fileExtension,
      }),
    });
  };

  return (
    <ArtifactUploaderInput
      {...props}
      uploadFile={uploadFile}
      fileExtensions={formats}
      maximumSize={MAX_SIZE}
      addArtifactToContext={addArtifactToContext}
      removeArtifactFromContext={removeArtifactFromContext}
      value={artifactsIdsList}
    />
  );
};

export default UploadInputWithoutArtifactID;
