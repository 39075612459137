import React, { useEffect, useState } from 'react';
import Loading from 'shared/uibuilder/Loading';
import useAuthenticationService from 'authentication/authenticationService';
import isOneClickEnabled from 'authentication/OneClick/isOneClickEnabled';
import { useAuthenticationUrl, ONE_CLICK_PATH } from 'authentication/AuthenticationRouter';
import useAuthenticatedUserService, { UserName } from 'authentication/authenticatedUserService';

export interface UserRedirectService {
  checkAuthenticationAndRedirectIfFailed: () => Promise<void>;
}

const useUserRedirectService = (): UserRedirectService => {
  const { getAzureAuthUrl } = useAuthenticationUrl();
  const { isAuthenticated } = useAuthenticationService();

  const checkAuthenticationAndRedirectIfFailed = async (): Promise<void> => {
    const isUserAuthenticated = await isAuthenticated();

    if (!isUserAuthenticated) {
      if (isOneClickEnabled()) {
        window.location.assign(ONE_CLICK_PATH);
      } else {
        window.location.assign(getAzureAuthUrl());
      }
    }
  };

  return {
    checkAuthenticationAndRedirectIfFailed,
  };
};

const ProtectedRoute = ({ children }: any) => {
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState<Nullable<UserName>>(null);
  const { getUserName } = useAuthenticatedUserService();
  const { checkAuthenticationAndRedirectIfFailed } = useUserRedirectService();

  useEffect(() => {
    (async () => {
      await checkAuthenticationAndRedirectIfFailed();
      const name = await getUserName();

      setUserName(name);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || !userName) {
    return <Loading />;
  } else {
    return children;
  }
};

export default ProtectedRoute;
