/* istanbul ignore file */
import EnumField from 'shared/uibuilder/field/EnumField';
import React from 'react';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { getSourceOptions } from 'erp/candidate/applications/shared/candidateApplicationService';

const CandidateApplicationSourceField = (props: FieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);

  return <EnumField label={getLabel() || 'Source'} value={getValue()} options={getSourceOptions()} />;
};

export default CandidateApplicationSourceField;
