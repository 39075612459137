import React from 'react';

import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { omit } from 'lodash';

export interface NameFieldValue {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  maidenName?: string;
}

interface SingleLineNameFieldLayoutProps extends NameFieldValue {
  label?: string;
}

export type SingleLineNameFieldLayoutType = ReactComponent<SingleLineNameFieldLayoutProps>;

const NameField = ({ ignoredNameParts = [], ...props }: FieldProps<NameFieldValue>) => {
  const { getValue, getLabel } = useFieldHelper(props);

  const { NameFieldLayout } = useUiTheme<SingleLineNameFieldLayoutType>();

  const nameField = omit(getValue() || {}, ignoredNameParts) as any;

  return (
    <NameFieldLayout
      firstName={nameField.firstName}
      lastName={nameField.lastName}
      middleName={nameField.middleName}
      maidenName={nameField.maidenName}
      label={getLabel() as string}
    />
  );
};

export default NameField;
