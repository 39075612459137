/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import WorkingHoursList from 'erp/workinghours/List';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import { READ_WORKING_HOURS_REPORT } from 'erp/workinghours/shared/workingHoursService';

export const PAYROLL_WORKING_HOURS_PATH = '/payroll/working-hours';

export const useWorkingHoursRoute = () => {
  return useRoute({ listRoute: PAYROLL_WORKING_HOURS_PATH });
};

export const useLeaveUrl = () => {
  return useEntityUrl({
    baseLink: PAYROLL_WORKING_HOURS_PATH,
  });
};

const WorkingHoursRouter = () => {
  const { listRoute } = useWorkingHoursRoute();

  return (
    <ProtectedRouter basePath={PAYROLL_WORKING_HOURS_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_WORKING_HOURS_REPORT}>
              <WorkingHoursList />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

WorkingHoursRouter.getRouterPath = () => PAYROLL_WORKING_HOURS_PATH;

export default WorkingHoursRouter;
