/* istanbul ignore file */
import React, { useState } from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ViewArtifactStoragePageMenu from 'artifactStorage/Show/ViewArtifactStorageFilePageMenu';
import { useArtifactStorageId, useArtifactStorageUrl } from 'artifactStorage/ArtifactStorageRouter';
import useArtifactStorageService, {
  ArtifactFileTypeLabel,
  DELETE_FILE,
} from 'artifactStorage/shared/artifactStorageService';
import ArtifactStorageFileHeader from 'artifactStorage/shared/ArtifactStorageFileHeader';
import DownloadArtifactButton from 'artifactStorage/shared/button/DownloadArtifactButton';
import ArtifactStorageBreadcrumbs from 'artifactStorage/shared/ArtifactStorageBreadcrumbs';
import { DateField, EnumField, NumberField, TextField } from 'shared/uibuilder/field';
import MetaInformationField from 'artifactStorage/Show/shared/field/MetaInformationField';
import ArtifactRelationField from 'artifactStorage/Show/shared/field/ArtifactRelationField';
import ArtifactStorageHashTooltip from 'artifactStorage/shared/ArtifactStorageHashTooltip';
import ArtifactStorageSizeField from 'artifactStorage/shared/field/ArtifactStorageSizeField';
import { EmployeeLinkField } from 'erp/employee';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import DeleteButton from 'uibuilder/button/DeleteButton';

const ViewArtifactStorageFile = () => {
  const id = useArtifactStorageId()!;
  const { getById, deleteById } = useArtifactStorageService();
  const { getListUrl } = useArtifactStorageUrl();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const redirectUrl = () => {
    const path = breadcrumbs.slice(0, breadcrumbs.length - 1).map(({ alias }) => alias);
    return path.length ? `${getListUrl()}/${path.join('/').toLowerCase()}` : getListUrl();
  };

  const getData = async () => {
    const response = await getById(id);
    setBreadcrumbs(response.path);
    return response;
  };

  return (
    <Show getDataMethod={() => getData()}>
      <ShowPageLayout
        breadcrumbs={<ArtifactStorageBreadcrumbs breadcrumbs={breadcrumbs} />}
        menu={<ViewArtifactStoragePageMenu />}
        entityHeader={<ArtifactStorageFileHeader />}
        headerButtons={[
          <DownloadArtifactButton source="artifact" />,
          <DeleteButton
            deleteMethod={() => deleteById(id)}
            displayMessage="Do you really want to delete this document?"
            afterDelete={{
              redirectUrl: redirectUrl(),
              successMessage: 'The Artifact Storage File has been successfully deleted.',
              errorMessage: "Can't delete Artifact Storage File",
            }}
            permissionToCheck={DELETE_FILE}
          />,
        ]}
      >
        <ShowSection title="Artifact Information">
          <SectionRow>
            <NumberField source="id" label="ID" />
            <EnumField source="metaInfoType" label="Document Type" options={ArtifactFileTypeLabel} />
            <TextField
              source="artifact.hash"
              label={
                <>
                  Hash <ArtifactStorageHashTooltip />
                </>
              }
            />
            <ArtifactStorageSizeField source="artifact.size" label="Size" />
          </SectionRow>
          <SectionRow>
            <AttachmentsListField source="artifact.id" label="Document" isDownloadAvailable={false} isOneArtifact />
            <DateField source="createdAt" label="Created At" />
            <EmployeeLinkField nameSource="createdByName" source="createdById" label="Created By" />
          </SectionRow>
        </ShowSection>
        <ShowSection title="Meta Information">
          <MetaInformationField source="metaInformation" />
        </ShowSection>
        <ShowSection title="Artifact Relations">
          <ArtifactRelationField source="artifactRelation" />
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewArtifactStorageFile;
