/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useEntityUrl from 'shared/routing/useEntityUrl';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import { Route } from 'shared/routing';
import EmployeeAssignmentsListPage from 'erp/employee/assignments/List';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import CreateEmployeeAssignment from 'erp/employee/assignments/createupdate/Create';
import { CREATE_ASSIGNMENT, UPDATE_ASSIGNMENT } from 'erp/assignment/shared/assignmentService';
import UpdateEmployeeAssignment from 'erp/employee/assignments/createupdate/Update';

export const useEmployeeAssignmentUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();

  const baseUrls = useEntityUrl({
    baseLink: `${getSingleEntityUrl()}/assignments`,
  });

  const getListUrl = (id?: any) => `${getSingleEntityUrl(id)}/assignments`;

  const getCopyEntityUrl = (id?: string): string => `${baseUrls.getSingleEntityUrl(id)}/copy`;

  return {
    ...baseUrls,
    getCopyEntityUrl,
    getListUrl,
  };
};

export const useEmployeeAssignmentRoute = () => {
  const routers = useRoute({
    singleEntityRoute: `/:assignmentId`,
    listRoute: `/assignments`,
  });

  return {
    ...routers,
    copyEntityRoute: `${routers.singleEntityRoute}/copy`,
  };
};

export const useEmployeeAssignmentId = () => {
  return useParams<Dictionary<string>>().assignmentId;
};

const EmployeeAssignmentsRouter = () => {
  const { listRoute, createRoute, updateRoute, copyEntityRoute } = useEmployeeAssignmentRoute();

  return (
    <Routes>
      <Route path={listRoute} element={<EmployeeAssignmentsListPage />} />

      <Route
        path={createRoute}
        element={
          <ProtectedRoute permissionToCheck={CREATE_ASSIGNMENT}>
            <CreateEmployeeAssignment />
          </ProtectedRoute>
        }
      />

      <Route
        path={updateRoute}
        element={
          <ProtectedRoute permissionToCheck={UPDATE_ASSIGNMENT}>
            <UpdateEmployeeAssignment />
          </ProtectedRoute>
        }
      />

      <Route
        path={copyEntityRoute}
        element={
          <ProtectedRoute permissionToCheck={CREATE_ASSIGNMENT}>
            <CreateEmployeeAssignment />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default EmployeeAssignmentsRouter;
