/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useAssignmentService, {
  ASSIGNMENT_DELETE_MESSAGE,
  DELETE_ASSIGNMENT,
} from 'erp/assignment/shared/assignmentService';
import { useAssignmentUrl } from 'erp/assignment/AssignmentRouter';

const DeleteAssignmentButton = () => {
  const { id: assignmentId } = useParams<Dictionary<string>>();
  const { deleteById } = useAssignmentService();
  const { getListUrl } = useAssignmentUrl();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(assignmentId!)}
      displayMessage={ASSIGNMENT_DELETE_MESSAGE}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Assignment has been successfully deleted.',
        errorMessage: `Can't delete assignment`,
      }}
      permissionToCheck={DELETE_ASSIGNMENT}
    />
  );
};

export default DeleteAssignmentButton;
