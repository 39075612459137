// libs
import React, { ReactElement } from 'react';
// components
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import SensitiveDataPlug from 'shared/uibuilder/sensitiveData/SensitiveDataPlug';
import ToggleSensitiveDataButton from 'shared/uibuilder/sensitiveData/buttons/ToggleSensitiveDataButton';
// services
import { useSensitiveDataService } from 'shared/uibuilder/sensitiveData';
import { DavinciFormPageLayoutProps } from 'uibuilder/layout/DavinciFormPageLayout';
// styles
import './SensitiveRelationListPageLayout.scss';

interface SensitiveUpdatePageLayoutProps {
  buttons?: ReactElement[];
  children?: ReactElement | ReactElement[];
  timerId?: string;
  [key: string]: any;
}

const SensitiveUpdatePageLayout: React.FC<SensitiveUpdatePageLayoutProps> = ({
  actions = [],
  children,
  timerId,
  ...other
}) => {
  const { isTimerActive, isShown, toggleSensitiveData, isSensitiveDataLoading } = useSensitiveDataService({ timerId });

  const getActions = () => {
    const buttonsArray = [...actions];
    const toggleButton = (
      <ToggleSensitiveDataButton
        key="toggle-sensitive-data"
        isShown={isShown}
        onClick={() => toggleSensitiveData([])}
        disabled={isSensitiveDataLoading}
        isTimerActive={isTimerActive}
      />
    );

    buttonsArray.unshift(toggleButton);

    return buttonsArray;
  };

  return (
    <UpdatePageLayout actions={getActions()} {...(other as DavinciFormPageLayoutProps)}>
      {(isShown ? children : <SensitiveDataPlug />) as ReactElement}
    </UpdatePageLayout>
  );
};

export default SensitiveUpdatePageLayout;
