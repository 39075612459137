/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import { TextField, DateField, BooleanField } from 'shared/uibuilder/field';
import AssetHeader from 'financialAnalytic/assets/shared/AssetHeader';
import SingleAssetBreadcrumbs from 'financialAnalytic/assets/shared/SingleAssetBreadcrumbs';
import AssetCategoryField from 'financialAnalytic/assets/shared/field/AssetCategoryField';
import AssetCategoryTypeField from 'financialAnalytic/assets/shared/field/AssetCategoryTypeField';
import AssetConditionField from 'financialAnalytic/assets/shared/field/AssetConditionField';
import AssetDurationField from 'financialAnalytic/assets/shared/field/AssetDurationField';
import OfficeField from 'financialAnalytic/shared/field/OfficeField';
import LedgerLink from 'financialAnalytic/ledger/shared/field/LedgerLink';
import CopyAssetButton from 'financialAnalytic/assets/shared/button/CopyAssetButton';
import InvoiceTotalField from 'financialAnalytic/invoice/shared/field/InvoiceTotalField';
import AssetStatusField from 'financialAnalytic/assets/shared/field/AssetStatusField';
import {
  isExpandedEquipmentCategory as initialIsExpandedEquipmentCategory,
  isIntangibleAsset as initialIsIntangibleAsset,
  isSubscription as initialIsSubscription,
  isAutoRenewSubscription as initialIsAutoRenewSubscription,
  ASSET_STATUS,
} from 'financialAnalytic/assets/useAssetService';
import { BUSINESS_OBJECT_TYPES } from 'financialAnalytic/ledger/useLedgerService';
import TransfersListField from 'financialAnalytic/shared/field/TransfersListField';
import { useAssetId } from 'financialAnalytic/assets/AssetRouter';
import HtmlField from 'shared/uibuilder/field/HtmlField';
import Box from 'uibuilder/Box';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import AssignAssetOwnerButton from 'financialAnalytic/assets/shared/button/AssignAssetOwnerButton';
import ReturnToStorageAssetButton from 'financialAnalytic/assets/shared/button/ReturnToStorageAssetButton';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import EditAssetButton from 'financialAnalytic/assets/shared/button/EditAssetButton';
import AssetMenu from 'financialAnalytic/assets/shared/AssetMenu';
import AllNotesButton from '../shared/button/AllNotesButton';
import DisposeAssetButton from '../shared/button/DisposeAssetButton';
import WriteOffAssetButton from '../shared/button/WriteOffAssetButton';

const isExpandedEquipmentCategory = (data: any) =>
  initialIsExpandedEquipmentCategory(data.getValueBySource('category'));

const isIntangibleAsset = (data: any) => initialIsIntangibleAsset(data.getValueBySource('category'));

const isSubscription = (data: any) => initialIsSubscription(data.getValueBySource('type'));

const isAutoRenewSubscription = (data: any) =>
  initialIsAutoRenewSubscription(data.getValueBySource('subscriptionDetails'));

const ViewAssetPageContent = ({
  isSensitiveDataLoading = false,
  isSensitiveDataIsShown = false,
  toggleSensitiveDataButton = <></>,
}: {
  isSensitiveDataLoading?: boolean;
  isSensitiveDataIsShown?: boolean;
  toggleSensitiveDataButton?: React.ReactElement;
}) => {
  const id = useAssetId();

  return (
    <ShowPageLayout
      entityHeader={<AssetHeader />}
      breadcrumbs={<SingleAssetBreadcrumbs />}
      headerButtons={[
        toggleSensitiveDataButton,
        <EditAssetButton
          isVisible={(data: any) => ![ASSET_STATUS.DISPOSED].includes(data?.getValueBySource('status'))}
        />,
        <CopyAssetButton source="id" />,
        <WriteOffAssetButton />,
        <DisposeAssetButton />,
      ]}
      hasSeparateSections
      menu={<AssetMenu />}
    >
      <ShowSection title="Asset Details">
        <SectionRow>
          <TextField source="inventoryNumber" label="Inventory Number" />
          <AssetCategoryField source="category" label="Category" placeholder="Select Category" />
          <AssetCategoryTypeField source="type" label="Type" categorySource="category" />
          <OfficeField source="office" />
        </SectionRow>

        <SectionRow>
          <TextField source="name" label="Name" />
          <TextField source="serialNumber" label="Serial Number" />
          <AssetConditionField source="condition" label="Condition" isVisible={isExpandedEquipmentCategory} />
          <TextField source="location" />
        </SectionRow>

        <BigSectionRow>
          <TextField source="characteristics" />
        </BigSectionRow>
      </ShowSection>

      <ShowSection title="Financial Information">
        <SectionRow>
          <LedgerLink source="attribution.balanceAccountId" nameSource="balanceAccountId" label="Balance Account" />
          <LedgerLink source="attribution.expenseAccountId" nameSource="expenseAccountId" label="Expense Account" />
          <TextField source="attribution.budgetCategory" nameSource="budgetCategory" label="Budget Category" />
          <AssetStatusField source="status" label="Status" />
        </SectionRow>

        <SectionRow>
          <InvoiceTotalField
            source="costInPurchaseCurrency.amount"
            currencySource="costInPurchaseCurrency.currency"
            label="Purshase Cost"
            isVisible={isSensitiveDataIsShown}
          />
          <InvoiceTotalField
            source="costInStandardOperationCurrency.amount"
            currencySource="costInStandardOperationCurrency.currency"
            label="Cost In Target Currency"
            isVisible={isSensitiveDataIsShown}
          />
          <AssetDurationField
            source="usefulLifePeriod.amount"
            timeUnitSource="usefulLifePeriod.timeUnit"
            label="Useful Life"
          />
        </SectionRow>

        <SectionRow>
          <DateField label="Purchase Date" source="purchaseDate" />
          <DateField label="Validity Start Date" source="validityStartDate" isVisible={isIntangibleAsset} />
          <DateField label="Validity End Date" source="validityEndDate" isVisible={isIntangibleAsset} />
          <DateField label="Warranty End Date" source="warrantyEndDate" isVisible={isExpandedEquipmentCategory} />
          <DateField
            dateFormat={DATE_FORMAT.SIMPLE_MONTH}
            source="latestAccountingCycle"
            label="Latest Amortization Cycle"
          />
        </SectionRow>
      </ShowSection>

      <ShowSection title="Subscription details" isVisible={isSubscription}>
        <SectionRow>
          <BooleanField source="subscriptionDetails.isAutoRenew" label="Is auto-renew?" />
          <DateField
            label="Next Payment Date"
            source="subscriptionDetails.nextPaymentDate"
            isVisible={isAutoRenewSubscription}
          />
          <DateField
            label="Expiry Date"
            source="subscriptionDetails.expiryDate"
            isVisible={(data: any) => !isAutoRenewSubscription(data)}
          />
          <AssetDurationField
            source="subscriptionDetails.billingCycle.amount"
            timeUnitSource="subscriptionDetails.billingCycle.timeUnit"
            label="Billing cycle"
            prefix="Every "
          />
        </SectionRow>
      </ShowSection>

      <ShowSection title="Assignment Details" actions={[<AssignAssetOwnerButton />, <ReturnToStorageAssetButton />]}>
        <SectionRow>
          <EmployeeLinkFieldWithNameLoading
            source="assignmentDetails.currentOwner"
            nameSource="assignmentDetails.currentOwnerFullName"
            label="Current Owner"
          />
          <DateField
            source="assignmentDetails.provisionDate"
            label="Provision Date"
            isVisible={(data: any) => !!data.getValueBySource('assignmentDetails')?.currentOwner}
          />
        </SectionRow>
      </ShowSection>

      <ShowSection title="Note" actions={[<AllNotesButton />]}>
        <BigSectionRow>
          <Box sx={{ '.field__wrapper > h3': { display: 'none' } }}>
            <HtmlField source="note.content" label="Note" />
          </Box>
        </BigSectionRow>
      </ShowSection>

      <ShowSection isVisible={isSensitiveDataIsShown || isSensitiveDataLoading}>
        <TransfersListField
          businessObjectId={id}
          businessObjectType={BUSINESS_OBJECT_TYPES.ASSET}
          startDateSource="purchaseDate"
        />
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewAssetPageContent;
