import { NON_STRICT_RULES } from '../shared/leaveService';
import RulesViolationReasonDropdown from '../shared/input/RulesViolationReasonDropdown';
import { TextArea } from '../../../shared/uibuilder/form/input';
import NonStrictRulesHandler from '../../shared/rules/NonStrictRulesHandler';
import React from 'react';

const LeaveNotStrictRulesHandler = ({ errorMap }: { errorMap: Dictionary<any> }) => {
  return (
    <NonStrictRulesHandler
      title="Your leave request violates the following rules:"
      nonStrictRules={NON_STRICT_RULES}
      errorMap={errorMap}
    >
      <p>
        Please return to leave creation form and change the leave details so that your request does not violate the
        rules or submit request with rules violated.
      </p>
      <p>
        If you need to request the leave without changes in its details, please specify a reason for the rules
        violation. Your Resource manager will review the request prior to its processing and may contact you for
        additional discussion.
      </p>

      <RulesViolationReasonDropdown source="rulesViolationReason" label="Reasons for business rules violation" />
      <p>For your request evaluating, enter any additional information if you want.</p>
      <TextArea source="comment" label="" />
    </NonStrictRulesHandler>
  );
};

export default LeaveNotStrictRulesHandler;
