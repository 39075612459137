/* istanbul ignore file */
import React, { useState } from 'react';
import useMailboxConsentService, { REVOKE_CONSENT_PERMISSION } from 'erp/employee/mailboxConsent/mailboxConsentService';
import { ButtonWithConfirmation } from 'uibuilder/button';

const DisableEmailScanningButton = () => {
  const { revokeConsent } = useMailboxConsentService();
  const [isVisible, setIsVisible] = useState(true);
  return isVisible ? (
    <ButtonWithConfirmation
      modalConfirmButtonText="Submit"
      displayMessage="If you disable Email Scanning, DaVinci will not be able to analyze the mailbox for that user. Are you sure?"
      submitMethod={revokeConsent}
      color="secondary"
      outline
      permissionToCheck={REVOKE_CONSENT_PERMISSION}
      afterSubmit={{
        successMessage: 'Successfully disabled Email Scanning!',
        execute: () => setIsVisible(false),
      }}
    >
      Disable Email Scanning
    </ButtonWithConfirmation>
  ) : null;
};

export default DisableEmailScanningButton;
