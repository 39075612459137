/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useContactUrl } from 'crm/contact/ContactRouter';

const EditContactButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useContactUrl();

  return <EditButton path={id => getUpdateEntityUrl(id)} {...props} />;
};

export default EditContactButton;
