import { useFinanceApi } from 'api';
import useCrudService from 'shared/crud';
import { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import customerInvoiceValidation from './createupdate/customerInvoiceValidation';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export const READ_CUSTOMER_INVOICE_LIST = 'COMPANY_READ_CUSTOMER_INVOICES';
export const READ_CUSTOMER_INVOICE = 'COMPANY_READ_CUSTOMER_INVOICES';
export const UPDATE_CUSTOMER_INVOICE = 'COMPANY_WRITE_CUSTOMER_INVOICES';
export const CREATE_CUSTOMER_INVOICE = 'COMPANY_WRITE_CUSTOMER_INVOICES';
export const REJECT_CUSTOMER_INVOICE = 'COMPANY_WRITE_CUSTOMER_INVOICES';
export const MARK_AS_PAID_CUSTOMER_INVOICE = 'COMPANY_WRITE_CUSTOMER_INVOICES';

export const CUSTOMER_INVOICE_PATH = '/customer-invoices';
export const RESOURCE_URL = '/customer-invoices';

export const CUSTOMER_INVOICE_STATUSES = {
  PENDING: 'PENDING',
  PAID: 'PAID',
  REJECTED: 'REJECTED',
  ACCRUED: 'ACCRUED',
};

export const CUSTOMER_INVOICE_STATUSES_OPTIONS = {
  [CUSTOMER_INVOICE_STATUSES.PENDING]: 'Pending',
  [CUSTOMER_INVOICE_STATUSES.PAID]: 'Paid',
  [CUSTOMER_INVOICE_STATUSES.REJECTED]: 'Rejected',
  [CUSTOMER_INVOICE_STATUSES.ACCRUED]: 'Accrued',
};

type CustomerInvoiceSensitiveDataType = {
  [key: string]: {
    sum: {
      amount: string;
      currency: string;
    };
    invoiceId: string;
    correctedInvoiceId: Nullable<string>;
    id: string;
  }[];
};

const useCustomerInvoiceService = () => {
  const { sendPostRequest } = useFinanceApi();

  const {
    search: baseSearch,
    create: initialCreate,
    update: initialUpdate,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useFinanceApi,
  });

  const search = (request: ResourceData) => {
    const params = cloneDeep(request);

    return baseSearch(params);
  };

  const create = async (data: FormFieldsData) => initialCreate(data);

  const update = async (id: StringOrNumber, data: FormFieldsData) => initialUpdate(id, data);

  const rejectCustomerInvoice = async (id: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/reject`);
    return result.json();
  };

  const cancelCustomerInvoicePayment = async (id: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/cancel-payment`);
    return result.json();
  };

  const maskAsPaidCustomerInvoice = async (
    id: string,
    data: {
      invoicePaidDate: string;
      bankTransactionId: string;
    },
  ) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/mark-as-paid`, data);
    return result.json();
  };

  const mergeInvoiceData = (invoiceSums: CustomerInvoiceSensitiveDataType) => {
    const mergedResults = new Map();

    Object.entries(invoiceSums).forEach(([invoiceId, details]) => {
      details.forEach(value => {
        const keyName = value.correctedInvoiceId ? 'invoiceCorrectionSum' : 'invoiceSum';

        mergedResults.set(invoiceId, {
          ...mergedResults.get(invoiceId),
          [keyName]: {
            amount: value.sum.amount,
            currency: value.sum.currency,
          },
        });
      });
    });

    return Object.fromEntries(mergedResults);
  };

  const getSensitiveDataByIds = async (ids: string[]) => {
    if (!ids.length) {
      return {
        totalElements: 0,
        totalPages: 1,
        result: [],
      };
    }

    const sumsResult = await sendPostRequest(`${RESOURCE_URL}/sums`, {
      invoiceIds: ids,
    });

    const parseSum = await sumsResult.json();

    return mergeInvoiceData(parseSum);
  };

  const getValidationSchema = () => Promise.resolve(customerInvoiceValidation);

  return {
    ...baseCrud,
    create,
    update,
    search,
    rejectCustomerInvoice,
    cancelCustomerInvoicePayment,
    maskAsPaidCustomerInvoice,
    getSensitiveDataByIds,
    getValidationSchema,
  };
};

export default useCustomerInvoiceService;
