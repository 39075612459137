/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';

const EmployeesBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useEmployeeUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Employees" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default EmployeesBreadcrumbs;
