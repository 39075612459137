/* istanbul ignore file */
import React from 'react';
import { ARTIFACT_ID } from 'artifact/const';
import ArtifactUploaderInput, { ArtifactUploaderInputProps } from 'artifact/shared/input/ArtifactUploaderInput';

const LeaveApplicationScanUploaderInput = (props: ArtifactUploaderInputProps) => {
  return <ArtifactUploaderInput {...props} artifactTypeId={ARTIFACT_ID.LEAVE_APPLICATION_SCAN} />;
};

export default LeaveApplicationScanUploaderInput;
