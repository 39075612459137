/* istanbul ignore file */
import React from 'react';
import DropDownFilter, { DropDownFilterProps } from 'shared/uibuilder/list/filter/DropDownFilter';
import LeaveTypeDropdown from 'erp/leave/shared/input/LeaveTypeDropdown';

const LeaveTypeFilter = (props: Omit<DropDownFilterProps, 'children'>) => {
  return (
    <DropDownFilter {...props}>
      <LeaveTypeDropdown />
    </DropDownFilter>
  );
};

export default LeaveTypeFilter;
