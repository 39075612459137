/* istanbul ignore file */
import CreateNoteForm from 'crm/shared/timeline/note/form/CreateNoteForm';
import UpdateNoteForm from 'crm/shared/timeline/note/form/UpdateNoteForm';
import NoteTimelineRecordLayout from 'crm/shared/timeline/note/NoteTimelineRecordLayout';
import { UPDATE_CRM_NOTE_PERMISSION } from './crmNoteService';

const useCrmNoteItem = () => ({
  AddForm: CreateNoteForm,
  UpdateForm: UpdateNoteForm,
  isApplicable: (item: { note: any }) => item.note,
  source: 'note',
  urlPostfix: 'notes',
  RecordLayout: NoteTimelineRecordLayout,
  name: 'Note',
  permissionToCheck: UPDATE_CRM_NOTE_PERMISSION,
});

export default useCrmNoteItem;
