/* istanbul ignore file */
import { ThemeProvider } from '@mui/material/styles';
import React, { createContext, useCallback, useState } from 'react';
import { themeCreator } from './base';

interface themeValue {
  (themeName: string): void;
}

export const ThemeContext = createContext<themeValue | undefined>(undefined);

const CustomThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const curThemeName = localStorage.getItem('appTheme') || 'violetTheme';

  const [themeName, _setThemeName] = useState(curThemeName);

  const theme = themeCreator(themeName);

  const setThemeName = useCallback((name: string): void => {
    localStorage.setItem('appTheme', name);
    _setThemeName(name);
  }, []);

  return (
    <ThemeContext.Provider value={setThemeName}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default CustomThemeProvider;
