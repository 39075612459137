import React from 'react';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import AccountingCycleHeader from '../shared/AccountingCycleHeader';
import AccountingCycleBreadcrumbsItem from './AccountingCycleBreadcrumbsItem';
import CloseAccountingCycleButton from '../shared/button/CloseAccountingCycleButton';
import { EnumField, TextField } from 'shared/uibuilder/field';
import {
  ACCOUNTING_CYCLE_EVENTS,
  ACCOUNTING_CYCLE_EVENTS_OPTIONS,
  ACCOUNTING_CYCLE_OPTIONS,
  ACCOUNTING_CYCLE_STATUS,
  AccountingCycleEventType,
  AccountingCycleType,
} from '../useAccountingCycle';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import DateField from 'shared/uibuilder/field/DateField';
import FullDateField from 'shared/uibuilder/field/FullDateField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import List from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import AccountingCycleActionButton from 'erp/costaccounting/accountingCycle/shared/button/AccountingCycleActionButton';
import Box from 'uibuilder/Box';

const ViewAccountingCycleContent = () => {
  const { getValue: getEvents } = useFieldHelper<AccountingCycleEventType[]>({ source: 'events' });
  const { getValue: getId } = useFieldHelper<string>({ source: 'id' });
  const events = getEvents();
  const id = getId();
  const listData = {
    totalPages: 1,
    totalElements: Object.values(ACCOUNTING_CYCLE_EVENTS).length,
    items: Object.values(ACCOUNTING_CYCLE_EVENTS).map(event => {
      const eventData = events?.find((item: AccountingCycleEventType) => item?.eventType === event);
      return {
        id: event,
        eventType: ACCOUNTING_CYCLE_EVENTS_OPTIONS[event],
        triggeredBy: eventData?.triggeredBy || null,
        createdAt: eventData?.createdAt || null,
        event,
      };
    }),
  };

  return (
    <ShowPageLayout
      entityHeader={<AccountingCycleHeader />}
      breadcrumbs={<AccountingCycleBreadcrumbsItem />}
      headerButtons={[<CloseAccountingCycleButton />]}
    >
      <ShowSection title="Main information">
        <SectionRow>
          <TextField source="id" label="Cycle" />
          <EnumField source="status" label="Status" options={ACCOUNTING_CYCLE_OPTIONS} />
        </SectionRow>

        <List data={listData}>
          <Box
            sx={{
              '* .list-general .list-btn-container .MuiButtonBase-root': { width: 'auto' },
              '* .list-general .list-btn-container': { textAlign: 'left' },
            }}
          >
            <DataGridLayout
              showPagination={false}
              showTotal={false}
              buttons={[<AccountingCycleActionButton id={id} />]}
            >
              <TextField label="Event Type" source="eventType" width="25%" />
              <EmployeeLinkFieldWithNameLoading label="Triggered By" source="triggeredBy" width="25%" />
              <FullDateField label="Сompleted At" source="createdAt" width="25%" />
            </DataGridLayout>
          </Box>
        </List>

        <SectionRow>
          <EmployeeLinkFieldWithNameLoading
            isVisible={(data: AccountingCycleType) => data?.status === ACCOUNTING_CYCLE_STATUS.CLOSE}
            label="Closed By"
            source="closedBy"
          />
          <DateField
            isVisible={(data: AccountingCycleType) => data?.status === ACCOUNTING_CYCLE_STATUS.CLOSE}
            source="closedAt"
            label="Closed AT"
          />
        </SectionRow>
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewAccountingCycleContent;
