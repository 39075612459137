/* istanbul ignore file */
import React from 'react';
import EmployeeNoteTagDropdown from '../input/EmployeeNoteTagDropdown';
import DropDownFilter, { DropDownFilterProps } from '../../../../../shared/uibuilder/list/filter/DropDownFilter';

const EmployeeTimelineNoteTagFilter = ({ source, ...props }: Omit<DropDownFilterProps, 'children'>) => {
  return (
    <DropDownFilter source={source} {...props} isSingle>
      <EmployeeNoteTagDropdown source={source} />
    </DropDownFilter>
  );
};

export default EmployeeTimelineNoteTagFilter;
