import { BpmnDiagramService, Event, EventType } from 'camunda/monitoring/facade/useBpmnJs';
import {
  ActivityInstance,
  ShortActivityInstance,
  Incident,
  ProcessInstance,
} from 'camunda/monitoring/instance/processInstanceService';
import ActiveTokenMarker from 'camunda/monitoring/facade/marker/ActiveTokenMarker';
import IncidentTokenMarker from 'camunda/monitoring/facade/marker/IncidentTokenMarker';
import DueDateMarker from 'camunda/monitoring/facade/marker/DueDateMarker';
import { useNavigate } from 'react-router-dom';
import { useProcessInstanceId, useProcessInstanceUrl } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useCallback } from 'react';

const useProcessDiagramHelper = ({ addClass, addMarker, addEventListener }: BpmnDiagramService) => {
  const highlightPassedActivities = (processInstance: ProcessInstance) => {
    processInstance.activities
      .filter((activity: ActivityInstance) => activity.endDateTime)
      .forEach((activity: ActivityInstance) => addClass(activity.activityId, 'passed-activity'));
  };

  const countTokens = (activityIdList: List<string>) => {
    const countByActivityId = new Map();
    activityIdList.forEach(id => {
      const count = (countByActivityId.get(id) ?? 0) + 1;
      countByActivityId.set(id, count);
    });
    return countByActivityId;
  };

  const addTokens = (processInstance: ProcessInstance) => {
    const activeActivityIds = processInstance.runtimeActivities
      .filter((activity: ShortActivityInstance) => !activity.multiInstanceBody)
      .map((activity: ShortActivityInstance) => activity.activityId);
    const activeTokens = countTokens(activeActivityIds);
    activeTokens.forEach((count, activityId) => addMarker(new ActiveTokenMarker(activityId, count)));

    const failedActivityIds = processInstance.incidents.map((incident: Incident) => incident.activityId);
    const incidentTokens = countTokens(failedActivityIds);
    incidentTokens.forEach((count, activityId) => addMarker(new IncidentTokenMarker(activityId, count)));
  };

  const addDueDates = (processInstance: ProcessInstance) => {
    processInstance.activities
      .filter((activity: ActivityInstance) => activity.dueDate)
      .forEach((activity: ActivityInstance) => addMarker(new DueDateMarker(activity.activityId, activity.dueDate)));
  };

  const navigate = useNavigate();
  const { getVariablesUrl } = useProcessInstanceUrl();
  const id = useProcessInstanceId();
  const { data } = useShowContext();

  const activities = data && data.getValueBySource('activities');
  const onClickEventListener = useCallback(
    (e: Event) => {
      if (e.type === EventType.DOUBLE_CLICK && e.element?.id && data) {
        const context = activities.find((a: ActivityInstance) => a.activityId === e.element?.id)?.executionId;
        const url = getVariablesUrl(id, context);
        navigate(url);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activities],
  );

  const addEventListeners = () => {
    addEventListener(onClickEventListener);
  };

  return {
    highlightPassedActivities,
    addTokens,
    addDueDates,
    addEventListeners,
  };
};

export default useProcessDiagramHelper;
