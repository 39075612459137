import React, { useEffect } from 'react';
import { DefaultInputPropTypes, InputList } from 'shared/uibuilder/form/input';
import './index.scss';
import AdditionalContactInput from 'erp/shared/input/AdditionalContactListInput/AdditionalContactInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { isEqual } from 'lodash';
import useAdditionalContactsHelper from 'erp/shared/input/AdditionalContactListInput/useAdditionalContactsHelper';

const AdditionalContactListInput = (props: DefaultInputPropTypes<any>) => {
  const { getValue, initializeValue } = useInputHelper(props);
  const { getPreparedAdditionalContacts } = useAdditionalContactsHelper();
  const value = getValue();

  useEffect(() => {
    const updatedContacts = getPreparedAdditionalContacts(value);

    if (!isEqual(updatedContacts, value)) {
      initializeValue(updatedContacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="additional-contacts-input">
      <InputList {...props} inputTemplate={AdditionalContactInput} addText="Add additional contact" />
    </div>
  );
};

export default AdditionalContactListInput;
