import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import React from 'react';
import { BUDGET_VERSION_STATUS_OPTIONS } from 'erp/serviceCatalog/budgets/budgetVersionService';

const BudgetStatusDropdown = (props: DefaultInputPropTypes<string>) => {
  return <EnumDropdown options={BUDGET_VERSION_STATUS_OPTIONS} {...props} />;
};

export default BudgetStatusDropdown;
