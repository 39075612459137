import React from 'react';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import { NumberInputLayoutType } from 'shared/uibuilder/form/input/NumberInput';
import { BaseChangeEvent, CommonInputLayoutProps } from 'shared/uibuilder/form/input';

const NumberInputLayout: NumberInputLayoutType = ({
  value,
  ...otherProps
}: CommonInputLayoutProps<BaseChangeEvent, number>) => {
  const props = {
    ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
    ...otherProps,
  };
  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <input {...getCommonInputProps(props)} value={value} type="number" />
    </BaseInputLayout>
  );
};

export default NumberInputLayout;
