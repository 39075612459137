/* istanbul ignore file */
import React from 'react';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import { EmployeeLink } from 'erp/employee';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';
import NameField from 'shared/uibuilder/field/NameField';

const EmployeeLinkField = ({ source, nameSource, ...props }: FieldProps) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const name = getName();

  const value = (
    <EmployeeLink name={typeof name === 'string' ? name : <NameField value={name} />} id={getId()} {...props} />
  );
  const displayedValue = getId() ? value : EMPTY_VALUE_INDICATOR;

  return <TextField value={displayedValue} {...props} />;
};

export default EmployeeLinkField;
