/* istanbul ignore file */
import React from 'react';
import { WysiwygInput } from 'shared/uibuilder/form/input';
import { WysiwygInputProps } from 'shared/uibuilder/form/input/WysiwygInput';

const VacancyWysiwygInput = ({ ...props }: WysiwygInputProps) => {
  const editorConfig = ['bold', '|', 'bulletedList', 'numberedList', '|', 'heading'];

  return <WysiwygInput editorConfig={editorConfig} {...props} />;
};

export default VacancyWysiwygInput;
