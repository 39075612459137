import { useCallback } from 'react';
import { useFinanceApi, useRecruitmentApi } from 'api';
import useCrudService from 'shared/crud';
import { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import { MoveToStages, Stages, VacancyPriorityValues } from './constants';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useDateTimeService from 'shared/uibuilder/dateService/useDateTimeService';
import { Application, MappedApplication, MappedVacancyBoard, VacancyItem } from './types';
import { useDateService } from 'shared/uibuilder/dateService';
import useMessageService, { ErrorMessage } from '../../../shared/message/messageService';

const RECRUITING_POOLS_PATH = '/recruiting-dashboard';
export const REJECT_BOX_ID = 'reject-box';
const FINANCE_FIELDS_MAPPING = {
  compensationFromValue: 'expectedCompensationFromId',
  compensationToValue: 'expectedCompensationToId',
  offerSum: 'offerSumId',
};

const useRecruitingBoardService = () => {
  const { sendPostRequest } = useRecruitmentApi();
  const { sendPostRequest: uploadFinanceValue } = useFinanceApi();
  const { addMessage } = useMessageService();
  const { data: { items = [] } = {}, setData = () => {} } = useListContext();
  const { getDateInCurrentTimezone } = useDateTimeService();
  const { getCurrentDateTime } = useDateService();

  const {
    search: baseSearch,
    update: baseUpdate,
    create: baseCreate,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RECRUITING_POOLS_PATH}/:id`,
    listResourceUrl: RECRUITING_POOLS_PATH,
    apiService: useRecruitmentApi,
  });

  const applicationMap = useCallback(
    (entityId: string, application: Application): MappedApplication => ({
      id: application.id,
      entityId,
      candidateId: application.candidateId,
      name: application.candidateName,
      vacancyId: application.vacancyId,
      eventTimestamp: getDateInCurrentTimezone(application.applicationPipelineState.stageTransitionTimestamp),
      stage: application.applicationPipelineState.stage,
      activeStates: application.applicationPipelineState.jobsStates,
      canDrag: true,
    }),
    [getDateInCurrentTimezone],
  );

  const mapApplicationsToBoard = useCallback(
    (data: VacancyItem[]): MappedVacancyBoard[] =>
      data.map((item: VacancyItem) => ({
        id: item.id,
        name: item.level && item.level !== 'N_A' ? `${item.level} ${item.specialization}` : item.specialization,
        hired: item.hired,
        isLegacy: item.isLegacy,
        priority: VacancyPriorityValues[item.priority],
        needHire: item.needHire,
        forecastDate: item.forecastDate,
        items: item.applications.map(application => applicationMap(item.id, application)),
      })),
    [applicationMap],
  );

  const updateLocalState = useCallback(
    (updatedItem: MappedApplication) => {
      setData((prev: any) => ({
        ...prev,
        items: prev.items.map((entity: MappedVacancyBoard) =>
          entity.id === updatedItem.entityId
            ? {
                ...entity,
                items: entity.items.map(item => (item.id === updatedItem.id ? updatedItem : item)),
              }
            : entity,
        ),
      }));
    },
    [setData],
  );

  const moveStageAction = useCallback(
    (vacancyId: string, applicationId: number, stage: string) => {
      const targetEntity = items.find(entity => entity.id === vacancyId);
      if (!targetEntity) return null;

      const prevState = targetEntity.items.find((item: MappedApplication) => item.id === applicationId);

      return {
        newState: {
          ...prevState,
          eventTimestamp: getCurrentDateTime().toISOString(),
          stage,
          canDrag: false,
        },
        prevState,
      };
    },
    [items, getCurrentDateTime],
  );

  const moveStage = useCallback(
    async (vacancyId: string, applicationId: number, stage: string, payload: any = null) => {
      const updatedEntity = moveStageAction(vacancyId, applicationId, stage);
      if (!updatedEntity) return null;

      const { newState, prevState } = updatedEntity;

      updateLocalState(newState);

      try {
        const res = await sendPostRequest(`/candidates/applications/${newState.id}/pipeline/move`, {
          targetStage: MoveToStages[newState.stage as Stages],
          payload,
        });
        const resJson = await res.json();
        updateLocalState(applicationMap(newState.entityId, resJson));
        return resJson;
      } catch (error) {
        updateLocalState(prevState);
        return error;
      }
    },
    [sendPostRequest, applicationMap, moveStageAction, updateLocalState],
  );

  const completeJobStep = useCallback(
    async (applicationId: string, job: string, event: string, payload: any) => {
      try {
        if (payload?.resumeId?.length) {
          // workaround to correctly pass single artifact
          // eslint-disable-next-line no-param-reassign, prefer-destructuring
          payload.resumeId = payload.resumeId[0];
        }
        const resultPayload = await handleFinanceInfo(payload);
        const res = await sendPostRequest(`/candidates/applications/${applicationId}/pipeline/jobs/complete`, {
          event,
          jobName: job,
          payload: resultPayload,
        });
        const resJson = await res.json();
        return resJson;
      } catch (error) {
        addMessage(new ErrorMessage('Error during job completion'));
        return error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sendPostRequest, addMessage],
  );

  const handleFinanceInfo = async (payload: any) => {
    if (!payload) return null;

    const hasFinanceFields = Object.keys(FINANCE_FIELDS_MAPPING).some(field => field in payload);

    if (!hasFinanceFields) return payload;

    return Object.entries(FINANCE_FIELDS_MAPPING).reduce(async (accPromise, [fieldName, idField]) => {
      const acc = await accPromise;

      if (!(fieldName in acc)) return acc;

      const response = await uploadFinanceValue('/recruiting-finance', {
        value: acc[fieldName],
      });
      const { id } = await response.json();

      const { [fieldName]: _, ...rest } = acc;
      return {
        ...rest,
        [idField]: id,
      };
    }, Promise.resolve(payload));
  };

  const search = useCallback(
    async (request: ResourceData) => {
      try {
        const params = cloneDeep(request);
        const response = await baseSearch(params);
        return {
          ...response,
          result: mapApplicationsToBoard(response.result as VacancyItem[]),
        };
      } catch (error) {
        return error;
      }
    },
    [baseSearch, mapApplicationsToBoard],
  );

  return {
    search,
    completeJobStep,
    moveStage,
    ...baseCrud,
  };
};

export default useRecruitingBoardService;
