import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';
import { DATE_FORMAT, useDateTimeService } from 'shared/uibuilder/dateService';

type AsapTargetFieldProps = {
  label: string;
  asapSource: string;
  targetSource: string;
  width?: StringOrNumber;
};

const AsapTargetField = ({ asapSource, targetSource, ...props }: AsapTargetFieldProps) => {
  const { formatDateWithTimezone } = useDateTimeService();
  const { getValue: getIsAsap } = useFieldHelper({ source: asapSource });
  const { getValue: getTargetDate } = useFieldHelper({ source: targetSource });

  const isAsap = getIsAsap();
  const targetDate = getTargetDate();

  const displayedValue = isAsap ? 'Asap' : formatDateWithTimezone(targetDate, DATE_FORMAT.SIMPLE);

  return <TextField {...props} value={displayedValue} />;
};

export default AsapTargetField;
