import { saveAs } from 'file-saver';
import useArtifactService from 'artifact/artifactService';

const useArtifactDownloadHelper = () => {
  const { downloadArtifact, downloadInvoiceArtifact } = useArtifactService();

  const download = async (id: any, fileName?: string) => {
    const blob = await downloadArtifact(id);
    saveAs(blob, fileName);
  };

  const downloadForInvoice = async (id: any, fileName?: string) => {
    const blob = await downloadInvoiceArtifact(id);
    saveAs(blob, fileName);
  };

  return {
    download,
    downloadForInvoice,
  };
};

export default useArtifactDownloadHelper;
