/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useLeaveService from 'erp/leave/shared/leaveService';

const LeaveContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useLeaveService() as any;
  const CONTEXT_NAME = 'LEAVE_CONTEXT';

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={CONTEXT_NAME}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default LeaveContext;
