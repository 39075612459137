/* istanbul ignore file */
import React from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { KPI_TYPES_UNIT_OPTIONS } from '../useKPIService';

type KpiValueItem = {
  id: number;
  kpiParameterId: number;
  kpiParameterName: string;
  kpiType: string;
  period: string;
  value: number;
};

const KpiValuesTable = () => {
  const { data } = useListContext();
  const items: KpiValueItem[] = data?.items || [];

  if (items.length === 0) {
    return (
      <Typography variant="body1" align="center" sx={{ marginTop: 2 }}>
        No KPI Values found
      </Typography>
    );
  }

  const rows = Array.from(
    new Set(items.map(item => `${item.kpiParameterName}, ${KPI_TYPES_UNIT_OPTIONS[item.kpiType]}`)),
  );
  const columns = Array.from(new Set(items.map(item => item.period))).sort();

  const grid = items.reduce(
    (acc, item) => {
      const rowKey = `${item.kpiParameterName}, ${KPI_TYPES_UNIT_OPTIONS[item.kpiType]}`;

      if (!acc[rowKey]) {
        acc[rowKey] = columns.reduce(
          (colAcc, column) => ({
            ...colAcc,
            [column]: null,
          }),
          {} as Record<string, number | null>,
        );
      }

      acc[rowKey][item.period] = item.value;

      return acc;
    },
    {} as Record<string, Record<string, number | null>>,
  );

  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>KPI Name</TableCell>
            {columns.map(column => (
              <TableCell key={column} align="center">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row}>
              <TableCell>{row}</TableCell>
              {columns.map(column => (
                <TableCell key={`${row}-${column}`} align="center">
                  {grid[row][column] !== null ? grid[row][column] : '-'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default KpiValuesTable;
