/* istanbul ignore file */
import React from 'react';
import ShowDate from 'shared/uibuilder/ShowDate';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import TimelineRecordAuthor from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordAuthor';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

type TimelineSentFeedbackHeaderProps = {
  actionName?: string | ((string: Dictionary<any>) => string);
};

const TimelineSentFeedbackHeader = ({ actionName = '' }: TimelineSentFeedbackHeaderProps) => {
  const { data: entity } = useShowContext();
  const createdAt = entity.getValueBySource('createdAt');
  const employeeId = entity.getValueBySource('employeeId');
  const employeeName = entity.getValueBySource('employeeName');
  const resultActionName = typeof actionName === 'function' ? actionName(entity.getData()) : actionName;

  return (
    <>
      <div className="timeline__card-header mr-5 pr-3">
        <div className="timeline__employee-action">
          <div className="timeline__card-datetime d-inline-block">
            <ShowDate dateUTC={createdAt} format={DATE_FORMAT.FULL} />
          </div>
          <span>Sent to </span>
          <TimelineRecordAuthor id={employeeId} name={employeeName} /> {resultActionName}
        </div>
      </div>
    </>
  );
};

export default TimelineSentFeedbackHeader;
