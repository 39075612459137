/* istanbul ignore file */
/* This is temporary component made to highlight email in list, must be replaced with regular email field when global search implemented */
import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import SearchResultField from 'shared/uibuilder/field/SearchResultField';
import { FieldProps } from 'shared/uibuilder/field';

const EmailSearchField = (props: FieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);
  const { BaseFieldLayout } = useUiTheme();

  const { realValue } = props;
  const value = getValue();
  const linkValue = realValue || value;

  const passedValue = value ? (
    <a href={`mailto:${linkValue}`}>
      <SearchResultField value={value} />
    </a>
  ) : null;

  return <BaseFieldLayout value={passedValue} label={getLabel()} />;
};

export default EmailSearchField;
