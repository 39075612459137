import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { useParams } from 'react-router-dom';

export const VACANCY_GROUP_PATH = '/vacancy-group';

export const useVacancyGroupRoute = () => {
  const routers = useRoute({ listRoute: VACANCY_GROUP_PATH });

  return {
    ...routers,
  };
};

export const useVacancyGroupUrl = () => {
  const { getSingleEntityUrl, ...restUrls } = useEntityUrl({
    baseLink: VACANCY_GROUP_PATH,
  });

  return {
    ...restUrls,
    getSingleEntityUrl,
  };
};

export const useVacancyGroupId = () => {
  const { id } = useParams<Dictionary<string>>();
  return id!;
};
