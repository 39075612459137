/* istanbul ignore file */
import React from 'react';
import DropDownFilter, { DropDownFilterProps } from 'shared/uibuilder/list/filter/DropDownFilter';
import EmployeeWorkingStatusDropdown from 'erp/employee/shared/input/EmployeeWorkingStatusDropdown';

const EmployeeWorkingStatusFilter = ({
  excluded,
  source,
  ...props
}: Omit<DropDownFilterProps, 'children'> & { excluded?: string[] }) => {
  return (
    <DropDownFilter source={source} {...props}>
      <EmployeeWorkingStatusDropdown excluded={excluded} source={source} />
    </DropDownFilter>
  );
};

export default EmployeeWorkingStatusFilter;
