/* istanbul ignore file */
import React from 'react';
import Button, { ButtonProps } from 'uibuilder/button/Button';
import DataTimer from 'shared/uibuilder/DataTimer';
import { SENSITIVE_VISIBLE_TIMEOUT } from 'shared/uibuilder/sensitiveData/sensitiveDataService';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';

interface ToggleSensitiveDataButtonProps extends ButtonProps {
  isTimerActive?: boolean;
  isShown?: boolean;
}
const ToggleSensitiveDataButton = ({
  isTimerActive = false,
  isShown = false,
  ...other
}: ToggleSensitiveDataButtonProps) => {
  const { key, ...props } = other;

  return (
    <>
      {isShown ? (
        <DataTimer isActive={isTimerActive} timerLifetime={SENSITIVE_VISIBLE_TIMEOUT}>
          <Button
            {...props}
            type="button"
            className="create-btn toggle-btn"
            color="error"
            startIcon={<VisibilityOffIcon />}
          >
            Hide content
          </Button>
        </DataTimer>
      ) : (
        <Button
          type="button"
          className="create-btn toggle-btn"
          color="secondary"
          {...props}
          startIcon={<VisibilityIcon />}
        >
          Show content
        </Button>
      )}
    </>
  );
};

export default ToggleSensitiveDataButton;
