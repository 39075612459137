/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import BaseInputErrors from './BaseInputErrors';
import { CheckboxLayoutProps, CheckboxLayoutType } from 'shared/uibuilder/form/input/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputTooltip from './InputTooltip';

const CheckboxLayout: CheckboxLayoutType = ({
  label = '',
  value,
  errorMessageMapper,
  onChangeCallback,
  onFocusCallback,
  source,
  isRequired = false,
  disabled = false,
  errorMessages = [],
  fullWidth = true,
  className,
  tooltip = null,
  disableLabelClick = false,
  sxCheckbox,
}: CheckboxLayoutProps) => {
  const hasError = !!errorMessages && errorMessages.length > 0;
  const labelClickStyle = disableLabelClick ? 'none' : 'auto';

  return (
    <FormControl
      className={classnames('form-group checkbox-form-group', className)}
      fullWidth={fullWidth}
      error={hasError}
      required={!!isRequired}
      variant="standard"
    >
      <FormControlLabel
        // if we pass disableLabelClick prop we disable click action on the label,
        // but hover should work as usual for tooltip
        sx={{
          '&.MuiFormControlLabel-root:active': {
            pointerEvents: labelClickStyle,
          },
        }}
        disabled={disabled || false}
        value={String(value)}
        control={
          <Checkbox
            checked={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (onChangeCallback) {
                onChangeCallback({
                  [source]: (event.target as HTMLInputElement).checked,
                });
              }
            }}
            onFocus={onFocusCallback}
            name={source}
            sx={{
              ...sxCheckbox,
              '& .MuiSvgIcon-root': { fontSize: 24 },
              pointerEvents: 'auto',
            }}
          />
        }
        label={
          <>
            {label}
            {isRequired && <span> *</span>}
            {tooltip && <InputTooltip message={tooltip} source={source} />}
          </>
        }
      />
      <FormHelperText>
        {hasError ? <BaseInputErrors errorMessages={errorMessages} errorMessageMapper={errorMessageMapper} /> : null}
      </FormHelperText>
    </FormControl>
  );
};

export default CheckboxLayout;
