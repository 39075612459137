/* istanbul ignore file */
import React from 'react';
import { SERVICE_CATALOG_STATUSES } from 'erp/serviceCatalog/useServiceCatalogService';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import CreateServiceCatalogButton from 'erp/serviceCatalog/shared/button/CreateServiceCatalogButton';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ServiceCatalogLink from 'erp/serviceCatalog/shared/field/ServiceCatalogLink';
import DateField from 'shared/uibuilder/field/DateField';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import { EmployeeFilter, EmployeeLinkFieldWithNameLoading } from 'erp/employee';
import BadgeField from '../../../shared/uibuilder/field/BadgeField';
import ServiceCatalogStatusBadge from '../shared/field/ServiceCatalogStatusBadge';
import ServiceCatalogHealthBadge from 'erp/serviceCatalog/shared/field/ServiceCatalogHealthBadge';

const ServiceCatalogListPageContent = () => {
  const filters = (
    <Filters>
      <FiltersLayout
        quickFilters={[
          <QuickFilter
            label="All not archived services"
            state={{ status: { ne: SERVICE_CATALOG_STATUSES.ARCHIVED } }}
          />,
        ]}
      >
        <EmployeeFilter label="Owner" source="owner" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <ListPageLayout
      header={<ListHeader label="Service catalog" />}
      actions={[<CreateServiceCatalogButton />]}
      filter={filters}
    >
      <DataGridLayout>
        {[
          <BadgeField
            badges={[
              <ServiceCatalogStatusBadge source="status" />,
              <ServiceCatalogHealthBadge source="currentHealth.issues"/>
            ]}
            label="Name"
            width="40%"
          >
            <ServiceCatalogLink source="id" nameSource="name" label="Name" width="40%" />
          </BadgeField>,
          <EmployeeLinkFieldWithNameLoading source="owner" label="Owner" />,
          <DateField source="updatedAt" label="Last Modified Date" width="20%" isSortable />,
        ]}
      </DataGridLayout>
    </ListPageLayout>
  );
};

export default ServiceCatalogListPageContent;
