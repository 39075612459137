/* istanbul ignore file */
import React from 'react';
import useSkillsService from 'erp/candidate/skills/skillsService';
import MultiSelectInputFilter from 'shared/uibuilder/list/filter/MultiSelectInputFilter';
import SkillsInput from 'shared/uibuilder/skillsInput/SkillsInput';
import { ConditionProps } from '../../../../shared/uibuilder/list/filter/condition/Condition';

const CandidateSkillsFilter = ({ label, ...props }: { label: string } & Omit<ConditionProps, 'children'>) => {
  const { searchByName } = useSkillsService();

  return (
    <MultiSelectInputFilter {...props}>
      <SkillsInput {...props} loadSuggestionsMethod={searchByName} label={label} />
    </MultiSelectInputFilter>
  );
};

export default CandidateSkillsFilter;
