import React, { useEffect, useState } from 'react';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

const CandidateSpecializationDropdown = (props: any) => {
  const [options, setOptions] = useState([]);
  const { getPositionOptions } = useVacancyService();

  useEffect(() => {
    (async () => {
      const positions = await getPositionOptions();

      const fullNameOptions = Object.entries(positions).reduce((acc, [, value]) => {
        acc[value as string] = value;
        return acc;
      }, {} as any);

      setOptions(fullNameOptions);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <EnumDropdown options={options} label="Specialization" placeholder="Select Specialization" {...props} />;
};

export default CandidateSpecializationDropdown;
