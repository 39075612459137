import React from 'react';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import useHiringService from '../../useHiringService';
import { useHiringId, useHiringUrl } from '../../HiringRouter';
import EntityHeader from 'shared/layout/EntityHeader';
import UpdateHiringBreadcrumbs from './UpdateHiringBreadcrumbs';
import UpdateHiringContent from './UpdateHiringContent';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';

const UpdateHiringPage = () => {
  const { update, getById, getUpdateValidationSchema } = useHiringService();
  const { getSingleEntityUrl } = useHiringUrl();
  const id = useHiringId();

  return (
    <UpdateForm
      submitFormFunc={update as (id: StringOrNumber, values: FormFieldsData) => Promise<object>}
      getDataFunc={getById}
      getValidationSchemaFunc={getUpdateValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(id),
        message: 'You are awesome! The hiring request has been successfully saved.',
      }}
    >
      <UpdatePageLayout
        title="Edit: Hiring request"
        breadcrumbs={<UpdateHiringBreadcrumbs />}
        entityHeader={<EntityHeader entityTitleSource="specialisation" />}
      >
        <UpdateHiringContent />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateHiringPage;
