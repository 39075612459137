/* istanbul ignore file */
import React, { useEffect } from 'react';
import FormRow from 'shared/layout/form/FormRow';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import usePresaleOpportunityService, {
  APPLICATION_TYPE,
  MOBILE_OS,
  PRESALE_REQUEST_TYPE,
  REQUEST_STATUS_ALIAS,
} from 'crm/opportunity/presale/request/shared/presaleRequestService';
import BooleanDropdownOptionInput from 'shared/uibuilder/form/input/BooleanDropdownOptionInput';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';
import TextArea from 'shared/uibuilder/form/input/TextArea';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import WysiwygInput from 'shared/uibuilder/form/input/WysiwygInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import FormSection from 'shared/layout/form/FormSection';
import {
  showApplicationTypeField,
  showCollaborationExpectationFiled,
  showHasAutomationTestingField,
  showMaterialsField,
  showMobileOSField,
  showPercentTestCoverageField,
  showReplacementReasonField,
  showRequiredFteField,
  showScopeOfWorkField,
  showSystemCompletionField,
  showSystemHostingLocationField,
  showTeamInvolvementField,
  showThirdPartyField,
} from 'crm/opportunity/presale/request/shared/presaleRequestFieldVisibilityHelper';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';

const MATERIAL_HELP_TEXT =
  '* High-level overview\n' +
  '* Source code of current product version\n' +
  '* Access to deployed environment\n' +
  '* List of new features\n' +
  '* List of existing known issues\n' +
  '* Technical documentation';

const PresaleRequestForm = ({ isCreateForm }: { isCreateForm?: boolean }) => {
  const { data, setFormData, formErrors } = useFormContext();
  const { getLastPresaleRequest, mapInitialData } = usePresaleOpportunityService();
  const opportunityId = useOpportunityId();

  useEffect(() => {
    (async () => {
      if (isCreateForm) {
        const lastOutput = await getLastPresaleRequest(opportunityId);
        if (lastOutput != null && setFormData) {
          const initialData = mapInitialData(lastOutput);
          setFormData(initialData);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityId]);

  useEffect(() => {
    if (formErrors && formErrors.requestStatus && setFormData && data?.requestStatus !== 'DRAFT') {
      setFormData({
        ...data,
        requestStatus: 'DRAFT',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  return (
    <FormSection>
      <FormRow>
        <EnumDropdown options={REQUEST_STATUS_ALIAS} source="requestStatus" disabled label="Request Status" />
      </FormRow>
      <FormRow>
        <EnumDropdown
          options={PRESALE_REQUEST_TYPE}
          source="requestType"
          label="Request Type"
          placeholder="Select..."
        />
        <ClearAfterUnmountWrapper isVisible={showSystemCompletionField}>
          <NumberInput source="systemCompletionProgress" label="System Completion" />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={showTeamInvolvementField}>
          <BooleanDropdownOptionInput
            source="hasPreviousTeamInvolvement"
            label="Previous team involvement"
            placeholder="Select..."
          />
        </ClearAfterUnmountWrapper>
        <DateInput source="expectedCompletionAt" label="Expected Completion At" />
        <ClearAfterUnmountWrapper isVisible={showHasAutomationTestingField}>
          <BooleanDropdownOptionInput
            source="hasAutomatedTesting"
            label="Automated Testing Coverage"
            placeholder="Select..."
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={showPercentTestCoverageField}>
          <NumberInput source="automatedTestingCoverage" label="% of coverage" />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={showApplicationTypeField}>
          <EnumDropdown
            options={APPLICATION_TYPE}
            source="applicationTypes"
            multiple
            label="Application Types"
            placeholder="Select..."
            helpText="Select one or more options"
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={showMobileOSField}>
          <EnumDropdown
            options={MOBILE_OS}
            source="mobileOperationSystems"
            multiple
            label="Mobile OS"
            placeholder="Select..."
            helpText="Select one or more options"
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={showThirdPartyField}>
          <BooleanDropdownOptionInput
            source="hasThirdPartyIntegrations"
            label="Third-Party Integration"
            placeholder="Select..."
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={showRequiredFteField}>
          <NumberInput source="requiredFte" label="Required FTE" />
        </ClearAfterUnmountWrapper>
      </FormRow>

      <BigFormRow>
        <ClearAfterUnmountWrapper isVisible={showCollaborationExpectationFiled}>
          <TextArea source="collaborationExpectations" label="Collaboration Expectations" />
        </ClearAfterUnmountWrapper>
      </BigFormRow>

      <BigFormRow>
        <ClearAfterUnmountWrapper isVisible={showSystemHostingLocationField}>
          <TextArea source="systemHostingLocation" label="System Hosting Location" />
        </ClearAfterUnmountWrapper>
      </BigFormRow>

      <BigFormRow>
        <ClearAfterUnmountWrapper isVisible={showReplacementReasonField}>
          <TextArea source="replacementReason" label="Replacement reason" />
        </ClearAfterUnmountWrapper>
      </BigFormRow>

      <BigFormRow>
        <ClearAfterUnmountWrapper isVisible={showScopeOfWorkField}>
          <TextArea source="scopeOfWork" label="Scope of work" />
        </ClearAfterUnmountWrapper>
      </BigFormRow>

      <BigFormRow>
        <ClearAfterUnmountWrapper isVisible={showMaterialsField}>
          <WysiwygInput source="materials" label="Description" helpText={MATERIAL_HELP_TEXT} />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={showMaterialsField}>
          <CrmAttachmentUploaderInput label="Attachments" source="attachmentIds" />
        </ClearAfterUnmountWrapper>
      </BigFormRow>
    </FormSection>
  );
};

export default PresaleRequestForm;
