/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';

const EmployeeProfileBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const id = useEmployeeId();
  const { getProfileUrl } = useEmployeeUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <BreadcrumbItem link={getProfileUrl(id)}>Profile</BreadcrumbItem>
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default EmployeeProfileBreadcrumbs;
