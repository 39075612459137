/* istanbul ignore file */
import React from 'react';
import DropDownFilter, { DropDownFilterProps } from 'shared/uibuilder/list/filter/DropDownFilter';
import PositionDropdown from 'erp/employee/shared/input/PositionDropdown';

const EmployeePositionFilter = (props: Omit<DropDownFilterProps, 'children'>) => {
  return (
    <DropDownFilter {...props}>
      <PositionDropdown {...props} />
    </DropDownFilter>
  );
};

export default EmployeePositionFilter;
