/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEmployeeAssignmentUrl } from '../EmployeeAssignmentsRouter';

const EmployeeAssignmentsBreadCrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useEmployeeAssignmentUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Assignments" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default EmployeeAssignmentsBreadCrumbs;
