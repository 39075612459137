/* istanbul ignore file */
import React from 'react';
import { Navigate, Router, Route } from 'shared/routing';
import { ROOT_PATH } from 'ApplicationRouter';
import { Routes } from 'react-router-dom';
import { ONE_STOP_PATH } from './OneStopRouterHelper';
import useAuthorization from '../shared/authorization/authorizationService';
import { EMPLOYEE_READ_ONE_STOP } from './OneStopRouter';
import { HANDBOOK_PATH } from '../handbook/HandbookRouter';

const REDIRECT_PATH = ONE_STOP_PATH;

const RedirectToOneStop: Router = () => {
  const { isGranted } = useAuthorization();
  const isOnlyHandbook = !isGranted(EMPLOYEE_READ_ONE_STOP);

  return (
    <Routes>
      <Route path={ROOT_PATH} element={<Navigate to={isOnlyHandbook ? HANDBOOK_PATH : REDIRECT_PATH} replace />} />
    </Routes>
  );
};

RedirectToOneStop.getRouterPath = () => ROOT_PATH;

export default RedirectToOneStop;
