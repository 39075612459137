/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  BUDGET_PATH,
  READ_BUDGETS_LIST,
  CREATE_BUDGET,
  UPDATE_BUDGET,
  READ_BUDGET,
} from 'erp/budget/shared/budgetService';
import BudgetList from 'erp/budget/List';
import { CreateBudget, UpdateBudget } from 'erp/budget/createupdate';
import ViewBudget from 'erp/budget/Show';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import BudgetContext from 'erp/budget/BudgetContext';

export const useBudgetRoute = () => {
  const routers = useRoute({ listRoute: BUDGET_PATH });

  return {
    ...routers,
    copyEntityRoute: `${routers.singleEntityRoute}/copy`,
  };
};

export const useBudgetUrl = () => {
  const { getSingleEntityUrl, ...restUrls } = useEntityUrl({
    baseLink: BUDGET_PATH,
  });
  const getCopyEntityUrl = (id: StringOrNumber): string => `${getSingleEntityUrl(id)}/copy`;

  return {
    ...restUrls,
    getSingleEntityUrl,
    getCopyEntityUrl,
  };
};

export const useBudgetId = () => {
  const { id } = useParams<Dictionary<string>>();

  return id!;
};

const BudgetRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, updateRoute, copyEntityRoute } = useBudgetRoute();

  return (
    <ProtectedRouter basePath={BUDGET_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_BUDGETS_LIST}>
              <BudgetList />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_BUDGET}>
              <CreateBudget />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={copyEntityRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_BUDGET}>
              <CreateBudget />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <BudgetContext permissionToCheck={READ_BUDGET}>
              <ViewBudget />
            </BudgetContext>
          }
        />

        <Route
          path={updateRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={UPDATE_BUDGET}>
              <UpdateBudget />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

BudgetRouter.getRouterPath = () => `${BUDGET_PATH}/*`;

export default BudgetRouter;
