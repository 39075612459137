/* istanbul ignore file */
import React, { useContext } from 'react';
import { Permission } from '../utils';

export interface SecurityContext {
  permissions?: Permission[];
}

const SecurityReactContext = React.createContext<SecurityContext>({});

export const SecurityProvider = SecurityReactContext.Provider;

const useSecurityReactContext = () => useContext(SecurityReactContext);

export default useSecurityReactContext;
