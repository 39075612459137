import React, { useMemo } from 'react';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { DateInput } from 'shared/uibuilder/form/input';
import useKPIService, { KPI_TYPES_UNIT_OPTIONS, PublishValueType } from '../../useKPIService';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useFeatureToggle, { Features } from 'featuretoggle';
import useAuthorization from 'shared/authorization/authorizationService';
import ModalFormButton from 'shared/uibuilder/form/ModalFormButton';
import FormSection from 'shared/layout/form/FormSection';
import Typography from 'uibuilder/Typography';
import useMessageService, { SuccessMessage } from 'shared/message/messageService';

type PublishKpiButtonProps = {
  effectiveDate: string;
  permissionToCheck: string;
  kpiStructureId: string;
};

const PublishKpiButton = ({ kpiStructureId, effectiveDate, permissionToCheck }: PublishKpiButtonProps) => {
  const { data } = useShowContext();
  const { addMessage } = useMessageService();
  const { id: kpiParameterId, name, type } = data.getData();
  const formatType = KPI_TYPES_UNIT_OPTIONS[type];
  const { formatDate } = useDateService();
  const minDate = useMemo(() => formatDate(effectiveDate, DATE_FORMAT.API_ONLY_DATE), [effectiveDate, formatDate])!;

  const { isFeatureEnabled } = useFeatureToggle();
  const { isGranted } = useAuthorization();
  const { publishKPIParams, getPublishValidationSchema } = useKPIService();

  return isFeatureEnabled(Features.PUBLISH_KPI) && isGranted(permissionToCheck) ? (
    <>
      <ModalFormButton
        outline
        isVisible={isFeatureEnabled(Features.PUBLISH_KPI) && isGranted(permissionToCheck)}
        formTitle="Publish"
        label="Publish"
        submitFormFunc={formState =>
          publishKPIParams({
            ...formState,
            kpiStructureId,
            kpiParameterId,
          } as PublishValueType)
        }
        afterSubmit={{
          execute: () => {
            addMessage(new SuccessMessage('The KPI parameter has been published successfully.'));
          },
        }}
        initialData={{
          period: effectiveDate,
        }}
        getValidationSchemaFunc={getPublishValidationSchema}
      >
        <FormSection>
          <Typography mb="10px">
            Effective Date - {effectiveDate}. Publish parameter - {name} ({formatType}).
          </Typography>

          <DateInput minDate={minDate} dateFormat={DATE_FORMAT.API_ONLY_MONTH} source="period" label="Period" />
          <TextInput label={`Value (${formatType})`} source="value" />
        </FormSection>
      </ModalFormButton>
    </>
  ) : (
    <></>
  );
};

export default PublishKpiButton;
