/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import CreateInstantFeedback from 'instantFeedback/createupdate/Create';

export const INSTANT_FEEDBACK_PATH = '/instant-feedbacks';

export const useInstantFeedbackRoute = () => {
  return useRoute({ listRoute: INSTANT_FEEDBACK_PATH });
};

export const useInstantFeedbackUrl = () => {
  const { getSingleEntityUrl, ...restUrls } = useEntityUrl({
    baseLink: INSTANT_FEEDBACK_PATH,
  });

  return {
    ...restUrls,
    getSingleEntityUrl,
  };
};

const InstantFeedbackRouter = () => {
  const { createRoute } = useInstantFeedbackRoute();

  return (
    <ProtectedRouter basePath={INSTANT_FEEDBACK_PATH}>
      <Routes>
        <Route path={createRoute} element={<CreateInstantFeedback />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

InstantFeedbackRouter.getRouterPath = () => `${INSTANT_FEEDBACK_PATH}/*`;

export default InstantFeedbackRouter;
