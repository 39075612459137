/* istanbul ignore file */
import React from 'react';
import Page404 from 'shared/uibuilder/Page404';
import { Router, Route } from 'shared/routing';
import { CamundaMetricsRouter } from 'camunda/metrics';
import { ProcessInstanceRouter } from 'camunda/monitoring/instance';
import { ProcessDefinitionRouter } from 'camunda/monitoring/definition';
import { Routes } from 'react-router-dom';

export const CAMUNDA_PATH = '/processes';

const CamundaRouter: Router = () => (
  <Routes>
    <Route path={ProcessInstanceRouter.getRouterPath()} element={<ProcessInstanceRouter />} />
    <Route path={ProcessDefinitionRouter.getRouterPath()} element={<ProcessDefinitionRouter />} />
    <Route path={CamundaMetricsRouter.getRouterPath()} element={<CamundaMetricsRouter />} />
    <Route path="*" element={<Page404 />} />
  </Routes>
);

CamundaRouter.getRouterPath = () => `${CAMUNDA_PATH}/*`;

export default CamundaRouter;
