import React from 'react';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import ZoneDateTimeInput, { ZonedApiDateFormat } from '../../../../../shared/uibuilder/form/input/ZoneDateTimeInput';
import VacancyByGroupDropdown from 'erp/recruitment/newVacancy/shared/inputs/VacancyByGroupDropdown';

interface HiredStageFormProps {
  handleCancelClick: () => void;
  vacancyGroupId: string | null;
}

const HiredStageForm = ({ handleCancelClick, vacancyGroupId }: HiredStageFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();

  return (
    <>
      <ZoneDateTimeInput
        label="Employee Start Date"
        source="firstWorkingDate"
        isRequired
        apiDateFormat={ZonedApiDateFormat.ISO_8601_DATETIME_WITH_OFFSET}
      />
      <VacancyByGroupDropdown
        source="vacancyId"
        label="Vacancy"
        vacancyGroupId={vacancyGroupId}
        placeholder="Select Vacancy..."
        helpText="Empty dropdown indicates that the vacancy needs to be migrated to the new Recruiting service first."
      />
      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default HiredStageForm;
