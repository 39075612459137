import { useNavigate } from 'react-router-dom';
import Box from 'uibuilder/Box';
import { Button } from 'uibuilder/button';
import React, { useState } from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import './FormButtonsLayout.scss';

interface FormButtonsLayoutProps {
  additionalOnSubmitAction?: () => void;
  messageComponent?: React.ReactNode;
  onSubmitAction?: () => void;
  onCancelAction?: Nullable<() => void>;
  isCancelButtonVisible?: boolean;
}

const FormButtonsLayout = ({
  additionalOnSubmitAction,
  messageComponent,
  onSubmitAction,
  onCancelAction,
  isCancelButtonVisible = true,
}: FormButtonsLayoutProps) => {
  const navigate = useNavigate();
  const [length] = useState(navigate.length - 1);

  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();

  const onCancel =
    onCancelAction ||
    (() => {
      navigate(length - navigate.length);
    });

  const defaultAction = () => {
    if (submitForm) {
      submitForm();
    }

    if (additionalOnSubmitAction) {
      additionalOnSubmitAction();
    }
  };

  const action = () => (onSubmitAction ? onSubmitAction() : defaultAction());

  return (
    <Box className="form-buttons-wrapper">
      <div className="btn-area">
        <Button
          data-testid="submit-button"
          type="submit"
          onClick={action}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Submit
        </Button>
        {isCancelButtonVisible && (
          <Button data-testid="cancel-btn" outline type="button" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </div>
      {messageComponent}
    </Box>
  );
};

export default FormButtonsLayout;
