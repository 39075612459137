import Lightbox from 'artifact/shared/Lightbox/index';
import React from 'react';
import useAsyncValue from 'shared/asyncHelper';

export type AsyncLightboxProps = {
  onDownload?: Nullable<(id: string, name: string) => void>;
  open: boolean;
  photos: any[];
  initial: any;
  onClose: any;
};

const AsyncLightbox = ({ onDownload = () => {}, photos: photosPromises, ...props }: AsyncLightboxProps) => {
  const photos = useAsyncValue(() => Promise.all(photosPromises));

  return !!photos?.length && <Lightbox onDownload={onDownload} {...props} photos={photos} />;
};

export default AsyncLightbox;
