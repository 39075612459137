import moment from 'moment';
import React from 'react';
import DurationBetweenTwoDatesField from './DurationBetweenTwoDatesField';
import { FieldProps } from './index';

const DateTimerField = ({ value, source, label }: FieldProps) => {
  return (
    <DurationBetweenTwoDatesField dateFromValue={moment()} dateToValue={value} dateToSource={source} label={label} />
  );
};

export default DateTimerField;
