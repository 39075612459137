import { APPEND_EMPLOYEE_NAMES, CLEAR_EMPLOYEE_NAMES, REMOVE_EMPLOYEE_NAMES } from './employeeNamesService';
import { Action } from 'redux';

interface EmployeeNamesActions extends Action {
  employeeNames: any[];
  employeeNamesAliases: string[];
}

const initialState = {};

const employeeNamesReducer = (
  state: Dictionary<any> = initialState,
  action: EmployeeNamesActions = {} as EmployeeNamesActions,
) => {
  if (action.type === APPEND_EMPLOYEE_NAMES) {
    return {
      ...state,
      ...action.employeeNames,
    };
  }

  if (action.type === REMOVE_EMPLOYEE_NAMES) {
    const newState = { ...state };
    action.employeeNamesAliases.forEach(employeeNamesAlias => {
      if (newState[employeeNamesAlias]) {
        delete newState[employeeNamesAlias];
      }
    });

    return {
      ...newState,
    };
  }

  if (action.type === CLEAR_EMPLOYEE_NAMES) {
    return {
      ...initialState,
    };
  }

  return state;
};

export default employeeNamesReducer;
