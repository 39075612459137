/* istanbul ignore file */
import React from 'react';
import useLeaveService from 'erp/leave/shared/leaveService';
import LeaveForm from 'erp/leave/createupdate/LeaveForm';
import { CreateForm } from 'shared/uibuilder/form';
import useLeavesErrorMap from 'erp/leave/createupdate/errorHandlers';
import OneStopPageLayout from 'uibuilder/layout/form/OneStopPageLayout';
import LeaveNotStrictRulesHandler from '../LeaveNotStictRulesHandler';

type OneStopLeaveProps = {
  openModal: (message: string) => void;
};

const OneStopLeave = ({ openModal }: OneStopLeaveProps) => {
  const { createEmployeeLeave, getOneStopValidationSchema } = useLeaveService();
  const errorsMap = useLeavesErrorMap();

  return (
    <CreateForm
      submitFormFunc={createEmployeeLeave}
      afterSubmit={{
        execute: response => {
          openModal(response.message);
        },
      }}
      getValidationSchemaFunc={getOneStopValidationSchema}
      errorsMap={errorsMap}
    >
      <LeaveNotStrictRulesHandler errorMap={errorsMap} />
      <OneStopPageLayout>
        <LeaveForm isOneStop />
      </OneStopPageLayout>
    </CreateForm>
  );
};

export default OneStopLeave;
