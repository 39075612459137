/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import ServiceCatalogBreadcrumbs from 'erp/serviceCatalog/shared/ServiceCatalogBreadcrumbs';

const SingleServiceCatalogBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useServiceCatalogUrl();
  const id = useServiceCatalogId();
  const serviceName = data?.getValueBySource('name') ?? 'Service';

  return (
    <ServiceCatalogBreadcrumbs>
      <SingleBreadcrumbItem entity={serviceName} link={getSingleEntityUrl(id)} />
      {children}
    </ServiceCatalogBreadcrumbs>
  );
};

export default SingleServiceCatalogBreadcrumbs;
