/* istanbul ignore file */
// libs
import React from 'react';
import { useParams } from 'react-router-dom';
// components
import Show from 'shared/uibuilder/show/Show';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import {
  AddressField,
  HtmlField,
  PhoneField,
  TextField,
  ListField,
  EmailField,
  LinkField,
} from 'shared/uibuilder/field';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import { LeadLinkField } from 'crm/lead';
import ContactsMenu from 'crm/contact/shared/ContactsMenu';
import EditContactButton from 'crm/contact/shared/button/EditContactButton';
import DeleteContactButton from 'crm/contact/shared/button/DeleteContactButton';
import ContactEntityHeader from 'crm/contact/shared/ContactEntityHeader';
import AccountLinkField from 'crm/account/shared/field/AccountLinkField';
import SingleContactsBreadcrumbs from 'crm/contact/shared/SingleContactsBreadcrumbs';
// services
import useContactService from 'crm/contact/shared/contactService';
import { UPDATE } from 'crm/crmService';

const ContactViewPage = () => {
  const { id = '' } = useParams<Dictionary<string>>();
  const { getById } = useContactService();

  const buttons = [
    <EditContactButton key="edit-contact" permissionToCheck={UPDATE} />,
    <DeleteContactButton key="delete-contact" permissionToCheck={UPDATE} sx={{ marginLeft: '12px' }} />,
  ];

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        headerButtons={buttons}
        menu={<ContactsMenu />}
        breadcrumbs={<SingleContactsBreadcrumbs />}
        entityHeader={<ContactEntityHeader />}
        hasSeparateSections
      >
        <ShowSection title="Contact Information">
          <SectionRow>
            <ListField source="emails">
              <EmailField />
            </ListField>
            <PhoneField source="phoneNumber" label="Phone 1" />
            <PhoneField source="mobilePhoneNumber" label="Phone 2" />
            <LinkField source="linkedInLink" label="LinkedIn" />
            <AccountLinkField
              label="Account Name"
              source="accountName"
              idSource="accountId"
              isInternal={false}
              isVisible={(data: any) => data.getValueBySource('accountId') && data.getValueBySource('accountName')}
            />
          </SectionRow>
          <SectionRow>
            <TextField source="title" />
            <LeadLinkField
              label="Converted From"
              idSource="convertedFromId"
              nameSource="convertedFromName"
              isVisible={(data: any) => data.getValueBySource('convertedFromId')}
            />
          </SectionRow>
        </ShowSection>
        <ShowSection title="Address Information">
          <AddressField source="address" />
        </ShowSection>
        <ShowSection title="Description">
          <BigSectionRow>
            <ReplaceUrlWithLink>
              <HtmlField source="description" label="" />
            </ReplaceUrlWithLink>
          </BigSectionRow>
        </ShowSection>
        <ShowSection title="Attachments">
          <AttachmentsListField source="attachmentsIds" label="" />
        </ShowSection>
        <ShowSection title="Additional Information" isVisible={data => data.getValueBySource('additionalInformation')}>
          <TextField source="additionalInformation" label="" />
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ContactViewPage;
