/* istanbul ignore file */
import React from 'react';
import Condition, { ConditionProps } from './Condition';
import { FilterCondition } from '../FilterContext';

export const EQ_CONDITION_TYPE = 'eq';

const EqCondition = ({ children, ...conditionProps }: ConditionProps) => {
  return (
    <Condition {...conditionProps} conditionType={FilterCondition.EQ}>
      {children}
    </Condition>
  );
};

export default EqCondition;
