import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import AccountLinkField from 'crm/account/shared/field/AccountLinkField';
import ArtifactStorageFileField from 'artifactStorage/shared/field/ArtifactStorageFileField';
import { ArtifactFileTypeLabel } from 'artifactStorage/shared/artifactStorageService';
import ProjectLink from 'erp/project/shared/field/ProjectLink';

type ArtifactStorageAdditionalInfoFieldProps = {
  label?: string;
  source: string;
  additionalSource?: string;
};

export const getAdditionalInfoField = (info: any) => {
  let name = null;
  let field = null;

  switch (info.entityClass) {
    case 'Account':
      name = 'Account';
      field = (
        <AccountLinkField
          idSource=""
          value={info.entity.accountName}
          idValue={info.entity.accountId}
          isInternal={false}
        />
      );
      break;
    case 'ArtifactStorageFile':
      name = ArtifactFileTypeLabel[info.entity.metaInformation.find((data: any) => data.key === 'TYPE')?.value] || '';
      field = <ArtifactStorageFileField source="metaInformation" value={info.entity.artifact} />;
      break;
    case 'Project':
      name = 'Project';
      field = <ProjectLink isClickable name={info.entity.name} id={info.entity.id} />;
      break;
    default:
      name = null;
      field = null;
  }

  if (name || field) {
    return (
      <div key={info.entityId}>
        {name ? <span className="mr-1">{name}:</span> : null}
        {field}
      </div>
    );
  }

  return null;
};

export const getMetaInfoField = (info: any) => {
  let name = null;
  let field = null;

  switch (info.key) {
    case 'TYPE':
      name = 'Type';
      field = info.value === 'INACTIVE_CONTRACT' ? 'Inactive Contract' : ArtifactFileTypeLabel[info.value];
      break;
    case 'STATEMENT_OF_WORK_NUMBER':
      name = 'SOW Number';
      field = info.value;
      break;
    case 'MSA_NUMBER':
      name = 'MSA Number';
      field = info.value;
      break;
    case 'CHANGE_REQUEST_NUMBER':
      name = 'Change Request Number';
      field = info.value;
      break;
    case 'AMENDMENT_NUMBER':
      name = 'Amendment Number';
      field = info.value;
      break;
    case 'COMPANY_SERVICE_BUDGET_NAME':
      name = 'Budget Name';
      field = info.value;
      break;
    default:
      name = null;
      field = null;
  }

  if (name || field) {
    return (
      <div key={info.id}>
        {name ? <span className="mr-1">{name}:</span> : null}
        {field}
      </div>
    );
  }

  return null;
};

const ArtifactStorageAdditionalInfoField = ({
  source,
  additionalSource,
  label,
}: ArtifactStorageAdditionalInfoFieldProps) => {
  const { getValue } = useFieldHelper<any>({ source, label });
  const { getValue: getAdditionalInfo } = useFieldHelper<any>({ source: additionalSource });
  const metaInfo: any[] = getValue() || [];
  const additionalInfo: any[] = getAdditionalInfo() || [];

  return (
    <>
      {metaInfo.map(getMetaInfoField)}
      {additionalInfo.map(getAdditionalInfoField)}
    </>
  );
};

export default ArtifactStorageAdditionalInfoField;
