import React, { useEffect, useState } from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import Loading from 'shared/uibuilder/Loading';
import Fade from 'uibuilder/Fade';
import './KpiValueLayout.css';

const KpiValueLayout = ({ filter, children }: { filter: React.ReactNode; children: React.ReactNode }) => {
  const { data, loading } = useListContext();
  const [mapLoading, setMapLoading] = useState(true);

  useEffect(() => {
    setMapLoading(true);
  }, [loading]);

  useEffect(() => {
    if (data) {
      setMapLoading(false);
    }
  }, [data]);

  if (loading || mapLoading) {
    return <Loading />;
  }

  return (
    <Fade in={!loading && !mapLoading} timeout={20}>
      <div className="kpi-value-layout">
        <div className="kpi-value-layout__header">
          <div className="kpi-value-layout__controls">{filter}</div>
        </div>
        {children}
      </div>
    </Fade>
  );
};

export default KpiValueLayout;
