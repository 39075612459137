/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useProjectWeeklyReportsService from 'erp/project/weeklyreports/ProjectWeeklyReportService';

export const PROJECT_WEEKLY_REPORT_CONTEXT = 'Project Weekly Report';

const ProjectWeeklyReportContext = ({
  permissionToCheck,
  children,
}: {
  permissionToCheck: string | string[];
  children: React.ReactNode;
}) => {
  const { getPermissions } = useProjectWeeklyReportsService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions as any}
      permissionToCheck={permissionToCheck}
      contextName={PROJECT_WEEKLY_REPORT_CONTEXT}
      idSource="reportId"
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default ProjectWeeklyReportContext;
