import { useCallback, useEffect, useState } from 'react';
import { useDateService } from 'shared/uibuilder/dateService';
import { MomentInput } from 'moment';

const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

type useTimerServiceProps = {
  expiredTimerCallback: () => void;
  timerLifetime: number;
  intervalUpdateDelay: number;
  intervalStep: number;
};

const useTimerService = ({
  expiredTimerCallback,
  timerLifetime,
  intervalUpdateDelay,
  intervalStep,
}: useTimerServiceProps) => {
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isTimerReset, setIsTimerReset] = useState(false);
  const [intervalId, setIntervalId] = useState<Nullable<NodeJS.Timeout>>(null);
  const [triggerCheck, setTriggerCheck] = useState<Nullable<MomentInput>>(null);
  const { getCurrentDateTime, getTimeDifference } = useDateService();
  const [lastActivityDateTime, setLastActivityDateTime] = useState(getCurrentDateTime());

  const clearTimer = useCallback(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      setIsTimerActive(false);
      setIsTimerReset(false);
    }
  }, [intervalId]);

  useEffect(() => {
    const lastActivityTimeDifference = getTimeDifference(lastActivityDateTime);
    setIsTimerActive(true);
    if (lastActivityTimeDifference < timerLifetime + intervalUpdateDelay) {
      return;
    }

    expiredTimerCallback();
    clearTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCheck]);

  const setTimer = () => {
    setLastActivityDateTime(getCurrentDateTime());

    const timeout = setInterval(() => {
      setTriggerCheck(getCurrentDateTime());
    }, intervalStep);

    setIsTimerReset(false);
    setIntervalId(timeout);
    setIsTimerActive(false);
  };

  const updateUserActivity = useCallback(() => {
    const lastActivityTimeDifference = getTimeDifference(lastActivityDateTime);
    if (lastActivityTimeDifference < intervalUpdateDelay) {
      return;
    }

    const dateTime = getCurrentDateTime();
    setLastActivityDateTime(dateTime);
    setIsTimerActive(false);
    setIsTimerReset(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastActivityDateTime]);

  useEffect(() => {
    if (intervalId) {
      activityEvents.forEach(eventName => {
        window.addEventListener(eventName, updateUserActivity);
      });
    }

    return () => {
      activityEvents.forEach(eventName => {
        window.removeEventListener(eventName, updateUserActivity);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalId]);

  useEffect(() => {
    if (isTimerReset) {
      clearTimer();
      setTimer();
    }
    // Suppressed warnings because we only need to call useEffect callback after reset timer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimerReset]);

  useEffect(() => {
    return () => {
      if (!isTimerReset) {
        clearTimer();
      }
    };
  }, [isTimerReset, clearTimer]);

  return {
    setTimer,
    clearTimer,
    intervalId,
    isTimerActive,
  };
};

export default useTimerService;
