/* istanbul ignore file */
// libs
import React from 'react';
import { Routes } from 'react-router-dom';
// components
import Page404 from 'shared/uibuilder/Page404';
import { useEmployeeRoute, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { READ_EMPLOYEE_QUALIFICATIONS } from 'erp/employee/employeeService';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import QualificationsListPage from 'erp/employee/newQualification/Show';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import { Route } from 'shared/routing';

const NEW_QUALIFICATIONS_PATH = '/qualifications';

export const useNewQualificationsRoute = () => {
  const { singleEntityRoute: employeeRoute } = useEmployeeRoute();

  return useRoute({
    listRoute: `${employeeRoute}${NEW_QUALIFICATIONS_PATH}`,
  });
};

export const useNewQualificationUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${employeeLink}${NEW_QUALIFICATIONS_PATH}`,
  });
};

const NewQualificationRouter = () => {
  const { listRoute } = useNewQualificationsRoute();

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <ProtectedRoute permissionToCheck={READ_EMPLOYEE_QUALIFICATIONS}>
            <QualificationsListPage />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default NewQualificationRouter;
