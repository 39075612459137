/* istanbul ignore file */
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import React from 'react';
import useErrorMapping from 'shared/uibuilder/form/errorsMapper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { FieldProps } from 'shared/uibuilder/field';

const BusinessRulesListField = (props: FieldProps<any[]>) => {
  const { getValue, getLabel } = useFieldHelper(props);
  const { BaseFieldLayout } = useUiTheme();
  const { errorMap } = props;
  const { mapErrorMessage } = useErrorMapping(errorMap);

  const violatedRules = getValue() || [];

  // @ts-ignore
  const uniqueViolatedRules = [...new Set(violatedRules)];

  const displayedRules = (
    <ul className="m-0 pl-3">
      {uniqueViolatedRules.map(rule => (
        <li key={rule}>{mapErrorMessage(rule)}</li>
      ))}
    </ul>
  );

  return <BaseFieldLayout label={getLabel()} value={displayedRules} isColumnDirection={uniqueViolatedRules.length} />;
};

export default BusinessRulesListField;
