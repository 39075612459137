/* istanbul ignore file */
import { extendTheme } from '@mui/material/styles';

const white = '#fff';
const commonInputBorder = 'rgba(0, 0, 0, 0.23)';

const COLOR_PALETTE = {
  primary: {
    main: '#5954A8',
    99: '#FFFBFF',
    95: '#F3EEFF',
    90: '#E3DFFF',
    80: '#C5C0FF',
    70: '#A7A1FC',
    60: '#8C86DF',
    50: '#726DC3',
    40: '#5954A8',
    30: '#413B8F',
    20: '#2A2277',
    10: '#140363',
    title: '#140363',
  },
  secondary: {
    main: '#A82682',
    95: '#FFECF3',
    90: '#FFD8EB',
    80: '#FFAEDC',
    10: '#3C002C',
  },
  info: {
    main: '#0288d1',
    95: '#d3eef6',
    90: '#aedfee',
    dark: '#01579b',
    light: '#03a9f4',
  },
  neutral: {
    main: '#666666',
    99: '#FFFBFF',
    95: '#F4EFF4',
    90: '#E5E1E6',
    80: '#C9C5CA',
    70: '#ADAAAF',
    60: '#929094',
    50: '#78767A',
    40: '#5F5E62',
    30: '#47464A',
    20: '#313034',
    10: '#1C1B1F',
    0: '#000',
    contrastText: white,
  },
  neutralLight: {
    main: '#E5E1EC',
    99: '#FFFBFF',
    95: '#F3EFFA',
    90: '#E5E1EC',
    80: '#C8C5D0',
    70: '#ADAAB4',
    60: '#928F99',
    50: '#787680',
    40: '#5F5D67',
    30: '#47464F',
    20: '#312F38',
    10: '#1B1B23',
    0: '#000000',
    dark: '#C8C5D0',
    contrastText: '#000',
  },
  error: {
    main: '#B3261E',
    95: '#FFDAD6',
    90: '#F9DEDC',
    80: '#F2B8B5',
    70: '#EC928E',
    60: '#ff5449',
    50: '#DC362E',
    40: '#cc453c',
  },
  success: {
    main: '#24A148',
    20: '#006d3c',
  },
  warning: {
    main: '#FF832B',
    50: '#ffe199',
    20: '#ca7516',
  },
};

const lightPinkColorPalette = {
  ...COLOR_PALETTE,
  text: {
    primary: '#232323',
  },
  background: {
    paper: white,
    default: '#F5F5F5',
  },
  common: {
    white,
    menuItemActive: 'rgba(89, 84, 168, 0.08)',
    commonInputBorder,
    tooltipBg: '#ece8f4',
    inputColor: 'rgba(0, 0, 0, 0.87)',
    inputDisabledBackground: 'rgba(0, 0, 0, 0.06)',
    sidebarMenuActive: '#3b4247',
    sidebarMenuBg: '#303539',
    sidebarMenuText: '#fff',
    surface: '#fafafa',
    surface1: 'linear-gradient(0deg, rgba(89, 84, 168, 0.05), rgba(89, 84, 168, 0.05)), #FFFBFF;',
    surface2: 'linear-gradient(0deg, rgba(89, 84, 168, 0.08), rgba(89, 84, 168, 0.08)), #FFFBFF;',
    surface3: 'linear-gradient(0deg, rgba(89, 84, 168, 0.11), rgba(89, 84, 168, 0.11)), #FFFBFF;',
    surface5: 'linear-gradient(0deg, rgba(89, 84, 168, 0.15), rgba(89, 84, 168, 0.15)), #FFFBFF;',
    surface4: '#eae7f3',
    sidebarBorder: '#AEAEAE',
    separator: '#e6e6e6',
    scrollbarTrack: '#f5f5f5',
    scrollbar: '#a8a8a85c',
    deletedTableRow: '#f6f6f6',
    tableRowHover: '#f1f1f1',
  },
  action: {
    disabled: 'rgba(31, 26, 28, 0.12)',
  },
};

export const violetTheme = extendTheme({
  breakpoints: {
    values: {
      xs: 0,
      smallMobile: 600,
      sm: 768,
      md: 990,
      lg: 1195,
      xl: 1440,
      xxl: 1500,
      xxxl: 1681,
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    body1: {
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'none',
    },
    display0: {
      fontSize: 80,
      lineHeight: '80px',
      fontWeight: 400,
      textTransform: 'none',
    },
    h1: {
      fontSize: 32,
      lineHeight: '40px',
      fontWeight: 400,
      textTransform: 'none',
    },
    h2: {
      fontSize: 28,
      lineHeight: '36px',
      fontWeight: 400,
      textTransform: 'none',
    },
    h3: {
      fontSize: 24,
      lineHeight: '32px',
      fontWeight: 400,
      textTransform: 'none',
    },
    h4: {
      fontSize: 22,
      lineHeight: '28px',
      fontWeight: 400,
      textTransform: 'none',
    },
    h5: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 600,
      textTransform: 'none',
    },
    subtitle1: {
      fontSize: 12,
      fontWeight: 500,
      textTransform: 'none',
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 600,
      textTransform: 'none',
    },
  },
  colorSchemes: {
    light: {
      palette: lightPinkColorPalette,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          borderBottom: '1px solid #f7f0f7',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          flexShrink: 0,
          '@media (max-width: 767px)': {
            padding: '3px 7px',
          },
        },
        outlined: {
          '&, &:hover': {
            borderColor: COLOR_PALETTE.neutral[50],
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          transition: 'none !important',
          wordWrap: 'break-word',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          padding: '12px',
          '&:first-child': {
            paddingLeft: '8px',
          },
          '&:last-child': {
            paddingRight: '8px',
          },
        },
        sizeSmall: { padding: '6px 16px' },
        head: {
          color: COLOR_PALETTE.primary.main,
          fontSize: '12px',
          lineHeight: '1.5',
          '& .Mui-active': {
            color: COLOR_PALETTE.primary.main,
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '1.5',
          marginRight: '-6px',
          '&.Mui-active': {
            color: COLOR_PALETTE.primary.main,
            '& .MuiTableSortLabel-icon': {
              fill: COLOR_PALETTE.primary.main,
            },
            '&: hover': {
              opacity: 0.8,
            },
          },
          '& .MuiSvgIcon-root': { opacity: 0.4, fontSize: '16px', fill: '#00000099' },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '11px',
          lineHeight: '1.4',
          marginLeft: 0,
          marginRight: 0,
          '&:not(:empty)': {
            marginBottom: '2px',
          },
          '&:empty': {
            marginTop: 0,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          lineHeight: '1',
          height: 'auto',
          minHeight: '16px',
        },
        label: {
          padding: '0 6px',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& > .MuiGrid-item': {
            paddingTop: 0,
          },
        },
        container: {
          marginTop: 0,
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: COLOR_PALETTE.neutral[50],
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          fontSize: '14px',
        },
        action: {
          paddingTop: '2px',
        },
        icon: {
          alignItems: 'center',
          marginRight: '14px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: commonInputBorder,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: COLOR_PALETTE.neutral[20],
        },
        filled: {
          color: COLOR_PALETTE.neutral[20],
        },
      },
    },
  },
});

export default violetTheme;
