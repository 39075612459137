import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { useKernelApi } from 'api';
import { RESOURCE_URL as EMPLOYEES_RESOURCE_URL } from 'erp/employee/employeeService';
import useBaseCrudService from 'shared/crud/baseCrudService';
import educationValidation from 'erp/employee/Education/createupdate/educationValidation';
import createEducationValidation from 'erp/employee/Education/createupdate/createEducationValidation';
import { LoadingParams } from 'shared/uibuilder/list/ListContext';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export const EDUCATIONS_RESOURCE_URL = '/educations';

export const EDUCATION_OPTIONS = {
  HIGHER: 'Higher Education',
  SECONDARY_SPECIAL: 'Secondary Special Education',
  COURSE: 'Course',
  TRAINING: 'Training',
};

export const DOCUMENT_TYPES = {
  DIPLOMA: 'Diploma',
  CERTIFICATE: 'Certificate',
};

/**
 * Education service.
 */
const useEducationService = () => {
  const { sendGetRequest } = useKernelApi();
  const employeeId = useEmployeeId();
  const parentResourceUrl = `${EMPLOYEES_RESOURCE_URL}/${employeeId}`;
  const getValidationSchema = () => Promise.resolve(educationValidation);
  const getCreateValidationSchema = () => Promise.resolve(createEducationValidation);

  const mapFromApi = (education: { document: any }) => {
    const haveDocument = !!education.document;

    return {
      haveDocument,
      ...education,
    };
  };

  const mapEducation = (education: any, isCreateForm?: boolean) => {
    const result = { ...education };

    if (!education.haveDocument) {
      if (isCreateForm) {
        delete result.document;
      } else {
        result.document = {};
      }
    }

    delete result.haveDocument;

    return result;
  };

  const baseCrudRequests = useBaseCrudService({
    singleResourceUrl: `${EDUCATIONS_RESOURCE_URL}/:id`,
    listResourceUrl: `${parentResourceUrl}${EDUCATIONS_RESOURCE_URL}`,
    apiService: useKernelApi,
  });

  const getSearchParams = (request: LoadingParams) => {
    const params = [];
    params.push(`pageSize=${encodeURIComponent(request.pageSize)}`);
    params.push(`pageNumber=${encodeURIComponent(request.pageNumber)}`);

    // @ts-ignore
    if (Object.keys(request?.sort).length > 0) {
      // @ts-ignore
      const [sortKey, sortValue] = Object.entries(request?.sort)[0];
      params.push(`${encodeURIComponent(`sort[${sortKey}]`)}=${encodeURIComponent(sortValue)}`);
    }

    return params.join('&');
  };

  const search = async (request: any) => {
    const url = `${parentResourceUrl}${EDUCATIONS_RESOURCE_URL}?${getSearchParams(request)}`;
    const response = await sendGetRequest(url);
    const result = await response.json();
    return result;
  };

  const { getById, update: baseUpdate, create: baseCreate } = baseCrudRequests;

  const get = async (id: StringOrNumber) => {
    const response = await getById(id);

    return mapFromApi(response);
  };

  const update = async (id: StringOrNumber, education: any) => {
    return baseUpdate(id, mapEducation(education));
  };

  const create = async (data: FormFieldsData) => {
    const educations = data.request || [];
    const request = educations.map((item: any) => mapEducation(item, true));

    return baseCreate({
      request,
    });
  };

  return {
    ...baseCrudRequests,
    getValidationSchema,
    getCreateValidationSchema,
    search,
    get,
    update,
    create,
    mapFromApi,
    mapEducation,
  };
};

export default useEducationService;
