import React from 'react';
import { EmployeeLink } from 'erp/employee/index';
import { FEEDBACK_OBTAINED } from 'instantFeedback/input/ObtainedDropdown';

type FeedbackOnBehalfProps = {
  onBehalfOf?: string;
  onBehalfOfEmployee?: string;
  onBehalfOfEmployeeName?: string;
  obtainedBy?: string;
};

const FeedbackOnBehalf = ({
  onBehalfOf,
  onBehalfOfEmployee,
  onBehalfOfEmployeeName,
  obtainedBy,
}: FeedbackOnBehalfProps) => {
  let result: React.ReactElement | string = ' ';

  const obtainedByValue = obtainedBy ? FEEDBACK_OBTAINED[obtainedBy] : null;

  if (onBehalfOf) {
    result += `(on ${onBehalfOf}'s behalf${obtainedByValue ? ` from ${obtainedByValue})` : ')'}`;
  }

  if (onBehalfOfEmployee) {
    result = (
      <>
        {'(on '}
        <EmployeeLink id={onBehalfOfEmployee} name={onBehalfOfEmployeeName} />
        `s behalf{obtainedByValue ? ` from ${obtainedByValue}` : ''})
      </>
    );
  }

  return result;
};

export default FeedbackOnBehalf;
