import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import SingleVacancyBreadcrumbs from '../shared/SingleVacancyBreadcrumbs';

type ShowVacancyPageLayoutProps = {
  children: React.ReactElement[] | React.ReactElement;
};

const ShowVacanciesGroupPageLayout = ({ children }: ShowVacancyPageLayoutProps) => {
  return (
    <ShowPageLayout breadcrumbs={<SingleVacancyBreadcrumbs />} hasSeparateSections>
      {children}
    </ShowPageLayout>
  );
};

export default ShowVacanciesGroupPageLayout;
