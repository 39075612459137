/* istanbul ignore file */
import React from 'react';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Routes, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import Page404 from 'shared/uibuilder/Page404';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';
import { READ_DEFINITIONS_LIST } from 'camunda/monitoring/definition/processDefinitionService';
import ProcessDefinitionListPage from 'camunda/monitoring/definition/List/ProcessDefinitionListPage';
import ProcessDefinitionRedirect from 'camunda/monitoring/definition/Show/ProcessDefinitionRedirect';

export const PROCESS_DEFINITION_PATH = '/processes/definitions';

export const useProcessDefinitionRoute = () => {
  return useRoute({ listRoute: PROCESS_DEFINITION_PATH });
};

export const useProcessDefinitionUrl = () => {
  return useEntityUrl({ baseLink: PROCESS_DEFINITION_PATH });
};

export const useProcessDefinitionKey = () => useParams<Dictionary<string>>().id!;

const ProcessDefinitionRouter: Router = () => {
  const { listRoute, singleEntityRoute } = useProcessDefinitionRoute();

  return (
    <ProtectedRouter basePath={PROCESS_DEFINITION_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_DEFINITIONS_LIST}>
              <ProcessDefinitionListPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_DEFINITIONS_LIST}>
              <ProcessDefinitionRedirect />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

ProcessDefinitionRouter.getRouterPath = () => '/definitions/*';

export default ProcessDefinitionRouter;
