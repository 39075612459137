import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { TextField } from 'shared/uibuilder/field';
import ShowDate from 'shared/uibuilder/ShowDate';
import './ProjectDashboardDateField.scss';
import { ProjectDashboardCycleStatus } from '../../ProjectDashboardService';

interface ProjectDashboardDateFieldProps {
  source: string;
  sourceOnTime: string;
  sourceCycleStatus: string;
  [key: string]: any;
}

const ProjectDashboardDateField = ({ source, sourceOnTime, sourceCycleStatus }: ProjectDashboardDateFieldProps) => {
  const { getValue: getOnTime } = useFieldHelper({ source: sourceOnTime });
  const { getValue: getCycleStatus } = useFieldHelper({ source: sourceCycleStatus });
  const { getValue: getDate } = useFieldHelper({ source });
  let component = <TextField value={getDate()} />;

  if (getCycleStatus() !== ProjectDashboardCycleStatus.FUTURE) {
    if (getDate()) {
      component = (
        <span className={getOnTime() ? 'on-time' : 'with-delay'}>
          <ShowDate dateUTC={getDate()} format={DATE_FORMAT.FULL_DATE} isDateTime={false} />
        </span>
      );
    } else if (getCycleStatus() !== ProjectDashboardCycleStatus.ACTIVE) {
      component = <span className="no-data">No data</span>;
    }
  }

  return component;
};

export default ProjectDashboardDateField;
