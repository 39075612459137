/* istanbul ignore file */
// libraries
import React from 'react';
import { Route, Routes } from 'react-router-dom';
// components
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import Page404 from 'shared/uibuilder/Page404';
import CompanyRolesList from './lists/CompanyRolesList';
import AddEmployeeCompanyRole from './create/AddEmployeeCompanyRole';
// services
import {
  READ_EMPLOYEE_COMPANY_ROLES,
  UPDATE_EMPLOYEE_COMPANY_ROLES,
  useCompanyRolesRoute,
} from './shared/companyRolesService';

const CompanyRolesRouter = () => {
  const { listRoute, createRoute } = useCompanyRolesRoute();

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <ProtectedRoute permissionToCheck={READ_EMPLOYEE_COMPANY_ROLES}>
            <CompanyRolesList />
          </ProtectedRoute>
        }
      />

      <Route
        path={createRoute}
        element={
          <ProtectedRoute permissionToCheck={UPDATE_EMPLOYEE_COMPANY_ROLES}>
            <AddEmployeeCompanyRole />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default CompanyRolesRouter;
