/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import KpiValuesTable from './KpiValuesTable';
import KpiValueLayout from './KpiValueLayout';
import useKPIService from '../useKPIService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { useServiceCatalogId } from '../../ServiceCatalogRouter';
import ViewServiceCatalogPageMenu from '../../Show/ViewServiceCatalogPageMenu';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ListKpiValuesBreadcrumbs from './ListKpiValuesBreadcrumbs';
import ServiceCatalogHeader from '../../shared/ServiceCatalogHeader';
import KpiValuesStaticFilters from './filter/KpiValuesStaticFilters';

const KpiValuesListPage = () => {
  const { searchKPIValues } = useKPIService();
  const currentMonth = moment().format(DATE_FORMAT.INPUT_MONTH_VALUE);
  const startDate = moment().subtract(3, 'months').format(DATE_FORMAT.INPUT_MONTH_VALUE);

  const id = useServiceCatalogId();

  const filters = (
    <Filters>
      <KpiValuesStaticFilters />
    </Filters>
  );

  return (
    <ShowPageLayout
      breadcrumbs={<ListKpiValuesBreadcrumbs />}
      entityHeader={<ServiceCatalogHeader />}
      menu={<ViewServiceCatalogPageMenu />}
      hasSeparateSections
    >
      <List
        getDataMethod={async req => searchKPIValues(id, req)}
        defaultFilter={{ period: { ge: startDate, le: currentMonth } }}
      >
        <ListPageLayout header={<ListHeader label="KPI Values" />}>
          <KpiValueLayout filter={filters}>
            <KpiValuesTable />
          </KpiValueLayout>
        </ListPageLayout>
      </List>
    </ShowPageLayout>
  );
};

export default KpiValuesListPage;
