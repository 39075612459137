/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import RemovableInputWrapper from 'shared/uibuilder/form/RemovableInputWrapper';
import { DefaultInputPropTypes, TextArea, TextInput } from 'shared/uibuilder/form/input';
import EducationTypeInput from 'erp/employee/Education/shared/input/EducationTypeDropdown';
import EducationalDocumentInput from 'erp/employee/Education/shared/input/EducationalDocumentInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

interface EducationInputProps extends DefaultInputPropTypes<any> {
  isAdditionalInfoVisible?: boolean;
}

const EducationInput = ({ isAdditionalInfoVisible = true, ...props }: EducationInputProps) => {
  const { getSource, getValue } = useInputHelper(props);
  const source = getSource() || '';
  const educationData = getValue();

  const sourcePrefix = source ? `${source}.` : '';
  const documentSource = `${sourcePrefix}document`;

  return (
    <>
      <FormRow>
        <EducationTypeInput
          source={`${sourcePrefix}type`}
          label="Education Type"
          value={educationData.type}
          placeholder="Education Type"
        />
        <TextInput
          source={`${sourcePrefix}providerName`}
          label="Education Provider"
          value={educationData.providerName}
        />
        <TextInput source={`${sourcePrefix}graduationYear`} value={educationData.graduationYear} />
        <TextInput source={`${sourcePrefix}specialization`} value={educationData.specialization} />
      </FormRow>
      <MediumFormRow>
        <TextInput source={`${sourcePrefix}assignedQualification`} value={educationData.assignedQualification} />
      </MediumFormRow>
      {isAdditionalInfoVisible && (
        <MediumFormRow>
          <TextArea source={`${sourcePrefix}additionalInformation`} value={educationData.additionalInformation} />
        </MediumFormRow>
      )}
      <BigFormRow>
        <div className="education-document__wrapper">
          <RemovableInputWrapper
            addText="Add educational document"
            deleteText="Delete educational document"
            colSize={3}
            source={`${sourcePrefix}haveDocument`}
            value={educationData.haveDocument}
          >
            <EducationalDocumentInput source={documentSource} value={educationData.document} />
          </RemovableInputWrapper>
        </div>
      </BigFormRow>
    </>
  );
};

export default EducationInput;
