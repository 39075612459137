import assetValidation, { editFormValidationSchema } from 'financialAnalytic/assets/createupdate/assetValidation';
import { useFinanceApi, useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import useSingletonPromise from 'shared/useSingletonPromise';
import useEmployeeService from 'erp/employee/employeeService';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import { cloneDeep } from 'lodash';
import { ResourceData, ResourceId } from 'shared/crud/baseCrudService';
import useTimelineService from 'shared/crud/timelineService';

export const READ_ASSET_LIST = 'COMPANY_READ_ASSET_LIST';
export const READ_ASSET = 'COMPANY_READ_ASSET';
export const DISPOSE_ASSET = 'COMPANY_DISPOSE_ASSET';
export const WRITE_OFF_ASSET = 'COMPANY_WRITE_OFF_ASSET';
export const CREATE_ASSET = 'COMPANY_CREATE_ASSET';
export const UPDATE_ASSET = 'COMPANY_UPDATE_ASSET';
export const UPDATE_ASSET_ASSIGNMENT = 'COMPANY_UPDATE_ASSET_ASSIGNMENT';
export const PERFORM_ASSET_COST_PRORATING = 'COMPANY_PERFORM_ASSET_COST_PRORATING';
export const READ_TIMELINE = 'COMPANY_SEARCH_ASSET_NOTES';
export const ASSET_PATH = '/assets';
export const RESOURCE_URL = '/asset';

export const ASSET_CATEGORIES = {
  INDIVIDUAL_EQUIPMENT: 'INDIVIDUAL_EQUIPMENT',
  OFFICE_EQUIPMENT: 'OFFICE_EQUIPMENT',
  COST_CENTER_EQUIPMENT: 'COST_CENTER_EQUIPMENT',
  FURNITURE: 'OFFICE_FURNITURE',
  COMPANY_INTANGIBLE_ASSETS: 'COMPANY_INTANGIBLE_ASSETS',
  COST_CENTER_INTANGIBLE_ASSETS: 'COST_CENTER_INTANGIBLE_ASSETS',
};

export const ASSET_CATEGORIES_OPTIONS = {
  [ASSET_CATEGORIES.INDIVIDUAL_EQUIPMENT]: 'Individual Equipment',
  [ASSET_CATEGORIES.OFFICE_EQUIPMENT]: 'Office Equipment',
  [ASSET_CATEGORIES.COST_CENTER_EQUIPMENT]: 'Cost Center Equipment',
  [ASSET_CATEGORIES.FURNITURE]: 'Furniture',
  [ASSET_CATEGORIES.COMPANY_INTANGIBLE_ASSETS]: 'Company Intangible Assets',
  [ASSET_CATEGORIES.COST_CENTER_INTANGIBLE_ASSETS]: 'Cost Center Intangible Assets',
};

export const ASSET_STATUS = {
  REGISTERED: 'REGISTERED',
  ON_BALANCE: 'ON_BALANCE',
  ON_BALANCE_COST_PRORATED: 'ON_BALANCE_COST_PRORATED',
  WRITE_OFF_AFTER_PURCHASE: 'WRITE_OFF_AFTER_PURCHASE',
  WRITE_OFF_AFTER_COST_PRORATING: 'WRITE_OFF_AFTER_COST_PRORATING',
  DISPOSED: 'DISPOSED',
};

export const ASSET_STATUS_OPTIONS = {
  [ASSET_STATUS.REGISTERED]: 'Registered',
  [ASSET_STATUS.ON_BALANCE]: 'On Balance',
  [ASSET_STATUS.ON_BALANCE_COST_PRORATED]: 'On Balance Cost Prorated',
  [ASSET_STATUS.WRITE_OFF_AFTER_PURCHASE]: 'Write-off after purchase',
  [ASSET_STATUS.WRITE_OFF_AFTER_COST_PRORATING]: 'Write-off after cost prorating',
  [ASSET_STATUS.DISPOSED]: 'Disposed',
};

export const INTANGIBLE_ASSETS_CATEGORY_TYPES = {
  SOFTWARE_LICENSE_KEY: 'Software License Key',
  LICENSE: 'License',
  SUBSCRIPTION: 'Subscription',
  CERTIFICATE: 'Certificate',
  OTHER_INTANGIBLE_ASSETS: 'Other',
};

export const ASSET_CATEGORIES_TYPES: Dictionary<Dictionary<string>> = {
  [ASSET_CATEGORIES.INDIVIDUAL_EQUIPMENT]: {
    LAPTOP: 'Laptop',
    MOUSE: 'Mouse',
    HEADSET: 'Headset',
    WEBCAM: 'Webcam',
    MONITOR: 'Monitor',
    SYSTEM_UNIT: 'System Unit',
    SPEAKER: 'Speaker',
    TABLET: 'Tablet',
    KEYBOARD: 'Keyboard',
    ADAPTER: 'Adapter',
    OTHER_INDIVIDUAL_EQUIPMENT: 'Other',
  },
  [ASSET_CATEGORIES.OFFICE_EQUIPMENT]: {
    COFFEE_MACHINE: 'Coffee Machine',
    ELECTRIC_KETTLE: 'Electric Kettle',
    PRINTER: 'Printer',
    OTHER_OFFICE_EQUIPMENT: 'Other',
  },
  [ASSET_CATEGORIES.FURNITURE]: {
    TABLE: 'Table',
    ARMCHAIR: 'Armchair',
    WARDROBE: 'Wardrobe',
    SHELVING: 'Shelving',
    PICTURE: 'Picture',
    OTHER_OFFICE_FURNITURE: 'Other',
  },
  [ASSET_CATEGORIES.COMPANY_INTANGIBLE_ASSETS]: INTANGIBLE_ASSETS_CATEGORY_TYPES,
  [ASSET_CATEGORIES.COST_CENTER_INTANGIBLE_ASSETS]: INTANGIBLE_ASSETS_CATEGORY_TYPES,
  [ASSET_CATEGORIES.COST_CENTER_EQUIPMENT]: {
    TEST_DEVICE: 'Test device',
    OTHER_COST_CENTER_EQUIPMENT: 'Other',
  },
};

export const ASSET_CONDITIONS = {
  NEW: 'Brand new',
  USED: 'Used, but still good enough to use',
  BROKEN: 'Broken, eligible for repair',
  LOST: 'Completely lost',
  UNAVAILABLE: 'Unavailable for provision',
};

export const isEquipmentCategory = (category: string) =>
  [ASSET_CATEGORIES.INDIVIDUAL_EQUIPMENT, ASSET_CATEGORIES.OFFICE_EQUIPMENT, ASSET_CATEGORIES.FURNITURE].includes(
    category,
  );

export const isExpandedEquipmentCategory = (category: string) =>
  category === ASSET_CATEGORIES.COST_CENTER_EQUIPMENT || isEquipmentCategory(category);

export const isIntangibleAsset = (category: string) =>
  [ASSET_CATEGORIES.COMPANY_INTANGIBLE_ASSETS, ASSET_CATEGORIES.COST_CENTER_INTANGIBLE_ASSETS].includes(category);

export const isSubscription = (type: string) => type === 'SUBSCRIPTION';

export const isAutoRenewSubscription = (subscriptionDetails: any) => subscriptionDetails?.isAutoRenew;

const useAssetService = () => {
  const { getFullName } = useEmployeeService();
  const { getEmployeeNameDataById } = useEmployeeNamesService();
  const { sendGetRequest, sendPostRequest, sendPutRequest } = useFinanceApi();

  const {
    search: baseSearch,
    create: initialCreate,
    getById: initialGetById,
    update: initialUpdate,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useFinanceApi,
  });
  const { getPermissions } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const { search: initialSearchTimelineItems, create: initialCreateTimelineItem } = useTimelineService({
    parentEntityUrl: `${RESOURCE_URL}/:id`,
    apiService: useFinanceApi,
  });

  const searchTimelineItems = async (entityId: ResourceId, request: ResourceData) => {
    const response = await initialSearchTimelineItems(entityId, request);

    return {
      ...response,
      result: response.result.map((item: any) => ({ ...item, createdById: item.createdBy, timelineId: item.id })),
    };
  };

  const createTimelineItem = async (entityId: ResourceId, timelineEntityType: string, data: ResourceData) => {
    const response = (await initialCreateTimelineItem(entityId, timelineEntityType, data)) as any;

    return {
      ...response,
      createdById: response.createdBy,
      timelineId: response.id,
    };
  };

  const search = (request: ResourceData) => {
    const searchParams = cloneDeep(request);
    const searchString = searchParams.filter?.['specification:search']?.eq || '';
    const currentAssignmentStatus = searchParams.filter?.currentAssignmentStatus?.in?.[0] || '';

    if (searchString && searchParams.filter) {
      delete searchParams.filter?.['specification:search'];
      searchParams.filter.name = {
        ct: searchString,
      };
    }

    if (currentAssignmentStatus && searchParams.filter) {
      delete searchParams.filter.currentAssignmentStatus;
      searchParams.filter['specification:currentAssignmentStatus'] = {
        [currentAssignmentStatus === 'YES' ? 'eq' : 'ne']: 'ACTIVE',
      };
    }

    return baseSearch(searchParams);
  };

  const create = async ({ cost, assignmentDetails: initialAssignmentDetails, ...data }: any) => {
    let assignmentDetails = { ...(initialAssignmentDetails || {}) };

    if (assignmentDetails.currentOwner) {
      delete assignmentDetails.currentOwnerName;
      delete assignmentDetails.currentOwnerFullName;
    } else {
      assignmentDetails = null;
    }

    return initialCreate({
      ...data,
      cost: {
        ...cost,
        currency: cost.currency === 'BYN' ? 'BYR' : cost.currency,
      },
      assignmentDetails,
    });
  };

  const getBalanceAccounts = useSingletonPromise('search-asset-balance-accounts', async () => {
    const response = await sendGetRequest('/asset/accounts/balance');
    const result = await response.json();

    return {
      result,
      totalElements: result.length,
      totalPages: 1,
    };
  });

  const getExpenseAccounts = useSingletonPromise('search-asset-expense-accounts', async () => {
    const response = await sendGetRequest('/asset/accounts/expense');
    const result = await response.json();

    return {
      result,
      totalElements: result.length,
      totalPages: 1,
    };
  });

  const getBusinessRules = async () => {
    const response = await sendGetRequest('/asset/business-rules-metadata/useful-life');

    return response.json();
  };

  const getValidationSchema = () => Promise.resolve(assetValidation);

  const getUpdateValidationSchema = () => Promise.resolve(editFormValidationSchema);

  const getById = async (id: string) => {
    const { assignmentDetails, ...data } = await initialGetById(id);

    if (assignmentDetails?.currentOwner) {
      try {
        const employeeName = (await getEmployeeNameDataById(assignmentDetails.currentOwner)) || {};
        assignmentDetails.currentOwnerName = employeeName.nameEn;
        assignmentDetails.currentOwnerFullName = getFullName(employeeName.nameEn);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    return {
      ...data,
      assignmentDetails,
    };
  };

  const getByIdForCopying = async (id: string) => {
    const { assignmentDetails, ...data } = await getById(id);

    if (assignmentDetails?.currentOwner) {
      const employeeName = assignmentDetails.currentOwnerName;
      assignmentDetails.currentOwnerFullName = `${assignmentDetails.currentOwner} ${
        employeeName?.firstName || ''
      } ${employeeName?.lastName || ''}`.trim();
    }

    return {
      category: data.category,
      type: data.type,
      office: data.office,
      name: data.name,
      condition: data.condition,
      serialNumber: data.serialNumber,
      characteristics: data.characteristics,
      attribution: data.attribution,
      cost: {
        ...data.costInPurchaseCurrency,
        currency: data.costInPurchaseCurrency.currency === 'BYR' ? 'BYN' : data.costInPurchaseCurrency.currency,
      },
      customUsefulLifePeriod: data.usefulLifePeriod,
      purchaseDate: data.purchaseDate,
      validityStartDate: data.validityStartDate,
      validityEndDate: data.validityEndDate,
      warrantyEndDate: data.warrantyEndDate,
      subscriptionDetails: data.subscriptionDetails,
      assignmentDetails,
    };
  };

  const performAssetCostProrating = (costProratingDate: string) =>
    sendPostRequest(`${RESOURCE_URL}/cost-prorating/perform`, { costProratingDate });

  const updateAssignmentDetails = async (
    inventoryNumberId: string,
    { assignmentDetails: initialAssignmentDetails }: any,
  ) => {
    let assignmentDetails = { ...(initialAssignmentDetails || {}) };

    if (assignmentDetails.currentOwner) {
      delete assignmentDetails.currentOwnerName;
      delete assignmentDetails.currentOwnerFullName;
    } else {
      assignmentDetails = null;
    }

    const response = await sendPutRequest(`${RESOURCE_URL}/${inventoryNumberId}/assignment-details`, {
      assignmentDetails,
    });

    return response.json();
  };

  const runMigration = async (data: Dictionary<string>) => {
    return sendPostRequest(`${RESOURCE_URL}/migration`, data);
  };

  const disposeAsset = async (inventoryNumber: string) => {
    const res = await sendPostRequest(`${RESOURCE_URL}/${inventoryNumber}/dispose`);
    return res.json();
  };

  const writeOffAsset = async ({
    inventoryNumber,
    expenseAccountId,
    budgetCategory,
  }: {
    inventoryNumber: string;
    expenseAccountId: string;
    budgetCategory: string;
  }) => {
    const res = await sendPostRequest(`${RESOURCE_URL}/${inventoryNumber}/write-off`, {
      expenseAccountId,
      budgetCategory,
    });
    return res.json();
  };

  const update = async (inventoryNumber: StringOrNumber, { cost, ...data }: any) => {
    return initialUpdate(inventoryNumber, {
      ...data,
      cost: {
        ...cost,
        currency: cost.currency === 'BYN' ? 'BYR' : cost.currency,
      },
    });
  };

  return {
    ...baseCrud,
    create,
    disposeAsset,
    writeOffAsset,
    getValidationSchema,
    getBalanceAccounts,
    getExpenseAccounts,
    getBusinessRules,
    getById,
    getByIdForCopying,
    performAssetCostProrating,
    search,
    updateAssignmentDetails,
    getUpdateValidationSchema,
    runMigration,
    searchTimelineItems,
    createTimelineItem,
    update,
    getPermissions,
  };
};

export default useAssetService;
