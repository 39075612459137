import { get } from 'lodash';
import Data from 'shared/uibuilder/Data';

class ShowData implements Data {
  private readonly data: object;

  constructor(data: object) {
    this.data = data;
    Object.entries(this.data).forEach(([key, value]) => {
      // @ts-ignore
      this[key] = value;
    });
  }

  getData() {
    return this.data;
  }

  getValueBySource(source: Nullable<any>) {
    return get(this.data, source);
  }

  isNotEmpty() {
    return Object.keys(this.data).length > 0;
  }
}

export default ShowData;
