import React from 'react';
import useAccountService from 'crm/account/accountService';
import { SearchInput } from 'shared/uibuilder/form/input';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

const mapResponse = (account: { accountId: any; accountName: any; hasCodeName: any; hasProjects: any }) => {
  return {
    id: account.accountId,
    text: account.accountName,
    accountName: account.accountName,
    hasAccountCodeName: account.hasCodeName,
    accountHasProjects: account.hasProjects,
  };
};

type AccountInputProps = {
  accountNameSource?: string;
  accountNameValue?: Nullable<string>;
  customMapResponse?: (account: { accountId: any; accountName: any; hasCodeName: any; hasProjects: any }) => any;
  label?: Nullable<string>;
  source?: string;
  onChangeCallback?: (values: FormFieldsData) => void;
  errorSource?: string;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  required?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  tooltip?: string;
  defaultFilter?: Object;
};

const AccountInput = ({ accountNameSource = 'accountName', accountNameValue = '', ...props }: AccountInputProps) => {
  const { search } = useAccountService();
  const { customMapResponse } = props;

  return (
    <SearchInput
      searchRequest={search}
      noResultMessage="No Accounts are found."
      mapResults={customMapResponse || mapResponse}
      initialLabel={accountNameValue}
      nameSource={accountNameSource}
      {...props}
    />
  );
};

export default AccountInput;
