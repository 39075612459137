import React from 'react';
import useCandidateService from 'erp/candidate/shared/candidateService';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const CandidateDropdown = ({ filter = {}, ...props }: DefaultDropdownProps) => {
  const { searchAll } = useCandidateService();

  const loadCandidates = async () => {
    const candidates = await searchAll({ filter });
    return {
      result: candidates,
    };
  };

  return (
    <LoadDropdown
      loadDataMethod={loadCandidates}
      mapResults={candidate => ({
        value: candidate.id,
        label: `${candidate.name.fullName} (id: ${candidate.id})`,
      })}
      {...props}
    />
  );
};

export default CandidateDropdown;
