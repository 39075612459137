import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useResumeService from 'erp/candidate/Resume/shared/resumeService';
import useFeatureToggle, { Features } from 'featuretoggle';
import CandidateResumeUploaderInput from 'artifact/shared/input/CandidateResumeUploaderInput';
import Loading from 'shared/uibuilder/Loading';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';

export interface CandidateApplicationResumeUploaderWithParsingInputProps {
  parsingEnabled: boolean;
  resumeParsed: boolean;
  onParsed: () => void;
  onNoResume: () => void;
  source: string;
  label?: string;
}

const CandidateApplicationResumeUploaderWithParsingInput = (
  props: CandidateApplicationResumeUploaderWithParsingInputProps,
) => {
  const { getValue } = useInputHelper(props);
  const { data: formData, setSubmitEnabled = () => {}, setFormData = () => {} } = useFormContext();
  const { parseCandidateDataFromResume } = useResumeService();
  const { isFeatureEnabled } = useFeatureToggle();
  const isResumeParsingFeatureEnabled = isFeatureEnabled(Features.RESUME_CANDIDATE_DATA_PARSING);
  const { resumeParsed, onParsed, onNoResume, parsingEnabled } = props;
  const { addMessage } = useMessageService();

  const value: any = getValue();
  let artifactUuid: string = '';
  if (value != null && value.length > 0) {
    [artifactUuid] = value;
  }

  useEffect(() => {
    onNoResume();
    // setSubmitEnabled(false);
    if (isResumeParsingFeatureEnabled && artifactUuid && parsingEnabled) {
      parseCandidateDataFromResume(artifactUuid)
        .then(values => {
          const { name, email, phone } = values;
          const [firstName, lastName] = name != null ? name.split(' ') : [null, null];
          const candidateData = formData?.candidate;
          const updatedCandidateData = {
            ...candidateData,
            name: {
              firstName: firstName == null ? candidateData?.name?.firstName : firstName,
              lastName: lastName == null ? candidateData?.name?.lastName : lastName,
            },
            personalPhoneNumber: phone == null ? candidateData?.personalPhoneNumber : phone,
            personalEmail: email == null ? candidateData?.personalEmail : email,
          };

          const updatedData = {
            ...formData,
            candidate: updatedCandidateData,
          };

          setFormData(updatedData);
          onParsed();
          setSubmitEnabled(true);
        })
        .catch(() => {
          addMessage(new ErrorMessage('Resume has not been parsed'));
          onParsed();
          setSubmitEnabled(true);
        });
    } else if (artifactUuid) {
      onParsed();
      // setSubmitEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifactUuid, parsingEnabled]);

  return resumeParsed || !artifactUuid ? (
    <CandidateResumeUploaderInput {...props} />
  ) : (
    <Loading hasBackground hasPositionFixed />
  );
};

export default CandidateApplicationResumeUploaderWithParsingInput;
