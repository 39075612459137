/* istanbul ignore file */
import { CreateForm } from 'shared/uibuilder/form';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import { func } from 'prop-types';
import React from 'react';
import useEmployeeFeedbackService from 'erp/employee/Timeline/feedback/employeeFeedbackService';
import FeedbackForm from 'erp/employee/Timeline/feedback/form/FeedbackForm';
import { FEEDBACK_RELATIONSHIP_ALIAS } from 'erp/employee/Timeline/feedback/form/FeedbackRelationshipDropdown';
import { FormTemplateProps } from '../../../../../shared/uibuilder/form/FormTemplate';
import { FormFieldsData } from '../../../../../shared/uibuilder/form/FormContext';

interface CreateFeedbackFormProps extends FormTemplateProps {
  onCancel: () => void;
  submitFormFunc: (values: FormFieldsData) => Promise<object | void>;
}

const CreateFeedbackForm = ({ submitFormFunc, onCancel, ...props }: CreateFeedbackFormProps) => {
  const { getValidationSchema } = useEmployeeFeedbackService();
  return (
    <CreateForm
      submitFormFunc={submitFormFunc}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        relationship: FEEDBACK_RELATIONSHIP_ALIAS.NO_RELATION,
      }}
      {...props}
    >
      <CreateTimelineItemLayout onCancel={onCancel}>
        <FeedbackForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

CreateFeedbackForm.propTypes = {
  submitFormFunc: func.isRequired,
  onCancel: func.isRequired,
};

export default CreateFeedbackForm;
