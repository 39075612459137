/* istanbul ignore file */
import { useKernelApi } from 'api';
import accessLogValidation from 'erp/employee/AccessLog/accessLogValidation';
import { useDateTimeService } from 'shared/uibuilder/dateService';

import { useEmployeeId } from 'erp/employee/EmployeesRouter';

export const READ_ACCESS_LOG = 'READ_ACCESS_LOG';
/**
 * Employee access log service.
 */
const useAccessLogService = () => {
  const employeeId = useEmployeeId();
  const getValidationSchema = () => Promise.resolve(accessLogValidation);
  const { sendGetRequest } = useKernelApi();
  const { formatDateForAPI } = useDateTimeService();

  const requestLogCsv = (textDateFrom: any, textDateTo: any) => {
    const apiDateFrom = formatDateForAPI(textDateFrom, 'start');
    const apiDateTo = formatDateForAPI(textDateTo, 'end');
    return sendGetRequest(`/employees/${employeeId}/candidateaccesslog?dateFrom=${apiDateFrom}&dateTo=${apiDateTo}`);
  };

  return {
    getValidationSchema,
    requestLogCsv,
  };
};

export default useAccessLogService;
