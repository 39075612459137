import React from 'react';

const HLetterIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
    <path
      fill="currentColor"
      d="M22 19.5556C22 20.2039 21.7425 20.8256 21.284 21.284C20.8256 21.7425 20.2039 22 19.5556 22H2.44444C1.79614 22 1.17438 21.7425 0.715961 21.284C0.257539 20.8256 0 20.2039 0 19.5556V2.44444C0 1.79614 0.257539 1.17438 0.715961 0.715961C1.17438 0.257539 1.79614 0 2.44444 0L19.5556 0C20.2039 0 20.8256 0.257539 21.284 0.715961C21.7425 1.17438 22 1.79614 22 2.44444V19.5556Z"
    />
    <path
      fill="white"
      d="M15.5831 4.27783C15.1779 4.27783 14.7893 4.43879 14.5028 4.72531C14.2163 5.01182 14.0553 5.40042 14.0553 5.80561V9.16672H7.94423V5.80561C7.94423 5.40042 7.78327 5.01182 7.49675 4.72531C7.21024 4.43879 6.82164 4.27783 6.41645 4.27783C6.01126 4.27783 5.62266 4.43879 5.33615 4.72531C5.04963 5.01182 4.88867 5.40042 4.88867 5.80561V16.1945C4.88867 16.5997 5.04963 16.9883 5.33615 17.2748C5.62266 17.5613 6.01126 17.7223 6.41645 17.7223C6.82164 17.7223 7.21024 17.5613 7.49675 17.2748C7.78327 16.9883 7.94423 16.5997 7.94423 16.1945V12.2223H14.0553V16.1945C14.0553 16.5997 14.2163 16.9883 14.5028 17.2748C14.7893 17.5613 15.1779 17.7223 15.5831 17.7223C15.9883 17.7223 16.3769 17.5613 16.6634 17.2748C16.9499 16.9883 17.1109 16.5997 17.1109 16.1945V5.80561C17.1109 5.40042 16.9499 5.01182 16.6634 4.72531C16.3769 4.43879 15.9883 4.27783 15.5831 4.27783Z"
    />
  </svg>
);

export default HLetterIcon;
