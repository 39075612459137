/* istanbul ignore file */
import { createContext, useContext } from 'react';
import { SensitiveContextType } from './SensitiveShowElementLayout';

const SensitiveDataContext = createContext({});
const SensitiveDataContextProvider = SensitiveDataContext.Provider;

const useSensitiveDataContext = (): SensitiveContextType | Dictionary<any> => {
  const context = useContext(SensitiveDataContext);
  return context || {};
};

export { SensitiveDataContext, useSensitiveDataContext, SensitiveDataContextProvider };
