import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import OneStopPageLayout from 'uibuilder/layout/form/OneStopPageLayout';
import useVacancyGroupService from '../../useVacancyGroupService';
import VacancyForm from '../VacancyForm';
import { useRecruitingDashboardUrl } from '../../../dashboard/RecruitingDashboardRouter';

const OneStopCreateVacancy = () => {
  const { newDashboard } = useRecruitingDashboardUrl();
  const { createVacancy, getCreateValidationSchema } = useVacancyGroupService();

  return (
    <CreateForm
      submitFormFunc={createVacancy}
      afterSubmit={{
        redirect: newDashboard,
        message: 'You are awesome! The Vacancy has been successfully created.',
      }}
      getValidationSchemaFunc={getCreateValidationSchema}
      initialData={{
        isCompetencyLevelRange: false,
        workingConditions: [
          {
            hiringLocation: '',
            employmentTypes: '',
            contractTypes: '',
            isRelocationAvailable: '',
          },
        ],
      }}
    >
      <OneStopPageLayout>
        <VacancyForm />
      </OneStopPageLayout>
    </CreateForm>
  );
};

export default OneStopCreateVacancy;
