import React, { useEffect } from 'react';
import { DefaultInputPropTypes, NumberInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getLastSource } from 'shared/uibuilder/helper';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import { VACANCY_EMPLOYEE_CONTRACT_TYPES_NO_INTERNSHIP } from '../vacancyService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import VacancyOfficeDropdown from 'erp/recruitment/vacancy/shared/input/VacancyOfficeDropdown';

const ExpectedCompensationInput = (props: DefaultInputPropTypes<any>) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const expectedCompensation = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});

  const source = getSource();

  const onChangeCallback = (value: { [x: string]: any }) => {
    rawOnChangeCallback({
      [source]: {
        ...expectedCompensation,
        ...getLastSource(value),
      },
    });
  };

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MediumFormRow>
        <VacancyOfficeDropdown
          source={`${source}.office`}
          value={expectedCompensation.office}
          label="Location (Office)"
          onChangeCallback={onChangeCallback}
        />
        <EnumDropdown
          options={VACANCY_EMPLOYEE_CONTRACT_TYPES_NO_INTERNSHIP}
          source={`${source}.contractType`}
          value={expectedCompensation.contractType}
          label="Contract type"
          placeholder="Select contract type"
          onChangeCallback={onChangeCallback}
        />
        <NumberInput
          source={`${source}.valueFrom`}
          value={expectedCompensation.valueFrom}
          label="Value From"
          onChangeCallback={onChangeCallback}
        />
        <NumberInput
          source={`${source}.valueTo`}
          value={expectedCompensation.valueTo}
          label="Value To"
          onChangeCallback={onChangeCallback}
        />
        <CurrencyDropdown
          source={`${source}.currency`}
          value={expectedCompensation.currency}
          label="Currency"
          onChangeCallback={onChangeCallback}
        />
      </MediumFormRow>
    </>
  );
};

export default ExpectedCompensationInput;
