/* istanbul ignore file */
import React from 'react';
import { Breadcrumbs, ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';

const ServiceCatalogBreadcrumbs = ({ children }: { children: React.ReactNode }) => {
  const { getListUrl } = useServiceCatalogUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Service Catalog" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default ServiceCatalogBreadcrumbs;
