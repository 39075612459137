import { useFinanceApi } from 'api';
import useCrudService from 'shared/crud';
import { useCallback } from 'react';
import sowValidation from './createupdate/sowValidation';
import { useNavigate } from 'react-router-dom';
import { useSowUrl } from './SowRouter';
import { ResourceData } from 'shared/crud/baseCrudService';

export const READ_SOW = 'READ_SOW';
export const WRITE_SOW = 'WRITE_SOW';

export const BILLING_TYPE = {
  RETAINER: 'RETAINER',
  MILESTONES: 'MILESTONES',
  TIME_AND_MATERIALS: 'TIME_AND_MATERIALS',
} as const;

export const BILLING_TYPE_OPTIONS = {
  [BILLING_TYPE.RETAINER]: 'Retainer',
  [BILLING_TYPE.MILESTONES]: 'Milestones',
  [BILLING_TYPE.TIME_AND_MATERIALS]: 'Time & Materials',
};

export const MILESTONES_STATUS = {
  CREATED: 'CREATED',
  ACHIEVED: 'ACHIEVED',
};

export const MILESTONES_STATUS_OPTIONS = {
  [MILESTONES_STATUS.CREATED]: 'Created',
  [MILESTONES_STATUS.ACHIEVED]: 'Achieved',
};

const RESOURCE_URL = '/sow';

type MilestoneType = {
  id: string;
  name: string;
  amount: string;
  completionRate: string;
};

type SowType = {
  id: string;
  projectName: string;
  accountId: string;
  billingType: keyof typeof BILLING_TYPE;
  currency: string;
  endDate: string;
  startDate: string;
  estimatedHours: string;
  milestones: Nullable<MilestoneType[]>;
  totalAmount: string;
};

const useSowService = () => {
  const navigate = useNavigate();
  const { getEmptyEntityUrl } = useSowUrl();
  const { sendPutRequest } = useFinanceApi();

  const {
    create: initialCreate,
    update: initialUpdate,
    getById,
    invalidateCache,
    ...baseCrudRequests
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: '/sow',
    apiService: useFinanceApi,
  });

  const requestDataMapper = (requestData: SowType) => {
    return {
      ...requestData,
      milestones: requestData.milestones || [],
    };
  };

  const update = async (requestId: StringOrNumber, data: ResourceData) => {
    return initialUpdate(requestId, requestDataMapper(data));
  };

  const create = async (data: ResourceData) => {
    return initialCreate(requestDataMapper(data));
  };

  const achieveMilestone = async (sowId: StringOrNumber, milestoneId: StringOrNumber) => {
    const result = await sendPutRequest(`${RESOURCE_URL}/${sowId}/milestone/${milestoneId}/achieve`);
    return result.json();
  };

  const getDataMethod = useCallback(
    async (sowId: StringOrNumber) => {
      if (!sowId) {
        navigate(getEmptyEntityUrl(), { replace: true });
        return null;
      }
      try {
        return await getById(sowId);
      } catch (e) {
        navigate(getEmptyEntityUrl(), { replace: true });
        return e;
      }
    },
    [getById, getEmptyEntityUrl, navigate],
  );

  const getValidationSchema = () => Promise.resolve(sowValidation);

  return {
    getDataMethod,
    achieveMilestone,
    update,
    create,
    invalidateCache,
    getValidationSchema,
    ...baseCrudRequests,
  };
};

export default useSowService;
