/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { DateInput, NumberInput, TextInput } from 'shared/uibuilder/form/input';
import EmployeeContractPositionDropdown from 'erp/employee/contracts/shared/input/EmployeeContractPositionDropdown';
import EmployeeContractAssignmentTypeRadios, {
  EMPLOYEE_CONTRACT_ASSIGMENT_ALIAS,
} from 'erp/employee/contracts/shared/input/EmployeeContractAssignmentTypeRadios';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import BooleanRadiosGroup from 'shared/uibuilder/form/input/BooleanRadiosGroup';
import ContractScanUploaderInput from 'artifact/shared/input/ContractScanUploaderInput';
import DurationBetweenTwoDatesInput from 'shared/uibuilder/form/input/DurationBetweenTwoDatesInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import useAuthorization from 'shared/authorization/authorizationService';
import { SET_PROBATION_END_DATE } from 'erp/employee/contracts/shared/employeeContractsService';
import EmployeeContractTypeDropdown, {
  EMPLOYEE_CONTRACT_TYPES_ALIASES,
} from 'erp/employee/contracts/shared/input/EmployeeContractTypeDropdown';
import OfficeDropdown from 'erp/employee/shared/input/OfficeDropdown';
import { isPolishOffice } from 'erp/employee/office/officeService';
import PaidLeaveBalanceDropdown from '../shared/input/PaidLeaveBalanceDropdown';
import { useDateService } from 'shared/uibuilder/dateService';
import { getPreviousContract } from '../../../contracts/shared/contractsService';
import useGetAllContracts from './useGetAllContracts';
import useFeatureToggle, { Features } from 'featuretoggle';
import { MomentInput } from 'moment';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

const isNotPolishOfficeForm = (formData: FormFieldsData) => {
  return !isPolishOffice(String(formData.officeId));
};

export const isPolishOfficeForm = (formData: FormFieldsData) => {
  return isPolishOffice(String(formData.officeId));
};

const isPolishOfficeAndEmployeeContractType = (formData: FormFieldsData) => {
  return isPolishOffice(String(formData.officeId)) && formData.type === EMPLOYEE_CONTRACT_TYPES_ALIASES.EC;
};

const isAllowedIndefiniteContract = (formData: FormFieldsData) => {
  return (
    isPolishOffice(String(formData.officeId)) &&
    formData.type !== EMPLOYEE_CONTRACT_TYPES_ALIASES.CFS &&
    formData.type !== EMPLOYEE_CONTRACT_TYPES_ALIASES.INTERNSHIP
  );
};

const showPaidLeaveBalanceFieldForEmployeeContract = (formData: { officeId: any; type: string }) => {
  return isPolishOffice(String(formData.officeId)) && formData.type === EMPLOYEE_CONTRACT_TYPES_ALIASES.EC;
};

const showPaidLeaveBalanceFieldForB2BContract = (formData: { officeId: any; type: string }) => {
  return isPolishOffice(String(formData.officeId)) && formData.type === EMPLOYEE_CONTRACT_TYPES_ALIASES.B2B;
};

const showPreviousPaidLeaveBalanceField = (
  currentContract: {
    startDate?: any;
    officeId?: any;
    type?: string;
  },
  contracts: any,
  getDaysBetween: {
    (dateTo: MomentInput, dateFrom: MomentInput, format?: string): number;
    (arg0: any, arg1: any): number;
  },
) => {
  const previousContract = getPreviousContract(contracts, currentContract);
  const hasContract = previousContract != null;
  const isContractHasPolishOfficeAndECType = hasContract && isPolishOfficeAndEmployeeContractType(previousContract);
  const isFirstPolishECContract =
    !isContractHasPolishOfficeAndECType && isPolishOfficeAndEmployeeContractType(currentContract);
  const isNewPolishECContractAndNotExtension =
    isContractHasPolishOfficeAndECType &&
    isPolishOfficeAndEmployeeContractType(currentContract) &&
    getDaysBetween(currentContract.startDate, previousContract.endDate) > 1;

  return isFirstPolishECContract || isNewPolishECContractAndNotExtension;
};

type EmployeeContractFormProps = {
  hasAnchorLinks?: boolean;
  isCreateForm?: boolean;
  employeeAlias: string;
};

const EmployeeContractForm = ({
  hasAnchorLinks = false,
  isCreateForm = false,
  employeeAlias,
}: EmployeeContractFormProps) => {
  const { isGranted } = useAuthorization();
  const isSettingProbationEndDateDisabled = isCreateForm || !isGranted(SET_PROBATION_END_DATE);
  const contracts = useGetAllContracts(employeeAlias);
  const { getDaysBetween } = useDateService();
  const twoContractEnabled = useFeatureToggle().isFeatureEnabled(Features.TWO_CONTRACTS);

  return (
    <FormSection title="Contract information" hasAnchorLink={hasAnchorLinks}>
      <FormRow>
        <BooleanRadiosGroup source="secondary" label="Is Secondary Contract?" isVisible={twoContractEnabled} />
      </FormRow>
      <FormRow>
        <OfficeDropdown source="officeId" label="Registered Office" errorSource="office" />
        <EmployeeContractTypeDropdown source="type" label="Contract Type" />
        <TextInput source="number" label="Contract Number" isVisible={isNotPolishOfficeForm} />
        <DateInput source="dateOfSignature" label="Date of Signature" />
        <DateInput source="startDate" label="Contract Start Date" />
        <BooleanRadiosGroup
          source="indefinite"
          label="Is it a contract for an indefinite period?"
          isVisible={isAllowedIndefiniteContract}
        />
        <ClearAfterUnmountWrapper isVisible={contract => !contract.indefinite}>
          <DateInput source="endDate" label="Contract End Date" />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={contract => !contract.indefinite}>
          <DurationBetweenTwoDatesInput
            dateFromSource="startDate"
            dateToSource="endDate"
            label="Contract Duration"
            source=""
          />
        </ClearAfterUnmountWrapper>
        <EmployeeContractPositionDropdown source="position" label="Contract Position" />
        <ClearAfterUnmountWrapper isVisible={showPaidLeaveBalanceFieldForB2BContract}>
          <NumberInput source="paidLeaveBalance" label="Paid Leave Balance" />
        </ClearAfterUnmountWrapper>
      </FormRow>
      <FormRow>
        <ClearAfterUnmountWrapper
          isVisible={currentContract => showPreviousPaidLeaveBalanceField(currentContract, contracts, getDaysBetween)}
        >
          <NumberInput
            source="previousPaidLeaveBalance"
            label="Start Paid Leave Balance"
            helpText="Paid Leave Balance for the first calendar year"
            isRequired
          />
        </ClearAfterUnmountWrapper>
        <ClearAfterUnmountWrapper isVisible={showPaidLeaveBalanceFieldForEmployeeContract}>
          <PaidLeaveBalanceDropdown
            source="paidLeaveBalance"
            label="Annual Paid Leave Balance"
            helpText="Norm of paid leave balance according to work experience"
          />
        </ClearAfterUnmountWrapper>
      </FormRow>
      <FormRow>
        <EmployeeContractAssignmentTypeRadios source="assignmentType" label="Assignment Type" />
        <ClearAfterUnmountWrapper
          isVisible={contract => contract.assignmentType === EMPLOYEE_CONTRACT_ASSIGMENT_ALIAS.PART_TIME}
        >
          <NumberInput source="workRate" label="Work Rate, %" />
        </ClearAfterUnmountWrapper>
      </FormRow>
      <FormRow>
        <BooleanRadiosGroup
          source="hasProbationPeriod"
          label="Does Contract Have Probation Period?"
          isVisible={contract =>
            contract.type !== EMPLOYEE_CONTRACT_TYPES_ALIASES.INTERNSHIP &&
            contract.type !== EMPLOYEE_CONTRACT_TYPES_ALIASES.CFS
          }
        />
        <ClearAfterUnmountWrapper
          isVisible={contract =>
            contract.hasProbationPeriod &&
            contract.type !== EMPLOYEE_CONTRACT_TYPES_ALIASES.INTERNSHIP &&
            contract.type !== EMPLOYEE_CONTRACT_TYPES_ALIASES.CFS
          }
        >
          <DateInput
            disabled={isSettingProbationEndDateDisabled}
            source="probationPeriodEndedAt"
            label="Probation Period End Date"
            helpText={
              isSettingProbationEndDateDisabled
                ? 'The field will be auto-populated by DaVinci when probation finishes'
                : null
            }
          />
        </ClearAfterUnmountWrapper>
      </FormRow>
      <BigFormRow>
        <ContractScanUploaderInput label="Contract Scans" source="scansIds" />
      </BigFormRow>
    </FormSection>
  );
};

export default EmployeeContractForm;
