// libs
import React from 'react';
// services
import useArtifactService from 'artifact/artifactService';
// constants
import { API_FILE_TYPE, CROP_SIZES } from 'artifact/const';
import useAsyncValue from 'shared/asyncHelper';
import InputLoader from 'shared/uibuilder/InputLoader';
import CloseIcon from '@mui/icons-material/CloseOutlined';
// styles
import './FilePreviewLayout.scss';

const isImage = (artifact: Dictionary<any>) => artifact && artifact.type && artifact.type === API_FILE_TYPE.image.alias;

const getImageThumbnail = async (
  artifact: Dictionary<any>,
  getThumbnail: { (id: any, cropSize: any): Promise<any>; (arg0: any, arg1: string): any },
) => {
  if (artifact.filePreview) {
    return artifact.filePreview;
  } else if (artifact.thumbnails.local) {
    return artifact.thumbnails.local;
  } else {
    const thumbnail = await getThumbnail(artifact.id, CROP_SIZES.SIZE_100);
    return thumbnail;
  }
};

const getFilePreview = async (
  artifact: Dictionary<any>,
  getArtifactIcon: { (artifact: any): any; (arg0: any): any },
) => {
  return getArtifactIcon(artifact);
};

type FilePreviewLayoutProps = {
  artifactObject: Dictionary<any>;
  onRemove: () => void;
  children?: Nullable<React.ReactElement>;
  disabled?: boolean;
};

/**
 * Renders file preview. This component decide which icon to select
 * @param artifactObject - artifact's object
 * @param onRemove - onRemove callback. It'll be called when user click 'x' button near the preview
 * @param children
 * @param disabled
 * @constructor
 */
const FilePreviewLayout: React.FC<FilePreviewLayoutProps> = ({
  artifactObject,
  onRemove,
  children = null,
  disabled = false,
}) => {
  const { getArtifactIcon, getThumbnail } = useArtifactService();

  const getSrc = async () => {
    let src;

    if (isImage(artifactObject)) {
      src = await getImageThumbnail(artifactObject, getThumbnail);
    } else {
      src = await getFilePreview(artifactObject, getArtifactIcon);
    }

    return src;
  };

  const src = useAsyncValue(getSrc);

  const buttonClassName = !disabled ? 'photo-preview__btn' : 'photo-preview__btn-disabled';

  return (
    <div
      className={`
        photo-preview
        ${artifactObject.invalid ? 'photo-preview--invalid' : ''}
        ${artifactObject.uploading ? 'photo-preview--uploading' : ''}
      `}
    >
      {src ? (
        <img src={src} alt={artifactObject.name} title={artifactObject.title || artifactObject.name} />
      ) : (
        <InputLoader />
      )}
      <button
        type="button"
        disabled={disabled}
        className={buttonClassName}
        aria-label="Remove artifact"
        onClick={onRemove}
      >
        <CloseIcon sx={{ fontSize: '14px' }} />
      </button>
      {children}
    </div>
  );
};

export default FilePreviewLayout;
