/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useAccountUrl } from 'crm/account/index';

const EditAccountButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useAccountUrl();

  return (
    <EditButton path={(id: any) => getUpdateEntityUrl(id)} {...props}>
      Edit
    </EditButton>
  );
};

export default EditAccountButton;
