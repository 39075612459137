/* istanbul ignore file */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AppSidebarNav from 'shared/layout/AppSidebarNav';
import useSidebarNav from 'shared/uibuilder/layout/useSidebarNav';

const SidebarNav = ({ ...rest }) => {
  const { pathname } = useLocation();
  const {
    getIsActiveLink,
    updateOpenMenuList,
    getNavigationItems,
    addActiveItemToOpenMenuList,
    getHeaderMenuData,
    getSidebarItems,
  } = useSidebarNav();
  const items = getNavigationItems();

  useEffect(() => {
    addActiveItemToOpenMenuList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppSidebarNav
      // @ts-ignore
      navConfig={{
        items,
        sidebarItems: getSidebarItems(items),
        headerMenuData: getHeaderMenuData(items),
        clickHandler: updateOpenMenuList,
      }}
      getIsActiveLink={getIsActiveLink}
      location={{ pathname }}
      {...rest}
    />
  );
};

export default SidebarNav;
