/* istanbul ignore file */
import React from 'react';
import './PassportNumberLayout.scss';

const PassportNumberLayout = ({
  passportNumberInput,
  passportSeriesInput,
}: {
  passportNumberInput: React.ReactElement;
  passportSeriesInput: React.ReactElement;
}) => {
  return (
    <>
      <div className="passport-series">{passportSeriesInput}</div>
      <div className="passport-number">{passportNumberInput}</div>
    </>
  );
};

export default PassportNumberLayout;
