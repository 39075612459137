/* istanbul ignore file */
import Condition, { ConditionProps } from 'shared/uibuilder/list/filter/condition/Condition';
import React from 'react';
import { node } from 'prop-types';
import { FilterCondition } from '../FilterContext';

const CtCondition = ({ children, ...conditionProps }: ConditionProps) => {
  return (
    <Condition {...conditionProps} conditionType={FilterCondition.CT}>
      {children}
    </Condition>
  );
};

CtCondition.propTypes = {
  children: node.isRequired,
};

export default CtCondition;
