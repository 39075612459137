import useCandidateService, { CandidateStatus } from 'erp/candidate/shared/candidateService';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateCandidateApplicationBreadcrumbs from 'erp/candidate/applications/createupdate/Create/Breadcrumbs';
import CandidateApplicationFromJobBoardForm from 'erp/candidate/applications/createupdate/CandidateApplicationFromJobBoardForm';
import React, { useState } from 'react';
import useCandidateApplicationService, {
  CandidateApplicationSource,
  CandidateApplicationSourceType,
  CandidateApplicationStatus,
  CreateCandidateApplicationOptions,
} from 'erp/candidate/applications/shared/candidateApplicationService';
import useMessageService, { ErrorMessage, SuccessMessage } from 'shared/message/messageService';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { ResumeType } from 'erp/candidate/Resume/shared/resumeService';

const getApplicationCreatedMessage = (isUpdate: boolean, applicationsUrl: string) =>
  `<div>You are awesome! The Candidate ${isUpdate ? 'Timeline has been successfully updated.' : 'Application has been successfully created.'}<br>
    You can check in<a href="${applicationsUrl}" target="_blank" rel="noopener noreferrer" > the candidate profile </a></div>`;

const CreateCandidateApplicationFromJobBoardPage = ({ showBreadcrumbs }: { showBreadcrumbs?: boolean }) => {
  const { create: createApplication, getValidationSchema } = useCandidateApplicationService();
  const { createCandidateAndLeftNote } = useCandidateService();
  const { addMessage } = useMessageService();
  const { getApplicationsUrl, getTimelineUrl } = useCandidateUrl();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [createApplicationOption, setCreateApplicationOption] = useState(
    CreateCandidateApplicationOptions.CREATE_IN_SOURCE_STEP,
  );

  const shouldCreateApplication = createApplicationOption !== CreateCandidateApplicationOptions.DO_NOT_CREATE;

  const initialData = {
    source: CandidateApplicationSource.JOB_BOARD,
    sourceType: CandidateApplicationSourceType.PASSIVE,
    createNewCandidate: true,
    createApplicationOption: CreateCandidateApplicationOptions.CREATE_IN_SOURCE_STEP,
    status: CandidateApplicationStatus.IN_PROCESSING,
  };
  const resumeInitialData = {
    resumeArtifactIds: [],
    resumeType: ResumeType.APPLICATION_RESUME,
  };
  const candidateInitialData = {
    additionalContacts: [{ type: 'LinkedIn' }],
    status: CandidateStatus.DEFAULT,
  };

  return (
    <CreateForm
      submitFormFunc={data =>
        shouldCreateApplication
          ? createApplication(data)
          : createCandidateAndLeftNote({
              ...data.candidate,
              resume: { ...data.resume, resumeType: ResumeType.STAND_ALONE },
              mainData: data,
              timelineNote: {
                attachmentsIds: data.note?.attachmentsIds,
                noteText: data.note?.noteText,
              },
            })
      }
      initialData={{
        candidate: {
          ...candidateInitialData,
        },
        ...initialData,
        resume: {
          ...resumeInitialData,
        },
        note: {
          attachmentsIds: [],
        },
      }}
      afterSubmit={{
        execute: data => {
          const { isUpdate } = data;
          const applicationsUrl = isUpdate
            ? getTimelineUrl(data.candidateId)
            : getApplicationsUrl(shouldCreateApplication ? data.candidate.id : data.id);
          addMessage(new SuccessMessage(getApplicationCreatedMessage(isUpdate, applicationsUrl)));
          setIsSubmitted(true);
          setCreateApplicationOption(CreateCandidateApplicationOptions.CREATE_IN_SOURCE_STEP);
          window.scrollTo(0, 0);
        },
        resetData: shouldCreateApplication
          ? data => ({
              candidate: {
                ...candidateInitialData,
                residenceCountry: data.candidate.residenceCountry,
              },
              source: data.source,
              sourceType: data.sourceType,
              sourceComment: data.sourceComment,
              vacancyAlias: data.vacancy.alias,
              createNewCandidate: true,
              createApplicationOption: CreateCandidateApplicationOptions.CREATE_IN_SOURCE_STEP,
              status: CandidateApplicationStatus.IN_PROCESSING,
              note: {
                noteText: '',
              },
              resume: {
                ...resumeInitialData,
              },
            })
          : () => ({
              candidate: {
                ...candidateInitialData,
              },
              ...initialData,
              resume: {
                ...resumeInitialData,
              },
              note: {
                noteText: '',
                attachmentsIds: [],
              },
              shouldCreateApplication: true,
            }),
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout
        breadcrumbs={showBreadcrumbs ? <CreateCandidateApplicationBreadcrumbs /> : undefined}
        title="Create: Candidate Application"
      >
        <CandidateApplicationFromJobBoardForm
          isCreateForm
          hasAnchorLink
          onDuplicatesFail={() => addMessage(new ErrorMessage("Oops! We can't check duplicates. Please, try again."))}
          isSubmitted={isSubmitted}
          createApplicationOption={createApplicationOption}
          clearIsSubmitted={() => setIsSubmitted(false)}
          setCreateApplicationOption={setCreateApplicationOption}
        />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateCandidateApplicationFromJobBoardPage;
