import React, { useCallback, useEffect, useState } from 'react';
import { ChangeEvent, OnSuggestionSelected, SuggestionSelectedEventData } from 'react-autosuggest';
import { debounce } from 'throttle-debounce';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import {
  DefaultInputLayoutProps,
  InputLayoutHintProps,
  InputLayoutStateProps,
  ValidationLayoutProps,
} from 'shared/uibuilder/form/input';

const DEBOUNCE_THRESHOLD = 500;
const DEFAULT_NUMBER_RESULTS_PER_PAGE = 30;

export type Suggestion = {
  id: number | string;
  text: string;
};

interface AutosuggestDropdownProps {
  source: string;
  loadSuggestionsMethod: (searchValue: string) => ResultResourceData;
  mapResults: (data: any) => Suggestion;
  noResultMessage?: string;
  resultsPerPage?: number;
  initialValue?: string;
  isLoading?: boolean;
  valueDecorator?: (searchValue: string) => string;
  onInputChangeCallback?: (resultValue: string) => void;
  buildAutoSuggestLabel?: (info: Suggestion) => string;
  searchRequest: (object: object) => ResultResourceData;
}

export interface AutosuggestionDropdownLayoutProps
  extends DefaultInputLayoutProps,
    InputLayoutHintProps,
    InputLayoutStateProps,
    ValidationLayoutProps {
  suggestions: Suggestion[];
  suggestionsLoaded: boolean;
  suggestionsTotalPages: number;
  onSuggestionsFetchRequested: ({ value }: { value: string }) => void;
  onSuggestionsClearRequested: () => void;
  onSuggestionSelected: OnSuggestionSelected<Suggestion>;
  onChangeCallback: (event: React.FormEvent<HTMLElement>, { newValue }: ChangeEvent) => void;
  onBlurCallback?: () => void;
  onFocusCallback?: () => void;
  noResultMessage: string;
  resultsPerPage: number;
  loading: boolean;
}

export type AutosuggestionDropdownLayoutType = ReactComponent<AutosuggestionDropdownLayoutProps>;

const AutosuggestDropdown: React.FC<AutosuggestDropdownProps> = ({
  loadSuggestionsMethod,
  buildAutoSuggestLabel,
  noResultMessage = '',
  resultsPerPage = DEFAULT_NUMBER_RESULTS_PER_PAGE,
  initialValue = '',
  mapResults,
  isLoading = false,
  searchRequest,
  onInputChangeCallback,
  valueDecorator,
  ...props
}: AutosuggestDropdownProps) => {
  const { AutosuggestionDropdownLayout } = useUiTheme<AutosuggestionDropdownLayoutType>();
  const inputHelper = useInputHelper(props);
  const source = inputHelper.getSource();
  const onChangeCallback = inputHelper.getRawOnChangeCallback();
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [suggestionsTotalPages, setSuggestionsTotalPages] = useState(0);
  const [suggestionsLoaded, setSuggestionsLoaded] = useState(false);
  const [loading, setLoading] = useState(isLoading);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    (async () => {
      if (initialValue && buildAutoSuggestLabel) {
        setLoading(true);

        const filter = {
          filter: {
            id: {
              eq: initialValue,
            },
          },
        };
        const { result = [] } = await searchRequest(filter);
        const info = result.find(({ id }: Suggestion) => id === Number(initialValue));

        if (info) {
          setSelectedValue(buildAutoSuggestLabel(info));
        }
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultHandleInputChange = () => {
    if (onChangeCallback) {
      onChangeCallback({ [source]: null });
    }
  };
  const handleInputChange = onInputChangeCallback || defaultHandleInputChange;

  const loadSuggestions = async (searchValue: string) => {
    const resultSearchValue = valueDecorator ? valueDecorator(searchValue) : searchValue;

    setLoading(true);
    try {
      const { result, totalPages } = (await loadSuggestionsMethod(resultSearchValue)) as {
        result: any;
        totalPages: number;
      };

      setSuggestions(result.map(mapResults));
      setSuggestionsTotalPages(totalPages);
      setLoading(false);
      setSuggestionsLoaded(true);
    } finally {
      setLoading(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceMethod = useCallback(debounce(DEBOUNCE_THRESHOLD, loadSuggestions), []);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    setSuggestionsLoaded(false);
  };

  const onSuggestionsFetchRequested = ({ value: searchValue }: { value: string }) => {
    debounceMethod(searchValue);
  };

  const onChange = (event: React.FormEvent<HTMLElement>, { newValue }: ChangeEvent) => {
    const resultValue = valueDecorator ? valueDecorator(newValue) : newValue;

    setSelectedValue(resultValue);
    handleInputChange(resultValue);

    return resultValue;
  };

  const onSuggestionSelected = (
    event: React.FormEvent<any>,
    { suggestion }: SuggestionSelectedEventData<Suggestion>,
  ) => {
    const { text, id, ...otherFields } = suggestion;
    setSelectedValue(text);

    if (onChangeCallback) {
      onChangeCallback({
        [source]: id,
        ...otherFields,
      });
    }
  };

  return (
    <AutosuggestionDropdownLayout
      {...props}
      suggestions={suggestions}
      suggestionsLoaded={suggestionsLoaded}
      suggestionsTotalPages={suggestionsTotalPages}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      source={source}
      value={selectedValue}
      onChangeCallback={onChange}
      loading={loading}
      isRequired={inputHelper.getIsRequired()}
      isVisible={inputHelper.isVisible()}
      errorMessages={inputHelper.getErrorMessages()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      noResultMessage={noResultMessage}
      resultsPerPage={resultsPerPage}
      disabled={inputHelper.getIsDisabled()}
    />
  );
};

export default AutosuggestDropdown;
