/* istanbul ignore file */
import { Navigate } from 'react-router-dom';
import { useProcessInstanceId, useProcessInstanceUrl } from 'camunda/monitoring/instance/ProcessInstanceRouter';
import React from 'react';

const ProcessInstanceRedirect = () => {
  const id = useProcessInstanceId();
  const { getHistoryUrl } = useProcessInstanceUrl();
  return <Navigate to={getHistoryUrl(id)} replace />;
};

export default ProcessInstanceRedirect;
