import React, { CSSProperties, useState } from 'react';
import Grid from 'uibuilder/Grid';
import Typography from 'uibuilder/Typography';
import { getVisibility } from 'shared/uibuilder/helper';
import { useFormContext, FormFieldsData } from 'shared/uibuilder/form/FormContext';
import ScrollToSectionAnchor from 'shared/uibuilder/Anchor/ScrollToSectionAnchor';
import classnames from 'classnames';
import './FormSection.scss';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';
import { TooltipSize } from 'uibuilder/Tooltip';
import Box from 'uibuilder/Box';
import IconButton from 'uibuilder/button/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Иконка стрелки

interface FormSectionProps {
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  title?: string;
  subtitle?: string;
  hasEqualPadding?: boolean;
  hasAnchorLink?: boolean;
  titleStyle?: CSSProperties;
  subtitleClass?: string;
  tooltipMessage?: string | React.ReactElement;
  tooltipSource?: string;
  isLargeTooltip?: boolean;
  titleVariant?: 'h4' | 'h5' | 'h6';
  actions?: React.ReactNode;
  isExpandedSection?: boolean;
  defaultExpanded?: boolean;
}

const FormSection = ({
  children,
  title = '',
  subtitle,
  isVisible = true,
  hasAnchorLink = false,
  hasEqualPadding = false,
  titleStyle,
  subtitleClass,
  tooltipMessage,
  tooltipSource,
  isLargeTooltip = false,
  titleVariant = 'h4',
  actions,
  isExpandedSection = false,
  defaultExpanded = false, // По умолчанию секция раскрыта
}: FormSectionProps) => {
  const { data } = useFormContext();
  const [isOpen, setIsOpen] = useState(defaultExpanded); // Локальное состояние для управления раскрытием

  const isSectionsVisible = getVisibility(data, isVisible as any);

  const handleToggle = () => {
    setIsOpen(!isOpen); // Переключаем состояние при клике
  };

  return (
    isSectionsVisible && (
      <fieldset
        className={classnames('long-form-section', {
          'equal-padding': hasEqualPadding,
        })}
      >
        <Grid container>
          <Grid item xs={12}>
            {(!!title || hasAnchorLink) && (
              <legend className="section-title" style={titleStyle}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Typography variant={titleVariant} display="flex">
                      {title}
                      <ScrollToSectionAnchor titleId={title} isLinkDiplayed={hasAnchorLink} />
                    </Typography>
                    {tooltipMessage && (
                      <InputTooltip
                        source={tooltipSource || ''}
                        message={tooltipMessage}
                        tooltipSize={isLargeTooltip ? TooltipSize.BIG : TooltipSize.DEFAULT}
                        sx={{ marginLeft: '5px', fontSize: '20px', transform: 'translateY(3px)' }}
                      />
                    )}

                    {actions && (
                      <Box ml="10px" display="flex" alignItems="center">
                        {actions}
                      </Box>
                    )}
                  </Box>

                  {isExpandedSection && (
                    <Box display="flex" alignItems="center">
                      <IconButton onClick={handleToggle}>
                        <ExpandMoreIcon
                          className={classnames('toggle-icon', {
                            open: isOpen, // Если открыто, стрелка разворачивается
                          })}
                          style={{
                            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s',
                          }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </legend>
            )}
            {subtitle && (
              <p>
                <i className={classnames('subtitle', subtitleClass)}>{subtitle}</i>
              </p>
            )}
          </Grid>
        </Grid>

        <div
          className="section-content"
          style={{
            display: (isOpen && isExpandedSection) || !isExpandedSection ? 'block' : 'none',
          }}
        >
          {children}
        </div>
      </fieldset>
    )
  );
};

export default FormSection;
