/* istanbul ignore file */
import React from 'react';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useEducationService from 'erp/employee/Education/shared/educationService';
import { useEducationUrl, useEducationId } from 'erp/employee/Education/EducationRouter';
import { ButtonProps } from 'uibuilder/button/Button';

const deleteMessage = 'Do you really want to delete this education record?';

const DeleteEducationButton = (props: ButtonProps) => {
  const educationId = useEducationId();
  const { deleteById } = useEducationService();

  const { getListUrl } = useEducationUrl();

  return (
    <DeleteButton
      {...props}
      deleteMethod={() => deleteById(educationId)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Education has been successfully deleted.',
        errorMessage: `Can't delete education`,
      }}
    />
  );
};

export default DeleteEducationButton;
