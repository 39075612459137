/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import LeadStatusDropdown from 'crm/lead/shared/input/LeadStatusDropdown';
import { DefaultDropdownProps } from '../../../../shared/uibuilder/form/input/dropdownHelper';

const LeadStatusFilter = (props: Omit<DefaultDropdownProps, 'options'>) => {
  return (
    <DropDownFilter {...props}>
      <LeadStatusDropdown {...props} />
    </DropDownFilter>
  );
};

export default LeadStatusFilter;
