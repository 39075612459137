/* istanbul ignore file */
import React from 'react';
import SingleServiceCatalogBreadcrumbs
  from 'erp/serviceCatalog/shared/SingleServiceCatalogBreadcrumbs';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';
import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';

const ViewServiceBudgetStructureBreadcrumbs = ({ children } : { children? : React.ReactElement | React.ReactElement[] }) => {
  const id = useServiceCatalogId();
  const { getBudgetStructuresUrl } = useServiceCatalogUrl();

  return (
    <SingleServiceCatalogBreadcrumbs>
      <SingleBreadcrumbItem entity='Budgets Structure' link={getBudgetStructuresUrl(id)} />
      {children}
    </SingleServiceCatalogBreadcrumbs>
  );
};

export default ViewServiceBudgetStructureBreadcrumbs;
