import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import SummaryMenuItem from 'uibuilder/menuitem/SummaryMenuItem';
import React from 'react';
import SidebarMenuItem from 'shared/uibuilder/menu/SidebarMenuItem';
import useFeatureToggle, { Features } from 'featuretoggle';
import useAuthorization from 'shared/authorization/authorizationService';
import { KPI_STRUCTURE_READ, KPI_VALUES_READ_LIST } from '../kpi/useKPIService';

const ViewServiceCatalogPageMenu = () => {
  const id = useServiceCatalogId();
  const { isFeatureEnabled } = useFeatureToggle();
  const { isGranted } = useAuthorization();

  const { getSingleEntityUrl, getBudgetsUrl, getBudgetStructuresUrl, getKPIStructuresUrl, getKPIValuesUrl } =
    useServiceCatalogUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem exact linkUrl={getSingleEntityUrl(id)} />
      {isFeatureEnabled(Features.BUDGETING) ? (
        <>
          <SidebarMenuItem exact icon="money" linkUrl={getBudgetsUrl(id)} name="Budgets" />
          <SidebarMenuItem exact icon="list" linkUrl={getBudgetStructuresUrl(id)} name="Budgets Structure" />
        </>
      ) : (
        <></>
      )}
      {isGranted(KPI_STRUCTURE_READ) ? (
        <SidebarMenuItem exact icon="check" linkUrl={getKPIStructuresUrl(id)} name="KPI Structure" />
      ) : (
        <></>
      )}
      {isFeatureEnabled(Features.PUBLISH_KPI) && isGranted(KPI_VALUES_READ_LIST) ? (
        <SidebarMenuItem exact icon="tasks" linkUrl={getKPIValuesUrl(id)} name="KPI Values" />
      ) : (
        <></>
      )}
    </SidebarStickyMenu>
  );
};

export default ViewServiceCatalogPageMenu;
