/* istanbul ignore file */
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Show from 'shared/uibuilder/show/Show';
import StatusesBreadcrumbs from 'erp/project/statuses/statusList/StatusesBreadcrumbs';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import useProjectDemoStatusRegistry from '../demo/projectDemoRegistry';
import useProjectStatusesService from 'erp/project/statuses/ProjectStatusesService';
import ProjectEntityHeader from 'erp/project/shared/ProjectEntityHeader';
import ViewProjectPageMenu from 'erp/project/Show/ViewProjectPageMenu';
import useProjectService from 'erp/project/shared/projectService';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import ProjectStatusTypeFilter from '../shared/filter/ProjectStatusTypeFilter';
import useProjectPlanningStatusRegistry from '../planning/projectPlanningRegistry';
import { useProjectRoute } from 'erp/project/ProjectRouter';
import { TimelineRegistry } from '../../../../shared/uibuilder/timeline/timelineRegistryHelper';

const ProjectStatusesList = () => {
  const oldHashStatus = '#status';
  const { id: projectId = '' } = useParams<Dictionary<string>>();
  const navigate = useNavigate();
  const location = useLocation();
  const { getSingleProjectStatusUrl } = useProjectRoute();
  const { getById } = useProjectService();
  const { search } = useProjectStatusesService();
  const statusDemoRegistry = useProjectDemoStatusRegistry();
  const statusPlanningRegistry = useProjectPlanningStatusRegistry();

  useEffect(() => {
    const { hash } = location;
    const statusId = hash && hash.includes(oldHashStatus) && hash.replace(oldHashStatus, '');

    if (statusId) {
      navigate(getSingleProjectStatusUrl(projectId, statusId), { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filters = (
    <Filters>
      <FiltersLayout>
        <ProjectStatusTypeFilter label="Status Type" source="projectStatusType" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <Show getDataMethod={() => getById(projectId)}>
      <Timeline
        registries={[statusDemoRegistry, statusPlanningRegistry] as unknown as TimelineRegistry[]}
        getDataMethod={search}
      >
        <TimelinePageLayout
          menu={<ViewProjectPageMenu />}
          breadcrumbs={<StatusesBreadcrumbs />}
          entityHeader={<ProjectEntityHeader />}
          title="Project Status"
          hasAddForm={false}
          filter={filters}
        >
          <TimelineLayout noEntriesMessage="No project status yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default ProjectStatusesList;
