/* istanbul ignore file */

import ListPageLayout from 'shared/uibuilder/list/layout/ReportPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import React from 'react';

import useWorkingHoursService from 'erp/workinghours/shared/workingHoursService';
import FormTemplate from 'shared/uibuilder/form/FormTemplate';
import FormRow from 'shared/layout/form/FormRow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import SubmitFormButton from 'erp/employee/AccessLog/SubmitFormButton';
import workingHoursFiltersValidation from 'erp/workinghours/List/workingHoursFiltersValidation';
import QuickFilters from 'erp/workinghours/List/filter/QuickFiltersLayout';
import PreviousAndCurrentMonthsQuickFilter from 'erp/workinghours/List/filter/PreviousAndCurrentMonthsQuickFilter';
import { downloadFile } from 'shared/fileHelper';
import CurrentMonthQuickFilter from 'erp/workinghours/List/filter/CurrentMonthQuickFilter';
import PreviousMonthQuickFilter from 'erp/workinghours/List/filter/PreviousMonthQuickFilter';
import LoadingFormLayout from 'uibuilder/layout/form/input/LoadingFormLayout';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';

const WorkingHoursList = () => {
  const { getReport } = useWorkingHoursService();

  return (
    <>
      <ListPageLayout header={<ListHeader label="Working Hours" icon="fa-clock-o" />}>
        <FormTemplate
          submitFormFunc={data => getReport(data as any).then(resp => downloadFile(resp))}
          getValidationSchemaFunc={() => Promise.resolve(workingHoursFiltersValidation)}
          handleDirty={false}
        >
          <QuickFilters>
            <PreviousAndCurrentMonthsQuickFilter />
            <CurrentMonthQuickFilter />
            <PreviousMonthQuickFilter />
          </QuickFilters>

          <FormRow colXl={4}>
            <EmployeeInput source="employeeAlias" label="Employee name or ID" withContractors />
          </FormRow>
          <FormRow colXl={2}>
            <DateInput label="Period from" source="dateFrom" />
            <DateInput label="To" source="dateTo" />
          </FormRow>

          <SubmitFormButton sx={{ marginBottom: '10px' }}>Generate</SubmitFormButton>

          <LoadingFormLayout />
        </FormTemplate>
      </ListPageLayout>
    </>
  );
};

export default WorkingHoursList;
