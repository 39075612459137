/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';

import ContractsContext from 'erp/contracts/ContractsContext';

import Page404 from 'shared/uibuilder/Page404';
import ViewPage from 'erp/contracts/Show';
import ListPage from 'erp/contracts/List';

import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { GET_ALL_CONTRACTS_LIST, READ_CONTRACT } from 'erp/contracts/shared/contractsService';

export const CONTRACTS_PATH = '/contracts';

export const useContractsRoute = () => {
  return useRoute({ listRoute: CONTRACTS_PATH });
};

export const useContractsUrl = () => {
  return useEntityUrl({
    baseLink: CONTRACTS_PATH,
  });
};

export const useContractsId = () => useParams<Dictionary<string>>().id!;

const ContractsRouter = () => {
  const { listRoute, singleEntityRoute } = useContractsRoute();

  return (
    <ProtectedRouter basePath={CONTRACTS_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={GET_ALL_CONTRACTS_LIST}>
              <ListPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <ContractsContext permissionToCheck={READ_CONTRACT}>
              <ViewPage />
            </ContractsContext>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

ContractsRouter.getRouterPath = () => `${CONTRACTS_PATH}/*`;

export default ContractsRouter;
