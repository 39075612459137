/* istanbul ignore file */
import React from 'react';
import { LinkField } from 'shared/uibuilder/field';
import useAccountUrl from '../accountUrl';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

interface AccountLinkFieldProps {
  idValue?: string;
  idSource?: string;
  isInternal?: boolean;
  [key: string]: any;
}

const AccountLinkField: React.FC<AccountLinkFieldProps> = ({
  idValue = undefined,
  idSource = undefined,
  isInternal = true,
  ...props
}) => {
  const { getSingleEntityUrl } = useAccountUrl();
  const { getValue: getIdValue } = useFieldHelper({ value: idValue, source: idSource });
  const { getValue: getName } = useFieldHelper(props);

  const id = getIdValue();
  const url = id && getSingleEntityUrl(id);
  const name = getName();

  return <LinkField {...props} linkText={name} value={url} isInternal={isInternal} />;
};

export default AccountLinkField;
