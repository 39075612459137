/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_LEDGER, LEDGER_PATH, READ_LEDGER_LIST } from 'financialAnalytic/ledger/useLedgerService';
import LedgerList from 'financialAnalytic/ledger/List';
import LedgerViewPage from 'financialAnalytic/ledger/Show';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const useLedgerRoute = () => useRoute({ listRoute: LEDGER_PATH });

export const useLedgerUrl = () =>
  useEntityUrl({
    baseLink: LEDGER_PATH,
  });

export const useLedgerId = () => useParams<Dictionary<string>>().id!;

const LedgerRouter = () => {
  const { listRoute, singleEntityRoute } = useLedgerRoute();

  return (
    <ProtectedRouter basePath={LEDGER_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_LEDGER_LIST}>
              <LedgerList />
            </GlobalProtectedRoute>
          }
        />
        <Route
          path={singleEntityRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_LEDGER}>
              <LedgerViewPage />
            </GlobalProtectedRoute>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

LedgerRouter.getRouterPath = () => `${LEDGER_PATH}/*`;

export default LedgerRouter;
