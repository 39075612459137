/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { DAY_TYPES, DAY_MARK } from '../../usePerformanceReportService';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';
import { TooltipSize } from 'uibuilder/Tooltip';

export const DAYS_LEGEND = [
  {
    label: 'Working days',
    values: [
      {
        dateType: DAY_TYPES.WORKING,
      },
    ],
  },
  {
    label: 'Weekends and public holidays',
    values: [
      {
        dateType: DAY_TYPES.HOLIDAY,
      },
    ],
  },
  {
    label: 'Leaves',
    values: [
      {
        dateType: DAY_TYPES.LEAVE,
      },
    ],
  },
];

export const DAY_MARKS_LEGEND = [
  {
    label: 'Expected',
    values: [
      {
        dateType: DAY_TYPES.WORKING,
        state: DAY_MARK.POSITIVE,
      },
    ],
  },
  {
    label: 'Acceptable',
    values: [
      {
        dateType: DAY_TYPES.WORKING,
        state: DAY_MARK.NEUTRAL,
      },
    ],
  },
  {
    label: 'Improvement areas',
    values: [
      {
        dateType: DAY_TYPES.WORKING,
        state: DAY_MARK.NEGATIVE,
      },
    ],
  },
];

const PerformanceHeatmapLegend = ({
  data: blocks = [DAYS_LEGEND, DAY_MARKS_LEGEND],
  rowDirection,
}: {
  data?: any[];
  rowDirection?: boolean;
}) => {
  const getItems = (
    data: {
      label: string;
      values: ({ dateType: DAY_TYPES; state?: undefined } | { dateType: DAY_TYPES; state: DAY_MARK })[];
      tooltip: React.ReactNode;
    }[],
  ) =>
    data.map(({ label, values, tooltip }) => (
      <div key={label} className="performance-heatmap__legend-item">
        <div>
          {values.map(({ dateType, state }) => (
            <div
              key={`${label}-${dateType}-${state}`}
              className="performance-heatmap__legend-cell"
              data-datetype={dateType}
              data-state={state}
            />
          ))}
        </div>
        <span>
          {label}
          {tooltip ? (
            <InputTooltip source={`${label}-tooltip`} message={tooltip as string} tooltipSize={TooltipSize.BIG} />
          ) : null}
        </span>
      </div>
    ));

  return (
    <div className="performance-heatmap__legend">
      {blocks.map(block => (
        <div
          key={uuidv4()}
          className={classnames('performance-heatmap__legend-inner', {
            'performance-heatmap__legend-inner--row': rowDirection,
          })}
        >
          {getItems(block)}
        </div>
      ))}
    </div>
  );
};

export default PerformanceHeatmapLegend;
