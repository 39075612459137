/* istanbul ignore file */
import React from 'react';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleContactsBreadcrumbs from 'crm/contact/shared/SingleContactsBreadcrumbs';

const ContactRelatedOpportunitiesBreadcrumbs = () => {
  return (
    <SingleContactsBreadcrumbs>
      <BreadcrumbItem>Related Opportunities</BreadcrumbItem>
    </SingleContactsBreadcrumbs>
  );
};

export default ContactRelatedOpportunitiesBreadcrumbs;
