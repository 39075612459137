/* istanbul ignore file */
import React from 'react';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout/ListFiltersLayout';
import useFiltersVisibilityHelper from 'shared/uibuilder/list/filter/layout/useFiltersVisibilityHelper';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import { FiltersLayoutProps } from 'shared/uibuilder/list/filter/layout';
import DropDownFilterLayout from './filter/DropDownFilterLayout';
import DropDownFilterInputLayout from './filter/DropDownFilterInputLayout/DropDownFilterInputLayout';
import DateRangeFilterLayout from './filter/DateRangeFilterLayout';
import AutosuggestionDropdownLayout from './filter/AutosuggestionDropdownLayout';
import MultiSelectInputLayout from './filter/MultipleDropdownFilterInputLayout';
import MonthRangeFilterLayout from './filter/MonthRangeFilterLayout';

const ListFiltersLayout = ({ children, quickFilters }: FiltersLayoutProps) => {
  const { isApplied, getFilteredChildren } = useFiltersVisibilityHelper({ children });

  return (
    <UiThemeOverrider
      overriddenLayouts={{
        DropdownLayout: DropDownFilterInputLayout,
        DropDownFilterLayout,
        DateRangeFilterLayout,
        AutosuggestionDropdownLayout,
        MultiSelectInputLayout,
        MonthRangeFilterLayout,
      }}
    >
      <FiltersLayout isApplied={isApplied} quickFilters={quickFilters}>
        {getFilteredChildren()}
      </FiltersLayout>
    </UiThemeOverrider>
  );
};

export default ListFiltersLayout;
