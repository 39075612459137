/* istanbul ignore file */
import React from 'react';
import LinkButton from 'uibuilder/button/LinkButton';

const EditEmailButton = ({
  path,
  isVisible = true,
  ...props
}: {
  isVisible?: boolean;
  path: string;
  [key: string]: any;
}) => {
  return isVisible ? (
    <LinkButton outline url={path} {...props}>
      Edit
    </LinkButton>
  ) : (
    <></>
  );
};

export default EditEmailButton;
