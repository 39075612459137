import React from 'react';

const PLetterIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
    <path
      fill="currentColor"
      d="M22 19.5556C22 20.2039 21.7425 20.8256 21.284 21.284C20.8256 21.7425 20.2039 22 19.5556 22H2.44444C1.79614 22 1.17438 21.7425 0.715961 21.284C0.257539 20.8256 0 20.2039 0 19.5556V2.44444C0 1.79614 0.257539 1.17438 0.715961 0.715961C1.17438 0.257539 1.79614 0 2.44444 0L19.5556 0C20.2039 0 20.8256 0.257539 21.284 0.715961C21.7425 1.17438 22 1.79614 22 2.44444V19.5556Z"
    />
    <path
      fill="white"
      d="M6.58594 5.70911C6.58594 4.81811 7.11638 4.23145 8.06422 4.23145H11.418C14.204 4.23145 16.269 6.05072 16.269 8.75978C16.269 11.5263 14.1277 13.2508 11.5323 13.2508H9.42944V16.3583C9.42944 17.3062 8.82322 17.836 8.00799 17.836C7.19277 17.836 6.58655 17.3062 6.58655 16.3583L6.58594 5.70911ZM9.42822 10.7496H11.3605C12.5547 10.7496 13.3124 9.87811 13.3124 8.74083C13.3124 7.60356 12.5547 6.73211 11.3605 6.73211H9.42822V10.7496Z"
    />
  </svg>
);

export default PLetterIcon;
