/* istanbul ignore file */
import React from 'react';
import { ARTIFACT_ID } from 'artifact/const';
import WysiwygInput, { WysiwygInputProps } from 'shared/uibuilder/form/input/WysiwygInput';
import WysiwygInputPasteArtifactDecorator from 'artifact/shared/input/WysiwygInputPasteArtifactDecorator';

interface ResumeWysiwygInputProps extends WysiwygInputProps {
  artifactsSource: string;
}

const ResumeWysiwygInput = ({ artifactsSource, ...props }: ResumeWysiwygInputProps) => {
  return (
    <WysiwygInputPasteArtifactDecorator artifactsSource={artifactsSource} artifactTypeId={ARTIFACT_ID.CANDIDATE_RESUME}>
      <WysiwygInput height={100} {...props} />
    </WysiwygInputPasteArtifactDecorator>
  );
};

export default ResumeWysiwygInput;
