/* istanbul ignore file */
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import React from 'react';
import { LEAVE_STATUSES } from 'erp/leave/shared/leaveService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const LeaveStatusDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => {
  return (
    <Dropdown
      placeholder="Choose Leave Status"
      options={Object.entries(LEAVE_STATUSES).map(entry => {
        const [value, label] = entry;

        return {
          value,
          label,
        };
      })}
      label="Leave Status"
      {...props}
    />
  );
};

export default LeaveStatusDropdown;
