import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import TabsWrapper from 'shared/uibuilder/TabsWrapper/TabsWrapper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ViewVacancyContent from './ViewVacancyContent';

const VacanciesWrapper = () => {
  const { data } = useShowContext();
  const vacancies = data?.getValueBySource('vacancies') || [];

  return (
    <TabsWrapper
      sxContentProps={{ p: 0 }}
      tabs={vacancies.map((vacancy: any, index: number) => {
        return {
          index,
          label: vacancy.specialization,
          content: (
            <Show getDataMethod={() => vacancy}>
              <ViewVacancyContent />
            </Show>
          ),
        };
      })}
    />
  );
};

export default VacanciesWrapper;
