import React from 'react';
import LeaveTypeField from 'erp/leave/shared/field/LeaveTypeField';
import { LinkField } from 'shared/uibuilder/field';
import Badge from 'shared/uibuilder/badge/Badge';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';
import { ExcludedFromBalanceBadge } from 'erp/leave';

const BalancesLeavesTypeField = ({ value = {} }: { value?: Dictionary<any> }) => {
  const { getSingleEntityUrl } = useLeaveUrl();
  const { id, isExtended, isComposite, isExcludedFromBalance, type } = value;
  const url = getSingleEntityUrl(id);

  return (
    <LinkField
      value={url}
      linkText={
        <div className="d-flex flex-column ml-auto mr-auto">
          <LeaveTypeField value={type} />
          <div className="d-flex balances-leaves-type-badges-container">
            {isExtended && <Badge color="primary">Extended</Badge>}
            {isExcludedFromBalance && <ExcludedFromBalanceBadge />}
            {isComposite && (
              <Badge className={isExtended ? 'ml-2' : ''} color="primary">
                Composite
              </Badge>
            )}
          </div>
        </div>
      }
    />
  );
};

export default BalancesLeavesTypeField;
