import React from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import MenuItem from '@mui/material/MenuItem';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { scrollToHash } from 'shared/uibuilder/domUtils';
import { ResourceData } from 'shared/crud/baseCrudService';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';
import useServiceKPIStructureRegistry from '../../useServiceKPIStructureRegistry';

type CopyKpiStructureButtonProps = {
  permissionToCheck: string;
};

const SCROLL_OFFSET = 100;
const DEFAULT_FUNCTION = () => {};

const CopyKpiStructureButton = ({ permissionToCheck }: CopyKpiStructureButtonProps) => {
  const { isGranted } = useAuthorization();
  const {
    addForm,
    setAddForm = DEFAULT_FUNCTION,
    createTimelineItemMethod = DEFAULT_FUNCTION,
    addToList = DEFAULT_FUNCTION,
    wrapItem = () => ({}),
    reloadData,
  }: TimelineContextData = useListContext();
  const { data: entity } = useShowContext();
  const { AddForm, source, urlPostfix, additionalAfterAddSubmit } = useServiceKPIStructureRegistry() as any;
  const formData = entity.getData();

  const closeAddForm = () => setAddForm(null);

  const copy = () => {
    const pageTop = document.querySelector('.show-page__content-inner .page-content');
    scrollToHash(pageTop, SCROLL_OFFSET);

    const form = (
      <AddForm
        onCancel={closeAddForm}
        submitFormFunc={(data: ResourceData) => createTimelineItemMethod(urlPostfix, data)}
        afterSubmit={{
          execute: (data: TimelineItem) => {
            addToList(wrapItem(data, source));
            closeAddForm();
            if (additionalAfterAddSubmit) {
              additionalAfterAddSubmit(data, entity, reloadData);
            }
          },
        }}
        initialData={{
          ...formData,
        }}
      />
    );

    setAddForm(form);
  };

  if (addForm || !isGranted(permissionToCheck)) {
    return null;
  }

  return isGranted(permissionToCheck) ? <MenuItem onClick={copy}>Copy</MenuItem> : null;
};

export default CopyKpiStructureButton;
