import React from 'react';
import ReplaceUrlWithLinkChild from 'shared/uibuilder/ReplaceUrlWithLink/ReplaceUrlWithLinkChild';

const ReplaceUrlWithLink = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
  isVisible?: boolean | ((data: any) => boolean);
}) => {
  return (
    <>
      {React.Children.map(children, child => (
        <ReplaceUrlWithLinkChild child={child} />
      ))}
    </>
  );
};

export default ReplaceUrlWithLink;
