import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, BaseFieldLayoutType } from 'shared/uibuilder/field';
import { useShowContext } from '../show/ShowContext';
import getLabel, { getValue } from '../helper';
import useUiTheme from '../useUiTheme';

export type TextFieldValue = string | number | React.ReactElement | React.ReactElement[];

const TextField = ({
  source = '',
  value,
  label,
  isSortable,
  className,
  isColumnDirection,
}: FieldProps<TextFieldValue>) => {
  const { data } = useShowContext();
  const { getValue: getValueFromFieldHelper } = useFieldHelper({ source });
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const getFieldValue = () => {
    const valueFromShowContext = getValue(value, src => data && data.getValueBySource(src as string), source, '');

    if (valueFromShowContext) {
      return valueFromShowContext;
    }

    return getValueFromFieldHelper();
  };

  return (
    <BaseFieldLayout
      isColumnDirection={isColumnDirection}
      label={getLabel(label, source)}
      value={getFieldValue()}
      isSortable={isSortable}
      className={className}
    />
  );
};

export default TextField;
