import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import { useShowContext } from '../show/ShowContext';
import { getValue } from '../helper';

type FormattedTextFieldProps = string | number | React.ReactElement | React.ReactElement[];

const FormattedTextField = ({
  source = '',
  value,
  label,
  isSortable,
  format = (str: string) => str,
}: FieldProps<FormattedTextFieldProps>) => {
  const { data } = useShowContext();
  const { getValue: getValueFromFieldHelper } = useFieldHelper({ source });

  const getFieldValue = () => {
    const valueFromShowContext = getValue(value, (src?: string) => data && data.getValueBySource(src!), source, '');
    return format(valueFromShowContext || getValueFromFieldHelper());
  };

  return <TextField label={label} source={source} value={getFieldValue()} isSortable={isSortable} />;
};

export default FormattedTextField;
