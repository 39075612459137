/* istanbul ignore file */
import React, { ReactElement, useEffect, useRef } from 'react';
import usePageTitle from 'pageTitle/pageTitle';

interface DynamicPageTitleBreadcrumbsProps {
  children: ReactElement;
}

const DynamicPageTitleBreadcrumbs = ({ children }: DynamicPageTitleBreadcrumbsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { setPageTitle, mapHtmlElementsForPageTitle } = usePageTitle();

  useEffect(() => {
    const title = mapHtmlElementsForPageTitle([
      ...(containerRef.current as any).querySelectorAll('.breadcrumb-item'),
    ] as any);
    setPageTitle(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div ref={containerRef}>{children}</div>;
};

export default DynamicPageTitleBreadcrumbs;
