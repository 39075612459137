/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import CandidateStatusDropdown from 'erp/candidate/shared/input/CandidateStatusDropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const CandidateStatusFilter = (props: DefaultDropdownProps) => {
  return (
    <DropDownFilter {...props}>
      <CandidateStatusDropdown {...props} />
    </DropDownFilter>
  );
};

export default CandidateStatusFilter;
