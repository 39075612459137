/* istanbul ignore file */
// libs
import React from 'react';
// components
import TextField from 'shared/uibuilder/field/TextField';
// services
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { LEAD_GRADE } from 'crm/lead/shared/leadService';
// styles
import './LeadGradeField.scss';
import { FieldProps } from 'shared/uibuilder/field';

const LeadGradeField = ({ source = null, value: inputValue = null, isFull = false }: FieldProps) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source!));

  const displayValue = value && (
    <span className={`lead-grade lead-grade--${LEAD_GRADE[value].cssModificator}`}>
      {isFull ? `${LEAD_GRADE[value].shorthand} (${LEAD_GRADE[value].name})` : LEAD_GRADE[value].shorthand}
    </span>
  );

  return <TextField value={displayValue} label="Lead Grade" />;
};

export default LeadGradeField;
