/* istanbul ignore file */
import React from 'react';
import CreateButton, { CreateButtonProps } from 'uibuilder/button/CreateButton';
import { CREATE_CONTACT } from 'crm/contact/shared/contactService';
import { useContactUrl } from 'crm/contact/ContactRouter';

const CreateContactButton = (props: Partial<CreateButtonProps>) => {
  const { getCreateUrl } = useContactUrl();

  return <CreateButton {...props} url={getCreateUrl()} permissionToCheck={CREATE_CONTACT} label="Create Contact" />;
};

export default CreateContactButton;
