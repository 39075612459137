/* istanbul ignore file */
import React from 'react';
import EnumDropdown, { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';

export const EMPLOYEE_TIMELINE_RECORDS_ALIAS = {
  FEEDBACK_CREATED: 'FEEDBACK_CREATED',
  NOTE_CREATED: 'NOTE_CREATED',
};

const employeeTimelineRecords = {
  FEEDBACK_CREATED: 'Feedback',
  NOTE_CREATED: 'Note',
};

const EmployeeTimelineRecordTypeDropdown = (props: Omit<EnumDropdownProps, 'options'>) => {
  return <EnumDropdown {...props} options={employeeTimelineRecords} />;
};

export default EmployeeTimelineRecordTypeDropdown;
