/* istanbul ignore file */
import { CreateForm } from 'shared/uibuilder/form';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import React from 'react';
import NoteForm from 'erp/candidate/Timeline/note/shared/form/NoteForm';
import { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';

export interface CreateNoteFormProps extends FormTemplateProps {
  service: () => any;
  onCancel: () => void;
}

const CreateNoteForm = ({ service, submitFormFunc, onCancel, ...props }: CreateNoteFormProps) => {
  const { getValidationSchema } = service();
  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateTimelineItemLayout onCancel={onCancel}>
        <NoteForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateNoteForm;
