/* istanbul ignore file */
import React from 'react';
import VacanciesBreadcrumbs from 'erp/recruitment/vacancy/shared/VacanciesBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';
import { useParams } from 'react-router-dom';

const SingleVacanciesBreadcrumbs = ({ children = null }: { children?: React.ReactNode | React.ReactNode[] | null }) => {
  const { getSingleEntityUrl } = useVacancyUrl();
  // @ts-ignore
  const { id } = useParams<Dictionary<string>>();

  return (
    <VacanciesBreadcrumbs>
      <SingleBreadcrumbItem entity={id!} link={getSingleEntityUrl(id)} />
      {children}
    </VacanciesBreadcrumbs>
  );
};

export default SingleVacanciesBreadcrumbs;
