import { Action } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { persistor } from 'uibuilder/reducer/store';
import { AuthenticationTokenActions } from 'authentication/tokenprovider/authenticationTokenReducer';

export interface AuthenticationToken {
  token: string;
  ssid: string;
}

export interface AuthenticationTokenAction extends Action {
  authenticationToken: AuthenticationToken;
}

export interface AuthenticationTokenState {
  authenticationToken: Nullable<AuthenticationToken>;
}

const saveToken = (authenticationToken: AuthenticationToken) => ({
  type: AuthenticationTokenActions.SAVE_TOKEN,
  authenticationToken,
});

const clearToken = () => ({
  type: AuthenticationTokenActions.CLEAR_TOKEN,
});

export interface AuthenticationTokenRepository {
  getToken: () => Promise<AuthenticationToken>;
  saveToken: (authToken: AuthenticationToken) => Promise<void>;
  clearToken: () => Promise<void>;
}

const useAuthenticationTokenRepository = (): AuthenticationTokenRepository => {
  const dispatch = useDispatch();
  const authenticationToken: AuthenticationToken = useSelector(
    (state: { authToken: AuthenticationTokenState }) => state?.authToken?.authenticationToken as AuthenticationToken,
  );

  const getAccessToken = (): AuthenticationToken => authenticationToken;

  const saveAccessToken = async (authToken: AuthenticationToken): Promise<void> => {
    dispatch(saveToken(authToken));
    await persistor.flush();
    // });
  };

  const clearAccessToken = async (): Promise<void> => {
    dispatch(clearToken());
    await persistor.flush();
  };
  return {
    getToken: () => Promise.resolve(getAccessToken()),
    saveToken: saveAccessToken,
    clearToken: clearAccessToken,
  };
};

export default useAuthenticationTokenRepository;
