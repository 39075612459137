import { useKernelApi } from 'api';
import { useDateTimeService } from 'shared/uibuilder/dateService';

export const READ_SECURITY_AUDIT_LOG = 'READ_SECURITY_AUDIT_LOG';

const useAuditService = () => {
  const { sendGetRequest } = useKernelApi();
  const { formatDateTimeForAPI } = useDateTimeService();

  const getRoleAssignmentsReport = async ({ filters }: Dictionary<any>) => {
    const period = filters.dateTime.eq;
    return sendGetRequest(`/audit/roles/report?dateBeforeAt=${formatDateTimeForAPI(period)}`);
  };

  const getPermissionsConfigurationReport = async ({ filters }: Dictionary<any>) => {
    const period = filters.dateTime.eq;
    return sendGetRequest(`/audit/permissions/report?dateBeforeAt=${formatDateTimeForAPI(period)}`);
  };

  const getArtifactsConfigurationReport = async ({ filters }: Dictionary<any>) => {
    const from = filters.dateOfAccess?.ge;
    const to = filters.dateOfAccess?.le;

    const params: any = {};

    if (from) {
      params.dateFrom = from;
    }
    if (to) {
      params.dateTo = to;
    }

    const searchParams = new URLSearchParams(params);

    const result = await sendGetRequest(`/artifacts/security/audit?${searchParams.toString()}`);
    return result;
  };

  return {
    getRoleAssignmentsReport,
    getPermissionsConfigurationReport,
    getArtifactsConfigurationReport,
  };
};

export default useAuditService;
