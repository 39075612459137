import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRouter } from 'routing';
import InterviewsList from 'erp/interview/List';
import { INTERVIEW_PATH, READ_INTERVIEWS_LIST } from 'erp/interview/shared/interviewService';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import useRoute from 'shared/routing/useRoute';
import Page404 from 'shared/uibuilder/Page404';

export const useInterviewRoute = () => {
  return useRoute({ listRoute: INTERVIEW_PATH });
};

export namespace Interview {
  export const Router = () => {
    const { listRoute } = useInterviewRoute();
    return (
      <ProtectedRouter basePath={INTERVIEW_PATH}>
        <Routes>
          <Route
            path={listRoute}
            element={
              <GlobalProtectedRoute globalPermissionToCheck={READ_INTERVIEWS_LIST}>
                <InterviewsList />
              </GlobalProtectedRoute>
            }
          />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </ProtectedRouter>
    );
  };

  export const getRouterPath = () => `${INTERVIEW_PATH}/*`;
}

export default Interview;
