import React, { useState } from 'react';
import ArtifactPreviewWrapper from './ArtifactPreviewWrapper';
import SearchResultField from 'shared/uibuilder/field/SearchResultField';
import InputLoader from 'shared/uibuilder/InputLoader';
import './SearchResultLayout.scss';

interface SearchResultDocumentLayoutProps {
  value: Dictionary<string>;
  handleDownload?: () => void;
  handleOpenPreview?: () => void;
}

const SearchResultDocumentLayout = ({
  value,
  handleDownload = () => {},
  handleOpenPreview = () => {},
}: SearchResultDocumentLayoutProps) => {
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    setLoading(true);
    await handleDownload();
    setLoading(false);
  };

  return (
    value && (
      <>
        <ArtifactPreviewWrapper
          key="artifact-preview-wrapper"
          artifact={value as any}
          handleOpenPreview={handleOpenPreview}
          handleDownload={onClick}
          hasIcon={false}
          customFileName={<SearchResultField value={value.title} isRowClickable={false} />}
        />
        {loading && <InputLoader className="files-list__files-loader" />}
      </>
    )
  );
};

export default SearchResultDocumentLayout;
