/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import useProjectStatusesService from '../../ProjectStatusesService';
import ProjectDemoStatusForm from './ProjectDemoStatusForm';

const UpdateProjectDemoStatusForm = ({ getDataFunc, onCancel, ...props }: any) => {
  const { getDemoValidationSchema, update } = useProjectStatusesService();

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      getValidationSchemaFunc={getDemoValidationSchema}
      {...props}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <ProjectDemoStatusForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

export default UpdateProjectDemoStatusForm;
