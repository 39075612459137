/* istanbul ignore file */
import React from 'react';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import LinkButton, { LinkButtonProps } from 'uibuilder/button/LinkButton';

const ViewEmployeeButton = (props: Omit<LinkButtonProps, 'children' | 'url'>) => {
  const { getSingleEntityUrl } = useEmployeeUrl();

  return (
    <LinkButton {...props} url={getSingleEntityUrl} key="view-employee" outline>
      View
    </LinkButton>
  );
};

export default ViewEmployeeButton;
