/* istanbul ignore file */
import React from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import LinkField from 'shared/uibuilder/field/LinkField';
import RequiredResourcesListField from 'crm/opportunity/presale/output/shared/field/RequiredResourcesListField';
import DateField from 'shared/uibuilder/field/DateField';
import { EmployeeLinkField } from 'erp/employee';
import EnumField from 'shared/uibuilder/field/EnumField';
import { OPPORTUNITY_PROJECT_TYPE } from 'crm/opportunity/presale/output/shared/presaleOutputService';

const PresaleOutputFields = () => {
  return (
    <>
      <EmployeeLinkField
        nameSource="relatedPresaleCoordinatorName"
        source="relatedPresaleCoordinatorAlias"
        label="Presale Coordinator"
      />
      <TextField source="descriptionText" label="Description" />
      <LinkField source="sharepointLink" label="Sharepoint Link" />
      <EnumField source="projectType" options={OPPORTUNITY_PROJECT_TYPE} />
      <DateField source="expectedProjectStart" label="Expected Project Start" />
      <RequiredResourcesListField source="requiredResources" label="Team Structure" />
    </>
  );
};

export default PresaleOutputFields;
