/* istanbul ignore file */
import React from 'react';
import { useServiceCatalogId } from '../../ServiceCatalogRouter';
import useKPIService from '../useKPIService';
import List from 'shared/uibuilder/list/List';
import moment from 'moment';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import Filters from 'shared/uibuilder/list/filter/Filters';
import Box from 'uibuilder/Box';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import KPIValueListField from '../shared/fields/KPIValueListField';
import KpiStructureStaticFilters from './filter/KpiStructureStaticFilters';

const ActiveKpiStructure = () => {
  const id = useServiceCatalogId();
  const { getActiveStructure } = useKPIService();
  const currentMonth = moment().startOf('month').format(DATE_FORMAT.API_ONLY_MONTH);

  return (
    <Box sx={{ minHeight: '200px', position: 'relative' }}>
      <List
        getDataMethod={async req => getActiveStructure(id, req)}
        defaultFilter={{
          period: {
            eq: currentMonth,
          },
        }}
      >
        <>
          <Filters>
            <KpiStructureStaticFilters />
          </Filters>
          <DataGridLayout showTotal={false} isSmallTable>
            <KPIValueListField source="kpiParameters" dateSource="effectiveDate" />
          </DataGridLayout>
        </>
      </List>
    </Box>
  );
};

export default ActiveKpiStructure;
