import React, { useState } from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useInputHelper from './inputHelper';
import { Placement } from 'shared/uibuilder/SimpleTooltip';

export interface ButtonDropdownProps {
  label?: string | JSX.Element | (() => string | JSX.Element) | null | undefined;
  displayedValue?: React.ReactNode;
  children: React.ReactNode;
  onRemoveCallback?: Nullable<() => void>;
  className?: string;
  source?: string;
  isOpen?: boolean;
  toggleMenu?: () => void;
  shouldCloseMenuOnReset?: boolean;
  popperPlacement?: Placement;
}

export interface ButtonDropdownLayoutProps extends ButtonDropdownProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

export type ButtonDropdownLayoutType = ReactComponent<ButtonDropdownLayoutProps>;

const ButtonDropdown = ({
  source = '',
  isOpen,
  toggleMenu: baseToggleMenu,
  displayedValue,
  ...props
}: ButtonDropdownProps) => {
  const inputHelper = useInputHelper<string>({ source });
  const { ButtonDropdownLayout } = useUiTheme<ButtonDropdownLayoutType>();
  const [isMenuShown, setIsMenuShown] = useState(false);

  const toggleMenu = () => setIsMenuShown(!isMenuShown);

  return (
    <ButtonDropdownLayout
      {...props}
      displayedValue={displayedValue || inputHelper.getValue()}
      toggleMenu={baseToggleMenu || toggleMenu}
      isOpen={isOpen || isMenuShown}
      source={source}
    />
  );
};

export default ButtonDropdown;
