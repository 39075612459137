import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { Button } from 'uibuilder/button';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useOneClickService from 'authentication/OneClick/oneClickService';
import { useOneStopRequestsUrl } from 'oneStop/OneStopRouterHelper';
import useAzureAuthenticator from 'authentication/azure/azureAuthenticator';
import useEventBus, { USER_IS_LOGGED_OUT } from 'shared/useEventBus';

const OneClickButton = ({ password }: { password: { password: Nullable<string> } }) => {
  const { getValue: getUsername } = useFieldHelper({ source: 'username' });
  const { getAccessTokenForUser } = useOneClickService();
  const { addMessage } = useMessageService();
  const { setLoading = () => {} } = useListContext();
  const { authenticate } = useAzureAuthenticator();
  const { getListUrl: getOneStopRequestsUrl } = useOneStopRequestsUrl();
  const { dispatchEvent } = useEventBus();

  const signInAsUser = (username: string) => {
    setLoading(true);
    getAccessTokenForUser(username, password)
      .then(async ({ accessToken: token, ssid }) => {
        const authToken = { token, ssid };

        dispatchEvent(USER_IS_LOGGED_OUT, {});
        await authenticate(authToken);
        // @ts-ignore
        window.location = getOneStopRequestsUrl();
      })
      .catch(() => {
        addMessage(new ErrorMessage('Cannot sign in as the chosen user'));
        setLoading(false);
      });
  };

  const username = getUsername();
  return (
    <Button
      key={`sign-in-as-${username}`}
      onClick={() => {
        signInAsUser(username);
      }}
    >
      Sign in
    </Button>
  );
};

export default OneClickButton;
