/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { AssignmentType } from 'erp/assignment/shared/assignmentService';
import EmployeeLinkField from 'erp/employee/shared/field/EmployeeLinkField';
import { FieldProps, TextField } from 'shared/uibuilder/field';

const AssigneeField = ({ source, ...props }: FieldProps) => {
  const { getValue: getAssignmentType } = useFieldHelper({ source });

  return getAssignmentType() === AssignmentType.INDIVIDUAL_ASSIGNMENT ? (
    <EmployeeLinkField source="employeeId" nameSource="employeeName" {...props} />
  ) : (
    <TextField source="employeeGroup.name" />
  );
};

export default AssigneeField;
