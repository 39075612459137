/* istanbul ignore file */
import Show from 'shared/uibuilder/show/Show';
import React from 'react';
import { useProjectId } from 'erp/project/ProjectRouter';
import useProjectService from 'erp/project/shared/projectService';

const ShowProject = ({ children = null }: { children?: React.ReactNode }) => {
  const { getById } = useProjectService();
  const currentProjectId = useProjectId();

  return <Show getDataMethod={() => getById(currentProjectId)}>{children}</Show>;
};

export default ShowProject;
