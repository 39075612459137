import { usePeopleManagementApi } from 'api';
import useCrudService from 'shared/crud';
import { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import useHiringService, { HiringRequest } from '../hiring/useHiringService';

export const HIRING_REQUEST_DASHBOARD = 'COMPANY_HIRING_REQUEST_DASHBOARD';

const RECRUITING_POOLS_PATH = '/hiring-requests/dashboard';

const useRecruitingDashboardService = () => {
  const { sendPostRequest } = usePeopleManagementApi();
  const { mapToViewData } = useHiringService();

  const {
    search: baseSearch,
    update: baseUpdate,
    create: baseCreate,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RECRUITING_POOLS_PATH}/:id`,
    listResourceUrl: RECRUITING_POOLS_PATH,
    apiService: usePeopleManagementApi,
  });

  const search = async (request: ResourceData) => {
    const params = cloneDeep(request);

    const searchString = params.filter?.['specification:search']?.eq || '';

    if (searchString && params.filter) {
      delete params.filter?.['specification:search'];
      params.filter.specialisation = {
        ct: searchString,
      };
    }

    ['isAsap'].forEach(source => {
      const filterValue = params.filter?.[source]?.eq || '';

      if (filterValue && params.filter) {
        params.filter[source] = {
          eq: filterValue === 'YES',
        };
      }
    });

    const res = await sendPostRequest(RECRUITING_POOLS_PATH, params);
    const response = await res.json();

    const newData = response?.result?.map((item: HiringRequest) => {
      return mapToViewData(item);
    });

    return {
      ...response,
      result: newData,
    };
  };

  return {
    search,
    ...baseCrud,
  };
};

export default useRecruitingDashboardService;
