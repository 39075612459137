import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import VacancyLinkField from 'erp/recruitment/vacancy/shared/input/VacancyLinkField';
import VacancyGroupLinkField from 'erp/recruitment/newVacancy/shared/VacancyGroupLinkField';
import { FieldProps } from 'shared/uibuilder/field';

const UniversalVacancyField = ({ isLegacySource, ...props }: FieldProps) => {
  const { getValue: isLegacy } = useFieldHelper({ source: isLegacySource });
  return isLegacy() ? <VacancyLinkField {...props} /> : <VacancyGroupLinkField {...props} />;
};

export default UniversalVacancyField;
