/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useAccountService from 'crm/account/accountService';

export const ACCOUNT_CONTEXT = 'Account';

const AccountContext = ({
  permissionToCheck,
  children,
}: {
  permissionToCheck: string | string[];
  children: React.ReactNode;
}) => {
  const { getPermissions } = useAccountService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={ACCOUNT_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default AccountContext;
