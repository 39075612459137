import React from 'react';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import EnumDropdown from '../../../../../shared/uibuilder/form/input/EnumDropdown';
import { REJECTION_REASONS_BY_STAGE } from '../../constants';

interface RejectStageFormProps {
  handleCancelClick: () => void;
  sourceStage: string;
}

const RejectStageForm = ({ handleCancelClick, sourceStage }: RejectStageFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();
  const options = Object.entries(REJECTION_REASONS_BY_STAGE).find(([key, _]) => key === sourceStage);
  const reasons = options ? options[1] : null;

  return (
    <>
      <EnumDropdown options={reasons as Dictionary<string>} source="reason" />
      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default RejectStageForm;
