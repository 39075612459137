/* istanbul ignore file */
import React from 'react';
import { TextArea } from 'shared/uibuilder/form/input/index';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getLastSource } from 'shared/uibuilder/helper';
import Grid from 'uibuilder/Grid';
import AccountInput from 'financialAnalytic/ledger/shared/input/AccountInput';
import TransferCurrencyInput from './TransferCurrencyInput';
import useLedgerService from 'financialAnalytic/ledger/useLedgerService';
import BudgetCategoryDropdown from './BudgetCategoryDropdown';
import { isBudgetCategoryRequired } from 'erp/serviceCatalog/budgets/shared/service/budgetCatagorySuggestionService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const TransferInput = ({ className, disabled, ...props }: any) => {
  const { searchJournalEntriesAccount } = useLedgerService();
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback() as any;

  const transfer = getValue() || ({} as any);
  const source = getSource();
  const { data = {} } = useFormContext();
  const asOfDate: string = data.asDateOf;

  const onChangeCallback = (value: any, additionalValues: Dictionary<any> = {}) => {
    rawOnChangeCallback({
      [source]: {
        ...transfer,
        ...getLastSource(value),
        ...additionalValues,
      },
    });
  };

  const onAccountChangeCallback = (value: any) => {
    onChangeCallback(value, {
      currency: value.currency,
    });
  };

  return (
    <Grid container spacing={2} className="journal-entries-transfer-row">
      <Grid item xs={12} md={3} sx={{ flexShrink: 1 }}>
        <AccountInput
          source={`${source}.accountId`}
          label="Account"
          value={transfer.accountId}
          onChangeCallback={onAccountChangeCallback}
          disabled={disabled}
          cacheKey="ledger-accounts"
          loadDataMethod={searchJournalEntriesAccount}
        />
      </Grid>
      <Grid item xs={12} md={3} sx={{ flexShrink: 1 }}>
        <TransferCurrencyInput
          source={`${source}.debit`}
          label="Debit"
          onChangeCallback={onChangeCallback}
          disabled={!!transfer.credit || disabled}
          currency={transfer.currency}
        />
      </Grid>
      <Grid item xs={12} md={3} sx={{ flexShrink: 1 }}>
        <TransferCurrencyInput
          source={`${source}.credit`}
          label="Credit"
          onChangeCallback={onChangeCallback}
          disabled={!!transfer.debit || disabled}
          currency={transfer.currency}
        />
      </Grid>
      <Grid item xs={12} md={3} sx={{ flexShrink: 1 }}>
        <BudgetCategoryDropdown
          disabled={!isBudgetCategoryRequired(transfer.accountId)}
          source={`${source}.attributes.budgetCategory`}
          label="Budget Category"
          dateValue={asOfDate}
          accountIdValue={transfer.accountId}
        />
      </Grid>
      <Grid item xs={12} md={3} sx={{ flexShrink: 1 }}>
        <TextArea
          source={`${source}.description`}
          value={transfer.description}
          label="Description"
          onChangeCallback={onChangeCallback}
          disabled={disabled}
          rows={1}
        />
      </Grid>
    </Grid>
  );
};

export default TransferInput;
