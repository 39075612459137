/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import RoleAssignmentsList from 'audit/List/roles';
import PermissionsConfigurationList from 'audit/List/permissions';
import { READ_SECURITY_AUDIT_LOG } from 'audit/shared/auditService';
import ArtifactsAuditList from './List/artifact';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import useRoute from '../shared/routing/useRoute';

export const AUDIT_PATH = '/audit';
export const ROLES_AUDIT_PATH = '/audit/roles';
export const PERMISSIONS_AUDIT_PATH = '/audit/permissions';
export const ARTIFACTS_AUDIT_PATH = '/audit/artifacts';

const useAuditRoutes = () => {
  const baseRoutes = useRoute({ listRoute: AUDIT_PATH });

  return {
    ...baseRoutes,
    rolesRoute: `${baseRoutes.listRoute}/roles`,
    permissionsRoute: `${baseRoutes.listRoute}/permissions`,
    artifactsRoute: `${baseRoutes.listRoute}/artifacts`,
  };
};

const AuditRouter = () => {
  const { rolesRoute, permissionsRoute, artifactsRoute } = useAuditRoutes();

  return (
    <ProtectedRouter basePath={AUDIT_PATH}>
      <Routes>
        <Route
          path={rolesRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_SECURITY_AUDIT_LOG}>
              <RoleAssignmentsList />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={permissionsRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_SECURITY_AUDIT_LOG}>
              <PermissionsConfigurationList />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={artifactsRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_SECURITY_AUDIT_LOG}>
              <ArtifactsAuditList />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

AuditRouter.getRouterPath = () => `${AUDIT_PATH}/*`;

export default AuditRouter;
