/* istanbul ignore file */
import React from 'react';
import { FieldProps, LinkField } from 'shared/uibuilder/field/index';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';

const VacancyLinkField = ({ source, nameSource, value, nameValue, getLinkText, ...props }: FieldProps) => {
  const { getValue: getId } = useFieldHelper({ source, value });
  const { getValue: getName } = useFieldHelper({ source: nameSource, value: nameValue });
  const { getSingleEntityUrl } = useVacancyUrl();

  const id = getId();
  const name = getName();
  const linkUrl = id && getSingleEntityUrl(id);
  const linkText = nameSource || nameValue ? name : id;

  return (
    <LinkField
      {...props}
      linkText={getLinkText ? getLinkText({ id, name }) : linkText}
      value={linkUrl as string}
      isInternal={false}
    />
  );
};

export default VacancyLinkField;
