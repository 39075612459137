import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import Loading from 'shared/uibuilder/Loading';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setGlobalPermissions } from 'shared/authorization/redux/authorizationActions';
import { GlobalSecurityProvider } from 'shared/authorization/react-context/GlobalSecurityReactContext';
import convertArrayToObject from 'shared/authorization/utils';

type PermissionLoaderProps = {
  getGlobalPermissions: () => Promise<{ json: () => Promise<any> }>;
  children: React.ReactNode;
  isUserAuthenticated: boolean;
};

let isLoading = false;

const loadGlobalPermissions = (
  dispatch: Dispatch,
  getGlobalPermissions: () => Promise<{ json: () => Promise<any> }>,
) => {
  if (!isLoading) {
    isLoading = true;
    getGlobalPermissions()
      .then(result => {
        result.json().then(permissions => {
          if (permissions) {
            dispatch(setGlobalPermissions(convertArrayToObject(permissions)));
          }
        });
      })
      .catch(() => {
        throw new Error('Error occurred. Please, reload the page');
      })
      .finally(() => {
        isLoading = false;
      });
  }
};

const GlobalPermissionsLoader = ({ getGlobalPermissions, children, isUserAuthenticated }: PermissionLoaderProps) => {
  const [isLoaded, setLoaded] = useState<boolean>();

  // @ts-ignore
  const globalPermissions = useSelector(state => state.authorization.globalPermissions, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!globalPermissions && isUserAuthenticated) {
      setLoaded(true);
      loadGlobalPermissions(dispatch, getGlobalPermissions);
    } else {
      setLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalPermissions, isUserAuthenticated]);

  let result;
  if (!isUserAuthenticated) {
    result = children;
  } else if (isLoaded) {
    result = <Loading />;
  } else {
    const context = {
      permissions: globalPermissions,
    };
    result = <GlobalSecurityProvider value={context}>{children}</GlobalSecurityProvider>;
  }

  return <>{result}</>;
};

export default GlobalPermissionsLoader;
