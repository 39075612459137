/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem, { SidebarMenuItemProps } from 'shared/uibuilder/menu/SidebarMenuItem';

const ContactsMenuItem = ({ linkUrl, ...props }: SidebarMenuItemProps) => (
  <SidebarMenuItem {...props} linkUrl={linkUrl} icon="group" />
);

ContactsMenuItem.displayName = 'Contacts';

export default ContactsMenuItem;
