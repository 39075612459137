/* istanbul ignore file */
import React from 'react';
import Paper from 'uibuilder/Paper';
import Message from 'shared/message/Message';

interface ReportPageLayoutProps {
  header?: React.ReactElement;
  filter?: React.ReactElement;
  actions?: React.ReactElement[];
  children?: React.ReactElement | React.ReactElement[];
  search?: React.ReactElement;
  showTotal?: boolean;
}

const ReportPageLayout = ({ header, filter, actions, showTotal, children, search }: ReportPageLayoutProps) => {
  return (
    <Paper
      sx={{ padding: { xs: '16px', md: '20px 25px' }, paddingBottom: 3, '& > h1': { marginBottom: '36px' } }}
      className="page-content"
    >
      {header}
      <Message />
      {(!!search || !!filter) && (
        <>
          {search}
          {filter}
        </>
      )}
      {children}
    </Paper>
  );
};

export default ReportPageLayout;
