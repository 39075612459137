import React from 'react';
import { WysiwygInput } from 'shared/uibuilder/form/input/index';
import { Route } from 'shared/routing';
import { Routes } from 'react-router-dom';

export const WYSIWYG_ROUTES = {
  main: '/wysiwyg-input',
};

export const useWysiwygInputTestingRoutes = (visit: (arg0: string) => any) => {
  return {
    main: () => visit(WYSIWYG_ROUTES.main),
  };
};

const preparedHtmlTemplate =
  '<b>bold</b> <i>italic</i> <u>underline</u> <s>strike</s> <a href="#">link</a> <ul><li>list 1</li></ul> <ol><li>list 2</li></ol>' +
  '\n <table><thead><tr><th>th 1</th><th>th 2</th></tr></thead><tbody><tr><td>td 1</td><td>td 2</td></tr></tbody></table> ' +
  '<h1>H1</h1>' +
  '<h2>H2</h2>' +
  '<h3>H3</h3>' +
  '<blockquote>blockquote</blockquote>';

const WysiwygInputMainPage = () => {
  const handleChange = (data: any) => {
    // @ts-ignore
    window.WysiwygInput = {
      valueAfterOnChange: Object.values(data)[0],
    };
  };

  return <WysiwygInput value={preparedHtmlTemplate} onChangeCallback={handleChange} source="" />;
};

export default () => (
  <Routes>
    <Route path={WYSIWYG_ROUTES.main} element={<WysiwygInputMainPage />} />
  </Routes>
);
