import { TextField } from 'shared/uibuilder/field';
import React from 'react';

import 'erp/leave/createupdate/duration/LeaveDurationLayout.scss';
import { useDateService } from 'shared/uibuilder/dateService';

const LeaveDurationLayout = ({ days, startDate, endDate }: { days: number; startDate: string; endDate: string }) => {
  const { getDaysBetween } = useDateService();
  const requestDuration = getDaysBetween(endDate, startDate) + 1;
  const isHolidaysExcluded = requestDuration > days;
  let selectedTimeUnit = null;
  if (days === 1) {
    selectedTimeUnit = 'day';
  } else if (days > 1 || days === 0) {
    selectedTimeUnit = 'days';
  }
  const message = isHolidaysExcluded ? `${selectedTimeUnit}, holidays excluded` : `calendar ${selectedTimeUnit}`;

  const value = selectedTimeUnit ? `(${days} ${message})` : null;

  return <div className="leave-duration-layout">{value && <TextField value={value} />}</div>;
};

export default LeaveDurationLayout;
