/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import { UPDATE_CONTRACT_REQUEST } from 'crm/opportunity/shared/opportunityService';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import Collapsible from 'shared/uibuilder/field/Collapsible';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import RedmineIssueField from 'redmine/RedmineIssueField';
import ContractRequestFields from 'crm/opportunity/presale/contract/ContractOutputTimelineRecordLayout/ContractRequestFields';

const ContractOutputTimelineRecordLayout = ({ onEdit, ...props }: any) => {
  const { data } = useShowContext();

  const buttons = [<EditTimelineRecordButton onEdit={onEdit} permissionToCheck={UPDATE_CONTRACT_REQUEST} />];

  /* eslint-disable react/no-unstable-nested-components */
  return (
    <BaseTimelineRecordLayout
      fields={
        <Collapsible>
          <ContractRequestFields />
        </Collapsible>
      }
      entity={data}
      timelineType={TimelineType.RESUME}
      buttons={buttons}
      actions={[]}
      timelineFooter={() => (
        <div className="timeline__additional d-flex">
          <span>Redmine ticket:&nbsp;</span>
          <RedmineIssueField source="redmineTicketId" />
        </div>
      )}
      {...props}
    />
  );
};

export default ContractOutputTimelineRecordLayout;
