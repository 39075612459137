import React from 'react';
import Box from 'uibuilder/Box';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import MonthFilter from 'shared/uibuilder/list/filter/MonthFilter';

type KpiStructureStaticFiltersProps = {
  title?: React.ReactNode;
  sx?: SxProps<Theme>;
};

const KpiStructureStaticFilters = ({ title, sx = {} }: KpiStructureStaticFiltersProps) => {
  const filters = (
    <FiltersLayout>
      <MonthFilter label="" source="period" isRequired canBeInFuture hasTodayButton todayButtonLabel="Current month" />
    </FiltersLayout>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& [data-testId="remove-btn"], & .filters-reset-btn': {
          display: 'none',
        },
        '.button-dropdown__toggle': {
          minHeight: '36px',
          padding: '5px 12px',
        },
        '.filter-item-wrapper': {
          marginRight: 0,
        },
        ...sx,
      }}
    >
      {title}
      <Box>{filters}</Box>
    </Box>
  );
};

export default KpiStructureStaticFilters;
