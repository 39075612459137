import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import React, { useEffect } from 'react';
import Grid from 'uibuilder/Grid';
import { DefaultInputPropTypes, TextInput } from 'shared/uibuilder/form/input';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { ADDITIONAL_CONTACTS_OPTIONS } from 'erp/shared/input/AdditionalContactListInput/useAdditionalContactsHelper';

const AdditionalContactInput = (props: DefaultInputPropTypes<unknown>) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const onChangeCallback = getRawOnChangeCallback() || (() => {});

  const additionalContact = getValue() || {};

  useEffect(() => {
    if (!getValue()) {
      onChangeCallback({
        [getSource()]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeSource = `${getSource()}.type`;
  const valueSource = `${getSource()}.value`;

  const typeOnChangeCallback = (obj: { [x: string]: any }) => {
    onChangeCallback({
      [getSource()]: {
        ...additionalContact,
        type: obj[typeSource],
      },
    });
  };

  const valueOnChangeCallback = (obj: { [x: string]: any }) => {
    onChangeCallback({
      [getSource()]: {
        ...additionalContact,
        value: obj[valueSource],
      },
    });
  };

  return (
    <Grid container className="additional-contact-row" spacing={2}>
      <Grid item xs={12} md={6}>
        <EnumDropdown
          source={typeSource}
          label="Type"
          value={additionalContact.type}
          onChangeCallback={typeOnChangeCallback}
          options={ADDITIONAL_CONTACTS_OPTIONS}
          placeholder="Select type"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          source={valueSource}
          label="Value"
          value={additionalContact.value}
          onChangeCallback={valueOnChangeCallback}
        />
      </Grid>
    </Grid>
  );
};

export default AdditionalContactInput;
