import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PAGE_LOAD_START, PAGE_LOAD_RESET_COUNTER } from 'shared/audit/pageLoad';
import useGoogleAnalytics from 'shared/audit/useGoogleAnalytics';
import usePageLoad from 'shared/audit/usePageLoad';
import { useKernelApi } from 'api';

const GEOLOCATION_PATH = '/geolocations';

const Audit = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { sendPostRequest } = useKernelApi();
  const { initObserver } = usePageLoad();

  useGoogleAnalytics();

  const observer = initObserver();
  const previousPathname = useRef(location.pathname);

  useEffect(() => {
    dispatch({
      type: PAGE_LOAD_START,
      globalStart: true,
    });

    observer.observe({ entryTypes: ['resource'] });

    const getData = async () => {
      try {
        await sendPostRequest(GEOLOCATION_PATH);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    getData();

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (previousPathname.current !== location.pathname) {
      dispatch({ type: PAGE_LOAD_RESET_COUNTER });
      previousPathname.current = location.pathname;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <>{children}</>;
};

export default Audit;
