/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { LEAD_MEDIUM } from 'crm/lead/shared/leadService';
import { FieldProps } from 'shared/uibuilder/field';

const LeadMediumField = ({ value: inputValue = null, source = null }: FieldProps) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));

  return <EnumField label="Lead Medium" value={value} options={LEAD_MEDIUM} />;
};

export default LeadMediumField;
