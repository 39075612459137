import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import VacancyGroupsBreadcrumbs from './VacancyGroupsBreadcrumbs';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useVacancyGroupId, useVacancyGroupUrl } from 'erp/recruitment/newVacancy/routing';

const SingleVacancyBreadcrumbs = ({ children = null }: { children?: React.ReactNode | React.ReactNode[] | null }) => {
  const { data } = useShowContext();
  const { getSingleEntityUrl } = useVacancyGroupUrl();
  const id = useVacancyGroupId();

  return (
    <VacancyGroupsBreadcrumbs>
      <SingleBreadcrumbItem entity={data.getValueBySource('specialization')} link={getSingleEntityUrl(id)} />
      {children}
    </VacancyGroupsBreadcrumbs>
  );
};

export default SingleVacancyBreadcrumbs;
