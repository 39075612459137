/* istanbul ignore file */
// libs
import React from 'react';
// components
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import FullDateField from 'shared/uibuilder/field/FullDateField';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import { SearchResultField, PhoneField } from 'shared/uibuilder/field';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import AccountFilter from 'crm/shared/filter/AccountFilter';
import CreateContactButton from 'crm/contact/list/buttons/CreateContactButton';
import ContactEmailField from 'crm/contact/list/field/ContactEmailField';
import ContactLinkField from 'crm/contact/shared/field/ContactLinkField';
import AccountLinkField from 'crm/account/shared/field/AccountLinkField';
import EditContactButton from 'crm/contact/shared/button/EditContactButton';
// services
import useContactService, { UPDATE_CONTACT_FROM_LIST_PAGE } from 'crm/contact/shared/contactService';

const ContactsListPage = () => {
  const { search, getFilterValidationSchema } = useContactService();

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout>
        <AccountFilter label="Account Name" source="account.alias" />
        <DateTimeRangeFilter label="Created Date" source="createdAt" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List getDataMethod={search} defaultSortField="updatedAt" defaultSortOrder={DESC}>
      <ListPageLayout
        header={<ListHeader label="Contacts" icon="fa-id-card" />}
        filter={filters}
        actions={[<CreateContactButton key="create-contact" />]}
        search={<SearchInput label="Contact Name" />}
      >
        <DataGridLayout
          buttons={[
            <EditContactButton
              key="edit-contact"
              source="id"
              outline
              permissionToCheck={UPDATE_CONTACT_FROM_LIST_PAGE}
            />,
          ]}
        >
          <ContactLinkField
            linkText={(contact: any) => contact.getValueBySource('id')}
            label="Contact ID"
            source="id"
            idSource="id"
            isSortable
            width={120}
          />
          <SearchResultField
            value={contact => `${contact.firstName} ${contact.middleName || ''} ${contact.lastName}`}
            label="Contact Name"
            source="lastName"
            isSortable
          />
          <AccountLinkField
            label="Account name"
            source="accountName"
            sortSource="account.accountName"
            idSource="accountId"
            isSortable
            isInternal={false}
          />
          <PhoneField source="phoneNumber" label="Phone" />
          <ContactEmailField source="emails" label="Email" />
          <FullDateField source="createdAt" label="Created Date" isSortable />
          <FullDateField source="updatedAt" label="Last Modified Date" isSortable />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default ContactsListPage;
