/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import useRoute from 'shared/routing/useRoute';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import BudgetReportList from 'erp/budget/report/List';
import { READ_BUDGET_REPORT } from 'erp/budget/shared/budgetService';

export const BUDGET_REPORT_PATH = '/budgets-report';

export const useBudgetReportRoute = () => {
  return useRoute({ listRoute: BUDGET_REPORT_PATH });
};

const BudgetReportRouter = () => {
  const { listRoute } = useBudgetReportRoute();

  return (
    <ProtectedRouter basePath={BUDGET_REPORT_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_BUDGET_REPORT}>
              <BudgetReportList />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

BudgetReportRouter.getRouterPath = () => `${BUDGET_REPORT_PATH}/*`;

export default BudgetReportRouter;
