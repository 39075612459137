/* istanbul ignore file */
import React from 'react';
import {
  Checkbox,
  DateInput,
  Dropdown,
  NumberInput,
  TextArea,
  TextInput,
  WysiwygInput,
} from 'shared/uibuilder/form/input';
import NotDismissedEmployeeInput from 'erp/employee/shared/input/NotDismissedEmployeeInput';
import CandidateInput from 'erp/candidate/shared/input/CandidateInput';
import DocumentScanUploaderInput from 'artifact/shared/input/DocumentScanUploaderInput';
import ContractScanUploaderInput from 'artifact/shared/input/ContractScanUploaderInput';
import EmployeePhotoUploaderInput from 'artifact/shared/input/EmployeePhotoUploaderInput';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import NoteAttachmentUploaderInput from 'artifact/shared/input/NoteAttachmentUploaderInput';
import CandidateResumeUploaderInput from 'artifact/shared/input/CandidateResumeUploaderInput';
import OneStopAttachmentUploaderInput from 'artifact/shared/input/OneStopAttachmentUploaderInput';
import FeedbackAttachmentUploaderInput from 'artifact/shared/input/FeedbackAttachmentUploaderInput';
import LeaveApplicationScanUploaderInput from 'artifact/shared/input/LeaveApplicationScanUploaderInput';
import ZoneDateTimeInput from 'shared/uibuilder/form/input/ZoneDateTimeInput';
import EmailAttachmentUploaderInput from 'artifact/shared/input/EmailAttachmentUploaderInput';
import ProjectInput from 'erp/project/shared/input/ProjectInput';
import OpportunityInput from 'crm/opportunity/shared/input/OpportunityInput';
import VacancyDropdown from 'erp/candidate/shared/input/VacancyDropdown';
import CandidateApplicationDropdown from 'erp/candidate/applications/shared/input/CandidateApplicationDropdown';
import ServiceInput from 'erp/service/shared/input/ServiceInput';

export enum FormInputType {
  TEXT = 'text',
  DATE = 'date',
  LONG = 'long',
  NUMBER = 'number',
  DROPDOWN = 'dropdown',
  DATETIME = 'datetime',
  TEXTAREA = 'textarea',
  CHECKBOX = 'checkbox',
  EMPLOYEE = 'employee',
  CANDIDATE = 'candidate',
  CANDIDATE_APPLICATION = 'candidate_application',
  VACANCY = 'vacancy',
  CONTRACT_SCAN = 'contract_scan',
  CONTRACT_SCANS = 'contract_scan[]',
  DOCUMENT_SCAN = 'document_scan',
  DOCUMENT_SCANS = 'document_scan[]',
  CRM_ATTACHMENT = 'crm_attachment',
  CRM_ATTACHMENTS = 'crm_attachment[]',
  EMPLOYEE_PHOTO = 'employee_photo',
  EMPLOYEE_PHOTOS = 'employee_photo[]',
  NOTE_ATTACHMENT = 'note_attachment',
  NOTE_ATTACHMENTS = 'note_attachment[]',
  CANDIDATE_RESUME = 'candidate_resume',
  CANDIDATE_RESUMES = 'candidate_resume[]',
  FEEDBACK_ATTACHMENT = 'feedback_attachment',
  FEEDBACK_ATTACHMENTS = 'feedback_attachment[]',
  EMAIL_ATTACHMENT = 'email_attachment',
  LEAVE_APPLICATION_SCAN = 'leave_application_scan',
  LEAVE_APPLICATION_SCANS = 'leave_application_scan[]',
  ONE_STOP_ATTACHMENT = 'one_stop_request_attachment',
  ONE_STOP_ATTACHMENTS = 'one_stop_request_attachment[]',
  REDMINE_PROJECT = 'redmine_project',
  WYSIWYG = 'wysiwyg',
  OPPORTUNITY = 'opportunity',
  COMPANY_SERVICE = 'company_service',
}

interface FormInputFactoryProps {
  type?: FormInputType;
  props?: any;
}

const FormInputFactory = ({ type, props }: FormInputFactoryProps) => {
  switch (type) {
    case FormInputType.TEXT:
      return <TextInput {...props} />;
    case FormInputType.DATE:
      return <DateInput {...props} />;
    case FormInputType.LONG:
    case FormInputType.NUMBER:
      return <NumberInput {...props} />;
    case FormInputType.DROPDOWN:
      return <Dropdown placeholder="Select option" {...props} />;
    case FormInputType.DATETIME:
      return <ZoneDateTimeInput {...props} />;
    case FormInputType.TEXTAREA:
      return <TextArea {...props} />;
    case FormInputType.CHECKBOX:
      return <Checkbox {...props} />;
    case FormInputType.EMPLOYEE:
      return <NotDismissedEmployeeInput nameSource={props.source} includeContractors {...props} />;
    case FormInputType.CANDIDATE:
      return <CandidateInput nameSource={props.source} {...props} />;
    case FormInputType.CANDIDATE_APPLICATION:
      return <CandidateApplicationDropdown nameSource={props.source} {...props} />;
    case FormInputType.VACANCY:
      return <VacancyDropdown nameSource={props.source} {...props} />;
    case FormInputType.DOCUMENT_SCAN:
    case FormInputType.DOCUMENT_SCANS:
      return <DocumentScanUploaderInput canAddFromClipboard {...props} />;
    case FormInputType.CONTRACT_SCAN:
    case FormInputType.CONTRACT_SCANS:
      return <ContractScanUploaderInput {...props} />;
    case FormInputType.EMPLOYEE_PHOTO:
    case FormInputType.EMPLOYEE_PHOTOS:
      return <EmployeePhotoUploaderInput canAddFromClipboard {...props} />;
    case FormInputType.CRM_ATTACHMENT:
    case FormInputType.CRM_ATTACHMENTS:
      return <CrmAttachmentUploaderInput canAddFromClipboard {...props} />;
    case FormInputType.NOTE_ATTACHMENT:
    case FormInputType.NOTE_ATTACHMENTS:
      return <NoteAttachmentUploaderInput canAddFromClipboard {...props} />;
    case FormInputType.CANDIDATE_RESUME:
    case FormInputType.CANDIDATE_RESUMES:
      return <CandidateResumeUploaderInput canAddFromClipboard {...props} />;
    case FormInputType.ONE_STOP_ATTACHMENT:
    case FormInputType.ONE_STOP_ATTACHMENTS:
      return <OneStopAttachmentUploaderInput canAddFromClipboard {...props} />;
    case FormInputType.LEAVE_APPLICATION_SCAN:
    case FormInputType.LEAVE_APPLICATION_SCANS:
      return <LeaveApplicationScanUploaderInput {...props} />;
    case FormInputType.FEEDBACK_ATTACHMENT:
    case FormInputType.FEEDBACK_ATTACHMENTS:
      return <FeedbackAttachmentUploaderInput canAddFromClipboard {...props} />;
    case FormInputType.EMAIL_ATTACHMENT:
      return <EmailAttachmentUploaderInput canAddFromClipboard {...props} />;
    case FormInputType.REDMINE_PROJECT:
      return <ProjectInput searchString="name" {...props} />;
    case FormInputType.WYSIWYG:
      return <WysiwygInput {...props} />;
    case FormInputType.OPPORTUNITY:
      return <OpportunityInput nameSource={props.source} {...props} />;
    case FormInputType.COMPANY_SERVICE:
      return <ServiceInput searchString="name" {...props} />;
    default:
      return null;
  }
};

export default FormInputFactory;
