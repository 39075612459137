/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem, { SidebarMenuItemProps } from 'shared/uibuilder/menu/SidebarMenuItem';

interface ProfileMenuItemProps extends Omit<SidebarMenuItemProps, 'name'> {
  linkUrl: string;
  name?: string;
}

const ProfileMenuItem = ({ linkUrl, ...props }: ProfileMenuItemProps) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Profile" icon="user-secret" {...props} />
);

ProfileMenuItem.displayName = 'Profile';

export default ProfileMenuItem;
