/* istanbul ignore file */
import TextField from '@mui/material/TextField';
import AutosuggestComponent, { ChangeEvent, RenderInputComponentProps } from 'react-autosuggest';
import React from 'react';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';
import { SearchInputLayoutProps } from './SearchInput';
import SearchIcon from '@mui/icons-material/SearchOutlined';

const shouldRenderSuggestions = (value: string) => value.trim().length === 0;
const renderSuggestion = (suggestion: string) => <div>{suggestion}</div>;
const getSuggestionValue = (suggestion: string) => suggestion;

export const DEFAULT_SEARCH_INPUT_ID = 'search-input';

const SearchInputLayout = ({
  value = '',
  label,
  suggestions,
  placeholder = '',
  inputRef = {
    current: null,
  },
  isAutoCompleteEnabled = true,
  onKeyUpCallback = () => {},
  onFocusCallback = () => {},
  onChangeCallback: parentOnChangeCallback,
  tooltipMessage = undefined,
  id = DEFAULT_SEARCH_INPUT_ID,
  width = '330px',
}: SearchInputLayoutProps) => {
  const autoComplete = isAutoCompleteEnabled ? 'on' : 'off';

  const onChangeCallback = (event: React.FormEvent<HTMLElement>, { newValue }: ChangeEvent) => {
    parentOnChangeCallback({
      target: {
        value: newValue,
      },
    });
  };

  const renderInputComponent = (inputProps: RenderInputComponentProps) => {
    // @ts-ignore
    const { key, ...props } = inputProps;
    return (
      <TextField
        {...props}
        variant="outlined"
        color="primary"
        size="medium"
        InputProps={{
          startAdornment: <SearchIcon sx={{ fontSize: 22 }} />,
          endAdornment: tooltipMessage ? <InputTooltip message={tooltipMessage} source={id} size="small" /> : null,
        }}
        sx={{ '.MuiInputBase-input': { padding: '12px 14px', height: '20px', fontSize: '1rem' } }}
      />
    );
  };

  return (
    <AutosuggestComponent
      suggestions={isAutoCompleteEnabled ? suggestions : []}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      shouldRenderSuggestions={shouldRenderSuggestions}
      onSuggestionsFetchRequested={() => {}}
      onSuggestionsClearRequested={() => {}}
      renderInputComponent={renderInputComponent}
      inputProps={{
        name: 'search',
        ref: inputRef,
        autoComplete,
        placeholder: placeholder || `Search by ${label}`,
        value,
        onKeyUp: onKeyUpCallback,
        onFocus: onFocusCallback,
        onChange: onChangeCallback,
        style: { minWidth: width },
        type: 'search',
      }}
    />
  );
};

export default SearchInputLayout;
