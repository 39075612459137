import React, { useMemo } from 'react';
import Typography from 'uibuilder/Typography';
import { FormFieldsData, useFormContext } from '../uibuilder/form/FormContext';
import { ShowContextProvider, useShowContext } from '../uibuilder/show/ShowContext';
import ShowDate from 'shared/uibuilder/ShowDate';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import { TextField } from 'shared/uibuilder/field';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import EntityHeaderField from 'shared/layout/EntityHeaderField';
import ShowData from 'shared/uibuilder/show/ShowData';
import Data from 'shared/uibuilder/Data';
import './EntityHeader.scss';

export enum HEADER_SIZES {
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
}

interface EntityHeaderLayoutProps {
  value: string;
  label: string;
  className?: string;
}

const EntityHeaderMetaFieldLayout = ({ value, label, className = '' }: EntityHeaderLayoutProps) => {
  return value && value !== EMPTY_VALUE_INDICATOR ? (
    <div className={className}>
      <i>
        {label} {value}
      </i>
    </div>
  ) : null;
};

const EntityHeaderFieldLayout = ({ value, label, className = '' }: EntityHeaderLayoutProps) => {
  return value && value !== EMPTY_VALUE_INDICATOR ? (
    <div className={className}>
      {label} {value}
    </div>
  ) : null;
};

const DEFAULT_AUTHOR = ' Leonardo DaVinci';

interface EntityHeaderProps {
  entityTitle?: React.ReactNode | ((data: Data | FormFieldsData) => React.ReactNode);
  entityTitleSource?: string;
  children?: React.ReactElement | React.ReactElement[];
  badges?: React.ReactNode | ((data: Data | FormFieldsData) => React.ReactNode);
  hasAuthor?: boolean;
  size?: HEADER_SIZES;
}

/**
 * Component of the header of the entity which displays name of the entity and meta information (creation, updating info).
 * @param entityTitle - string with name of the entity or function that receives data and returns entity name
 * @param entityTItleSource - field where entity name is stored
 * @returns {*}
 * @constructor
 */
const EntityHeader = ({
  entityTitle,
  entityTitleSource,
  children,
  badges = null,
  hasAuthor = true,
  size = HEADER_SIZES.LARGE,
}: EntityHeaderProps) => {
  const formContext = useFormContext();
  const showContext = useShowContext();

  const data = (showContext?.data.isNotEmpty() && showContext.data) || new ShowData(formContext?.data || {});

  const createdAt = data.getValueBySource('createdAt');
  const updatedAt = data.getValueBySource('updatedAt');
  const createdByName = data.getValueBySource('createdByName');
  const updatedByName = data.getValueBySource('updatedByName');

  const displayedName = useMemo(() => {
    if (typeof entityTitle === 'function') {
      return entityTitle(data);
    } else if (entityTitle) {
      return entityTitle;
    } else if (entityTitleSource) {
      return data.getValueBySource(entityTitleSource);
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityTitle]);

  const displayedBadges = typeof badges === 'function' ? badges(data) : badges;

  const EntityName = useMemo(() => {
    let EntityNameTag: keyof JSX.IntrinsicElements;

    if (size === HEADER_SIZES.LARGE) {
      EntityNameTag = 'h1';
    } else if (size === HEADER_SIZES.MEDIUM) {
      EntityNameTag = 'h2';
    } else {
      EntityNameTag = 'h3';
    }

    return (
      <Typography variant={EntityNameTag} className="entity-header__name c-title">
        {displayedName}
        <span className="entity-header__badges">{displayedBadges}</span>
      </Typography>
    );
  }, [displayedBadges, displayedName, size]);

  return (
    <ShowContextProvider
      value={{
        data,
      }}
    >
      <UiThemeOverrider overriddenLayouts={{ BaseFieldLayout: EntityHeaderMetaFieldLayout }}>
        <div>{EntityName}</div>
        <div className="entity-header__logs">
          <TextField
            label="Created"
            value={
              createdAt && (
                <>
                  <ShowDate dateUTC={createdAt} format={DATE_FORMAT.FULL} />
                  {hasAuthor && ` by ${createdByName || DEFAULT_AUTHOR}`}
                </>
              )
            }
          />
          <TextField
            label="Last modified"
            value={
              updatedAt && (
                <>
                  <ShowDate dateUTC={updatedAt} format={DATE_FORMAT.FULL} />
                  {hasAuthor && ` by ${updatedByName || DEFAULT_AUTHOR}`}
                </>
              )
            }
          />
        </div>
        {React.Children.map(children, child => {
          if (!child) return null;
          const { metaInformation } = child.props;

          if (!metaInformation) {
            return (
              <UiThemeOverrider
                overriddenLayouts={{
                  BaseFieldLayout: EntityHeaderFieldLayout,
                }}
              >
                <EntityHeaderField>{child}</EntityHeaderField>
              </UiThemeOverrider>
            );
          } else {
            return child;
          }
        })}
      </UiThemeOverrider>
    </ShowContextProvider>
  );
};

export default EntityHeader;
