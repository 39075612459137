import { useParams } from 'react-router-dom';
import useEntityUrl, { UrlParams } from 'shared/routing/useEntityUrl';
import useFeatureToggle, { Features } from 'featuretoggle';
import { ONE_STOP_ROOT_PATH } from 'oneStop/OneStopRouterHelper';
import useRoute from '../shared/routing/useRoute';

export const READ_SENT_FEEDBACKS = 'EMPLOYEE_FEEDBACK_READ_SENT_FEEDBACKS';

export const IsMyActivityEnabled = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  return isFeatureEnabled(Features.MY_ACTIVITY_TAB);
};

export const MY_ACTIVITY_ROOT_PATH = '/my-activity';
export const SENT_FEEDBACK_HISTORY = `${MY_ACTIVITY_ROOT_PATH}/feedback`;
export const ONE_STOP_HISTORY_PATH = IsMyActivityEnabled()
  ? `${MY_ACTIVITY_ROOT_PATH}/requests`
  : `${ONE_STOP_ROOT_PATH}/history`;
export const ONE_STOP_HISTORY_REQUEST_ID = 'requestId';

export const useMyActivityRoute = () => {
  const baseRoutes = useRoute({ listRoute: SENT_FEEDBACK_HISTORY });

  return {
    ...baseRoutes,
    feedbackRoute: '/feedback',
    historyRoute: `/requests/:${ONE_STOP_HISTORY_REQUEST_ID}?`,
  };
};

export const useOneStopHistoryRequestId = () => {
  const urlParams: UrlParams = useParams<Dictionary<string>>();

  return urlParams[ONE_STOP_HISTORY_REQUEST_ID];
};

export const useOneStopHistoryUrl = () => {
  const historyUrl = useEntityUrl({
    baseLink: ONE_STOP_HISTORY_PATH,
  });

  return {
    ...historyUrl,
  };
};
