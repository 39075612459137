import React from 'react';

const ALetterIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      d="M20 17.7778C20 18.3671 19.7659 18.9324 19.3491 19.3491C18.9324 19.7659 18.3671 20 17.7778 20H2.22222C1.63285 20 1.06762 19.7659 0.650874 19.3491C0.234126 18.9324 0 18.3671 0 17.7778V2.22222C0 1.63285 0.234126 1.06762 0.650874 0.650874C1.06762 0.234126 1.63285 0 2.22222 0L17.7778 0C18.3671 0 18.9324 0.234126 19.3491 0.650874C19.7659 1.06762 20 1.63285 20 2.22222V17.7778Z"
    />
    <path
      fill="white"
      d="M8.19271 5.06958C8.48549 4.27736 9.15715 3.64014 10.0355 3.64014C10.9483 3.64014 11.5866 4.24291 11.8788 5.06958L15.2549 14.4401C15.3583 14.7157 15.3927 14.9574 15.3927 15.0774C15.3927 15.749 14.8416 16.214 14.2044 16.214C13.4805 16.214 13.1188 15.8351 12.9466 15.319L12.4294 13.6996H7.64049L7.12382 15.3018C6.9516 15.8357 6.58993 16.214 5.88382 16.214C5.19493 16.214 4.60938 15.6974 4.60938 15.0085C4.60938 14.7329 4.69549 14.5262 4.72993 14.4401L8.19271 5.06958ZM8.3816 11.3229H11.6544L10.0527 6.32736H10.0183L8.3816 11.3229Z"
    />
  </svg>
);

export default ALetterIcon;
