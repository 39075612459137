import React from 'react';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import FormListTemplate from 'shared/uibuilder/form/formList/FormListTemplate';
import FormTemplate from 'shared/uibuilder/form/FormTemplate';
import { OFFICE } from '../../shared/field/OfficeField';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import OfficeDropdown from 'erp/employee/shared/input/OfficeDropdown';
import { snakeCase } from 'lodash';
import { OFFICE_NAMES } from 'erp/employee/office/officeService';
import BigFormRow from 'shared/layout/form/BigFormRow';
import UploadInputWithoutArtifactID from 'artifact/shared/input/UploadInputWithoutArtifactID';
import UploadJournalEntryBreadcrumbs from './UploadJournalEntryBreadcrumbs';
import UploadJournalEntryValidation from './UploadJournalEntryValidation';
import useUploadJournalEntry from './useUploadJournalEntry';
import { useJournalEntryUrl } from '../JournalEntryRouter';
import JournalEntryForm from '../createupdate/JournalEntryForm';

const UploadJournalEntry = () => {
  const { getListUrl } = useJournalEntryUrl();
  const {
    additionalInfoCallback,
    uploadListOfJournalEntry,
    getListJournalEntry,
    initialFormsListData,
    getValidationSchema: getEditValidationSchema,
  } = useUploadJournalEntry();

  const getValidationSchema = () => {
    return Promise.resolve(UploadJournalEntryValidation);
  };

  return initialFormsListData.length > 0 ? (
    <CreatePageLayout buttons={<></>} breadcrumbs={<UploadJournalEntryBreadcrumbs />} title="Upload Journal Entries">
      <FormListTemplate
        isBatchUpload={false}
        isBatchUploadBySize
        batchUploadSize={5}
        submitFormFunc={uploadListOfJournalEntry}
        isShowAdditionalInformation
        additionalInfoFunc={additionalInfoCallback}
        getValidationSchemaFunc={getEditValidationSchema}
        initialListData={initialFormsListData}
        afterSubmit={{
          message: 'You are awesome! Employee Journal Entries have been successfully uploaded.',
          redirect: getListUrl(),
        }}
      >
        <JournalEntryForm isDisabledOffice />
      </FormListTemplate>
    </CreatePageLayout>
  ) : (
    <FormTemplate
      initialData={{
        // TODO Now only AUSTIN_1
        office: OFFICE.AUSTIN_1,
      }}
      getValidationSchemaFunc={getValidationSchema}
      submitFormFunc={getListJournalEntry}
    >
      <CreatePageLayout breadcrumbs={<UploadJournalEntryBreadcrumbs />} title="Upload Journal Entries">
        <FormSection>
          <FormRow>
            <DateInput label="Period" source="period" dateFormat={DATE_FORMAT.API_ONLY_MONTH} />
            <OfficeDropdown
              mapResults={(office: any) => ({
                value: snakeCase(office.name).toUpperCase(),
                label: OFFICE_NAMES[office.name],
              })}
              source="office"
              // TODO Now only AUSTIN_1
              disabled
            />
          </FormRow>

          <BigFormRow>
            <UploadInputWithoutArtifactID
              maxElements={1}
              label="File"
              source="file"
              formats="xls,xlsx"
              canAddFromClipboard={false}
            />
          </BigFormRow>
        </FormSection>
      </CreatePageLayout>
    </FormTemplate>
  );
};

export default UploadJournalEntry;
