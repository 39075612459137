import SecurityContextTemplate, {
  SecurityContextCommonProps
} from 'shared/authorization/SecurityContextTemplate';
import React from 'react';
import useServiceCatalogService from 'erp/serviceCatalog/useServiceCatalogService';

const ServiceBudgetContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getBudgetPermissions } = useServiceCatalogService() as any;

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getBudgetPermissions}
      permissionToCheck={permissionToCheck}
      contextName="ServiceBudget"
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default ServiceBudgetContext;
