/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import ProjectWeeklyReportContext from 'erp/project/weeklyreports/ProjectWeeklyReportContext';
import { useProjectUrl } from 'erp/project/ProjectRouter';
import ProjectWeeklyReportList from 'erp/project/weeklyreports/List';
import ViewProjectWeeklyReport from 'erp/project/weeklyreports/show';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_PROJECT_REPORTS_LIST } from 'erp/project/weeklyreports/ProjectWeeklyReportService';
import UpdateProjectWeeklyReport from 'erp/project/weeklyreports/update';

export const REPORT_ID = 'reportId';
export const PATH = '/reports';

export const useProjectWeeklyReportRoute = () => {
  return useRoute({
    listRoute: `${PATH}`,
    singleEntityRoute: `/:${REPORT_ID}`,
  });
};

export const useProjectWeeklyReportsUrl = () => {
  const { getSingleEntityUrl } = useProjectUrl();
  const projectLink = getSingleEntityUrl();
  const listUrl = `${projectLink}${PATH}`;
  const baseUrls = useEntityUrl({
    baseLink: `${projectLink}${PATH}`,
    idSource: REPORT_ID,
  });

  const getListUrl = () => listUrl;
  const getSingleEntityLink = (reportId: string, projectId: string) => {
    return `${getSingleEntityUrl(projectId)}${PATH}/${reportId}`;
  };

  return {
    ...baseUrls,
    getListUrl,
    getSingleEntityLink,
  };
};

export const useProjectWeeklyReportId = () => {
  const { reportId } = useParams<Dictionary<string>>();

  return reportId;
};

export const READ_PROJECT_WEEKLY_REPORT = 'READ';
export const UPDATE_PROJECT_WEEKLY_REPORT = 'PARTIAL_UPDATE';
export const UPLOAD_PROJECT_WEEKLY_REPORT = 'UPLOAD_PROJECT_WEEKLY_REPORT';

const ProjectWeeklyReportRouter = () => {
  const { listRoute, singleEntityRoute, updateRoute } = useProjectWeeklyReportRoute();

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <GlobalProtectedRoute globalPermissionToCheck={READ_PROJECT_REPORTS_LIST}>
            <ProjectWeeklyReportList />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={singleEntityRoute}
        element={
          <ProjectWeeklyReportContext permissionToCheck={READ_PROJECT_WEEKLY_REPORT}>
            <ViewProjectWeeklyReport />
          </ProjectWeeklyReportContext>
        }
      />

      <Route
        path={updateRoute}
        element={
          <ProjectWeeklyReportContext permissionToCheck={UPDATE_PROJECT_WEEKLY_REPORT}>
            <UpdateProjectWeeklyReport />
          </ProjectWeeklyReportContext>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default ProjectWeeklyReportRouter;
