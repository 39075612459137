/* istanbul ignore file */
import React from 'react';
import { BASE_AMOUNT_TYPES } from 'erp/employee/finance/shared/financeService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const BaseAmountTypeDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => (
  <EnumDropdown options={BASE_AMOUNT_TYPES} placeholder="Select amount type" {...props} />
);

export default BaseAmountTypeDropdown;
