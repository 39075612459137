import useCrudService from 'shared/crud';
import { useFinanceApi, useServiceManagementApi } from 'api';
import { cloneDeep } from 'lodash';
import { useServiceCatalogId } from '../ServiceCatalogRouter';
import serviceKPIStructureValidation from './Create/serviceKPIStructureValidation';
import publishKPIValueValidation from './shared/publishKPIValueValidation';
import updateKPIValueValidation from './shared/updateKPIValueValidation';

export const KPI_STRUCTURE_API_PATH = '/kpi-structure';

export const SERVICE_MANAGEMENT_KPI_VALUE_CREATE_KPI_VALUE = 'SERVICE_MANAGEMENT_KPI_VALUE_CREATE_KPI_VALUE';
export const KPI_STRUCTURE_READ = 'SERVICE_MANAGEMENT_KPI_STRUCTURE_LIST_GET_ALL_KPI_STRUCTURE';
export const KPI_VALUES_READ_LIST = 'SERVICE_MANAGEMENT_KPI_VALUE_LIST_READ_KPI_VALUE_LIST';
export const KPI_VALUES_UPDATE = 'SERVICE_MANAGEMENT_KPI_VALUE_UPDATE_KPI_VALUE';
export const KPI_STRUCTURE_ADD = 'SERVICE_MANAGEMENT_KPI_STRUCTURE_CREATE_KPI_STRUCTURE';
export const KPI_STRUCTURE_UPDATE = 'SERVICE_MANAGEMENT_KPI_STRUCTURE_UPDATE_KPI_STRUCTURE';

export const KPI_TYPES = {
  UNITS: 'UNITS',
  PERCENTAGE: 'PERCENTAGE',
  USD: 'USD',
};

export const KPI_TYPES_UNIT_OPTIONS = {
  [KPI_TYPES.UNITS]: 'units',
  [KPI_TYPES.USD]: '$',
  [KPI_TYPES.PERCENTAGE]: '%',
};

export const KPI_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const KPI_STATUS_OPTIONS = {
  [KPI_STATUS.ACTIVE]: 'Active',
  [KPI_STATUS.INACTIVE]: 'Inactive',
};

type KPIParameters = {
  id: number;
  name: string;
  type: string;
  createdAt: string;
  createdBy: string;
};

type KPIDataApi = {
  createdAt: string;
  createdBy: string;
  id: number;
  kpiParameters: KPIParameters[];
  serviceId: string;
  effectiveDate: string;
  status: string;
  updatedAt: string;
  updatedBy: string;
};

type KPIDataForm = {
  id: number;
  serviceId: string;
  timelineId: number;
  effectiveDate: string;
  status: string;

  createdById: string;
  updatedById: string;

  kpiParameters: Omit<KPIParameters, 'id, createdAt, createdBy'>[];
};

export type PublishValueType = {
  period: string;
  value: StringOrNumber;
  kpiParameterId: StringOrNumber;
  kpiStructureId: StringOrNumber;
};

export type UpdateValueType = {
  value: StringOrNumber;
  kpiValueId: number | null;
};

export type KpiValueItem = {
  id: number;
  kpiParameterId: number;
  kpiParameterName: string;
  kpiType: string;
  kpiStructureId: number;
  value: string;
  period: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string | null;
  updatedAt: string | null;
};

export type KpiParameter = {
  id: number;
  name: string;
  type: string;
  value: string | null;
  kpiValueId: number;
  createdAt: string;
  createdBy: string;
};

type ActiveStructureResponse = {
  id: number;
  serviceId: string;
  kpiParameters: KpiParameter[];
  effectiveDate: string;
  status: string;
  createdBy: string;
  createdAt: string;
  updatedBy: string | null;
  updatedAt: string | null;
};

type FinanceResponse = {
  id: string;
  value: number;
};
const useKPIService = () => {
  const serviceCatalogId = useServiceCatalogId();
  const { sendPostRequest: sendPostServiceManagement } = useServiceManagementApi();
  const { sendGetRequest: sendGetServiceManagement } = useServiceManagementApi();
  const { sendPutRequest: sendPutServiceManagement } = useServiceManagementApi();
  const { sendPostRequest: sendPostFinance } = useFinanceApi();

  const {
    search: baseSearchStructure,
    create: baseCreateStructure,
    update: baseUpdateStructure,
  } = useCrudService({
    singleResourceUrl: `${KPI_STRUCTURE_API_PATH}/:id`,
    listResourceUrl: KPI_STRUCTURE_API_PATH,
    apiService: useServiceManagementApi,
  });

  const kpiFormDataMapping = (data: KPIDataApi): KPIDataForm => {
    return {
      ...data,
      createdById: data.createdBy,
      timelineId: data.id,
      updatedById: data.updatedBy,
    };
  };

  const searchStructure = async (serviceId: string, request: any) => {
    const searchParams = cloneDeep(request);

    searchParams.filter = {
      ...searchParams.filter,
      serviceId: {
        eq: serviceId,
      },
    };

    const response = await baseSearchStructure(searchParams);

    return {
      ...response,
      result: response.result.map(kpiFormDataMapping),
    };
  };

  const kpiMappingForApi = (formData: KPIDataForm) => {
    const kpiParameters = formData?.kpiParameters
      ? formData?.kpiParameters?.map((item: Omit<KPIParameters, 'id, createdAt, createdBy'>) => ({
          name: item.name,
          type: item.type,
        }))
      : [];

    return {
      serviceId: formData.serviceId,
      effectiveDate: formData.effectiveDate,
      kpiParameters,
    };
  };

  const createStructure = async (serviceId: string, request: any) => {
    const params = kpiMappingForApi({ serviceId, ...request });
    const response = await baseCreateStructure(params);
    return kpiFormDataMapping(response);
  };

  const updateStructure = async (entityId: string, serviceId: string, request: any) => {
    const params = kpiMappingForApi({ serviceId, ...request });

    const response = await baseUpdateStructure(entityId, params);

    return kpiFormDataMapping(response);
  };

  const getActiveStructure = async (serviceId: string, searchRequest: any) => {
    try {
      const date = searchRequest.filter.period.eq;

      const response = await sendGetServiceManagement(`/kpi-structure/${serviceId}/active?date=${date}`);
      const activeStructure: ActiveStructureResponse = await response.json();

      const ids = activeStructure.kpiParameters.map(param => param.value).filter(value => value !== null);

      const financePublishResponse = await sendPostFinance('/kpi-value/search', { ids });
      const financeData: FinanceResponse[] = await financePublishResponse.json();

      const updatedKpiParameters = activeStructure.kpiParameters.map(param => {
        const financeItem = financeData.find(finance => finance.id === param.value);
        return {
          ...param,
          value: financeItem ? financeItem.value : param.value,
        };
      });

      const result = [
        {
          ...activeStructure,
          kpiParameters: updatedKpiParameters,
        },
      ];

      return { result, totalElements: result.length };
    } catch {
      return { result: [], totalElements: 0 };
    }
  };

  const publishKPIParams = async (data: PublishValueType) => {
    const financePublishResponse = await sendPostFinance('/kpi-value', { value: data.value });
    const response = await financePublishResponse.json();

    const serviceMenagePublishResponse = await sendPostServiceManagement('/kpi-value', {
      period: data.period,
      value: response.id,
      kpiParameterId: data.kpiParameterId,
      kpiStructureId: data.kpiStructureId,
    });
    return serviceMenagePublishResponse.json();
  };

  const updateKPIValue = async (data: UpdateValueType) => {
    const financePublishResponse = await sendPostFinance('/kpi-value', { value: data.value });
    const response = await financePublishResponse.json();

    const serviceMenagePublishResponse = await sendPutServiceManagement(`/kpi-value/${data.kpiValueId}`, {
      value: response.id,
    });

    return serviceMenagePublishResponse.json();
  };

  const searchKPIValues = async (serviceId: String, searchRequest: any) => {
    const fromDate = searchRequest.filter.period.ge;
    const toDate = searchRequest.filter.period.le;

    const searchKpiResponse = await sendPostServiceManagement(`/kpi-value/${serviceId}/search`, {
      fromDate,
      toDate,
    });

    const kpiData: KpiValueItem[] = await searchKpiResponse.json();

    const ids = kpiData.map((item: KpiValueItem) => item.value);

    const financePublishResponse = await sendPostFinance('/kpi-value/search', { ids });
    const financeData: FinanceResponse[] = await financePublishResponse.json();

    const updatedKpiData = kpiData.map((kpiItem: KpiValueItem) => {
      const financeItem = financeData.find(finance => finance.id === kpiItem.value);
      return {
        ...kpiItem,
        value: financeItem ? financeItem.value : kpiItem.value,
      };
    });

    return { result: updatedKpiData, totalElements: updatedKpiData.length };
  };

  const getValidationSchema = () => Promise.resolve(serviceKPIStructureValidation);
  const getPublishValidationSchema = () => Promise.resolve(publishKPIValueValidation);
  const getUpdateValidationSchema = () => Promise.resolve(updateKPIValueValidation);

  return {
    serviceCatalogId,
    searchStructure,
    createStructure,
    getValidationSchema,
    updateStructure,
    publishKPIParams,
    getPublishValidationSchema,
    searchKPIValues,
    getActiveStructure,
    updateKPIValue,
    getUpdateValidationSchema,
  };
};

export default useKPIService;
