/* istanbul ignore file */
import { useParams } from 'react-router-dom';
import useServiceService, { DELETE_SERVICE } from 'erp/service/shared/serviceService';
import { useServiceUrl } from 'erp/service/ServiceRouter';
import DeleteButton from 'uibuilder/button/DeleteButton';
import React from 'react';

const deleteMessage = 'Do you really want to delete this service?';

const DeleteServiceButton = () => {
  const { id: serviceId } = useParams<Dictionary<string>>();
  const { deleteById } = useServiceService();
  const { getListUrl } = useServiceUrl();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(serviceId!)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Service has been successfully deleted.',
        errorMessage: `Can't delete Service`,
      }}
      permissionToCheck={DELETE_SERVICE}
      sx={{ marginLeft: '12px' }}
    />
  );
};

export default DeleteServiceButton;
