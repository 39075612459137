/* istanbul ignore file */
import CreateNoteForm from 'erp/employee/Timeline/note/form/CreateNoteForm';
import UpdateNoteForm from 'erp/employee/Timeline/note/form/UpdateNoteForm';
import EmployeeNoteTimelineRecordLayout from 'erp/employee/Timeline/note/shared/layout/EmployeeNoteTimelineRecordLayout';
import { CREATE_EMPLOYEE_NOTE } from 'erp/employee/Timeline/note/employeeNoteService';

const useEmployeeNoteItem = () => ({
  AddForm: CreateNoteForm,
  UpdateForm: UpdateNoteForm,
  isApplicable: (item: { note: any }) => item.note,
  source: 'note',
  urlPostfix: 'notes',
  RecordLayout: EmployeeNoteTimelineRecordLayout,
  name: 'Note',
  permissionToCheck: CREATE_EMPLOYEE_NOTE,
});

export default useEmployeeNoteItem;
