/* istanbul ignore file */
import React from 'react';
import { useOneStopRequestsUrl } from 'oneStop/OneStopRouterHelper';
import { Navigate } from 'react-router-dom';
import { useSearchParametersString } from 'shared/routing/helper';

const OneStopRequestRedirect = () => {
  const { getSingleEntityUrl } = useOneStopRequestsUrl();
  const params = useSearchParametersString();

  const url = `${getSingleEntityUrl()}${params}`;
  return <Navigate to={url} replace />;
};

export default OneStopRequestRedirect;
