/* istanbul ignore file */
// libs
import React, { ReactElement } from 'react';
import classnames from 'classnames';
import Paper from 'uibuilder/Paper';
import Box from 'uibuilder/Box';
import BreadcrumbsWithMenu from 'shared/layout/BreadcrumbsWithMenu';
// components
import Message from 'shared/message/Message';
// context
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
// services
import { getVisibility } from 'shared/uibuilder/helper';

export interface ShowPageLayoutWithoutMenuProps {
  breadcrumbs?: ReactElement;
  children: ReactElement | ReactElement[];
  entityHeader?: Nullable<ReactElement>;
  menu?: ReactElement;
  buttons?: ReactElement[];
  headerButtons?: ReactElement[];
  hasSeparateSections?: boolean;
}

interface ShowPageLayoutProps extends ShowPageLayoutWithoutMenuProps {
  menu?: ReactElement;
}

const getButtons = (data: any, buttons?: ReactElement[]) =>
  buttons &&
  buttons.map(button => {
    const { isVisible: isVisibleProp } = button.props;

    const isVisible = getVisibility(data, isVisibleProp);

    return isVisible ? button : null;
  });

const ShowPageLayout = ({
  breadcrumbs,
  menu,
  buttons,
  children,
  entityHeader,
  headerButtons,
  hasSeparateSections,
}: ShowPageLayoutProps) => {
  const { data } = useShowContext();

  return (
    <Paper className="page-content view-single-entry__wrapper" elevation={0}>
      <BreadcrumbsWithMenu breadcrumbs={breadcrumbs} menu={menu} />
      {(entityHeader || headerButtons) && (
        <div className="show-page__header-wrapper entity-header__wrapper">
          <div className="show-page__entity-header-wrapper">{entityHeader}</div>
          <div className="top-btns">{getButtons(data, headerButtons)}</div>
        </div>
      )}
      <div className="show-page__content-wrapper">
        {menu && (
          <Box className="show-page__menu-wrapper" sx={{ display: { xs: 'none', sm: 'block' } }}>
            {menu}
          </Box>
        )}

        <div
          className={classnames('show-page__content-inner', {
            'show-page__content-inner--without-wrapper': hasSeparateSections,
          })}
        >
          <div className="alerts__wrapper">
            <Message />
          </div>
          {buttons && (
            <div className="top-btns__wrapper">
              <div className="top-btns">{getButtons(data, buttons)}</div>
            </div>
          )}
          {React.Children.map(children, child => {
            const { isVisible: isVisibleProp } = child.props;

            const isVisible = getVisibility(data, isVisibleProp);

            return isVisible ? child : null;
          })}
        </div>
      </div>
    </Paper>
  );
};

export default ShowPageLayout;
