/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { IS_PHYSICAL_OPTION } from '../leaveService';
import { FieldProps } from 'shared/uibuilder/field';

const SignatureOptionField = (props: FieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);

  return <EnumField label={getLabel()} value={getValue()} options={IS_PHYSICAL_OPTION} />;
};

export default SignatureOptionField;
