/* istanbul ignore file */
import React from 'react';
import { PrepopulationProvider } from 'erp/employee/prepopulation/PrepopulationContext';
import usePrepopulation from 'erp/employee/prepopulation/prepopulationHelper';

const Prepopulation = ({ children, mapResponse }: { children?: React.ReactNode; mapResponse?: any }) => {
  const contextValue: any = usePrepopulation({ mapResponse });

  return <PrepopulationProvider value={contextValue}>{children}</PrepopulationProvider>;
};

export default Prepopulation;
