import EqCondition, { EQ_CONDITION_TYPE } from 'shared/uibuilder/list/filter/condition/EqCondition';
import React, { useEffect, useState } from 'react';
import { FilterCondition, useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DEFAULT_TIMEZONE, useDateTimeService } from 'shared/uibuilder/dateService';
import momentTimezone from 'moment-timezone';
import DateTimeInput from 'shared/uibuilder/form/input/DateTimeInput';

const FILTER_ERROR =
  'Please, choose the correct date and time at which you want to generate the snapshot. This date and time should be in the past.';

type AuditDateFilterProps = {
  source: string;
};

const AuditDateFilter = ({ source }: AuditDateFilterProps) => {
  const [timezone, setTimezone] = useState<string>(DEFAULT_TIMEZONE);
  const { filters, getFilterErrors, setFilterErrors } = useFilterContext();
  const { getToday } = useDateService();
  const { getTimezone } = useDateTimeService();

  useEffect(() => {
    (async () => {
      let userTimezone = null;
      if (getTimezone) {
        userTimezone = await getTimezone();
      }
      if (userTimezone) {
        setTimezone(userTimezone);
      }
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const maxDate = getToday();

  const validateAndShowErrorMessages = () => {
    const inputValue = filters && filters[source] && filters[source][EQ_CONDITION_TYPE];
    const date = momentTimezone.tz(inputValue, timezone);
    const isValid = inputValue && date.isValid() && date.isSameOrBefore(maxDate);
    const showErrors = !isValid;
    if (showErrors) {
      setFilterErrors(source, EQ_CONDITION_TYPE as FilterCondition, [FILTER_ERROR]);
    } else {
      setFilterErrors(source, EQ_CONDITION_TYPE as FilterCondition, []);
    }
    return showErrors;
  };

  const getErrors = () => {
    return getFilterErrors(source, EQ_CONDITION_TYPE as FilterCondition);
  };

  return (
    <EqCondition source={source}>
      <DateTimeInput
        isRequired
        label="Date and Time of the snapshot"
        validationCallback={validateAndShowErrorMessages}
        errorMessages={getErrors()}
        maxDate={maxDate}
        source={source}
      />
    </EqCondition>
  );
};

export default AuditDateFilter;
