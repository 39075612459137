import React from 'react';
import CreateButton, { CreateButtonProps } from 'uibuilder/button/CreateButton';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';
import { CREATE_OPPORTUNITY } from 'crm/opportunity/shared/opportunityService';

const CreateOpportunityButton = (props: Omit<CreateButtonProps, 'url'>) => {
  const { getCreateUrl } = useOpportunityUrl();

  return (
    <CreateButton url={getCreateUrl()} permissionToCheck={CREATE_OPPORTUNITY} label="Create Opportunity" {...props} />
  );
};

export default CreateOpportunityButton;
