/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import useServiceCatalogService from 'erp/serviceCatalog/useServiceCatalogService';
import { useServiceCatalogId } from 'erp/serviceCatalog/ServiceCatalogRouter';
import ViewServiceCatalogPageContent from 'erp/serviceCatalog/Show/ViewServiceCatalogPageContent';

const ServiceCatalogViewPage = () => {
  const { getById } = useServiceCatalogService();
  const id = useServiceCatalogId();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ViewServiceCatalogPageContent />
    </Show>
  );
};

export default ServiceCatalogViewPage;
