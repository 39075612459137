import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE404 } from 'shared/api/const';
import { CreateForm } from 'shared/uibuilder/form';
import EF1Form from 'externalform/ef1/create/EF1Form';
import useEF1Service from 'externalform/ef1/ef1Service';
import { useExternalFormId } from 'externalform/ExternalFormRouter';
import EF1CreatePageLayout from 'externalform/ef1/create/EF1CreatePageLayout';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { INITIAL_FORM_DATA } from 'externalform/baseExternalFormService';
import { isPolishOffice } from 'erp/employee/office/officeService';

const FORM_SUBMISSION_ERROR = "An error occured. Form wasn't submitted.";

const EF1CreatePage = () => {
  const navigate = useNavigate();
  const id = useExternalFormId()!;
  const { addMessage, cleanMessage } = useMessageService();
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isErrorButtonVisible, setIsErrorButtonVisible] = useState(false);

  const hideForm = () => {
    setIsFormVisible(false);
  };

  const { getDataById, setData, getValidationSchema, validateAlias } = useEF1Service({ hideForm });

  const isValidFormAlias = validateAlias(id);

  const isNotPolishOfficeHandler = (formData: any) => {
    return !isPolishOffice(formData.additionalInfo?.office);
  };

  if (!isValidFormAlias) {
    navigate(ROUTE404);

    return null;
  }

  const returnToForm = () => {
    cleanMessage();
    setIsFormVisible(true);
    setIsErrorButtonVisible(false);
  };

  const handleErrorsOnSubmit = () => {
    addMessage(new ErrorMessage(FORM_SUBMISSION_ERROR));
    setIsErrorButtonVisible(true);
    setIsFormVisible(false);
  };

  return (
    <CreateForm
      initialData={INITIAL_FORM_DATA}
      getDataFunc={() => getDataById(id)}
      getValidationSchemaFunc={getValidationSchema}
      submitFormFunc={(data: any) => setData(id, data)}
      afterSubmit={{
        execute: hideForm,
        message: 'You are awesome! Form was successfully submitted.',
      }}
      handleErrorsOnSubmit={handleErrorsOnSubmit}
    >
      <EF1CreatePageLayout
        isFormVisible={isFormVisible}
        isErrorButtonVisible={isErrorButtonVisible}
        returnToForm={returnToForm}
      >
        <EF1Form isNotPolishOffice={isNotPolishOfficeHandler} />
      </EF1CreatePageLayout>
    </CreateForm>
  );
};

export default EF1CreatePage;
