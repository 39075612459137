import useExpenseService, { EXPENSE_TYPES } from '../useExpenseService';
import { useState } from 'react';
import { CURRENCIES } from 'shared/uibuilder/field/CurrencyField';
import { CURRENCY_TYPES } from 'erp/employee/finance/shared/financeService';
import { OFFICE } from '../../shared/field/OfficeField';
import { LEAVE_TYPES_ALIASES } from 'erp/leave/shared/leaveService';

interface Compensation {
  amount: number;
  currency: string;
}

interface EmployeeExpenseType {
  id: number;
  employeeAlias: string;
  employeeName: string;
  type: keyof typeof EXPENSE_TYPES;
  leaveId?: string;
  subtype?: keyof typeof LEAVE_TYPES_ALIASES;
  office: keyof typeof OFFICE;
  registrationDate: string;
  title: string;
  compensation: Compensation;
  description: string;
}

const useUploadExpenses = () => {
  const [initialFormsListData, setInitialFormsListData] = useState<any[]>([]);

  const { uploadExpenses, submitListOfExpenses, getValidationSchema } = useExpenseService();

  const getListExpenses = async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file[0].file);
    formData.append('office', data.office);
    formData.append('period', data.period);
    const response: EmployeeExpenseType[] = await uploadExpenses(formData);

    setInitialFormsListData(
      response.map(form => {
        return {
          ...form,
          // TODO Now only KRAKOW_1. Office for expense. Same as in upload form.
          office: OFFICE.KRAKOW_1,
          compensation: {
            amount: form.compensation.amount,
            currency: CURRENCY_TYPES.PLN,
          },
        };
      }),
    );

    return response;
  };

  // TODO uncomment EmployeeExpenseType[] when the backend is ready to handle batch sending with the array object
  const uploadExpensesForms = async (data: EmployeeExpenseType) => {
    return submitListOfExpenses(data);
  };

  const additionalInfoCallback = (formsData: any[]) => {
    const totalAmount = formsData.reduce((sum, { data: { compensation = {} } = {} }) => {
      return sum + +(compensation?.amount ?? 0);
    }, 0);

    // TODO Now only PLN
    return `Total amount: ${totalAmount} ${CURRENCIES[CURRENCY_TYPES.PLN]}`;
  };

  return {
    uploadExpensesForms,
    initialFormsListData,
    getListExpenses,
    getValidationSchema,
    additionalInfoCallback,
  };
};

export default useUploadExpenses;
