/* istanbul ignore file */
import React from 'react';
import { FINANCE_TYPES } from 'erp/employee/finance/shared/financeService';
import EnumDropdown, { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';

const FinanceTypeDropdown = (props: Omit<EnumDropdownProps, 'options'>) => {
  const options = { ...FINANCE_TYPES };

  return <EnumDropdown options={options} placeholder="Select type" {...props} />;
};

export default FinanceTypeDropdown;
