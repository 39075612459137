/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import DayOnDemandsBalanceField from 'erp/employee/leavebalance/BalancesList/field/DayOnDemandsBalanceField';
import { isEmployeeContractByType } from 'erp/employee/contracts/shared/employeeContractsService';
import useAsyncValue from 'shared/asyncHelper';
import { isPolishOffice } from 'erp/employee/office/officeService';
import useEmployeeService from 'erp/employee/employeeService';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
// styles
import './TotalLeavesBalanceColumnLayout.scss';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useDateService } from 'shared/uibuilder/dateService';

type TotalLeavesBalanceColumnLayoutProps = {
  label?: string;
  totalField: React.ReactElement;
  useTooltip?: boolean;
  dayOnDemandField?: React.ReactElement | number;
  leavesField: React.ReactElement;
  [p: string]: any;
};

const TotalLeavesBalanceColumnLayout: React.FC<TotalLeavesBalanceColumnLayoutProps> = ({
  totalField,
  useTooltip = false,
  dayOnDemandField = 0,
  leavesField,
}) => {
  const { isCurrentTimeInPeriod } = useDateService();
  const { getValue } = useFieldHelper(totalField.props);
  const leaveData = useShowContext()?.data;
  const totalValue = getValue();
  const totalFieldComponent = useTooltip ? (
    <b title={totalValue.endsWith('w.d.') ? 'Working Days' : 'Calendar Days'}>{totalField}</b>
  ) : (
    <b>{totalField}</b>
  );

  const id = useEmployeeId();
  const { getById } = useEmployeeService();
  const employee = useAsyncValue(async () => getById(id!));
  const isPolishContractEmployee =
    employee && isPolishOffice(String(employee.officeId)) && isEmployeeContractByType(String(employee.contractType));
  const isCurrentYearPeriod = isCurrentTimeInPeriod(leaveData.startDate, leaveData.endDate);

  return (
    <>
      {totalFieldComponent}
      <div className="balances-leaves-column">{leavesField}</div>
      {isPolishContractEmployee && isCurrentYearPeriod && (
        <DayOnDemandsBalanceField useTooltip={useTooltip} dayOnDemandField={dayOnDemandField} />
      )}
    </>
  );
};

export default TotalLeavesBalanceColumnLayout;
