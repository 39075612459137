/* istanbul ignore file */
import React from 'react';
import useMessageEventService, { InitialDataType } from 'camunda/shared/messageEventService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import FormSection from 'shared/layout/form/FormSection';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { CamundaInputProps } from 'shared/uibuilder/form/input/CamundaInput';
import ModalFormButton from 'shared/uibuilder/form/ModalFormButton';
import { getVisibility } from 'shared/uibuilder/helper';
import Data from 'shared/uibuilder/Data';
import { AfterSubmitProps } from 'shared/uibuilder/form/formHelper';
import { ValidationSchema } from 'validation-schema-library';
import useSubmit from 'shared/useSubmit';

type ExecuteType =
  | ((response: object) => void)
  | ((response: object) => Promise<any>)
  | ((response: object) => Promise<void>)
  | (() => void);

interface MessageEventButtonProps {
  messageEventName: string;
  getValidationSchemaFunc?: () => Promise<ValidationSchema>;
  isVisible?: boolean | ((data: Data | object | ProcessInstance) => boolean);
  afterSubmit?: AfterSubmitProps<ExecuteType>;
  label: string;
  formFields?: React.ReactElement<CamundaInputProps> | React.ReactElement<CamundaInputProps>[];
  initialData?: InitialDataType<string>;
}

interface ProcessInstance {
  processInstanceId: string;
}

const MessageEventButton = ({
  getValidationSchemaFunc,
  messageEventName,
  label,
  isVisible = true,
  afterSubmit,
  formFields = [],
  initialData = {},
}: MessageEventButtonProps) => {
  const { getData } = useFieldHelper();
  const { publishMessage, getVariables } = useMessageEventService();
  const data = getData() as ProcessInstance;
  const isButtonVisible = getVisibility(data, isVisible as any);

  const submitFormFunc = async (formFieldsData: FormFieldsData): Promise<object> => {
    const variables = await getVariables({ formFields, formFieldsData, initialData });
    return publishMessage({
      processInstanceId,
      request: { eventName: messageEventName, variables },
    });
  };

  const { message, redirect, execute } = afterSubmit || {};
  const { onSubmit } = useSubmit({
    afterSubmit: {
      successMessage: message,
      redirectUrl: redirect,
      execute: execute as any,
      raiseError: true,
    },
    submitMethod: submitFormFunc,
  });
  const { processInstanceId } = data;

  let result;

  if (isButtonVisible) {
    result = (
      <ModalFormButton
        getValidationSchemaFunc={getValidationSchemaFunc}
        outline
        handleDirty={false}
        label={label}
        formTitle={label}
        submitFormFunc={onSubmit as any}
      >
        <FormSection>{formFields}</FormSection>
      </ModalFormButton>
    );
  }

  return <>{result}</>;
};

export default MessageEventButton;
