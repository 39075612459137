/* istanbul ignore file */
import React from 'react';
import usePurchaseOrderService, {
  REJECT_PURCHASE_ORDER,
  PURCHASE_ORDER_STATUSES,
} from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import { useParams } from 'react-router-dom';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import RejectButton from 'financialAnalytic/shared/button/RejectButton';

const deleteMessage = 'Do you really want to reject this purchase order?';

const RejectPurchaseOrderButton = ({ statusSource }: { statusSource: string }) => {
  const { id } = useParams<Dictionary<string>>();
  const { rejectPurchaseOrder, invalidateCache } = usePurchaseOrderService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();

  if (status !== PURCHASE_ORDER_STATUSES.PENDING && status !== PURCHASE_ORDER_STATUSES.APPROVED) {
    return null;
  }

  return (
    <RejectButton
      displayMessage={deleteMessage}
      deleteMethod={rejectReason => rejectPurchaseOrder(id!, rejectReason)}
      successMessage="The Purchase Order has been successfully rejected."
      errorMessage="Can't reject purchase order."
      permissionToCheck={REJECT_PURCHASE_ORDER}
      execute={() => {
        invalidateCache();
      }}
    />
  );
};

export default RejectPurchaseOrderButton;
