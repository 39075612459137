import React from 'react';
import useDropdownHelper, { DefaultDropdownProps, Option } from 'shared/uibuilder/form/input/dropdownHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { CommonInputLayoutProps } from 'shared/uibuilder/form/input';
import { SelectComponentsConfig } from 'react-select';

export interface DefaultDropdownLayoutProps<OptionsType = Option[]> extends CommonInputLayoutProps<any> {
  options?: OptionsType;
  multiple?: boolean;
  filterOption?: (option: { data: Option }, rawInput: string) => boolean;
  formatOptionLabel?: (
    data: Option,
    formatOptionLabelMeta: {
      context: 'menu' | 'value';
    },
  ) => React.ReactNode;
  styles?: Dictionary<any>;
  components?: SelectComponentsConfig<any, any, any>;
  isLoading?: boolean;
  disabled?: any;
  [key: string]: any;
}

export type DefaultDropdownLayoutType = ReactComponent<DefaultDropdownLayoutProps>;

const Dropdown = (props: DefaultDropdownProps) => {
  const { DropdownLayout } = useUiTheme<DefaultDropdownLayoutType>();
  const dropdownHelper = useDropdownHelper(props);

  return (
    <DropdownLayout
      {...props}
      isVisible={dropdownHelper.isVisible()}
      label={dropdownHelper.getLabel()}
      source={dropdownHelper.getSource()}
      onChangeCallback={
        dropdownHelper.isMultiple()
          ? dropdownHelper.getMultipleOnChangeCallback()
          : dropdownHelper.getOnChangeCallback()
      }
      onBlurCallback={dropdownHelper.getValidationCallback()}
      onFocusCallback={dropdownHelper.getClearValidationCallback()}
      value={dropdownHelper.getValue()}
      errorMessages={dropdownHelper.getErrorMessages()}
      isRequired={dropdownHelper.getIsRequired()}
      options={dropdownHelper.getSelectOptions()}
      multiple={dropdownHelper.isMultiple()}
      disabled={dropdownHelper.getIsDisabled()}
      placeholder={dropdownHelper.getPlaceholder()}
      tooltip={dropdownHelper.getTooltip()}
      helpText={dropdownHelper.getHelpText()}
      className={dropdownHelper.getClassName()}
    />
  );
};

export default Dropdown;
