/* istanbul ignore file */
import React from 'react';
import { EnumField, FieldProps } from 'shared/uibuilder/field';
import { ACCOUNT_TYPES } from 'crm/account/accountService';

const AccountTypeField = (props: FieldProps) => {
  return <EnumField {...props} options={ACCOUNT_TYPES} />;
};

export default AccountTypeField;
