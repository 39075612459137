import React from 'react';
import { useNavigate } from 'react-router-dom';
import SpecializationQualificationModel from './SpecializationQualificationModel';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import QualificationMenu from 'erp/qualification/model/shared/QualificationMenu';
import { useSpecializationId, QM_PATH } from 'erp/qualification/model/QualificationModelRouter';

const QualificationModel = ({ source }: { source: string }) => {
  const { getValue } = useFieldHelper<any[]>({ source });
  const specializations = getValue() || [];
  const navigate = useNavigate();
  const specializationId = useSpecializationId();
  const currentSpecialization = specializations.find(({ id }) => String(id) === String(specializationId));

  if (specializationId && !currentSpecialization) {
    navigate(QM_PATH);
  }

  return (
    <>
      <QualificationMenu specializations={specializations} />
      <SpecializationQualificationModel data={currentSpecialization || specializations[0]} />
    </>
  );
};

export default QualificationModel;
