import React, { ReactElement } from 'react';
import Button from 'uibuilder/button/Button';
import 'shared/uibuilder/form/input/CopyableInput/index.scss';
import VerticalCopyableInput from 'shared/uibuilder/form/input/VerticalCopyableInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';
import { get, set } from 'lodash';

interface CopyableInputProps {
  source: string;
  buttonText?: string;
  copySource: any;
  copySourceList: string[];
  tooltipMessage?: string;
  className?: TypeOrFunction<string>;
  children: ReactElement;
  label?: string | JSX.Element;
}

const CopyableInput = ({
  buttonText,
  copySource,
  copySourceList,
  tooltipMessage,
  children,
  ...props
}: CopyableInputProps) => {
  const { getSource, getLabel, getRawOnChangeCallback } = useInputHelper(props);
  const { data } = useFormContext();
  const rawOnchangeCallback = getRawOnChangeCallback();
  const source = getSource();

  const setValues = () => {
    const updateObj = { ...data };

    copySourceList.forEach(key => {
      const copiedValue = get(data, `${copySource}.${key}`);
      set(updateObj, `${source}.${key}`, copiedValue);
    });

    if (rawOnchangeCallback) {
      rawOnchangeCallback(updateObj);
    }
  };

  return (
    <>
      <div className="copyable-header">
        <h3>{getLabel() as string}</h3>

        <Button
          size="small"
          variant="text"
          onClick={setValues}
          sx={{ transform: 'translateY(1px)', marginRight: '10px' }}
        >
          {buttonText}
        </Button>
        {tooltipMessage && <InputTooltip size="small" message={tooltipMessage} source={source} />}
      </div>
      <VerticalCopyableInput input={children} />
    </>
  );
};

export default CopyableInput;
