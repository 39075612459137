import React, { useEffect, useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import { Button } from 'uibuilder/button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { RadioButtonGroup } from 'shared/uibuilder/form/input';
import PrepopulateFormDataLayout from 'erp/employee/prepopulation/PrepopulateFormDataLayout';
import { usePrepopulationContext, PrepopulationForm } from 'erp/employee/prepopulation/PrepopulationContext';

type CallbackData = {
  [source: string]: any;
};

type PrepopulateButtonProps = {
  scrollToPrepopulatedInputs?: () => void;
};

const PrepopulateButton = ({ scrollToPrepopulatedInputs }: PrepopulateButtonProps) => {
  const { forms = [], loadForm, loading } = usePrepopulationContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const isSingleFormAvailable = forms.length === 1;
  const initialSelectedFormId = forms.length >= 1 ? forms[0].id : null;
  const [selectedFormId, setSelectedFormId] = useState<Nullable<number | string>>(null);

  useEffect(() => {
    setSelectedFormId(initialSelectedFormId);
  }, [forms, initialSelectedFormId]);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedFormId(initialSelectedFormId);
  };

  const prepopulateForm = async () => {
    await loadForm(selectedFormId);
    setModalOpen(false);
    setSelectedFormId(initialSelectedFormId);

    if (scrollToPrepopulatedInputs) {
      scrollToPrepopulatedInputs();
    }
  };

  const getOptionLabel = (form: PrepopulationForm) => {
    return (
      <ListItemButton
        key={form.id}
        selected={form.id === selectedFormId}
        onClick={e => {
          e.preventDefault();
          setSelectedFormId(form.id);
        }}
        className="external-form"
      >
        <PrepopulateFormDataLayout form={form} />
      </ListItemButton>
    );
  };

  const getOptions = () =>
    forms.map(form => ({
      value: form.id,
      label: getOptionLabel(form),
    }));

  const handleChange = (data: CallbackData) => {
    setSelectedFormId(data.form);
  };

  return (
    <div className="prepopulate-container">
      {forms && forms.length ? (
        <Button onClick={openModal} sx={{ marginLeft: '10px' }}>
          Prepopulate form
        </Button>
      ) : null}
      <ModalWindow
        isOpen={isModalOpen}
        title={isSingleFormAvailable ? 'Form prepopulation' : 'Choose form for prepopulation'}
        buttons={[
          <Button
            key="populate-button"
            onClick={prepopulateForm}
            disabled={!selectedFormId || loading}
            id="prepopulate-button"
          >
            Populate
          </Button>,
        ]}
        onToggle={closeModal}
      >
        <p className="mb-2">Here is the information available for prepopulating this form:</p>
        {isSingleFormAvailable ? (
          <PrepopulateFormDataLayout form={forms[0]} />
        ) : (
          <RadioButtonGroup
            options={getOptions()}
            value={selectedFormId}
            className="forms-wrapper"
            source="form"
            label=""
            onChangeCallback={handleChange}
            isColumnDirection
          />
        )}
      </ModalWindow>
    </div>
  );
};

export default PrepopulateButton;
