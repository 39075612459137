/* istanbul ignore file */
import crmActivityValidation from 'crm/shared/timeline/activity/form/crmActivityValidation';
import useBaseCrudService, { ResourceId } from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';

export const ActivityType = {
  EMAIL: 'Email',
  CALL: 'Call',
  MEETING: 'Meeting',
};

export const ActivityIcons = {
  EMAIL: 'fa-envelope',
  CALL: 'fa-phone',
  MEETING: 'fa-calendar',
};

export const ActivityAction = {
  EMAIL: 'sent an Email',
  CALL: 'made a Call',
  MEETING: 'held a Meeting',
};

export const UPDATE_CRM_ACTIVITY_PERMISSION = 'CREATE_NOTE';
export const DELETE_CRM_ACTIVITY_PERMISSION = 'CREATE_NOTE';

const useActivityService = () => {
  const { deleteById, update } = useBaseCrudService({
    listResourceUrl: '/crm/activities',
    singleResourceUrl: '/crm/activities/:id',
    apiService: useKernelApi,
  });

  const { sendPostRequest } = useKernelApi();

  const addToBlacklist = async (id: ResourceId) => {
    const response = await sendPostRequest(`/crm/activities/${id}/black-list`);

    return response.json();
  };

  return {
    getValidationSchema: () => Promise.resolve(crmActivityValidation),
    deleteById,
    update,
    addToBlacklist,
  };
};

export default useActivityService;
