/* istanbul ignore file */
import React from 'react';

import useContactService from 'crm/contact/shared/contactService';
import ContactForm from 'crm/contact/createupdate/ContactForm';
import { CreateForm } from 'shared/uibuilder/form';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import CreateContactBreadcrumbs from 'crm/contact/createupdate/Create/Breadcrumbs';
import useRelatedRedirect from 'shared/routing/redirect/relatedRedirect';
import useErrorMap from 'crm/contact/createupdate/errorHandlers';
import { useAccountUrl } from 'crm/account';
import { useContactUrl } from 'crm/contact/ContactRouter';
import { useSearchParameter } from 'shared/routing/helper';

const CreateContact = () => {
  const { create, getValidationSchema } = useContactService();
  const { getRelatedContactsUrl } = useAccountUrl();
  const { getListUrl } = useContactUrl();
  const { errorsMap } = useErrorMap();
  const accountId = useSearchParameter('accountId');
  const accountName = useSearchParameter('accountName');

  const { redirect } = useRelatedRedirect({
    message: 'You are awesome! The Contact has been successfully created.',
    changedUrl: getListUrl(),
    unchangedUrl: getRelatedContactsUrl(accountId!),
    fields: {
      accountId,
    },
  });

  return (
    <CreateForm
      submitFormFunc={create}
      initialData={{
        accountId,
        accountName,
        address: {
          country: 'United States',
        },
        emails: [],
      }}
      afterSubmit={{
        execute: redirect,
      }}
      getValidationSchemaFunc={getValidationSchema}
      errorsMap={errorsMap}
    >
      <CreatePageLayout title="Create: Contact" breadcrumbs={<CreateContactBreadcrumbs />}>
        <ContactForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateContact;
