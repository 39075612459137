/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { PAID_LEAVE_BALANCES } from 'erp/employee/contracts/shared/employeeContractsService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const PaidLeaveBalanceDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => (
  <EnumDropdown {...props} options={PAID_LEAVE_BALANCES} placeholder="Select paid leave balance" />
);

export default PaidLeaveBalanceDropdown;
