/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LEAVE_STATUSES } from '../leaveService';
import { FieldProps } from 'shared/uibuilder/field';

interface LeaveStatusFieldProps extends FieldProps {
  value?: Nullable<string>;
  source?: Nullable<string>;
  label?: Nullable<string>;
}

const LeaveStatusField = ({ value: inputValue = null, source = null, label = null }: LeaveStatusFieldProps) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });

  const value = inputValue || (getValue && getValue());

  return <EnumField label={label} value={value} options={LEAVE_STATUSES} />;
};

export default LeaveStatusField;
