/* istanbul ignore file */
import React from 'react';
import 'erp/contracts/shared/RelatedContractsList/badge/EmployeeContractWillExpireSoonBadge.scss';
import { BadgeProps } from 'shared/uibuilder/badge/Badge';

const EmployeeContractWillExpireSoonBadge = (props: BadgeProps) => {
  return <i className="fa fa-exclamation-circle ml-2 expire-soon-badge" title="Contract will expire soon" />;
};

export default EmployeeContractWillExpireSoonBadge;
