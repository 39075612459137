import React, { useEffect, useState } from 'react';
import FormRow from 'shared/layout/form/FormRow';
import { DefaultInputPropTypes, RadioButtonGroup, TextInput } from 'shared/uibuilder/form/input';
import { AccountContactsInput, AccountNameInput } from 'crm/account';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import GenerateCodeNameButton from 'erp/codename/button/GenerateCodeNameButton';

import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

export const ACCOUNT_TYPE = {
  NEW: 'NEW',
  EXISTING: 'EXISTING',
};

const ACCOUNT_TYPES = [
  { value: ACCOUNT_TYPE.NEW, label: 'New' },
  { value: ACCOUNT_TYPE.EXISTING, label: 'Existing' },
];

export const TYPE_SOURCE = 'accountType';

interface AccountInputProps extends DefaultInputPropTypes<any> {
  contactSource?: string;
  nameSource?: string;
  idSource?: string;
}

const AccountInput = ({ nameSource = '', idSource = '', contactSource = undefined, ...props }: AccountInputProps) => {
  const { getRawOnChangeCallback } = useInputHelper(props);
  const { initializeValue } = useInputHelper({
    source: TYPE_SOURCE,
  });

  const { data = {}, setFormData } = useFormContext();
  const type = data[TYPE_SOURCE] || ACCOUNT_TYPE.NEW;
  const onChangeCallback = getRawOnChangeCallback() || (() => {});
  const [isButtonDisplayed] = useState(!data.accountName);

  const changeAccountType = (value: { [x: string]: any }) => {
    const newType = value[TYPE_SOURCE];
    const params: any = {
      [TYPE_SOURCE]: newType,
      accountName: null,
    };

    if (newType === ACCOUNT_TYPE.EXISTING) {
      // on type switch previously entered data should be cleared
      params[nameSource] = '';
      params.hasAccountCodeName = false;
    } else {
      params[idSource] = null;

      if (contactSource) {
        params[contactSource] = null;
      }
    }

    if (onChangeCallback) {
      onChangeCallback(params);
    }
  };

  const onChangeAccount = (newValue: FormFieldsData) => {
    onChangeCallback({
      ...newValue,
      accountName: newValue.hasAccountCodeName ? newValue.accountName : '',
    });
  };

  useEffect(() => {
    let initialType = ACCOUNT_TYPE.NEW;
    if (data[idSource]) {
      initialType = ACCOUNT_TYPE.EXISTING;
    }

    initializeValue(initialType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNewAccountName = (newName: any) => {
    if (setFormData) {
      setFormData({
        ...data,
        accountName: newName,
      });
    }
  };

  const isCodeNameFieldDisplayed = !data.hasAccountCodeName && !!data[idSource];

  return (
    <>
      <FormRow>
        <RadioButtonGroup options={ACCOUNT_TYPES} onChangeCallback={changeAccountType} source={TYPE_SOURCE} />
      </FormRow>
      {type === ACCOUNT_TYPE.NEW && (
        <FormRow>
          <TextInput label="Original Name" source="originalAccountName" errorSource="account.alias" />
          <TextInput label="Account Name" source={nameSource} disabled />
          <div style={{ marginTop: '23px' }}>
            <GenerateCodeNameButton
              source="accountName"
              isVisible={isButtonDisplayed}
              setCodeNameMethod={setNewAccountName}
            />
          </div>
        </FormRow>
      )}
      {type === ACCOUNT_TYPE.EXISTING && (
        <>
          <FormRow>
            <AccountNameInput
              label="Account Search"
              source={idSource}
              accountNameSource="accountCodeName"
              onChangeCallback={onChangeAccount}
              errorSource="account.alias"
            />
            <TextInput
              label="Account Name"
              source={nameSource}
              disabled
              isVisible={isCodeNameFieldDisplayed}
              isRequired={isCodeNameFieldDisplayed}
            />
            <div style={{ marginTop: '23px' }}>
              <GenerateCodeNameButton
                source={nameSource}
                isVisible={isCodeNameFieldDisplayed}
                setCodeNameMethod={setNewAccountName}
              />
            </div>
          </FormRow>
          <FormRow>
            {contactSource && data.accountId && (
              <ClearAfterUnmountWrapper>
                <AccountContactsInput
                  label="Primary Contact"
                  placeholder="Choose Primary Contact"
                  source={contactSource}
                  accountId={data.accountId}
                />
              </ClearAfterUnmountWrapper>
            )}
          </FormRow>
        </>
      )}
    </>
  );
};

export default AccountInput;
