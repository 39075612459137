/* istanbul ignore file */
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useFormContext } from 'shared/uibuilder/form/FormContext';
import OpportunitiesBreadcrumbs from 'crm/opportunity/shared/OpportunitiesBreadcrumbs';
import { SingleBreadcrumbItem, UpdateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';

const UpdateOpportunityBreadcrumbs = () => {
  const { id } = useParams<Dictionary<string>>();
  const { data = {} } = useFormContext();
  const { name } = data;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayedName = useMemo(() => name, []);

  return (
    <OpportunitiesBreadcrumbs>
      <SingleBreadcrumbItem entity={displayedName} link={`/crm/opportunities/${id}`} />
      <UpdateBreadcrumbItem />
    </OpportunitiesBreadcrumbs>
  );
};

export default UpdateOpportunityBreadcrumbs;
