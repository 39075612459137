/* istanbul ignore file */
// libs
import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
// components
import FilePreviewLayout from 'artifact/shared/input/layout/preview/FilePreviewLayout';

type LoadingFilePreviewLayoutProps = {
  loadingArtifactObject: any;
  onRemove: () => void;
};

const LoadingFilePreviewLayout = ({ loadingArtifactObject, onRemove }: LoadingFilePreviewLayoutProps) => {
  return (
    <FilePreviewLayout artifactObject={loadingArtifactObject} onRemove={onRemove}>
      {loadingArtifactObject && loadingArtifactObject.loadingProgress ? (
        <LinearProgress
          color="primary"
          variant="determinate"
          value={loadingArtifactObject.loadingProgress}
          className="progress progress-xs"
          sx={{ marginTop: '-5px' }}
        />
      ) : null}
    </FilePreviewLayout>
  );
};

export default LoadingFilePreviewLayout;
