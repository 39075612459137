/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const options = {
  soldiers: 'Солдаты',
  sailors: 'Матросы',
  sergeants: 'Cержанты',
  ensigns: 'Прапорщики',
  midshipmans: 'Мичманы',
};

const MilitaryContingentDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => {
  return <EnumDropdown {...props} options={options} />;
};

export default MilitaryContingentDropdown;
