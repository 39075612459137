import IconButton from 'uibuilder/button/IconButton';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

export const EditGroupButton = ({ onClickButton }: any) => {
  return (
    <IconButton onClick={onClickButton}>
      <AppRegistrationIcon color="primary" />
    </IconButton>
  );
};

export const SaveGroupButton = () => {
  const { submitForm = () => {}, isSubmitEnabled } = useFormContext();
  const saveEdit = async () => {
    submitForm();
  };

  return (
    <IconButton onClick={saveEdit} disabled={!isSubmitEnabled}>
      <CheckIcon color="success" />
    </IconButton>
  );
};

export const CloseEditGroupButton = ({ toggleEdit, getDataForUpdate }: any) => {
  const { setFormData = () => {} } = useFormContext();
  const closeEdit = async () => {
    toggleEdit();
    const prevData = await getDataForUpdate();
    setFormData(prevData);
  };

  return (
    <IconButton onClick={closeEdit}>
      <CloseIcon color="error" />
    </IconButton>
  );
};
