export interface BaseRouteConfig {
  createPostfix?: string;
  editPostfix?: string;
  timelinePostfix?: string;
}

interface RouteHelperParams extends BaseRouteConfig {
  singleEntityRoute?: string;
  listRoute: string;
  prefix?: string;
}

const useRoute = ({
  singleEntityRoute,
  listRoute,
  createPostfix = 'add',
  editPostfix = 'edit',
  timelinePostfix = 'timeline',
  prefix = '',
}: RouteHelperParams) => {
  const actualSingleEntityRoute = singleEntityRoute || `${prefix}/:id`;
  return {
    singleEntityRoute: actualSingleEntityRoute,
    listRoute: `${prefix}`,
    createRoute: `${prefix}/${createPostfix}`,
    updateRoute: `${actualSingleEntityRoute}/${editPostfix}`,
    timelineRoute: `${actualSingleEntityRoute}/${timelinePostfix}/:timelineEntryId?`,
  };
};

export default useRoute;
