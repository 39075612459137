import React from 'react';
import './InputLayouts.scss';
import { PhoneInputLayoutProps, PhoneInputLayoutType } from 'shared/uibuilder/form/input/PhoneInput';

const PhoneInputLayout: PhoneInputLayoutType = ({
  phoneInput,
  extensionInput,
  hasExtension = false,
}: PhoneInputLayoutProps) => {
  return (
    <>
      <div className={hasExtension ? 'phone-number' : ''}>{phoneInput}</div>

      {hasExtension && <div className="phone-extension">{extensionInput}</div>}
    </>
  );
};

export default PhoneInputLayout;
