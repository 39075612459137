/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useEmployeeContractsService from 'erp/employee/contracts/shared/employeeContractsService';
import { useEmployeeContractId } from 'erp/employee/contracts/EmployeeContractsRouter';

export const CONTRACTS_CONTEXT = 'CONTRACTS';

const EmployeeContractsContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useEmployeeContractsService();
  const id = useEmployeeContractId();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={() => getPermissions(id)}
      permissionToCheck={permissionToCheck}
      id={id}
      contextName={CONTRACTS_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default EmployeeContractsContext;
