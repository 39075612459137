/* istanbul ignore file */
import React, { useCallback } from 'react';
import classnames from 'classnames';
import { ButtonDropdownLayoutProps, ButtonDropdownLayoutType } from 'shared/uibuilder/form/input/ButtonDropdown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import ArrowIcon from '@mui/icons-material/ArrowDropDownOutlined';
import './ButtonDropdownLayout.scss';

const ButtonDropdownLayout: ButtonDropdownLayoutType = ({
  label,
  children,
  displayedValue,
  onRemoveCallback,
  className,
  toggleMenu,
  isOpen,
  shouldCloseMenuOnReset,
  source,
  popperPlacement,
}: ButtonDropdownLayoutProps) => {
  const id = source || label?.toString() || '';
  let dropdownToggleLabel: any = label;
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  if (displayedValue) {
    dropdownToggleLabel = (
      <>
        {typeof label === 'string' ? <span>{`${label}: `}</span> : label}
        <b>{displayedValue}</b>
      </>
    );
  }

  const onRemoveButtonClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      if (shouldCloseMenuOnReset && isOpen) {
        toggleMenu();
      }

      if (onRemoveCallback) {
        onRemoveCallback();
      }
    },
    [onRemoveCallback, isOpen, shouldCloseMenuOnReset, toggleMenu],
  );

  return (
    <>
      <Button
        ref={anchorRef}
        data-testid="toggle-btn"
        id={id}
        aria-controls={isOpen ? id : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={toggleMenu}
        variant="outlined"
        className={classnames('button-dropdown__toggle', { 'button-dropdown__toggle--active': !!displayedValue })}
        sx={{
          color: (theme: any) => (theme.vars.palette.neutral as any)[0],
          paddingRight: onRemoveCallback ? '2px' : '7px',
          b: { marginLeft: '5px' },
        }}
      >
        {dropdownToggleLabel}
        <ArrowIcon fontSize="small" sx={{ marginLeft: '9px', marginRight: '-6px', fontSize: '22px' }} />
        {!!onRemoveCallback && (
          <IconButton data-testid="remove-btn" size="small" onClick={onRemoveButtonClick} sx={{ marginLeft: '5px' }}>
            <CloseIcon sx={{ fontSize: '15px', color: (theme: any) => (theme.vars.palette.neutral as any)[0] }} />
          </IconButton>
        )}
      </Button>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        placement={popperPlacement || 'bottom-start'}
        transition
        disablePortal
        className={className}
        sx={{ zIndex: 1200 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper elevation={3} className="button-dropdown__menu dropdown-menu__wrapper">
              <ClickAwayListener onClickAway={toggleMenu} touchEvent={false}>
                <MenuList autoFocusItem={false} id={id}>
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ButtonDropdownLayout;
