import usePrevious from 'shared/usePrevious';
import { useState } from 'react';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';
import useArtifactHelper from 'artifact/shared/input/WysiwygInputPasteArtifactDecorator/artifactHelper';
import useConvertingTagsHelper from 'artifact/shared/input/WysiwygInputPasteArtifactDecorator/artifactTagsConverted';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';

const useArtifactsGarbageCollector = ({
  artifactsIds,
  editorContent,
  source,
  onChangeCallback,
}: {
  artifactsIds: string[];
  editorContent: string;
  source: string;
  onChangeCallback: (values: FormFieldsData) => void;
}) => {
  const prevArtifacts: any = usePrevious(artifactsIds);
  const [refreshData, setRefreshData] = useState(null);

  const { getArtifactsDataByIds, getThumbnail } = useArtifactHelper();
  const { replaceArtifactWithImageTag } = useConvertingTagsHelper();

  useDidUpdateEffect(() => {
    const isValidArtifact = (artifact: Element) => artifactsIds.some((id: string) => id === artifact.id);

    if (artifactsIds.length >= prevArtifacts.length) {
      return;
    }

    const div = document.createElement('div');
    div.innerHTML = editorContent;

    const allArtifacts = div.querySelectorAll('artifact');

    allArtifacts.forEach((artifact: any) => {
      if (!isValidArtifact(artifact)) {
        artifact.parentNode.removeChild(artifact);
      }
    });

    if (refreshData) {
      const newValuePromise = replaceArtifactWithImageTag(
        div.querySelectorAll('artifact'),
        getArtifactsDataByIds,
        getThumbnail,
      );
      newValuePromise.then(() => {
        const newValue = div.innerHTML;
        // @ts-ignore
        refreshData(newValue);
        onChangeCallback({
          [source]: newValue,
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artifactsIds]);

  return {
    setRefreshData: (fn: any) => setRefreshData(() => fn),
  };
};

export default useArtifactsGarbageCollector;
