import React from 'react';
import useRecruitingBoardService from '../useRecruitingBoardService';
import List from 'shared/uibuilder/list/ListWithInfiniteScroll';
import RecruitingBoardContent from './RecruitingBoardContent';

const RecruitingBoard = () => {
  const { search } = useRecruitingBoardService();

  // TODO: Infinite Scroll (ListWithInfiniteScroll)
  // Use DataGridInfiniteScrollPagingHandler for handle scroll (See - ProjectsDashboardsDataGrid.tsx)
  return (
    <List getDataMethod={search} defaultSortOrder="DESC" defaultSortField="createdAt">
      <RecruitingBoardContent />
    </List>
  );
};

export default RecruitingBoard;
