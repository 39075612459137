import React from 'react';
import RowGroupInputsWrapper from 'uibuilder/form/RowGropInputsWrapper/RowGroupInputsWrapper';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import TogglerInput from 'shared/uibuilder/form/input/TogglerInput';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { COMPETENCY_LEVEL, COMPETENCY_LEVEL_WITHOUT_NA } from '../../constants';

const COMPETENCY_LEVELS = Object.keys(COMPETENCY_LEVEL_WITHOUT_NA);

const CompetencyInputs = () => {
  const { getRawOnChangeCallback: getMinChange } = useInputHelper({ source: 'competencyLevelMin' });
  const { getRawOnChangeCallback: getMaxChange } = useInputHelper({ source: 'competencyLevelMax' });
  const { getValue } = useInputHelper({ source: 'isCompetencyLevelRange' });
  const competencyLevelRange = getValue();
  const minChange = getMinChange() || (() => {});
  const maxChange = getMaxChange() || (() => {});

  const customChange = (value: FormFieldsData) => {
    if (value.competencyLevelMax === null || value.competencyLevelMax === undefined) {
      maxChange(value);
    }

    if (value.competencyLevelMin === null || value.competencyLevelMin === undefined) {
      minChange(value);
    }

    if (value.competencyLevelMax) {
      const currentIndex = COMPETENCY_LEVELS.indexOf(value.competencyLevelMax);
      const newMinIndex = Math.max(currentIndex - 1, 0);
      maxChange({
        ...value,
        competencyLevelMin: COMPETENCY_LEVELS[newMinIndex],
      });
    }

    if (value.competencyLevelMin) {
      const currentIndex = COMPETENCY_LEVELS.indexOf(value.competencyLevelMin);
      const newMaxIndex = Math.min(currentIndex + 1, COMPETENCY_LEVELS.length - 1);

      minChange({
        ...value,
        competencyLevelMax: COMPETENCY_LEVELS[newMaxIndex],
      });
    }
  };

  return (
    <RowGroupInputsWrapper
      isRequired
      wrapperLabel="Competency level"
      sxProps={{
        '& > div:first-of-type': {
          flexBasis: '80%',
          flexGrow: 1,
          maxWidth: '80%',
        },

        '& > div:not(:first-of-type)': {
          flexBasis: '10%',
          maxWidth: '10%',
        },
      }}
    >
      {competencyLevelRange ? (
        <RowGroupInputsWrapper>
          <ClearAfterUnmountWrapper>
            <EnumDropdown
              onChangeCallback={customChange}
              source="competencyLevelMin"
              options={COMPETENCY_LEVEL_WITHOUT_NA}
              label=""
              placeholder="Min"
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper>
            <EnumDropdown
              onChangeCallback={customChange}
              source="competencyLevelMax"
              options={COMPETENCY_LEVEL_WITHOUT_NA}
              label=""
              placeholder="Max"
            />
          </ClearAfterUnmountWrapper>
        </RowGroupInputsWrapper>
      ) : (
        <ClearAfterUnmountWrapper>
          <EnumDropdown
            source="competencyLevel"
            options={COMPETENCY_LEVEL}
            label=""
            placeholder="Select Competency level"
          />
        </ClearAfterUnmountWrapper>
      )}

      <TogglerInput source="isCompetencyLevelRange" label=" " tooltip="One level / range" />
    </RowGroupInputsWrapper>
  );
};

export default CompetencyInputs;
