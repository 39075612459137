/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import EqCondition, { EQ_CONDITION_TYPE } from 'shared/uibuilder/list/filter/condition/EqCondition';
import AccountInput from 'crm/account/shared/input/AccountNameInput';
import { BaseFilterProps, FilterCondition, useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import InputLoader from 'shared/uibuilder/InputLoader';
import useAccountService from 'crm/account/accountService';

const AccountFilter = ({ source, label, ...props }: BaseFilterProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [accountName, setAccountName] = useState(null);

  const { getValue } = useFilterContext();
  const { getById } = useAccountService();

  const loadAccountAndInitializeLabel = async (accountId: StringOrNumber) => {
    const result = await getById(accountId);
    setAccountName(result.accountName);
    setIsInitialized(true);
  };

  useEffect(() => {
    const accountId = getValue(source, EQ_CONDITION_TYPE as FilterCondition);

    if (accountId) {
      loadAccountAndInitializeLabel(accountId);
    } else {
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isInitialized ? (
    <EqCondition {...props} source={source}>
      <AccountInput accountNameValue={accountName} label={label} />
    </EqCondition>
  ) : (
    <InputLoader />
  );
};

export default AccountFilter;
