import React from 'react';
import { TextField } from 'shared/uibuilder/field';
import { LEAVE_TYPES_ALIASES } from 'erp/leave/shared/leaveService';

const NumberOfCalendarDaysField = ({ value }: { value?: any }) => {
  let result;
  const { type, duration, leaveBalance } = value;

  if (type === LEAVE_TYPES_ALIASES.PAID_LEAVE) {
    result = leaveBalance.replace('-', '');
  } else {
    result = duration;
  }

  return <TextField value={result} />;
};

export default NumberOfCalendarDaysField;
