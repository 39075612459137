/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import Milestons from 'davinci/techreview/Milestones';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';

export const DAVINCI_TEAM_PATH = '/davinciteam';

const DavinciRouter: Router = () => {
  return (
    <ProtectedRouter basePath={DAVINCI_TEAM_PATH}>
      <Routes>
        <Route path="/techreviewbuilder" element={<Milestons />} />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

DavinciRouter.getRouterPath = () => `${DAVINCI_TEAM_PATH}/*`;

export default DavinciRouter;
