/* istanbul ignore file */
import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import AssessmentContext from 'erp/employee/assessment/AssessmentContext';
import Page404 from 'shared/uibuilder/Page404';
import { CreateAssessmentPage, UpdateAssessmentPage } from 'erp/employee/assessment/createupdate';
import AssessmentsListPage from 'erp/employee/assessment/List';
import useRoute from 'shared/routing/useRoute';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import useEntityUrl, { UrlParams } from 'shared/routing/useEntityUrl';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { CREATE_ASSESSMENT, UPDATE_ASSESSMENT } from 'erp/employee/assessment/assessmentService';
import { READ_EMPLOYEE_COMMUNICATIONS } from 'erp/employee/employeeService';

export const ASSESSMENTS_PATH = 'assessments';
export const ASSESSMENTS_ID_SOURCE = 'assessmentId';

export const useAssessmentRoute = () => {
  const baseRoutes = useRoute({
    singleEntityRoute: `/:${ASSESSMENTS_ID_SOURCE}`,
    listRoute: `/${ASSESSMENTS_PATH}`,
  });

  return {
    ...baseRoutes,
  };
};

export const useAssessmentUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const listUrl = `${getSingleEntityUrl()}/${ASSESSMENTS_PATH}`;
  const baseUrls = useEntityUrl({
    baseLink: `${listUrl}`,
  });

  const getListUrl = () => listUrl;

  return {
    ...baseUrls,
    getListUrl,
  };
};

export const useAssessmentId = () => {
  const urlParams: UrlParams = useParams<Dictionary<string>>();

  return urlParams[ASSESSMENTS_ID_SOURCE]!;
};

const AssessmentRouter = () => {
  const { createRoute, updateRoute, listRoute } = useAssessmentRoute();

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <ProtectedRoute permissionToCheck={READ_EMPLOYEE_COMMUNICATIONS}>
            <AssessmentsListPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={createRoute}
        element={
          <ProtectedRoute path={createRoute} permissionToCheck={CREATE_ASSESSMENT}>
            <CreateAssessmentPage />
          </ProtectedRoute>
        }
      />

      <Route
        path={updateRoute}
        element={
          <AssessmentContext permissionToCheck={UPDATE_ASSESSMENT}>
            <UpdateAssessmentPage />
          </AssessmentContext>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AssessmentRouter;
