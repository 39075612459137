/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';

const LeavesBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useLeaveUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Leaves" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default LeavesBreadcrumbs;
