/* istanbul ignore file */
import { CreateForm } from 'shared/uibuilder/form';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import React from 'react';
import useActivityService from 'crm/shared/timeline/activity/crmActivityService';
import ActivityForm from 'crm/shared/timeline/activity/form/ActivityForm';
import { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

interface CreateActivityFormProps extends FormTemplateProps {
  onCancel: () => void;
  submitFormFunc: (values: FormFieldsData) => Promise<object | void>;
}

const CreateActivityForm = ({ submitFormFunc, onCancel, ...props }: CreateActivityFormProps) => {
  const { getValidationSchema } = useActivityService();
  return (
    <CreateForm
      submitFormFunc={submitFormFunc}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        activityType: 'EMAIL',
      }}
      {...props}
    >
      <CreateTimelineItemLayout onCancel={onCancel}>
        <ActivityForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateActivityForm;
