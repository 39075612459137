/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  CREATE_SKILL_BASED_QUEUE,
  READ_SKILL_BASED_QUEUE,
  READ_SKILL_BASED_QUEUES_LIST,
  UPDATE_SKILL_BASED_QUEUE,
} from 'erp/skillbasedqueue/shared/skillBasedQueueService';
import SkillBasedQueueList from 'erp/skillbasedqueue/List';
import { CreateSkillBasedQueue, UpdateSkillBasedQueue } from 'erp/skillbasedqueue/createupdate';
import ViewSkillBasedQueue from 'erp/skillbasedqueue/Show';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import SkillBasedQueueContext from 'erp/skillbasedqueue/SkillBasedQueueContext';

export const SKILL_BASED_QUEUE_PATH = '/skill-based-queues';

export const useSkillBasedQueueRoute = () => {
  const routers = useRoute({ listRoute: SKILL_BASED_QUEUE_PATH });

  return {
    ...routers,
    copyEntityRoute: `${routers.singleEntityRoute}/copy`,
  };
};

export const useSkillBasedQueueUrl = () => {
  const { getSingleEntityUrl, ...restUrls } = useEntityUrl({
    baseLink: SKILL_BASED_QUEUE_PATH,
  });
  const getCopyEntityUrl = (id: StringOrNumber): string => `${getSingleEntityUrl(id)}/copy`;

  return {
    ...restUrls,
    getSingleEntityUrl,
    getCopyEntityUrl,
  };
};

export const useSkillBasedQueueId = () => {
  const { id } = useParams<Dictionary<string>>();

  return id!;
};

const SkillBasedQueueRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, updateRoute, copyEntityRoute } = useSkillBasedQueueRoute();

  return (
    <ProtectedRouter basePath={SKILL_BASED_QUEUE_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_SKILL_BASED_QUEUES_LIST}>
              <SkillBasedQueueList />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_SKILL_BASED_QUEUE}>
              <CreateSkillBasedQueue />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={copyEntityRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_SKILL_BASED_QUEUE}>
              <CreateSkillBasedQueue />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <SkillBasedQueueContext permissionToCheck={READ_SKILL_BASED_QUEUE}>
              <ViewSkillBasedQueue />
            </SkillBasedQueueContext>
          }
        />

        <Route
          path={updateRoute}
          element={
            <SkillBasedQueueContext permissionToCheck={UPDATE_SKILL_BASED_QUEUE}>
              <UpdateSkillBasedQueue />
            </SkillBasedQueueContext>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

SkillBasedQueueRouter.getRouterPath = () => `${SKILL_BASED_QUEUE_PATH}/*`;

export default SkillBasedQueueRouter;
