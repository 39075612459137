import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import React from 'react';
import { BUDGETING_CREATE } from 'erp/serviceCatalog/budgets/budgetingService';
import { useParams } from 'react-router-dom';
import { BUDGET_VERSION_STATUSES } from 'erp/serviceCatalog/budgets/budgetVersionService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const EditBudgetButton = ({
                            permissionToCheck = BUDGETING_CREATE,
                            ...props
                          }: EditButtonProps) => {
  const { data } = useShowContext();
  const { getUpdateBudgetUrl } = useServiceCatalogUrl();
  const id = useServiceCatalogId();
  const budgetId = useParams<Dictionary<string>>().budgetId!

  if (data.getValueBySource('status') === BUDGET_VERSION_STATUSES.APPROVED) {
    return (<></>);
  }

  return <EditButton
    outline
    permissionToCheck={permissionToCheck}
    path={getUpdateBudgetUrl(id, budgetId)}
    {...props}
  />;
};

export default EditBudgetButton;
