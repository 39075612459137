/* istanbul ignore file */
import React from 'react';
import useInputHelper from './inputHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import {
  DefaultInputPropTypes,
  DefaultInputLayoutProps,
  InputLayoutHintProps,
  InputLayoutStateProps,
  ValidationLayoutProps,
} from 'shared/uibuilder/form/input';
import { FormFieldsData } from '../FormContext';

type RadioButtonOption = {
  value: string | number;
  label: string | JSX.Element;
};

export interface CommonRadioButtonGroupProps {
  options?: RadioButtonOption[];
  isColumnDirection?: boolean;
}

export interface RadioButtonGroupProps extends CommonRadioButtonGroupProps, DefaultInputPropTypes<string | number> {}

export interface RadioButtonGroupLayoutProps
  extends CommonRadioButtonGroupProps,
    DefaultInputLayoutProps,
    InputLayoutHintProps,
    InputLayoutStateProps,
    ValidationLayoutProps {
  onChangeCallback?: Nullable<(values: FormFieldsData, isFieldInitializing?: boolean) => void>;
  onBlurCallback?: () => void;
  onFocusCallback?: () => void;
}

export type RadioButtonGroupLayoutType = ReactComponent<RadioButtonGroupLayoutProps>;

const RadioButtonGroup = ({ isColumnDirection = false, ...props }: RadioButtonGroupProps) => {
  const inputHelper = useInputHelper(props);
  const { RadioButtonGroupLayout } = useUiTheme<RadioButtonGroupLayoutType>();

  return (
    <RadioButtonGroupLayout
      {...props}
      isVisible={inputHelper.isVisible()}
      disabled={inputHelper.getIsDisabled()}
      className={inputHelper.getClassName()}
      isColumnDirection={isColumnDirection}
      label={inputHelper.getLabel()}
      source={inputHelper.getSource()}
      onChangeCallback={inputHelper.getRawOnChangeCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      value={inputHelper.getValue() as string}
      isRequired={inputHelper.getIsRequired()}
      errorMessages={inputHelper.getErrorMessages()}
    />
  );
};

export default RadioButtonGroup;
