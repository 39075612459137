import React from 'react';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import TableSortLabel from '@mui/material/TableSortLabel';

interface SortButtonProps {
  source?: string;
  label?: any;
}

const SortButton = ({ source = '', label = '' }: SortButtonProps) => {
  const { sort = {}, sortBy = () => {}, loading } = useListContext();
  const isActive = sort.field === source;
  const { order = '' } = sort;

  return (
    <TableSortLabel
      className="sort-btn"
      disabled={loading}
      active={isActive}
      direction={isActive ? (order.toLowerCase() as any) : 'asc'}
      onClick={() => {
        sortBy(source);
      }}
    >
      {label}
    </TableSortLabel>
  );
};

export default SortButton;
