/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form/index';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import useServiceCatalogService from 'erp/serviceCatalog/useServiceCatalogService';
import { useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import CreateServiceCatalogBreadcrumbs from 'erp/serviceCatalog/createupdate/Create/Breadcrumbs';
import ServiceCatalogForm from 'erp/serviceCatalog/createupdate/ServiceCatalogForm';

const CreateServiceCatalogPage = () => {
  const { create, getValidationSchema } = useServiceCatalogService();
  const { getSingleEntityUrl } = useServiceCatalogUrl();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: data => getSingleEntityUrl(data.id),
        message: 'You are awesome! The Service has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout breadcrumbs={<CreateServiceCatalogBreadcrumbs />} title="Create: Service">
        <ServiceCatalogForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateServiceCatalogPage;
