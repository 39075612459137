/* istanbul ignore file */
import React, { useState } from 'react';
import { EmployeeInput } from 'erp/employee';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import FormSection from 'shared/layout/form/FormSection';
import { InputList, TextArea } from 'shared/uibuilder/form/input';
import CurrencyInput from 'shared/uibuilder/form/input/CurrencyInput';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import FormTemplate from 'shared/uibuilder/form/FormTemplate';
import { Button } from 'uibuilder/button';
import Alert from 'uibuilder/Alert';
import { copyToClipboard } from 'shared/clipboard';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';

const MilestonesInput = ({ source }: { source: string }) => {
  const { getValue, getSource, getRawOnChangeCallback } = useInputHelper({ source });

  const onChangeCallback = getRawOnChangeCallback() || (() => {});

  const milestones = getValue() || {};

  const createOnChange = (fieldSource: string, path: string) => (obj: { [x: string]: any }) => {
    onChangeCallback({
      [getSource()]: {
        ...milestones,
        [path]: obj[fieldSource],
      },
    });
  };

  return (
    <>
      <EmployeeInput
        label="Employee"
        source={`${source}.employeeId`}
        onChangeCallback={createOnChange(`${source}.employeeId`, 'employeeId')}
      />
      <TextInput
        label="Title"
        source={`${source}.title`}
        className="milestone-input"
        onChangeCallback={createOnChange(`${source}.title`, 'title')}
      />
      <TextArea
        label="Details"
        source={`${source}.details`}
        onChangeCallback={createOnChange(`${source}.details`, 'details')}
        className="milestone-input"
      />
      <CurrencyInput
        label="Estimated"
        source={`${source}.estimated`}
        onChangeCallback={createOnChange(`${source}.estimated`, 'estimated')}
        className="milestone-input milestone-input--estimated"
      />
      <TextArea
        source={`${source}.checklist`}
        label="Design checklist"
        onChangeCallback={createOnChange(`${source}.checklist`, 'checklist')}
        className="milestone-input"
      />
    </>
  );
};

const Milestones = () => {
  const [milestones, setMilestones] = useState(null);

  const milestonesComponent = milestones ? (
    <Alert color="success">
      {JSON.stringify(milestones)}
      <Button
        variant="text"
        onClick={() => {
          copyToClipboard(JSON.stringify(milestones));
        }}
        id="copy-main"
        className="copy-anchor p-0"
        sx={{ minWidth: '15px', marginLeft: '10px' }}
      >
        <CopyIcon />
      </Button>
    </Alert>
  ) : null;

  return (
    <FormTemplate
      initialData={{
        milestones: [],
      }}
      submitFormFunc={({ milestones: formMilestones }) =>
        new Promise(resolve => {
          setMilestones(formMilestones);
          resolve();
        })
      }
    >
      {milestonesComponent}
      <CreatePageLayout title="Tech review milestones">
        <FormSection title="">
          <InputList
            source="milestones"
            inputTemplate={MilestonesInput}
            maxFields={1000}
            defaultValue={{
              checklist: `### Design
- [ ] 1 / Item 1
- [ ] 1 / Item 2

### Security

- [ ] 1 / Item 1
- [ ] 1 / Item 2`,
            }}
          />
        </FormSection>
      </CreatePageLayout>
    </FormTemplate>
  );
};

export default Milestones;
