import React from 'react';
import { applyPhoneSchema, splitPhone } from 'shared/uibuilder/phoneUtils';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import useUiTheme from 'shared/uibuilder/useUiTheme';

export interface PhoneFieldLayoutProps {
  label?: string;
  phone?: string;
  phoneExtension?: string;
}

export type PhoneFieldLayoutType = ReactComponent<PhoneFieldLayoutProps>;

const PhoneField = (props: FieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);
  const { PhoneFieldLayout } = useUiTheme<PhoneFieldLayoutType>();

  const value = splitPhone(getValue());

  const phone = value && applyPhoneSchema(value.phone);
  const extension = value && value.extension;

  return <PhoneFieldLayout phone={phone} phoneExtension={extension as any} label={getLabel() as string} />;
};

export default PhoneField;
