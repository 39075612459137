import React from 'react';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import { InputLayoutType } from 'shared/uibuilder/form/input/TextInput';
import { BaseChangeEvent, CommonInputLayoutProps } from '../../../../shared/uibuilder/form/input';

const DefaultInputLayout: InputLayoutType = ({
  value,
  ...otherProps
}: CommonInputLayoutProps<BaseChangeEvent, Nullable<string | string[]>>) => {
  const props = {
    ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
    ...otherProps,
  };
  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <input {...getCommonInputProps(props)} value={value || ''} type="" />
    </BaseInputLayout>
  );
};

export default DefaultInputLayout;
