/* istanbul ignore file */
import React from 'react';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

export const MIN_DAYS_BORDER = 150;

export const DISQUALIFIED_DAYS_BORDER = 180;

export interface LeadWillAutoDisqualifiedSoonBadgeProps {
  source: string;
  statusSource: string;
  skipStates: string[];
  id?: string;
}

const isStatusAllowed = (status: string, skipStates: string[]) => {
  return !skipStates.includes(status);
};

const LeadWillAutoDisqualifiedSoonBadge: React.FC<LeadWillAutoDisqualifiedSoonBadgeProps> = ({
  source,
  statusSource,
  skipStates,
}) => {
  const { getValue: getLastModified } = useFieldHelper({ source });
  const { getValue: getStatus } = useFieldHelper({ source: statusSource });
  const { getDaysBetween, getMomentDateObjAsUtc, getCurrentDateTimeInUtc } = useDateService();

  const status = getStatus();
  if (!isStatusAllowed(status, skipStates)) {
    return null;
  }

  const lastModified = getMomentDateObjAsUtc(getLastModified());
  const currentDateTime = getCurrentDateTimeInUtc();

  const daysBetweenDates = getDaysBetween(currentDateTime, lastModified, DATE_FORMAT.FULL);

  if (daysBetweenDates < MIN_DAYS_BORDER) {
    return null;
  }

  const daysBeforeDisqualified = DISQUALIFIED_DAYS_BORDER - daysBetweenDates;

  const daysWithUnits = `${daysBeforeDisqualified} d`;
  const title = `Lead will be disqualified by system in ${daysWithUnits}!`;

  return <i className="fa fa-exclamation-circle ml-2 expire-soon-badge" title={title} />;
};

export default LeadWillAutoDisqualifiedSoonBadge;
