import { SERVICE_CATALOG_HEALTH_STATUSES } from 'erp/serviceCatalog/useServiceCatalogService';
import Badge from 'shared/uibuilder/badge/Badge';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';
import { TextField } from 'shared/uibuilder/field';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

type HealthCheckListProps = {
  allIssues: {[p: string]: string},
  issuesOptions: {[p: string]: string},
  issuesImportance: {[p: string]: string},
  issuesDescription: {[p: string]: string},
  unresolvedIssues: { type: string }[],
};

const HealthCheckListColumns = () => {
  const { getValue: getIsResolved } = useFieldHelper<boolean>({ source: 'isResolved' });
  const { getValue: getPrimary } = useFieldHelper<any>({ source: 'primary' });
  const { getValue: getSecondary } = useFieldHelper<any>({ source: 'secondary' });

  return <>
    <Checkbox
      checked={getIsResolved()}
      size='small'
      disableRipple
      readOnly
      disabled={!getIsResolved()}
    />
    <TextField value={getPrimary() ?? ' '} />
    <TextField value={getSecondary() ?? ' '} />
  </>;
};

const HealthCheckList = ({
                           allIssues,
                           unresolvedIssues,
                           issuesOptions,
                           issuesImportance,
                           issuesDescription,
                         }: HealthCheckListProps) => {
  const colors: Record<string, BasicColors> = {
    [SERVICE_CATALOG_HEALTH_STATUSES.NORMAL]: 'neutralLight',
    [SERVICE_CATALOG_HEALTH_STATUSES.WARNING]: 'warning',
    [SERVICE_CATALOG_HEALTH_STATUSES.CRITICAL]: 'error',
  };

  const unresolvedIssueNames = unresolvedIssues.map(issue => issue.type);

  const items = Object.entries(allIssues).map((entry) => {
    const isResolved = !unresolvedIssueNames.includes(entry[0]);

    const primary = !isResolved
      ? issuesDescription[entry[0]] ?? ''
      : '';
    const secondary = !isResolved
      ? <Badge color={colors[issuesImportance[entry[0]]]}>{issuesOptions[entry[0]]}</Badge>
      : <s>{issuesDescription[entry[0]]}</s>;

    return {
      isResolved,
      primary,
      secondary
    }
  });

  return <ControlledList data={{ items, totalPages: 1, totalElements: items.length }}>
    <DataGridLayout>
      <HealthCheckListColumns />
    </DataGridLayout>
  </ControlledList>
}

export default HealthCheckList;
