/* istanbul ignore file */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthorization from 'shared/authorization/authorizationService';
import { READ_EMPLOYEE_TIMELINE } from 'erp/employee/employeeService';
import { useEmployeeId, useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';

const EmployeeIdPage = () => {
  const { getTimelineUrl, getSummaryUrl, getProfileUrl } = useEmployeeUrl();
  const { isGranted } = useAuthorization();
  const navigate = useNavigate();
  const id = useEmployeeId();
  const { getUserAlias } = useAuthenticatedUserService();

  useEffect(() => {
    (async () => {
      const userAlias = await getUserAlias();

      if (id === userAlias) {
        navigate(getProfileUrl(id), { replace: true });
      } else if (isGranted(READ_EMPLOYEE_TIMELINE)) {
        navigate(getTimelineUrl(id), { replace: true });
      } else {
        navigate(getSummaryUrl(id), { replace: true });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default EmployeeIdPage;
