/* istanbul ignore file */
import OwnerInput from 'crm/shared/input/OwnerInput';
import React from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import { CHANGE_OWNER } from 'crm/crmService';
import { READ_FULL_OPPORTUNITIES_LIST } from 'crm/opportunity/shared/opportunityService';

const OpportunityOwnerInput = (props: { source: string }) => {
  const { isGranted } = useAuthorization();

  return (
    <OwnerInput
      isUserCanChange={isGranted(CHANGE_OWNER) || isGranted(READ_FULL_OPPORTUNITIES_LIST)}
      label="Opportunity Owner"
      dropdownPlaceholder="Choose Opportunity Owner"
      {...props}
    />
  );
};

export default OpportunityOwnerInput;
