import React from 'react';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const LeaveDurationField = ({ value: inputValue = null, source = null, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper<any>({ value: inputValue, source });
  const duration = getValue();
  let value = '';

  if (duration) {
    const postfix = duration > 1 ? 'days' : 'day';
    value = `${duration} ${postfix}`;
  }

  return <TextField value={value} source={source} {...props} />;
};

export default LeaveDurationField;
