import { useSelector, useDispatch } from 'react-redux';

export const SET_SETTING = 'SET_PAYROLL_REPORTS_SETTING';

export type Settings = {
  [key: string]: any;
};

export const setSetting = (payload: any) => ({
  type: SET_SETTING,
  payload,
});

export interface PayrollReportsSettingState {
  isLatestReportsVisibleOnly: boolean;
}

const initialState = {
  isLatestReportsVisibleOnly: false,
};

export const payrollReportsSettingsReducer = (state = initialState, action: any = {}) => {
  if (action.type === SET_SETTING) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

const usePayrollReportSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector(
    (state: { payrollReportsSettings: PayrollReportsSettingState }) => state?.payrollReportsSettings,
  );
  const { isLatestReportsVisibleOnly }: Settings = settings;

  const updateSettings = (updatedSettings: Settings) => {
    dispatch(
      setSetting({
        ...settings,
        ...updatedSettings,
      }),
    );
  };

  const setIsLatestReportsVisibleOnly = (isChecked: boolean) => {
    updateSettings({
      isLatestReportsVisibleOnly: isChecked,
    });
  };

  return {
    setIsLatestReportsVisibleOnly,
    isLatestReportsVisibleOnly,
  };
};

export default usePayrollReportSettings;
