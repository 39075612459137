// @ts-nocheck
import EditButton from 'uibuilder/button/EditButton';
import EntityHeader from 'shared/layout/EntityHeader';
import { BreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { DateField, EmailField, PhoneField, TextField } from 'shared/uibuilder/field';
import MediumSectionRow from 'shared/uibuilder/layout/MediumSectionRow';
import AdditionalContactsField from 'erp/shared/field/AdditionalContactsField';
import CommaListField from 'shared/uibuilder/field/CommaListFIeld';
import React from 'react';
import useTestingCrudService from 'integration-tests/testingCrudService';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { BigAvatar } from 'artifact';
import ProfileMenuItem from 'uibuilder/menuitem/ProfileMenuItem';
import SidebarMenuSubItem from 'shared/uibuilder/menu/SidebarMenuSubItem';
import TimelineMenuItem from 'uibuilder/menuitem/TimelineMenuItem';
import showData from 'shared/uibuilder/show/__integration-tests__/showData';

const buttons = [<EditButton permissionToCheck={null} icon={<i className="fa fa-edit" />} />];

const entityHeader = <EntityHeader entityTitleSource="name.fullName" />;

const breadcrumbs = (
  <Breadcrumbs>
    <BreadcrumbItem link="#">List</BreadcrumbItem>
    <BreadcrumbItem>John Black</BreadcrumbItem>
  </Breadcrumbs>
);

const menu = (
  <SidebarStickyMenu avatar={<BigAvatar source="mainPhotoId" />}>
    <ProfileMenuItem exact linkUrl="">
      <SidebarMenuSubItem name="Main Information" />
      <SidebarMenuSubItem name="Contact Information" />
      <SidebarMenuSubItem name="Skills" />
    </ProfileMenuItem>
    <TimelineMenuItem linkUrl="/timeline" />
  </SidebarStickyMenu>
);

const IntegrationShowPage = () => {
  const { getById } = useTestingCrudService();

  return (
    <Show getDataMethod={getById}>
      <ShowPageLayout menu={menu} buttons={buttons} entityHeader={entityHeader} breadcrumbs={breadcrumbs}>
        <ShowSection title="The first section" hasAnchorLink>
          <SectionRow>
            <TextField source="name.firstName" label="First Name" />
            <TextField source="name.lastName" label="Last Name" />
            <DateField source="dateOfBirth" label="Date of Birth" />
            <EmailField source="email" isVisible={data => data.email === showData.email} />
            <PhoneField source="phone" />
            <TextField source="data" isVisible={data => data.email !== showData.email} />
          </SectionRow>
        </ShowSection>

        <ShowSection title="The second section" hasAnchorLink>
          <MediumSectionRow>
            <AdditionalContactsField source="additionalContacts" />
            <CommaListField source="skills" />
          </MediumSectionRow>
        </ShowSection>

        <ShowSection title="Third section" isVisible={data => data.email !== showData.email} />
      </ShowPageLayout>
    </Show>
  );
};

export default IntegrationShowPage;
