import useCrudService from 'shared/crud';
import { useFinanceApi } from 'api';
import { cloneDeep } from 'lodash';
import serviceBudgetsStructureValidation from './structure/Create/serviceBudgetsStructureValidation';

export const BUDGETING_PATH = '/budgeting';
export const BUDGETING_API_PATH = '/budgets';

export const BUDGETING_READ = 'READ';
export const BUDGETING_CREATE = 'CREATE';
export const BUDGETING_CREATE_STRUCTURE = 'SERVICE_MANAGEMENT_SERVICE_BUDGET_CREATE_STRUCTURE';
export const BUDGETING_APPROVE = 'SERVICE_MANAGEMENT_SERVICE_BUDGET_APPROVE';

const useBudgetingService = () => {
  const singleResourceUrl = '/budgets/:id';
  const {
    search: baseSearch,
    create,
    getById,
    update,
  } = useCrudService({
    singleResourceUrl,
    listResourceUrl: BUDGETING_API_PATH,
    apiService: useFinanceApi,
  });

  const singleStructureUrl = '/budgets/structures/:id';
  const { search: baseSearchStructure, create: baseCreateStructure } = useCrudService({
    singleResourceUrl: singleStructureUrl,
    listResourceUrl: `${BUDGETING_API_PATH}/structures`,
    apiService: useFinanceApi,
  });

  const { sendGetRequest, sendPostRequest } = useFinanceApi();

  const search = (request: any) => {
    const searchParams = cloneDeep(request);
    const searchString = searchParams.filter?.['specification:search']?.eq || '';

    if (searchString && searchParams.filter) {
      delete searchParams.filter?.['specification:search'];
      searchParams.filter.name = {
        ct: searchString,
      };
    }

    return baseSearch(searchParams);
  };

  const searchStructure = (budgetId: string, request: any) => {
    const searchParams = cloneDeep(request);
    if (searchParams.filter) {
      searchParams.filter['budget.id'] = {
        eq: budgetId,
      };
    } else {
      searchParams.filter = {
        'budget.id': {
          eq: budgetId,
        },
      };
    }
    return baseSearchStructure(searchParams);
  };

  const createStructure = async (id: string, request: any) => {
    const params = cloneDeep(request);
    params.budgetId = id;
    params.categories = params.categories.map((item: { name: string }) => item.name);
    return baseCreateStructure(params);
  };

  const getActiveStructure = async (budgetId: string, date: string | null) => {
    try {
      const queryParam = date ? `?date=${date}` : '';
      const result = await sendGetRequest(`/budgets/structures/${budgetId}/active${queryParam}`);
      return result.json();
    } catch {
      return { error: 'Not Found.' };
    }
  };

  const approveBudget = async (budgetId: string) => {
    const result = await sendPostRequest(`/budgets/versions/${budgetId}/approve`);
    return result.json();
  };

  const rejectBudget = async (budgetId: string, rejectionReason: string) => {
    const result = await sendPostRequest(`/budgets/versions/${budgetId}/reject`, { rejectionReason });
    return result.json();
  };

  const getValidationSchema = () => Promise.resolve(serviceBudgetsStructureValidation);

  return {
    create,
    update,
    search,
    getById,
    getActiveStructure,
    searchStructure,
    createStructure,
    approveBudget,
    rejectBudget,
    getValidationSchema,
  };
};

export default useBudgetingService;
