import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import React from 'react';
import EqCondition from 'shared/uibuilder/list/filter/condition/EqCondition';
import {
  Filter,
  FilterCondition,
  useFilterContext,
} from 'shared/uibuilder/list/filter/FilterContext';

type InterviewTypeFilterProps = {
  source: string,
  label: string
};

const INTERVIEW_TYPES = {
  roleRelated: 'Role-related',
  general: 'General'
};

const InterviewTypeFilter = ({source, label, ...props}: InterviewTypeFilterProps) => {
  const { filterBy } = useFilterContext();

  const handleChange = (data: Filter = {}) => {
    const value = data[source][FilterCondition.EQ];

    filterBy({
      [source]: {
        [FilterCondition.EQ]: value ,
      },
    });
  };

  const mapOptions = () => Object.entries(INTERVIEW_TYPES)
    .map(([key, value]) => ({ label: value, value: key }));

  return <EqCondition {...props} source={source} onChangeCallback={handleChange}>
    <Dropdown
      {...props}
      placeholder="Select interview type"
      options={mapOptions()}
      source={source} />
  </EqCondition>;
};

export default InterviewTypeFilter;
