import React from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';

export interface EnumFieldProps extends FieldProps {
  options: Dictionary<string>;
}

const EnumField = ({ options, ...props }: EnumFieldProps) => {
  const { getValue, getLabel } = useFieldHelper(props);

  const displayValue = options[getValue()];

  return <TextField value={displayValue} label={getLabel()} />;
};

export default EnumField;
