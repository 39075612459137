/* istanbul ignore file */
import React from 'react';
import { NotesMenuItem, SummaryMenuItem } from 'uibuilder/menuitem';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { useAssetId, useAssetUrl } from 'financialAnalytic/assets/AssetRouter';

const AssetMenu = () => {
  const id = useAssetId();
  const { getTimelineUrl, getSingleEntityUrl } = useAssetUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl(id)} />
      <NotesMenuItem linkUrl={getTimelineUrl(id)} />
    </SidebarStickyMenu>
  );
};

export default AssetMenu;
