/* istanbul ignore file */
import React from 'react';
import OneClick from 'authentication/OneClick';
import { Route } from 'shared/routing';
import { APPLICATION_URL } from 'ApplicationRouter';
import isOneClickEnabled from 'authentication/OneClick/isOneClickEnabled';
import AzureCallbackPage from 'authentication/azure/AzureCallbackPage';
import { useSearchParameter } from 'shared/routing/helper';

export const AZURE_ACCESS_CODE = 'code';
export const AZURE_ACCESS_ERROR = 'error';
export const ONE_CLICK_PATH = '/signin/oneclick';
export const OAUTH_AZURE_PATH = '/oauth/azure';
export const AZURE_LOGOUT_PATH = `${OAUTH_AZURE_PATH}/logout`;
export const AZURE_CALLBACK_PATH = `${OAUTH_AZURE_PATH}/callback`;
export const AZURE_CALLBACK_URL = process.env.REACT_APP_DAVINCI_FRONTEND_AZURE_CALLBACK;
export const AZURE_TENANT_ID = process.env.REACT_APP_DAVINCI_FRONTEND_AZURE_TENANT_ID;
export const AZURE_LOGOUT_URL = process.env.REACT_APP_DAVINCI_FRONTEND_AZURE_LOGOUT_URL;
export const AZURE_CLIENT_ID = process.env.REACT_APP_DAVINCI_FRONTEND_AZURE_CLIENT_ID;

export const useAuthenticationUrl = () => {
  const getAzureAuthUrl = () => {
    return (
      'https://login.microsoftonline.com/' +
      `${AZURE_TENANT_ID}/` +
      'oauth2/authorize?' +
      'response_type=code&' +
      `redirect_uri=${encodeURIComponent(APPLICATION_URL || '')}` +
      `${encodeURIComponent(AZURE_CALLBACK_URL || '')}&` +
      'response_mode=query&' +
      `client_id=${AZURE_CLIENT_ID}`
    );
  };

  const getAzureLogoutUrl = () => {
    return `${AZURE_LOGOUT_URL}?post_logout_redirect_uri=${encodeURIComponent(APPLICATION_URL || '')}`;
  };

  return {
    getAzureAuthUrl,
    getAzureLogoutUrl,
  };
};

export const useAzureAccessCode = () => {
  return useSearchParameter(AZURE_ACCESS_CODE);
};

export const useAzureAccessError = () => {
  return useSearchParameter(AZURE_ACCESS_ERROR);
};

const AuthenticationRouter = () =>
  [
    isOneClickEnabled() && <Route key={ONE_CLICK_PATH} path={ONE_CLICK_PATH} element={<OneClick />} />,
    <Route key={AZURE_LOGOUT_PATH} path={AZURE_LOGOUT_PATH} element={null} />,
    <Route key={AZURE_CALLBACK_PATH} path={AZURE_CALLBACK_PATH} element={<AzureCallbackPage />} />,
  ].filter(Boolean);

export default AuthenticationRouter;
