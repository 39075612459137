/* istanbul ignore file */

import { ACCOUNTS_WITH_BUDGET_CATEGORIES } from 'erp/serviceCatalog/budgets/shared/service/budgetCatagorySuggestionService';

export default {
  type: {
    type: 'string',
    required: true,
  },
  vendor: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          nin: ['BLANKET'],
        },
      },
    },
  },
  title: {
    type: 'string',
    required: true,
  },
  office: {
    type: 'string',
    required: true,
  },
  account: {
    type: 'string',
    required: true,
  },
  budgetCategory: {
    type: 'string',
    required: {
      value: true,
      when: {
        $account: {
          in: ACCOUNTS_WITH_BUDGET_CATEGORIES,
        },
      },
    },
  },
  purchasePurpose: {
    type: 'string',
    maxSize: 512,
    required: true,
  },
  requiredDeliveryDate: {
    type: 'date',
  },
  expirationDate: {
    type: 'date',
  },
  currency: {
    type: 'string',
    required: true,
  },
  requestor: {
    type: 'string',
    required: true,
  },
  purchases: {
    type: 'array',
    required: true,
    minSize: 1,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        name: {
          type: 'string',
          required: true,
          maxSize: 255,
        },
        quantity: {
          type: 'number',
          required: {
            value: true,
            when: {
              $type: {
                in: ['STANDARD'],
              },
            },
          },
        },
        cost: {
          type: 'object',
          properties: {
            amount: {
              type: 'number',
              required: true,
            },
          },
        },
      },
    },
  },
};
