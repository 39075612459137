import React from 'react';
import Typography from 'uibuilder/Typography';
import Box from 'uibuilder/Box';

import './index.scss';
import { SxProps } from '@mui/system';

type RowGroupInputsWrapperProps = {
  wrapperLabel?: string;
  isRequired?: boolean;
  children: React.ReactNode;
  sxProps?: SxProps;
};

const RowGroupInputsWrapper = ({ isRequired, wrapperLabel, sxProps, children }: RowGroupInputsWrapperProps) => {
  return (
    <Box>
      {wrapperLabel ? (
        <Typography
          sx={{
            mb: '3px',
          }}
        >
          {wrapperLabel}
          {isRequired ? ' *' : ''}
        </Typography>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',

          '& > div:not(:last-child)': {
            mr: '10px',
          },

          '& > div': {
            flexWrap: 'wrap',
          },

          '& > div > .label__wrapper': {
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: 'auto',
          },
          '& > div > input': {
            flex: '1 1 0',
          },
          '& > div > .Mui-error': {
            flexBasis: '100%',
          },
          ...sxProps,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default RowGroupInputsWrapper;
