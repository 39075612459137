/* istanbul ignore file */
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Scroll from 'shared/uibuilder/Scroll';
import ApplicationRouter from 'ApplicationRouter';
import { store, persistor } from 'uibuilder/reducer/store';
import DavinciSettings from 'uibuilder/DavinciSettings';
import PageTitle from 'pageTitle';
import SingletonHooksProvider from 'SingletonHooksProvider';
import useArtifactsInfoReceiver from 'artifact/artifactsInfoReceiver';
import useInvoiceArtifactsInfoReceiver from 'artifact/invoiceArtifactsInfoReceiver';
import useEmployeeNamesInfoReceiver from 'erp/employee/shared/employeeNameInfoReceiver';
import Audit from 'shared/audit/Audit';
import UrlTokenProvider from './security/UrlTokenProvider';
import GlobalPermissionsProvider from 'authorization/GlobalPermissionsProvider';
import AuthenticationProviderWrapper from 'authentication/AuthenticationProviderWrapper';
import CustomThemeProvider from 'uibuilder/themes/themeProvider';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import './App.scss';

const App = () => (
  <StyledEngineProvider injectFirst>
    <CustomThemeProvider>
      <CssBaseline />
      <Provider store={store}>
        {/* @ts-ignore */}
        <PersistGate loading={null} persistor={persistor}>
          <AuthenticationProviderWrapper>
            <Scroll />
            <Audit>
              <UrlTokenProvider>
                <SingletonHooksProvider
                  hooksForEagerInitialization={[
                    useArtifactsInfoReceiver,
                    useEmployeeNamesInfoReceiver,
                    useInvoiceArtifactsInfoReceiver,
                  ]}
                />
              </UrlTokenProvider>
              <DavinciSettings>
                <PageTitle
                  allowedSubParts={['crm', 'one-stop', 'signin', 'audit', 'processes', 'payroll', 'my-activity']}
                  specificSubParts={['qualification-models']}
                >
                  <GlobalPermissionsProvider>
                    <ApplicationRouter />
                  </GlobalPermissionsProvider>
                </PageTitle>
              </DavinciSettings>
            </Audit>
          </AuthenticationProviderWrapper>
        </PersistGate>
      </Provider>
    </CustomThemeProvider>
  </StyledEngineProvider>
);

export default App;
