/* istanbul ignore file */
import { UpdateForm } from 'shared/uibuilder/form';
import React from 'react';
import useActivityService from 'crm/shared/timeline/activity/crmActivityService';
import ActivityForm from 'crm/shared/timeline/activity/form/ActivityForm';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';

const UpdateActivityForm = ({ getDataFunc, onCancel, ...props }: any) => {
  const { getValidationSchema, update } = useActivityService();
  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      getValidationSchemaFunc={getValidationSchema}
      {...props}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <ActivityForm isEditForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

export default UpdateActivityForm;
