/* istanbul ignore file */
// libs
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
// services
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import { COMPETENCIES_URL } from './competencyService';
import CompetencyViewPage from './Show';
import CompetencyContext from './CompetencyContext';

export const useCompetencyRoute = () => {
  return useRoute({ listRoute: COMPETENCIES_URL });
};

export const useCompetencyUrl = () => {
  return useEntityUrl({
    baseLink: COMPETENCIES_URL,
  });
};

export const useCompetencyId = () => {
  return useParams<Dictionary<string>>().id!;
};

const CompetencyRouter = () => {
  const { singleEntityRoute } = useCompetencyRoute();

  return (
    <ProtectedRouter basePath={COMPETENCIES_URL}>
      <Routes>
        <Route
          path={singleEntityRoute}
          element={
            <CompetencyContext permissionToCheck="READ">
              <CompetencyViewPage />
            </CompetencyContext>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

CompetencyRouter.getRouterPath = () => `${COMPETENCIES_URL}/*`;

export default CompetencyRouter;
