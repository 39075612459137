/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import CurrentUserAvatarField from 'uibuilder/page/header/CurrentUserAvatarField';
import useAzureAuthenticator from 'authentication/azure/azureAuthenticator';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from 'uibuilder/Typography';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';

const CurrentUserActions = ({ userName }: { userName?: Dictionary<string> }) => {
  const { logout } = useAzureAuthenticator();
  const { firstName = '', lastName = '', maidenName = null } = userName || {};

  return userName === null ? null : (
    <DropdownMenu
      popupId="user-header-menu"
      iconButton={
        <IconButton size="large">
          <CurrentUserAvatarField />
        </IconButton>
      }
    >
      <Typography
        sx={{
          pointerEvents: 'none',
          padding: '8px 16px',
          background: (theme: any) => theme.vars.palette.common.surface3,
          marginTop: '-4px',
          margitBottom: '3px',
        }}
        variant="subtitle1"
      >
        {`Welcome, ${firstName} ${lastName}`}
        {maidenName ? ` (${maidenName})` : ''}
      </Typography>
      <MenuItem component={Link} to="/settings">
        <SettingsIcon />
        Settings
      </MenuItem>
      <MenuItem component={Link} to="/oauth/azure/logout" onClick={logout}>
        <LogoutIcon />
        Logout
      </MenuItem>
    </DropdownMenu>
  );
};

export default CurrentUserActions;
