import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useMatch } from 'react-router-dom';
import useTimelinePaging from 'shared/uibuilder/timeline/paging/timelinePagingHelper';
import {
  useListCrud,
  useListParams,
  useListDataLoading,
  useListUrlStoring,
  useListLocalStorageStoring,
  useListParamsConcatenation,
} from 'shared/uibuilder/list/builder';
import getLoadingParams from 'shared/uibuilder/list/builder/listBuilderHelper';
import { TimelineProps } from './Timeline';
import { ListResponse } from '../list/builder/useListCrud';
import { UrlParams } from 'shared/routing/useEntityUrl';
import { Filter } from '../list/filter/FilterContext';

const pageSize = 30;

interface TimeLineHelperParams extends TimelineProps {
  defaultSortOrder?: string;
  defaultSortField?: string;
  timelineEntryIdSource: string;
  defaultFilter?: Filter;
}

export type TimelineItemId = Nullable<StringOrNumber>;
export type TimelineItem = Nullable<{
  id: TimelineItemId;
  timelineId: TimelineItemId;
  createdById: number;
  updatedAt: string;
  createdAt: string;
  artifacts: any[];
}>;
export type TimelineItems = List<TimelineItem>;
export type TimelineResponseData = Nullable<ListResponse<TimelineItems>>;

export type TimelineEntity = {
  id?: TimelineItemId;
  authorId?: number;
  updatedAt?: string;
  createdAt?: string;
  artifacts?: object[];
  [key: string]: any;
};

const useTimeline = ({
  getDataMethod,
  getPageByEntryIdMethod,
  registries,
  createTimelineItemMethod,
  timelineEntryIdSource,
  defaultSortOrder = 'DESC',
  defaultSortField = 'createdAt',
  defaultFilter = {},
}: TimeLineHelperParams) => {
  const location = useLocation();

  const match = useMatch(location.pathname);

  const path = match ? match.pattern.path : null;

  const [currentEditingId, setCurrentEditingId] = useState<TimelineItemId>(null);
  const [addForm, setAddForm] = useState<Nullable<React.ReactElement>>(null);
  const timelineUrlParams: UrlParams = useParams<Dictionary<string>>();
  const timelineEntryId = timelineUrlParams[timelineEntryIdSource];

  const isEntryViewMode = timelineEntryId && !!getPageByEntryIdMethod;

  const { params: urlParams, updateParamsInUrl } = useListUrlStoring();
  const { params: localStorageParams, updateParamsInLocalStorage } = useListLocalStorageStoring({
    localStorageKey: path,
  });
  const { params: initialParams } = useListParamsConcatenation({ urlParams, localStorageParams });
  const { params, goToPage, filterBy, resetParamsToDefault } = useListParams({
    initialParams,
    defaultSortOrder,
    defaultSortField,
    defaultFilter,
    onParamsChangeCallbacks: [updateParamsInUrl, updateParamsInLocalStorage],
  });

  const {
    loading,
    setLoading: setGlobalLoading,
    loadData: _loadData,
  } = useListDataLoading({
    getDataMethod,
    resetParamsToDefault,
  });
  const { data, setData, updateInList: _updateInList, addToList, deleteFromList } = useListCrud();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadingParams = useMemo(() => getLoadingParams(params, pageSize), [params.filter, params.sort]);

  const pagingMethods = useTimelinePaging({
    currentPage: params.page || 0,
    loadData: (pageNumber: number) => _loadData({ ...loadingParams, pageNumber }, false),
    setData,
    data,
  });

  const handleLoading = async (loadDataMethod: () => Promise<TimelineResponseData>) => {
    setGlobalLoading(true);
    const response = await loadDataMethod();
    setData({
      items: response?.result || [],
      totalPages: response?.totalPages || 0,
    });
    goToPage(response?.pageNumber || 0);
    setGlobalLoading(false);
  };

  const loadData = () => {
    if (timelineEntryId && getPageByEntryIdMethod) {
      return handleLoading(() => getPageByEntryIdMethod(timelineEntryId));
    } else {
      return handleLoading(() => _loadData(loadingParams, false));
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingParams]);

  /**
   * Wraps item's object to timeline entry's object
   */
  const wrapItem = (item: TimelineItem, source: string): TimelineEntity => {
    const mainData = {
      id: item?.timelineId,
      authorId: item?.createdById,
      updatedAt: item?.updatedAt,
      createdAt: item?.createdAt,
      artifacts: item?.artifacts || [],
    };

    if (!source) {
      return {
        ...mainData,
        ...item,
      };
    }

    return {
      [source]: item,
      ...mainData,
    };
  };

  const updateInList = (id: TimelineItemId, newEntity: TimelineItem) => {
    _updateInList(newEntity);
  };

  const setCurrentEditingIdMethod = (id: TimelineItemId) => {
    setCurrentEditingId(id);
    setAddForm(null);
  };

  const setAddFormMethod = (form: Nullable<React.ReactElement>) => {
    setAddForm(form);
    setCurrentEditingId(null);
  };

  const isHighlighted = (id: TimelineItemId) => {
    return !!id && String(id) === String(timelineEntryId);
  };

  return {
    ...pagingMethods,
    filter: params.filter,
    sort: params.sort,
    filterBy,
    isEntryViewMode,
    data,
    setCurrentEditingId: setCurrentEditingIdMethod,
    currentEditingId,
    setAddForm: setAddFormMethod,
    addForm,
    registries,
    wrapItem,
    createTimelineItemMethod,
    isHighlighted,
    loading,
    // CUD Operations
    addToList,
    deleteFromList,
    updateInList,
    reloadData: loadData,
  };
};

export default useTimeline;
