/* istanbul ignore file */
export default {
  value: {
    type: 'number',
    required: true,
    regex: {
      value: /^[-]?((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
      message: 'Invalid number.',
    },
  },
};
