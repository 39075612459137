/* istanbul ignore file */
import React from 'react';
import { useOneStopHistoryUrl } from 'myActivity/MyActivityRouterHelper';
import { Navigate } from 'react-router-dom';
import { useSearchParametersString } from 'shared/routing/helper';

const OneStopHistoryRedirect = () => {
  const { getSingleEntityUrl } = useOneStopHistoryUrl();
  const params = useSearchParametersString();

  const url = `${getSingleEntityUrl()}${params}`;
  return <Navigate to={url} replace />;
};

export default OneStopHistoryRedirect;
