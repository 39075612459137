/* istanbul ignore file */
import React from 'react';
import { LinkField } from 'shared/uibuilder/field';
import { LinkFieldProps } from 'shared/uibuilder/field/LinkField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { getValue } from 'shared/uibuilder/helper';
import getDocusignEnvelopeBasePath from 'shared/docusign/envelope/docusignConfiguration';

const DocuSignEnvelopeLinkField = ({ source, value = '', ...props }: LinkFieldProps) => {
  const { data } = useShowContext();
  const { getValue: getValueFromFieldHelper } = useFieldHelper({ source });

  const getFieldValue = () => {
    const valueFromShowContext = getValue(value, (src?: string) => data && data.getValueBySource(src!), source, '');

    if (valueFromShowContext) {
      return valueFromShowContext;
    }

    return getValueFromFieldHelper();
  };

  const docusignId = getFieldValue();

  const baseDocusignPath = getDocusignEnvelopeBasePath();
  const linkValue = `${baseDocusignPath}${docusignId}`;

  return <LinkField value={linkValue} {...props} linkText={docusignId} />;
};

export default DocuSignEnvelopeLinkField;
