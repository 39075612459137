/* istanbul ignore file */
import React from 'react';
import Typography from 'uibuilder/Typography';
import SingleProjectWeeklyReportBreadcrumbs from 'erp/project/weeklyreports/shared/SingleProjectWeeklyReportBreadcrumbs';
import ShowProjectWeeklyReport from 'erp/project/weeklyreports/show/ShowProjectWeeklyReport';
import useProjectWeeklyReportsService from 'erp/project/weeklyreports/ProjectWeeklyReportService';
import { useParams } from 'react-router-dom';
import Show from 'shared/uibuilder/show/Show';
import EditProjectWeeklyReportButton from 'erp/project/weeklyreports/shared/button/EditProjectWeeklyReport';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ViewProjectPageMenu from 'erp/project/Show/ViewProjectPageMenu';
import { UPDATE_PROJECT_WEEKLY_REPORT } from 'erp/project/weeklyreports/ProjectWeeklyReportRouter';

const ViewProjectWeeklyReport = () => {
  const { reportId } = useParams<Dictionary<string>>();
  const { getById } = useProjectWeeklyReportsService();

  return (
    <ShowPageLayout
      entityHeader={
        <Typography variant="h1" className="c-title">
          Weekly Report
        </Typography>
      }
      menu={<ViewProjectPageMenu />}
      breadcrumbs={<SingleProjectWeeklyReportBreadcrumbs />}
      buttons={[
        <EditProjectWeeklyReportButton
          source="reportId"
          key="edit-project-weekly-report"
          permissionToCheck={UPDATE_PROJECT_WEEKLY_REPORT}
        />,
      ]}
    >
      <Show getDataMethod={() => getById(reportId)}>
        <ShowProjectWeeklyReport />
      </Show>
    </ShowPageLayout>
  );
};

export default ViewProjectWeeklyReport;
