import React from 'react';
import { BooleanField, EnumField, ListField, TextField } from 'shared/uibuilder/field';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { EMPLOYEE_CONTRACT_ASSIGMENT_TYPES } from 'erp/employee/contracts/shared/input/EmployeeContractAssignmentTypeRadios';
import { RELOCATION_OPTIONS, WORKING_SCHEDULE } from 'erp/recruitment/vacancy/shared/vacancyService';
import ContractTypeField from 'erp/contracts/shared/field/ContractTypeField';
import { EMPLOYEE_WORKPLACE_LOCATION } from 'erp/employee/employeeService';
import VacancyOfficeField from 'erp/recruitment/vacancy/shared/field/VacancyOfficeField';

type VacancyDetailsFieldProps = {
  value?: any;
};

const VacancyDetailsField = ({ value = {} }: VacancyDetailsFieldProps) => {
  return (
    <SectionRow>
      <VacancyOfficeField label="Location (Office)" value={value.office} />
      <ListField value={value.assignmentType} label="Employment Type">
        <EnumField options={EMPLOYEE_CONTRACT_ASSIGMENT_TYPES} />
      </ListField>
      <EnumField options={RELOCATION_OPTIONS} value={value.relocationOption} label="Relocation Options" />
      <BooleanField value={value.hasProbation} label="Has Probation" />
      <ListField value={value.contractType} label="Contract Type">
        <ContractTypeField />
      </ListField>
      <ListField value={value.workplaceLocation} label="Working Mode">
        <EnumField options={EMPLOYEE_WORKPLACE_LOCATION} />
      </ListField>
      <EnumField value={value.workingSchedule} label="Working Schedule" options={WORKING_SCHEDULE} />
      <TextField
        isVisible={!!value.workingScheduleDescription}
        value={value.workingScheduleDescription}
        label="Working Schedule Description"
      />
    </SectionRow>
  );
};

export default VacancyDetailsField;
