import React from 'react';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useDropdownHelper, { DefaultDropdownProps, Option } from './dropdownHelper';
import { DefaultDropdownLayoutProps } from './Dropdown';

export interface GroupOptionType {
  options: Option[];
  label: string;
}

export type SelectInputOptions = Option | GroupOptionType;

interface BaseSelectInputProps {
  filterOption?: (option: { data: Option }, rawInput: string) => boolean;
  noOptionsMessage?: () => string;
}

export interface SelectInputLayoutProps
  extends DefaultDropdownLayoutProps<SelectInputOptions[]>,
    BaseSelectInputProps {}

export type SelectInputLayoutType = ReactComponent<SelectInputLayoutProps>;

export interface SelectInputProps extends DefaultDropdownProps<SelectInputOptions[]>, BaseSelectInputProps {}

const SelectInput = ({ filterOption, noOptionsMessage, options, ...props }: SelectInputProps) => {
  const { SelectInputLayout } = useUiTheme<SelectInputLayoutType>();
  const dropdownHelper = useDropdownHelper(props);

  return (
    <SelectInputLayout
      filterOption={filterOption}
      noOptionsMessage={noOptionsMessage}
      isVisible={dropdownHelper.isVisible()}
      label={dropdownHelper.getLabel()}
      source={dropdownHelper.getSource()}
      onChangeCallback={dropdownHelper.getOnChangeCallback()}
      onBlurCallback={dropdownHelper.getValidationCallback()}
      onFocusCallback={dropdownHelper.getClearValidationCallback()}
      value={dropdownHelper.getValue()}
      errorMessages={dropdownHelper.getErrorMessages()}
      isRequired={dropdownHelper.getIsRequired()}
      options={options}
      disabled={dropdownHelper.getIsDisabled()}
      placeholder={dropdownHelper.getPlaceholder()}
      className={dropdownHelper.getClassName()}
    />
  );
};

export default SelectInput;
