/* istanbul ignore file */
import React from 'react';
import usePageLoad from 'shared/audit/usePageLoad';
import Box from 'uibuilder/Box';

const formatSeconds = (time: number | null) => {
  return `Page loaded in ${((time || 0) / 1000).toFixed(2)} second(s)`;
};

const DefaultFooter = () => {
  const currentYear = new Date().getFullYear();
  const { getLoadTime } = usePageLoad();

  return (
    <Box
      sx={(theme: any) => ({
        zIndex: '-1',
        position: 'relative',
        padding: '3px 1rem',
        color: theme.vars.palette.common.sidebarMenuText,
        background: theme.vars.palette.common.sidebarMenuBg,
        borderTop: `1px solid ${theme.vars.palette.common.sidebarMenuActive}`,
        fontSize: '12px',
        textAlign: 'center',
        '.load-info': {
          [theme.breakpoints.up('md')]: {
            position: 'absolute',
            right: '1rem',
            bottom: '3px',
          },
          [theme.breakpoints.down('md')]: {
            marginTop: '5px',
          },
        },
      })}
    >
      © 2019 - {currentYear} Syberry Corporation. Proprietary and Confidential.
      <div className="load-info">{formatSeconds(getLoadTime())}</div>
    </Box>
  );
};

export default DefaultFooter;
