import React, { useEffect, useState } from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import useRoleService from 'erp/roleonproject/roleService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const RolesDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => {
  const { findAll } = useRoleService();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const roles = await findAll();
      const data = roles.map((role: any) => ({
        value: role.key,
        label: role.name,
      }));
      setOptions(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Dropdown {...props} placeholder="Select role" options={options} />;
};

export default RolesDropdown;
