/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import { Route } from 'shared/routing';
import { useIntegrationTestRoutes } from 'integration-tests/Router';
import IntegrationShowPage from 'shared/uibuilder/show/__integration-tests__/ShowPage';
import IntegrationTimelinePage from 'shared/uibuilder/timeline/__integration-tests__/TimelinePage';
import IntegrationListPage from 'shared/uibuilder/list/__integration-tests__/ListPage';
import IntegrationCreateFormPage from 'shared/uibuilder/form/__integration-tests__/CreateFormPage';
import IntegrationUpdateFormPage from 'shared/uibuilder/form/__integration-tests__/UpdateFormPage';

const IntegrationCrudRouter = () => {
  const { singleEntityRoute, createRoute, updateRoute, listRoute, timelineRoute } = useIntegrationTestRoutes();

  return (
    <Routes>
      <Route path={timelineRoute} element={<IntegrationTimelinePage />} />
      <Route path={createRoute} element={<IntegrationCreateFormPage />} />
      <Route path={updateRoute} element={<IntegrationUpdateFormPage />} />
      <Route path={listRoute} element={<IntegrationListPage />} />
      <Route path={singleEntityRoute} element={<IntegrationShowPage />} />
    </Routes>
  );
};

export default IntegrationCrudRouter;
