/* istanbul ignore file */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthorization from 'shared/authorization/authorizationService';
import { useCandidateId, useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { READ_TIMELINE } from 'erp/candidate/shared/candidateService';

const CandidateIdPage = () => {
  const { isGranted } = useAuthorization();
  const navigate = useNavigate();
  const id = useCandidateId();
  const { getTimelineUrl, getProfileUrl } = useCandidateUrl();

  useEffect(() => {
    if (isGranted(READ_TIMELINE)) {
      navigate(getTimelineUrl(id), { replace: true });
    } else {
      navigate(getProfileUrl(id), { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CandidateIdPage;
