/* istanbul ignore file */
import React from 'react';
import { DateInput } from 'shared/uibuilder/form/input';
import { CommonDateInputProps } from '../../../../../../shared/uibuilder/form/input/dateHelper';

const CalendarRightDateInput = (props: CommonDateInputProps) => {
  return (
    <div className="calendar-right">
      <DateInput {...props} />
    </div>
  );
};

export default CalendarRightDateInput;
