import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Stack } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Draggable from 'shared/dnd/Draggable';
import { formatTime, getShortName, getTaskStatusByDuration } from './utils';
import Typography from 'uibuilder/Typography';
import { useDateService } from 'shared/uibuilder/dateService';
import IconMenu from '../IconMenu';
import { MINUTE, stepConfigurations } from '../../constants';
import { TaskStyles } from '../../types';
import useRecruitingBoardService from '../../useRecruitingBoardService';
import CandidateLink from '../../../../candidate/shared/CandidateLink';

interface DraggableItemProps {
  item: any;
  columnSettings: any;
}

const getCardStyles = (deadlineStyle: TaskStyles) => ({
  borderRadius: 1,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: deadlineStyle.borderColor,
  background: deadlineStyle.background,
  minWidth: '200px',
});

const DragHandle = React.forwardRef<HTMLDivElement, { background: string }>(({ background }, ref) => (
  <div
    ref={ref}
    style={{
      minHeight: '100%',
      width: '10px',
      background,
      cursor: 'grab',
    }}
  >
    <DragIndicatorIcon sx={{ width: '10px', height: '100%' }} fontSize="small" />
  </div>
));

const Content: React.FC<{ item: any; formattedTime: string; deadlineStyle: any; completeJobStep?: any }> = ({
  item,
  formattedTime,
  completeJobStep = null,
  deadlineStyle,
}) => (
  <Stack
    sx={{ padding: '2px 5px 2px 4px', fontSize: '10px' }}
    direction="column"
    alignItems="start"
    justifyContent="space-between"
    flex={1}
  >
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <CandidateLink
        color="#4360E5"
        style={{
          display: 'inline-block',
          maxWidth: '110px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        id={item?.candidateId}
        name={getShortName(item?.name)}
      />

      <Stack direction="row" gap="2px">
        {stepConfigurations[item.stage]?.map(config => (
          <IconMenu
            icon={config?.icon}
            items={config?.states}
            defaultKey={
              config?.states?.find(state => item.activeStates.includes(state.key))?.key || config.states[0].key
            }
            onChange={(key, payload) => {
              const event = config.states.find(state => state.key === key)?.event;
              completeJobStep(item.id, config.job, event, payload);
            }}
          />
        ))}
      </Stack>
    </Stack>
    <Stack direction="row" gap="2px" alignItems="center">
      <AccessTimeIcon sx={{ color: deadlineStyle.borderColor, width: '10px', height: '10px' }} />
      <Typography sx={{ color: deadlineStyle.borderColor, fontSize: '9px', lineHeight: 1.6 }}>
        {formattedTime}
      </Typography>
    </Stack>
  </Stack>
);

const DraggableItem: React.FC<DraggableItemProps> = ({ item, columnSettings }) => {
  const { getCurrentDateTime, getTimeDifference } = useDateService();
  const [timeElapsed, setTimeElapsed] = useState(getCurrentDateTime());
  const { completeJobStep } = useRecruitingBoardService();

  useEffect(() => {
    const intervalId = setInterval(() => setTimeElapsed(getCurrentDateTime()), MINUTE);
    return () => clearInterval(intervalId);
  }, [getCurrentDateTime]);

  const timeDifference = useMemo(() => {
    const diff = getTimeDifference(item.eventTimestamp, timeElapsed);
    return Math.max(0, diff);
  }, [item.eventTimestamp, timeElapsed, getTimeDifference]);

  const deadlineStyle = useMemo(
    () => getTaskStatusByDuration(timeDifference, columnSettings.deadlinesForStage),
    [timeDifference, columnSettings.deadlinesForStage],
  );

  const formattedTime = useMemo(() => formatTime(timeDifference), [timeDifference]);

  const renderPreview = useCallback(
    () => (
      <Card sx={getCardStyles(deadlineStyle)}>
        <Stack direction="row" alignItems="stretch">
          <DragHandle background={deadlineStyle.dragIconBackground} />
          <Content item={item} formattedTime={formattedTime} deadlineStyle={deadlineStyle} />
        </Stack>
      </Card>
    ),
    [deadlineStyle, formattedTime, item],
  );

  return (
    <Draggable canDrag={item.canDrag} isHandleRef preview={renderPreview()} initialData={item}>
      {({ draggableRef }) => (
        <Card sx={{ ...getCardStyles(deadlineStyle), opacity: item.canDrag ? 1 : 0.5 }}>
          <Stack direction="row" alignItems="stretch">
            <DragHandle ref={draggableRef} background={deadlineStyle.dragIconBackground} />
            <Content
              completeJobStep={completeJobStep}
              item={item}
              formattedTime={formattedTime}
              deadlineStyle={deadlineStyle}
            />
          </Stack>
        </Card>
      )}
    </Draggable>
  );
};

export default React.memo(DraggableItem);
