/* istanbul ignore file */
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { BooleanField, FieldProps } from 'shared/uibuilder/field';
import React from 'react';

const BusinessRulesViolatedField = (props: FieldProps<any[]>) => {
  const { getValue } = useFieldHelper(props);

  const rules = getValue();

  const areViolated = !!rules && !!rules.length;

  return <BooleanField {...props} value={areViolated} />;
};

export default BusinessRulesViolatedField;
