/* istanbul ignore file */
import React from 'react';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { PROBABILITY_MAP } from 'crm/opportunity/shared/opportunityService';

const OpportunityProbabilityField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const value = getValue();
  const displayedValue = value ? `${PROBABILITY_MAP[value]}%` : null;

  return <TextField {...props} value={displayedValue} />;
};

export default OpportunityProbabilityField;
