import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { HIRING_STATUS_OPTIONS } from '../../../hiring/useHiringService';

type HiringStatusFilterProps = {
  source: string;
  label: string;
};

const HiringStatusFilter = (props: HiringStatusFilterProps) => {
  return (
    <DropDownFilter {...props}>
      <EnumDropdown options={HIRING_STATUS_OPTIONS} {...props} />
    </DropDownFilter>
  );
};

export default HiringStatusFilter;
