import { useKernelApi } from 'api';
import EMPLOYEE_CONTRACT_VALIDATION from 'erp/employee/contracts/createupdate/employeeContractValidation';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { ASC } from 'shared/uibuilder/list/baseListHelper';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { EMPLOYEE_CONTRACT_TYPES_ALIASES } from './input/EmployeeContractTypeDropdown';
import { isPolishOffice } from 'erp/employee/office/officeService';
import { FormFieldsData } from '../../../../shared/uibuilder/form/FormContext';

export const CREATE_EMPLOYEE_CONTRACT = 'CREATE_CONTRACT';
export const FE_UPDATE_EMPLOYEE_CONTRACT = 'UPDATE_EMPLOYEE_CONTRACTS';
export const READ_EMPLOYEE_CONTRACTS_LIST = 'READ_CONTRACTS_LIST';
export const READ_EMPLOYEE_CONTRACT = 'READ';
export const UPDATE_EMPLOYEE_CONTRACT = 'UPDATE';
export const DELETE_EMPLOYEE_CONTRACT = 'DELETE';
export const SET_PROBATION_END_DATE = 'SET_PROBATION_END_DATE';
export const PAID_LEAVE_BALANCES = {
  20: '20 working days',
  26: '26 working days',
};
export const NON_STRICT_RULES = ['CONTRACT-BR-1'];

export const isB2BContract = (contractType: string) => {
  return EMPLOYEE_CONTRACT_TYPES_ALIASES.B2B.toString() === contractType;
};

export const isEmployeeContract = (formData: FormFieldsData) => {
  return EMPLOYEE_CONTRACT_TYPES_ALIASES.EC.toString() === formData.type.toString();
};

export const isEmployeeContractByType = (contractType: string) => {
  return EMPLOYEE_CONTRACT_TYPES_ALIASES.EC.toString() === contractType;
};

const useEmployeeContractsService = () => {
  const { sendGetRequest, sendPostRequest } = useKernelApi();
  const currentAlias = useEmployeeId();

  const baseCrudOperations = useBaseCrudService({
    singleResourceUrl: '/contracts/:id',
    listResourceUrl: '/contracts',
    apiService: useKernelApi,
  });

  const search = async ({
    pageNumber,
    sort = {
      dateOfSignature: ASC,
    },
    employeeAlias = currentAlias,
    pageSize,
  }: any) => {
    const [sortBy, sortOrder] = Object.entries(sort)[0];
    const params = [`pageNumber=${pageNumber}&pageSize=${pageSize}`];
    params.push(`sort[${sortBy}]=${sortOrder}`);

    const url = `/employees/${employeeAlias}/contracts?${params.join('&')}`;
    const response = await sendGetRequest(encodeURI(url));
    const data = await response.json();
    return data;
  };

  const getAll = async (employeeAlias: any) => {
    const url = `/employees/${employeeAlias}/contracts`;
    const response = await sendGetRequest(url);
    const contracts = await response.json();

    return contracts;
  };

  const mapContract = (data: any) => {
    const { number, paidLeaveBalance, ...rest } = data;

    return {
      ...rest,
      ...(!isPolishOffice(String(data.officeId)) && { number }),
      paidLeaveBalance: paidLeaveBalance === null ? 0 : paidLeaveBalance,
    };
  };

  const create = async (data: FormFieldsData, employeeAlias = currentAlias) => {
    const requestData = mapContract(data);

    const response = await sendPostRequest(`/employees/${employeeAlias}/contracts`, requestData);
    const result = await response.json();
    return result;
  };

  const { update: baseUpdate } = baseCrudOperations;

  const update = async (id: StringOrNumber, data: FormFieldsData) => {
    return baseUpdate(id, mapContract(data));
  };

  const getValidationSchema = () => Promise.resolve(EMPLOYEE_CONTRACT_VALIDATION);

  return {
    ...baseCrudOperations,
    search,
    create,
    update,
    getValidationSchema,
    getAll,
  };
};

export default useEmployeeContractsService;
