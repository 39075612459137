import { useEffect, useState } from 'react';
import useBaseExternalFormService from 'externalform/baseExternalFormService';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import { splitFamily } from 'erp/employee/employeeService';
import { CHILDREN_SOURCE, OPTION_YES } from '../shared/input/ChildrenInput';

export const mapToEmployee = (
  prepopulationData: {
    homeAddress: any;
    registrationAddress?: { tested: string };
    maritalStatus: any;
    familyMembers: any;
    militaryRecord: any;
  },
  formData: FormFieldsData,
) => {
  const familyData = splitFamily(prepopulationData.familyMembers);
  const result: any = {
    ...formData,
    extendedInformation: {
      ...formData.extendedInformation,
      homeAddress: prepopulationData.homeAddress,
      maritalStatus: prepopulationData.maritalStatus,
      militaryRecord: prepopulationData.militaryRecord,
      ...familyData,
    },
  };

  if (familyData.children.length) {
    result[CHILDREN_SOURCE] = OPTION_YES;
  }

  return result;
};

const usePrepopulation = ({ mapResponse }: { mapResponse?: any } = {}) => {
  const { findAll, getById } = useBaseExternalFormService();
  const { setFormData = () => {}, data: formData } = useFormContext();
  const employeeId = useEmployeeId()!;
  const [forms, setForms] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const response = await findAll(employeeId);

      setForms(response.result || []);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadForm = async (formId: string) => {
    setLoading(true);

    try {
      const response = await getById(formId);

      let resultData;
      if (mapResponse) {
        resultData = mapResponse(response);
      } else {
        resultData = mapToEmployee(response.info.data, formData!);
      }

      setFormData({
        ...formData,
        ...resultData,
      });

      setData(resultData);
    } finally {
      setLoading(false);
    }
  };

  return {
    forms,
    data,
    loadForm,
    loading,
  };
};

export default usePrepopulation;
