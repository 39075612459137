/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import { SummaryMenuItem, ActivitiesMenuItem } from 'uibuilder/menuitem';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { useLeadUrl } from 'crm/lead/LeadRouter';
import useAuthorization from 'shared/authorization/authorizationService';
import { READ_TIMELINE } from 'crm/crmService';

const ShowLeadPageMenu = () => {
  const { id } = useParams<Dictionary<string>>();
  const { getSingleEntityUrl, getTimelineUrl } = useLeadUrl();
  const { isGranted } = useAuthorization();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl(id)} exact />
      <ActivitiesMenuItem linkUrl={getTimelineUrl(id)} isVisible={isGranted(READ_TIMELINE)} />
    </SidebarStickyMenu>
  );
};

export default ShowLeadPageMenu;
