/* istanbul ignore file */
import React from 'react';
import LinkButton, { LinkButtonProps } from 'uibuilder/button/LinkButton';
import { useEmployeeBackgroundChecksUrl } from 'erp/employee/backgroundcheck/EmployeeBackgroundChecksRouter';

const ViewEmployeeBackgroundCheckButton = (props: Omit<LinkButtonProps, 'url' | 'children'>) => {
  const { getSingleEntityUrl } = useEmployeeBackgroundChecksUrl();

  return (
    <LinkButton {...props} outline url={getSingleEntityUrl}>
      View
    </LinkButton>
  );
};

export default ViewEmployeeBackgroundCheckButton;
