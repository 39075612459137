import React from 'react';
import TextInput from './TextInput';
import { DefaultInputPropTypes } from './index';
import useInputHelper from './inputHelper';
import { FormFieldsData } from '../FormContext';

const TrimTextInput = (props: DefaultInputPropTypes<string>) => {
  const { getRawOnChangeCallback, getValue } = useInputHelper(props);
  const { source } = props;
  const parentOnChangeCallback = getRawOnChangeCallback() || (() => {});

  const value = getValue();

  const onChangeCallback = (eventValue: FormFieldsData) => {
    parentOnChangeCallback({
      [source]: eventValue[source].trim(),
    });
  };

  return <TextInput {...props} source={source} value={value} onChangeCallback={onChangeCallback} />;
};

export default TrimTextInput;
