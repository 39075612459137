/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import { FilterSection } from 'oneStop/oneStopService';

export type SectionProps = {
  isDesktop: boolean;
  activeFilterId: string;
  requestsList: React.ReactChild;
  handleCategoryClick: (id: string) => void;
};

type OneStopDefaultFilterItemProps = {
  classes?: string;
  section: FilterSection;
  isIconVisible?: boolean;
  sectionProps: SectionProps;
};

const OneStopDefaultFilterItem = ({
  section,
  sectionProps,
  classes = '',
  isIconVisible = false,
}: OneStopDefaultFilterItemProps) => {
  const { id, title, icon } = section;
  const { handleCategoryClick, activeFilterId, requestsList, isDesktop } = sectionProps;

  return (
    <>
      <li className="nav-item">
        <button
          type="button"
          className={classnames(`nav-link ${classes}`, {
            active: activeFilterId === id,
          })}
          onClick={() => handleCategoryClick(id)}
        >
          {isIconVisible && icon}
          {title}
        </button>
      </li>
      {!isDesktop && activeFilterId === id && requestsList}
    </>
  );
};

export default OneStopDefaultFilterItem;
