/* istanbul ignore file */
import React from 'react';
import { LinkField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useContactUrl } from 'crm/contact/ContactRouter';

const ContactLinkField = ({
  idSource = undefined,
  idValue = '',
  linkText: initialLinkText = undefined,
  isInternal = true,
  ...props
}: any) => {
  const { getSingleEntityUrl } = useContactUrl();
  const { getValue: getId } = useFieldHelper({ source: idSource, value: idValue });
  const { data } = useShowContext();
  const linkText = typeof initialLinkText === 'function' ? initialLinkText(data) : initialLinkText;
  const id = getId();

  return <LinkField {...props} isInternal={isInternal} linkText={linkText} value={id && getSingleEntityUrl(id)} />;
};

export default ContactLinkField;
