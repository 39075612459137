/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import EducationBreadcrumbs from 'erp/employee/Education/shared/EducationBreadcrumbs';
import { useEducationId, useEducationUrl } from 'erp/employee/Education/EducationRouter';

const SingleEducationBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getSingleEntityUrl } = useEducationUrl();
  const id = useEducationId();

  return (
    <EducationBreadcrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </EducationBreadcrumbs>
  );
};

export default SingleEducationBreadcrumbs;
