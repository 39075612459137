import {
  BUDGETING_CREATE,
} from 'erp/serviceCatalog/budgets/budgetingService';
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ServiceBudgetEntityHeader from 'erp/serviceCatalog/budgets/shared/ServiceBudgetEntityHeader';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import List from 'shared/uibuilder/list/List';
import useBudgetVersionService, { BUDGET_VERSION_STATUSES } from 'erp/serviceCatalog/budgets/budgetVersionService';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import StatusBadgeField from 'erp/serviceCatalog/budgets/shared/field/StatusBadgeField';
import Filters from 'shared/uibuilder/list/filter/Filters';
import { FiltersLayout } from 'shared/uibuilder/list/filter';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import BudgetStatusDropdownFilter from 'erp/serviceCatalog/budgets/shared/filter/BudgetStatusDropdownFilter';
import EmployeeFilter from 'erp/employee/shared/filter/EmployeeFilter';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import CreateButton from 'uibuilder/button/CreateButton';
import useFeatureToggle, { Features } from 'featuretoggle';
import ViewServiceCatalogPageMenu from 'erp/serviceCatalog/Show/ViewServiceCatalogPageMenu';
import ViewServiceBudgetsBreadcrumbs from 'erp/serviceCatalog/budgets/List/ViewServiceBudgetsBreadcrumbs';
import useServiceCatalogService from 'erp/serviceCatalog/useServiceCatalogService';
import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import PeriodBudgetField from '../shared/field/PeriodBudgetField';

const ViewServiceBudgets = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const id = useServiceCatalogId();
  const { getById } = useServiceCatalogService();
  const { getCreateBudgetUrl } = useServiceCatalogUrl();
  const { searchByBudget } = useBudgetVersionService();

  const defaultFilter = {
    status: {
      in: [BUDGET_VERSION_STATUSES.APPROVED, BUDGET_VERSION_STATUSES.AWAITING_APPROVAL],
    },
  };

  const versionFilters = (
    <Filters>
      <FiltersLayout quickFilters={[<QuickFilter label="Active" state={defaultFilter} />]}>
        <DateRangeFilter source="startDate" label="Start Date" canBeInFuture />
        <DateRangeFilter source="endDate" label="End Date" canBeInFuture />
        <BudgetStatusDropdownFilter source="status" label="Status" />
        <EmployeeFilter source="createdBy" label="Creator" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        breadcrumbs={<ViewServiceBudgetsBreadcrumbs />}
        entityHeader={<ServiceBudgetEntityHeader />}
        headerButtons={[
          isFeatureEnabled(Features.BUDGET_VERSIONS) ? (
            <CreateButton
              url={getCreateBudgetUrl(id)}
              label="Propose budget"
              key="create-budget"
              permissionToCheck={BUDGETING_CREATE}
            />
          ) : (
            <></>
          ),
        ]}
        menu={<ViewServiceCatalogPageMenu />}
        hasSeparateSections
      >
        <List
          getDataMethod={async req => searchByBudget(id, req)}
          defaultSortOrder="DESC"
          defaultSortField="startDate"
          defaultFilter={defaultFilter}
        >
          <ShowSection title="Budgets">
            {versionFilters}
            <DataGridLayout>
              <PeriodBudgetField label="Period" endDateSource="endDate" startDateSource="startDate" width="50%" />
              <StatusBadgeField />
            </DataGridLayout>
          </ShowSection>
        </List>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewServiceBudgets;
