import React from 'react';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { POSITION_STATUS } from 'erp/recruitment/hiring/useHiringService';
import { useDateService } from 'shared/uibuilder/dateService';
import moment from 'moment/moment';

type ForecastDateBadgeProps = {
  source: string;
  positionStatusSource: string;
  width?: number;
};

const ForecastDateBadge = ({ source, positionStatusSource }: ForecastDateBadgeProps) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getStatus } = useFieldHelper({ source: positionStatusSource });
  const { getToday } = useDateService();
  const currentDate = getToday();

  const value = getValue();
  const status = getStatus();
  let text = '';
  let color: BasicColors = 'neutralLight';

  if (status === POSITION_STATUS.OPEN && value && !moment(value).isAfter(currentDate)) {
    text = 'DELAYED';
    color = 'error';
  } else {
    return null;
  }

  return <CustomBadge text={text} color={color} />;
};

export default ForecastDateBadge;
