/* istanbul ignore file */
import React from 'react';
import Grid from 'uibuilder/Grid';
import Typography from 'uibuilder/Typography';
import './index.scss';

export interface RelationListLayoutProps {
  header?: React.ReactNode;
  actions?: React.ReactElement[];
  children: React.ReactElement;
  filter?: React.ReactElement;
  headerVariant?: 'h1' | 'h2' | 'h3' | 'h4';
}

const RelationListLayout = ({ header, actions, children, filter, headerVariant = 'h2' }: RelationListLayoutProps) => {
  return (
    <section className="single-entry-info-block relation-list">
      <Grid container className="relations-heading">
        <Grid item xs={12} sm={6}>
          <Typography variant={headerVariant} component="h2" className="c-title">
            {header}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className="top-btns">
          {actions}
        </Grid>
      </Grid>
      {!!filter && <div className="list-top-btns">{filter}</div>}

      {React.cloneElement(children, { isSmallLoader: true })}
    </section>
  );
};

export default RelationListLayout;
