import useInputHelper, { InputHelperData } from 'shared/uibuilder/form/input/inputHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import useFormValidation from 'shared/uibuilder/form/useFormValidation';
import React, { useEffect, useMemo } from 'react';
import { arrayOf, bool, func, oneOfType, shape, string, array, number } from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { CreatableAdditionalProps } from 'react-select/dist/declarations/src/useCreatable';

export type Option = {
  value: string | number;
  label: string;
  disabled?: boolean;
  isSearchable?: boolean;
};

export type DefaultDropdownProps<OptionsType = Option[]> = {
  options?: OptionsType;
  multiple?: boolean;
  placeholder?: string;
  helpText?: Nullable<string>;
  isLoading?: boolean;
  filterOption?: (option: { data: Option }, rawInput: string) => boolean;
  formatOptionLabel?: (
    data: Option,
    formatOptionLabelMeta: {
      context: 'menu' | 'value';
    },
  ) => React.ReactNode;
  styles?: Dictionary<any>;
  isCreatable?: boolean;
  filter?: Dictionary<any>;
} & DefaultInputPropTypes<string> &
  CreatableAdditionalProps<any, any>;

interface DropdownData extends InputHelperData<string> {
  getSelectOptions: () => Option[] | undefined;
  isMultiple: () => boolean;
  getMultipleOnChangeCallback: () => (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const useDropdownHelper = (dropdownProps: DefaultDropdownProps): DropdownData => {
  const inputHelper = useInputHelper(dropdownProps);

  /**
   * All options without filtering
   */
  const rawOptions = dropdownProps.options || [];

  const { getAllowedTransitions } = useFormValidation(dropdownProps.source);

  const isMultiple = () => {
    const { multiple = false } = dropdownProps;

    return multiple;
  };

  /*
   * Allowed transitions are calculated according to initial value.
   */
  // eslint-disable-next-line
  const initialValue = useMemo(() => inputHelper.getValue(), []);

  const getSelectOptions = () => {
    const allowedTransitions = getAllowedTransitions && getAllowedTransitions();

    const currentlyAllowedTransitions = allowedTransitions && allowedTransitions[initialValue as string];

    return rawOptions.filter(
      option =>
        !currentlyAllowedTransitions ||
        currentlyAllowedTransitions.includes(option.value) ||
        option.value === initialValue,
    );
  };

  useEffect(() => {
    if (!inputHelper.getPlaceholder()) {
      const firstOption = getSelectOptions()[0];
      const onChangeCallback = inputHelper.getRawOnChangeCallback();
      const isValueNull =
        inputHelper.getValue() === null || inputHelper.getValue() === undefined || inputHelper.getValue() === '';

      if (firstOption && onChangeCallback && isValueNull) {
        onChangeCallback({
          [inputHelper.getSource()]: firstOption.value,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawOptions.length]);

  /**
   * Returns onChange event handler for multiple select
   * (it's different from usual onChangeCallback because event.target.value returned
   * from js event contains value that was clicked last, not array of selected values).
   */
  const getMultipleOnChangeCallback = () => {
    const { getRawOnChangeCallback } = inputHelper;
    const source = inputHelper.getSource();
    const rawOnChangeCallback = getRawOnChangeCallback();

    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { options } = event.target;
      const selectedValues = Array.from(options)
        .filter(option => option.selected)
        .map(option => option.value);

      if (rawOnChangeCallback) {
        rawOnChangeCallback({
          [source]: selectedValues,
        });
      }
    };
  };

  return {
    ...inputHelper,
    getSelectOptions,
    isMultiple,
    getMultipleOnChangeCallback,
  };
};

export default useDropdownHelper;

// TODO: Delete after refactor erp/leave/shared/input/RulesViolationReasonDropdown using Typescript
export const DEFAULT_DROPDOWN_PROPTYPES = {
  label: string,
  source: string,
  value: oneOfType([string, array]),
  rawOnChangeCallback: func,
  multiple: bool,
  onChangeCallback: func,
  placeholder: string,
  options: arrayOf(
    shape({
      value: oneOfType([string, number]),
      label: string,
    }),
  ),
};
