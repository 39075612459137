import React, { useEffect, useMemo, useRef } from 'react';
import Anchor from 'shared/uibuilder/Anchor/index';
import { getAnchor } from 'shared/uibuilder/helper';
import { scrollToHash } from 'shared/uibuilder/domUtils';
import { useLocation } from 'react-router-dom';

interface ScrollToSectionAnchorProps {
  titleId: string;
  isLinkDiplayed: boolean;
}

const ScrollToSectionAnchor = ({ titleId, isLinkDiplayed }: ScrollToSectionAnchorProps) => {
  const anchor = useMemo(() => getAnchor(titleId), [titleId]);
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash && anchorRef.current && anchorRef.current.getAttribute('href') === hash) {
      scrollToHash(anchorRef.current);
    }
  }, [hash]);

  return (
    <>
      {isLinkDiplayed ? (
        <Anchor classes="section-anchor" id={`copy-${anchor}`} link={`#${anchor}`} linkRef={anchorRef}>
          Copy Link
        </Anchor>
      ) : null}
      <span id={anchor} className="invisible-anchor" />
    </>
  );
};

export default ScrollToSectionAnchor;
