import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, HtmlField, TextField } from 'shared/uibuilder/field';
import './AdditionalContactsField.scss';
import ReplaceUrlWithLink from 'shared/uibuilder/ReplaceUrlWithLink';
import { ADDITIONAL_CONTACTS_OPTIONS } from 'erp/shared/input/AdditionalContactListInput/useAdditionalContactsHelper';

const AdditionalContactsField = (props: FieldProps<string> | undefined) => {
  const { getValue, getLabel } = useFieldHelper<any>(props);

  const contacts = getValue();

  const displayedContacts =
    contacts && contacts.length
      ? contacts.map((contact: { type: any; value: any }, index: React.Key) => {
          const { type, value } = contact;

          const displayValue = (
            <ReplaceUrlWithLink>
              <HtmlField value={value} />
            </ReplaceUrlWithLink>
          );

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="form-inline additional-contact-field" key={index} title={value}>
              {
                // @ts-ignore
                ADDITIONAL_CONTACTS_OPTIONS[type] || type
              }
              :&nbsp;{displayValue}
            </div>
          );
        })
      : null;

  return <TextField value={displayedContacts} label={getLabel()} isColumnDirection={contacts?.length > 1} />;
};

export default AdditionalContactsField;
