/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import NoteForm from 'erp/employee/Timeline/note/form/NoteForm';
import useEmployeeNoteService from 'erp/employee/Timeline/note/employeeNoteService';
import { UpdateFormProps } from 'shared/uibuilder/form/UpdateForm';

interface UpdateNoteFormProps extends Omit<UpdateFormProps, 'submitFormFunc'> {
  onCancel: () => void;
}

const UpdateNoteForm = ({ getDataFunc, onCancel, ...props }: UpdateNoteFormProps) => {
  const { getValidationSchema, update } = useEmployeeNoteService();

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      getValidationSchemaFunc={getValidationSchema}
      {...props}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <NoteForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

export default UpdateNoteForm;
