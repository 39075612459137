/* istanbul ignore file */
import { SingletonHooksContainer } from 'react-singleton-hook';
import React from 'react';

type SingletonHooksProviderProps = {
  hooksForEagerInitialization: any[];
};

type HookContainerProps = {
  useHook: () => void;
};

const HookContainer = ({ useHook }: HookContainerProps) => {
  useHook();
  return <></>;
};

const EagerInitializer = ({ hooksForEagerInitialization }: SingletonHooksProviderProps) => {
  return hooksForEagerInitialization.map(useHook => <HookContainer useHook={useHook} />);
};

/**
 * By default, react-singleton-hook component uses lazy initialization for hooks.
 * So, it returns some static object when it's called for the first time.
 *
 * It's not always a correct behavior. So, with this decorator
 * developer can specify hooks which should use eager initialization.
 */
const SingletonHooksProvider = ({ hooksForEagerInitialization }: SingletonHooksProviderProps) => {
  return (
    <>
      <SingletonHooksContainer />
      <EagerInitializer hooksForEagerInitialization={hooksForEagerInitialization} />
    </>
  );
};

export default SingletonHooksProvider;
