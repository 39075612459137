import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import CandidateResumeUploaderInput from '../../../../../artifact/shared/input/CandidateResumeUploaderInput';
import { EmployeeInput } from '../../../../employee';
import ZoneDateTimeInput, { ZonedApiDateFormat } from '../../../../../shared/uibuilder/form/input/ZoneDateTimeInput';

interface InterviewScheduledFormProps {
  handleCancelClick: () => void;
}

const InterviewScheduledForm = ({ handleCancelClick }: InterviewScheduledFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();

  return (
    <>
      <ZoneDateTimeInput
        label="Interview Date & Time"
        source="interviewDateTime"
        isRequired
        apiDateFormat={ZonedApiDateFormat.ISO_8601_DATETIME_WITH_OFFSET}
      />
      <EmployeeInput label="Interviewer" source="interviewerAlias" isRequired />
      <Dropdown
        label="Interview Method"
        source="interviewMethod"
        options={[
          { value: 'online', label: 'Online' },
          { value: 'offline', label: 'Offline' },
        ]}
        isRequired
      />
      <CandidateResumeUploaderInput label="Candidate's CV" source="resumeId" maxElements={1} isRequired />

      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default InterviewScheduledForm;
