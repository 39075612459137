/* istanbul ignore file */
import React from 'react';
import Grid from 'uibuilder/Grid';
import { TextInput, DateInput } from 'shared/uibuilder/form/input';
import DocumentTypeDropdown from 'erp/employee/Education/shared/input/DocumentTypeDropdown';
import DocumentScanUploaderInput from 'artifact/shared/input/DocumentScanUploaderInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const SCANS_SOURCE = 'scansIds';

interface EducationalDocumentInputProps {
  source: string;
  value: any;
}

const EducationalDocumentInput: React.FC<EducationalDocumentInputProps> = props => {
  const { getSource, getValue, getRawOnChangeCallback } = useInputHelper(props);
  const source = getSource();

  const documentData = getValue() || {};
  const onChangeCallback = getRawOnChangeCallback();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={3}>
          <DocumentTypeDropdown
            source={`${source}.type`}
            label="Document Type"
            value={documentData.type}
            onChangeCallback={onChangeCallback}
            placeholder="Document Type"
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <TextInput
            source={`${source}.number`}
            label="Document Series and Number"
            value={documentData.number}
            onChangeCallback={onChangeCallback}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={3}>
          <DateInput
            source={`${source}.dateOfIssue`}
            label="Date of Issue"
            value={documentData.dateOfIssue}
            onChangeCallback={onChangeCallback}
          />
        </Grid>
      </Grid>
      <BigFormRow>
        <div className="education-list__document-scan">
          <DocumentScanUploaderInput
            label="Document Scans"
            source={`${source}.${SCANS_SOURCE}`}
            value={documentData[SCANS_SOURCE]}
          />
        </div>
      </BigFormRow>
    </>
  );
};

export default EducationalDocumentInput;
