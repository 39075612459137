/* istanbul ignore file */
import React from 'react';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import calculateAge from 'shared/ageUtils';
import { DefaultInputPropTypes } from '../../../../shared/uibuilder/form/input';

const CandidateAgeInput = (props: DefaultInputPropTypes<any>) => {
  const { getValue, getLabel } = useInputHelper(props);

  return <TextInput {...props} value={String(calculateAge(getValue()) || 0)} label={getLabel()} disabled />;
};

export default CandidateAgeInput;
