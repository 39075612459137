/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import getLabel from 'shared/uibuilder/helper';
import { INDUSTRIES } from 'crm/crmService';

type IndustryFieldProps = {
  value?: string;
  source: string;
  label?: string;
};

const IndustryField = ({ value: inputValue, source, label }: IndustryFieldProps) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));

  return <EnumField value={value} options={INDUSTRIES} label={getLabel(label, source)} />;
};

export default IndustryField;
