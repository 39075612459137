import { merge } from 'lodash';
import { useState } from 'react';
import useCandidateService, { CANDIDATE_RESOURCE_URL, CandidateStatus } from 'erp/candidate/shared/candidateService';
import { useEmployeeMapper } from 'erp/employee';
import { useKernelApi } from 'api';
import useConversionRouteHelper from 'erp/employee/conversionRouteHelper';
import { useNavigate } from 'react-router-dom';
import useGetData from 'shared/useGetData';

const isLatin = (string: string) => {
  return /^[a-zA-Z-]+$/.test(string);
};

const useCandidateToEmployeeMapper = () => {
  const { createMapper } = useEmployeeMapper();
  const { sendPostRequest } = useKernelApi();

  const mapCandidateToEmployee = (
    initialData: any,
    candidate: {
      personalPhoneNumber: any;
      personalEmail: any;
      otherPhoneNumber: any;
      photosIds: any;
      additionalContacts: any;
      name: { [x: string]: any };
    },
  ) => {
    const mappedFields = ['firstName', 'lastName', 'middleName', 'maidenName'];
    const mappedToCyrillic = 'maidenName';

    const mappedEmployee: any = {
      extendedInformation: {
        personalPhoneNumber: candidate.personalPhoneNumber,
        personalEmail: candidate.personalEmail,
        otherPhoneNumber: candidate.otherPhoneNumber,
        photosIds: candidate.photosIds,
        additionalContactInfo: candidate.additionalContacts || [],
      },
      nameLocal: {},
      nameEn: {},
    };

    if (candidate.name) {
      mappedFields.forEach(field => {
        const value = candidate.name[field];

        if (isLatin(value)) {
          mappedEmployee.nameEn[field] = value;
        } else if (field === mappedToCyrillic) {
          mappedEmployee.nameLocal[field] = value;
        }
      });
    }

    return merge(initialData, mappedEmployee);
  };

  const convertToCandidate = async (employee: any, candidateId: any) => {
    const data = createMapper(employee);

    const result = await sendPostRequest(`${CANDIDATE_RESOURCE_URL}/${candidateId}/convert`, data);

    return result.json();
  };

  return {
    mapCandidateToEmployee,
    convertToCandidate,
  };
};

const useCandidateConversionService = () => {
  const navigate = useNavigate();
  const { getById } = useCandidateService();
  const { candidateId } = useConversionRouteHelper();
  const { mapCandidateToEmployee, convertToCandidate } = useCandidateToEmployeeMapper();
  const [candidate, setCandidate] = useState({});

  const isCandidateReadyForConversion = (entity: { convertedToEmployeeId: any; status: CandidateStatus }) =>
    !entity.convertedToEmployeeId && entity.status === CandidateStatus.ACCEPTED;

  const loadCandidate = async () => {
    if (candidateId) {
      return getById(candidateId);
    } else {
      return Promise.resolve({});
    }
  };

  const setData = (data: any) => {
    if (data.id && !isCandidateReadyForConversion(data)) {
      navigate('/not-found');
    } else {
      setCandidate(data);
    }
  };

  const { loading } = useGetData(loadCandidate, setData, true, candidateId);

  return {
    getCandidate: () => ({
      loading,
    }),
    mapCandidateToEmployee: (initialData: any) => {
      return mapCandidateToEmployee(initialData, candidate as any);
    },
    convertCandidate: (employee: any) => {
      return convertToCandidate(employee, candidateId);
    },
  };
};

export default useCandidateConversionService;
