/* istanbul ignore file */
import React from 'react';
import EnumDropdown, { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';

export const NOTE_TAG_ALIAS = {
  CAREER_DEVELOPMENT: 'Career review',
  OFFER_NOTE: 'Offer',
};

const EmployeeNoteTagDropdown = (props: Omit<EnumDropdownProps, 'options'>) => {
  return <EnumDropdown {...props} options={NOTE_TAG_ALIAS} />;
};

export default EmployeeNoteTagDropdown;
