/* istanbul ignore file */
import React from 'react';
import SingleServiceCatalogBreadcrumbs from 'erp/serviceCatalog/shared/SingleServiceCatalogBreadcrumbs';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';
import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';

const ViewKPIStructureBreadcrumbs = ({ children }: { children?: React.ReactElement | React.ReactElement[] }) => {
  const id = useServiceCatalogId();
  const { getBudgetStructuresUrl } = useServiceCatalogUrl();

  return (
    <SingleServiceCatalogBreadcrumbs>
      <SingleBreadcrumbItem entity="KPI Structure" link={getBudgetStructuresUrl(id)} />
      {children}
    </SingleServiceCatalogBreadcrumbs>
  );
};

export default ViewKPIStructureBreadcrumbs;
