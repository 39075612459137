import React from 'react';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';
import { columnNames } from '../constants';
import { countItemsWithStatus } from './board/utils';

interface HeaderProps {
  data: any[];
}

const Header: React.FC<HeaderProps> = ({ data }) => {
  return (
    <>
      <Box sx={{ backgroundColor: '#f5f5f5' }} />
      {columnNames.map(({ name, stage }) => {
        const itemsCount = countItemsWithStatus(data, stage);

        return (
          <Box
            key={`header-${name}`}
            sx={{
              borderBottom: '1px solid black',
              display: 'flex',
              justifyContent: 'center',
              padding: '15px 0',
              backgroundColor: '#f5f5f5',
            }}
          >
            <Typography sx={{ color: (theme: any) => theme.vars.palette.neutral[50] }}>
              {`${name} (${itemsCount})`}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

export default Header;
