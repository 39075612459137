import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldLayoutProps, FieldProps } from 'shared/uibuilder/field';
import useUiTheme from 'shared/uibuilder/useUiTheme';

export interface LinkFieldProps extends FieldProps {
  linkText?: string | number | React.ReactNode;
  isInternal?: boolean;
  linkSource?: string;
  icon?: React.FC<any>;
}

export interface LinkFieldLayoutProps extends FieldLayoutProps {
  linkText?: string | number | React.ReactNode;
  link?: string;
  icon?: React.FC<any>;
}

export type LinkFieldLayoutType = ReactComponent<LinkFieldLayoutProps>;

const LinkField = ({ linkText, isInternal, linkSource, icon, ...props }: LinkFieldProps) => {
  const { ExternalLinkLayout, InternalLinkLayout } = useUiTheme<LinkFieldLayoutType>();
  const { getValue, getLabel } = useFieldHelper(props);
  const { getValue: getLink } = useFieldHelper({ source: linkSource });

  const Layout = isInternal ? InternalLinkLayout : ExternalLinkLayout;

  return (
    <Layout
      link={linkSource && getLink()}
      linkText={linkText || getValue()}
      value={getValue()}
      label={getLabel()}
      icon={icon}
    />
  );
};

export default LinkField;
