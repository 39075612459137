/* istanbul ignore file */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import DavinciSettings from 'uibuilder/DavinciSettings';
import { createStore } from 'redux';
import rootReducer from 'uibuilder/reducer/rootReducer';
import Container from '@mui/material/Container';
import IntegrationTestsRouter from 'integration-tests/Router';
import Audit from '../shared/audit/Audit';
import SingletonHooksProvider from '../SingletonHooksProvider';
import useArtifactsInfoReceiver from '../artifact/artifactsInfoReceiver';
import AuthenticationProviderWrapper from 'authentication/AuthenticationProviderWrapper';
import CustomThemeProvider from 'uibuilder/themes/themeProvider';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import './IntegrationTestsApp.scss';

const store = createStore(rootReducer);

const IntegrationTestsApp = () => {
  return (
    <StyledEngineProvider injectFirst>
      <CustomThemeProvider>
        <CssBaseline />
        <Provider store={store}>
          <BrowserRouter>
            <AuthenticationProviderWrapper>
              <Audit>
                <SingletonHooksProvider hooksForEagerInitialization={[useArtifactsInfoReceiver]} />
                <DavinciSettings>
                  <Container>
                    <IntegrationTestsRouter />
                  </Container>
                </DavinciSettings>
              </Audit>
            </AuthenticationProviderWrapper>
          </BrowserRouter>
        </Provider>
      </CustomThemeProvider>
    </StyledEngineProvider>
  );
};

export default IntegrationTestsApp;
