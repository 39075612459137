import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import useBudgetVersionService from 'erp/serviceCatalog/budgets/budgetVersionService';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import UpdateServiceBudgetBreadcrumbs
  from 'erp/serviceCatalog/budgets/Update/UpdateServiceBudgetBreadcrumbs';
import ServiceBudgetForm from 'erp/serviceCatalog/budgets/ServiceBudgetForm';
import { useParams } from 'react-router-dom';
import React from 'react';

const UpdateServiceBudget = () => {
  const id = useServiceCatalogId();
  const budgetId = useParams<Dictionary<string>>().budgetId!;
  const { getBudgetsUrl } = useServiceCatalogUrl();
  const { getById, update, getValidationSchema } = useBudgetVersionService();

  return (
    <UpdateForm
      submitFormFunc={(_, data) => update(budgetId, data)}
      afterSubmit={{
        redirect: getBudgetsUrl(id),
        message: 'You are awesome! The Budget has been successfully created.'
      }}
      getValidationSchemaFunc={getValidationSchema}
      getDataFunc={() => getById(budgetId)}
    >
      <UpdatePageLayout
        title="Update: Budget"
        breadcrumbs={<UpdateServiceBudgetBreadcrumbs />}
      >
        <ServiceBudgetForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateServiceBudget;
