import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import TextField from 'shared/uibuilder/field/TextField';
import EnumField from 'shared/uibuilder/field/EnumField';
import { KPI_TYPES_UNIT_OPTIONS, SERVICE_MANAGEMENT_KPI_VALUE_CREATE_KPI_VALUE } from '../../useKPIService';
import PublishKpiButton from '../buttons/PublishKPIButton';

const KpiParametersListField = ({ source, dateSource }: { source: string; dateSource: string }) => {
  const { data } = useShowContext();
  const requestPositions = data.getValueBySource(source);
  const effectiveDate = data.getValueBySource(dateSource);
  const kpiStructureId = data.getValueBySource('id');

  return (
    <ControlledList data={{ items: requestPositions, totalPages: 1, totalElements: requestPositions?.length }}>
      <DataGridLayout
        customEmptyMessage="No parameters found"
        buttons={[
          <PublishKpiButton
            kpiStructureId={kpiStructureId}
            effectiveDate={effectiveDate}
            permissionToCheck={SERVICE_MANAGEMENT_KPI_VALUE_CREATE_KPI_VALUE}
          />,
        ]}
      >
        <TextField label="Name" source="name" width="30%" />

        <EnumField label="Type" source="type" options={KPI_TYPES_UNIT_OPTIONS} />
      </DataGridLayout>
    </ControlledList>
  );
};

export default KpiParametersListField;
