import { useKernelApi } from 'api';

export const READ_WORKING_HOURS_REPORT = 'READ_WORKING_HOURS_REPORT';

const useWorkingHoursService = () => {
  const { sendGetRequest } = useKernelApi();

  const getReport = async ({
    dateFrom,
    dateTo,
    employeeAlias,
  }: {
    dateFrom: string;
    dateTo: string;
    employeeAlias: string;
  }) => {
    const result = await sendGetRequest(`/employees/${employeeAlias}/working-hours?from=${dateFrom}&to=${dateTo}`);
    return result;
  };

  return {
    getReport,
  };
};

export default useWorkingHoursService;
