/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import SearchLinkField from 'shared/uibuilder/field/SearchLinkField';

type CandidateSearchLinkFieldProps = {
  idValue?: string;
  idSource?: string;
  source?: string;
  label?: string;
};

const CandidateSearchLinkField = ({ idValue = '', idSource, ...props }: CandidateSearchLinkFieldProps) => {
  const { getSingleEntityUrl } = useCandidateUrl();
  const { getValue: getId } = useFieldHelper({ source: idSource, value: idValue });
  const { getValue } = useFieldHelper<any>(props);

  const id = getId();
  const name = getValue();
  const linkText = name ? `${name.firstName} ${name.lastName}` : id;

  return <SearchLinkField {...props} isInternal value={linkText} url={getSingleEntityUrl(id)} isRowClickable={false} />;
};

export default CandidateSearchLinkField;
