import React from 'react';
import useRoute from 'shared/routing/useRoute';
import {
  CREATE_CUSTOMER_INVOICE,
  CUSTOMER_INVOICE_PATH,
  READ_CUSTOMER_INVOICE,
  UPDATE_CUSTOMER_INVOICE,
} from './useCustomerInvoiceService';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Routes, useParams } from 'react-router-dom';
import { ProtectedRouter } from 'routing';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import CustomerInvoiceListPage from './List';
import CreateCustomerInvoicePage from './createupdate/Create';
import CustomerInvoiceViewPage from './Show';
import UpdateCustomerInvoicePage from './createupdate/Update';

export const useCustomerInvoiceRoute = () => {
  const routers = useRoute({ listRoute: CUSTOMER_INVOICE_PATH });

  return { ...routers, createFromProjectRoute: `${routers.createRoute}/project/:sowId` };
};

export const useCustomerInvoiceUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: CUSTOMER_INVOICE_PATH,
  });

  return {
    ...baseUrls,
    getCreateEntityFormProjectUrl: (sowId: string): string => `${baseUrls.getCreateUrl()}/project/${sowId}` as string,
  };
};

export const useCustomerInvoiceId = () => useParams<Dictionary<string>>().id!;

export const useCustomerInvoiceSowId = () => useParams<Dictionary<string>>().sowId;

const CustomerInvoiceRouter = () => {
  const { listRoute, createRoute, createFromProjectRoute, singleEntityRoute, updateRoute } = useCustomerInvoiceRoute();

  return (
    <ProtectedRouter basePath={CUSTOMER_INVOICE_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_CUSTOMER_INVOICE}>
              <CustomerInvoiceListPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={createFromProjectRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_CUSTOMER_INVOICE}>
              <CreateCustomerInvoicePage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_CUSTOMER_INVOICE}>
              <CreateCustomerInvoicePage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={updateRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={UPDATE_CUSTOMER_INVOICE}>
              <UpdateCustomerInvoicePage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_CUSTOMER_INVOICE}>
              <CustomerInvoiceViewPage />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

CustomerInvoiceRouter.getRouterPath = () => `${CUSTOMER_INVOICE_PATH}/*`;

export default CustomerInvoiceRouter;
