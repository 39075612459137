/* istanbul ignore file */
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import FieldBadgeProps from 'shared/uibuilder/badge/FieldBadgeProps';

const PotentialCandidateBadge = (props: FieldBadgeProps) => {
  return (
    <Badge className="ml-2" color="secondary">
      Potential Candidate
    </Badge>
  );
};

export default PotentialCandidateBadge;
