import { useKernelApi, useFinanceApi } from 'api';
import moment from 'moment';
import useFeatureToggle, { Features } from 'featuretoggle';

export const READ_PAYROLL_REPORT = 'READ_PAYROLL_REPORT';
export const PAYROLL_PERIOD_FORMAT = 'yyyy-MM';

const getMaximalAvailablePeriod = () => {
  return moment().format(PAYROLL_PERIOD_FORMAT);
};

const usePayrollService = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const isNewPayrollEnabled = isFeatureEnabled(Features.PAYROLL);
  const apiHook = isNewPayrollEnabled ? useFinanceApi : useKernelApi;
  const { sendGetRequest } = apiHook();

  const getReport = async ({ filters }: any) => {
    const period = filters.period.eq;
    const offices = filters.office ? filters.office.eq : [];

    const result = await sendGetRequest(`/payroll/report?period=${period}&offices=${offices}`);
    return result;
  };

  // TODO: Remove it after integration and testing new BE
  const getLatestGeneration = async ({ filters }: any) => {
    const period = filters.period.eq;
    const offices = filters.office ? filters.office.eq : [];

    const result = await sendGetRequest(`/payroll/report/latest-generation?period=${period}&offices=${offices}`);
    const json = await result.json();
    return json;
  };

  return {
    getReport,
    getMaximalAvailablePeriod,
    getLatestGeneration,
  };
};

export default usePayrollService;
