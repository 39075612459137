import ServiceKPIStructureRecordLayout from './ServiceKPIStructureRecordLayout';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';
import { KPI_STRUCTURE_READ } from './useKPIService';
import CreateKPIStructure from './Create';
import UpdateKPIStructure from './Update';
import Data from 'shared/uibuilder/Data';

const useServiceKPIStructureRegistry = () => ({
  AddForm: CreateKPIStructure,
  UpdateForm: UpdateKPIStructure,
  RecordLayout: ServiceKPIStructureRecordLayout,
  isApplicable: (item: TimelineItem) => item,
  source: '',
  urlPostfix: 'structures',
  name: 'New KPI Structure',
  hasFullData: true,
  permissionToCheck: KPI_STRUCTURE_READ,
  additionalAfterAddSubmit: (item: TimelineItem, parentContext?: Data, reloadData?: () => void) => {
    if (reloadData) {
      reloadData();
    } // reload page for fetch actual state
  },
});

export default useServiceKPIStructureRegistry;
