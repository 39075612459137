/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem, { SidebarMenuItemProps } from 'shared/uibuilder/menu/SidebarMenuItem';

const OpportunitiesMenuItem = ({ linkUrl, ...props }: Omit<SidebarMenuItemProps, 'name' | 'icon'>) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Opportunities" icon="compass" {...props} />
);

OpportunitiesMenuItem.displayName = 'Opportunities';

export default OpportunitiesMenuItem;
