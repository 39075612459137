import useUiTheme from 'shared/uibuilder/useUiTheme';
import React from 'react';
import { BaseFieldLayoutType } from 'shared/uibuilder/field';
import { LinkFieldLayoutProps, LinkFieldLayoutType } from 'shared/uibuilder/field/LinkField';

const ExternalLinkFieldLayout: LinkFieldLayoutType = ({
  linkText,
  value,
  label,
  link,
  icon: Icon,
}: LinkFieldLayoutProps) => {
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const displayedValue = value ? (
    <a href={link || value} target="_blank" rel="noopener noreferrer">
      {linkText}
      {Icon && <Icon sx={{ marginLeft: '7px', fontSize: '1.25rem', transform: 'translateY(-1px)' }} />}
    </a>
  ) : (
    '—'
  );

  return <BaseFieldLayout label={label} value={displayedValue} />;
};

export default ExternalLinkFieldLayout;
