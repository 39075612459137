export const saveFile = (blob: Blob, filename: string) => {
  const objectUrl = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = objectUrl;
  anchor.download = decodeURIComponent(filename);
  anchor.click();
  window.URL.revokeObjectURL(objectUrl);
};

export const downloadFile = async (response: any, defaultFileName = '', readInUtf8WithBom = false) => {
  const contentDisposition = response.headers.get('content-disposition');
  // @ts-ignore
  const filename = contentDisposition ? decodeURI(/filename="(.*)"/g.exec(contentDisposition)[1]) : defaultFileName;

  if (readInUtf8WithBom) {
    const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
    const arrayBufer = await response.arrayBuffer();

    saveFile(new Blob([BOM, arrayBufer]), filename);
  } else {
    await response.blob().then(async (blob: Blob) => {
      saveFile(blob, filename);
    });
  }
};

export const getHumanReadableDataSize = (bytes: StringOrNumber) => {
  const units = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
  let l = 0;
  let n = parseInt(String(bytes), 10) || 0;
  // eslint-disable-next-line no-plusplus
  while (n >= 1024 && ++l) {
    n /= 1024;
  }
  return `${Math.round(n * 10) / 10} ${units[l]}`;
};
