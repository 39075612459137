import useDropdownHelper, { Option } from 'shared/uibuilder/form/input/dropdownHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import React from 'react';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export interface ButtonsSelectInputProps {
  source: string;
  options?: Option[];
  isVisible?: TypeOrFunction<boolean>;
  value?: Nullable<TypeOrFunction<string>>;
  onChangeCallback?: (values: FormFieldsData) => void;
  disabled?: boolean;
}

export interface ButtonsSelectInputLayoutProps {
  options?: Option[];
  value: string;
  createOnChangeCallback: (key: string | number) => () => void;
  disabled?: boolean;
}

export type ButtonsSelectInputLayoutType = ReactComponent<ButtonsSelectInputLayoutProps>;

const ButtonsSelectInput = ({ disabled, ...props }: ButtonsSelectInputProps) => {
  const dropdownHelper = useDropdownHelper({ ...props, disabled });
  const { ButtonsSelectInputLayout } = useUiTheme<ButtonsSelectInputLayoutType>();

  const createOnChangeCallback = (key: string | number) => () => {
    const source = dropdownHelper.getSource();
    const rawOnChangeCallback = dropdownHelper.getRawOnChangeCallback();

    if (rawOnChangeCallback) {
      rawOnChangeCallback({
        [source]: key,
      });
    }
  };

  return (
    <ButtonsSelectInputLayout
      options={dropdownHelper.getSelectOptions()}
      value={dropdownHelper.getValue()}
      createOnChangeCallback={createOnChangeCallback}
      disabled={disabled}
    />
  );
};

export default ButtonsSelectInput;
