import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useBudgetingService, {
  BUDGETING_APPROVE,
} from 'erp/serviceCatalog/budgets/budgetingService';
import { useParams } from 'react-router-dom';
import { BUDGET_VERSION_STATUSES } from 'erp/serviceCatalog/budgets/budgetVersionService';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import React, { useState } from 'react';

const RejectBudgetButton = () => {
  const { data, setData } = useShowContext();
  const { budgetId } = useParams<Dictionary<string>>();
  const { rejectBudget } = useBudgetingService();
  const [ reason, setReason ] = useState('');

  const rejectAndUpdate = async (rejectedBudgetId: string, rejectionReason: string) => {
    const version = await rejectBudget(rejectedBudgetId, rejectionReason);
    setData!(version);
  };

  if (data.getValueBySource('status') !== BUDGET_VERSION_STATUSES.AWAITING_APPROVAL) {
    return (<></>);
  }

  const onReasonChange = (values: FormFieldsData) => {
    setReason(values.reason);
  };

  return (
    <ButtonWithConfirmation permissionToCheck={BUDGETING_APPROVE}
      displayMessage="Do you really want to reject this budget?"
      submitMethod={() => rejectAndUpdate(budgetId!, reason)}
      afterSubmit={{
        successMessage: 'The budget has been successfully rejected.',
        errorMessage: "Can't reject this budget.",
      }}
      color="error"
      modalChildren={
        <div className="mt-5">
          <TextInput
            label="Rejection reason:"
            source="reason"
            value={reason}
            onChangeCallback={onReasonChange}
          />
        </div>
      }
    >
      Reject
    </ButtonWithConfirmation>
  );
};

export default RejectBudgetButton;
