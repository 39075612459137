import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import FormSection from 'shared/layout/form/FormSection';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import InputList from 'shared/uibuilder/form/input/InputList/InputList';
import KpiParameterInputs from '../shared/inputs/KPIParameterInputs';

const ServiceKPIStructureForm = () => {
  return (
    <>
      <FormSection>
        <FormRow>
          <DateInput dateFormat={DATE_FORMAT.API_ONLY_MONTH} source="effectiveDate" label="Effective Date" isRequired />
        </FormRow>
      </FormSection>
      <FormSection>
        <InputList source="kpiParameters" inputTemplate={KpiParameterInputs} addText="Add parameter" maxFields={50} />
      </FormSection>
    </>
  );
};

export default ServiceKPIStructureForm;
