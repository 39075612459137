import { ArtifactFileTypeLabel } from './artifactStorageService';

export const mapArtifactMetaInfo = (metaInfo: Array<any>) => {
  return metaInfo
    .map(info => {
      switch (info.key) {
        case 'TYPE':
          return `Type: ${ArtifactFileTypeLabel[info.value]}`;
        case 'STATEMENT_OF_WORK_NUMBER':
          return `Number: ${info.value}`;
        case 'MSA_NUMBER':
          return `Number: ${info.value}`;
        case 'CHANGE_REQUEST_NUMBER':
          return `Number: ${info.value}`;
        case 'AMENDMENT_NUMBER':
          return `Number: ${info.value}`;
        case 'COMPANY_SERVICE_BUDGET_NAME':
          return `Budget Name: ${info.value}`;
        default:
          return '';
      }
    })
    .join(' ');
};

export const mapArtifactRelationInfo = (relations: Array<any>, showRelationToClass: String) => {
  return relations
    .filter(relation => relation.entityClass === showRelationToClass)
    .map(relation => {
      switch (relation.entityClass) {
        case 'Account':
          return `Account: ${relation.entity.accountName} (${relation.entityId})`;
        default:
          return '';
      }
    })
    .join(' ');
};
