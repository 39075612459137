import React from 'react';
import { CandidateDropdown } from 'erp/candidate';
import { CandidateStatus } from 'erp/candidate/shared/candidateService';
import ConvertCandidatePrompt from './ConvertCandidatePrompt';
import useConversionRouteHelper from 'erp/employee/conversionRouteHelper';

const ConvertCandidateDropdown = ({ source, ...props }: { source: string; label: string }) => {
  const { candidateId, setCandidateId } = useConversionRouteHelper();

  const onChangeCallback = (value: { [x: string]: any }) => {
    setCandidateId(value[source]);
  };

  const filter = {
    status: {
      eq: CandidateStatus.ACCEPTED,
    },
    convertedToEmployee: {
      NULL: true,
    },
  };

  return (
    <>
      <ConvertCandidatePrompt candidateId={candidateId!} />
      <CandidateDropdown
        {...props}
        source={source}
        onChangeCallback={onChangeCallback}
        value={candidateId}
        placeholder="Choose candidate"
        filter={filter}
      />
    </>
  );
};

export default ConvertCandidateDropdown;
