import React, { useState } from 'react';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useHiringService, {
  HIRING_REQUEST_UPDATE,
  HiringRequest,
  POSITION_STATUS,
  RequestPosition,
} from '../../useHiringService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { DateInput } from 'shared/uibuilder/form/input';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import moment from 'moment';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

type CloseHiringPositionButtonProps = {
  allShowData: HiringRequest;
  parentShowSetData: (data: HiringRequest) => void;
  isVisible?: boolean;
};

const CloseHiringPositionButton: React.FC<CloseHiringPositionButtonProps> = ({ allShowData, parentShowSetData }) => {
  const { closePosition } = useHiringService();
  const [dateErrors, setDateErrors] = useState<string[]>([]);
  const { getToday } = useDateService();
  const currentDay = getToday();
  const [date, setDate] = useState(currentDay);
  const { data } = useShowContext();
  const positionData: RequestPosition = data.getData() as RequestPosition;

  const handleClosePosition = async () => {
    const updatedData = {
      ...allShowData,
      requestPositions: allShowData.requestPositions.map(position =>
        position.id === positionData.id
          ? {
              ...position,
              positionStatus: POSITION_STATUS.CLOSED,
              closeDate: moment(date).format(DATE_FORMAT.API_ONLY_DATE),
            }
          : position,
      ),
    } as HiringRequest;

    const response = await closePosition(updatedData);
    parentShowSetData(response);
  };

  const onDateChange = (values: FormFieldsData) => {
    setDate(values.date);
    const isValid = moment(values.date, DATE_FORMAT.API_ONLY_DATE, true).isValid();

    if (!values.date) {
      setDateErrors(['Field is required.']);
    } else if (!isValid) {
      setDateErrors(['Please, choose the correct date.']);
    } else if (moment(values.date).isAfter(currentDay)) {
      setDateErrors(["Please, choose a date before or today's date."]);
    } else {
      setDateErrors([]);
    }
  };

  if (positionData.positionStatus !== POSITION_STATUS.OPEN) return null;

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to close this Position?"
      modalChildren={
        <div className="mt-5">
          <DateInput
            source="date"
            label="Close date"
            onChangeCallback={onDateChange}
            errorMessages={dateErrors}
            isRequired
            value={
              date && moment(date, DATE_FORMAT.API_ONLY_DATE, true).isValid()
                ? moment(date).format(DATE_FORMAT.API_ONLY_DATE)
                : (date as any)
            }
            maxDate={currentDay}
          />
        </div>
      }
      submitMethod={handleClosePosition}
      confirmBtnProps={{ disabled: !!dateErrors.length }}
      permissionToCheck={HIRING_REQUEST_UPDATE}
      afterSubmit={{
        successMessage: 'Position closed successfully!',
        errorMessage: "Position couldn't be closed!",
      }}
    >
      Close
    </ButtonWithConfirmation>
  );
};

export default CloseHiringPositionButton;
