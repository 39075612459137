import React, { useCallback, useState } from 'react';
import Tooltip from 'uibuilder/Tooltip';
import useArtifactPreviewHelper from 'artifact/shared/artifactPreviewHelper';
import { API_FILE_TYPE } from 'artifact/const';
import InputLoader from 'shared/uibuilder/InputLoader';
import FileName from './FileName';

export const getArtifactsIcon = (type: string) => {
  const artifactType = type.toLowerCase();

  // @ts-ignore
  return API_FILE_TYPE[artifactType] ? API_FILE_TYPE[artifactType].category.icon : API_FILE_TYPE.default.category.icon;
};

export type ArtifactType = { id: string; hasPreview?: boolean; type: string; title: string };

export type ArtifactPreviewWrapperProps = {
  artifact: ArtifactType;
  handleOpenPreview: () => Promise<void> | void;
  handleDownload: () => void;
  hasIcon?: boolean;
  customFileName?: string | React.ReactElement;
};

const ArtifactPreviewWrapper = ({
  artifact,
  handleOpenPreview,
  handleDownload,
  hasIcon = true,
  customFileName,
}: ArtifactPreviewWrapperProps) => {
  const { id, hasPreview, type, title } = artifact;
  const { isPreviewAvailable } = useArtifactPreviewHelper();
  const previewAvailable = isPreviewAvailable(type);
  const elementId = `view-preview-button-${id}`;
  const isShowTooltip = previewAvailable && !hasPreview;
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(async () => {
    if (previewAvailable) {
      if (hasPreview && !loading) {
        setLoading(true);
        await handleOpenPreview();
        setLoading(false);
      }
    } else {
      handleDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, hasPreview, previewAvailable]);

  return (
    <Tooltip title={isShowTooltip ? 'Preview is not yet ready, please wait' : ''} placement="right" id={elementId}>
      <button key={id} type="button" onClick={handleClick} id={elementId} data-testid="artifact-open-preview">
        {hasIcon && (
          <span className="files-list__files-icon">
            <img src={getArtifactsIcon(type)} alt="" title={title} data-testid="artifact-doc" />
          </span>
        )}
        {customFileName || <FileName title={title} />}
        {loading ? <InputLoader className="files-list__files-loader" /> : null}
      </button>
    </Tooltip>
  );
};

export default ArtifactPreviewWrapper;
