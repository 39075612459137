/* istanbul ignore file */
import React from 'react';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import { useProjectWeeklyReportsUrl } from 'erp/project/weeklyreports/ProjectWeeklyReportRouter';
import { useParams } from 'react-router-dom';
import ProjectWeeklyReportsBreadcrumbs from 'erp/project/weeklyreports/shared/ProjectWeeklyReportsBreadcrumbs';

const SingleProjectWeeklyReportBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getSingleEntityUrl } = useProjectWeeklyReportsUrl();
  const { reportId } = useParams<Dictionary<string>>();

  return (
    <ProjectWeeklyReportsBreadcrumbs>
      <SingleBreadcrumbItem entity={reportId!} link={getSingleEntityUrl(reportId)} />
      {children}
    </ProjectWeeklyReportsBreadcrumbs>
  );
};

export default SingleProjectWeeklyReportBreadcrumbs;
