/* istanbul ignore file */
import React from 'react';
import { useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import { CREATE_SERVICE_CATALOG } from 'erp/serviceCatalog/useServiceCatalogService';
import CreateButton from 'uibuilder/button/CreateButton';

const CreateServiceCatalogButton = () => {
  const { getCreateUrl } = useServiceCatalogUrl();

  return (
    <CreateButton
      url={getCreateUrl()}
      label="Add service"
      key="create-service"
      permissionToCheck={CREATE_SERVICE_CATALOG}
    />
  );
};

export default CreateServiceCatalogButton;
