// @ts-ignore
import TurndownService from 'turndown';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import rules from 'shared/textile/textileRules';

const createTurndownService = () => {
  const turndownService = new TurndownService();

  Object.entries(rules).forEach(([key, rule]) => {
    turndownService.addRule(key, rule);
  });

  return turndownService;
};

const htmlToTextile = (html: string | null | undefined) => {
  if (!html) {
    return html;
  }

  const turndownService = createTurndownService();

  return turndownService.turndown(html);
};

export default htmlToTextile;
