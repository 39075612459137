import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResourceData } from './crud/baseCrudService';

/**
 * Method that makes request to API and sets data
 *
 * @param {function} getDataMethod Function thats returns promise (ex. function useAPI)
 * @param setData
 * @param isNeedToLoad
 * @param dependencies -- list of dependencies for useEffect
 * @returns {boolean} Returns true if data is loading
 */
const useGetData = (
  getDataMethod: Nullable<() => Promise<ResourceData>>,
  setData: Dispatch<SetStateAction<ResourceData>>,
  isNeedToLoad: boolean = true,
  ...dependencies: any[]
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const history = useNavigate();

  useEffect(() => {
    let isComponentMounted = true;

    const getData = async () => {
      try {
        if (getDataMethod) {
          const res = await getDataMethod();
          if (isComponentMounted) {
            setData(res);
          }
        }
      } catch (error) {
        if ((error as Response).status === 403) {
          history('/access-denied');
        }

        if ((error as Response).status === 404) {
          history('/not-found');
        }
      } finally {
        if (isComponentMounted) {
          setLoading(false);
        }
      }
    };

    if (loading) {
      getData();
    }

    return () => {
      isComponentMounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, ...dependencies]);

  useEffect(() => {
    if (isNeedToLoad) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  return { loading };
};

export default useGetData;
