/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import { DateInput, TextArea, TextInput, Checkbox, WysiwygInput } from 'shared/uibuilder/form/input';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import InvoiceTotalInput from 'financialAnalytic/invoice/shared/input/InvoiceTotalInput';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import Box from 'uibuilder/Box';
import AssetCategoryDropdown from 'financialAnalytic/assets/shared/input/AssetCategoryDropdown';
import AssetCategoryTypeDropdown from 'financialAnalytic/assets/shared/input/AssetCategoryTypeDropdown';
import AssetConditionDropdown from 'financialAnalytic/assets/shared/input/AssetConditionDropdown';
import AssetUsefulLifeInput from 'financialAnalytic/assets/shared/input/AssetUsefulLifeInput';
import AssetBillingCycleInput from 'financialAnalytic/assets/shared/input/AssetBillingCycleInput';
import OfficeDropdown from 'financialAnalytic/shared/input/OfficeDropdown';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import AccountInput from 'financialAnalytic/ledger/shared/input/AccountInput';
import useAssetService, {
  isExpandedEquipmentCategory as initialIsExpandedEquipmentCategory,
  isIntangibleAsset as initialIsIntangibleAsset,
  isSubscription as initialIsSubscription,
  isAutoRenewSubscription as initialIsAutoRenewSubscription,
  ASSET_STATUS,
} from 'financialAnalytic/assets/useAssetService';
import { CURRENCY_TYPES } from 'erp/employee/finance/shared/financeService';
import { NotDismissedEmployeeInput } from 'erp/employee';
import useInputHelper from '../../../shared/uibuilder/form/input/inputHelper';
import BudgetCategoryDropdown from 'financialAnalytic/journalEntry/shared/input/BudgetCategoryDropdown';
import { isBudgetCategoryRequired } from 'erp/serviceCatalog/budgets/shared/service/budgetCatagorySuggestionService';

const { EUR, ...ASSET_CURRENCY_TYPES } = CURRENCY_TYPES || {};

const isExpandedEquipmentCategory = (data: any) => initialIsExpandedEquipmentCategory(data.category);

const isIntangibleAsset = (data: any) => initialIsIntangibleAsset(data.category);

const isSubscription = (data: any) => initialIsSubscription(data.type);

const isAutoRenewSubscription = (data: any) => initialIsAutoRenewSubscription(data.subscriptionDetails);

const AssetForm = ({ isEditForm }: { isEditForm?: boolean }) => {
  const { getBalanceAccounts, getExpenseAccounts } = useAssetService();
  const { getValue: getStatus } = useInputHelper({ source: 'status' });
  const status = getStatus();
  const { getValue: getExpenseAccountId } = useInputHelper({ source: 'attribution.expenseAccountId' });
  const expenseAccountId = getExpenseAccountId();
  const { getValue: getPurchaseDate } = useInputHelper<string>({ source: 'purchaseDate' });
  const purchaseDate = getPurchaseDate();
  const isAllowToEditAllFields = status === ASSET_STATUS.ON_BALANCE || status === ASSET_STATUS.WRITE_OFF_AFTER_PURCHASE;

  return (
    <>
      <FormSection title="Asset Details">
        <FormRow>
          <TextInput source="inventoryNumber" label="Inventory Number" isVisible={!isEditForm} />
          <AssetCategoryDropdown
            disabled={isEditForm && !isAllowToEditAllFields}
            source="category"
            label="Category"
            placeholder="Select Category"
          />
          <ClearAfterUnmountWrapper isVisible={(data: any) => data.category}>
            <AssetCategoryTypeDropdown
              disabled={isEditForm && !isAllowToEditAllFields}
              source="type"
              label="Type"
              categorySource="category"
              placeholder="Select Type"
            />
          </ClearAfterUnmountWrapper>
          <OfficeDropdown
            disabled={isEditForm && !isAllowToEditAllFields}
            source="office"
            filterOptions={({ label }: { label: string }) => !['Tbilisi'].includes(label)}
          />
        </FormRow>

        <FormRow>
          <TextInput disabled={isEditForm && !isAllowToEditAllFields} source="name" label="Name" />
          <TextInput disabled={isEditForm && !isAllowToEditAllFields} source="serialNumber" label="Serial Number" />
          <ClearAfterUnmountWrapper isVisible={isExpandedEquipmentCategory}>
            <AssetConditionDropdown source="condition" label="Condition" />
          </ClearAfterUnmountWrapper>
          <TextInput source="location" />
        </FormRow>

        <BigFormRow>
          <TextArea source="characteristics" />
        </BigFormRow>
      </FormSection>

      <FormSection title="Financial Information">
        <FormRow>
          <AccountInput
            disabled={isEditForm && !isAllowToEditAllFields}
            source="attribution.balanceAccountId"
            label="Balance Account"
            placeholder="Select Balance Account"
            loadDataMethod={getBalanceAccounts}
            cacheKey="balance-accounts"
          />
          <AccountInput
            disabled={isEditForm && !isAllowToEditAllFields}
            source="attribution.expenseAccountId"
            label="Expense Account"
            placeholder="Select Expense Account"
            loadDataMethod={getExpenseAccounts}
            cacheKey="expense-accounts"
          />
          <ClearAfterUnmountWrapper isVisible={(data: any) => data.purchaseDate && data.attribution.expenseAccountId}>
            <BudgetCategoryDropdown
              disabled={(isEditForm && !isAllowToEditAllFields) || !isBudgetCategoryRequired(expenseAccountId)}
              source="attribution.budgetCategory"
              label="Budget Category"
              dateValue={purchaseDate}
              accountIdValue={expenseAccountId}
            />
          </ClearAfterUnmountWrapper>
        </FormRow>

        <FormRow>
          <InvoiceTotalInput
            disabled={isEditForm && !isAllowToEditAllFields}
            source="cost.amount"
            currencySource="cost.currency"
            label="Cost Amount"
          />
          <CurrencyDropdown
            disabled={isEditForm && !isAllowToEditAllFields}
            source="cost.currency"
            label="Cost Currency"
            placeholder=""
            options={ASSET_CURRENCY_TYPES}
          />
          <AssetUsefulLifeInput
            disabled={isEditForm && !isAllowToEditAllFields}
            source="customUsefulLifePeriod"
            label="Useful Life"
            categorySource="category"
            typeSource="type"
          />
        </FormRow>

        <FormRow>
          <DateInput disabled={isEditForm && !isAllowToEditAllFields} label="Purchase Date" source="purchaseDate" />
          <ClearAfterUnmountWrapper isVisible={isIntangibleAsset}>
            <DateInput
              disabled={isEditForm && !isAllowToEditAllFields}
              label="Validity Start Date"
              source="validityStartDate"
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={isIntangibleAsset}>
            <DateInput
              disabled={isEditForm && !isAllowToEditAllFields}
              label="Validity End Date"
              source="validityEndDate"
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={isExpandedEquipmentCategory}>
            <DateInput
              disabled={isEditForm && !isAllowToEditAllFields}
              label="Warranty End Date"
              source="warrantyEndDate"
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>

      <FormSection title="Subscription details" isVisible={isSubscription}>
        <FormRow>
          <ClearAfterUnmountWrapper>
            <Checkbox
              disabled={isEditForm && !isAllowToEditAllFields}
              source="subscriptionDetails.isAutoRenew"
              label="Is auto-renew?"
            />
          </ClearAfterUnmountWrapper>
        </FormRow>

        <FormRow>
          <ClearAfterUnmountWrapper isVisible={isAutoRenewSubscription}>
            <DateInput
              disabled={isEditForm && !isAllowToEditAllFields}
              label="Next Payment Date"
              source="subscriptionDetails.nextPaymentDate"
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={(data: any) => !isAutoRenewSubscription(data)}>
            <DateInput
              disabled={isEditForm && !isAllowToEditAllFields}
              label="Expiry Date"
              source="subscriptionDetails.expiryDate"
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper>
            <AssetBillingCycleInput
              disabled={isEditForm && !isAllowToEditAllFields}
              source="subscriptionDetails.billingCycle"
              label="Billing cycle"
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>

      <FormSection title="Assignment Details" isVisible={!isEditForm}>
        <FormRow>
          <NotDismissedEmployeeInput
            disabled={isEditForm && !isAllowToEditAllFields}
            source="assignmentDetails.currentOwner"
            nameSource="assignmentDetails.currentOwnerFullName"
            label="Current Owner"
            includeContractors
          />
          <ClearAfterUnmountWrapper isVisible={(data: any) => !!data?.assignmentDetails?.currentOwner}>
            <DateInput
              disabled={isEditForm && !isAllowToEditAllFields}
              source="assignmentDetails.provisionDate"
              label="Provision Date"
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>

      <FormSection title="Note" isVisible={!isEditForm}>
        <BigFormRow>
          <Box sx={{ '.label__wrapper': { display: 'none' } }}>
            <WysiwygInput disabled={isEditForm && !isAllowToEditAllFields} source="note.content" label="Note" />
          </Box>
        </BigFormRow>
      </FormSection>
    </>
  );
};

export default AssetForm;
