import React from 'react';
import { DateField, FieldProps, TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import EmployeeContractEndDateLayout from 'erp/contracts/shared/layout/EmployeeContractEndDateLayout';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import SimpleFieldLayout from 'uibuilder/layout/field/SimpleFieldLayout';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import EmployeeContractWillExpireSoonBadge from 'erp/contracts/shared/RelatedContractsList/badge/EmployeeContractWillExpireSoonBadge';
import useContractsService from 'erp/contracts/shared/contractsService';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';

const ContractEndDateField = (props: FieldProps<string> | undefined) => {
  const { BaseFieldLayout } = useUiTheme();
  const { getValue, getLabel } = useFieldHelper(props);
  const { getValue: getIsIndefiniteValue } = useFieldHelper({ source: 'indefinite', value: false });
  const { isContractWillExpireSoon } = useContractsService();

  const value = getValue();
  const isIndefinite = getIsIndefiniteValue();
  const notification = isContractWillExpireSoon(value) ? <EmployeeContractWillExpireSoonBadge /> : null;
  const badgeNotification = isIndefinite ? (
    <InputTooltip source="" message="Contract for an indefinite period" />
  ) : null;

  return (
    <BaseFieldLayout
      label={
        <>
          {getLabel()}
          {badgeNotification}
        </>
      }
      value={
        <UiThemeOverrider
          overriddenLayouts={{
            BaseFieldLayout: SimpleFieldLayout,
          }}
        >
          <EmployeeContractEndDateLayout
            dateField={isIndefinite ? <TextField value="∞" /> : <DateField value={value} />}
            notification={notification}
          />
        </UiThemeOverrider>
      }
    />
  );
};

export default ContractEndDateField;
