import React, { useEffect } from 'react';
import useEmployeeService from 'erp/employee/employeeService';
import MultiSelectInput from 'shared/uibuilder/form/input/MultiSelectInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';

const MentorsInput = (
  props: DefaultInputPropTypes<any> & { noOptionsMessage?: (value?: Dictionary<string>) => string },
) => {
  const { search } = useEmployeeService();
  const { getValue, initializeValue } = useInputHelper(props);

  const mapEntry = (item: any) => ({
    value: item.alias,
    label: `${item.nameEn?.lastName} ${item.nameEn?.firstName}`,
  });

  const formatDataForMultiSelectInput = (data: any) => {
    if (!data) {
      return [];
    }

    return data.map(mapEntry);
  };

  const getSuggestions = async (query: any) => {
    const response = await search({
      filter: {
        searchString: {
          ct: query,
        },
      },
    });
    return response.result;
  };

  const rawValue = getValue() || [];

  const value = rawValue.map((item: any) => (item.value ? item : mapEntry(item)));

  useEffect(() => {
    initializeValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MultiSelectInput
      {...props}
      value={value}
      loadSuggestionsMethod={getSuggestions}
      mapResults={formatDataForMultiSelectInput}
    />
  );
};

export default MentorsInput;
