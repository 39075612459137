/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import { TextField } from 'shared/uibuilder/field';
import 'artifactStorage/Show/shared/field/ArtifactStorageShowPage.scss';
import { ArtifactFileTypeLabel } from 'artifactStorage/shared/artifactStorageService';

interface MetaInformationFieldProps {
  source: string;
}

const MESSAGE_CONTEXT = 'ArtifactStorageFile_MetaInfoTable';

const META_KEYS: Record<string, string> = {
  TYPE: 'Type',
  STATEMENT_OF_WORK_NUMBER: 'SOW Number',
  MSA_NUMBER: 'MSA Number',
  CHANGE_REQUEST_NUMBER: 'Change Request Number',
  AMENDMENT_NUMBER: 'Amendment Number',
  ACCEPTANCE_FROM_DATE: 'Acceptance From date',
  ACCEPTANCE_TO_DATE: 'Acceptance To date',
  PROJECT_WEEKLY_REPORT_FROM: 'Project Weekly Report From date',
  PROJECT_WEEKLY_REPORT_TO: 'Project Weekly Report To date',
  COMPANY_SERVICE_BUDGET_NAME: 'Budget Name',
};

const mapMetaInfo = (info: { key: string; value: any }) => {
  const mappedInfo = {
    key: META_KEYS[info.key] || info.key,
    value: (info.key === 'TYPE' && ArtifactFileTypeLabel[info.value]) || info.value,
  };

  return mappedInfo;
};

const MetaInformationField = ({ source }: MetaInformationFieldProps) => {
  const { getValue } = useFieldHelper<any>({ source });
  const relations = getValue();

  return (
    <div className="storage-file-info-table">
      <List
        getDataMethod={async () => {
          return Promise.resolve({ result: relations.map(mapMetaInfo) });
        }}
      >
        <ListPageLayout contextName={MESSAGE_CONTEXT}>
          <DataGridLayout showTotal={false} isSmallTable tableWrapperClassName="meta-info-table">
            <TextField source="key" width={250} />
            <TextField source="value" />
          </DataGridLayout>
        </ListPageLayout>
      </List>
    </div>
  );
};

export default MetaInformationField;
