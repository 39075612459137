import React from 'react';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useOpportunityService from 'crm/opportunity/shared/opportunityService';
import { SearchInput } from 'shared/uibuilder/form/input';

const mapResponse = (opportunity: { id: any; name: any }) => {
  return {
    id: opportunity.id,
    text: `${opportunity.id}: ${opportunity.name}`,
  };
};

type OpportunitySearchInputProps = {
  opportunityNameSource?: string;
  opportunityNameValue?: string;
  label?: Nullable<string>;
  source?: string;
  onChangeCallback?: (values: FormFieldsData) => void;
  errorSource?: string;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  required?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  tooltip?: string;
  defaultFilter?: Object;
};

const OpportunitySearchInput = ({
  opportunityNameSource = 'opportunityName',
  opportunityNameValue = '',
  ...props
}: OpportunitySearchInputProps) => {
  const { search } = useOpportunityService();

  return (
    <SearchInput
      searchRequest={search}
      noResultMessage="No Leads are found"
      mapResults={mapResponse}
      nameSource={opportunityNameSource}
      initialLabel={opportunityNameValue}
      {...props}
    />
  );
};

export default OpportunitySearchInput;
