import React from 'react';
import { isBudgetCategoryRequired } from 'erp/serviceCatalog/budgets/shared/service/budgetCatagorySuggestionService';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { get } from 'lodash';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import BudgetCategoryDropdown from './BudgetCategoryDropdown';

const BudgetCategoryDropdownWrapper = ({
  dateValue,
  accountIdValue,
  ...props
}: DefaultInputPropTypes<string> & {
  dateValue: string;
  accountIdValue: string;
}) => {
  const { data } = useFormContext();
  const accountId = get(data, accountIdValue);

  return (
    <BudgetCategoryDropdown
      disabled={!isBudgetCategoryRequired(accountId)}
      source="budgetCategory"
      label="Budget Category"
      dateValue={dateValue}
      accountIdValue={accountId}
    />
  );
};

export default BudgetCategoryDropdownWrapper;
