/* istanbul ignore file */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import DeleteButton from 'uibuilder/button/DeleteButton';
import { useListContext } from 'shared/uibuilder/list/ListContext';

type DeleteTimelineRecordButtonProps = {
  permissionToCheck: Nullable<string | string[]>;
  deleteMethod: (id: string) => Promise<void>;
  entityName: string;
  id: string;
  timelineId: string;
};

const DeleteTimelineRecordButton = ({
  permissionToCheck,
  deleteMethod,
  entityName,
  timelineId,
  id,
}: DeleteTimelineRecordButtonProps) => {
  const message = `Do you really want to delete this ${entityName}?`;
  const { deleteFromList } = useListContext();

  return (
    <DeleteButton
      deleteMethod={() => deleteMethod(id)}
      afterDelete={{
        execute: () => (deleteFromList ? deleteFromList(timelineId) : null),
      }}
      displayMessage={message}
      permissionToCheck={permissionToCheck}
      showIcon={false}
      buttonTag={MenuItem}
      className="danger-color"
    />
  );
};

export default DeleteTimelineRecordButton;
