import React from 'react';
import classnames from 'classnames';
import SanitizedHtml from 'shared/security/SanitizedHtml';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { TooltipSize } from 'uibuilder/Tooltip';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

const getValidLinkFromText = (text: string) => {
  const websiteRegex = /(https?:\/\/)/;
  let resultLink = text;

  if (!websiteRegex.test(text)) {
    resultLink = `http://${text}`;
  }

  return resultLink;
};

const parseTooltip = (tooltipText: string) => {
  // matches all "someword":link in the string
  const modellerLinkRegex = /"(.[^"]+)":(.[^\s]+)/g;

  // replace all "link":https://url.com with <a href=...>
  return tooltipText.replace(
    modellerLinkRegex,
    (match, p1, p2) => `<a href="${getValidLinkFromText(p2)}" target="_blank" rel="noopener">${p1}</a>`,
  );
};

interface InputTooltipProps {
  source?: Nullable<string>;
  message: string | React.ReactElement;
  className?: string;
  size?: 'inherit' | 'large' | 'medium' | 'small';
  sx?: SxProps<Theme>;
  tooltipSize?: TooltipSize;
}

const InputTooltip = ({ message, source, className, size, sx = {}, tooltipSize }: InputTooltipProps) => {
  const id = `info-${(source || '').split('.').join('')}`;

  const renderTooltipContent = (spanProps = {}) =>
    typeof message === 'string' ? (
      <SanitizedHtml html={parseTooltip(message)} wrapperTag="span" {...spanProps} />
    ) : (
      <span {...spanProps}>{message}</span>
    );

  return (
    <Tooltip id={id} title={renderTooltipContent()} arrow placement="right" size={tooltipSize}>
      <InfoIcon
        className={classnames('input-tooltip tooltip-icon', className)}
        fontSize={size}
        sx={size ? sx : { fontSize: '16px', transform: 'translateY(3px)', marginLeft: '0.25rem', ...sx }}
      />
    </Tooltip>
  );
};

export default InputTooltip;
