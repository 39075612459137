import React from 'react';
import { useBusinessEngineApi } from 'api';
import { useNavigate } from 'react-router-dom';
import { CustomForm } from 'oneStop/CustomRequestForm/formMap';
import { LoadingParams } from 'shared/uibuilder/list/ListContext';
import { MIN_THRESHOLD } from 'oneStop/List/OneStopListPageLayout';
import useCacheService from 'shared/cache/cacheService';
import { HTTP, ROUTE403 } from 'shared/api/const';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { COMMON_ERROR_SOURCE } from 'shared/uibuilder/form/formHelper';
import useOneStopRequestMapper from './oneStopRequestMapper';
import SanitizedHtml from '../shared/security/SanitizedHtml';
import HistoryIcon from '@mui/icons-material/HistoryOutlined';
import htmlToTextile from 'shared/textile/textileConverter';
import { FormInputType } from 'shared/uibuilder/form/Builder/FormInputFactory';
import { FormInputs } from 'shared/uibuilder/form/Builder';

export enum OneStopRequestStatus {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  CLOSED = 'Closed',
}

type RecentRequest = {
  lastUsedAt: string;
  requestId: string;
};

type Field = {
  constraints: any;
  defaultValue: Nullable<string>;
  id: string;
  label: string;
  properties: Nullable<string>;
  type: string;
  values: Nullable<{}[]>;
};

export enum RequestType {
  TYPE_TASK_TRACKER_ISSUE = 'task_tracker_issue',
  TYPE_PROCESS = 'process',
  TYPE_FORM = 'form',
}

export type Request = {
  description: string;
  fields: Nullable<Field[]>;
  form: Nullable<CustomForm>;
  id: string;
  type?: RequestType;
  section: string;
  shortDescription: Nullable<string>;
  title: string;
  validationSchema: Nullable<{}[]>;
  sectionTitle?: string;
};

type Requests = {
  [key: string]: Request;
};

type Section = {
  icon: React.ReactElement;
  service_id: string; // eslint-disable-line camelcase
  title: string;
};

type Sections = {
  [key: string]: Section;
};

type oneStopProps = {
  sections: Sections;
  requests: Requests;
  recent: RecentRequest[];
};

export type FilterSection = {
  id: string;
  title: string;
  icon: Nullable<React.ReactElement>;
  items?: Request[];
};

export type MappedSection = {
  id: string;
  title: string;
  icon: Nullable<React.ReactElement>;
  items: Request[];
};

export const RESOURCE_URL = '/onestop';
const CACHE_NAME = 'oneStop';
const CACHE_ID = 'data';
const DEFAULT_PROCESS_TITLE = 'Process';

export const FILTER_TABS = {
  ALL: 'ALL',
  RECENT: 'RECENT',
};

export function sortRequestsById(a: Request, b: Request) {
  if (a.id < b.id) return -1;
  if (a.id > b.id) return 1;
  return 0;
}

const mapFilterList = ({ sections, requests, recent }: oneStopProps) => {
  const fullRecentRequests = recent.map(recentRequest => {
    const request = requests[recentRequest.requestId];

    return {
      ...recentRequest,
      ...request,
    };
  });

  const sectionsList = [
    {
      id: FILTER_TABS.RECENT,
      title: 'Recently sent',
      icon: <HistoryIcon />,
      items: fullRecentRequests,
    },
    {
      id: FILTER_TABS.ALL,
      title: 'All requests',
      icon: null,
      items: Object.values(requests).sort(sortRequestsById),
    },
  ];

  Object.keys(sections).forEach(section => {
    const row = {
      id: section,
      title: sections[section].title,
      icon: sections[section].icon,
      items: [],
    };

    Object.keys(requests).forEach(request => {
      const item = requests[request];
      if (item.section === section) {
        item.sectionTitle = sections[section].title;

        // @ts-ignore
        row.items.push(item);
        row.items.sort(sortRequestsById);
      }
    });

    sectionsList.push(row);
  });

  return sectionsList;
};

const isPromise = (value: any) => {
  return value && value.then && typeof value.then === 'function';
};

const buildSearchString = (item: Request) => {
  let result = `${item.id}. ${item.title}`;
  result += `${item.sectionTitle}`;
  result += `${item.description}`;

  return result.trim().toLocaleLowerCase();
};

const useOneStopService = ({ messageContext = undefined }: { messageContext?: string } = {}) => {
  const navigate = useNavigate();
  const { addMessage } = useMessageService(messageContext);
  const { getValue, addToCache, invalidateCache } = useCacheService(CACHE_NAME);
  const { sendGetRequest, sendPostRequest } = useBusinessEngineApi();
  const { mapToApiFormat } = useOneStopRequestMapper();
  let dataPromise: Promise<MappedSection[]>;
  let requestPromise: Promise<MappedSection[]>;

  const loadData = async () => {
    let response;

    if (isPromise(dataPromise)) {
      response = dataPromise;
    } else {
      response = sendGetRequest(RESOURCE_URL);
      dataPromise = response;
    }

    return response.then((res: any) => {
      return res.clone().json();
    });
  };

  const loadHistoryData = async (request: any) => {
    const response = await sendPostRequest(`${RESOURCE_URL}/history/search`, request);
    return response.json();
  };

  const getProcessTitle = (requestId: string) => {
    const cachedValue = getValue(CACHE_ID);

    return (cachedValue && cachedValue[requestId]?.title) || DEFAULT_PROCESS_TITLE;
  };

  const getProcessForbiddenHtmlMessage = (processId: string) => {
    if (processId === 'RM03') {
      return (
        '<div>You cannot initiate your Career Review by yourself.<br>' +
        '<b>Hint</b>: This error has likely occurred because your Career Review will start automatically.<br>' +
        'Check your <a href="employees/me/qualifications"> DaVinci profile </a> to see when your Career Review will start.</div>'
      );
    } else {
      return undefined;
    }
  };

  const handleGetByIdError = (id: string, errorStatus: number) => {
    switch (errorStatus) {
      case HTTP.FORBIDDEN:
        navigate(ROUTE403, { state: { htmlMessage: getProcessForbiddenHtmlMessage(id) } });
        break;
      case HTTP.NOT_FOUND:
        addMessage(new ErrorMessage(`${getProcessTitle(id)} not found.`));
        break;
      default:
        addMessage(new ErrorMessage('Process cannot be shown due to the server error.'));
        break;
    }
  };

  const initialGetById = async (id: string) => {
    try {
      const response = await sendGetRequest(`${RESOURCE_URL}/${id}`);
      return response.json();
    } catch (error) {
      handleGetByIdError(id, (error as Response).status);
      return null;
    }
  };

  const loadRequestData = async (id: string) => {
    let response;

    if (isPromise(requestPromise)) {
      response = requestPromise;
    } else {
      response = initialGetById(id);
      requestPromise = response;
    }

    return response.then((res: any) => res);
  };

  const getData = async () => {
    const cachedValue = getValue(CACHE_ID);
    if (cachedValue) {
      return cachedValue;
    } else {
      const oneStopData = await loadData();
      const mappedList = mapFilterList(oneStopData);

      addToCache(CACHE_ID, mappedList);

      return mappedList;
    }
  };

  const getFilteredData = async (activeFilterId: string) => {
    const oneStopData = await getData();

    // @ts-ignore
    const activeSection = oneStopData.filter((section: MappedSection) => {
      return section.id === activeFilterId;
    });

    return activeSection.length ? activeSection[0].items : [];
  };

  const getSections = async () => {
    const oneStopData = await getData();

    // @ts-ignore
    return oneStopData.map((section: FilterSection) => {
      const object = {
        ...section,
      };

      delete object.items;

      return object;
    });
  };

  const getSearchData = async (searchString: string) => {
    const requests = await getFilteredData(FILTER_TABS.ALL);
    const inputValue = searchString.trim().toLowerCase();
    const inputLength = inputValue.length;
    const restSuggestions: any[] = [];
    const suggestionsByIdPriority: any[] = [];

    if (inputLength >= MIN_THRESHOLD) {
      Object.keys(requests).forEach(request => {
        const searchableItem = requests[parseInt(request, 10)];
        const searchableItemId = searchableItem.id.toLowerCase();

        if (searchableItemId.includes(inputValue)) {
          suggestionsByIdPriority.push(searchableItem);
        } else {
          const itemSearchString = buildSearchString(searchableItem);

          if (itemSearchString.includes(inputValue)) {
            restSuggestions.push(searchableItem);
          }
        }
      });
    }

    return [...suggestionsByIdPriority.sort(sortRequestsById), ...restSuggestions.sort(sortRequestsById)];
  };

  const search = async ({ filter }: LoadingParams) => {
    const activeFilterId = filter?.section?.eq;
    const searchString = filter?.['specification:search']?.eq || '';
    let filteredData;

    if (searchString && searchString.length) {
      filteredData = await getSearchData(searchString);
    } else {
      filteredData = await getFilteredData(activeFilterId);
    }

    return {
      result: filteredData,
    };
  };

  const getRequestFromListCache = (requestId: string) => {
    const cachedValue = getValue(CACHE_ID);
    if (cachedValue) {
      const allRequests = cachedValue.filter((section: MappedSection) => {
        return section.id === FILTER_TABS.ALL;
      });
      return allRequests[0].items.filter((request: Request) => {
        return request.id === requestId;
      })[0];
    } else {
      return null;
    }
  };

  const getById = async (requestId: string) => {
    const cachedRequestFromList = getRequestFromListCache(requestId);
    if (cachedRequestFromList && cachedRequestFromList.fields) {
      return cachedRequestFromList;
    } else {
      return loadRequestData(requestId);
    }
  };

  const mapErrors = async (response: Response) => {
    const json = await response.json();
    const { errors: errorsObject = {} } = json;

    if (errorsObject.all) {
      errorsObject[COMMON_ERROR_SOURCE] = <SanitizedHtml html={errorsObject.all} wrapperTag="span" />;
      delete errorsObject.all;
      json.errors = errorsObject;
    }

    response.json = () => json;
    return response;
  };

  const sendRequest = async (requestId: string, formData: any) => {
    try {
      const request = await getById(requestId);
      const mappedFormData = await mapToApiFormat(requestId, formData, request);
      const response = await sendPostRequest(`${RESOURCE_URL}/${requestId}`, mappedFormData);
      return response.json();
    } catch (response) {
      let modifiedResponse = response;

      if ((response as Response).status === HTTP.BAD_REQUEST) {
        modifiedResponse = await mapErrors(response as Response);
      }

      throw modifiedResponse;
    }
  };

  const getFieldSourcesForHtmlFields = (data: FormInputs): string[] => {
    const sources: string[] = [];

    data?.sections?.forEach(section => {
      section?.rows?.forEach(row => {
        row?.fields?.forEach(field => {
          if (field.type === FormInputType.WYSIWYG && field.source) {
            sources.push(field.source);
          }
        });
      });
    });

    return sources;
  };

  const getFormattedData = (data: any, htmlSources: string[]) => {
    const formattedData: any = {};
    Object.keys(data)?.forEach(key => {
      const value = data[key];
      if (htmlSources.includes(key)) {
        formattedData[key] = htmlToTextile(value);
      } else {
        formattedData[key] = value;
      }
    });

    return formattedData;
  };

  return {
    search,
    getById,
    sendRequest,
    getSections,
    invalidateCache,
    loadHistoryData,
    getFieldSourcesForHtmlFields,
    getFormattedData,
  };
};

export default useOneStopService;
