/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
// components
import DeleteButton from 'uibuilder/button/DeleteButton';
// services
import useLeaveService from 'erp/leave/shared/leaveService';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';

const deleteMessage = 'Do you really want to delete this leave?';

const DeleteLeaveButton = () => {
  const { id: leaveId } = useParams<Dictionary<string>>();
  const { deleteById } = useLeaveService();
  const { getListUrl } = useLeaveUrl();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(leaveId!)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Leave has been successfully deleted.',
        errorMessage: `Can't delete leave`,
      }}
    />
  );
};

export default DeleteLeaveButton;
