/* istanbul ignore file */
import useBaseCrudService from 'shared/crud/baseCrudService';
import employeeFeedbackValidation from 'erp/employee/Timeline/feedback/form/employeeFeedbackValidation';
import { useKernelApi } from 'api';
import employeeFeedbackFlagValidation from 'erp/employee/Timeline/feedback/form/employeeFeedbackFlagValidation';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export const CREATE_EMPLOYEE_FEEDBACK = 'CREATE_FEEDBACK';
export const UPDATE_EMPLOYEE_FEEDBACK = 'UPDATE_FEEDBACK';

const useEmployeeFeedbackService = () => {
  const { sendPatchRequest } = useKernelApi();

  const { update } = useBaseCrudService({
    listResourceUrl: '/feedbacks',
    singleResourceUrl: '/feedbacks/:id',
    apiService: useKernelApi,
  });

  const updateFlag = async (feedbackId: any, data: FormFieldsData) => {
    const result = await sendPatchRequest(`/feedbacks/${feedbackId}`, data);
    return result.json();
  };

  const getValidationSchema = () => Promise.resolve(employeeFeedbackValidation);

  const getFlagValidationSchema = () => Promise.resolve(employeeFeedbackFlagValidation);

  return {
    getValidationSchema,
    update,
    updateFlag,
    getFlagValidationSchema,
  };
};

export default useEmployeeFeedbackService;
