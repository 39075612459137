import React, { useCallback, useState } from 'react';
import useAsyncValue from 'shared/asyncHelper';
import SimpleList from 'shared/uibuilder/list/SimpleList';
import { TextField } from 'shared/uibuilder/field';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import OneClickButton from 'authentication/OneClick/OneClickButton';
import RedirectButton from 'authentication/OneClick/RedirectButton';
import useOneClickService from 'authentication/OneClick/oneClickService';
import OneClickPasswordForm from 'authentication/OneClick/OneClickPasswordForm';
import useAuthenticationService from 'authentication/authenticationService';

const OneClick = () => {
  const oneClickPassword = sessionStorage.getItem('oneClickPassword');
  const [hasPassword, setHasPassword] = useState(oneClickPassword !== 'null');
  const { isAuthenticated } = useAuthenticationService();
  const isUserAuthenticated = useAsyncValue(isAuthenticated);

  const setPassword = (passwordParam: string | null) => {
    sessionStorage.setItem('oneClickPassword', passwordParam!);
    setHasPassword(passwordParam !== null);
  };

  const getPasswordDto = useCallback(() => {
    return {
      password: oneClickPassword,
    };
  }, [oneClickPassword]);

  const { getUsers } = useOneClickService();
  const getUsersWrapper = useCallback(async () => {
    const users = await getUsers(getPasswordDto()).catch(() => {
      setPassword(null);
    });
    return { result: users };
    // Suppressed warnings because async function getUsers is excluded from dependencies because it calls rerender.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPasswordDto]);

  const OneClickList = useCallback(() => {
    return (
      <SimpleList getDataMethod={() => getUsersWrapper()}>
        <ListPageLayout actions={!isUserAuthenticated ? [<RedirectButton key="redirect-button" />] : []}>
          <DataGridLayout
            showTotal={false}
            buttons={[<OneClickButton key="onestop-button" password={getPasswordDto()} />]}
            isSmallTable
            rowKeySource="username"
            tableWrapperClassName="one-click-table"
          >
            <TextField source="role" width={300} />
            <TextField source="username" />
          </DataGridLayout>
        </ListPageLayout>
      </SimpleList>
    );
  }, [getPasswordDto, getUsersWrapper, isUserAuthenticated]);

  if (isUserAuthenticated === null) {
    return null;
  }

  return <>{hasPassword ? <OneClickList /> : <OneClickPasswordForm setPassword={setPassword} />}</>;
};

export default OneClick;
