/* istanbul ignore file */
import React, { ReactNode } from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';

const OpportunitiesBreadcrumbs = ({ children }: { children?: ReactNode | ReactNode[] }) => {
  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Opportunities" link="/crm/opportunities" />
      {children}
    </Breadcrumbs>
  );
};

export default OpportunitiesBreadcrumbs;
