/* istanbul ignore file */
import React, { useEffect } from 'react';
import { Routes, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { Route } from 'shared/routing';
import { useDispatch } from 'react-redux';
import { clearTimer } from 'shared/uibuilder/DataTimer';

import Page404 from 'shared/uibuilder/Page404';
import ListPage from 'erp/employee/finance/List';
import ViewPage from 'erp/employee/finance/Show';
import { Create, Update } from 'erp/employee/finance/createupdate';

import FinanceContext from 'erp/employee/finance/FinanceContext';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { READ_EMPLOYEE_COMPENSATION_PACKAGES } from 'erp/employee/employeeService';
import {
  FINANCE_READ_EMPLOYEE_COMPENSATION_PACKAGE,
  FINANCE_UPDATE_EMPLOYEE_COMPENSATION_PACKAGE,
} from 'erp/employee/finance/shared/financeService';
import { READ, UPDATE } from 'crm/crmService';
import useFeatureToggle, { Features } from 'featuretoggle';

export const FINANCE_ID = 'compensationId';
export const PATH = '/finance';

export const useFinanceRoute = () => {
  return useRoute({
    listRoute: `${PATH}`,
    singleEntityRoute: `/:${FINANCE_ID}`,
  });
};

export const useFinanceUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${employeeLink}${PATH}`,
    idSource: FINANCE_ID,
  });
};

export const useFinanceId = () => {
  return useParams<Dictionary<string>>()[FINANCE_ID]!;
};

export const FE_UPDATE_EMPLOYEE_COMPENSATION_PACKAGES = 'FE_UPDATE_EMPLOYEE_COMPENSATION_PACKAGES';
export const CREATE_COMPENSATION_PACKAGE = 'CREATE_COMPENSATION_PACKAGE';

const FinanceRouter = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const isNewPayrollEnabled = isFeatureEnabled(Features.PAYROLL);

  const { listRoute, createRoute, updateRoute, singleEntityRoute } = useFinanceRoute();
  const { getListUrl } = useFinanceUrl();
  const listUrl = getListUrl();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearTimer(listUrl));
    };
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <GlobalProtectedRoute globalPermissionToCheck={READ_EMPLOYEE_COMPENSATION_PACKAGES}>
            <ListPage />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={createRoute}
        element={
          <GlobalProtectedRoute path={createRoute} globalPermissionToCheck={CREATE_COMPENSATION_PACKAGE}>
            <Create />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={updateRoute}
        element={
          <FinanceContext
            permissionToCheck={isNewPayrollEnabled ? FINANCE_UPDATE_EMPLOYEE_COMPENSATION_PACKAGE : UPDATE}
          >
            <Update />
          </FinanceContext>
        }
      />

      <Route
        path={singleEntityRoute}
        element={
          <FinanceContext permissionToCheck={isNewPayrollEnabled ? FINANCE_READ_EMPLOYEE_COMPENSATION_PACKAGE : READ}>
            <ViewPage />
          </FinanceContext>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default FinanceRouter;
