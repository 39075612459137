/* istanbul ignore file */
import React, { useState } from 'react';
import Box from 'uibuilder/Box';
import Collapse from '@mui/material/Collapse';
import { Button } from 'uibuilder/button';
import MoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import LessIcon from '@mui/icons-material/ExpandLessOutlined';

interface BreadcrumbsWithMenuProps {
  breadcrumbs?: React.ReactNode;
  menu?: React.ReactNode;
}

const BreadcrumbsWithMenu = ({ breadcrumbs, menu = null }: BreadcrumbsWithMenuProps) => {
  const [menuIsOpened, setMenuIsOpened] = useState(false);

  return (
    <>
      <div className="d-flex align-items-center">
        {breadcrumbs && <div className="breadcrumbs__wrapper">{breadcrumbs}</div>}
        {menu && (
          <Button
            size="small"
            sx={{
              display: { xs: 'inline-flex', sm: 'none' },
              ml: '8px',
              mt: '-28px',
              minWidth: '10px',
              p: '3px !important',
            }}
            outline
            onClick={() => setMenuIsOpened(!menuIsOpened)}
          >
            {menuIsOpened ? <LessIcon /> : <MoreIcon />}
          </Button>
        )}
      </div>

      <Box
        className="show-page__menu-wrapper"
        sx={{ display: { xs: 'block', sm: 'none' }, mb: menuIsOpened ? '' : '0 !important' }}
      >
        <Collapse in={menuIsOpened}>{menu}</Collapse>
      </Box>
    </>
  );
};

export default BreadcrumbsWithMenu;
