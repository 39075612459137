/* istanbul ignore file */
import { ACCOUNTS_WITH_BUDGET_CATEGORIES } from 'erp/serviceCatalog/budgets/shared/service/budgetCatagorySuggestionService';

export default {
  type: {
    type: 'string',
    required: true,
  },
  subtype: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          in: ['LEAVE_COMPENSATION', 'ONE_TIME_PAYMENT', 'BENEFITS_EXPENSE', 'TAX', 'DEDUCTION'],
        },
      },
    },
  },
  employeeAlias: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          nin: ['TAX'],
        },
      },
    },
  },
  office: {
    type: 'string',
    required: true,
  },
  title: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  compensation: {
    type: 'object',
    _divisible: false,
    properties: {
      amount: {
        type: 'string',
        required: true,
      },
      currency: {
        type: 'string',
        required: true,
      },
    },
  },
  description: {
    type: 'string',
    maxSize: 1024,
  },
  registrationDate: {
    type: 'date',
    required: true,
  },
  startDate: {
    type: 'date',
    required: {
      value: true,
      when: {
        $type: {
          in: ['PERFORMANCE_BASED_BONUS'],
        },
      },
    },
    maxDate: {
      value: '$endDate',
      message: 'Please choose date earlier than End Date',
    },
  },
  endDate: {
    type: 'date',
    required: {
      value: true,
      when: {
        $type: {
          in: ['PERFORMANCE_BASED_BONUS'],
        },
      },
    },
    minDate: {
      value: '$startDate',
      message: 'Please choose date further than Start Date',
    },
  },
  leaveId: {
    type: 'string',
    required: {
      value: true,
      when: {
        $subtype: {
          nin: ['MATERNITY_LEAVE'],
        },
        $type: {
          in: ['LEAVE_COMPENSATION'],
        },
      },
    },
  },
  transferAccount: {
    type: 'string',
    required: {
      value: true,
      when: {
        $type: {
          in: ['DEDUCTION'],
        },
      },
    },
  },
  budgetCategory: {
    type: 'string',
    required: {
      value: true,
      when: {
        $transferAccount: {
          in: ACCOUNTS_WITH_BUDGET_CATEGORIES,
        },
      },
    },
  },
  project: {
    type: 'object',
    _divisible: false,
    properties: {
      projectId: {
        type: 'string',
        required: {
          value: true,
          when: {
            $subtype: {
              in: ['SALES_BONUSES_AND_COMMISSIONS', 'ACCOUNT_MANAGER_BONUSES_AND_COMMISSIONS'],
            },
          },
        },
      },
    },
  },
};
