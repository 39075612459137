/* istanbul ignore file */
import React from 'react';
import { useExpenseUrl } from 'financialAnalytic/expenses/ExpenseRouter';
import {
  CREATE_EXPENSE,
  EXPENSE_TYPES,
  FIND_EMPLOYEE_EXPENSES_BY_LEAVE_IDS,
} from 'financialAnalytic/expenses/useExpenseService';
import CreateButton from 'uibuilder/button/CreateButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ShowData from 'shared/uibuilder/show/ShowData';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { LEAVE_TYPES } from 'erp/leave/shared/leaveService';
import useDateService from 'shared/uibuilder/dateService/useDateService';

export const isLeaveCompensationButtonVisible = (data: ShowData) => {
  return (
    !data.getValueBySource('hasCompensation') &&
    ['REGISTERED', 'APPROVED'].includes(data.getValueBySource('status')) &&
    !['UNPAID_LEAVE'].includes(data.getValueBySource('type'))
  );
};

const AddLeaveCompensationButton = (props: { isVisible: boolean | ((data: any) => boolean) }) => {
  const { getCreateUrl } = useExpenseUrl();
  const { data } = useShowContext();
  const { formatDate } = useDateService();
  let subtype = data.getValueBySource('type');
  const startDate = data.getValueBySource('startDate');
  const endDate = data.getValueBySource('endDate');

  if (subtype === 'DAY_ON_DEMAND' || subtype === 'COMPENSATION') {
    subtype = 'PAID_LEAVE';
  }

  const expenseData = {
    employeeAlias: data.getValueBySource('employeeId'),
    employeeName: data.getValueBySource('employeeName'),
    leaveId: data.getValueBySource('id'),
    subtype,
    type: EXPENSE_TYPES.LEAVE_COMPENSATION,
    title: `${LEAVE_TYPES[data.getValueBySource('type') as keyof typeof LEAVE_TYPES]}, ${formatDate(
      startDate,
      DATE_FORMAT.FULL_DATE,
    )?.trim()} - ${formatDate(endDate, DATE_FORMAT.FULL_DATE)?.trim()}`,
    registrationDate: startDate,
  };

  return (
    <CreateButton
      url={`${getCreateUrl()}?${new URLSearchParams(expenseData).toString()}`}
      label="Add Compensation"
      key="create-expense"
      permissionToCheck={[CREATE_EXPENSE, FIND_EMPLOYEE_EXPENSES_BY_LEAVE_IDS]}
      variant="outlined"
      hasIcon={false}
      openInNewTab
      {...props}
    />
  );
};

export default AddLeaveCompensationButton;
