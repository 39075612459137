/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';

const EmployeeContractsBreadCrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useEmployeeContractUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Contracts" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default EmployeeContractsBreadCrumbs;
