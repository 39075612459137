import React, { useState } from 'react';
import Droppable from 'shared/dnd/Droppable';
import Box from '@mui/material/Box';
import ModalWindow from '../../../../../shared/uibuilder/ModalWindow';
import { CreateForm } from '../../../../../shared/uibuilder/form';
import HiredForm from '../forms/HiredForm';
import DecisionForm from '../forms/DecisionForm';
import { ColumnState } from '../../types';
import { getBorderColor } from './utils';
import useRecruitingBoardService from '../../useRecruitingBoardService';
import { Stages } from '../../constants';
import RejectStageForm from '../forms/RejectForm';
import { GAP } from './Board';
import DraggableItem from './DraggableItem';

interface ColumnProps {
  entity: any;
  expanded: boolean;
  items: any;
  columnSettings: any;
}

interface PendingItem {
  vacancyId: string;
  applicationId: number;
  targetStage: string;
}

const Column: React.FC<ColumnProps> = ({ expanded, items, entity, columnSettings }) => {
  const [columnState, setColumnState] = useState<ColumnState>(ColumnState.IDLE);
  const [sourceStage, setSourceStage] = useState<string | null>(null);
  const [vacancyGroupStateId, setVacancyGroupStateId] = useState<string | null>(null);
  const renderItems = expanded ? items : items?.slice(0, 4);
  const [pendingItem, setPendingItem] = useState<PendingItem | null>(null);
  const [ActiveModal, setActiveModal] = useState<ReactComponent<any> | null>(null);
  const { moveStage } = useRecruitingBoardService();

  const closeDialog = () => {
    setActiveModal(null);
    setPendingItem(null);
    setSourceStage(null);
    setVacancyGroupStateId(null);
  };

  return (
    <>
      <Droppable
        params={{
          onDrop: ({ self, source }) => {
            const applicationId = source.data.id as number;
            const vacancyGroupId = source.data.entityId as string;
            const targetColumn = self.data.columnSettings as { stage: string };
            const targetStage = targetColumn.stage;

            const initialColumnName = source.data.stage;
            setSourceStage(initialColumnName as string);
            setVacancyGroupStateId(vacancyGroupId);
            setColumnState(ColumnState.IDLE);
            if (targetStage === initialColumnName) {
              return;
            }

            if (targetStage === Stages.Hired) {
              setPendingItem({ vacancyId: vacancyGroupId, applicationId, targetStage });
              setActiveModal(() => HiredForm);
            } else if (targetStage === Stages.Decision) {
              setPendingItem({ vacancyId: vacancyGroupId, applicationId, targetStage });
              setActiveModal(() => DecisionForm);
            } else if (targetStage === Stages.PendingRejection) {
              setPendingItem({ vacancyId: vacancyGroupId, applicationId, targetStage });
              setActiveModal(() => RejectStageForm);
            } else {
              moveStage(vacancyGroupId, applicationId as number, targetStage);
            }
          },
          onDragEnter: args => {
            const movedItem = args?.source?.data;
            if (movedItem.entityId === entity.id) {
              setColumnState(ColumnState.VALID_DROP);
            } else {
              setColumnState(ColumnState.INVALID_DROP);
            }
          },
          onDragLeave: () => setColumnState(ColumnState.IDLE),
        }}
        data={{ ...entity, columnSettings }}
      >
        {({ droppableRef }) => (
          <Box
            ref={droppableRef}
            gap={GAP}
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              overflowX: 'hidden',
              border: getBorderColor(columnState),
              backgroundColor: 'white',
              borderRadius: 1,
            }}
          >
            {renderItems?.map((itemData: any) => (
              <DraggableItem key={itemData.id} item={itemData} columnSettings={columnSettings} />
            ))}
          </Box>
        )}
      </Droppable>
      {ActiveModal && (
        <ModalWindow
          isOpen
          key="modal-not-strict"
          modalSize="sm"
          backdrop="static"
          title="Additional info required"
          onToggle={closeDialog}
        >
          <CreateForm
            submitFormFunc={formData => {
              if (pendingItem) {
                return moveStage(pendingItem.vacancyId, pendingItem.applicationId, pendingItem.targetStage, formData);
              } else {
                return Promise.reject();
              }
            }}
            afterSubmit={{
              execute: () => {
                setPendingItem(null);
                setActiveModal(null);
                setSourceStage(null);
              },
            }}
          >
            <ActiveModal
              handleCancelClick={closeDialog}
              sourceStage={sourceStage}
              vacancyGroupId={vacancyGroupStateId}
            />
          </CreateForm>
        </ModalWindow>
      )}
    </>
  );
};

export default React.memo(Column);
