/* istanbul ignore file */
import React from 'react';
import PrepopulateButton from 'erp/employee/prepopulation/PrepopulateButton';
import './UpdateTitle.scss';

type UpdateTitleProps = {
  scrollToPrepopulatedInputs?: () => void;
};

const UpdateTitle = ({ scrollToPrepopulatedInputs }: UpdateTitleProps) => {
  return (
    <div className="edit-employee-title">
      Edit: Employee
      <PrepopulateButton scrollToPrepopulatedInputs={scrollToPrepopulatedInputs} />
    </div>
  );
};

export default UpdateTitle;
