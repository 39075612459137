import React, { useState, useEffect } from 'react';
import useContactService from 'crm/contact/shared/contactService';
import { Dropdown } from 'shared/uibuilder/form/input';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

export const PAGE_SIZE = 30;

interface AccountContactsInputProps extends DefaultDropdownProps {
  accountId?: Nullable<string>;
}

const AccountContactsInput = ({ accountId = null, ...props }: AccountContactsInputProps) => {
  const [contacts, setContacts] = useState([]);
  const { search } = useContactService();

  useEffect(() => {
    const loadContacts = async () => {
      const { result } = await search({
        filter: {
          'account.alias': { eq: accountId },
        },
        pageSize: PAGE_SIZE,
      });

      const mappedContacts = result.map((contact: { firstName: any; lastName: any; id: any }) => ({
        label: `${contact.firstName} ${contact.lastName}`,
        value: contact.id,
      }));

      setContacts(mappedContacts);
    };

    if (accountId) {
      loadContacts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  return <Dropdown options={contacts} {...props} />;
};

export default AccountContactsInput;
