/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import usePositionService from 'erp/position/positionService';
import TextField from 'shared/uibuilder/field/TextField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';

const EmployeePositionField = (props: FieldProps) => {
  const { findPositionById } = usePositionService();
  const { getValue } = useFieldHelper(props);
  const [displayedValue, setDisplayedValue] = useState(null);

  useEffect(() => {
    (async () => {
      const position = await findPositionById(getValue());
      setDisplayedValue(position.name);
    })();
  });

  return <TextField {...props} value={displayedValue} />;
};

export default EmployeePositionField;
