/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import { Route } from 'shared/routing';
import useEntityUrl from 'shared/routing/useEntityUrl';
import useRoute from 'shared/routing/useRoute';
import Page404 from 'shared/uibuilder/Page404';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import CreateEmployeeBackgroundCheck from 'erp/employee/backgroundcheck/createupdate/Create';
import UpdateEmployeeBackgroundCheck from 'erp/employee/backgroundcheck/createupdate/Update';
import EmployeeBackgroundCheckList from 'erp/employee/backgroundcheck/List';
import ViewEmployeeBackgroundCheck from 'erp/employee/backgroundcheck/Show';
import {
  CREATE_BACKGROUND_CHECK,
  READ_BACKGROUND_CHECK,
  READ_BACKGROUND_CHECK_LIST,
  UPDATE_BACKGROUND_CHECK,
} from 'erp/employee/backgroundcheck/shared/employeeBackgroundCheckService';

export const RESOURCE_URL = '/backgroundChecks';

export const useEmployeeBackgroundChecksUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();

  return useEntityUrl({
    baseLink: `${getSingleEntityUrl()}${RESOURCE_URL}`,
  });
};

export const useEmployeeBackgroundChecksRoute = () => {
  return useRoute({
    singleEntityRoute: `/:backgroundCheckId`,
    listRoute: `${RESOURCE_URL}`,
  });
};

export const useEmployeeBackgroundCheckId = () => {
  return useParams<Dictionary<string>>().backgroundCheckId!;
};

const EmployeeBackgroundChecksRouter = () => {
  const { singleEntityRoute, listRoute, updateRoute, createRoute } = useEmployeeBackgroundChecksRoute();

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <ProtectedRoute permissionToCheck={READ_BACKGROUND_CHECK_LIST}>
            <EmployeeBackgroundCheckList />
          </ProtectedRoute>
        }
      />

      <Route
        path={createRoute}
        element={
          <ProtectedRoute path={createRoute} permissionToCheck={CREATE_BACKGROUND_CHECK}>
            <CreateEmployeeBackgroundCheck />
          </ProtectedRoute>
        }
      />

      <Route
        path={updateRoute}
        element={
          <ProtectedRoute path={updateRoute} permissionToCheck={UPDATE_BACKGROUND_CHECK}>
            <UpdateEmployeeBackgroundCheck />
          </ProtectedRoute>
        }
      />

      <Route
        path={singleEntityRoute}
        element={
          <ProtectedRoute path={singleEntityRoute} permissionToCheck={READ_BACKGROUND_CHECK}>
            <ViewEmployeeBackgroundCheck />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default EmployeeBackgroundChecksRouter;
