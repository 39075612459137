import React from 'react';
import OneStopCreatePageLayout from 'oneStop/Create/OneStopCreatePageLayout';
import CustomRequestFormWrapper from 'oneStop/CustomRequestForm/CustomRequestFormWrapper';
import { CustomForm } from 'oneStop/CustomRequestForm/formMap';
import { RequestType } from 'oneStop/oneStopService';

const MOCK_REQUEST = {
  id: 'REC04',
  type: RequestType.TYPE_FORM,
  section: 'REC00',
  title: 'Vacancy Request',
  description: 'Vacancy request for specific position',
  shortDescription: 'Submit to create vacancy request for specific position',
  form: CustomForm.NEW_REQUEST_VACANCY,
  fields: null,
  validationSchema: null,
};

// TODO Delete it
const TemporaryCreateVacancyWrapper = () => {
  return (
    <OneStopCreatePageLayout>
      <CustomRequestFormWrapper request={MOCK_REQUEST} />
    </OneStopCreatePageLayout>
  );
};

export default TemporaryCreateVacancyWrapper;
