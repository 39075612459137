/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import OneStopListPage from 'oneStop/List';
import OneStopCreatePage from 'oneStop/Create';
import OneStopHistoryPage from 'myActivity/history/List';
import { ProtectedRouter } from 'routing';
import { Router, Route, Navigate } from 'shared/routing';
import {
  IsOneStopHistoryEnabled,
  useOneStopRoute,
  ONE_STOP_PATH,
  ONE_STOP_ROOT_PATH,
} from 'oneStop/OneStopRouterHelper';
import { IsMyActivityEnabled, ONE_STOP_HISTORY_PATH, useMyActivityRoute } from 'myActivity/MyActivityRouterHelper';
import OneStopRequestRedirect from 'oneStop/Create/OneStopRequestRedirect';
import OneStopHistoryRedirect from 'myActivity/history/OneStopHistoryRedirect';
import GlobalProtectedRoute from '../shared/authorization/GlobalProtectedRoute';
import TemporaryCreateVacancyWrapper from '../erp/recruitment/newVacancy/createupdate/TemporaryCreateVacancyWrapper';
import useFeatureToggle, { Features } from '../featuretoggle';

export const EMPLOYEE_READ_ONE_STOP = 'EMPLOYEE_READ_ONE_STOP';

const OneStopRouter: Router = () => {
  const { listRoute, singleEntityRoute, oldSingleEntityRoute, oldSingleHistoryRoute } = useOneStopRoute();
  const { historyRoute } = useMyActivityRoute();
  const { isFeatureEnabled } = useFeatureToggle();
  const isNewVacancyEnabled = isFeatureEnabled(Features.CREATE_NEW_VACANCY);

  return (
    <ProtectedRouter basePath={ONE_STOP_PATH}>
      <Routes>
        {IsOneStopHistoryEnabled() && <Route path="" element={<Navigate to={ONE_STOP_PATH} replace />} />}

        {IsMyActivityEnabled() ? (
          <Route path="/history" element={<Navigate to={ONE_STOP_HISTORY_PATH} replace />} />
        ) : (
          <Route path={historyRoute} element={<OneStopHistoryPage />} />
        )}

        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={EMPLOYEE_READ_ONE_STOP}>
              <OneStopListPage />
            </GlobalProtectedRoute>
          }
        />

        {/* TODO: Delete when integrate new Recruiting */}
        {isNewVacancyEnabled ? (
          <Route
            path="/requests/new-vacancy"
            element={
              <GlobalProtectedRoute globalPermissionToCheck={EMPLOYEE_READ_ONE_STOP}>
                <TemporaryCreateVacancyWrapper />
              </GlobalProtectedRoute>
            }
          />
        ) : null}

        <Route
          path={singleEntityRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={EMPLOYEE_READ_ONE_STOP}>
              <OneStopCreatePage />
            </GlobalProtectedRoute>
          }
        />

        <Route path={oldSingleEntityRoute} element={<OneStopRequestRedirect />} />

        {IsMyActivityEnabled() && <Route path={oldSingleHistoryRoute} element={<OneStopHistoryRedirect />} />}

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

OneStopRouter.getRouterPath = () => `${ONE_STOP_ROOT_PATH}/*`;

export default OneStopRouter;
