// eslint-disable-next-line no-restricted-syntax
import * as Sentry from '@sentry/react';

export default function initSentry() {
  const dsn = process.env.REACT_APP_DAVINCI_FRONTEND_SENTRY_DSN;
  const environment = process.env.REACT_APP_DAVINCI_FRONTEND_SENTRY_ENVIRONMENT;

  if (dsn) {
    Sentry.init({
      dsn,
      environment,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.5,
      integrations: [Sentry.replayIntegration()],
    });
  }
}
