/* istanbul ignore file */
import React from 'react';
import ResourcePoolDropdown from 'erp/employee/shared/input/ResourcePoolDropdown';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

interface ResourcePoolFilterProps extends DefaultDropdownProps {
  value?: string;
}

const ResourcePoolFilter = ({ ...props }: ResourcePoolFilterProps) => {
  return (
    <DropDownFilter {...props}>
      <ResourcePoolDropdown {...props} />
    </DropDownFilter>
  );
};

export default ResourcePoolFilter;
