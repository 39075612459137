/* istanbul ignore file */
import React from 'react';
import { Button } from 'uibuilder/button';
import FormSection from 'shared/layout/form/FormSection';
import Message from 'shared/message/Message';
import { func } from 'prop-types';
import './OneClickPasswordForm.scss';
import RedirectButton from '../RedirectButton';

const OneClickPasswordForm = ({ setPassword }: { setPassword: (passwordParam: string | null) => void }) => {
  let formPasswordValue: string;
  const submit = () => {
    setPassword(formPasswordValue);
  };

  const handleChange = (event: { target: { value: string } }) => {
    formPasswordValue = event.target.value;
  };
  const onEnterKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      submit();
    }
  };

  return (
    <div className="one-click-password-form">
      <FormSection
        titleStyle={{
          display: 'flex',
          justifyContent: 'center',
        }}
        title="Welcome!"
        subtitle="Enter the One Click password"
      >
        <Message />

        <input
          className="form-control"
          type="password"
          onChange={handleChange}
          onKeyPress={onEnterKeyPress}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
        <Button color="primary" type="button" onClick={submit} className="one-click-password-form--button">
          Submit
        </Button>
      </FormSection>
      <RedirectButton />
    </div>
  );
};

OneClickPasswordForm.propTypes = {
  setPassword: func.isRequired,
};

export default OneClickPasswordForm;
