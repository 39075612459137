import React from 'react';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import Collapsible from 'shared/uibuilder/field/Collapsible';
import DateField from 'shared/uibuilder/field/DateField';
import { CommaListField } from 'shared/uibuilder/field';
import { BUDGET_OBJECTIVE_UNIT_OPTIONS } from '../budgetVersionService';

const ServiceBudgetStructureRecordLayout = ({ ...props }: any) => {
  const formatObjective = (objective: any) => {
    return `${objective.name} (${BUDGET_OBJECTIVE_UNIT_OPTIONS[objective.unit]})`;
  };

  return (
    <BaseTimelineRecordLayout
      timelineType={TimelineType.NOTE}
      buttons={[]}
      {...props}
      fields={
        <Collapsible>
          <>
            <DateField label="Effective Date" source="effectiveDate" dateFormat={DATE_FORMAT.API_ONLY_MONTH} />
            <CommaListField label="Categories" source="categories" />
            <CommaListField label="Objectives" source="objectives" mapValue={formatObjective} />
          </>
        </Collapsible>
      }
    />
  );
};

export default ServiceBudgetStructureRecordLayout;
