/* istanbul ignore file */
import React from 'react';
import { CreateBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import ServiceCatalogBreadcrumbs from 'erp/serviceCatalog/shared/ServiceCatalogBreadcrumbs';

const CreateServiceCatalogBreadcrumbs = () => {
  return (
    <ServiceCatalogBreadcrumbs>
      <CreateBreadcrumbItem entity="Service" />
    </ServiceCatalogBreadcrumbs>
  );
};

export default CreateServiceCatalogBreadcrumbs;
