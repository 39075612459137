import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import EmployeeContractTypeDropdown from 'erp/employee/contracts/shared/input/EmployeeContractTypeDropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const ContractTypeFilter = (props: DefaultDropdownProps) => {
  return (
    <DropDownFilter {...props}>
      <EmployeeContractTypeDropdown {...props} />
    </DropDownFilter>
  );
};

export default ContractTypeFilter;
