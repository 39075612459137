/* istanbul ignore file */
import React from 'react';
import DropDownFilter, { DropDownFilterProps } from 'shared/uibuilder/list/filter/DropDownFilter';
import { LEAD_GRADE } from 'crm/lead/shared/leadService';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { NULL_AS_STRING } from 'shared/uibuilder/helper';

const LeadGradeFilter = (props: Omit<DropDownFilterProps, 'children'>) => {
  const emptyGradeEntry = { value: NULL_AS_STRING, label: 'Without Grade' };
  const baseEntries = Object.entries(LEAD_GRADE).map(entry => {
    const [value, gradeObject] = entry;
    const { name, shorthand } = gradeObject;

    return {
      value,
      label: `${shorthand} (${name})`,
    };
  });
  const resultOptions = [emptyGradeEntry, ...baseEntries];

  return (
    <DropDownFilter {...props}>
      <Dropdown placeholder="Choose Lead Grade" options={resultOptions} label="Lead Grade" {...props} />
    </DropDownFilter>
  );
};

export default LeadGradeFilter;
