/* istanbul ignore file */
import React from 'react';

const SendEmailDataFieldLayout = ({ value, label }: { value?: string; label?: string }) => {
  return value ? (
    <p className="mb-1">
      {!!label && <b>{label}: </b>}
      {value}
    </p>
  ) : (
    <></>
  );
};

export default SendEmailDataFieldLayout;
