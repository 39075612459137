/* istanbul ignore file */
import OneStopLeave from 'erp/leave/createupdate/OneStop';
import OneStopCareerDevelopmentReview from 'erp/careerdevelopment/createupdate/OneStop';
import { CustomRequestFormType } from './index';
import { FormRowProps } from '../Create/formConfigurationMapper';
import { groupFormRowMapper } from './formMapHelpers';
import OneStopBudgetReallocation from 'erp/budgetreallocation/createupdate/OneStop';
import OneStopHiringForm from 'erp/recruitment/hiring/createupdate/OneStop';
import OneStopCreateVacancy from 'erp/recruitment/newVacancy/createupdate/create/OneStopCreateVacancy';

export enum CustomForm {
  CREATE_LEAVE = 'CREATE_LEAVE',
  CREATE_HIRING_REQUEST_VACANCY = 'CREATE_HIRING_REQUEST_VACANCY',
  NEW_REQUEST_VACANCY = 'NEW_REQUEST_VACANCY',
  CREATE_CAREER_DEVELOPMENT_REVIEW = 'CREATE_CAREER_DEVELOPMENT_REVIEW',
  PURCHASE_INVENTORY = 'IM01',
  CREATE_BUDGET_REALLOCATION = 'CREATE_BUDGET_REALLOCATION',
}

export const formMap: Dictionary<CustomRequestFormType> = {
  [CustomForm.CREATE_LEAVE]: OneStopLeave,
  [CustomForm.CREATE_HIRING_REQUEST_VACANCY]: OneStopHiringForm,
  [CustomForm.NEW_REQUEST_VACANCY]: OneStopCreateVacancy,
  [CustomForm.CREATE_CAREER_DEVELOPMENT_REVIEW]: OneStopCareerDevelopmentReview,
  [CustomForm.CREATE_BUDGET_REALLOCATION]: OneStopBudgetReallocation,
};

const PURCHASE_INVENTORY_GROUP_LENGTH = 7;
const PURCHASE_INVENTORY_SKIPPED_FIELDS_LENGTH = 2;

export const formRowMap: Dictionary<(row: FormRowProps) => FormRowProps[]> = {
  [CustomForm.PURCHASE_INVENTORY]: row =>
    groupFormRowMapper(row, PURCHASE_INVENTORY_GROUP_LENGTH, PURCHASE_INVENTORY_SKIPPED_FIELDS_LENGTH),
};
