/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useEducationUrl } from 'erp/employee/Education/EducationRouter';

const EditEducationButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useEducationUrl();
  return <EditButton path={getUpdateEntityUrl} {...props} />;
};

export default EditEducationButton;
