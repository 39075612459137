/* istanbul ignore file */
import oneStopLeaveValidation from 'erp/leave/createupdate/oneStopLeaveValidation';
import leaveValidation from 'erp/leave/createupdate/leaveValidation';
import leaveFilterValidation from 'erp/leave/List/filter/leaveFilterValidation';
import { useBusinessEngineApi, useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import ShowData from 'shared/uibuilder/show/ShowData';
import useAuthorization from 'shared/authorization/authorizationService';
import useExpenseService, {
  CREATE_EXPENSE,
  FIND_EMPLOYEE_EXPENSES_BY_LEAVE_IDS,
} from 'financialAnalytic/expenses/useExpenseService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

export const LEAVE_PATH = '/leaves';

export const LEAVE_TYPES_ALIASES = {
  PAID_LEAVE: 'PAID_LEAVE',
  UNPAID_LEAVE: 'UNPAID_LEAVE',
  DAY_ON_DEMAND: 'DAY_ON_DEMAND',
  SICK_DAY: 'SICK_DAY',
  SICK_LEAVE: 'SICK_LEAVE',
  COMPENSATION: 'COMPENSATION',
  MILITARY_LEAVE: 'MILITARY_LEAVE',
};

export const LEAVE_TYPES = {
  PAID_LEAVE: 'Paid Leave',
  UNPAID_LEAVE: 'Unpaid Leave',
  DAY_ON_DEMAND: 'Day On Demand',
  SICK_DAY: 'Sick Day',
  SICK_LEAVE: 'Sick Leave',
  MILITARY_LEAVE: 'Military leave',
  COMPENSATION: 'Compensation',
};

export const SICK_DAY_REASONS = {
  I_AM_ILL: 'I am ill',
  PLANNED_MEDICAL_PROCEDURE: 'I will have planned medical procedure',
  CARE_FOR_RELATIVES: 'I need to care for a relative',
};

export const LEAVE_PAYOUT_OPTIONS = {
  WITH_THE_SALARY: 'With the salary',
  BEFORE_PAID_LEAVE: 'Before paid leave',
};

export const LEAVE_STATUSES_ALIASES = {
  IN_APPROVAL: 'IN_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REGISTERED: 'REGISTERED',
};

export const LEAVE_STATUSES = {
  IN_APPROVAL: 'In Approval',
  APPROVED: 'Approved, to be registered',
  REGISTERED: 'Registered',
  REJECTED: 'Rejected',
};

export const LEAVE_RULES_VIOLATION_REASON = {
  PERSONAL_MEDICAL_REASONS: 'Personal medical reasons',
  FAMILY_EMERGENCY: 'Family emergency',
  EDUCATION_OPPORTUNITY: 'Extraordinary educational opportunities',
  TRAVEL_OPPORTUNITY: 'Extraordinary travel opportunities',
  RELIGIOUS_REASONS: 'Religious reasons',
  MILITARY_DUTY: 'Military duty',
  COMPANY_ASKED_FOR_VACATION: 'Company asked for vacation',
};

export const IS_PHYSICAL_OPTION = {
  PHYSICAL: 'Yes',
  ELECTRONIC: 'No',
};

export const IS_PHYSICAL_OPTION_ALIASES = {
  PHYSICAL: 'PHYSICAL',
  ELECTRONIC: 'ELECTRONIC',
};

export const READ_LEAVES_LIST = 'READ_LEAVES_LIST';
export const CREATE_LEAVE = 'CREATE_LEAVE';
export const REQUEST_LEAVE = 'REQUEST_LEAVE';
export const UPDATE_LEAVE = 'UPDATE';
export const EXTEND_LEAVE = 'EXTEND_LEAVE';
export const REJECT_LEAVE = 'REJECT_LEAVE';
export const READ_LEAVE_REQUEST_INFORMATION = 'READ_REQUEST_INFORMATION';
export const READ_FULL_LEAVE_DATA = 'READ_FULL_LEAVE_DATA';
export const DISPLAY_UPDATE_BUTTON = 'FE_EDIT_LEAVES_LIST';
export const UPDATE_LEAVE_STATUS = 'UPDATE';
export const DISPLAY_EXTEND_BUTTON = 'FE_EXTEND_LEAVE';
export const NON_STRICT_RULES = [
  'LEAVE-BR-6',
  'LEAVE-BR-7-BY',
  'LEAVE-BR-7-PL',
  'LEAVE-BR-8-BY',
  'LEAVE-BR-8-PL',
  'LEAVE-BR-9',
  'LEAVE-BR-10',
  'LEAVE-BR-11',
  'LEAVE-BR-14',
  'LEAVE-BR-15-1',
  'LEAVE-BR-15-2',
];

export const hasSignatureOption = (leave: FormFieldsData) => {
  const typesWithSignatureOption = [
    LEAVE_TYPES_ALIASES.PAID_LEAVE,
    LEAVE_TYPES_ALIASES.COMPENSATION,
    LEAVE_TYPES_ALIASES.UNPAID_LEAVE,
    LEAVE_TYPES_ALIASES.SICK_DAY,
  ];
  if (leave instanceof ShowData) {
    return typesWithSignatureOption.includes(leave.getValueBySource('type'));
  } else {
    return typesWithSignatureOption.includes(leave.type);
  }
};

/**
 * Contact service.
 *
 * @returns {{getById: (function(*=): *), deleteById: ((function(*=): *)|Promise<any>), create: (function(*=): *), update: (function(*=, *=): *), getPermissions: (function(*=): *)}}
 */

const useLeaveService = () => {
  const { checkLeaveCompensations } = useExpenseService();
  const { isGranted } = useAuthorization();
  const singleResourceUrl = '/leaves/:id';
  const {
    update: baseUpdate,
    invalidateCache,
    ...baseCrudRequests
  } = useCrudService({
    singleResourceUrl,
    listResourceUrl: '/leaves',
    apiService: useKernelApi,
  });

  const { sendPutRequest, sendPostRequest: sendKernelPostRequest } = useKernelApi();
  const { sendPostRequest } = useBusinessEngineApi();

  const getValidationSchema = () => Promise.resolve(leaveValidation);

  const getFiltersValidationSchema = () => Promise.resolve(leaveFilterValidation);

  const getOneStopValidationSchema = () => Promise.resolve(oneStopLeaveValidation);

  const createEmployeeLeave = async (leave: any) => {
    const response = await sendPostRequest('/me/leaves', leave);
    return response.json();
  };

  const canBeExtended = (leave: any) => {
    return !!leave.getValueBySource('allowedExtensionDate');
  };

  const update = async (id: StringOrNumber, leave: FormFieldsData) => {
    if (leave.extendedEndDate || leave.isExtensionCanceled) {
      const url = `${singleResourceUrl.replace(':id', String(id))}/extend`;
      const response = await sendPutRequest(url, leave);
      const result = await response.json();

      if (invalidateCache) {
        invalidateCache();
      }

      return result;
    } else {
      return baseUpdate(id, leave);
    }
  };

  const rejectById = async (id: string) => {
    const url = `${singleResourceUrl.replace(':id', id)}/reject`;
    await sendPostRequest(url);
  };

  const getDuration = async (type: any, startDate: any, endDate: any, employeeId: any) => {
    const request = { type, startDate, endDate, employeeId };
    const response = await sendKernelPostRequest('/leaves/duration', request);
    return response.json();
  };

  const searchWithExpensesInfo = async (params: any) => {
    if (!isGranted(FIND_EMPLOYEE_EXPENSES_BY_LEAVE_IDS) || !isGranted(CREATE_EXPENSE)) {
      return baseCrudRequests.search(params);
    }

    const result = await baseCrudRequests.search(params);

    try {
      const expensesResult = await checkLeaveCompensations(result.result.map(({ id }: any) => id));

      return {
        ...result,
        result: result.result.map((item: { id: any }) => ({
          ...item,
          hasCompensation: !!expensesResult.find(({ leaveId }: any) => String(leaveId) === String(item.id))
            ?.compensationExists,
        })),
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return result;
  };

  const searchAllWithExpensesInfo = async (params: any) => {
    let totalPages = 0;
    let currentPage = 0;
    let result: any = [];

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await searchWithExpensesInfo({
        ...params,
        pageNumber: currentPage,
      });
      // eslint-disable-next-line no-await-in-loop

      currentPage += 1;
      totalPages = response.totalPages;
      result = [...result, ...(response.result?.filter((item: any) => !item.hasCompensation) || [])];
    } while (currentPage < totalPages);

    return result;
  };

  const getByIdWithExpensesInfo = async (id: string) => {
    if (!isGranted(FIND_EMPLOYEE_EXPENSES_BY_LEAVE_IDS) || !isGranted(CREATE_EXPENSE)) {
      return baseCrudRequests.getById(id);
    }

    const result = await baseCrudRequests.getById(id);
    try {
      const expensesResult = await checkLeaveCompensations([id]);

      return {
        ...result,
        hasCompensation: !!expensesResult[0].compensationExists,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return result;
  };

  return {
    getValidationSchema,
    getFiltersValidationSchema,
    getOneStopValidationSchema,
    createEmployeeLeave,
    canBeExtended,
    ...baseCrudRequests,
    update,
    getDuration,
    rejectById,
    searchWithExpensesInfo,
    getByIdWithExpensesInfo,
    searchAllWithExpensesInfo,
  };
};

export default useLeaveService;
