import React from 'react';
import { TextInput, DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { useDateService } from 'shared/uibuilder/dateService';

interface DurationBetweenTwoDatesInputProps extends DefaultInputPropTypes<string> {
  dateFromSource: string;
  dateToSource: string;
  dateFromValue?: string;
  dateToValue?: string;
}

const DurationBetweenTwoDatesInput = ({
  dateFromSource,
  dateFromValue,
  dateToSource,
  dateToValue,
  ...props
}: DurationBetweenTwoDatesInputProps) => {
  const { getValue: getDateFromValue } = useInputHelper({ source: dateFromSource, value: dateFromValue });
  const { getValue: getDateToValue } = useInputHelper({ source: dateToSource, value: dateToValue });
  const { getStringDurationBetweenTwoDates } = useDateService();

  const dateFrom = getDateFromValue();
  const dateTo = getDateToValue();

  const resultValue = getStringDurationBetweenTwoDates(dateFrom, dateTo);

  return <TextInput {...props} disabled value={resultValue} />;
};

export default DurationBetweenTwoDatesInput;
