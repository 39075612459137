/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import useAccountService from 'crm/account/accountService';
import UpdateAccountBreadcrumbs from 'crm/account/createupdate/Update/Breadcrumbs';
import AccountForm from 'crm/account/createupdate/AccountForm';
import { useAccountId } from 'crm/account/AccountRouter';
import { useAccountUrl } from 'crm/account/index';

const UpdateAccount = () => {
  const { update, getById, getValidationSchema, getOriginalName } = useAccountService();
  const { getSingleEntityUrl } = useAccountUrl();
  const id = useAccountId();

  const getDataMethod = async (accountId: StringOrNumber) => {
    const data = await getById(accountId);
    const originalName = await getOriginalName(accountId);

    return {
      ...data,
      accountName: data.hasCodeName ? data.accountName : '',
      originalName: originalName[accountId].realAccountName,
    };
  };

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getDataMethod}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getSingleEntityUrl(id),
        message: 'You are awesome! The Account has been successfully saved.',
      }}
    >
      <UpdatePageLayout title="Edit: Account" breadcrumbs={<UpdateAccountBreadcrumbs />}>
        <AccountForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default UpdateAccount;
