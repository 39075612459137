/* istanbul ignore file */
import React from 'react';
import './EmployeeFamilyMembersLayout.scss';
import { FieldProps } from 'shared/uibuilder/field';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import getLabel from 'shared/uibuilder/helper';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import moment from 'moment';

const PreviousWorkingPeriodField = ({ from, to }: { from: any; to: any }) => {
  const { getStringDurationBetweenTwoDates } = useDateService();
  const periodDiff = getStringDurationBetweenTwoDates(from, to);

  const fromDate = moment(from).format('DD.MM.YYYY');
  const toDate = moment(to).format('DD.MM.YYYY');

  return (
    <div key={`${from}-${to}`}>
      {periodDiff} from {fromDate} to {toDate};
    </div>
  );
};

const CurrentWorkingPeriodField = ({ value }: { value: any }) => {
  const { getStringDurationBetweenTwoDates } = useDateService();
  const { startDate } = value;
  const firstWorkingDay = moment(startDate).format('DD.MM.YYYY');
  const diff = getStringDurationBetweenTwoDates(startDate, moment());

  return (
    <div>
      <div>{`First working day: ${firstWorkingDay}`}</div>
      <div>{`Employment duration: ${diff}`}</div>
    </div>
  );
};

const EmploymentHistoryFieldLayout = ({ label, source, current, previous }: FieldProps) => {
  const { BaseFieldLayout } = useUiTheme();

  const getPreviousPeriods = (previousPeriods: any[]) => {
    return previousPeriods.map(period => {
      const { startDate: previousStartDate, endDate: previousEndDate } = period;
      return <PreviousWorkingPeriodField key={previousStartDate} from={previousStartDate} to={previousEndDate} />;
    });
  };

  const getFieldValue = () => {
    return current || previous ? (
      <>
        {current && <CurrentWorkingPeriodField value={current} />}
        {previous && (
          <div>
            <p>Previous employment at Syberry: </p>
            {getPreviousPeriods(previous)}
          </div>
        )}
      </>
    ) : null;
  };

  return <BaseFieldLayout label={getLabel(label, source as string)} value={getFieldValue()} isColumnDirection />;
};

export default EmploymentHistoryFieldLayout;
