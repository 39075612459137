/* istanbul ignore file */
import React from 'react';
import { REQUEST_STATUS, REQUEST_STATUS_ALIAS } from 'crm/opportunity/presale/request/shared/presaleRequestService';
import Badge from 'shared/uibuilder/badge/Badge';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const PresaleStatusBadge = ({ statusSource }: { statusSource: string }) => {
  const { data } = useShowContext();

  const status = data.getValueBySource(statusSource);

  const getColor = (statusData: string): BasicColors => {
    let color;
    if (statusData === REQUEST_STATUS.SUBMITTED) {
      color = 'info';
    } else if (statusData === REQUEST_STATUS.COMPLETED) {
      color = 'success';
    } else {
      color = 'neutral';
    }
    return color as BasicColors;
  };

  const getDisplayName = (statusData: string) => {
    return REQUEST_STATUS_ALIAS[statusData];
  };

  return <Badge color={getColor(status)}>{getDisplayName(status)}</Badge>;
};

export default PresaleStatusBadge;
