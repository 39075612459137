import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import { useProjectUrl } from '../ProjectRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { READ_SOW, WRITE_SOW } from './useSowService';
import ViewSowProject from './show/ViewSowProject';
import ShowProject from '../statuses/shared/ShowProject';
import EditSow from './createupdate/update/EditSow';
import EmptySowPage from './show/empty/EmptySowPage';
import CreateSow from './createupdate/create/CreateSow';
import ProjectContext from '../ProjectContext';

export const PATH = '/sow';

export const useSowRoute = () => {
  const routs = useRoute({
    listRoute: `${PATH}`,
    singleEntityRoute: ``,
  });

  return {
    ...routs,
    createRoute: '/add',
    updateRoute: '/edit',
    emptyEntityRoute: `/empty`,
  };
};

export const useSowUrl = () => {
  const { getSingleEntityUrl } = useProjectUrl();
  const projectLink = getSingleEntityUrl();
  const listUrl = `${projectLink}${PATH}`;
  const baseUrls = useEntityUrl({
    baseLink: `${projectLink}${PATH}`,
  });

  const getListUrl = () => listUrl;
  const getUpdateEntityUrl = () => `${listUrl}/edit`;
  const getEmptyEntityUrl = () => `${listUrl}/empty`;

  return {
    ...baseUrls,
    getListUrl,
    getUpdateEntityUrl,
    getEmptyEntityUrl,
  };
};

const SowRouter = () => {
  const { listRoute, updateRoute, emptyEntityRoute, createRoute } = useSowRoute();

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <ProjectContext permissionToCheck={READ_SOW}>
            <ViewSowProject />
          </ProjectContext>
        }
      />

      <Route
        path={createRoute}
        element={
          <ProjectContext permissionToCheck={WRITE_SOW}>
            <ShowProject>
              <CreateSow />
            </ShowProject>
          </ProjectContext>
        }
      />

      <Route
        path={updateRoute}
        element={
          <ProjectContext permissionToCheck={WRITE_SOW}>
            <ShowProject>
              <EditSow />
            </ShowProject>
          </ProjectContext>
        }
      />

      <Route
        path={emptyEntityRoute}
        element={
          <ProjectContext permissionToCheck={READ_SOW}>
            <ShowProject>
              <EmptySowPage />
            </ShowProject>
          </ProjectContext>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default SowRouter;
