import React, { useState } from 'react';
import VacancyGroupSectionTitle from '../shared/VacancyGroupSectionTitle';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import TextField from 'shared/uibuilder/field/TextField';
import { CommaListField } from 'shared/uibuilder/field';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import DateField from 'shared/uibuilder/field/DateField';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import EmployeesInput from 'financialAnalytic/corporateEvents/shared/input/EmployeesInput';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import useVacancyGroupService from '../../useVacancyGroupService';
import { CloseEditGroupButton, EditGroupButton, SaveGroupButton } from './shared/buttons/VacancyGroupButton';

import './VacancyGroupContent.scss';

const VacancyGroupContent = () => {
  const [isEdit, setEdit] = useState(false);
  const { updateVacancyGroup } = useVacancyGroupService();
  const { getEmployeeNamesByAliases } = useEmployeeNamesService();
  const { data: show, setData = () => {} } = useShowContext();
  const showData = show.getData();

  const toggleEdit = () => {
    setEdit(prev => !prev);
  };

  const getDataForUpdate = async () => {
    return {
      recruiters: await getEmployeeNamesByAliases(showData.recruiters),
      sourcers: await getEmployeeNamesByAliases(showData.sourcers),
    };
  };

  return (
    <UpdateForm
      submitFormFunc={updateVacancyGroup}
      getDataFunc={getDataForUpdate}
      afterSubmit={{
        message: 'You are awesome! The Vacancy Group has been successfully updated.',
        execute: (_id: StringOrNumber, response: any) => {
          setData({ ...showData, recruiters: response.recruiters, sourcers: response.sourcers });
          toggleEdit();
        },
      }}
    >
      <ShowSection
        justifyContent="space-between"
        actions={[
          !isEdit ? (
            <EditGroupButton onClickButton={toggleEdit} />
          ) : (
            <>
              <SaveGroupButton />
              <CloseEditGroupButton toggleEdit={toggleEdit} getDataForUpdate={getDataForUpdate} />
            </>
          ),
        ]}
        titleId="vacancy-group-title"
        title={data => <VacancyGroupSectionTitle data={data?.getData()} />}
      >
        <SectionRow spacing={{}}>
          <TextField source="details.englishLevel" label="English Level" />
          <CommaListField label="Hiring Mode" source="details.hiringModes" />
          {isEdit ? (
            <EmployeesInput
              className="inputs-in-view"
              source="recruiters"
              label="Recruiters"
              customMapper={(item: any) => ({
                value: item.alias,
                label: `${item.nameEn?.firstName ?? ''} ${item.nameEn?.lastName ?? ''}`,
              })}
              defaultFilter={{
                'specification:isRecruiter': {
                  eq: true,
                },
              }}
            />
          ) : (
            <CommaListField
              label="Recruiters"
              source="recruiters"
              isNeedJoin={false}
              mapValue={value => {
                return <EmployeeLinkFieldWithNameLoading label="" externalId={value} />;
              }}
            />
          )}
        </SectionRow>

        <SectionRow spacing={{}}>
          <TextField source="details.compensation" label="Compensation" />
          <DateField
            source="details.nearestTargetDate"
            label="Nearest Target Date"
            dateFormat={DATE_FORMAT.FULL_DATE}
          />
          {isEdit ? (
            <EmployeesInput
              className="inputs-in-view"
              source="sourcers"
              label="Sourcers"
              customMapper={(item: any) => ({
                value: item.alias,
                label: `${item.nameEn?.firstName ?? ''} ${item.nameEn?.lastName ?? ''}`,
              })}
            />
          ) : (
            <CommaListField
              label="Sourcers"
              source="sourcers"
              isNeedJoin={false}
              mapValue={value => {
                return <EmployeeLinkFieldWithNameLoading label="" externalId={value} />;
              }}
            />
          )}
        </SectionRow>

        <SectionRow spacing={{}}>
          <TextField source="details.openPositionsCount" label="Open Positions" />
          <CommaListField label="Projects" source="details.projectNames" />
          <CommaListField
            label="Coordinators"
            source="details.coordinators"
            isNeedJoin={false}
            mapValue={value => {
              return <EmployeeLinkFieldWithNameLoading label="" externalId={value} />;
            }}
          />
        </SectionRow>
      </ShowSection>
    </UpdateForm>
  );
};

export default VacancyGroupContent;
