/* istanbul ignore file */
import React from 'react';
import useCrmNoteItem from 'crm/shared/timeline/note/crmNoteRegistry';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import Show from 'shared/uibuilder/show/Show';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import { useContactId } from 'crm/contact/ContactRouter';
import ContactEntityHeader from 'crm/contact/shared/ContactEntityHeader';
import useContactService from 'crm/contact/shared/contactService';
import ContactsMenu from 'crm/contact/shared/ContactsMenu';
import ContactTimelineBreadcrumbs from 'crm/contact/Timeline/Breadcrumbs';

const ContactTimelinePage = () => {
  const contactId = useContactId();
  const { getById, searchTimelineItems, createTimelineItem, getTimelinePageById, getFilterValidationSchema } =
    useContactService();

  const noteRegistry = useCrmNoteItem();

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout>
        <DateTimeRangeFilter label="Created date" source="createdAt" />
      </FiltersLayout>
    </Filters>
  );
  return (
    <Show getDataMethod={() => getById(contactId)}>
      <Timeline
        registries={[noteRegistry] as any}
        getDataMethod={request => searchTimelineItems(contactId, request)}
        createTimelineItemMethod={(itemType, request) => createTimelineItem(contactId, itemType, request) as any}
        getPageByEntryIdMethod={id => getTimelinePageById(contactId, id)}
      >
        <TimelinePageLayout
          menu={<ContactsMenu />}
          breadcrumbs={<ContactTimelineBreadcrumbs />}
          entityHeader={<ContactEntityHeader />}
          filter={filters}
        >
          <TimelineLayout noEntriesMessage="No entries in this contact timeline yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default ContactTimelinePage;
