/* istanbul ignore file */
import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import { DateInput, TextArea, TextInput, Checkbox } from 'shared/uibuilder/form/input';
import InvoiceTotalInput from 'financialAnalytic/invoice/shared/input/InvoiceTotalInput';
import FormRow from 'shared/layout/form/FormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import NoteAttachmentUploaderInput from 'artifact/shared/input/NoteAttachmentUploaderInput';
import OfficeDropdown from 'financialAnalytic/shared/input/OfficeDropdown';
import AccountInput from 'financialAnalytic/ledger/shared/input/AccountInput';
import useLedgerService from 'financialAnalytic/ledger/useLedgerService';
import CurrencyDropdownWithoutEur from 'financialAnalytic/shared/input/CurrencyDropdownWithoutEur';
import PurchaseOrderDropdown from 'financialAnalytic/purchaseOrder/shared/input/PurchaseOrderDropdown';
import InvoiceClassificationDropdown from 'financialAnalytic/invoice/shared/input/InvoiceClassificationDropdown';
import ClearAfterUnmountWrapper from '../../../shared/uibuilder/form/ClearAfterUnmountWrapper';
import useInputHelper from '../../../shared/uibuilder/form/input/inputHelper';
import InvoiceReferenceListInput from '../reference/InvoiceReferenceListInput';
import InvoiceReferenceInput from '../reference/InvoiceReferenceInput';
import BudgetCategoryDropdown from 'financialAnalytic/journalEntry/shared/input/BudgetCategoryDropdown';
import { isBudgetCategoryRequired } from 'erp/serviceCatalog/budgets/shared/service/budgetCatagorySuggestionService';

const InvoiceForm = () => {
  const { searchInvoiceAccount } = useLedgerService();
  const { getValue: getOffice } = useInputHelper<string>({ source: 'office' });
  const office = getOffice();
  const { getValue: getAccount } = useInputHelper<string>({ source: 'account' });
  const account = getAccount();
  const { getValue: getInvoiceDate } = useInputHelper<string>({ source: 'invoiceDate' });
  const invoiceDate = getInvoiceDate();

  return (
    <>
      <FormSection title="Main information">
        <FormRow>
          <Checkbox
            tooltip="Select this option to upload payment document and indicate the Invoce number later, when vendor provides it."
            source="isInvoiceDocumentWillArriveLater"
            label="Will the invoice arrive later?"
          />
          <Checkbox
            tooltip="Select this option if goods/services well be provided in a month other than the invoice date."
            source="isFutureExpense"
            label="Is future expense?"
          />
        </FormRow>
        <FormRow>
          <TextInput source="invoiceNumber" label="Invoice Number" />
          <DateInput label="Date" source="invoiceDate" />
          <OfficeDropdown source="office" />
        </FormRow>
        <FormRow>
          <TextInput source="name" label="Vendor Name" />
          <InvoiceTotalInput source="subTotal.amount" currencySource="subTotal.currency" label="Total Amount" />
          <CurrencyDropdownWithoutEur source="subTotal.currency" label="Total Currency" />
          <ClearAfterUnmountWrapper isVisible={data => !data.purchaseOrderId}>
            <AccountInput
              source="account"
              label="Account"
              cacheKey="invoice-accounts"
              loadDataMethod={searchInvoiceAccount}
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper
            isVisible={(data: any) => data.invoiceDate && data.account && !data.purchaseOrderId}
          >
            <BudgetCategoryDropdown
              disabled={!isBudgetCategoryRequired(account)}
              source="budgetCategory"
              label="Budget Category"
              dateValue={invoiceDate}
              accountIdValue={account}
            />
          </ClearAfterUnmountWrapper>
          <PurchaseOrderDropdown
            disabled={!office}
            officeData={office}
            source="purchaseOrderId"
            label="Purchase Order"
          />
          <InvoiceClassificationDropdown source="isPurchaseClassifiedAsAsset" label="Purchase Сlassification" />
        </FormRow>
        <BigFormRow>
          <TextArea source="description" />
        </BigFormRow>
        <BigFormRow>
          <NoteAttachmentUploaderInput label="Payment Document" source="invoiceDocumentId" />
        </BigFormRow>
      </FormSection>
      <FormSection title="Invoice References">
        <BigFormRow>
          <InvoiceReferenceListInput
            source="references"
            inputTemplate={InvoiceReferenceInput}
            addText="Add Reference"
          />
        </BigFormRow>
      </FormSection>

      <FormSection title="Vendor Requisites">
        <FormRow>
          <TextInput source="requisites.bankName" label="Bank Name" />
          <TextInput source="requisites.bankAccount" label="Bank Account" />
          <TextInput source="requisites.recipient" label="Recipient" />
        </FormRow>
      </FormSection>
    </>
  );
};

export default InvoiceForm;
