/* istanbul ignore file */
import { CreateForm } from 'shared/uibuilder/form';
import React from 'react';
import ResumeForm from 'erp/candidate/Timeline/resume/form/ResumeForm';
import useResumeService from 'erp/candidate/Resume/shared/resumeService';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import { func } from 'prop-types';
import { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

interface CreateResumeFormProps extends FormTemplateProps {
  onCancel: () => void;
  submitFormFunc: (values: FormFieldsData) => Promise<object | void>;
}

const CreateResumeForm = ({ submitFormFunc, onCancel, ...props }: CreateResumeFormProps) => {
  const { getValidationSchema } = useResumeService();

  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateTimelineItemLayout onCancel={onCancel}>
        <ResumeForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

CreateResumeForm.propTypes = {
  submitFormFunc: func.isRequired,
  onCancel: func.isRequired,
};

export default CreateResumeForm;
