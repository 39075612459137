/* istanbul ignore file */
import EntityHeader from 'shared/layout/EntityHeader';
import React from 'react';

const AccountEntityHeader = ({ entityTitle = '' }) => {
  const props = entityTitle ? { entityTitle } : { entityTitleSource: 'accountName' };

  return <EntityHeader {...props} />;
};

export default AccountEntityHeader;
