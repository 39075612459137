import FieldBadgeProps from 'shared/uibuilder/badge/FieldBadgeProps';
import Badge from 'shared/uibuilder/badge/Badge';
import React from 'react';

type StatusBadgeProps = {
  text: string;
  color: BasicColors;
};

const StatusBadge = ({ text, color, ...props }: StatusBadgeProps & FieldBadgeProps) => {
  return (
    <Badge className="ml-2" color={color} {...props}>
      {text}
    </Badge>
  );
};

export default StatusBadge;
