import React from 'react';
import { string } from 'prop-types';
import { Checkbox } from 'shared/uibuilder/form/input';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

const OpportunityCheckbox = ({ nameSource, label }: { nameSource: string; label: string }) => {
  const { formErrors, setFormErrors = () => {} } = useFormContext();

  const clearOpportunityValidationErrors = () => {
    const errors = { ...formErrors };

    delete errors.opportunityName;
    delete errors.closeDate;

    setFormErrors(errors);
  };

  return <Checkbox source={nameSource} label={label} clearValidationCallback={clearOpportunityValidationErrors} />;
};

OpportunityCheckbox.propTypes = {
  nameSource: string.isRequired,
  label: string.isRequired,
};

export default OpportunityCheckbox;
