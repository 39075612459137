import React from 'react';
import Box from 'uibuilder/Box';
import Typography from '@mui/material/Typography';
import OfficeAndAccommodationIcons from './OfficeAndAccommodationIcons';
import LabelStatus from './LabelStatus';

interface ShowSectionTitleProps {
  data: any;
}

const VacancyGroupSectionTitle: React.FC<ShowSectionTitleProps> = ({ data = {} }) => {
  const { specialization, details: { registrations = [], accommodations = [], competencyLevel, status } = {} } = data;

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="h4" sx={{ mr: '20px' }}>
        {specialization}
      </Typography>

      <Typography variant="h4" sx={{ mr: '20px' }}>
        {competencyLevel}
      </Typography>

      <OfficeAndAccommodationIcons accommodations={accommodations} registrations={registrations} />

      <LabelStatus status={status} />
    </Box>
  );
};

export default VacancyGroupSectionTitle;
