/* istanbul ignore file */
import React from 'react';
import ShowDate from 'shared/uibuilder/ShowDate';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import TimelineRecordAuthor from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordAuthor';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

interface TimeLineHeaderProps {
  createdAtSource?: string;
  actionName?: string | ((entity: any) => string);
  timelineId?: string;
  entity?: any;
}

const TimelineRecordHeader: React.FC<TimeLineHeaderProps> = ({ actionName, createdAtSource = null }) => {
  const { data: entity } = useShowContext();
  const createdAt = entity.getValueBySource(createdAtSource ?? 'createdAt');
  const createdById = entity.getValueBySource('createdById');
  const createdByName = entity.getValueBySource('createdByName');

  const resultActionName = typeof actionName === 'function' ? actionName(entity.getData()) : actionName;

  return (
    <>
      <div className="timeline__card-header mr-5 pr-3">
        <div className="timeline__employee-action">
          <div className="timeline__card-datetime d-inline-block">
            <ShowDate dateUTC={createdAt} format={DATE_FORMAT.FULL} />
          </div>
          <TimelineRecordAuthor id={createdById} name={createdByName} /> {resultActionName}
        </div>
      </div>
    </>
  );
};

export default TimelineRecordHeader;
