import React from 'react';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';

interface SetVacancyManagerFormProps {
  closeModal: () => void;
  modalOpen: boolean;
}

const SetVacancyManagerForm = ({ closeModal, modalOpen }: SetVacancyManagerFormProps) => {
  const { updateVacancyManager } = useVacancyService();

  const { getValue: getVacancyAlias } = useFieldHelper<string>({ source: 'alias' });

  const submit = async ({ employee }: any) => {
    if (employee) {
      return updateVacancyManager(getVacancyAlias(), employee);
    } else {
      return null;
    }
  };

  return (
    <ModalForm
      closeModal={closeModal}
      modalOpen={modalOpen}
      submitFormFunc={submit}
      afterSubmit={{
        execute: () => {
          window.location.reload(); // reload page for fetch actual state
        },
        message: 'You are awesome! Vacancy Manager was changed successfully.',
      }}
    >
      <EmployeeInput source="employee" />
    </ModalForm>
  );
};

export default SetVacancyManagerForm;
