/* istanbul ignore file */
import React from 'react';
import { Link } from 'react-router-dom';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const CandidateLink = ({
  style,
  color,
  name,
  id,
}: {
  name?: string;
  id: number | string;
  style?: Dictionary<string>;
  color?: string;
}) => {
  const { getSingleEntityUrl } = useCandidateUrl();

  return (
    <Link style={style} color={color} to={getSingleEntityUrl(id)} target="_blank">
      {name || id}
    </Link>
  );
};

export default CandidateLink;
