import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import { useServiceCatalogId } from 'erp/serviceCatalog/ServiceCatalogRouter';
import useServiceCatalogService from 'erp/serviceCatalog/useServiceCatalogService';
import ViewServiceCatalogPageMenu from 'erp/serviceCatalog/Show/ViewServiceCatalogPageMenu';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import useAuthorization from 'shared/authorization/authorizationService';
import useServiceKPIStructureRegistry from '../useServiceKPIStructureRegistry';
import ViewKPIStructureBreadcrumbs from './ViewKPIStructureBreadcrumbs';
import ServiceCatalogHeader from '../../shared/ServiceCatalogHeader';
import useKPIService, { KPI_STATUS, KPI_STRUCTURE_ADD } from '../useKPIService';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import KpiStatusFilter from '../shared/inputs/KPIStatusFilter';

const ViewKPIStructure = () => {
  const id = useServiceCatalogId();
  const { getById } = useServiceCatalogService();
  const { searchStructure } = useKPIService();
  const { isGranted } = useAuthorization();

  const budgetStructureRegistry = useServiceKPIStructureRegistry();

  const filters = (
    <Filters>
      <FiltersLayout>
        <KpiStatusFilter label="Status" source="status" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <Show getDataMethod={() => getById(id)}>
      <Timeline
        registries={[budgetStructureRegistry] as any}
        getDataMethod={async req => searchStructure(id, req)}
        defaultFilter={{
          status: { in: [KPI_STATUS.ACTIVE] },
        }}
      >
        <TimelinePageLayout
          title="KPI Structure"
          hasAddForm={isGranted(KPI_STRUCTURE_ADD)}
          breadcrumbs={<ViewKPIStructureBreadcrumbs />}
          entityHeader={<ServiceCatalogHeader />}
          menu={<ViewServiceCatalogPageMenu />}
          filter={filters}
        >
          <TimelineLayout noEntriesMessage="No entries in this KPI structure yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default ViewKPIStructure;
