/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import SkillBasedQueueBreadcrumbs from 'erp/skillbasedqueue/shared/SkillBasedQueueBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useSkillBasedQueueUrl } from 'erp/skillbasedqueue/SkillBasedQueueRouter';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';

const SingleSkillBasedQueueBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getSingleEntityUrl } = useSkillBasedQueueUrl();
  const { id } = useParams<Dictionary<string>>();

  return (
    <SkillBasedQueueBreadcrumbs>
      <SingleBreadcrumbItem entity={id!} link={getSingleEntityUrl(id)} />
      {children}
    </SkillBasedQueueBreadcrumbs>
  );
};

export default SingleSkillBasedQueueBreadcrumbs;
