import React from 'react';
import { METRICS_CONFIG, DIVIDER } from 'erp/employee/performanceReport/usePerformanceReportService';
import { v4 as uuidv4 } from 'uuid';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';
import Box from 'uibuilder/Box';
import { TooltipSize } from 'uibuilder/Tooltip';

const PerformanceHeatmapLabels = ({ labels }: { labels: Array<string> }) => {
  const getGridTemplateRows = () => {
    const result: string[] = [];
    let length = 0;

    labels.forEach(row => {
      if (row === DIVIDER) {
        result.push(length ? `repeat(${length}, var(--square-size)) var(--divider-size)` : '');
        length = 0;
      } else {
        length += 1;
      }
    });

    if (length) {
      result.push(`repeat(${length}, var(--square-size))`);
    }

    return result.join(' ');
  };

  return (
    <Box
      component="ul"
      className="performance-heatmap__labels"
      sx={{
        gridTemplateRows: getGridTemplateRows(),
      }}
    >
      {labels.map(metric => {
        return metric === DIVIDER ? (
          <li key={uuidv4()} className="divider" />
        ) : (
          <li key={metric}>
            {METRICS_CONFIG[metric].label}
            {METRICS_CONFIG[metric].tooltip ? (
              <InputTooltip source={uuidv4()} message={METRICS_CONFIG[metric].tooltip} tooltipSize={TooltipSize.BIG} />
            ) : null}
          </li>
        );
      })}
    </Box>
  );
};

export default PerformanceHeatmapLabels;
