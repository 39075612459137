/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import ContractsBreadcrumbs from 'erp/contracts/shared/ContractsBreadcrumbs';
import { useContractsUrl } from 'erp/contracts/ContractsRouter';

const SingleContractsBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getSingleEntityUrl } = useContractsUrl();
  const { id } = useParams<any>();

  return (
    <ContractsBreadcrumbs>
      <SingleBreadcrumbItem entity={id!} link={getSingleEntityUrl(id)} />
      {children}
    </ContractsBreadcrumbs>
  );
};

export default SingleContractsBreadcrumbs;
