/* istanbul ignore file */
import React from 'react';
import { LEAVE_TYPES_ALIASES } from 'erp/leave/shared/leaveService';
import { TextField } from 'shared/uibuilder/field';

const PaidLeaveBalanceField = ({ value }: { value?: any }) => {
  let result = '0';
  const { type, leaveBalance } = value;

  if (
    type === LEAVE_TYPES_ALIASES.PAID_LEAVE ||
    type === LEAVE_TYPES_ALIASES.COMPENSATION ||
    type === LEAVE_TYPES_ALIASES.DAY_ON_DEMAND
  ) {
    result = leaveBalance;
  }

  return <TextField value={result} />;
};

export default PaidLeaveBalanceField;
