/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
// layout
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
// buttons
import EditLeaveButton from 'erp/leave/shared/button/EditLeaveButton';
import CreateButton from 'uibuilder/button/CreateButton';
// fields
import { BadgeField, DateField, FullDateField, SearchLinkField, TextField } from 'shared/uibuilder/field';
import LeaveTypeField from 'erp/leave/shared/field/LeaveTypeField';
import LeaveStatusField from 'erp/leave/shared/field/LeaveStatusField';
import { EmployeeFilter, EmployeeLinkField } from 'erp/employee';
// filters
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import LeaveStatusFilter from 'erp/leave/List/filter/LeaveStatusFilter';
import LeaveTypeFilter from 'erp/leave/List/filter/LeaveTypeFilter';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import EmployeePositionFilter from 'erp/employee/List/filter/EmployeePositionFilter';

import useLeaveService, {
  CREATE_LEAVE,
  DISPLAY_EXTEND_BUTTON,
  DISPLAY_UPDATE_BUTTON,
  REQUEST_LEAVE,
} from 'erp/leave/shared/leaveService';
import { useLeaveUrl } from 'erp/leave/LeaveRouter';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import useAuthorization from 'shared/authorization/authorizationService';
import { ExcludedFromBalanceBadge } from 'erp/leave/index';
import useListParamsMigration from 'shared/uibuilder/list/useListParamsMigration';
import { useOneStopRequestsUrl } from 'oneStop/OneStopRouterHelper';
import useAsyncValue from 'shared/asyncHelper';
import LeaveOfficeFilter from 'erp/leave/List/filter/LeaveOfficeFilter';
import { CREATE_EXPENSE } from 'financialAnalytic/expenses/useExpenseService';
import AddLeaveCompensationButton, {
  isLeaveCompensationButtonVisible,
} from 'financialAnalytic/expenses/shared/button/AddLeaveCompensationButton';
import './LeaveListPage.scss';

const LeavesList = () => {
  const { searchWithExpensesInfo, canBeExtended, getFiltersValidationSchema } = useLeaveService();
  const { isGranted } = useAuthorization();
  const { getUserAlias } = useAuthenticatedUserService();
  const userAlias = useAsyncValue(() => getUserAlias());
  const { getCreateUrl, getSingleEntityUrl } = useLeaveUrl();
  const { getSingleEntityUrl: getSingleOneStopEntityUrl } = useOneStopRequestsUrl();
  const canAddCompensation = isGranted(CREATE_EXPENSE);

  const migrationsArray = [
    {
      version: '1.0',
      migration: {
        filter: userAlias ? { 'employee.alias': { eq: [userAlias] } } : {},
        sort: { field: 'startDate', order: 'DESC' },
      },
    },
  ];

  useListParamsMigration(migrationsArray as any);

  const isUpdateButtonVisible = (leave: any) => {
    return (
      leave.canEdit && (isGranted(DISPLAY_UPDATE_BUTTON) || (isGranted(DISPLAY_EXTEND_BUTTON) && canBeExtended(leave)))
    );
  };

  const filters = (
    <Filters getValidationSchema={getFiltersValidationSchema}>
      <FiltersLayout
        quickFilters={[
          <QuickFilter key="active-leaves" label="Leaves in approval" state={{ status: { in: ['IN_APPROVAL'] } }} />,
        ]}
      >
        <LeaveTypeFilter label="Type" source="type" />
        <LeaveStatusFilter label="Status" source="status" />
        <DateRangeFilter label="Start Date" source="startDate" canBeInFuture />
        <DateRangeFilter label="End Date" source="actualEndDate" canBeInFuture />
        <EmployeeFilter label="Employee" source="employee.alias" />
        <EmployeePositionFilter label="Position" source="employee.position.id" />
        <LeaveOfficeFilter label="Office" source="specification:leavesByOfficeSpecification" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List
      getDataMethod={searchWithExpensesInfo}
      defaultFilter={userAlias ? { 'employee.alias': { eq: [userAlias] } } : {}}
      defaultSortOrder="DESC"
      defaultSortField="startDate"
    >
      <ListPageLayout
        header={<ListHeader label="Leaves" icon="fa-ship" />}
        actions={[
          <CreateButton
            url={getCreateUrl()}
            label="Create Leave"
            key="create-leave"
            permissionToCheck={CREATE_LEAVE}
          />,
          <CreateButton
            url={getSingleOneStopEntityUrl('HR03')}
            label="Request Leave via One-Stop"
            key="request-leave"
            permissionToCheck={REQUEST_LEAVE}
            sx={{ marginLeft: '20px' }}
          />,
        ]}
        filter={filters}
      >
        <DataGridLayout
          buttons={[
            <EditLeaveButton
              key="edit-leave"
              source="id"
              outline
              isVisible={isUpdateButtonVisible}
              permissionToCheck={null}
            />,
            <AddLeaveCompensationButton isVisible={isLeaveCompensationButtonVisible} />,
          ]}
          tableWrapperClassName={classnames('leaves-list-wrapper', {
            'leaves-list-wrapper--with-compensation-btn': canAddCompensation,
          })}
        >
          <SearchLinkField
            source="id"
            url={(id: Nullable<StringOrNumber> | undefined) => getSingleEntityUrl(id)}
            label="Leave ID"
            isSortable
          />
          <EmployeeLinkField
            source="employeeId"
            nameSource="employeeName"
            label="Employee"
            sortSource="employee.nameEn.lastName"
            isSortable
          />
          <LeaveTypeField source="type" isSortable />
          <BadgeField
            badges={[<ExcludedFromBalanceBadge isVisible={({ isExcludedFromBalance }) => isExcludedFromBalance} />]}
            sortSource="startDate"
            label="Start Date"
            isSortable
          >
            <DateField source="startDate" />
          </BadgeField>
          <DateField source="actualEndDate" label="End Date" isSortable />
          <TextField source="duration" label="Duration, days" />
          <LeaveStatusField source="status" isSortable />
          <FullDateField source="updatedAt" label="Last Modified Date" isSortable />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default LeavesList;
