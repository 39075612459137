import React from 'react';

const BLetterIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
    <path
      fill="currentColor"
      d="M22 19.5556C22 20.2039 21.7425 20.8256 21.284 21.284C20.8256 21.7425 20.2039 22 19.5556 22H2.44444C1.79614 22 1.17438 21.7425 0.715961 21.284C0.257539 20.8256 0 20.2039 0 19.5556V2.44444C0 1.79614 0.257539 1.17438 0.715961 0.715961C1.17438 0.257539 1.79614 0 2.44444 0L19.5556 0C20.2039 0 20.8256 0.257539 21.284 0.715961C21.7425 1.17438 22 1.79614 22 2.44444V19.5556Z"
    />
    <path
      fill="white"
      d="M6.41504 5.65228C6.41504 4.74295 7.04021 4.23145 7.91165 4.23145H11.5496C13.5198 4.23145 15.1124 5.55756 15.1124 7.56628C15.1124 8.89239 14.5624 9.78339 13.3493 10.3517V10.3896C14.9608 10.6169 16.2295 11.962 16.2295 13.6108C16.2295 16.2826 14.4487 17.7229 11.8338 17.7229H7.93121C7.05915 17.7229 6.41504 17.1736 6.41504 16.2832V5.65228ZM9.25732 9.57439H10.7356C11.6639 9.57439 12.2701 9.025 12.2701 8.07717C12.2701 7.18678 11.5692 6.73211 10.7356 6.73211H9.25732V9.57439ZM9.25732 15.2211H11.4934C12.5164 15.2211 13.2748 14.6338 13.2748 13.5729C13.2748 12.6819 12.5922 12.0757 11.266 12.0757H9.25732V15.2211Z"
    />
  </svg>
);

export default BLetterIcon;
