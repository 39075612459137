/* istanbul ignore file */
import React from 'react';
import Typography from 'uibuilder/Typography';

/**
 * @param icon -- fa- prefixed classname for icon.
 * @param label
 * @returns {*}
 * @constructor
 */
interface ListHeaderProps {
  icon?: string;
  label: string;
}

const ListHeader = ({ icon, label }: ListHeaderProps) => {
  return (
    <Typography variant="h1" className="c-title" sx={{ marginBottom: '20px', marginTop: { xs: 0, md: '10px' } }}>
      {label}
    </Typography>
  );
};

export default ListHeader;
