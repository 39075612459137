import EditVacancyButton from 'erp/recruitment/vacancy/shared/button/EditVacancyButton';
import {
  CREATE_VACANCY,
  DISPLAY_UPDATE_BUTTON,
  UPDATE_VACANCY,
  UPDATE_VACANCY_MANAGER,
} from 'erp/recruitment/vacancy/shared/vacancyService';
import CopyVacancyButton from 'erp/recruitment/vacancy/shared/button/CopyVacancyButton';
import DeleteVacancyButton from 'erp/recruitment/vacancy/shared/button/DeleteVacancyButton';
import PublishVacancyButton from 'erp/recruitment/vacancy/shared/button/PublishVacancyButton';
import ViewVacancyPageMenu from 'erp/recruitment/vacancy/Show/ViewVacancyPageMenu';
import SingleVacanciesBreadcrumbs from 'erp/recruitment/vacancy/shared/SingleVacanciesBreadcrumbs';
import VacancyEntityHeader from 'erp/recruitment/vacancy/shared/VacancyEntityHeader';
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import EditVacancyManagerButton
  from 'erp/recruitment/vacancy/shared/button/EditVacancyManagerButton';
import MigrateVacancyButton from 'erp/recruitment/vacancy/shared/button/MigrateVacancyButton';
import useFeatureToggle, { Features } from 'featuretoggle';

type ShowVacancyPageLayoutProps = {
  children: React.ReactElement[] | React.ReactElement;
};

const ShowVacancyPageLayout = ({ children }: ShowVacancyPageLayoutProps) => {
  const { isFeatureEnabled } = useFeatureToggle();
  return (
    <ShowPageLayout
      headerButtons={[
        <EditVacancyManagerButton key="edit-manager" icon={<i className="fa fa-profile" />} permissionToCheck={UPDATE_VACANCY_MANAGER} />,
        <EditVacancyButton key="edit-vacancy" icon={<i className="fa fa-edit" />} permissionToCheck={UPDATE_VACANCY} />,
        <CopyVacancyButton key="copy-vacancy" permissionToCheck={CREATE_VACANCY} />,
        <DeleteVacancyButton key="delete-vacancy" />,
        <PublishVacancyButton
          isVisible={vacancy => vacancy.status === 'OPEN'}
          source="alias"
          key="publish-vacancy"
          permissionToCheck={DISPLAY_UPDATE_BUTTON}
        />,
        isFeatureEnabled(Features.MIGRATE_VACANCY) ? <MigrateVacancyButton key="migrate-vacancy"/> : <></>
      ]}
      menu={<ViewVacancyPageMenu />}
      breadcrumbs={<SingleVacanciesBreadcrumbs />}
      entityHeader={<VacancyEntityHeader />}
      hasSeparateSections
    >
      {children}
    </ShowPageLayout>
  );
};

export default ShowVacancyPageLayout;
