/* istanbul ignore file */
import React from 'react';
import { useParams, Routes } from 'react-router-dom';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import EmployeeContractsList from 'erp/employee/contracts/List';
import useEntityUrl from 'shared/routing/useEntityUrl';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import { Route } from 'shared/routing';
import {
  CREATE_EMPLOYEE_CONTRACT,
  READ_EMPLOYEE_CONTRACT,
  READ_EMPLOYEE_CONTRACTS_LIST,
  UPDATE_EMPLOYEE_CONTRACT,
} from 'erp/employee/contracts/shared/employeeContractsService';
import CreateEmployeeContract from 'erp/employee/contracts/createupdate/Create';
import EmployeeContractsContext from 'erp/employee/contracts/EmployeeContractsContext';
import UpdateEmployeeContract from 'erp/employee/contracts/createupdate/Update';
import ViewEmployeeContract from 'erp/employee/contracts/Show';

export const EMPLOYEE_CONTRACTS_ID_SOURCE = 'contractId';

export const useEmployeeContractUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();

  return useEntityUrl({
    baseLink: `${getSingleEntityUrl()}/contracts`,
  });
};

export const useEmployeeContractRoute = () => {
  return useRoute({
    singleEntityRoute: `/:contractId`,
    listRoute: `/contracts`,
  });
};

export const useEmployeeContractId = () => {
  return useParams<Dictionary<string>>()[EMPLOYEE_CONTRACTS_ID_SOURCE]!;
};

const EmployeeContractsRouter = () => {
  const { singleEntityRoute, listRoute, updateRoute, createRoute } = useEmployeeContractRoute();

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <ProtectedRoute permissionToCheck={READ_EMPLOYEE_CONTRACTS_LIST}>
            <EmployeeContractsList />
          </ProtectedRoute>
        }
      />

      <Route
        path={createRoute}
        element={
          <ProtectedRoute path={createRoute} permissionToCheck={CREATE_EMPLOYEE_CONTRACT}>
            <CreateEmployeeContract />
          </ProtectedRoute>
        }
      />

      <Route
        path={updateRoute}
        element={
          <EmployeeContractsContext permissionToCheck={UPDATE_EMPLOYEE_CONTRACT}>
            <UpdateEmployeeContract />
          </EmployeeContractsContext>
        }
      />

      <Route
        path={singleEntityRoute}
        element={
          <EmployeeContractsContext permissionToCheck={READ_EMPLOYEE_CONTRACT}>
            <ViewEmployeeContract />
          </EmployeeContractsContext>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default EmployeeContractsRouter;
