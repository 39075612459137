/* istanbul ignore file */
import React from 'react';
import { useCandidateOfferId, useCandidateOfferUrl } from 'erp/candidate/offercompensation/CandidateOfferRouter';
import useCandidateOfferService from 'erp/candidate/offercompensation/shared/candidateOfferService';
import CandidateEntityHeader from 'erp/candidate/shared/CandidateEntityHeader';
import SingleCandidateOfferBreadcrumbs from 'erp/candidate/offercompensation/Show/Breadcrumbs';
import EditCandidateOfferButton from 'erp/candidate/offercompensation/shared/buttons/EditCandidateOfferButton';
import { DELETE, UPDATE } from 'crm/crmService';
import DeleteCandidateOfferButton from 'erp/candidate/offercompensation/shared/buttons/DeleteCandidateOfferButton';
import { SensitiveShowPageLayout } from 'shared/uibuilder/sensitiveData';
import CandidateMenu from 'erp/candidate/shared/CandidateMenu';
import Show from 'shared/uibuilder/show/Show';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import OfferCompensationDetailsField from 'erp/candidate/offercompensation/shared/field/OfferCompensationDetailsField';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import BooleanField from 'shared/uibuilder/field/BooleanField';
import DateField from 'shared/uibuilder/field/DateField';
import useAuthorization from 'shared/authorization/authorizationService';
import { CANDIDATE_CONTEXT } from 'erp/candidate/CandidateContext';
import {
  INTERACTION_BASE_FINANCE_DATA,
  INTERACTION_CALCULATED_FINANCE_DATA,
  INTERACTION_PROBATION_FINANCE_DATA,
  UPDATE_CANDIDATE_OFFER_ATTACHMENTS,
} from 'erp/candidate/shared/candidateService';

const ViewCandidateOffer = () => {
  const offerCompensationId = useCandidateOfferId();
  const { getById } = useCandidateOfferService();
  const { getListUrl } = useCandidateOfferUrl();
  const { isGranted } = useAuthorization();

  return (
    <SensitiveShowPageLayout
      entityHeader={<CandidateEntityHeader />}
      menu={<CandidateMenu />}
      breadcrumbs={<SingleCandidateOfferBreadcrumbs />}
      buttons={[
        <EditCandidateOfferButton
          key="edit-compensation"
          icon={<i className="fa fa-edit" />}
          permissionToCheck={[UPDATE, UPDATE_CANDIDATE_OFFER_ATTACHMENTS]}
        />,
        <DeleteCandidateOfferButton key="delete-compensation" permissionToCheck={DELETE} />,
      ]}
      timerId={getListUrl()}
    >
      <Show getDataMethod={() => getById(offerCompensationId)}>
        <ShowSection title="Offer Information">
          <SectionRow>
            <BooleanField source="isAccepted" label="Offer Accepted" />
            <DateField source="createdAt" label="Created At" />
            <DateField source="updatedAt" label="Last Modified" />
          </SectionRow>
        </ShowSection>
        <ShowSection title="Offer Compensation Details">
          <FieldListLayout source="offerCompensations" hasSeparator>
            <OfferCompensationDetailsField
              canViewBaseFinanceFields={isGranted(INTERACTION_BASE_FINANCE_DATA, CANDIDATE_CONTEXT)}
              canViewProbationField={isGranted(INTERACTION_PROBATION_FINANCE_DATA, CANDIDATE_CONTEXT)}
              canViewCalculatedGrossField={isGranted(INTERACTION_CALCULATED_FINANCE_DATA, CANDIDATE_CONTEXT)}
            />
          </FieldListLayout>
        </ShowSection>
        <BigSectionRow>
          <AttachmentsListField source="attachmentIds" label="Attachments" />
        </BigSectionRow>
      </Show>
    </SensitiveShowPageLayout>
  );
};

export default ViewCandidateOffer;
