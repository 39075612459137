import React from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import LinkButton from './LinkButton';
import { ButtonProps } from './Button';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import EditIcon from '@mui/icons-material/EditOutlined';

export interface EditButtonProps extends ButtonProps {
  permissionToCheck?: Nullable<string>;
  icon?: React.ReactElement;
  isSmall?: boolean;
  source?: string;
  outline?: boolean;
  color?: BasicColors;
  hasIcon?: boolean;
  label?: string;
  isVisible?: boolean | ((data: any) => boolean);
}

interface GenericEditButtonProps extends EditButtonProps {
  path: string | ((id?: Nullable<string | number>) => string);
}

const EditButton = ({
  path: initialUrl,
  permissionToCheck = 'UPDATE',
  icon,
  isSmall = false,
  hasIcon = false,
  label,
  ...props
}: GenericEditButtonProps) => {
  const { getValue } = useFieldHelper(props);
  const { isGranted } = useAuthorization();

  let path = initialUrl;

  if (typeof initialUrl === 'function') {
    path = initialUrl(getValue());
  }

  return !permissionToCheck || isGranted(permissionToCheck) ? (
    <LinkButton
      url={path}
      size={isSmall ? 'small' : 'medium'}
      {...props}
      startIcon={hasIcon ? <EditIcon fontSize="small" /> : null}
    >
      {label || 'Edit'}
    </LinkButton>
  ) : null;
};

export default EditButton;
