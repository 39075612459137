// libs
import React, { useState } from 'react';
// components
import useArtifactService from 'artifact/artifactService';
import AsyncLightbox from 'artifact/shared/Lightbox/AsyncLightbox';

const getCompDisplayName = (WrappedComponent: { displayName: any; name: any }) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

const RESIZE_SIZES = {
  SIZE_1024: 'resize_1024x1024',
};

function withLightbox(WrappedComp: any) {
  const WithLightbox = ({ getThumbnail: getThumbnailFromProps, ...props }: any) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photosLightboxInitial, setPhotosLightboxInitial] = useState(0);
    const [imagesInLightbox, setImagesInLightbox] = useState([]);
    const { getThumbnail: getThumbnailFromService } = useArtifactService();
    const [onDownload, setOnDownload] = useState(null);
    const getThumbnail = getThumbnailFromProps || getThumbnailFromService;

    const open = (
      images: { map: (arg0: (photo: any) => Promise<any>) => React.SetStateAction<never[]> },
      index: React.SetStateAction<number>,
      download: null,
      isDownloadAvailable = true,
    ) => {
      if (isDownloadAvailable) {
        setOnDownload(() => download);
      }
      setPhotosLightboxInitial(index);
      setImagesInLightbox(
        images.map(async photo => ({
          ...photo,
          src: await getThumbnail(photo.id, RESIZE_SIZES.SIZE_1024),
        })),
      );
      setLightboxOpen(true);
    };

    const close = () => {
      setLightboxOpen(false);
    };

    return (
      <>
        <WrappedComp openLightbox={open} closeLightbox={close} getThumbnail={getThumbnail} {...props} />
        {lightboxOpen && (
          <AsyncLightbox
            open={lightboxOpen}
            onClose={close}
            photos={imagesInLightbox}
            initial={photosLightboxInitial}
            onDownload={onDownload}
          />
        )}
      </>
    );
  };

  WithLightbox.displayName = `withLightbox(${getCompDisplayName(WrappedComp)})`;

  return WithLightbox;
}

export default withLightbox;
