import { Stages, VacancyPriority } from './constants';
import { Moment } from 'moment';

export type TaskStyles = {
  borderColor: string;
  background: string;
  dragIconBackground: string;
};

export type ApplicationPipelineState = {
  stage: Stages;
  jobsStates: string[];
  stageTransitionTimestamp: string;
};

export type Application = {
  id: number;
  candidateId: number;
  candidateName: string;
  vacancyId: number;
  resumeId: string | null;
  createdBy: string | null;
  createdAt: string;
  updatedBy: string | null;
  updatedAt: string;
  applicationPipelineState: ApplicationPipelineState;
  source: string;
  sourceType: string;
  sourceComment: string | null;
  historySource: string;
  sourceEmployeeId: number | null;
};

export type VacancyItem = {
  id: string;
  isAsap: boolean;
  level: string;
  priority: VacancyPriority;
  forecastDate: string;
  hired: number;
  isLegacy: boolean;
  needHire: number;
  specialization: string;
  applications: Application[];
};

export type MappedApplication = {
  id: number;
  entityId: string;
  candidateId: number;
  name: string;
  vacancyId: number;
  activeStates: string[];
  eventTimestamp: string | number | Date | Moment;
  stage: Stages;
  canDrag: boolean;
};

export type MappedVacancyBoard = {
  id: string;
  name: string;
  hired: number;
  priority: string; // dependence on VacancyPriorityValues
  needHire: number;
  isLegacy: boolean;
  forecastDate: string;
  items: MappedApplication[];
  columnSettings?: any; // TODO Update type
};

export enum ColumnState {
  IDLE = 'idle',
  VALID_DROP = 'validDrop',
  INVALID_DROP = 'invalidDrop',
}
