import React from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import Prompt from 'uibuilder/prompt/Prompt';

export const MESSAGE = 'Are you sure you want to change candidate? Your changes will be lost.';

const ConvertCandidatePrompt = ({ candidateId }: { candidateId?: StringOrNumber }) => {
  const { isDirty } = useFormContext();

  if (!candidateId) {
    return null;
  }

  return (
    <Prompt
      message={MESSAGE}
      when={({ nextLocation: { search: newSearch, pathname: newPathname }, currentLocation: { search, pathname } }) => {
        const isCandidateChanged = search !== newSearch && pathname === newPathname;
        return !!isDirty || isCandidateChanged;
      }}
    />
  );
};

export default ConvertCandidatePrompt;
