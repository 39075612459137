import React from 'react';
import { Link } from 'react-router-dom';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { BaseFieldLayoutType } from 'shared/uibuilder/field';
import { LinkFieldLayoutProps, LinkFieldLayoutType } from 'shared/uibuilder/field/LinkField';

const InternalLinkFieldLayout: LinkFieldLayoutType = ({ linkText, label, value }: LinkFieldLayoutProps) => {
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const displayedValue = value ? <Link to={value}>{linkText}</Link> : null;

  return <BaseFieldLayout label={label} value={displayedValue} />;
};

export default InternalLinkFieldLayout;
