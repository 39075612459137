/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useContactUrl } from 'crm/contact/ContactRouter';

const ContactsBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useContactUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Contacts" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default ContactsBreadcrumbs;
