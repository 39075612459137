/* istanbul ignore file */
// libs
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
// components
import ContactContext from 'crm/contact/ContactContext';
import ContactTimelinePage from 'crm/contact/Timeline';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import ContactRelatedOpportunities from 'crm/contact/Show/ContactRelatedOpportunities';
import { CreateContact, UpdateContact } from 'crm/contact/createupdate';
import ListPage from 'crm/contact/list';
import ViewPage from 'crm/contact/Show';
import ShowContact from 'crm/contact/shared/ShowContact';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
// services
import { READ_TIMELINE, UPDATE, READ } from 'crm/crmService';
import { READ_CONTACTS_LIST, CREATE_CONTACT } from 'crm/contact/shared/contactService';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';

export const CONTACTS_PATH = '/crm/contacts';

export const useContactRoute = () => {
  const baseRoutes = useRoute({ listRoute: CONTACTS_PATH });

  return {
    ...baseRoutes,
    timelineRoute: `${baseRoutes.singleEntityRoute}/notes/:timelineEntryId?`,
    relatedOpportunitiesRoute: `${baseRoutes.singleEntityRoute}/related-opportunities`,
  };
};

export const useContactUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: CONTACTS_PATH,
  });

  const getCreateUrl = (params?: Dictionary<string>) => {
    const urlParameters = new URLSearchParams(params).toString();

    return `${baseUrls.getCreateUrl()}${urlParameters ? `?${urlParameters}` : ''}`;
  };

  const getNotesUrl = (id: string) => `${baseUrls.getSingleEntityUrl(id)}/notes`;

  const getRelatedOpportunitiesUrl = (id: string) => `${baseUrls.getSingleEntityUrl(id)}/related-opportunities`;

  return {
    ...baseUrls,
    getCreateUrl,
    getNotesUrl,
    getRelatedOpportunitiesUrl,
  };
};

export const useContactId = () => useParams<Dictionary<string>>().id!;

const ContactRouter = () => {
  const { listRoute, createRoute, updateRoute, timelineRoute, singleEntityRoute, relatedOpportunitiesRoute } =
    useContactRoute();

  return (
    <ProtectedRouter basePath={CONTACTS_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_CONTACTS_LIST}>
              <ListPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_CONTACT}>
              <CreateContact />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={updateRoute}
          element={
            <ContactContext permissionToCheck={UPDATE}>
              <UpdateContact />
            </ContactContext>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <ContactContext permissionToCheck={READ}>
              <ViewPage />
            </ContactContext>
          }
        />

        <Route
          path={timelineRoute}
          element={
            <ContactContext permissionToCheck={READ_TIMELINE}>
              <ContactTimelinePage />
            </ContactContext>
          }
        />

        <Route
          path={relatedOpportunitiesRoute}
          element={
            <ContactContext permissionToCheck={READ}>
              <ShowContact>
                <ContactRelatedOpportunities />
              </ShowContact>
            </ContactContext>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

ContactRouter.getRouterPath = () => '/contacts/*';

export default ContactRouter;
