/* istanbul ignore file */
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { EmailField, TextField } from 'shared/uibuilder/field';
import React from 'react';
import DateField from 'shared/uibuilder/field/DateField';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import PhoneField from 'shared/uibuilder/field/PhoneField';
import useEmployeeService, {
  READ_EMPLOYEE_CONTRACTS,
  READ_EMPLOYEE_MAIN_PERSONAL_INFO,
  READ_EMPLOYEE_MAIN_HR_INFORMATION,
  READ_EMPLOYEE_CONTACT_INFORMATION,
  READ_EMPLOYEE_WORKING_STATUS,
  READ_EMPLOYMENT_AGE_INFORMATION,
} from 'erp/employee/employeeService';
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import EmployeeWorkingStatusField from 'erp/employee/shared/field/EmployeeWorkingStatusField';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import FinalizeEmployeeButton from 'erp/employee/shared/button/FinalizeEmployeeButton';
import useAuthorization from 'shared/authorization/authorizationService';
import CandidateLinkField from 'erp/candidate/shared/field/CandidateLinkField';
import EmploymentHistoryField from 'erp/employee/shared/field/EmploymentHistoryField';
import EmployeeWorkplaceField from 'erp/employee/shared/field/EmployeeWorkplaceField';
import EmployeeOfficeField from 'erp/employee/shared/field/EmployeeOfficeField';

const EmployeeViewPage = () => {
  const { isGranted } = useAuthorization();

  const { getById } = useEmployeeService();
  const id = useEmployeeId()!;

  const shouldShowProbationInfo = (employee: any) => employee.getValueBySource('probation.onProbation');

  const shouldShowEmploymentHistory = () => isGranted(READ_EMPLOYMENT_AGE_INFORMATION);

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        menu={<EmployeeMenu />}
        entityHeader={<EmployeeEntityHeader />}
        breadcrumbs={<SingleEmployeeBreadcrumbs />}
        headerButtons={[<FinalizeEmployeeButton />]}
        hasSeparateSections
      >
        <ShowSection title="">
          <SectionRow>
            <DateField source="extendedInformation.dateOfBirth" label="Date of Birth" />
            <TextField label="Employee ID" source="alias" isVisible={isGranted(READ_EMPLOYEE_CONTRACTS)} />
            <EmailField
              label="Syberry Email"
              source="workingEmail"
              isVisible={isGranted(READ_EMPLOYEE_MAIN_PERSONAL_INFO)}
            />
            <EmployeeWorkplaceField
              label="Workplace"
              source="extendedInformation.hrInformation"
              isVisible={isGranted(READ_EMPLOYEE_MAIN_HR_INFORMATION)}
            />
            <EmailField
              label="Personal Email"
              source="extendedInformation.personalEmail"
              isVisible={isGranted(READ_EMPLOYEE_CONTACT_INFORMATION)}
            />
            <PhoneField
              label="Phone Number"
              source="extendedInformation.personalPhoneNumber"
              isVisible={isGranted(READ_EMPLOYEE_CONTACT_INFORMATION)}
            />
            <EmployeeWorkingStatusField
              source="workingStatus"
              label="Working Status"
              isVisible={isGranted(READ_EMPLOYEE_WORKING_STATUS)}
            />
            <EmployeeOfficeField
              source="officeId"
              label="Registered Office"
              isVisible={isGranted(READ_EMPLOYEE_MAIN_HR_INFORMATION)}
            />
          </SectionRow>
          <SectionRow>
            <AttachmentsListField
              source="extendedInformation.photosIds"
              label="Photos"
              isVisible={isGranted(READ_EMPLOYEE_MAIN_PERSONAL_INFO)}
            />
            <EmploymentHistoryField
              label="Employment history"
              source="extendedInformation.workingPeriods"
              isVisible={shouldShowEmploymentHistory}
            />
            <CandidateLinkField
              label="Candidate Profile"
              source="extendedInformation.candidateId"
              isVisible={(data: any) => data.getValueBySource('extendedInformation.candidateId')}
            />
          </SectionRow>
          <SectionRow>
            <DateField
              isVisible={shouldShowProbationInfo}
              source="probation.nextReviewDate"
              label="Next probation review date"
            />
            <TextField
              isVisible={shouldShowProbationInfo}
              source="probation.monthsPassed"
              label="Passed probation months"
            />
          </SectionRow>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default EmployeeViewPage;
