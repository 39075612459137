import useRoute from 'shared/routing/useRoute';
import {
  CORPORATE_EVENT_PATH,
  CREATE_CORPORATE_EVENT,
  READ_CORPORATE_EVENT,
  READ_CORPORATE_EVENT_LIST,
  UPDATE_CORPORATE_EVENT,
} from 'financialAnalytic/corporateEvents/useCorporateEventService';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Route, Routes, useParams } from 'react-router-dom';
import React from 'react';
import { ProtectedRouter } from 'routing';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import CorporateEventListPage from 'financialAnalytic/corporateEvents/List';
import CorporateEventViewPage from 'financialAnalytic/corporateEvents/Show';
import CreateCorporateEventPage from 'financialAnalytic/corporateEvents/createupdate/Create';
import UpdateCorporateEventPage from 'financialAnalytic/corporateEvents/createupdate/Update';
import CorporateEventBusinessUnitsViewPage from 'financialAnalytic/corporateEvents/Show/businessUnits';
import CorporateEventApplicationsViewPage from 'financialAnalytic/corporateEvents/Show/applications';
import Page404 from '../../shared/uibuilder/Page404';

export const useCorporateEventRoute = () => {
  const routers = useRoute({ listRoute: CORPORATE_EVENT_PATH });
  return {
    ...routers,
    businessUnitsRoute: `${routers.singleEntityRoute}/business-units`,
    candidateApplicationsRoute: `${routers.singleEntityRoute}/candidate-applications`,
  };
};

export const useCorporateEventUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: CORPORATE_EVENT_PATH,
  });

  return {
    ...baseUrls,
    getBusinessUnitsUrl: (id: string): string => `${baseUrls.getSingleEntityUrl()}/business-units`,
    getCandidateApplicationsUrl: (id: string): string => `${baseUrls.getSingleEntityUrl()}/candidate-applications`,
  };
};

export const useCorporateEventId = () => useParams<Dictionary<string>>().id!;

const CorporateEventRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, updateRoute, businessUnitsRoute, candidateApplicationsRoute } =
    useCorporateEventRoute();

  return (
    <ProtectedRouter basePath={CORPORATE_EVENT_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_CORPORATE_EVENT_LIST}>
              <CorporateEventListPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_CORPORATE_EVENT}>
              <CreateCorporateEventPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_CORPORATE_EVENT}>
              <CorporateEventViewPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={updateRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={UPDATE_CORPORATE_EVENT}>
              <UpdateCorporateEventPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={businessUnitsRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_CORPORATE_EVENT}>
              <CorporateEventBusinessUnitsViewPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={candidateApplicationsRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_CORPORATE_EVENT}>
              <CorporateEventApplicationsViewPage />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

CorporateEventRouter.getRouterPath = () => `${CORPORATE_EVENT_PATH}/*`;

export default CorporateEventRouter;
