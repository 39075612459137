import React, { useState } from 'react';
// styles
import './DocumentLayout.scss';
import ArtifactPreviewWrapper, { ArtifactType } from './ArtifactPreviewWrapper';
import DownloadButton from './DownloadButton';

type DocumentLayoutProps = {
  value: ArtifactType;
  handleDownload?: () => void;
  handleOpenPreview?: () => Promise<void> | void;
  isDownloadAvailable?: boolean;
};

const DocumentLayout = ({
  value,
  handleDownload = () => {},
  handleOpenPreview = () => {},
  isDownloadAvailable = true,
}: DocumentLayoutProps) => {
  const [loading, setLoading] = useState(false);
  const onClick = async () => {
    setLoading(true);
    await handleDownload();
    setLoading(false);
  };

  return (
    value && [
      <ArtifactPreviewWrapper
        key="artifact-preview-wrapper"
        artifact={value}
        handleOpenPreview={handleOpenPreview}
        handleDownload={onClick}
      />,
      isDownloadAvailable && <DownloadButton key="download-btn" onClick={onClick} loading={loading} />,
    ]
  );
};

export default DocumentLayout;
