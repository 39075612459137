/* istanbul ignore file */
import FeedbackNoteTimelineRecordLayout from 'erp/employee/Timeline/feedback/shared/layout/FeedbackTimelineRecordLayout';
import CreateFeedbackForm from 'erp/employee/Timeline/feedback/form/CreateFeedbackForm';
import UpdateFeedbackForm from 'erp/employee/Timeline/feedback/form/UpdateFeedbackForm';
import { CREATE_EMPLOYEE_FEEDBACK } from 'erp/employee/Timeline/feedback/employeeFeedbackService';

const useEmployeeFeedbackItem = () => ({
  AddForm: CreateFeedbackForm,
  UpdateForm: UpdateFeedbackForm,
  isApplicable: (item: { feedback: any }) => item.feedback,
  source: 'feedback',
  urlPostfix: 'feedbacks',
  RecordLayout: FeedbackNoteTimelineRecordLayout,
  name: 'Feedback',
  permissionToCheck: CREATE_EMPLOYEE_FEEDBACK,
});

export default useEmployeeFeedbackItem;
