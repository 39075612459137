/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem, { SidebarMenuItemProps } from 'shared/uibuilder/menu/SidebarMenuItem';

interface TimelineMenuItemProps extends Omit<SidebarMenuItemProps, 'name'> {
  linkUrl: string;
  name?: string;
}

const TimelineMenuItem = ({ linkUrl, ...props }: TimelineMenuItemProps) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Timeline" icon="history" {...props} />
);

TimelineMenuItem.displayName = 'Timeline';

export default TimelineMenuItem;
