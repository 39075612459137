/* istanbul ignore file */
import { CreateForm } from 'shared/uibuilder/form';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import React from 'react';
import NoteForm from 'crm/shared/timeline/note/form/NoteForm';
import useCrmNoteService from 'crm/shared/timeline/note/crmNoteService';
import { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

interface CreateNoteFormProps extends FormTemplateProps {
  onCancel: () => void;
  submitFormFunc: (values: FormFieldsData) => Promise<object | void>;
}

const CreateNoteForm = ({ submitFormFunc, onCancel, ...props }: CreateNoteFormProps) => {
  const { getValidationSchema } = useCrmNoteService();
  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateTimelineItemLayout onCancel={onCancel}>
        <NoteForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateNoteForm;
