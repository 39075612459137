/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const options = {
  I: 'I',
  II: 'II',
};

const MilitaryRegistrationCategoryDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => {
  return <EnumDropdown {...props} options={options} />;
};

export default MilitaryRegistrationCategoryDropdown;
