/* istanbul ignore file */
import React from 'react';
import useQualificationModelService from './qualificationModelService';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';

export const QM_CONTEXT = 'QualificationModelVersion';

const QualificationModelContext = ({
  permissionToCheck,
  children,
}: {
  permissionToCheck: string | string[];
  children: React.ReactNode;
}) => {
  const { getPermissions } = useQualificationModelService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={QM_CONTEXT}
      idSource="version"
    >
      {children}
    </SecurityContextTemplate>
  );
};
export default QualificationModelContext;
