import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import React from 'react';
import BudgetStatusDropdown from 'erp/serviceCatalog/budgets/shared/dropdown/BudgetStatusDropdown';

const BudgetStatusDropdownFilter = (props: any) => {
  return (
    <DropDownFilter {...props}>
      <BudgetStatusDropdown {...props} />
    </DropDownFilter>
  );
};

export default BudgetStatusDropdownFilter;
