/* istanbul ignore file */
import React, { useState } from 'react';
import { ButtonWithConfirmation } from 'uibuilder/button';
import useMailboxConsentService, {
  PROVIDE_CONSENT_PERMISSION,
} from 'erp/employee/mailboxConsent/mailboxConsentService';

const EnableEmailScanningButton = () => {
  const { provideConsent } = useMailboxConsentService();
  const [isVisible, setIsVisible] = useState(true);
  return isVisible ? (
    <ButtonWithConfirmation
      modalConfirmButtonText="Submit"
      displayMessage="By enabling Email Scanning, DaVinci will have access to the user's email account and can analyze emails. Are you sure?"
      submitMethod={provideConsent}
      color="secondary"
      permissionToCheck={PROVIDE_CONSENT_PERMISSION}
      outline
      afterSubmit={{
        successMessage: 'Successfully enabled Email Scanning!',
        execute: () => setIsVisible(false),
      }}
    >
      Enable Email Scanning
    </ButtonWithConfirmation>
  ) : null;
};

export default EnableEmailScanningButton;
