import artifactStorageValidation from 'artifactStorage/createupdate/artifactStorageValidation';
import { useKernelApi } from 'api';
import useCrudService from 'shared/crud';
import { useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';
import useArtifactService from 'artifact/artifactService';

export const ARTIFACT_STORAGE_PATH = '/artifact-storage';
export const ATTACH_ARTIFACT = 'ACCOUNT_ATTACH_ARTIFACT';
export const UPLOAD_ARTIFACT_STORAGE_FILES = 'UPLOAD_ARTIFACT_STORAGE_FILES';
export const FE_VIEW_ARTIFACT_STORAGE = 'FE_VIEW_ARTIFACT_STORAGE';
export const DELETE_FILE = 'ARTIFACT_STORAGE_FILE_DELETE';

export const PROJECT_MANAGEMENT_FOLDER = 'Project Management';

export enum ArtifactFileType {
  STATEMENT_OF_WORK = 'STATEMENT_OF_WORK',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
  ACCEPTANCE = 'ACCEPTANCE',
  MSA = 'MSA',
  NDA = 'NDA',
  AMENDMENT = 'AMENDMENT',
  PROJECT_WEEKLY_REPORT = 'PROJECT_WEEKLY_REPORT',
  COMPANY_SERVICE_BUDGET = 'COMPANY_SERVICE_BUDGET',
}

export const ArtifactFileTypeLabel: Dictionary<string> = {
  [ArtifactFileType.STATEMENT_OF_WORK]: 'SOW',
  [ArtifactFileType.CHANGE_REQUEST]: 'Change Request',
  [ArtifactFileType.ACCEPTANCE]: 'Acceptance',
  [ArtifactFileType.MSA]: 'MSA',
  [ArtifactFileType.NDA]: 'NDA',
  [ArtifactFileType.AMENDMENT]: 'Amendment',
  [ArtifactFileType.PROJECT_WEEKLY_REPORT]: 'Weekly Report',
  [ArtifactFileType.COMPANY_SERVICE_BUDGET]: 'Company Service Budget',
};

export enum ArtifactType {
  FOLDER = 'FOLDER',
  FILE = 'FILE',
}

const useArtifactStorageService = () => {
  const { pathname: codePathname } = useLocation();
  const pathname = decodeURIComponent(codePathname);
  const singleResourceUrl = '/artifact-storage/:id';
  const {
    search: baseSearch,
    create: baseCreate,
    getById: baseGetById,
    deleteById,
    ...baseCrudRequests
  } = useCrudService({
    singleResourceUrl,
    listResourceUrl: '/artifact-storage',
    apiService: useKernelApi,
  });
  const { removeArtifactsFromStore } = useArtifactService();

  const getValidationSchema = () => Promise.resolve(artifactStorageValidation);

  const search = async ({ filter = {}, ...params }: any) => {
    const requestParams = {
      ...params,
      path: filter.path || [],
      searchString: filter['specification:search']?.eq || '',
      searchType: filter.searchType || '',
      relationClass: filter.relationClass || '',
    };

    const data = await baseSearch(requestParams);
    return data;
  };

  const searchAll = async (params = {}) => {
    let totalPages = 0;
    let currentPage = 0;
    let result: Dictionary<string>[] = [];

    do {
      // eslint-disable-next-line no-await-in-loop
      const data = await search({
        ...params,
        pageNumber: currentPage,
      });
      // eslint-disable-next-line no-await-in-loop

      currentPage += 1;
      totalPages = data.totalPages;
      result = [...result, ...(data.result || [])];
    } while (currentPage < totalPages);

    return result;
  };

  const create = ({
    artifactId,
    relatedAccount,
    relatedService,
    sow,
    type,
    sowNumber,
    msaNumber,
    changeRequestNumber,
    amendmentNumber,
    acceptanceFromDate,
    acceptanceToDate,
    budgetName,
  }: any) => {
    removeArtifactsFromStore(artifactId);
    const requestData: any = {
      artifactId: artifactId[0],
      artifactRelation: [],
      metaInformation: [
        {
          key: 'TYPE',
          value: type,
        },
      ],
    };

    if (relatedAccount) {
      requestData.artifactRelation.push({
        entityClass: 'Account',
        entityId: relatedAccount,
      });
    }

    if (
      sow &&
      (type === ArtifactFileType.CHANGE_REQUEST ||
        type === ArtifactFileType.ACCEPTANCE ||
        type === ArtifactFileType.AMENDMENT)
    ) {
      requestData.artifactRelation.push({
        entityClass: 'ArtifactStorageFile',
        entityId: sow,
      });
    }

    if (relatedService) {
      requestData.artifactRelation.push({
        entityClass: 'CompanyService',
        entityId: relatedService,
      });
    }

    if (sowNumber && type === ArtifactFileType.STATEMENT_OF_WORK) {
      requestData.metaInformation.push({
        key: 'STATEMENT_OF_WORK_NUMBER',
        value: sowNumber,
      });
    }

    if (msaNumber && type === ArtifactFileType.MSA) {
      requestData.metaInformation.push({
        key: 'MSA_NUMBER',
        value: msaNumber,
      });
    }

    if (changeRequestNumber && type === ArtifactFileType.CHANGE_REQUEST) {
      requestData.metaInformation.push({
        key: 'CHANGE_REQUEST_NUMBER',
        value: changeRequestNumber,
      });
    }

    if (amendmentNumber && type === ArtifactFileType.AMENDMENT) {
      requestData.metaInformation.push({
        key: 'AMENDMENT_NUMBER',
        value: amendmentNumber,
      });
    }

    if (type === ArtifactFileType.ACCEPTANCE) {
      if (acceptanceFromDate) {
        requestData.metaInformation.push({
          key: 'ACCEPTANCE_FROM_DATE',
          value: acceptanceFromDate,
        });
      }
      if (acceptanceToDate) {
        requestData.metaInformation.push({
          key: 'ACCEPTANCE_TO_DATE',
          value: acceptanceToDate,
        });
      }
    }

    if (budgetName && type === ArtifactFileType.COMPANY_SERVICE_BUDGET) {
      requestData.metaInformation.push({
        key: 'COMPANY_SERVICE_BUDGET_NAME',
        value: budgetName,
      });
    }

    return baseCreate(requestData);
  };

  const getArtifactPath = () => {
    return pathname
      .replace('/artifact-storage', '')
      .split('/')
      .filter((path: any) => !!path)
      .map((path: string | undefined) =>
        path && path.toLowerCase().includes('companyservice')
          ? path.replace(/companyservice/gi, 'CompanyService')
          : capitalize(path),
      );
  };

  const getListData = async (params: any) => {
    const path = getArtifactPath();
    const requestParams = {
      ...params,
      filter: {
        ...params.filter,
        path,
      },
    };

    return search(requestParams);
  };

  const getById = async (id: any) => {
    const result = await baseGetById(id);
    return {
      ...result,
      metaInfoType: result.metaInformation.filter((metaInfo: any) => metaInfo.key === 'TYPE')[0].value,
    };
  };

  return {
    ...baseCrudRequests,
    getValidationSchema,
    search,
    create,
    searchAll,
    getArtifactPath,
    getListData,
    getById,
    deleteById,
  };
};

export default useArtifactStorageService;
