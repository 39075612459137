/* istanbul ignore file */
import { Checkbox } from 'shared/uibuilder/form/input';
import AccountNameInput from 'crm/account/shared/input/AccountNameInput';
import React, { useCallback, useEffect } from 'react';
import FormRow from 'shared/layout/form/FormRow';
import FormSection from 'shared/layout/form/FormSection';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import BooleanRadiosGroup, { BOOLEAN_RADIUS_GROUP_OPTIONS } from 'shared/uibuilder/form/input/BooleanRadiosGroup';
import useFeatureToggle, { Features } from 'featuretoggle';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import BigFormRow from 'shared/layout/form/BigFormRow';

const RedmineProjectForm = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const isProjectInfrastructureEnabled = isFeatureEnabled(Features.PROJECT_INFRASTRUCTURE_PAM);
  const { data: formData, formErrors, setFormData = () => {} } = useFormContext();
  const [createParent, setCreateParent] = React.useState(false);
  const [displayParentName] = React.useState(!formData?.parentName);
  const isParentNameFieldDisabled = !!formData?.parentName;

  useEffect(() => {
    if (
      !createParent &&
      formErrors?.parentIdentifier &&
      formErrors?.parentIdentifier.filter((msg: string | string[]) => msg.includes('not found')).length > 0
    ) {
      setCreateParent(true);
    }
    // we don't need to depends on createParent
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors, setCreateParent]);

  const projectCodeName = formData?.name;

  useEffect(() => {
    if (projectCodeName) {
      setFormData({
        ...formData,
        sharepointProjectCodeName: projectCodeName,
        gitlabGroupProjectCodeName: projectCodeName,
        teamsProjectCodeName: projectCodeName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCodeName]);

  const isNeedToCreateOnePassword = formData?.isNeedToCreateOnePassword;
  useEffect(() => {
    if (isNeedToCreateOnePassword === undefined || isNeedToCreateOnePassword === null) {
      setFormData({
        ...formData,
        isNeedToCreateOnePassword: true,
      });
    }
    // we need to set initial value to true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedToCreateOnePassword]);

  const mapAccountInfo = (account: any) => {
    return {
      id: account.accountId,
      accountName: account.accountName,
      text: account.accountName,
    };
  };

  const setAccountName = useCallback(
    (newValue: FormFieldsData) => {
      const { parentIdentifier, accountName } = newValue;
      setFormData({
        ...formData,
        parentIdentifier,
        accountName,
        gitlabGroupAccountAlias: parentIdentifier,
        gitlabGroupAccountName: accountName,
      });
    },
    [formData, setFormData],
  );
  const accountSearch =
    createParent || displayParentName ? (
      <AccountNameInput
        tooltip="Account to create Parent Redmine project"
        source="parentIdentifier"
        label="Parent Name"
        accountNameValue={formData?.parentName}
        accountNameSource="accountName"
        isRequired={createParent}
        disabled={isParentNameFieldDisabled}
        customMapResponse={mapAccountInfo}
        onChangeCallback={setAccountName}
      />
    ) : (
      <></>
    );

  const createParentCheckBox = createParent ? (
    <Checkbox source="createParent" label="Create parent Redmine project?" />
  ) : (
    <></>
  );

  const isNeedToCreateTeams = !!formData?.isNeedToCreateTeamsGroup;
  const isNeedToCreateGitlab = !!formData?.isNeedToCreateGitlabGroup;
  const isNeedToCreateSharepoint = !!formData?.isNeedToCreateSharepoint;
  const isGitlabAccountAliasExists = !!formData?.gitlabGroupAccountAlias;
  const isNeedToChangeAccountForGitlab = !!formData?.isNeedToChangedAccountForGitlab;

  return (
    <div>
      <FormSection title="Project information">
        <FormRow>
          <TextInput source="identifier" label="Identifier" />
          <TextInput source="name" label="Name" />
        </FormRow>
        <FormRow style={{ alignItems: 'center' }}>
          <TextInput source="parentIdentifier" label="Parent Identifier" />
          {accountSearch}
          {createParentCheckBox}
        </FormRow>
      </FormSection>

      {isProjectInfrastructureEnabled ? (
        <>
          <FormSection title="Teams Group">
            <MediumFormRow>
              <BooleanRadiosGroup
                options={BOOLEAN_RADIUS_GROUP_OPTIONS}
                source="isNeedToCreateTeamsGroup"
                label="Is it neccasary to create a Teams group for the project?"
              />
            </MediumFormRow>
            <BigFormRow>
              <ClearAfterUnmountWrapper isVisible={!isNeedToCreateTeams}>
                <TextInput source="existedTeamsWebUrl" label="Link to Teams Group" />
              </ClearAfterUnmountWrapper>
            </BigFormRow>
            <MediumFormRow>
              <TextInput source="teamsProjectCodeName" label="Name" isVisible={isNeedToCreateTeams} />
            </MediumFormRow>
          </FormSection>

          <FormSection title="Git">
            <MediumFormRow>
              <BooleanRadiosGroup
                options={BOOLEAN_RADIUS_GROUP_OPTIONS}
                source="isNeedToCreateGitlabGroup"
                label="Is it neccasary to create Gitlab for the project?"
              />
            </MediumFormRow>
            <FormRow>
              <TextInput
                source="gitlabGroupAccountAlias"
                label="Account Identifier"
                disabled
                isVisible={isNeedToCreateGitlab}
                helpText="Account ID for parent GitLab group."
              />
              <Checkbox
                label="Do you want to change Account ID?"
                source="isNeedToChangedAccountForGitlab"
                isVisible={isNeedToCreateGitlab && isGitlabAccountAliasExists}
              />
            </FormRow>
            <FormRow>
              <AccountNameInput
                tooltip="Account to create Gitlab Project Group"
                source="gitlabGroupAccountAlias"
                label="Related Account"
                accountNameValue={formData?.gitlabGroupAccountName}
                accountNameSource="gitlabGroupAccountName"
                isVisible={
                  (isNeedToCreateGitlab && isNeedToChangeAccountForGitlab) ||
                  (isNeedToCreateGitlab && !isGitlabAccountAliasExists)
                }
                customMapResponse={mapAccountInfo}
              />
            </FormRow>
            <TextInput source="gitlabGroupProjectCodeName" label="Name" isVisible={isNeedToCreateGitlab} />
            <BigFormRow>
              <ClearAfterUnmountWrapper isVisible={!isNeedToCreateGitlab}>
                <TextInput source="existedGitlabWebUrl" label="Link to Gitlab Group" />
              </ClearAfterUnmountWrapper>
            </BigFormRow>
          </FormSection>

          <FormSection title="SharePoint Document Storage">
            <MediumFormRow>
              <BooleanRadiosGroup
                options={BOOLEAN_RADIUS_GROUP_OPTIONS}
                source="isNeedToCreateSharepoint"
                label="Is it neccasary to create SharePoint Document Storage for the project"
              />
            </MediumFormRow>
            <BigFormRow>
              <ClearAfterUnmountWrapper isVisible={!isNeedToCreateSharepoint}>
                <TextInput source="existedSharepointWebUrl" label="Link to SharePoint" />
              </ClearAfterUnmountWrapper>
            </BigFormRow>
            <MediumFormRow>
              <TextInput source="sharepointProjectCodeName" label="Name" isVisible={isNeedToCreateSharepoint} />
            </MediumFormRow>
          </FormSection>

          <FormSection title="1Password Integration">
            <MediumFormRow>
              <BooleanRadiosGroup
                options={BOOLEAN_RADIUS_GROUP_OPTIONS}
                source="isNeedToCreateOnePassword"
                label="Is it neccasary to setup 1Password Integration for the project"
              />
            </MediumFormRow>
          </FormSection>
        </>
      ) : null}
    </div>
  );
};

export default RedmineProjectForm;
