import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Message from 'shared/message/Message';
import Loading from 'shared/uibuilder/Loading';
import { CreateForm } from 'shared/uibuilder/form';
import { useSearchParameters } from 'shared/routing/helper';
import useOneStopService, { Request } from 'oneStop/oneStopService';
import OneStopRequestLayout from 'oneStop/Create/OneStopRequestLayout';
import FormBuilder, { FormInputs } from 'shared/uibuilder/form/Builder';
import useOneStopConfiguration from 'oneStop/Create/oneStopConfiguration';
import OneStopModalDecorator from 'oneStop/Create/OneStopModalDecorator';
import OneStopCreatePageLayout from 'oneStop/Create/OneStopCreatePageLayout';
import { useOneStopRequestId, useOneStopRequestsUrl } from 'oneStop/OneStopRouterHelper';
import CustomRequestFormWrapper from 'oneStop/CustomRequestForm/CustomRequestFormWrapper';
import { FormInputType } from 'shared/uibuilder/form/Builder/FormInputFactory';

const OneStopCreatePage = () => {
  const [request, setRequest] = useState<Nullable<Request>>(null);
  const [formConfiguration, setFormConfiguration] = useState<Nullable<FormInputs>>(null);
  const [isLoading, setLoading] = useState<Boolean>(true);
  const navigate = useNavigate();
  const id = useOneStopRequestId()!;
  const initialDataFromSearch = useSearchParameters();
  const { getListUrl } = useOneStopRequestsUrl();
  const { getById, sendRequest, getFieldSourcesForHtmlFields, getFormattedData } = useOneStopService();
  const { getRequestConfiguration, getFormConfiguration, getValidationSchema } = useOneStopConfiguration({
    getById,
    id,
  });
  useEffect(() => {
    Promise.all([
      getRequestConfiguration().then(data => setRequest(data)),
      getFormConfiguration().then(data => setFormConfiguration(data)),
    ])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initialData = useMemo(() => {
    const fields = request && request.fields ? request.fields : [];

    return {
      ...Object.fromEntries(
        fields.map(field =>
          field.type === FormInputType.CHECKBOX
            ? [field.id, field.defaultValue === 'true']
            : [field.id, field.defaultValue],
        ),
      ),
      ...initialDataFromSearch,
    };
  }, [request, initialDataFromSearch]);

  if (isLoading) {
    return <Loading />;
  }

  if (!request || !formConfiguration) {
    return (
      <OneStopCreatePageLayout>
        <Message />
      </OneStopCreatePageLayout>
    );
  }

  const isCustomRequestForm = !request.fields;

  if (isCustomRequestForm) {
    return (
      <OneStopCreatePageLayout>
        <CustomRequestFormWrapper request={request} />
      </OneStopCreatePageLayout>
    );
  }

  const handleCancelClick = () => {
    if (initialData.from) {
      navigate(initialData.from);
    } else {
      navigate(getListUrl());
    }
  };

  const requestContext = { type: request?.type };

  const htmlFieldSources = getFieldSourcesForHtmlFields(formConfiguration);

  return (
    <OneStopModalDecorator requestContext={requestContext}>
      <CreateForm
        initialData={initialData}
        getValidationSchemaFunc={getValidationSchema}
        submitFormFunc={(data: any) => {
          const formattedData = getFormattedData(data, htmlFieldSources);
          return sendRequest(id, formattedData);
        }}
      >
        <OneStopCreatePageLayout id={request.id}>
          <OneStopRequestLayout request={request} handleCancelClick={handleCancelClick}>
            <FormBuilder formInputs={formConfiguration} />
          </OneStopRequestLayout>
        </OneStopCreatePageLayout>
      </CreateForm>
    </OneStopModalDecorator>
  );
};

export default OneStopCreatePage;
