import React from 'react';
import ViewServiceBudgetsBreadcrumbs
  from 'erp/serviceCatalog/budgets/List/ViewServiceBudgetsBreadcrumbs';
import UpdateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/UpdateBreadcrumbItem';

const UpdateServiceBudgetBreadcrumbs = () => {
  return (
    <ViewServiceBudgetsBreadcrumbs>
      <UpdateBreadcrumbItem entity="Budget" />
    </ViewServiceBudgetsBreadcrumbs>
  );
};

export default UpdateServiceBudgetBreadcrumbs;
