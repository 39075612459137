/* istanbul ignore file */
import { StatusContext } from 'privileged/externalaccounts/creation/accountsCreationResponseHandler';
import React from 'react';

const useCreateRedmineProjectResponseHandler = () => {
  const handleCreateProject = (creationStatus: any, statusContext: StatusContext, instructions?: any) => {
    if (creationStatus.status === 'OK') {
      statusContext.successes.push(
        <li key="success-creation">
          Successfully created Redmine project with Identifier: {creationStatus.identifier}.
        </li>,
      );
    } else if (creationStatus.status === 'ERROR') {
      statusContext.errors.push(<li key="error-creation">Failed to create Redmine project.</li>);
    }
  };

  const handleDavinciProjectUpdateStatus = (
    davinciProjectStatus: any,
    statusContext: StatusContext,
    instructions?: any,
  ) => {
    if (davinciProjectStatus.status === 'OK') {
      statusContext.successes.push(
        <li key="success-creation">Successfully updated RBS Tree node with adding Redmine project alias.</li>,
      );
    } else if (davinciProjectStatus.status === 'ERROR') {
      statusContext.errors.push(
        <li key="error-creation">
          Failed to update RBS Tree node with adding Redmine project alias. {davinciProjectStatus.errors.join(' ')}
        </li>,
      );
    }
  };

  const handleAddMembership = (addMembershipStatus: any, statusContext: StatusContext) => {
    if (addMembershipStatus.status === 'OK') {
      statusContext.successes.push(
        <li key="success-creation">Successfully added membership for user: {addMembershipStatus.name}.</li>,
      );
    } else if (addMembershipStatus.status === 'ERROR') {
      statusContext.errors.push(
        <li key="error-creation">
          Failed to add membership for user: {addMembershipStatus.name}. {addMembershipStatus.errors.join(' ')}
        </li>,
      );
    }
  };

  const handleCreateProjectResponse = (state: any, statusContext: StatusContext) => {
    if (state.projectCreationStatus) {
      handleCreateProject(state.projectCreationStatus, statusContext);
    }
    if (state.davinciProjectStatus) {
      handleDavinciProjectUpdateStatus(state.davinciProjectStatus, statusContext);
    }
    if (state.addMembershipStatusList) {
      state.addMembershipStatusList.forEach((addMembershipStatus: any) => {
        handleAddMembership(addMembershipStatus, statusContext);
      });
    }
  };

  return {
    handleCreateProjectResponse,
  };
};

export default useCreateRedmineProjectResponseHandler;
