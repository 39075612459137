/* istanbul ignore file */
import React from 'react';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import OpportunityStageDropdown from 'crm/opportunity/shared/input/OpportunityStageDropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const OpportunityStageFilter = (props: Omit<DefaultDropdownProps, 'options'>) => {
  return (
    <DropDownFilter {...props}>
      <OpportunityStageDropdown {...props} />
    </DropDownFilter>
  );
};

export default OpportunityStageFilter;
