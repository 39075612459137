/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import useRoute from 'shared/routing/useRoute';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import { READ_COST_CENTER_REPORT } from 'erp/costaccounting/shared/costAccountingService';
import CostAccountingDashboard from 'erp/costaccounting/dashboard/CostAccountingDashboard';

export const COST_ACCOUNTING_PATH = '/cost-accounting';

export const useCostAccountingRoute = () => {
  return useRoute({ listRoute: COST_ACCOUNTING_PATH });
};

const CostAccountingRouter = () => {
  const { listRoute } = useCostAccountingRoute();

  return (
    <ProtectedRouter basePath={COST_ACCOUNTING_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_COST_CENTER_REPORT}>
              <CostAccountingDashboard />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

CostAccountingRouter.getRouterPath = () => `${COST_ACCOUNTING_PATH}/*`;

export default CostAccountingRouter;
