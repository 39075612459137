/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import React from 'react';
import useActivityService, {
  DELETE_CRM_ACTIVITY_PERMISSION,
  UPDATE_CRM_ACTIVITY_PERMISSION,
} from 'crm/shared/timeline/activity/crmActivityService';
import ActivityFields from 'crm/shared/timeline/activity/ActivityTimelineRecordLayout/ActivityFields';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import DeleteTimelineRecordButton from 'uibuilder/layout/timeline/buttons/DeleteTimelineRecordButton';
import PastDueMark from 'crm/shared/timeline/activity/ActivityTimelineRecordLayout/PastDueMark';
import { Collapsible } from 'shared/uibuilder/field';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import useTransferActivityService from 'crm/shared/timeline/activity/shared/transferActivityService';
import TransferCrmActivityToAnotherEntityButton from 'crm/shared/timeline/activity/shared/Button/TransferCrmActivityToAnotherEntityButton';
import transferActivityValidation from '../shared/transferActivityValidation';

const ActivityTimelineRecordLayout = ({ onEdit, ...props }: any) => {
  const { data } = useShowContext();
  const { deleteById } = useActivityService();
  const { transferActivity } = useTransferActivityService();

  const buttons = [
    <EditTimelineRecordButton permissionToCheck={UPDATE_CRM_ACTIVITY_PERMISSION} key="edit" onEdit={onEdit} />,
    <TransferCrmActivityToAnotherEntityButton
      key="transfer"
      permissionToCheck={UPDATE_CRM_ACTIVITY_PERMISSION}
      submitFormFunc={values => transferActivity(data.getValueBySource('id'), values)}
      timelineId={data.getValueBySource('timelineId')}
      modalTitle="Transfer CRM activity to another entity"
      getValidationSchemaFunc={() => Promise.resolve(transferActivityValidation)}
      isApplicable={!!data.getValueBySource('internetMessageId')}
    />,
    <DeleteTimelineRecordButton
      permissionToCheck={DELETE_CRM_ACTIVITY_PERMISSION}
      deleteMethod={deleteById}
      key="add"
      id={data.getValueBySource('id')}
      timelineId={data.getValueBySource('timelineId')}
      entityName="note"
    />,
  ];

  const actions = [<PastDueMark />];

  const type = data.getValueBySource('activityType');

  return (
    <BaseTimelineRecordLayout
      fields={
        <Collapsible>
          <ActivityFields entity={data} />
        </Collapsible>
      }
      entity={data}
      buttons={buttons}
      actions={actions}
      timelineType={TimelineType[type]}
      {...props}
    />
  );
};

export default ActivityTimelineRecordLayout;
