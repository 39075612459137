/* istanbul ignore file */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import getAzureDomain from 'erp/config/emailConfiguration';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { ButtonWithConfirmation } from 'uibuilder/button';

type AddToBlacklistCrmActivityEmailProps = {
  permissionToCheck: Nullable<string | string[]>;
  deleteMethod: (id: string) => Promise<void>;
  id: string;
};

const AddToBlacklistCrmActivityEmail = ({
  permissionToCheck,
  deleteMethod,
  id,
}: AddToBlacklistCrmActivityEmailProps) => {
  const { data } = useShowContext();
  const { deleteFromList } = useListContext();
  const senderEmail = data.getValueBySource('senderEmail');
  const receiversEmails = data.getValueBySource('receiversEmails');
  const domainName = getAzureDomain();
  const emails = [...receiversEmails, senderEmail].filter(email => !email.endsWith(domainName));
  const emailsForBlock = emails.join(', ');

  return (
    <ButtonWithConfirmation
      submitMethod={() => deleteMethod(id)}
      afterSubmit={{
        execute: response => {
          if (deleteFromList) {
            response?.result?.forEach((timelineId: number) => deleteFromList(timelineId));
          }
        },
      }}
      permissionToCheck={permissionToCheck}
      modalConfirmButtonText="Submit"
      displayMessage=""
      className="danger-color"
      buttonTag={MenuItem}
      closeOnSubmit={false}
      modalChildren={
        <div className="modal-body">
          {emails.length} email{emails.length > 1 ? 's' : ''} will be added to the DaVinci blacklist:
          <br />
          <br />
          <strong>{emailsForBlock}</strong>
          <br />
          <br />
          <span>This action will:</span>
          <ul>
            <li>Block all future scraping activities for {emails.length > 1 ? 'these emails' : 'this email'}</li>
            <li>Delete all unlinked activities associated with {emails.length > 1 ? 'these emails' : 'this email'}</li>
          </ul>
          <br />
          <strong>Are you sure you want to continue?</strong>
        </div>
      }
    >
      <span>Add to blacklist</span>
    </ButtonWithConfirmation>
  );
};

export default AddToBlacklistCrmActivityEmail;
