/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem, { SidebarMenuItemProps } from 'shared/uibuilder/menu/SidebarMenuItem';

interface SummaryMenuItemProps extends Omit<SidebarMenuItemProps, 'name'> {
  linkUrl: string;
  name?: string;
}

const SummaryMenuItem = ({ linkUrl, ...props }: SummaryMenuItemProps) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Summary" icon="info-circle" {...props} />
);

SummaryMenuItem.displayName = 'Summary';

export default SummaryMenuItem;
