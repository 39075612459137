/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import ServiceBreadcrumbs from 'erp/service/shared/ServiceBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { BreadcrumbsProps } from 'shared/uibuilder/BreadCrumbs/BreadcrumbsWrapper';
import { useServiceUrl } from 'erp/service/ServiceRouter';

const SingleServiceBreadcrumbs = ({ children }: BreadcrumbsProps) => {
  const { getSingleEntityUrl } = useServiceUrl();
  const { id } = useParams<Dictionary<string>>();

  return (
    <ServiceBreadcrumbs>
      <SingleBreadcrumbItem entity={id!} link={getSingleEntityUrl(id)} />
      {children}
    </ServiceBreadcrumbs>
  );
};

export default SingleServiceBreadcrumbs;
