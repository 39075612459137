/* istanbul ignore file */
import React from 'react';
import LinkButton, { LinkButtonProps } from 'uibuilder/button/LinkButton';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const ViewCandidateTimelineButton = (props: Omit<LinkButtonProps, 'children' | 'url'>) => {
  const { getTimelineUrl } = useCandidateUrl();

  return (
    <LinkButton {...props} url={id => getTimelineUrl(id)} key="candidate-timeline">
      Timeline
    </LinkButton>
  );
};

export default ViewCandidateTimelineButton;
