import { CROP_SIZES } from 'artifact/const';

const useConvertingTagsHelper = () => {
  const replaceImagesWithArtifactTag = (images: NodeListOf<Element>) => {
    images.forEach(image => {
      const artifact = document.createElement('artifact');
      artifact.id = image.getAttribute('data-artifact-id')!;

      image.parentNode!.replaceChild(artifact, image);
    });
  };

  const loadAllThumbnails = async (artifacts: any[], asyncGetThumbnail: any) => {
    const getThumbnailsMap = async (id: any) => {
      const thumbnail = await asyncGetThumbnail(id, CROP_SIZES.SIZE_100);
      return {
        id,
        thumbnail,
      };
    };
    const thumbnails = await Promise.all(Array.from(artifacts).map(artifact => getThumbnailsMap(artifact.id)));

    return thumbnails;
  };

  const replaceArtifactWithImageTag = async (artifacts: any, getArtifactsDataByIds: any, asyncGetThumbnail: any) => {
    const thumbnails = await loadAllThumbnails(artifacts, asyncGetThumbnail);
    // @ts-ignore
    const getThumbnail = (id: any) => thumbnails.find(map => map.id === id).thumbnail;

    const artifactsData = await getArtifactsDataByIds(Array.from(artifacts).map((artifact: any) => artifact.id));

    artifacts.forEach((artifact: any) => {
      const thumbnailUrl = getThumbnail(artifact.id);
      const artifactData = artifactsData.find((a: { id: any }) => a.id === artifact.id) || {
        thumbnails: {},
      };
      const image = document.createElement('img');
      image.alt = artifactData.title || 'not-found';
      image.src = thumbnailUrl;
      image.setAttribute('data-artifact-id', artifactData.id);

      artifact.parentNode.replaceChild(image, artifact);
    });
  };

  return {
    replaceImagesWithArtifactTag,
    replaceArtifactWithImageTag,
  };
};

export default useConvertingTagsHelper;
