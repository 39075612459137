/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';

const VacanciesBreadcrumbs = ({ children = null }: { children?: React.ReactNode | React.ReactNode[] | null }) => {
  const { getListUrl } = useVacancyUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Vacancy" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default VacanciesBreadcrumbs;
