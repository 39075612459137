import React, { useState, useEffect, MouseEvent } from 'react';
import Loading from 'shared/uibuilder/Loading';
import usePrevious from 'shared/usePrevious';
import './Lightbox.scss';
import { AsyncLightboxProps } from './AsyncLightbox';

const KEYS = {
  ESC: 27,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  ENTER: 13,
};

const BODY = document.body;

const addBodyStyles = (paddingRight: number) => {
  BODY.classList.add('body-fixed');
  BODY.style.paddingRight = `${paddingRight}px`;
};

const removeBodyStyles = () => {
  BODY.classList.remove('body-fixed');
  BODY.style.paddingRight = '0';
};

const Lightbox = ({ initial, photos, onClose, onDownload = null }: AsyncLightboxProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [paddingRight] = useState(window.innerWidth - document.documentElement.clientWidth);
  const [error, setError] = useState<Nullable<string>>(null);
  const [image, setImage] = useState<any>(null);

  const prevActiveIndex: any = usePrevious(activeIndex);

  function movePrev() {
    setLoading(true);
    setActiveIndex((prevActiveIndex + photos.length - 1) % photos.length);
  }

  function moveNext() {
    setLoading(true);
    setActiveIndex((prevActiveIndex + 1) % photos.length);
  }

  function handleClick(e: MouseEvent) {
    const { target } = e;

    if (target === document.querySelector('.lightbox')) {
      onClose();
    }
  }

  function handleKeyDown(e: KeyboardEvent) {
    const key = e.keyCode;

    if (key === KEYS.ESC) {
      onClose();
    }
    if (key === KEYS.LEFT_ARROW) {
      movePrev();
    }
    if (key === KEYS.RIGHT_ARROW) {
      moveNext();
    }
  }

  useEffect(() => {
    setOpen(true);
    setActiveIndex(initial);

    if (image && image.naturalHeight > 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    addBodyStyles(paddingRight);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      removeBodyStyles();
    };
  });

  return (
    <div className={`lightbox ${open ? 'lightbox--visible' : ''}`} onClick={handleClick} role="presentation">
      {photos.length > 1 && (
        <button className="lightbox__prev" onClick={movePrev} aria-label="Previous photo" type="button">
          Previous photo
        </button>
      )}
      <img
        ref={node => {
          setImage(node);
        }}
        data-testid="lightbox-img"
        className={`lightbox__img ${loading || error ? 'lightbox__img--hidden' : ''}`}
        alt=""
        src={photos[activeIndex]?.src}
        onLoad={() => setLoading(false)}
        onError={() => {
          setError("Error occured, can't show the photo");
        }}
      />

      {loading ? <Loading /> : ''}

      {!loading && error ? <div className="lightbox__message">{error}</div> : ''}

      {photos.length > 1 && (
        <button className="lightbox__next" onClick={moveNext} aria-label="Next photo" type="button">
          Next photo
        </button>
      )}

      <button
        className="lightbox__close"
        onClick={() => {
          onClose();
        }}
        aria-label="Close"
        type="button"
      >
        Close
      </button>
      {onDownload && (
        <button
          className="lightbox__download"
          onClick={() => {
            const activePhoto = photos[activeIndex] || {};

            onDownload(activePhoto.id, activePhoto.title);
          }}
          aria-label="Download"
          type="button"
        >
          <i className="fa fa-download" aria-hidden="true" />
        </button>
      )}
    </div>
  );
};

export default Lightbox;
