/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import FullDateField from 'shared/uibuilder/field/FullDateField';
import PhoneSearchField from 'erp/candidate/List/field/PhoneSearchField';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import { SearchResultField } from 'shared/uibuilder/field';
import AccountTypeField from 'crm/account/shared/field/AccountTypeField';
import useAccountService, { READ_FULL_ACCOUNTS_LIST, UPDATE_ACCOUNT_FROM_LIST_PAGE } from 'crm/account/accountService';
import EditAccountButton from 'crm/account/shared/Button/EditAccountButton';
import CreateAccountButton from 'crm/account/list/buttons/CreateAccountButton';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import AccountSearchLinkField from 'crm/account/list/field/AccountSearchLinkField';
import OwnerFilter from 'crm/shared/filter/OwnerFilter';
import AccountTypeFilter from 'crm/account/list/filter/AccountTypeFilter';
import useAuthorization from 'shared/authorization/authorizationService';

const AccountsListPage = () => {
  const { search, getFilterValidationSchema } = useAccountService();
  const { isGranted } = useAuthorization();

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout>
        <AccountTypeFilter label="Account Type" source="accountType" />
        <OwnerFilter label="Account Owner" source="owner.alias" isVisible={isGranted(READ_FULL_ACCOUNTS_LIST)} />
        <DateTimeRangeFilter label="Created Date" source="createdAt" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="updatedAt">
      <ListPageLayout
        header={<ListHeader label="Accounts" icon="fa-id-card" />}
        filter={filters}
        actions={[<CreateAccountButton />]}
        search={<SearchInput label="Account Name or ID" />}
      >
        <DataGridLayout
          buttons={[<EditAccountButton source="accountId" outline permissionToCheck={UPDATE_ACCOUNT_FROM_LIST_PAGE} />]}
        >
          <AccountSearchLinkField idSource="accountId" label="Account ID" source="id" isSortable width={150} />
          <SearchResultField label="Account Name" source="accountName" isSortable />
          {/* TODO: remove after month */}
          <SearchResultField label="Original Name" source="originalName" isSortable />
          <PhoneSearchField source="phoneNumber" label="Phone" isSortable />
          <AccountTypeField source="accountType" label="Account Type" isSortable />
          <FullDateField source="createdAt" label="Created Date" isSortable />
          <FullDateField source="updatedAt" label="Last Modified Date" isSortable />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default AccountsListPage;
