/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';

const VacancyGroupsBreadcrumbs = ({ children = null }: { children?: React.ReactNode | React.ReactNode[] | null }) => {
  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Dashboard" link="/recruiting-dashboard/new-dashboard" />
      {children}
    </Breadcrumbs>
  );
};

export default VacancyGroupsBreadcrumbs;
