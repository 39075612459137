import React from 'react';
import {
  ACCOUNTING_CYCLE_EVENTS,
} from '../../useAccountingCycle';
import PerformPayrollDistributionButton from 'erp/payroll/payrollDistribution/shared/button/PerformPayrollDistributionButton';
import { LinkButton } from 'uibuilder/button';
import ComputeBonusesButton from 'financialAnalytic/expenses/shared/button/ComputeBonusesButton';
import PerformAssetCostProratingButton from 'financialAnalytic/assets/shared/button/PerformAssetCostProratingButton';
import AccrueRevenueButton from './AccrueRevenueButton';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const AccountingCycleActionButton = ({id}: {id: string}) => {
  const { data } = useShowContext();
  const event = data.getValueBySource('event');
  const disabled = !!data.getValueBySource('createdAt');

  switch (event) {
    case ACCOUNTING_CYCLE_EVENTS.PAYROLL_DISTRIBUTION:
      return (
        <PerformPayrollDistributionButton
          initialValue={id}
          disabledInput
          outline
          startIcon={null}
          disabled={disabled}
        />
      );
    case ACCOUNTING_CYCLE_EVENTS.ACCRUE_REVENUE:
      return <AccrueRevenueButton initialValue={id} disabledInput outline startIcon={null} disabled={disabled} />;
    case ACCOUNTING_CYCLE_EVENTS.COST_PRORATING:
      return <PerformAssetCostProratingButton disabledInput initialValue={id} disabled={disabled} />;
    case ACCOUNTING_CYCLE_EVENTS.RECONCILIATION:
      return (
        <LinkButton disabled={disabled} outline color="primary" url="/reconciliation">
          Reconciliation Page
        </LinkButton>
      );
    case ACCOUNTING_CYCLE_EVENTS.COMPUTE_PERFORMANCE_BASED_BONUS:
      return <ComputeBonusesButton disabled={disabled} disabledInput />;
    default:
      return <></>;
  }
};

export default AccountingCycleActionButton;
