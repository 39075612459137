// libs
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
// components
import { FieldProps, TextField } from 'shared/uibuilder/field';
// services
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useFinanceService from 'erp/employee/finance/shared/financeService';

const FinancePackageIdField = ({ source, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const { dateFromSource, dateToSource, probationAmountSource } = props;
  const { getValue: getDateFromValue } = useFieldHelper({ source: dateFromSource });
  const { getValue: getDateToValue } = useFieldHelper({ source: dateToSource });
  const { getValue: getProbationAmountValue } = useFieldHelper({ source: probationAmountSource });
  const { getIsCurrentPackage } = useFinanceService();
  const value = getValue();

  const getBadges = () => {
    const badgesBlock = [];
    const effectiveDateFrom = getDateFromValue();
    const effectiveDateTo = getDateToValue();
    const probationAmount = getProbationAmountValue();
    const showCurrent = getIsCurrentPackage({ effectiveDateFrom, effectiveDateTo });

    if (showCurrent) {
      badgesBlock.push(
        <Badge color="success" className="mr-2" key="current">
          Current
        </Badge>,
      );
    }

    if (probationAmount) {
      badgesBlock.push(
        <Badge color="primary" key="probation">
          Probation
        </Badge>,
      );
    }

    return badgesBlock;
  };

  const getFieldValue = () => {
    return (
      <>
        <span className="mr-2">{value}</span>
        {getBadges()}
      </>
    );
  };

  return <TextField value={getFieldValue()} {...props} />;
};

export default FinancePackageIdField;
