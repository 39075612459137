export default {
  originalName: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  name: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  projectType: {
    type: 'string',
    required: {
      value: true,
      when: {
        $id: {
          empty: true,
        },
      },
    },
  },
  parentProjectId: {
    type: 'string',
    required: {
      value: true,
      when: {
        $projectType: {
          eq: 'EXISTENT',
        },
        $id: {
          empty: true,
        },
      },
    },
  },
  defaultBudgetId: {
    type: 'string',
  },
  availableBudgetsIds: {
    type: 'array',
  },
};
