/* istanbul ignore file */
import React from 'react';
import { Button } from 'uibuilder/button';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import SearchResultField from 'shared/uibuilder/field/SearchResultField';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

type ArtifactStorageFolderFieldProps = {
  label?: string;
  source: string;
  isVisible?: boolean | ((values: FormFieldsData) => boolean);
};

const ArtifactStorageFolderField = ({ source, ...props }: ArtifactStorageFolderFieldProps) => {
  const { getValue } = useFieldHelper<any>({ source });
  const navigate = useNavigate();
  const location = useLocation();

  const folder = getValue();

  const onClick = () => {
    const path = `${location.pathname}/${(folder.pathKey
      ? `${folder.pathKey}:${folder.pathValue}`
      : folder.folderName
    ).toLowerCase()}`;
    navigate(path);
  };

  return (
    <Button onClick={onClick} link sx={{ minWidth: '1px' }}>
      <SearchResultField {...props} value={folder.folderName} isRowClickable={false} />
    </Button>
  );
};

export default ArtifactStorageFolderField;
