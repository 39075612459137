import React from 'react';
import useKPIService, { KPI_TYPES_UNIT_OPTIONS, UpdateValueType } from '../../useKPIService';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useFeatureToggle, { Features } from 'featuretoggle';
import useAuthorization from 'shared/authorization/authorizationService';
import ModalFormButton from 'shared/uibuilder/form/ModalFormButton';
import FormSection from 'shared/layout/form/FormSection';

type UpdateKpiButtonProps = {
  permissionToCheck: string;
  parenListReload: () => void;
};

const UpdateKpiValueButton = ({ permissionToCheck, parenListReload }: UpdateKpiButtonProps) => {
  const { data } = useShowContext();
  const { type, kpiValueId } = data.getData();
  const formatType = KPI_TYPES_UNIT_OPTIONS[type];
  const { isFeatureEnabled } = useFeatureToggle();
  const { isGranted } = useAuthorization();
  const { updateKPIValue, getUpdateValidationSchema } = useKPIService();

  return isFeatureEnabled(Features.PUBLISH_KPI) && isGranted(permissionToCheck) ? (
    <>
      <ModalFormButton
        outline
        isVisible={isFeatureEnabled(Features.PUBLISH_KPI) && isGranted(permissionToCheck)}
        disabled={kpiValueId === null}
        formTitle="Update"
        label="Update"
        submitFormFunc={formState =>
          updateKPIValue({
            ...formState,
            kpiValueId,
          } as UpdateValueType)
        }
        afterSubmit={{
          execute: () => {
            parenListReload();
          },
        }}
        getValidationSchemaFunc={getUpdateValidationSchema}
      >
        <FormSection>
          <TextInput label={`Value (${formatType})`} source="value" />
        </FormSection>
      </ModalFormButton>
    </>
  ) : (
    <></>
  );
};

export default UpdateKpiValueButton;
