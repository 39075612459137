import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useArtifactService from 'artifact/artifactService';
import getLabel from 'shared/uibuilder/helper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useAsyncValue from 'shared/asyncHelper';
import InputLoader from 'shared/uibuilder/InputLoader';
import useArtifactDownloadHelper from 'artifact/shared/artifactDownloadHelper';
import useArtifactPreviewHelper from 'artifact/shared/artifactPreviewHelper';

type InvoiceAttachmentsListFieldProps = {
  source: string;
  value?: string[] | undefined;
  label: string;
  isDownloadAvailable?: boolean;
  isOneArtifact?: boolean;
  width?: any;
};

const InvoiceAttachmentsListField: React.FC<InvoiceAttachmentsListFieldProps> = ({
  source,
  value: inputValue,
  label: propsLabel,
  isDownloadAvailable = true,
  isOneArtifact = false,
}) => {
  const { AttachmentsListLayout, BaseFieldLayout } = useUiTheme();

  const { data } = useShowContext();
  const label = getLabel(propsLabel, source);
  const { getInvoiceArtifactsDataByIds, getInvoiceThumbnail } = useArtifactService();
  const attachmentsIds = inputValue || (data && data.getValueBySource(source)) || [];

  // @ts-ignore
  const invoiceId = data.id;

  const attachments = useAsyncValue(async () =>
    getInvoiceArtifactsDataByIds(invoiceId, isOneArtifact ? [attachmentsIds] : attachmentsIds),
  );

  const { downloadForInvoice } = useArtifactDownloadHelper();
  const { openPreviewForInvoice } = useArtifactPreviewHelper();

  return attachments ? (
    <AttachmentsListLayout
      attachments={attachments}
      label={label}
      downloadFile={() => downloadForInvoice(invoiceId)}
      getThumbnail={(id: number, cropSize: number) => getInvoiceThumbnail(invoiceId, id, cropSize)}
      openPreview={() => openPreviewForInvoice(invoiceId)}
      isDownloadAvailable={isDownloadAvailable}
      className="mb-6"
    />
  ) : (
    <BaseFieldLayout label={label} value={<InputLoader />} />
  );
};

export default InvoiceAttachmentsListField;
