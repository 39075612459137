/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useLeadUrl } from 'crm/lead/LeadRouter';

const LeadsBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useLeadUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Leads" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default LeadsBreadcrumbs;
