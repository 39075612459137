import React from 'react';
import { SearchInput } from 'shared/uibuilder/form/input';
import useOpportunityService from 'crm/opportunity/shared/opportunityService';

export const mapResponse = (opportunity: any) => ({
  id: opportunity.id,
  text: buildOpportunityAutoSuggestLabel(opportunity),
});

export const buildOpportunityAutoSuggestLabel = (opportunity: any) =>
  !!opportunity && `${opportunity.name} (id: ${opportunity.id})`;

interface OpportunityInputProps {
  mapResults?: (opportunity: any) => void;
  props?: any;
}

const OpportunityInput = ({ mapResults = mapResponse, ...props }: OpportunityInputProps) => {
  const { search } = useOpportunityService() as any;

  return (
    <SearchInput
      // @ts-ignore
      buildAutoSuggestLabel={buildOpportunityAutoSuggestLabel}
      searchRequest={search}
      noResultMessage="No Opportunities are found."
      mapResults={mapResults}
      {...props}
    />
  );
};

export default OpportunityInput;
