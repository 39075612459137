import { useBlocker } from 'react-router-dom';
import { Action as HistoryAction, Location } from '@remix-run/router/history';

export type BlockerFunctionArgs = {
  currentLocation: Location;
  nextLocation: Location;
  historyAction: HistoryAction;
};

type PromptProps = {
  message: string;
  when: boolean | ((props: BlockerFunctionArgs) => boolean);
};

const Prompt = ({ when, message }: PromptProps) => {
  useBlocker(locations => {
    if (typeof when === 'function' && when(locations)) {
      // eslint-disable-next-line no-alert
      return !window.confirm(message);
    }

    if (typeof when === 'boolean' && when) {
      // eslint-disable-next-line no-alert
      return !window.confirm(message);
    }
    return false;
  });

  return null;
};

export default Prompt;
