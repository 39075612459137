/* istanbul ignore file */
import React from 'react';
import PassportNumberLayout from 'erp/employee/shared/layout/PassportNumberLayout';
import { DefaultInputPropTypes, TextInput } from 'shared/uibuilder/form/input';

interface PassportNumberInputProps extends DefaultInputPropTypes<string> {
  isPesel: boolean;
}

const PassportNumberInput = ({ source, isPesel, ...props }: PassportNumberInputProps) => {
  const passportNumberInput = <TextInput {...props} source={`${source}.number`} />;

  const passportSeriesInput = <TextInput {...props} source={`${source}.documentDetails.series`} />;

  return isPesel ? (
    passportNumberInput
  ) : (
    <PassportNumberLayout passportNumberInput={passportNumberInput} passportSeriesInput={passportSeriesInput} />
  );
};

export default PassportNumberInput;
