/* istanbul ignore file */
import React from 'react';
import { EnumField, FieldProps } from 'shared/uibuilder/field';

const options = {
  soldiers: 'Солдаты',
  sailors: 'Матросы',
  sergeants: 'Cержанты',
  ensigns: 'Прапорщики',
  midshipmans: 'Мичманы',
};

const EmployeeMilitaryContingentField = (props: FieldProps) => {
  return <EnumField {...props} options={options} />;
};

export default EmployeeMilitaryContingentField;
