import React from 'react';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import {
  CREATE_LEAD,
  FINALLY_NOT_ACTIVE_STATUSES,
  LEAD_STATUS,
  NOT_ACTIVE_STATUSES,
  READ_FULL_LEADS_LIST,
  UPDATE_LEAD_FROM_LIST_PAGE,
} from 'crm/lead/shared/leadService';
import CreateButton from 'uibuilder/button/CreateButton';
import LeadSearchInput from 'crm/lead/shared/field/LeadSearchInput';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import EditLeadButton from 'crm/lead/shared/button/EditLeadButton';
import { BadgeField, SearchLinkField, SearchResultField } from 'shared/uibuilder/field';
import ExpandingTooltipField from 'shared/uibuilder/field/ExpandingTooltipField';
import EmailSearchField from 'erp/candidate/List/field/EmailSearchField';
import CountryFlagField from 'shared/uibuilder/field/CountryFlagField';
import LeadWillAutoDisqualifiedSoonBadge from 'crm/lead/shared/field/LeadWillAutoDisqualifiedSoonBadge';
import LastTouchCrmField from 'crm/shared/field/LastTouchCrmField';
import LeadStatusField from 'crm/lead/shared/field/LeadStatusField';
import { EmployeeLinkField } from 'erp/employee';
import LeadGradeField from 'crm/lead/shared/field/LeadGradeField';
import LeadAgeField from 'crm/lead/shared/field/LeadAgeField';
import ReadByOwnerCheckboxField from 'crm/shared/field/ReadByOwnerCheckboxField';
import useAuthorization from 'shared/authorization/authorizationService';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import { useLeadUrl } from '../LeadRouter';
import useAsyncValue from 'shared/asyncHelper';
import { NULL_AS_STRING } from 'shared/uibuilder/helper';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import LeadStatusFilter from './filter/LeadStatusFilter';
import LeadGradeFilter from './filter/LeadGradeFilter';
import OwnerFilter from 'crm/shared/filter/OwnerFilter';
import LeadSourceFilter from './filter/LeadSourceFilter';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';
import { getUrlStorageParams } from 'shared/uibuilder/list/builder/useListUrlStoring';
import { useLocation } from 'react-router-dom';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';

const DAYS_COLOR_BORDER = {
  greenBorder: 1.5,
  yellowBorder: 3.5,
  lightRedBorder: 7.5,
};

const EXCEED_DAYS = 21;

const LeadsListContent = () => {
  const location = useLocation();
  const { quickFilter, filters: currentFilters } = useFilterContext();
  const { isGranted } = useAuthorization();
  const { getUserAlias } = useAuthenticatedUserService();
  const { getCreateUrl, getSingleEntityUrl } = useLeadUrl();
  const currentUserAlias = useAsyncValue(async () => getUserAlias());
  const shouldShowCLevelFilters = isGranted(READ_FULL_LEADS_LIST);

  const qualityLeadsFilterState = {
    status: { in: ['NEW', 'ASSIGNED', 'OPEN'] },
    grade: { in: ['A_PLUS', 'A', 'B_PLUS'] },
  };

  const myLeadsFilterState = {
    status: { in: ['NEW', 'ASSIGNED', 'OPEN'] },
    'owner.alias': {
      in: [currentUserAlias],
    },
  };

  const allLeadsState = {
    grade: { in: [NULL_AS_STRING, 'A_PLUS', 'A', 'B_PLUS', 'B', 'B_MINUS', 'C'] },
  };

  useDidUpdateEffect(() => {
    const urlFilters = getUrlStorageParams(location).filter;
    const stringifyCurrentFilters = currentFilters;

    if (
      urlFilters &&
      stringifyCurrentFilters &&
      JSON.stringify(stringifyCurrentFilters) !== JSON.stringify(urlFilters)
    ) {
      quickFilter(getUrlStorageParams(location).filter || {});
    }
  }, [location]);

  const filters = (
    <FiltersLayout
      quickFilters={[
        <QuickFilter label="My Leads" state={myLeadsFilterState} />,
        ...(shouldShowCLevelFilters ? [<QuickFilter label="Quality Leads" state={qualityLeadsFilterState} />] : []),
        ...(shouldShowCLevelFilters ? [<QuickFilter label="All Leads" state={allLeadsState} />] : []),
      ]}
    >
      <LeadStatusFilter label="Lead Status" source="status" />
      <LeadGradeFilter label="Lead Grade" source="grade" />
      <OwnerFilter label="Lead Owner" source="owner.alias" isVisible={isGranted(READ_FULL_LEADS_LIST)} />
      <LeadSourceFilter label="Lead Source" source="source" />
      <DateTimeRangeFilter label="Created Date" source="createdAt" />
    </FiltersLayout>
  );

  return (
    <ListPageLayout
      header={<ListHeader label="Leads" icon="fa-child" />}
      actions={[
        <CreateButton url={getCreateUrl()} label="Create Lead" key="create-lead" permissionToCheck={CREATE_LEAD} />,
      ]}
      search={<LeadSearchInput label="Name, Phone, Email, Company" />}
      filter={filters}
    >
      <DataGridLayout
        rowProps={lead => (lead.highlighted ? { className: 'highlighted' } : {}) as any}
        buttons={[
          <EditLeadButton
            key="edit-lead"
            source="id"
            outline
            permissionToCheck={UPDATE_LEAD_FROM_LIST_PAGE}
            isVisible={lead => LEAD_STATUS[lead.status] !== LEAD_STATUS.QUALIFIED}
            sx={{ height: '2em' }}
          />,
        ]}
        tableWrapperClassName="leads-table"
        isSmallTable
      >
        <SearchLinkField source="id" url={getSingleEntityUrl} label="ID" isSortable width={50} />
        <ExpandingTooltipField
          value={lead => `${lead.firstName} ${lead.middleName || ''} ${lead.lastName}`}
          label="Name"
          source="lastName"
          isSortable
          maxValueLength={12}
          width={150}
        >
          <SearchResultField source="lastName" />
        </ExpandingTooltipField>
        <ExpandingTooltipField source="companyName" label="Company" isSortable maxValueLength={12} width={150}>
          <SearchResultField source="companyName" />
        </ExpandingTooltipField>
        <ExpandingTooltipField source="email" label="Email" isSortable maxValueLength={12} width={150}>
          <EmailSearchField source="email" />
        </ExpandingTooltipField>
        <CountryFlagField source="country" label="Country" width={80} hasTooltip />
        <BadgeField
          badges={[
            <LeadWillAutoDisqualifiedSoonBadge
              id="disqualifiedSoon"
              source="updatedAt"
              statusSource="status"
              skipStates={NOT_ACTIVE_STATUSES}
            />,
          ]}
          label="Last Touch"
          source="updatedAt"
          isSortable
          width={140}
        >
          <LastTouchCrmField
            statusSource="status"
            colorBorders={DAYS_COLOR_BORDER}
            skipStates={NOT_ACTIVE_STATUSES}
            exceedDaysForStale={EXCEED_DAYS}
          />
        </BadgeField>
        <LeadStatusField source="status" label="Status" isSortable />
        <EmployeeLinkField source="ownerAlias" nameSource="ownerName" label="Owner" isSortable />
        <LeadGradeField source="grade" label="Grade" isSortable width={80} />
        <LeadAgeField
          source="leadAge.days"
          label="Age"
          skipStates={FINALLY_NOT_ACTIVE_STATUSES}
          isSortable
          width={100}
        />
        <ReadByOwnerCheckboxField
          source="isVisitedByOwner"
          statusSource="status"
          skipStates={FINALLY_NOT_ACTIVE_STATUSES}
          label="Unread"
          revertValue
          width={70}
        />
      </DataGridLayout>
    </ListPageLayout>
  );
};

export default LeadsListContent;
