/* istanbul ignore file */
import React from 'react';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { SummaryMenuItem } from 'uibuilder/menuitem';
import { useServiceUrl } from 'erp/service/ServiceRouter';

const ViewServicePageMenu = () => {
  const { getSingleEntityUrl } = useServiceUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl()} />
    </SidebarStickyMenu>
  );
};

export default ViewServicePageMenu;
