import React, { useEffect, useState } from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import useEmployeeService, {
  EMPLOYEE_STATE_KEYS,
  UPDATE_EMPLOYEE_INITIALIZATION_STATE,
  FINALIZE_STATE,
  FINALIZE_KEY,
} from 'erp/employee/employeeService';
import { Button } from 'uibuilder/button';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { useNavigate } from 'react-router-dom';
import { validate } from 'validation-schema-library';
import useMessageService, { ErrorMessage, SuccessMessage } from 'shared/message/messageService';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';

const FinalizeEmployeeButton = () => {
  const { isGranted, getPermissions } = useAuthorization();
  const { data: employee } = useShowContext();
  const { checkIsNewEmployee, getValidationSchema, rawUpdate } = useEmployeeService();
  const { addMessage } = useMessageService();
  const { getUpdateEntityUrl } = useEmployeeUrl();

  const [validationSchema, setValidationSchema] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { setData: updateDataInContext = () => {} } = useShowContext();

  useEffect(() => {
    (async () => {
      const schema: any = await getValidationSchema();
      setValidationSchema(schema);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openFinalizeModal = () => setIsModalOpen(true);

  const closeFinalizeModal = () => setIsModalOpen(false);

  const getInvalidKeys = () => {
    // @ts-ignore
    const errors = validate({
      schema: validationSchema,
      fullObject: employee.getData(),
      isCreateRequest: false,
      exceptions: {
        initializationState: EMPLOYEE_STATE_KEYS.FINALIZED,
        'extendedInformation.documentType': employee.getValueBySource('extendedInformation.documentType'),
      },
      permissions: getPermissions(),
    });
    return Object.keys(errors);
  };

  const goToFinalizeEmployee = () => {
    navigate({
      pathname: getUpdateEntityUrl(employee.getValueBySource('alias')),
      search: `${FINALIZE_KEY}=${FINALIZE_STATE}`,
    });
  };

  const isInvalid = () => {
    const invalidKeys = getInvalidKeys();
    return !!invalidKeys.length;
  };

  const finalizeEmployee = async () => {
    if (isInvalid()) {
      openFinalizeModal();
    } else {
      setIsLoading(true);
      try {
        const updatedEmployee: any = await rawUpdate(employee.getValueBySource('alias'), {
          initializationState: EMPLOYEE_STATE_KEYS.FINALIZED,
        });
        updateDataInContext(updatedEmployee);
        addMessage(new SuccessMessage('Finalization completed successfully'));
      } catch {
        addMessage(new ErrorMessage('Sorry, employee cannot be finalized'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const editBtn = (
    <Button color="primary" variant="text" key="editBtn" data-testid="edit-btn" onClick={goToFinalizeEmployee}>
      OK
    </Button>
  );

  return isGranted(UPDATE_EMPLOYEE_INITIALIZATION_STATE) &&
    checkIsNewEmployee(employee.getValueBySource('initializationState')) ? (
    <>
      <Button sx={{ marginLeft: '12px' }} color="primary" onClick={finalizeEmployee} disabled={isLoading}>
        Finalize
      </Button>
      <ModalWindow isOpen={isModalOpen} title="Validation errors" buttons={[editBtn]} onToggle={closeFinalizeModal}>
        <span>Finalization cannot be completed. Required fields are missing. Proceed to ‘Edit employee profile’?</span>
      </ModalWindow>
    </>
  ) : null;
};

export default FinalizeEmployeeButton;
