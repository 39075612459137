import React from 'react';
import * as domUtils from 'shared/uibuilder/domUtils';
import parse, { DOMNode, domToReact, Element, HTMLReactParserOptions } from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import { Link, useLocation } from 'react-router-dom';
import { useHandbookUrl } from './HandbookRouter';
import useSanitizeService from 'shared/security/sanitizeService';
import AttachmentsListFieldWithExternalPhotos from 'artifact/shared/field/AttachmentsListFieldWithExternalPhotos';
import { API_FILE_TYPE } from 'artifact/const';

const LINK_TAG = 'a';
const DETAILS_TAG = 'details';
const TABLE_TAG = 'table';
const IMG_TAG = 'img';
const SUMMARY_TAG = 'summary';

const useHandbookMapper = () => {
  const { pathname } = useLocation();
  const { getSingleEntityUrl } = useHandbookUrl();
  const { getSanitizedData } = useSanitizeService();

  /**
   * Converts an HTML string to one or more React elements.
   * @param {string} text
   * @return {ReturnType<typeof domToReact>}
   */
  function parseHtmlToReactElements(
    text: string,
    getProps?: Nullable<(attribs: Dictionary<string>) => Dictionary<React.ReactNode>>,
  ) {
    const options = {
      replace: (domNode: Element | DOMNode) => {
        const { name, attribs, children } = domNode as Element;
        let elementsProps: any = {};

        if (getProps) {
          elementsProps = getProps(attribs);
        }

        const { sibling, ...props } = elementsProps;

        if (name === DETAILS_TAG) {
          const childrenTags = children?.filter((item: { type: string }) => item.type === 'tag');
          const containOnlyImages = childrenTags?.every(
            (item: any) => item.name === IMG_TAG || item.name === SUMMARY_TAG,
          );
          const images = childrenTags?.filter((item: any) => item.name === IMG_TAG);

          if (containOnlyImages && images.length) {
            return (
              <AttachmentsListFieldWithExternalPhotos
                isDownloadAvailable
                source=""
                label=""
                value={images.map((item: any, index: number) => ({
                  id: item.attribs.src,
                  type: API_FILE_TYPE.image.alias,
                  title: item.attribs.alt || `image ${index + 1}`,
                }))}
              />
            );
          }
        }

        if (name === TABLE_TAG) {
          return (
            <div className="table-wrapper">
              <table className="table table-hover">{domToReact(children as DOMNode[], options)}</table>
            </div>
          );
        }

        if (name === LINK_TAG) {
          if (
            (attribs.href?.startsWith('#') && attribs.class !== 'anchor') ||
            (attribs.href?.startsWith('/') && attribs.href?.includes('#'))
          ) {
            return (
              <>
                <HashLink
                  to={`${pathname}${attribs.href}`}
                  scroll={(el: React.ReactElement) => domUtils.scrollToHash(el)}
                  {...props}
                >
                  {domToReact(children as DOMNode[])}
                </HashLink>
                {sibling}
              </>
            );
          }

          if (attribs.href?.startsWith('/')) {
            return (
              <>
                <Link to={getSingleEntityUrl(attribs.href.slice(1))} {...props}>
                  {domToReact(children as DOMNode[])}
                </Link>
                {sibling}
              </>
            );
          }

          return (
            <>
              <a href={attribs.href} id={attribs.id} target="_blank" rel="noopener noreferrer" {...props}>
                {domToReact(children as DOMNode[])}
              </a>
              {sibling}
            </>
          );
        }

        return domNode;
      },
    };

    const html = getSanitizedData(text);

    return parse(html, options as HTMLReactParserOptions);
  }

  return {
    parseHtmlToReactElements,
  };
};

export default useHandbookMapper;
