export default {
  name: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  ownerId: {
    type: 'string',
    required: true,
  },
  defaultBudgetId: {
    type: 'string',
  },
  availableBudgetsIds: {
    type: 'array',
  },
};
