import useBudgetingService from 'erp/serviceCatalog/budgets/budgetingService';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import React from 'react';
import { useServiceCatalogId } from 'erp/serviceCatalog/ServiceCatalogRouter';
import ServiceBudgetStructureForm from 'erp/serviceCatalog/budgets/structure/Create/ServiceBudgetStructureForm';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import { HTTP } from 'shared/api/const';
import useMessageService, { ErrorMessage, WarningMessage } from 'shared/message/messageService';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';

const CreateServiceBudgetStructure = ({ onCancel, afterSubmit }: any) => {
  const id = useServiceCatalogId();
  const { createStructure, getValidationSchema } = useBudgetingService();
  const { addMessage } = useMessageService();

  const handleErrorsOnSubmit = (submitErrors: any) => {
    switch (submitErrors.status) {
      case HTTP.NOT_FOUND:
        addMessage(new WarningMessage("The budget for this service has not yet been created. Please try again in a minute."));
        break;
      default:
        addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
    }
  };

  return (
    <CreateForm
      submitFormFunc={async req => createStructure(id, req)}
      afterSubmit={afterSubmit}
      handleErrorsOnSubmit={handleErrorsOnSubmit}
      getValidationSchemaFunc={getValidationSchema}
      initialData={{
        categories: [],
        objectives: [],
      }}
    >
      <CreateTimelineItemLayout onCancel={onCancel}>
        <ServiceBudgetStructureForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateServiceBudgetStructure;
