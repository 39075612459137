import { useState, useEffect } from 'react';
import useTimerService from 'shared/uibuilder/timerService/timerService';
import { useDispatch, useSelector } from 'react-redux';
import { DataTimerState, saveTimer, clearTimer as clearTimerFromStore } from 'shared/uibuilder/DataTimer';

export const SENSITIVE_VISIBLE_TIMEOUT = 60000;
const INTERVAL_UPDATE_DELAY = 5000;
const INTERVAL_STEP = 5000;
const DEFAULT_TIMER_ID = 'sensitiveDataTimer';

type SensitiveData = Dictionary<any>;

const getNestedProperty = (obj: SensitiveData, key: string) => {
  return key.split('.').reduce((o, k) => (o && o[k] !== undefined ? o[k] : undefined), obj);
};

const useSensitiveDataService = ({
  loadSensitiveDataMethod,
  timerId = DEFAULT_TIMER_ID,
}: {
  loadSensitiveDataMethod?: (ids: any) => Promise<SensitiveData>;
  timerId?: string;
}) => {
  const timers = useSelector((state: { timers: DataTimerState }) => state?.timers || {});
  const dispatch = useDispatch();

  const [isShown, setIsShown] = useState(false);
  const [sensitiveData, setSensitiveData] = useState<Nullable<SensitiveData>>(null);
  const [isSensitiveDataLoading, setIsSensitiveDataLoading] = useState(false);

  const hideSensitiveData = () => {
    setIsShown(false);
    dispatch(clearTimerFromStore(timerId));
  };

  const { setTimer, isTimerActive, clearTimer } = useTimerService({
    expiredTimerCallback: hideSensitiveData,
    timerLifetime: SENSITIVE_VISIBLE_TIMEOUT,
    intervalUpdateDelay: INTERVAL_UPDATE_DELAY,
    intervalStep: INTERVAL_STEP,
  });

  const displayData = () => {
    setIsShown(true);
    setTimer();
    dispatch(saveTimer(timerId));
  };

  const loadSensitiveDataByIds = async (ids: string[]) => {
    setIsSensitiveDataLoading(true);
    if (typeof loadSensitiveDataMethod === 'function') {
      const response = await loadSensitiveDataMethod(ids);
      setSensitiveData(response);
    }

    setIsSensitiveDataLoading(false);
  };

  const showSensitiveData = async (ids: string[]) => {
    if (!sensitiveData && typeof loadSensitiveDataMethod === 'function') {
      await loadSensitiveDataByIds(ids);
    }

    displayData();
  };

  const toggleSensitiveData = (ids: string[]) => {
    if (isShown) {
      hideSensitiveData();
      clearTimer();
    } else {
      showSensitiveData(ids);
    }
  };

  const getDataById = (id: string) => {
    if (!sensitiveData) {
      return {};
    }

    return sensitiveData[id];
  };

  const getValueByIdAndSource = (id: string, source: string) => {
    if (!id || !source) {
      return null;
    }

    return getNestedProperty(getDataById(id), source);
  };

  useEffect(() => {
    if (timers[timerId]) {
      displayData();
    }
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isShown,
    setIsShown,
    toggleSensitiveData,
    getValueByIdAndSource,
    isSensitiveDataLoading,
    setTimer,
    isTimerActive,
    loadSensitiveDataByIds,
    sensitiveData,
  };
};

export default useSensitiveDataService;
