/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { Route } from 'shared/routing';

import EducationContext from 'erp/employee/Education/EducationContext';

import Page404 from 'shared/uibuilder/Page404';
import ViewPage from 'erp/employee/Education/Show';
import ListPage from 'erp/employee/Education/List';
import { Create, Update } from 'erp/employee/Education/createupdate';

import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';

export const EDUCATION_ID = 'educationId';
export const PATH = '/education';

export const useEducationRoute = () => {
  return useRoute({
    listRoute: `${PATH}`,
    singleEntityRoute: `/:${EDUCATION_ID}`,
  });
};

export const useEducationUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();
  return useEntityUrl({
    baseLink: `${employeeLink}${PATH}`,
    idSource: EDUCATION_ID,
  });
};

export const useEducationId = () => {
  return useParams<Dictionary<string>>()[EDUCATION_ID]!;
};

export const READ_EDUCATIONS_LIST = 'READ_EDUCATIONS_LIST';
export const CREATE_EDUCATION = 'CREATE_EDUCATION';
export const UPDATE_EDUCATION = 'UPDATE';
export const READ_EDUCATION = 'READ';

const EducationRouter = () => {
  const { createRoute, listRoute, singleEntityRoute, updateRoute } = useEducationRoute();

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <GlobalProtectedRoute globalPermissionToCheck={READ_EDUCATIONS_LIST}>
            <ListPage />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={createRoute}
        element={
          <GlobalProtectedRoute globalPermissionToCheck={CREATE_EDUCATION}>
            <Create />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={singleEntityRoute}
        element={
          <EducationContext permissionToCheck={READ_EDUCATION}>
            <ViewPage />
          </EducationContext>
        }
      />

      <Route
        path={updateRoute}
        element={
          <EducationContext permissionToCheck={UPDATE_EDUCATION}>
            <Update />
          </EducationContext>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default EducationRouter;
