/* istanbul ignore file */
import React from 'react';
// layout
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
// buttons
import EditVacancyButton from 'erp/recruitment/vacancy/shared/button/EditVacancyButton';
import CreateButton from 'uibuilder/button/CreateButton';
// fields
import { BadgeField, EnumField, SearchLinkField, SearchResultField } from 'shared/uibuilder/field';
import VacancyStatusField from 'erp/recruitment/vacancy/shared/field/VacancyStatusField';
// filters
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import ResourceManagerFilter from 'erp/employee/resourcePool/filter/ResourceManagerFilter';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import VacancyStatusFilter from 'erp/recruitment/vacancy/List/filter/VacancyStatusFilter';

import useVacancyService, {
  CREATE_VACANCY,
  DISPLAY_UPDATE_BUTTON,
  VACANCY_DUE_DATE,
} from 'erp/recruitment/vacancy/shared/vacancyService';
import { useVacancyUrl } from 'erp/recruitment/vacancy/VacancyRouter';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import useAuthorization from 'shared/authorization/authorizationService';
import PublishVacancyButton from '../shared/button/PublishVacancyButton';
import { SYCOM_VACANCY_PUBLICATION_PLATFORM } from 'erp/recruitment/vacancy/publication/shared/vacancyPublicationService';
import VacancyPublishedOnBadge from 'erp/recruitment/vacancy/shared/badge/VacancyPublishedOnBadge';
import CopyVacancyButton from '../shared/button/CopyVacancyButton';

const VacanciesList = () => {
  const { search } = useVacancyService();
  const { getCreateUrl, getSingleEntityUrl } = useVacancyUrl();
  const { isGranted } = useAuthorization();

  const filters = (
    <Filters>
      <FiltersLayout>
        <VacancyStatusFilter label="Status" source="status" />
        <ResourceManagerFilter source="requestedBy.alias" label="Requested By" />
        <DateTimeRangeFilter label="Created At" source="createdAt" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List getDataMethod={search} defaultSortOrder="DESC" defaultSortField="updatedAt">
      <ListPageLayout
        header={<ListHeader label="Vacancies" icon="fa-ship" />}
        actions={[
          <CreateButton
            url={getCreateUrl()}
            label="Create Vacancy"
            key="create-vacancy"
            permissionToCheck={CREATE_VACANCY}
          />,
        ]}
        filter={filters}
        search={<SearchInput label="Vacancy Name or ID" />}
      >
        <DataGridLayout
          buttons={[
            <EditVacancyButton
              key="edit-vacancy"
              source="alias"
              outline
              isVisible={() => isGranted(DISPLAY_UPDATE_BUTTON)}
              className="m-0"
              permissionToCheck=""
            />,
            <CopyVacancyButton
              key="copy-vacancy"
              source="alias"
              permissionToCheck={CREATE_VACANCY}
              className="m-0"
              outline
            />,
            <PublishVacancyButton
              key="publish-vacancy"
              source="alias"
              outline
              className="m-0"
              isVisible={vacancy => vacancy.status === 'OPEN'}
              permissionToCheck={DISPLAY_UPDATE_BUTTON}
            />,
          ]}
        >
          <SearchLinkField
            source="alias"
            url={(id?: Nullable<StringOrNumber>) => getSingleEntityUrl(id)}
            value=""
            label="Vacancy ID"
          />
          <SearchResultField source="name" isSortable label="Position" width={200} />
          <BadgeField
            badges={[<VacancyStatusField source="status" label="Status" isSortable width={100} />]}
            label="Status"
          />
          <EnumField source="dueDate" label="Due Date" isSortable options={VACANCY_DUE_DATE} />
          <BadgeField
            badges={[
              <VacancyPublishedOnBadge
                source="publications"
                platform={SYCOM_VACANCY_PUBLICATION_PLATFORM}
                text="SYCOM"
                isVisible={data =>
                  data.publications &&
                  data.publications.find((p: { type: string }) => p.type === SYCOM_VACANCY_PUBLICATION_PLATFORM)
                }
              />,
            ]}
            label="Published On"
          />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default VacanciesList;
