import React, { useEffect, useMemo } from 'react';
import { Checkbox, DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useAuthorization from 'shared/authorization/authorizationService';
import { CHANGE_OWNER } from 'crm/crmService';
import { READ_FULL_LEADS_LIST } from 'crm/lead/shared/leadService';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';

import 'crm/lead/shared/input/LeadOwnerNotificationCheckbox.scss';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const LeadOwnerNotificationCheckbox = (props: DefaultInputPropTypes<any>) => {
  const { isGranted } = useAuthorization();
  const hasPermission = isGranted(CHANGE_OWNER) || isGranted(READ_FULL_LEADS_LIST);
  const { data = {} } = useFormContext();
  const { getUserAlias } = useAuthenticatedUserService();
  const initialOwner = useMemo(() => data.ownerAlias, []); // eslint-disable-line react-hooks/exhaustive-deps
  const inputHelper = useInputHelper(props);

  useEffect(() => {
    (async () => {
      const { initializeValue } = inputHelper;
      const alias = await getUserAlias();

      initializeValue(data.ownerAlias !== alias);
    })();
  }, [data.ownerAlias]); // eslint-disable-line react-hooks/exhaustive-deps

  return hasPermission && initialOwner !== data.ownerAlias ? (
    <div className="notification-checkbox">
      <Checkbox {...props} />
    </div>
  ) : null;
};

export default LeadOwnerNotificationCheckbox;
