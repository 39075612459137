import { AgileProductMaintenanceSupportType } from './shared/input/AgileProductMaintenanceSupportTypesDataInputRow';
import { DiscoveryScopeBasedType } from './shared/input/DiscoveryScopeBasedTypesDataInputRow';
import { useNavigate } from 'react-router-dom';
import { useKernelApi } from 'api/index';
import { useProjectId } from 'erp/project/ProjectRouter';
import projectWeeklyReportValidation from './update/projectWeeklyReportValidation';
import { ROUTE403 } from 'shared/api/const';
import useArtifactService from 'artifact/artifactService';

export const READ_PROJECT_REPORTS_LIST = 'PROJECT_REPORTS_READ_LIST';

export enum ProjectServiceModel {
  DISCOVERY = 'DISCOVERY',
  SCOPE_BASED_CUSTOM_SOFTWARE_DEVELOPMENT = 'SCOPE_BASED_CUSTOM_SOFTWARE_DEVELOPMENT',
  MAINTENANCE_AND_SUPPORT = 'MAINTENANCE_AND_SUPPORT',
  AGILE_PRODUCT_DEVELOPMENT = 'AGILE_PRODUCT_DEVELOPMENT',
}

export const ProjectServiceModelLabels = {
  [ProjectServiceModel.DISCOVERY]: 'Discovery ',
  [ProjectServiceModel.SCOPE_BASED_CUSTOM_SOFTWARE_DEVELOPMENT]: 'Scope-based custom software development',
  [ProjectServiceModel.MAINTENANCE_AND_SUPPORT]: 'Maintenance and support',
  [ProjectServiceModel.AGILE_PRODUCT_DEVELOPMENT]: 'Agile product development',
};

export enum ProjectWeeklyReportStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
}

export const ProjectWeeklyReportStatusLabels = {
  [ProjectWeeklyReportStatus.NEW]: 'New',
  [ProjectWeeklyReportStatus.IN_PROGRESS]: 'In Progress',
  [ProjectWeeklyReportStatus.SUBMITTED]: 'Submitted',
};

export enum ChangeRequestStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export const ChangeRequestStatusLabel = {
  [ChangeRequestStatus.NEW]: 'New',
  [ChangeRequestStatus.ACCEPTED]: 'Accepted',
  [ChangeRequestStatus.REJECTED]: 'Rejected',
};

export enum Probability {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export const ProbabilityLabel = {
  [Probability.LOW]: 'Low',
  [Probability.MEDIUM]: 'Medium',
  [Probability.HIGH]: 'High',
};

export enum Impact {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export const ImpactLabel = {
  [Impact.LOW]: 'Low',
  [Impact.MEDIUM]: 'Medium',
  [Impact.HIGH]: 'High',
};

export enum NumberFieldType {
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE',
}

export enum UseCorporateModel {
  YES = 'YES',
  NO = 'NO',
}

export const UseCorporateModelLabel = {
  [UseCorporateModel.NO]: 'No',
  [UseCorporateModel.YES]: 'Yes',
};

export const DoubleValueFormat = /^\d+(\.?)(\d{0,2})$/;
export const IntegerValueFormat = /^\d+$/;

const useProjectWeeklyReportsService = () => {
  const { sendPostRequest, sendGetRequest, sendPutRequest } = useKernelApi();
  const id = useProjectId();
  const navigate = useNavigate();
  const { removeArtifactsFromStore } = useArtifactService();

  const getValidationSchema = () => Promise.resolve(projectWeeklyReportValidation);

  const search = async (request: any) => {
    const response = await sendPostRequest(`/project/${id}/weekly-reports`, request);
    return response.json();
  };

  const getPermissions = async (reportId: any) => {
    const response = await sendGetRequest(`/project/weekly-report/${reportId}/permissions`);
    const json = await response.json();

    if (json.length === 0) {
      navigate(ROUTE403, { replace: true, state: { htmlMessage: 'First you need to submit the previous reports!' } });
      return null;
    }

    return json;
  };

  const getById = async (reportId: any) => {
    const response = await sendGetRequest(`/project/weekly-report/${reportId}`);

    const json = await response.json();

    if (response.status === 200) {
      return prepareResponseData(json);
    }

    return json;
  };

  const prepareResponseData = (json: any) => {
    return {
      ...json,
      corporateModel: json.corporateModel ? UseCorporateModel.YES : UseCorporateModel.NO,
      weeklyReportData: json.weeklyReportData
        ? json.weeklyReportData
        : {
            risks: [],
            milestones: [],
            otherNotes: '',
            discussions: [],
            achievements: [],
            plannedItems: [],
            changeRequests: [],
            executiveSummary: '',
            customerDependencies: [],
            customerExpectation: '',
          },
      internalReportData: json.internalReportData
        ? json.internalReportData
        : {
            projectServiceModel: null,
            discoveryScopeBasedTypesData: [],
            agileProductMaintenanceSupportTypesData: [],
          },
    };
  };

  const update = async (reportId: any, request: any) => {
    if (request.corporateModel === UseCorporateModel.YES) {
      return updateWithCorporateExternalReport(reportId, request);
    } else {
      return updateWithCustomExternalReport(reportId, request);
    }
  };

  const updateWithCorporateExternalReport = async (reportId: any, request: any) => {
    const preparedRequest = prepareUpdateRequest(request);

    const response = await sendPutRequest(`/project/weekly-report/${reportId}`, preparedRequest);
    return response.json();
  };

  const updateWithCustomExternalReport = async (reportId: any, request: any) => {
    const preparedRequest = prepareUpdateRequest(request);
    removeArtifactsFromStore([preparedRequest.reportArtifactId]);

    const response = await sendPutRequest(`/project/weekly-report/${reportId}/upload`, preparedRequest);
    return response.json();
  };

  const prepareUpdateRequest = (request: any) => {
    const { newStatus, ...props } = request;

    return {
      ...props,
      status: newStatus,
      corporateModel: request.corporateModel === UseCorporateModel.YES,
      reportArtifactId:
        request.corporateModel === UseCorporateModel.YES || request.report ? null : request.reportArtifactId[0],
      internalReportData: {
        projectServiceModel: request.internalReportData?.projectServiceModel
          ? request.internalReportData?.projectServiceModel
          : null,
        discoveryScopeBasedTypesData: request.internalReportData?.discoveryScopeBasedTypesData.map(
          (item: DiscoveryScopeBasedType) => {
            return {
              ...item,
              scopeCompletion: +item.scopeCompletion,
              actualBudget: +item.actualBudget,
              totalBudget: +item.totalBudget,
              actualWorkingDays: +item.actualWorkingDays,
              totalWorkingDays: +item.totalWorkingDays,
            };
          },
        ),
        agileProductMaintenanceSupportTypesData:
          request.internalReportData?.agileProductMaintenanceSupportTypesData.map(
            (item: AgileProductMaintenanceSupportType) => {
              return {
                ...item,
                provisioned: +item.provisioned,
                actual: +item.actual,
                billable: +item.billable,
                waste: +item.waste,
              };
            },
          ),
      },
    };
  };

  return {
    search,
    getPermissions,
    getById,
    update,
    getValidationSchema,
  };
};

export default useProjectWeeklyReportsService;
