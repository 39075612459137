/* istanbul ignore file */
import React from 'react';
import { useParams } from 'react-router-dom';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useSkillBasedQueueService, { DELETE_SKILL_BASED_QUEUE } from 'erp/skillbasedqueue/shared/skillBasedQueueService';
import { useSkillBasedQueueUrl } from 'erp/skillbasedqueue/SkillBasedQueueRouter';

const deleteMessage = 'Do you really want to delete this Skill Based Queue?';

const DeleteSkillBasedQueueButton = () => {
  const { id: skillId } = useParams<Dictionary<string>>();
  const { deleteById } = useSkillBasedQueueService();
  const { getListUrl } = useSkillBasedQueueUrl();

  return (
    <DeleteButton
      deleteMethod={() => deleteById(skillId!)}
      displayMessage={deleteMessage}
      afterDelete={{
        redirectUrl: getListUrl(),
        successMessage: 'The Skill Based Queue has been successfully deleted.',
        errorMessage: `Can't delete Skill Based Queue`,
      }}
      permissionToCheck={DELETE_SKILL_BASED_QUEUE}
    />
  );
};

export default DeleteSkillBasedQueueButton;
