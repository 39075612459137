/* istanbul ignore file */
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import { EmployeeLink } from 'erp/employee';
import './index.scss';

const ConvertedCandidateBadge = ({ id }: any) => {
  return (
    <Badge className="ml-2 converted-badge" color="success">
      <EmployeeLink id={id} name="Converted to Employee" />
    </Badge>
  );
};

export default ConvertedCandidateBadge;
