/* istanbul ignore file */
// libs
import React, { useMemo } from 'react';
// components
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { Checkbox, DateInput, TextArea } from 'shared/uibuilder/form/input';
import { EmployeeInput } from 'erp/employee';
import LeaveTypeDropdown from 'erp/leave/shared/input/LeaveTypeDropdown';
import LeaveStatusDropdown from 'erp/leave/shared/input/LeaveStatusDropdown';
import ActualEndDateInput from 'erp/leave/shared/input/ActualEndDateInput';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import {
  LEAVE_STATUSES_ALIASES,
  LEAVE_TYPES_ALIASES,
  UPDATE_LEAVE_STATUS,
  hasSignatureOption,
  IS_PHYSICAL_OPTION_ALIASES,
} from 'erp/leave/shared/leaveService';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import LeaveApplicationScanUploaderInput from 'artifact/shared/input/LeaveApplicationScanUploaderInput';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useAuthorization from 'shared/authorization/authorizationService';
import LeaveDurationWidget from 'erp/leave/createupdate/duration';
import SickDayReasonDropdown from 'erp/leave/shared/input/SickDayReasonDropdown';
import SignatureOptionDropdown from 'erp/leave/shared/input/SignatureOptionDropdown';
import SignatureOptionTooltip from 'erp/leave/shared/tooltip/SignatureOptionTooltip';
import BigFormRow from 'shared/layout/form/BigFormRow';
import useEmployeeService from 'erp/employee/employeeService';
import useAsyncValue from 'shared/asyncHelper';
import { isPolishOffice } from 'erp/employee/office/officeService';

const isRegistered = (status: string) => {
  return LEAVE_STATUSES_ALIASES.REGISTERED === status;
};

const LeaveForm = ({ isOneStop = false }) => {
  const { data } = useFormContext();
  const { isGranted } = useAuthorization();
  const initialStatus = useMemo(
    () => data && data.status,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { getCurrentEmployee } = useEmployeeService();
  const employee = useAsyncValue(async () => getCurrentEmployee(), []);
  const endDateHelpText =
    employee && isPolishOffice(String(employee.officeId))
      ? 'For Sick leave, please enter the earliest expected end date.'
      : 'For Sick leave or Military leave, please enter the earliest expected end date.';

  return (
    <>
      <FormSection title="Leave information">
        <FormRow>
          <EmployeeInput
            source="employeeId"
            label="Employee"
            nameSource="employeeName"
            isVisible={!isOneStop}
            disabled={isRegistered(initialStatus)}
            withContractors
          />
          <LeaveTypeDropdown
            source="type"
            label="Leave Type"
            disabled={isRegistered(initialStatus)}
            isOneStop={isOneStop}
          />
          <LeaveStatusDropdown
            source="status"
            label="Leave Status"
            isVisible={!isOneStop}
            disabled={isRegistered(initialStatus) && !isGranted(UPDATE_LEAVE_STATUS)}
          />
          <SickDayReasonDropdown
            source="sickDayReason"
            label="Sick Day Reason"
            isVisible={(leave: any) => leave.type === LEAVE_TYPES_ALIASES.SICK_DAY}
            disabled={isRegistered(initialStatus)}
          />
        </FormRow>
        <FormRow>
          <DateInput source="startDate" disabled={isRegistered(initialStatus)} />
          <DateInput source="endDate" disabled={isRegistered(initialStatus)} helpText={endDateHelpText} />
          <ClearAfterUnmountWrapper
            isVisible={leave =>
              !!leave.allowedExtensionDate &&
              (leave.type === LEAVE_TYPES_ALIASES.PAID_LEAVE || leave.type === LEAVE_TYPES_ALIASES.COMPENSATION) &&
              isRegistered(leave.status)
            }
          >
            <ActualEndDateInput
              source="extendedEndDate"
              label="Actual End Date"
              helpText="Employee has sick leave(s) within the period of the paid leave.
              So, the leave can be extended without duration changing."
            />
          </ClearAfterUnmountWrapper>
          <LeaveDurationWidget />
        </FormRow>
        <MediumFormRow>
          <LeaveApplicationScanUploaderInput
            label="Application Scans"
            source="applicationScansIds"
            isVisible={!isOneStop}
            disabled={isRegistered(initialStatus)}
          />
        </MediumFormRow>
      </FormSection>
      <FormSection title="Signature option" isVisible={leave => hasSignatureOption(leave)}>
        <MediumFormRow>
          <ClearAfterUnmountWrapper>
            <SignatureOptionDropdown
              source="signatureOption"
              disabled={isRegistered(initialStatus)}
              tooltip={<SignatureOptionTooltip />}
              label="Will you be able to arrive at the office for signing documents
            within 2 business days after leave approval?"
            />
          </ClearAfterUnmountWrapper>
        </MediumFormRow>
        <MediumFormRow>
          <ClearAfterUnmountWrapper
            isVisible={({ signatureOption }) => signatureOption === IS_PHYSICAL_OPTION_ALIASES.ELECTRONIC}
          >
            <TextArea
              source="electronicOptionReason"
              label="Please describe the reason why you are not able to arrive at the office"
              disabled={isRegistered(initialStatus)}
            />
          </ClearAfterUnmountWrapper>
        </MediumFormRow>
        <BigFormRow>
          <ClearAfterUnmountWrapper
            isVisible={({ signatureOption }) => signatureOption === IS_PHYSICAL_OPTION_ALIASES.ELECTRONIC}
          >
            <Checkbox
              source="electronicOptionAgreement"
              label="I confirm that the company can use my comment in the dedicated Redmine ticket as a replacement for my physical signature in leave documents"
              isVisible={({ signatureOption }) => signatureOption === IS_PHYSICAL_OPTION_ALIASES.ELECTRONIC}
              disabled={isRegistered(initialStatus)}
            />
          </ClearAfterUnmountWrapper>
        </BigFormRow>
      </FormSection>
    </>
  );
};

export default LeaveForm;
