/* istanbul ignore file */
import React from 'react';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import BadgeField from 'shared/uibuilder/field/BadgeField';
import DocuSignSignerStatusBadge from 'shared/docusign/envelope/badge/DocuSignSignerStatusBadge';
import FullDateField from 'shared/uibuilder/field/FullDateField';

export const DocuSignSignerStatus = {
  SENT: 'SENT',
  DELIVERED: 'DELIVERED',
  COMPLETED: 'COMPLETED',
  DECLINED: 'DECLINED',
};

export const DocuSignSignerStatusAlias = {
  [DocuSignSignerStatus.SENT]: 'Sent',
  [DocuSignSignerStatus.DELIVERED]: 'Viewed',
  [DocuSignSignerStatus.COMPLETED]: 'Signed',
  [DocuSignSignerStatus.DECLINED]: 'Declined',
};

const DocuSignRecipientsBox = ({ source }: { source: string }) => {
  const { getValue } = useFieldHelper({ source });
  const signers = (getValue() || {}) as any[];

  return (
    <ControlledList data={{ items: signers, totalElements: signers.length, totalPages: 1 }}>
      <DataGridLayout showTotal={false} isSmallTable>
        <TextField source="name" label="Name" width="20%" />
        <TextField source="email" label="Email" width="20%" />
        <BadgeField badges={[<DocuSignSignerStatusBadge source="status" />]} label="Status" width="20%" />
        <FullDateField source="updatedAt" label="Updated At" width="30%" />
      </DataGridLayout>
    </ControlledList>
  );
};

export default DocuSignRecipientsBox;
