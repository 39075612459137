import { useDispatch } from 'react-redux';
import { HTTP } from 'shared/api/const';
import { useSearchParameter } from 'shared/routing/helper';
import useEmployeeService from 'erp/employee/employeeService';
import { DEFAULT_TIMEZONE } from 'shared/uibuilder/dateService/constants';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import SETTINGS_VALIDATION from 'user/Settings/createupdate/settingsValidation';
import { AuthenticatedUserActions } from 'authentication/authenticatedUserService';

const setTimezone = (timezone: string) => ({
  type: AuthenticatedUserActions.SET_TIMEZONE,
  timezone,
});

const useSettingsService = () => {
  const dispatch = useDispatch();
  const exchangeIdParam = useSearchParameter('exchangeId');
  const { getById, update } = useEmployeeService();
  const { addMessage } = useMessageService();

  const getSettings = async (params: any) => {
    try {
      const response = await getById(params);
      const { timezone: tz, exchangeId } = response;
      const timezone = tz || DEFAULT_TIMEZONE;

      dispatch(setTimezone(timezone));

      return {
        timezone,
        exchangeId: exchangeIdParam || exchangeId,
      };
    } catch (error) {
      addMessage(new ErrorMessage("Can't get settings data"));

      return {
        timezone: DEFAULT_TIMEZONE,
        exchangeId: exchangeIdParam,
      };
    }
  };

  const updateSettings = async (alias: Nullable<string> = '', data: any = {}) => {
    try {
      const response = await update(alias as string, data);

      dispatch(setTimezone(response?.timezone || DEFAULT_TIMEZONE));

      return response;
    } catch (error) {
      let errorMessage = "Can't update settings data";

      if ((error as Response).status === HTTP.BAD_REQUEST) {
        errorMessage = 'Settings not saved due to validation errors. Please contact the system administrator.';
      }

      addMessage(new ErrorMessage(errorMessage));

      throw Error;
    }
  };

  const getValidationSchema = () => Promise.resolve(SETTINGS_VALIDATION);

  return {
    getSettings,
    updateSettings,
    getValidationSchema,
  };
};

export default useSettingsService;
