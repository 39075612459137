import React from 'react';
import ListPageHeaderWrapper from 'financialAnalytic/shared/ListPageHeaderWrapper';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import TextField from 'shared/uibuilder/field/TextField';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import AsapTargetField from '../../hiring/shared/fields/AsapTargetField';
import ClosedPositionsField from '../shared/fields/ClosedPositionsField';
import StatisticHiringField from '../shared/fields/StatisticHiringField';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';
import ViewHiringButton from 'erp/recruitment/hiring/shared/button/ViewHiringButton';
import useHiringService from 'erp/recruitment/hiring/useHiringService';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import BooleanFilter from 'shared/uibuilder/list/filter/BooleanFilter';
import TableBodyWithGrouping from 'shared/uibuilder/list/layout/DataGridLayout/TableBodyWithGrouping';
import Typography from 'uibuilder/Typography';
import PositionsListFields from 'erp/recruitment/hiring/shared/fields/PositionsListFields';
import { BadgeField } from 'shared/uibuilder/field';
import DateField from 'shared/uibuilder/field/DateField';
import CustomPositionStatusField from '../shared/fields/CustomPositionStatusField';
import HiringStatusFilter from '../shared/filters/HiringStatusFilter';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';

const COUNT_TABLE_TD = 9;

const RecruitingDashboardContent = () => {
  const { getFilterValidationSchema } = useHiringService();

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout quickFilters={[<QuickFilter label="All Asap" state={{ isAsap: { eq: 'YES' } }} />]}>
        <HiringStatusFilter label="Status" source="status" />
        <DateTimeRangeFilter label="Created At" source="createdAt" />
        <DateRangeFilter canBeInFuture label="Expiration Date" source="expirationDate" />
        <DateRangeFilter canBeInFuture label="Target date" source="targetDate" />
        <BooleanFilter source="isAsap" label="Asap" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <ListPageLayout
      filter={filters}
      search={<SearchInput label="specialisation" />}
      header={<ListPageHeaderWrapper header={<ListHeader label="Dashboard" />} />}
    >
      <DataGridLayout
        buttons={[<ViewHiringButton source="id" />]}
        tableBodyComponent={(props: any) => (
          <TableBodyWithGrouping
            {...props}
            childrenSource="requestPositions"
            toggleSource="specialisation"
            CustomChildTemplate={
              <tr style={{ backgroundColor: 'initial !important' }}>
                <td colSpan={COUNT_TABLE_TD}>
                  <Typography variant="h3" className="c-title" sx={{ mb: '20px', mt: '10px' }}>
                    Positions
                  </Typography>
                  <PositionsListFields isShowButton={false} source="requestPositions" />
                </td>
              </tr>
            }
          />
        )}
      >
        <TextField label="Specialisation" source="specialisation" width={140} />
        <TextField label="Level" source="level" width={50} />

        <BadgeField
          badges={[<CustomPositionStatusField sourceStatus="status" source="requestPositions" label="Status" />]}
          label="Status"
          width={110}
        />

        <StatisticHiringField
          label={
            <>
              Screened
              <InputTooltip source="screened" message="This week / Previous week / Total" />
            </>
          }
          source="screened"
          width={100}
        />
        <StatisticHiringField
          label={
            <>
              Interviewed
              <InputTooltip source="screened" message="This week / Previous week / Total" />
            </>
          }
          source="interviewed"
          width={100}
        />
        <StatisticHiringField
          label={
            <>
              Offered
              <InputTooltip source="screened" message="This week / Previous week / Total" />
            </>
          }
          source="offerDelivered"
          width={100}
        />

        <DateField source="createdAt" isSortable label="Created At" width={100} />
        <AsapTargetField label="Target date" asapSource="isAsap" targetSource="targetDate" width={100} />
        <DateField label="Expiration date" isSortable source="expirationDate" width={110} />

        <ClosedPositionsField
          label={
            <>
              Closed positions
              <InputTooltip source="screened" message="Closed / Total" />
            </>
          }
          source="requestPositions"
          width={100}
        />
      </DataGridLayout>
    </ListPageLayout>
  );
};

export default RecruitingDashboardContent;
