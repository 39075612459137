import { useEffect, useState } from 'react';
import { HTTP } from 'shared/api/const';
import useMessageService, { ErrorMessage, SuccessMessage } from 'shared/message/messageService';
import useErrorMapping from 'shared/uibuilder/form/errorsMapper';
import useAuthorization from 'shared/authorization/authorizationService';
import { cloneDeep, get, set } from 'lodash';
import { FormContextData, FormFieldsData } from './FormContext';
import { ErrorListener, ErrorsMap, FieldsExceptions, validate, ValidationSchema } from 'validation-schema-library';
import useFeatureToggle from 'featuretoggle';
import { flushSync } from 'react-dom';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong.';
export const COMMON_ERROR_SOURCE = '__ALL__';

export const isEmptyFormErrors = (formErrors: object): boolean => {
  return (
    formErrors.constructor === Object &&
    Object.values(formErrors).filter(errorValue => errorValue && errorValue.length !== 0).length === 0
  );
};

const isValidForm = (formErrors: object): boolean => {
  return Object.keys(formErrors).length === 0 && formErrors.constructor === Object;
};

/* eslint-disable */
export const unFlattenObject = (flattenedObj: object): object => {
  const result = {};

  Object.keys(flattenedObj).forEach(i => {
    const keys = i.split('.');
    keys.reduce((r, e, j) => {
      // @ts-ignore
      return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? flattenedObj[i] : {}) : []);
    }, result);
  });

  return result;
};

export const flattenObject = (obj: object, parent: string, res: object = {}): object => {
  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      const propName = parent ? `${parent}.${key}` : key;
      // @ts-ignore
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        // @ts-ignore
        flattenObject(obj[key], propName, res);
      } else {
        // @ts-ignore
        res[propName] = obj[key];
      }
    });
  }

  return res;
};

const updateObject = (object: object, newValue: any, path: string) => {
  const stack = path.split('.');
  while (stack.length > 1) {
    const currentKey = stack.shift();

    // @ts-ignore
    if (!object[currentKey]) {
      // @ts-ignore
      object[currentKey] = {};
    }

    // @ts-ignore
    object = object[currentKey];
  }

  // @ts-ignore
  object[stack.shift()] = newValue;
};
/* eslint-enable */

const defaultExceptions = {};
const defaultInitialData = {};

export interface AfterSubmitProps<ExecuteType, RedirectType = string> {
  redirect?: RedirectType;
  message?: string;
  execute?: ExecuteType;
  resetData?: ExecuteType;
}

interface SubmitFormProps {
  submitFormFunc: (values: FormFieldsData) => Promise<object | void>;
  afterSubmit?: AfterSubmitProps<(response: any) => any, string | ((response: any) => any)>;
}

export interface CommonFormProps {
  initialData?: FormFieldsData;
  handleDirty?: boolean;
  getValidationSchemaFunc?: () => Promise<ValidationSchema>;
  isNeedToHandleErrorsOnSubmit?: boolean;
  handleErrorsOnSubmit?: (submitErrors: Response) => void;
  fieldsExceptions?: FieldsExceptions;
  isCreateRequest?: boolean;
  errorsMap?: ErrorsMap;
  messageContext?: string;
}

export interface FormHelperProps extends SubmitFormProps, CommonFormProps {}

export const getSubmissionErrorsFromResponse = async (response: Response) => {
  const json = await response.json();

  const { errors: errorsObject = {} } = json;

  const errors = {};

  Object.entries(errorsObject).forEach(entry => {
    const [field, errorMessages] = entry;

    if (Array.isArray(errorMessages)) {
      // @ts-ignore
      errors[field] = errorMessages;
    } else {
      // @ts-ignore
      errors[field] = [errorMessages];
    }
  });

  return errors;
};

/**
 * Hook includes common logic of form components.
 *
 * @param initialData - initial form state
 * @param submitFormFunc
 * @param afterSubmit - submit handler configuration
 * @param handleDirty - true if form dirty is needed to be handled.
 * @param getValidationSchemaFunc
 * @param handleErrorsOnSubmit
 * @param isNeedToHandleErrorsOnSubmit
 * @param fieldsExceptions
 * @param isCreateRequest
 * @param errorsMap - error mapper configuration object
 * @returns {{onChangeCallback: onChangeCallback, submitForm: submitForm, data: {}, addFormError: addFormError, formErrors: {}}}
 */
const useForm = ({
  initialData = defaultInitialData,
  submitFormFunc,
  afterSubmit,
  handleDirty = true,
  handleErrorsOnSubmit,
  isNeedToHandleErrorsOnSubmit = true,
  getValidationSchemaFunc,
  fieldsExceptions = defaultExceptions,
  isCreateRequest = true,
  errorsMap = {},
  messageContext,
}: FormHelperProps): FormContextData => {
  const { addMessageAndRedirect, addMessage, cleanMessage } = useMessageService(messageContext);
  const [validationSchema, setValidationSchema] = useState<Nullable<ValidationSchema>>(null);
  const [errorListeners, setErrorListeners] = useState<ErrorListener[]>([]);

  /**
   * Contains form data.
   */
  // @ts-ignore
  const [data, setData] = useState<FormFieldsData>(initialData);

  /**
   * Is need to show warning before leaving the page
   */
  const [isDirty, setDirty] = useState(false);

  /**
   * Contains form errors in the following form.
   *
   * {
   *    _all: [
   *       "Form is invalid",
   *    ],
   *   firstName: [
   *       "Field must be required",
   *   ]
   * }
   */
  const [formErrors, setFormErrors] = useState({});

  /**
   * Boolean flag if submitting is disabled
   */
  const [isSubmitEnabled, setSubmitEnabled] = useState(true);
  const [isLoadingArtifact, setIsLoadingArtifact] = useState(false);

  const { getPermissions } = useAuthorization();

  const { mapFormErrors } = useErrorMapping(errorsMap);
  const { isFeatureEnabled } = useFeatureToggle();

  const validateForm = (validatedData = data) => {
    const errors = validate({
      schema: validationSchema,
      fullObject: validatedData,
      isCreateRequest,
      source: '',
      exceptions: fieldsExceptions,
      permissions: getPermissions(),
      isFeatureEnabled,
    });

    setFormErrors(errors);

    return errors;
  };

  useEffect(() => {
    if (getValidationSchemaFunc) {
      getValidationSchemaFunc().then(schema => setValidationSchema(schema));
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Perform validation if it's update form and data and validation schema are loaded
   */
  useEffect(() => {
    if (!isCreateRequest && validationSchema && data && !isDirty) {
      validateForm();
    }
    // eslint-disable-next-line
  }, [isCreateRequest, validationSchema, data, fieldsExceptions]);

  // we cannot test it via jest.
  const onUnload = (e: Event) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = false;
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  });

  /**
   * Can be used for setting of validation error by input widget.
   * @param source id of field
   * @param error text message that will be displayed like error
   */
  const addFormError = (source: string, error: string) => {
    // @ts-ignore
    let prevErrors = formErrors[source];

    if (!prevErrors) {
      prevErrors = [];
    }

    setFormErrors({
      ...formErrors,
      [source]: [...prevErrors, error],
    });
  };

  const setFieldErrors = (source: string, errors: object) => {
    setFormErrors({
      ...formErrors,
      [source]: errors,
    });
  };

  /**
   * Callback should be called on each form data change. Typically, it's used by UI widgets.
   * Values have the following format
   * {
   *    "field1": "field1value",
   *    "field2": "field2value"
   * }
   *
   * @param values
   * @param isFieldInitializing - if true then onChange is used to initialize some value and form won't be
   * marked as dirty.
   */
  const onChangeCallback = (values: FormFieldsData, isFieldInitializing?: boolean): void => {
    if (!isFieldInitializing && handleDirty) {
      setDirty(true);
    }

    setData(prevData => {
      const copyPrev = cloneDeep(prevData);

      Object.entries(values).forEach(entry => {
        const [source, value] = entry;
        updateObject(copyPrev, value, source);
      });

      return copyPrev;
    });
  };

  /**
   * Callback should be called on each collection form data change.
   * Values have the following format:
   * source, mappingFunc
   *
   */
  const collectionOnChangeCallback = (source: string, func: (values: any) => any) => {
    // Fixed artifact rendering crash after file loading
    flushSync(() =>
      setData(prevData => {
        const oldData = get(prevData, source);
        const newData = func(oldData);
        const newCollection = get(newData, source);

        return { ...set(prevData, source, newCollection) };
      }),
    );
    setDirty(true);
  };

  /**
   * Handles error response from the backend and set validation errors.
   * @param response response
   */
  const handleSubmissionErrors = async (response: Response) => {
    const json = await response.json();

    const { errors: errorsObject = {}, message } = json;

    const errors = {};

    Object.entries(errorsObject).forEach(entry => {
      const [field, errorMessages] = entry;

      if (field === COMMON_ERROR_SOURCE) {
        addMessage(new ErrorMessage(errorMessages as string));

        return;
      }

      if (Array.isArray(errorMessages)) {
        // @ts-ignore
        errors[field] = errorMessages;
      } else {
        // @ts-ignore
        errors[field] = [errorMessages];
      }
    });

    if (message) {
      addMessage(new ErrorMessage(message));
    }

    if (errorListeners.length) {
      const errorCodes = Object.values(errors).flat();
      // @ts-ignore
      errorListeners.forEach(callback => callback(errorCodes));
    }

    setFormErrors(errors);
  };

  /**
   * Function triggers form submission.
   */
  const submitForm = () => {
    // state isn't updated instantly. So, we need actual list of errors
    // in some field before submitting.
    const errors = validateForm();
    if (isValidForm(errors) && submitFormFunc) {
      cleanMessage();
      setSubmitEnabled(false);
      const object = data;
      submitFormFunc(object)
        .then(response => {
          if (afterSubmit) {
            // Unblock page after successful data submission
            flushSync(() => setDirty(false));

            const { redirect, message, execute, resetData } = afterSubmit;
            if (redirect && message) {
              let redirectUrl = redirect;
              if (typeof redirect === 'function') {
                redirectUrl = redirect(response);
              }
              addMessageAndRedirect(new SuccessMessage(message), redirectUrl as string);
            } else if (message) {
              addMessage(new SuccessMessage(message));
            }

            if (execute) {
              execute(response);
            }

            if (resetData) {
              let newData = resetData;
              if (typeof resetData === 'function') {
                newData = resetData(response);
              }
              resetFormData(newData);
            }
          }
        })
        .catch(submitErrors => {
          // Block page after failed data submission
          flushSync(() => setDirty(true));
          if (isNeedToHandleErrorsOnSubmit) {
            if (submitErrors.status === HTTP.BAD_REQUEST) {
              handleSubmissionErrors(submitErrors);
            } else if (handleErrorsOnSubmit) {
              if (typeof submitErrors === 'string') {
                addMessage(new ErrorMessage(submitErrors as string));
              } else {
                handleErrorsOnSubmit(submitErrors);
              }
            } else {
              addMessage(new ErrorMessage('Something went wrong.'));
            }
          }
        })
        .finally(() => {
          setSubmitEnabled(true);
        });
    }
  };

  const setFormData = (formData: FormFieldsData) => {
    // Fix display and data validation in update form
    flushSync(() => setData(formData));
  };

  const resetFormData = (newData: FormFieldsData = defaultInitialData) => {
    setFormData(newData);
    setIsLoadingArtifact(false);
    setFormErrors({});
    setDirty(false);
  };

  /**
   * Allows to add callback that will be called with all the validation errors codes from backend.
   */
  const addValidationErrorListener = (callback: ErrorListener) => {
    setErrorListeners((listeners: ErrorListener[]) => [...listeners, callback]);
  };

  const removeValidationErrorListener = (callback: ErrorListener) => {
    setErrorListeners((listeners: ErrorListener[]) => listeners.filter(cb => cb !== callback));
  };

  return {
    data,
    formErrors: mapFormErrors(formErrors),
    addFormError,
    onChangeCallback,
    setFieldErrors,
    setFormData,
    fieldsExceptions,
    submitForm,
    setFormErrors,
    validationSchema,
    isSubmitEnabled,
    setSubmitEnabled,
    collectionOnChangeCallback,
    isDirty,
    addValidationErrorListener,
    removeValidationErrorListener,
    isCreateRequest,
    isLoadingArtifact,
    setIsLoadingArtifact,
    validateForm,
  };
};

export default useForm;
