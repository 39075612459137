/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import OneStopHistoryPage from 'myActivity/history/List';
import { Router, Route } from 'shared/routing';
import { IsMyActivityEnabled, MY_ACTIVITY_ROOT_PATH, useMyActivityRoute } from 'myActivity/MyActivityRouterHelper';
import SentFeedbackPage from './feedback/List';
import { ProtectedRouter } from '../routing';

const MyActivityRouter: Router = () => {
  const { historyRoute, feedbackRoute } = useMyActivityRoute();

  return (
    <ProtectedRouter basePath={MY_ACTIVITY_ROOT_PATH}>
      <Routes>
        <Route path={historyRoute} element={<OneStopHistoryPage />} />
        {IsMyActivityEnabled() ? <Route path={feedbackRoute} element={<SentFeedbackPage />} /> : null}
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

MyActivityRouter.getRouterPath = () => `${MY_ACTIVITY_ROOT_PATH}/*`;

export default MyActivityRouter;
