/* istanbul ignore file */
import Badge from 'shared/uibuilder/badge/Badge';
import React from 'react';

const ExcludedFromBalanceBadge = (props: { isVisible?: boolean | ((data: any) => boolean) }) => (
  <Badge {...props} className="ml-2" color="primary">
    Excluded from balance
  </Badge>
);

export default ExcludedFromBalanceBadge;
