import React from 'react';
import Grid from 'uibuilder/Grid';
import Box from 'uibuilder/Box';
import TextField from 'shared/uibuilder/field/TextField';
import Typography from 'uibuilder/Typography';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';
import { BUDGET_OBJECTIVE_UNIT_OPTIONS } from '../../budgetVersionService';
import InputList from 'shared/uibuilder/form/input/InputList/InputList';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const CategoriesInputTemplate = (props: any) => {
  const { getSource, getValue } = useInputHelper(props);
  const source = getSource();
  const categories: any = getValue() || {};

  return (
    <>
      <Box flex={{ xs: 0.4, sm: 0.2 }}>
        <TextField value={categories.name} disabled label="Name" fullWidth />
      </Box>
      <Box flex={0.5} ml={2}>
        <NumberInput label="Amount" source={`${source}.amount`} isRequired />
      </Box>
    </>
  );
};

const ObjectivesInputTemplate = (props: any) => {
  const { getSource, getValue } = useInputHelper(props);
  const source = getSource();
  const objective: any = getValue() || {};

  return (
    <>
      <Box flex={{ xs: 0.4, sm: 0.2 }}>
        <TextField value={objective.name} disabled label="Name" fullWidth />
      </Box>
      <Box flex={0.5} ml={2}>
        <NumberInput label="Value" source={`${source}.value`} isRequired />
      </Box>
      <Box mt="30px" ml={1}>
        <Typography>{BUDGET_OBJECTIVE_UNIT_OPTIONS[objective.unit]}</Typography>
      </Box>
    </>
  );
};

const BudgetDetailsSection = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Box>
          <>
            <Typography variant="h4" mb="20px">
              Categories
            </Typography>
            <InputList
              source="categories"
              inputTemplate={CategoriesInputTemplate}
              isAddable={false}
              isDeletable={false}
            />
          </>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <>
          <Typography variant="h4" mb="20px">
            Objectives
          </Typography>
          <InputList
            source="objectives"
            inputTemplate={ObjectivesInputTemplate}
            isAddable={false}
            isDeletable={false}
          />
        </>
      </Grid>
    </Grid>
  );
};

export default BudgetDetailsSection;
