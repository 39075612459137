/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_SYBERRY_TODAY, SYBERRY_TODAY_PATH } from 'syberryToday/useSyberryTodayService';
import SyberryTodayList from 'syberryToday/List';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import { SyberryTodayProjectProjectsRouter } from 'syberryToday/projects';

export const useSyberryTodayRoute = () => useRoute({ listRoute: SYBERRY_TODAY_PATH });

export const useSyberryTodayUrl = () =>
  useEntityUrl({
    baseLink: SYBERRY_TODAY_PATH,
  });

export const useSyberryTodayId = () => useParams<Dictionary<string>>().id!;

const SyberryTodayRouter = () => {
  const { listRoute } = useSyberryTodayRoute();

  return (
    <ProtectedRouter basePath={SYBERRY_TODAY_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_SYBERRY_TODAY}>
              <SyberryTodayList />
            </GlobalProtectedRoute>
          }
        />
        <Route
          path={SyberryTodayProjectProjectsRouter.getRouterPath()}
          element={<SyberryTodayProjectProjectsRouter />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

SyberryTodayRouter.getRouterPath = () => `${SYBERRY_TODAY_PATH}/*`;

export default SyberryTodayRouter;
