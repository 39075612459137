/* istanbul ignore file */
import React, { useContext } from 'react';
import { Timezone } from '../../authentication/authenticatedUserService';

export interface ApplicationTheme<T extends ReactComponent<any>> {
  [index: string]: T;
}

interface ApplicationConfig {
  defaultPageTitle?: string;
  getTimezone: () => string | Promise<Nullable<Timezone>>;
  theme: ApplicationTheme<any>;
}

const ApplicationSettingsContext = React.createContext<Partial<ApplicationConfig>>({});

export const ApplicationSettingsProvider = ApplicationSettingsContext.Provider;

const useApplicationSettings = () => useContext(ApplicationSettingsContext);

interface ApplicationSettingsProps {
  defaultPageTitle?: string;
  getTimezone: () => string | Promise<Nullable<Timezone>>;
  theme: ApplicationTheme<any>;
  children: React.ReactNode;
}

export const ApplicationSettings: React.FC<ApplicationSettingsProps> = ({
  theme,
  getTimezone = undefined,
  children,
}) => {
  return (
    <ApplicationSettingsProvider
      value={{
        theme,
        getTimezone,
        defaultPageTitle: 'DaVinci',
      }}
    >
      {children}
    </ApplicationSettingsProvider>
  );
};

export default useApplicationSettings;
