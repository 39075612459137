/* istanbul ignore file */
import React from 'react';
import Box from 'uibuilder/Box';
import Container from '@mui/material/Container';
import DefaultFooter from 'layout/Default/DefaultFooter';

const PublicLayout = ({ children = '' }: { children?: React.ReactNode }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', main: { flexGrow: 1 } }}>
    <main className="main">
      <Container sx={{ paddingTop: '15px' }} className="animated fadeIn">
        {children}
      </Container>
    </main>
    <DefaultFooter />
  </Box>
);

export default PublicLayout;
