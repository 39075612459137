/* istanbul ignore file */
import React from 'react';

import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import useCrmActivityItem from 'crm/shared/timeline/activity/crmActivityRegistry';
import useCrmNoteItem from 'crm/shared/timeline/note/crmNoteRegistry';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import ShowLeadPageMenu from 'crm/lead/ShowLeadPageMenu';
import LeadEntityHeader from 'crm/lead/LeadEntityHeader';
import { useParams } from 'react-router-dom';
import useLeadService from 'crm/lead/shared/leadService';
import Show from 'shared/uibuilder/show/Show';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import DateTimeRangeFilter from 'shared/uibuilder/list/filter/DateTimeRangeFilter';
import CrmTimelineEntryTypeFilter from 'crm/shared/timeline/filter/CrmTimelineEntryTypeFilter';
import LeadTimelineBreadcrumbs from 'crm/lead/Timeline/Breadcrumbs';

const TimelinePage = () => {
  const { id: leadId = '' } = useParams<Dictionary<string>>();
  const { getById, searchTimelineItems, createTimelineItem, getTimelinePageById, getFilterValidationSchema } =
    useLeadService();

  const activityRegistry = useCrmActivityItem();
  const noteRegistry = useCrmNoteItem();

  const filters = (
    <Filters getValidationSchema={getFilterValidationSchema}>
      <FiltersLayout>
        <CrmTimelineEntryTypeFilter label="Entry Type" source="type" />
        <DateTimeRangeFilter label="Created date" source="createdAt" />
      </FiltersLayout>
    </Filters>
  );
  return (
    <Show getDataMethod={() => getById(leadId)}>
      <Timeline
        registries={[noteRegistry, activityRegistry] as any}
        getDataMethod={request => searchTimelineItems(leadId, request)}
        createTimelineItemMethod={(itemType, request) => createTimelineItem(leadId, itemType, request) as any}
        getPageByEntryIdMethod={id => getTimelinePageById(leadId, id)}
      >
        <TimelinePageLayout
          menu={<ShowLeadPageMenu />}
          breadcrumbs={<LeadTimelineBreadcrumbs />}
          entityHeader={<LeadEntityHeader />}
          filter={filters}
        >
          <TimelineLayout noEntriesMessage="No entries in this lead timeline yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default TimelinePage;
