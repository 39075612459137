/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { FE_UPDATE_EMPLOYEE_CONTRACT } from 'erp/employee/contracts/shared/employeeContractsService';
import { useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';

const EditEmployeeContractButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useEmployeeContractUrl();

  return (
    <EditButton {...props} outline permissionToCheck={FE_UPDATE_EMPLOYEE_CONTRACT} path={getUpdateEntityUrl}>
      Edit
    </EditButton>
  );
};

export default EditEmployeeContractButton;
