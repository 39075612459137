/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import { TextField, EnumField, DateField } from 'shared/uibuilder/field';
import {
  PURCHASE_ORDER_STATUSES_OPTIONS,
  PURCHASE_ORDER_STATUSES,
  PURCHASE_ORDER_TYPES,
} from 'financialAnalytic/purchaseOrder/usePurchaseOrderService';
import PurchaseOrderHeader from 'financialAnalytic/purchaseOrder/shared/PurchaseOrderHeader';
import RejectPurchaseOrderButton from 'financialAnalytic/purchaseOrder/shared/button/RejectPurchaseOrderButton';
import CopyPurchaseOrderButton from 'financialAnalytic/purchaseOrder/shared/button/CopyPurchaseOrderButton';
import PurchaseOrderTypeField from 'financialAnalytic/purchaseOrder/shared/field/PurchaseOrderTypeField';
import ApprovePurchaseOrderButton from 'financialAnalytic/purchaseOrder/shared/button/ApprovePurchaseOrderButton';
import ClosePurchaseOrderButton from 'financialAnalytic/purchaseOrder/shared/button/ClosePurchaseOrderButton';
import EditPurchaseOrderButton from 'financialAnalytic/purchaseOrder/shared/button/EditPurchaseOrderButton';
import SinglePurchaseOrderBreadcrumbs from 'financialAnalytic/purchaseOrder/shared/SinglePurchaseOrderBreadcrumbs';
import TransfersListField from 'financialAnalytic/shared/field/TransfersListField';
import { BUSINESS_OBJECT_TYPES } from 'financialAnalytic/ledger/useLedgerService';
import { usePurchaseOrderId } from 'financialAnalytic/purchaseOrder/PurchaseOrderRouter';
import PurchaseOrderTotalField from 'financialAnalytic/purchaseOrder/shared/field/PurchaseOrderTotalField';
import PurchasesListField from 'financialAnalytic/purchaseOrder/shared/field/PurchasesListField';
import InvoicesListField from 'financialAnalytic/purchaseOrder/shared/field/InvoicesListField';
import LedgerLink from 'financialAnalytic/ledger/shared/field/LedgerLink';
import OfficeField from 'financialAnalytic/shared/field/OfficeField';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';

const ViewPurchaseOrderPageContent = ({
  isSensitiveDataLoading = false,
  isSensitiveDataIsShown = false,
  toggleSensitiveDataButton = <></>,
}: {
  isSensitiveDataLoading?: boolean;
  isSensitiveDataIsShown?: boolean;
  toggleSensitiveDataButton?: React.ReactElement;
}) => {
  const id = usePurchaseOrderId();

  return (
    <ShowPageLayout
      entityHeader={<PurchaseOrderHeader />}
      breadcrumbs={<SinglePurchaseOrderBreadcrumbs />}
      headerButtons={[
        toggleSensitiveDataButton,
        <EditPurchaseOrderButton
          isVisible={(data: any) => data.getValueBySource('status') === PURCHASE_ORDER_STATUSES.PENDING}
        />,
        <CopyPurchaseOrderButton />,
        <ApprovePurchaseOrderButton statusSource="status" />,
        <ClosePurchaseOrderButton statusSource="status" />,
        <RejectPurchaseOrderButton statusSource="status" />,
      ]}
      hasSeparateSections
    >
      <ShowSection title="Main information">
        <SectionRow>
          <TextField label="Title" source="title" />
          <TextField label="Vendor" source="vendor" />
          <PurchaseOrderTypeField label="Type" source="type" />
          <OfficeField source="office" />
        </SectionRow>
        <SectionRow>
          <LedgerLink label="Account" source="account" nameSource="account" />
          <TextField source="budgetCategory" nameSource="budgetCategory" label="Budget Category" />
          <PurchaseOrderTotalField label="Total" source="subTotal" isVisible={isSensitiveDataIsShown} />
          <DateField
            label="Required Delivery Date"
            source="requiredDeliveryDate"
            isVisible={(data: any) => data.getValueBySource('type') === PURCHASE_ORDER_TYPES.STANDARD}
          />
          <DateField
            source="expirationDate"
            label="Expiration Date"
            isVisible={(data: any) =>
              data.getValueBySource('type') === PURCHASE_ORDER_TYPES.BLANKET ||
              data.getValueBySource('type') === PURCHASE_ORDER_TYPES.CONTRACT
            }
          />
        </SectionRow>
        <SectionRow>
          <EnumField label="Status" source="status" options={PURCHASE_ORDER_STATUSES_OPTIONS} />
          <TextField
            label="Reject reason"
            source="rejectReason"
            isVisible={(data: any) => data.getValueBySource('status') === PURCHASE_ORDER_STATUSES.REJECTED}
          />
          <EmployeeLinkFieldWithNameLoading source="requestor" label="Requestor" />
          <EmployeeLinkFieldWithNameLoading
            source="approvedBy"
            label="Approved By"
            isVisible={(data: any) => !!data.getValueBySource('approvedBy')}
          />
          <EmployeeLinkFieldWithNameLoading
            source="rejectedBy"
            label="Rejected By"
            isVisible={(data: any) => !!data.getValueBySource('rejectedBy')}
          />
        </SectionRow>
        <BigSectionRow>
          <TextField label="Purchase Purpose" source="purchasePurpose" />
        </BigSectionRow>
      </ShowSection>
      <ShowSection title="Purchases">
        <PurchasesListField source="purchases" isSensitiveDataIsShown={isSensitiveDataIsShown} />
      </ShowSection>
      <ShowSection>
        <InvoicesListField
          source="invoices"
          isSensitiveDataIsShown={isSensitiveDataIsShown || isSensitiveDataLoading}
        />
      </ShowSection>
      <ShowSection isVisible={isSensitiveDataIsShown || isSensitiveDataLoading}>
        <TransfersListField
          businessObjectId={id}
          businessObjectType={BUSINESS_OBJECT_TYPES.PURCHASE_ORDER}
          startDateSource="postedAt"
        />
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewPurchaseOrderPageContent;
