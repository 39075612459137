/* istanbul ignore file */
import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import InstantFeedbackForm from 'instantFeedback/createupdate/InstantFeedbackForm';
import useInstantFeedbackService from 'instantFeedback/InstantFeedbackService';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import OneStopModalDecorator from 'oneStop/Create/OneStopModalDecorator';
import { useOneStopRequestsUrl } from 'oneStop/OneStopRouterHelper';
import { useNavigate } from 'react-router-dom';

const CreateInstantFeedback = () => {
  const { create, getValidationSchema } = useInstantFeedbackService();
  const { getListUrl } = useOneStopRequestsUrl();
  const navigate = useNavigate();

  const redirectToOneStopRequests = () => navigate(getListUrl());

  return (
    <OneStopModalDecorator
      isOneStop={false}
      messageContext="feedbackForm"
      handleMessageModalClose={redirectToOneStopRequests}
    >
      <CreateForm
        submitFormFunc={create}
        getValidationSchemaFunc={getValidationSchema}
        initialData={{
          isSharedWithEmployee: false,
        }}
      >
        <CreatePageLayout title="Feedback about colleague" isCancelButtonVisible={false}>
          <InstantFeedbackForm isPageForm />
        </CreatePageLayout>
      </CreateForm>
    </OneStopModalDecorator>
  );
};

export default CreateInstantFeedback;
