/* istanbul ignore file */
import React from 'react';
import TransferActivityFormSection from 'crm/shared/timeline/activity/form/TransferActivityFormSection';
import TransferTimelineRecordToAnotherEntityButton from 'uibuilder/layout/timeline/buttons/TransferTimelineRecordToAnotherEntityButton';
import useMessageService, { SuccessMessage } from 'shared/message/messageService';
import { ENTITY_TYPE_ALIAS } from 'crm/shared/timeline/activity/shared/transferActivityService';
import { LEADS_PATH } from 'crm/lead/LeadRouter';
import { ACCOUNT_PATH } from 'crm/account/AccountRouter';
import { OPPORTUNITY_PATH } from 'crm/opportunity/OpportunityRouter';
import useCrmActivityItem from 'crm/shared/timeline/activity/crmActivityRegistry';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { ValidationSchema } from 'validation-schema-library';
import { Link } from 'react-router-dom';

type TransferCrmActivityToAnotherEntityButtonProps = {
  permissionToCheck: Nullable<string | string[]>;
  submitFormFunc: (values: FormFieldsData) => Promise<object | void>;
  timelineId: string;
  modalTitle: string;
  getValidationSchemaFunc?: () => Promise<ValidationSchema>;
  additionalAfterSubmit?: (response: any) => void;
  isApplicable: boolean;
};

const TransferCrmActivityToAnotherEntityButton = ({
  isApplicable,
  ...props
}: TransferCrmActivityToAnotherEntityButtonProps) => {
  const { addMessage } = useMessageService();
  const { urlPostfix } = useCrmActivityItem();

  if (!isApplicable) return null;

  const getPath = (entityType: string, entityId: string, entryId: string) => {
    let basePath = '';
    if (entityType === ENTITY_TYPE_ALIAS.LEAD) {
      basePath = LEADS_PATH;
    } else if (entityType === ENTITY_TYPE_ALIAS.ACCOUNT) {
      basePath = ACCOUNT_PATH;
    } else if (entityType === ENTITY_TYPE_ALIAS.OPPORTUNITY) {
      basePath = OPPORTUNITY_PATH;
    }
    return `${basePath}/${entityId}/${urlPostfix}/${entryId}`;
  };

  return (
    <TransferTimelineRecordToAnotherEntityButton
      additionalAfterSubmit={response => {
        const path = getPath(response.entityType, response.entityId, response.timelineId);
        addMessage(
          new SuccessMessage(
            (
              <>
                You awe awesome! CRM activity has been successfully transfer to another entity.
                <Link to={path} className="ml-2" target="_blank">
                  Please, check new location.
                </Link>
              </>
            ),
          ),
        );
      }}
      {...props}
    >
      <TransferActivityFormSection />
    </TransferTimelineRecordToAnotherEntityButton>
  );
};

export default TransferCrmActivityToAnotherEntityButton;
