/* istanbul ignore file */
import React, { useCallback, useEffect, useState } from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { Checkbox, Dropdown, TextInput } from 'shared/uibuilder/form/input';
import BudgetInput from 'erp/budget/shared/input/BudgetInput';
import BudgetsInput from 'erp/budget/shared/input/BudgetsInput';
import useFeatureToggle, { Features } from 'featuretoggle';
import AccountNameInput from 'crm/account/shared/input/AccountNameInput';
import ArtifactSowInput from 'artifactStorage/shared/input/ArtifactSowInput';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import GenerateCodeNameButton from 'erp/codename/button/GenerateCodeNameButton';
import ProjectInput from 'erp/project/shared/input/ProjectInput';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';

export enum NEW_PROJECT_TYPES {
  NEW = 'NEW',
  EXISTENT = 'EXISTENT',
}

export const NEW_PROJECT_TYPES_LABELS = {
  [NEW_PROJECT_TYPES.NEW]: 'New',
  [NEW_PROJECT_TYPES.EXISTENT]: 'Existent',
};

const ProjectForm = () => {
  const { data = {}, setFormData = () => {} } = useFormContext();
  const viewBudgetsEnabled = useFeatureToggle().isFeatureEnabled(Features.VIEW_BUDGETS);
  const viewSowFieldEnabled = useFeatureToggle().isFeatureEnabled(Features.LINK_SOW_WITH_REDMINE_PROJECT);
  const forbidProjectAssignmentsEnabled = useFeatureToggle().isFeatureEnabled(Features.FORBID_PROJECT_ASSIGNMENTS);

  const [isSowSpecified, setSowSpecified] = useState(false);
  const isCreateForm = !data.id;

  const getStaffingCoordinatorLabel = () => {
    const employee = data?.staffingCoordinator;

    if (!employee) {
      return undefined;
    }

    const alias = employee.alias || '';
    const firstName = employee.nameEn?.firstName || '';
    const lastName = employee.nameEn?.lastName || '';

    return `${alias} ${firstName} ${lastName}`;
  };

  const onChangeCallback = useCallback(
    (source: string) => (value: any) => {
      const newData = {
        ...data,
        ...value,
      };

      if (['accountId', 'projectType', 'parentProjectId'].includes(source)) {
        newData.name = '';
      }

      if (
        newData.projectType === NEW_PROJECT_TYPES.NEW &&
        newData.accountId &&
        newData.hasAccountCodeName &&
        !newData.accountHasProjects
      ) {
        newData.name = newData.accountName;
      }

      if (
        newData.projectType === NEW_PROJECT_TYPES.EXISTENT &&
        newData.parentProject &&
        newData.parentProject.hasCodeName
      ) {
        newData.name = newData.parentProject.name;
      }

      setFormData(newData);
    },
    [data, setFormData],
  );

  const isButtonDisplayed = () => {
    if (data.redmineAlias) {
      return false;
    }

    if (!isCreateForm) {
      return data.projectType === NEW_PROJECT_TYPES.NEW || !data.name;
    }

    if (data.projectType === NEW_PROJECT_TYPES.NEW) {
      if (data.accountId) {
        if (data.hasAccountCodeName && !data.accountHasProjects) {
          return false;
        }
      }
    }

    if (data.projectType === NEW_PROJECT_TYPES.EXISTENT) {
      if (data.parentProject && data.parentProject.hasCodeName) {
        return false;
      }
    }

    return isCreateForm;
  };

  const setName = (newName: string) => {
    setFormData({
      ...data,
      name: newName,
    });
  };

  useEffect(() => {
    setSowSpecified(!!data.sow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormSection title="Project information">
      <FormRow>
        <TextInput source="originalName" label="Original Name" />
        <TextInput source="taskTrackerAlias" label="Redmine Project Alias" />
        <TextInput source="name" label="Code Name" disabled={data.redmineAlias} />
        <div style={{ marginTop: '24px' }}>
          <GenerateCodeNameButton source="name" isVisible={isButtonDisplayed()} setCodeNameMethod={setName} />
        </div>
      </FormRow>
      <FormRow>
        {isSowSpecified ? (
          <TextInput source="accountName" label="Account" disabled />
        ) : (
          <AccountNameInput
            source="accountId"
            label="Account"
            accountNameValue=""
            accountNameSource="accountName"
            required
            onChangeCallback={onChangeCallback('accountId')}
          />
        )}
        <ArtifactSowInput
          source="sow.id"
          label="SOW"
          isVisible={viewSowFieldEnabled}
          errorSource="sow"
          showMetaInfo
          disabled={isSowSpecified}
        />
        <Dropdown
          source="projectType"
          label="Project Type"
          isVisible={isCreateForm}
          options={Object.entries(NEW_PROJECT_TYPES_LABELS).map(entry => {
            const [value, label] = entry;
            return {
              value,
              label,
            };
          })}
          onChangeCallback={onChangeCallback('projectType')}
        />
        <ProjectInput
          mapResults={(project: any) => {
            return {
              id: project.id,
              text: project.name,
              parentProject: project,
            };
          }}
          source="parentProjectId"
          label="Project"
          nameSource="parentProjectName"
          searchString="name"
          isVisible={isCreateForm && data.projectType === NEW_PROJECT_TYPES.EXISTENT}
          defaultFilter={{
            assignmentForbidden: {
              eq: false,
            },
          }}
          onChangeCallback={onChangeCallback('parentProjectId')}
        />
      </FormRow>
      <FormRow>
        <BudgetInput
          isVisible={viewBudgetsEnabled}
          source="defaultBudgetId"
          nameSource="defaultBudget.name"
          label="Default Budget"
          searchString="name"
          filter={{ defaultableBudget: true, notArchived: true }}
        />
        <BudgetsInput
          filter={{ defaultableBudget: true, notArchived: true }}
          source="availableBudgets"
          isVisible={viewBudgetsEnabled}
        />
      </FormRow>
      <FormRow>
        <EmployeeInput
          source="staffingCoordinatorAlias"
          label="Staffing Coordinator"
          initialLabel={getStaffingCoordinatorLabel()}
        />
      </FormRow>
      <FormRow>
        <Checkbox
          source="assignmentForbidden"
          label="Do not allow assignment creation"
          isVisible={forbidProjectAssignmentsEnabled}
        />
      </FormRow>
    </FormSection>
  );
};

export default ProjectForm;
