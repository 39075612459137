import React, { useEffect, useState } from 'react';
import useOfficeService, { OFFICE_NAMES } from 'erp/employee/office/officeService';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';

export const ANYWHERE_OFFICE_NAME = 'Full-Remote';

const OfficeDropdown = (props: any) => {
  const { additionalValues, mapResults, filterOptions, isAnywhereAvailable } = props;
  const { findAll } = useOfficeService();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const offices = await findAll();
      const filteredOffices = isAnywhereAvailable
        ? offices
        : offices.filter((office: any) => {
            return office.name !== ANYWHERE_OFFICE_NAME;
          });

      let data = filteredOffices.map(
        mapResults ||
          ((office: any) => ({
            value: office.id.toString(),
            label: OFFICE_NAMES[office.name],
          })),
      );
      if (additionalValues) {
        data = data.concat(additionalValues);
      }
      setOptions(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dropdown
      placeholder="Select office"
      {...props}
      options={filterOptions ? options.filter(filterOptions) : options}
    />
  );
};

export default OfficeDropdown;
