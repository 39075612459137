import React from 'react';

import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { CommonInputLayoutProps, SizeableLayout, DefaultInputPropTypes, Sizeable } from 'shared/uibuilder/form/input';

interface TextAreaProps extends DefaultInputPropTypes<string>, Sizeable {}

export interface TextAreaLayoutProps extends CommonInputLayoutProps, SizeableLayout {}

export type TextAreaLayoutType = ReactComponent<TextAreaLayoutProps>;

/**
 * TextArea is used for entering of text.
 *
 * @param props.inputLabel label
 * @param props.source source
 * @param props.validationCallback called each time user selects the value
 * @param props.inputValue value to display
 * @param props.inputOnChangeCallback callback will be called when the value of component is changed *
 * @param props.isVisibleInput defined if the input should be displayed
 *
 * @returns {*}
 * @constructor
 */
const TextArea = (props: TextAreaProps): any => {
  const inputHelper = useInputHelper<string>(props);
  const { TextAreaLayout } = useUiTheme<TextAreaLayoutType>();

  return (
    <TextAreaLayout
      isVisible={inputHelper.isVisible()}
      numberOfRows={inputHelper.getNumberOfRows()}
      label={inputHelper.getLabel()}
      source={inputHelper.getSource()}
      onChangeCallback={inputHelper.getOnChangeCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      value={inputHelper.getValue()}
      errorMessages={inputHelper.getErrorMessages()}
      isRequired={inputHelper.getIsRequired()}
      tooltip={inputHelper.getTooltip()}
      helpText={inputHelper.getHelpText()}
      disabled={inputHelper.getIsDisabled()}
      labelHint={inputHelper.getLabelHint()}
      placeholder={inputHelper.getPlaceholder()}
      className={inputHelper.getClassName()}
    />
  );
};

export default TextArea;
