import React from 'react';

const CalendarR: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 9.25C2.80109 9.25 2.61032 9.32902 2.46967 9.46967C2.32902 9.61032 2.25 9.80109 2.25 10V19C2.25 19.7293 2.53973 20.4288 3.05546 20.9445C3.57118 21.4603 4.27065 21.75 5 21.75H16C16.1988 21.7498 16.3895 21.6707 16.53 21.53L16.81 21.25H15.992C15.3953 21.25 14.823 21.0129 14.401 20.591C13.9791 20.169 13.742 19.5967 13.742 19C13.742 18.4033 13.9791 17.831 14.401 17.409C14.823 16.9871 15.3953 16.75 15.992 16.75H21.311L21.531 16.53C21.6713 16.3893 21.7501 16.1987 21.75 16V10C21.75 9.80109 21.671 9.61032 21.5303 9.46967C21.3897 9.32902 21.1989 9.25 21 9.25H3Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1.25C7.19891 1.25 7.38968 1.32902 7.53033 1.46967C7.67098 1.61032 7.75 1.80109 7.75 2V6C7.75 6.19891 7.67098 6.38968 7.53033 6.53033C7.38968 6.67098 7.19891 6.75 7 6.75C6.80109 6.75 6.61032 6.67098 6.46967 6.53033C6.32902 6.38968 6.25 6.19891 6.25 6V4.75H5C4.31 4.75 3.75 5.31 3.75 6V10C3.75 10.1989 3.67098 10.3897 3.53033 10.5303C3.38968 10.671 3.19891 10.75 3 10.75C2.80109 10.75 2.61032 10.671 2.46967 10.5303C2.32902 10.3897 2.25 10.1989 2.25 10V6C2.25 5.27065 2.53973 4.57118 3.05546 4.05546C3.57118 3.53973 4.27065 3.25 5 3.25H6.25V2C6.25 1.80109 6.32902 1.61032 6.46967 1.46967C6.61032 1.32902 6.80109 1.25 7 1.25ZM15 1.25C15.1989 1.25 15.3897 1.32902 15.5303 1.46967C15.671 1.61032 15.75 1.80109 15.75 2V6C15.75 6.19891 15.671 6.38968 15.5303 6.53033C15.3897 6.67098 15.1989 6.75 15 6.75C14.8011 6.75 14.6103 6.67098 14.4697 6.53033C14.329 6.38968 14.25 6.19891 14.25 6V4.75H10.5C10.3011 4.75 10.1103 4.67098 9.96967 4.53033C9.82902 4.38968 9.75 4.19891 9.75 4C9.75 3.80109 9.82902 3.61032 9.96967 3.46967C10.1103 3.32902 10.3011 3.25 10.5 3.25H14.25V2C14.25 1.80109 14.329 1.61032 14.4697 1.46967C14.6103 1.32902 14.8011 1.25 15 1.25ZM17.75 4C17.75 3.80109 17.829 3.61032 17.9697 3.46967C18.1103 3.32902 18.3011 3.25 18.5 3.25H19C19.7293 3.25 20.4288 3.53973 20.9445 4.05546C21.4603 4.57118 21.75 5.27065 21.75 6V10C21.75 10.1989 21.671 10.3897 21.5303 10.5303C21.3897 10.671 21.1989 10.75 21 10.75C20.8011 10.75 20.6103 10.671 20.4697 10.5303C20.329 10.3897 20.25 10.1989 20.25 10V6C20.25 5.31 19.69 4.75 19 4.75H18.5C18.3011 4.75 18.1103 4.67098 17.9697 4.53033C17.829 4.38968 17.75 4.19891 17.75 4Z"
    />
    <path d="M16 17V24H18V21.2H18.8L20 24H22L20.76 21.095C21.5 20.885 22 20.388 22 19.8V18.4C22 18.0287 21.7893 17.6726 21.4142 17.4101C21.0391 17.1475 20.5304 17 20 17H16ZM18 18.4H20V19.8H18V18.4Z" />
  </svg>
);

export default CalendarR;
