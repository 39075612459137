/* istanbul ignore file */
import React from 'react';

const EmployeeContractEndDateLayout = ({
  dateField,
  notification,
}: {
  dateField?: React.ReactNode;
  notification?: React.ReactNode;
}) => {
  return (
    <div className="d-flex align-items-center">
      {dateField}
      {notification}
    </div>
  );
};

export default EmployeeContractEndDateLayout;
