/* istanbul ignore file */
import React from 'react';
import DropDownFilter, { DropDownFilterProps } from 'shared/uibuilder/list/filter/DropDownFilter';
import CandidateApplicationSourceDropdown from 'erp/candidate/applications/shared/input/CandidateApplicationSourceDropdown';

const CandidateApplicationSourceFilter = ({ source, ...props }: Omit<DropDownFilterProps, 'children'>) => {
  return (
    <DropDownFilter source={source} {...props}>
      <CandidateApplicationSourceDropdown source={source} />
    </DropDownFilter>
  );
};

export default CandidateApplicationSourceFilter;
