import React from 'react';
import { getVisibility } from 'shared/uibuilder/helper';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

interface FormInputGroupProps {
  isVisible?: boolean | ((data: any) => boolean);
  children: React.ReactElement | React.ReactElement[];
}

const FormInputGroup = ({ isVisible, children }: FormInputGroupProps) => {
  const { data } = useFormContext();

  const isSectionsVisible = getVisibility(data, isVisible);

  return isSectionsVisible && children;
};

export default FormInputGroup;
