/* istanbul ignore file */
import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';
import { Area } from 'erp/qualification/model/qualificationModelService';

const CompetencyAreaBadge = ({ area }: { area: Area }) => {
  return (
    <Badge
      color="neutralLight"
      className="mr-1"
      sx={{
        background: (theme: any) => theme.vars.palette.warning[50],
        color: (theme: any) => (theme.vars.palette.neutral as any)[0],
      }}
    >
      {area.name}
    </Badge>
  );
};

export default CompetencyAreaBadge;
