/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useContactService from 'crm/contact/shared/contactService';

export const CONTACT_CONTEXT = 'Contact';

const ContactContext = ({
  permissionToCheck,
  children,
}: {
  permissionToCheck: string | string[];
  children: React.ReactNode;
}) => {
  const { getPermissions } = useContactService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={CONTACT_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default ContactContext;
