import { DateInput } from 'shared/uibuilder/form/input';
import EqCondition, { EQ_CONDITION_TYPE } from 'shared/uibuilder/list/filter/condition/EqCondition';
import React from 'react';
import moment from 'moment';
import { FilterCondition, useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import usePayrollService, { PAYROLL_PERIOD_FORMAT } from 'erp/payroll/shared/payrollService';
import { string } from 'prop-types';

const FILTER_ERROR = 'Please, choose the valid period in the past';

const PayrollPeriodFilter = ({ source }: { source: string }) => {
  const { filters, getFilterErrors, setFilterErrors } = useFilterContext();
  const { getMaximalAvailablePeriod } = usePayrollService();

  const validateAndShowErrorMessages = () => {
    const inputValue = filters && filters[source] && filters[source][EQ_CONDITION_TYPE];
    const date = moment(inputValue, PAYROLL_PERIOD_FORMAT, undefined, true);

    const isValid = inputValue && date.isValid() && date.isSameOrBefore(getMaximalAvailablePeriod());

    const showErrors = !isValid;
    if (showErrors) {
      setFilterErrors(source, EQ_CONDITION_TYPE as FilterCondition, [FILTER_ERROR]);
    } else {
      setFilterErrors(source, EQ_CONDITION_TYPE as FilterCondition, []);
    }
    return showErrors;
  };

  const getErrors = () => {
    return getFilterErrors(source, EQ_CONDITION_TYPE as FilterCondition);
  };

  const maxDate = moment(getMaximalAvailablePeriod(), PAYROLL_PERIOD_FORMAT).endOf('month');

  return (
    <EqCondition source={source}>
      <DateInput
        isRequired
        dateFormat={PAYROLL_PERIOD_FORMAT}
        validationCallback={validateAndShowErrorMessages}
        errorMessages={getErrors()}
        maxDate={maxDate}
        source={source}
      />
    </EqCondition>
  );
};

PayrollPeriodFilter.propTypes = {
  source: string.isRequired,
};

export default PayrollPeriodFilter;
