import React from 'react';
import { VacancyPriority, VacancyPriorityValues } from 'erp/recruitment/recruitingBoard/constants';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import Badge from 'shared/uibuilder/badge/Badge';

export const VacancyPriorityColor = {
  [VacancyPriorityValues[VacancyPriority.High]]: 'error' as BasicColors,
  [VacancyPriorityValues[VacancyPriority.Medium]]: 'warning' as BasicColors,
  [VacancyPriorityValues[VacancyPriority.Low]]: 'success' as BasicColors,
};

interface PriorityBadgeProps extends FieldProps {
  prioritySource: string;
}

const PriorityBadge = ({ prioritySource }: PriorityBadgeProps) => {
  const { getValue: getPriority } = useFieldHelper({ source: prioritySource });
  const priority = getPriority();

  return (
    <Badge color={VacancyPriorityColor[priority]} isVisible={entity => !entity.isLegacy}>
      {priority}
    </Badge>
  );
};

export default PriorityBadge;
