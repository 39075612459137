import { useKernelApi } from 'api';
import { OFFICE_NAMES } from 'erp/employee/office/officeService';

export const READ_LEAVES_BALANCES = 'READ_LEAVES_BALANCES';

const useLeaveBalanceService = () => {
  const { sendGetRequest } = useKernelApi();

  const isExtended = (actualEndDate: any, endDate: any) => actualEndDate !== endDate;

  const isComposite = (
    totalBalance: number,
    remainedBalance: number,
    debt: number,
    officeChanged: boolean,
    bidGap: any,
  ) => {
    return remainedBalance > totalBalance || remainedBalance < 0 || (!!debt && !officeChanged && !bidGap);
  };

  const mapPaidLeavesYears = (leaveYears: any[]) => {
    let balancePerYearForPaidLeaves = 0;
    let debtForPaidLeaves = 0;

    const getPaidLeaveBalance = (duration: number, officeChanged: boolean, bigGap: any) => {
      let result;

      if (balancePerYearForPaidLeaves >= 0 && (!debtForPaidLeaves || officeChanged || bigGap)) {
        result = `-${duration}`;
      } else if (balancePerYearForPaidLeaves < 0 && !debtForPaidLeaves) {
        result = officeChanged || bigGap ? `-${duration}` : `-${duration + balancePerYearForPaidLeaves}`;
      } else {
        result = `-${debtForPaidLeaves}`;
      }
      return result;
    };

    const addOfficeStatusInfo = (leaveYearsToMap: any[]) => {
      const { length } = leaveYearsToMap;
      return leaveYearsToMap.map((leaveYear, i) => {
        const leaveData = { ...leaveYear };

        if (i < length - 1) {
          const nextLeaveYear = leaveYearsToMap[i + 1];
          leaveData.officeWillBeChanged = leaveData.office !== nextLeaveYear.office;
          leaveData.bidGap =
            leaveData.bidGap ||
            Math.round((Date.parse(nextLeaveYear.startDate) - Date.parse(leaveData.endDate)) / (1000 * 60 * 60 * 24)) >
              1;
        }

        if (i > 0) {
          const prevLeaveYear = leaveYearsToMap[i - 1];
          leaveData.officeChanged = leaveData.office !== prevLeaveYear.office;
          leaveData.bidGap =
            leaveData.bidGap ||
            Math.round((Date.parse(leaveData.startDate) - Date.parse(prevLeaveYear.endDate)) / (1000 * 60 * 60 * 24)) >
              1;
        }

        return leaveData;
      });
    };

    const mapPaidLeave = (leaveYear: {
      leaves: any[];
      officeChanged: boolean;
      officeWillBeChanged: any;
      bidGap: any;
      totalBalance: number;
    }) => {
      const leavesLength = leaveYear.leaves.length;
      return leaveYear.leaves.map((leave, index) => {
        balancePerYearForPaidLeaves -=
          !leaveYear.officeChanged && !leaveYear.officeWillBeChanged
            ? debtForPaidLeaves || leave.duration
            : leave.duration;

        let officeChanged = false;
        if (index === 0) {
          officeChanged = leaveYear.officeChanged || false;
        }
        if (index === leavesLength - 1) {
          officeChanged = officeChanged || leaveYear.officeWillBeChanged || false;
        }

        const resultLeave = {
          ...leave,
          leaveBalance: getPaidLeaveBalance(leave.duration, officeChanged, leaveYear.bidGap),
          isExtended: isExtended(leave.actualEndDate, leave.endDate),
          isComposite: isComposite(
            leaveYear.totalBalance,
            balancePerYearForPaidLeaves,
            debtForPaidLeaves,
            officeChanged,
            leaveYear.bidGap,
          ),
        };

        if (balancePerYearForPaidLeaves < 0) {
          debtForPaidLeaves = Math.abs(balancePerYearForPaidLeaves);
        } else {
          debtForPaidLeaves = 0;
        }

        return resultLeave;
      });
    };

    const mappedLeaveYears = addOfficeStatusInfo(leaveYears);
    return mappedLeaveYears.map(leaveYear => {
      let leaves = [];
      balancePerYearForPaidLeaves = leaveYear.totalBalance;

      if (leaveYear.leaves.length) {
        leaves = mapPaidLeave(leaveYear);
      }

      const balancePostfix = OFFICE_NAMES[leaveYear.office] === 'Minsk' ? ' c.d.' : ' w.d.';

      return {
        startDate: leaveYear.startDate,
        endDate: leaveYear.endDate,
        officeName: OFFICE_NAMES[leaveYear.office],
        paidLeavesRemainedBalance: String(leaveYear.remainedBalance) + balancePostfix,
        paidLeavesTotalBalance: String(leaveYear.totalBalance),
        leaves: leaves.reverse(),
      };
    });
  };

  const getPreparedPaidLeaves = (data: any) => {
    const leavesYears = [...data].reverse();

    return mapPaidLeavesYears(leavesYears).reverse();
  };

  const mapDayOnDemandsLeave = (leaveYear: { leaves: any[] }) => {
    return leaveYear.leaves.map(leave => {
      return {
        ...leave,
        leaveBalance: `-${leave.duration}`,
      };
    });
  };

  const getPreparedDayOnDemands = (years: any) => {
    return [...years].map(year => {
      let leaves = [];
      if (year.leaves.length) {
        leaves = mapDayOnDemandsLeave(year);
      }

      return {
        startDate: year.startDate,
        endDate: year.endDate,
        leaves,
        dayOnDemandsRemainedBalance: String(year.remainedBalance),
        dayOnDemandsTotalBalance: String(year.totalBalance),
      };
    });
  };

  const getPreparedSickDays = (years: any) => {
    return [...years].map(year => ({
      startDate: year.startDate,
      endDate: year.endDate,
      leaves: year.leaves,
      sickDaysRemainedBalance: String(year.remainedBalance),
      sickDaysTotalBalance: String(year.totalBalance),
    }));
  };

  const mergeAllLeaves = (leavesGroups: { [x: string]: any[] }) => {
    const result: any[] = [];

    const getLeaveYearTotalFields = (storedYear: any, leaveYear: any) => {
      const paidLeavesRemainedBalance =
        storedYear.paidLeavesRemainedBalance || leaveYear.paidLeavesRemainedBalance || leaveYear.paidLeavesTotalBalance;

      const sickDaysRemainedBalance =
        storedYear.sickDaysRemainedBalance || leaveYear.sickDaysRemainedBalance || leaveYear.sickDaysTotalBalance;

      const dayOnDemandsRemainedBalance =
        storedYear.dayOnDemandsRemainedBalance ||
        leaveYear.dayOnDemandsRemainedBalance ||
        leaveYear.dayOnDemandsTotalBalance;

      const officeName = storedYear.officeName || leaveYear.officeName;

      return {
        paidLeavesRemainedBalance,
        dayOnDemandsRemainedBalance,
        sickDaysRemainedBalance,
        officeName,
      };
    };

    Object.keys(leavesGroups).forEach(leaveGroupType => {
      leavesGroups[leaveGroupType].forEach(leaveYear => {
        const storedYear = result.find(item => item.startDate === leaveYear.startDate);
        if (storedYear) {
          storedYear.leaves = [...storedYear.leaves, ...leaveYear.leaves];

          const { paidLeavesRemainedBalance, dayOnDemandsRemainedBalance, sickDaysRemainedBalance, officeName } =
            getLeaveYearTotalFields(storedYear, leaveYear);

          storedYear.paidLeavesRemainedBalance = paidLeavesRemainedBalance;
          storedYear.sickDaysRemainedBalance = sickDaysRemainedBalance;
          storedYear.dayOnDemandsRemainedBalance = dayOnDemandsRemainedBalance;
          storedYear.officeName = officeName;
        } else {
          result.push(leaveYear);
        }
      });
    });

    return result;
  };

  const sortByStartDate = (a: { startDate: string | number | Date }, b: { startDate: string | number | Date }) =>
    // @ts-ignore
    new Date(b.startDate) - new Date(a.startDate);

  const sortLeavesYears = (years: any[]) => {
    return years.sort(sortByStartDate).map(year => ({
      ...year,
      leaves: year.leaves.sort(sortByStartDate),
    }));
  };

  const getPreparedLeaves = (data: { [x: string]: any[]; SICK_DAY?: any; PAID_LEAVE?: any; DAY_ON_DEMAND?: any }) => {
    const mergedLeaves = mergeAllLeaves({
      ...data,
      SICK_DAY: getPreparedSickDays(data.SICK_DAY),
      PAID_LEAVE: getPreparedPaidLeaves(data.PAID_LEAVE),
      DAY_ON_DEMAND: getPreparedDayOnDemands(data.DAY_ON_DEMAND),
    });

    return sortLeavesYears(mergedLeaves);
  };

  const getLeavesBalances = async (employeeAlias: string) => {
    const response = await sendGetRequest(`/employees/${employeeAlias}/leaves/balances`);
    const result = await response.json();
    return getPreparedLeaves(result);
  };

  return {
    getLeavesBalances,
  };
};

export default useLeaveBalanceService;
