import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useDateHelper, {
  CommonDateInputProps,
  COMMON_DATE_INPUT_DEFAULT_PROPS,
} from 'shared/uibuilder/form/input/dateHelper';
import { DateInput } from './index';
import { useDateTimeService } from 'shared/uibuilder/dateService';
import { DEFAULT_TIMEZONE } from 'shared/uibuilder/dateService/constants';
import useAsyncValue from 'shared/asyncHelper';

const TIME_FORMAT = 'HH:mm:ss';
const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const ZonedApiDateFormat = {
  ISO_8601_DATETIME: 'YYYY-MM-DDTHH:mm:ss.SSS',
  ISO_8601_DATETIME_WITH_OFFSET: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
};

const ZoneDateTimeInput = ({
  disabled = COMMON_DATE_INPUT_DEFAULT_PROPS.disabled,
  dateFormat = COMMON_DATE_INPUT_DEFAULT_PROPS.dateFormat,
  apiDateFormat = '',
  minDate = COMMON_DATE_INPUT_DEFAULT_PROPS.minDate,
  maxDate = COMMON_DATE_INPUT_DEFAULT_PROPS.maxDate,
  timeFormat = COMMON_DATE_INPUT_DEFAULT_PROPS.timeFormat,
  ...other
}: CommonDateInputProps) => {
  const props = {
    disabled,
    dateFormat,
    minDate,
    maxDate,
    timeFormat,
    ...other,
  };
  const { getSource, getValue, getRawOnChangeCallback } = useDateHelper(props);
  const originalOnChangeCallback = getRawOnChangeCallback();
  const source = getSource();
  const {
    getDateInCurrentTimezone,
    getCurrentTimezoneDateInUtc,
    getTimezone = () => Promise.resolve(DEFAULT_TIMEZONE),
  } = useDateTimeService();
  const timezone = useAsyncValue(() => getTimezone() as Promise<string>);

  const { getTimeFormat, getDateFormat } = useDateHelper(props);

  const formatTime = getTimeFormat() || TIME_FORMAT;

  const getDateTimeFormat = () => {
    return `${getDateFormat()} ${formatTime}` || DATETIME_FORMAT;
  };

  const dateTimeFormat = getDateTimeFormat();

  const formatDate = (textDate: string) => {
    let result = textDate;
    if (moment(textDate, dateTimeFormat, true).isValid()) {
      result = moment(textDate).format(dateTimeFormat);
    }
    return result;
  };

  const onChangeCallback = ({ [source]: value }: Dictionary<string>) => {
    const utcDate = value && getCurrentTimezoneDateInUtc(value);
    const format = apiDateFormat || dateTimeFormat;
    const formatted = utcDate && utcDate.isValid() ? utcDate.format(format) : value;

    setValue(value);

    if (originalOnChangeCallback) {
      originalOnChangeCallback({
        [source]: formatted,
      });
    }
  };

  const datetimeValue = getValue();
  const currentTimezoneDate = datetimeValue && (getDateInCurrentTimezone(datetimeValue) as moment.Moment);
  const formattedValue =
    currentTimezoneDate && currentTimezoneDate.isValid() ? currentTimezoneDate.format(dateTimeFormat) : datetimeValue;

  const [inputValue, setValue] = useState(formattedValue);

  const updateValue = () => {
    setValue(formattedValue);
  };

  useEffect(() => {
    updateValue();
    // Suppressed warnings because we only need to call useEffect callback if timezone is updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  return (
    <DateInput
      {...props}
      timeFormat={formatTime}
      formatSourceDate={formatDate}
      onChangeCallback={onChangeCallback}
      value={inputValue}
      afterBlurCallback={updateValue}
    />
  );
};

export default ZoneDateTimeInput;
