import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';
import {
  Filter,
  FilterCondition,
  useFilterContext,
} from 'shared/uibuilder/list/filter/FilterContext';
import EqCondition from 'shared/uibuilder/list/filter/condition/EqCondition';
import { Dropdown } from 'shared/uibuilder/form/input';
import React from 'react';

interface InterviewIsActiveFilterProps extends DefaultDropdownProps {
  value?: string;
}

const InterviewIsActiveFilter = (props: InterviewIsActiveFilterProps) => {
  const { source } = props;
  const { filterBy } = useFilterContext();

  const handleChange = (data: Filter = {}) => {
    const value = data[source][FilterCondition.EQ];

    filterBy({
      [source]: {
        [FilterCondition.EQ]: value,
      },
    });
  };

  return (
    <EqCondition {...props} onChangeCallback={handleChange}>
      <Dropdown {...props} placeholder="Select status" options={[{ value: 'true', label: 'Active' }]} />
    </EqCondition>
  );
};

export default InterviewIsActiveFilter;
