import React from 'react';
import classNames from 'classnames';
import BaseInputErrors from './BaseInputErrors';
import InputTooltip from './InputTooltip';
import {
  DefaultInputLayoutProps,
  ValidationLayoutProps,
  InputLayoutHintProps,
  InputLayoutStateProps,
  CommonInputLayoutProps,
  SizeableLayout,
} from 'shared/uibuilder/form/input';
import Box from 'uibuilder/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

function instanceOfSizeable(object: any): object is SizeableLayout {
  return 'numberOfRows' in object;
}

export interface BaseInputLayoutProps
  extends DefaultInputLayoutProps,
    ValidationLayoutProps,
    InputLayoutHintProps,
    InputLayoutStateProps {
  sx?: SxProps<Theme>;
}

const BaseInputLayout = ({
  labelHint,
  source,
  label = '',
  errorMessages = [],
  isVisible = true,
  children,
  isRequired = false,
  helpText = null,
  inputWrapper,
  errorMessageMapper,
  tooltip = null,
  className = '',
  sx,
}: BaseInputLayoutProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <FormControl
      className={classNames('form-group', className || '')}
      fullWidth
      error={!!errorMessages?.length}
      required={!!isRequired && !!label}
      variant="outlined"
      sx={sx}
    >
      {label && (
        <>
          <Box className="label__wrapper" sx={{ marginBottom: '3px', display: 'flex', alignItems: 'center' }}>
            <div>
              <FormLabel id={`label-${source}`} htmlFor={source}>
                {label as string}
              </FormLabel>
              {tooltip && <InputTooltip message={tooltip} source={source} />}
            </div>
            {labelHint && (
              <FormHelperText sx={{ color: 'var(--mui-palette-text-secondary)', m: '3px 0 0 5px' }}>
                {labelHint}
              </FormHelperText>
            )}
          </Box>
        </>
      )}
      {children}
      <FormHelperText>
        {errorMessages?.length ? (
          <BaseInputErrors errorMessages={errorMessages} errorMessageMapper={errorMessageMapper} />
        ) : (
          helpText
        )}
      </FormHelperText>
    </FormControl>
  );
};

// Made var instead const due to error in integration test.
// If we had declared the variable with var, the error wouldn’t have occurred.
// This happens because variables declared with the var keyword are hoisted – they are moved by the JavaScript interpreter to the top of the current scope.
// eslint-disable-next-line
export var COMMON_INPUT_LAYOUT_DEFAULT_PROPS = {
  label: '',
  source: undefined,
  labelHint: undefined,
  errorMessages: [],
  isVisible: true,
  isRequired: false,
  helpText: null,
  tooltip: null,
  className: '',
  disabled: false,
};

export default BaseInputLayout;

export const getBaseInputLayoutProps = (props: CommonInputLayoutProps): BaseInputLayoutProps => {
  const {
    label,
    errorMessages,
    isVisible,
    source,
    isRequired,
    labelHint,
    helpText,
    disabled,
    errorMessageMapper,
    tooltip,
    className,
  } = props;

  return {
    disabled,
    label,
    errorMessages,
    isVisible,
    source,
    helpText,
    isRequired,
    labelHint,
    errorMessageMapper,
    tooltip,
    className,
  };
};

interface CommonInputData<ChangeEvent> {
  name: string;
  value?: string;
  onChange: (event: ChangeEvent) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  className: string;
  disabled?: boolean;
  rows?: number;
  placeholder?: string;
}

export const getCommonInputProps: <ChangeEvent>(
  props: CommonInputLayoutProps<ChangeEvent>,
  shouldHaveFormControlClass?: boolean,
) => CommonInputData<ChangeEvent> = (props, shouldHaveFormControlClass = true) => {
  const {
    errorMessages,
    source,
    value: initialValue,
    disabled,
    onChangeCallback,
    onFocusCallback,
    onBlurCallback,
    placeholder,
  } = props;

  let rows;
  let value;

  if (instanceOfSizeable(props) && props.numberOfRows) {
    rows = props.numberOfRows;
  }

  if (initialValue || typeof initialValue === 'boolean') {
    value = initialValue;
  }

  const hasError = errorMessages && errorMessages.length > 0;
  const formControlClass = shouldHaveFormControlClass ? 'form-control' : '';
  const invalidClass = hasError ? ' is-invalid' : '';
  const inputClasses = `${formControlClass}${invalidClass}`;

  return {
    id: source,
    name: source,
    value,
    onChange: onChangeCallback || (() => {}),
    onBlur: onBlurCallback,
    onFocus: onFocusCallback,
    className: inputClasses,
    disabled: disabled || false,
    rows,
    placeholder,
  };
};
