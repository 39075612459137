/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const EditCandidateButton = (props: EditButtonProps) => {
  const { getUpdateEntityUrl } = useCandidateUrl();

  return <EditButton path={id => getUpdateEntityUrl(id)} key="edit-candidate" {...props} />;
};

export default EditCandidateButton;
