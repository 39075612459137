/* istanbul ignore file */
import React from 'react';
import useServiceCatalogService, {
  ARCHIVE_SERVICE_CATALOG,
  SERVICE_CATALOG_STATUSES,
} from 'erp/serviceCatalog/useServiceCatalogService';
import { useParams } from 'react-router-dom';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const archiveMessage = 'Do you really want to archive this service?';

const ArchiveServiceCatalogButton = ({ statusSource }: { statusSource: string }) => {
  const { id } = useParams<Dictionary<string>>();
  const { archiveService, invalidateCache } = useServiceCatalogService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { setData = () => {} } = useShowContext();
  const status = getValue();

  if (status === SERVICE_CATALOG_STATUSES.ARCHIVED) {
    return null;
  }

  return (
    <DeleteButton
      displayMessage={archiveMessage}
      deleteMethod={() => archiveService(id!)}
      afterDelete={{
        successMessage: 'The service has been successfully archived.',
        errorMessage: "Can't archive the service.",
        execute: (data: any) => {
          invalidateCache();
          setData(data);
        },
      }}
      permissionToCheck={ARCHIVE_SERVICE_CATALOG}
      buttonText="Archive"
      color="error"
    />
  );
};

export default ArchiveServiceCatalogButton;
