/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, TextField } from 'shared/uibuilder/field';

const ContractWorkRateField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const displayedValue = `${getValue()}%`;

  return <TextField {...props} value={displayedValue} />;
};

export default ContractWorkRateField;
