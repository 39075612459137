import React from 'react';
import { formMap, CustomForm } from 'oneStop/CustomRequestForm/formMap';

type CustomRequestFormFactoryProps = {
  form: Nullable<CustomForm>;
  openModal: (content: any, title?: string) => void;
  props?: any;
};

export type CustomRequestFormProps = {
  openModal: (content: any, title?: string) => void;
};

export type CustomRequestFormType = ReactComponent<CustomRequestFormProps>;

const CustomRequestFormFactory = ({ form, ...props }: CustomRequestFormFactoryProps) => {
  if (!form) {
    return null;
  }

  const Form: CustomRequestFormType = formMap[form];

  return <Form {...props} />;
};

export default CustomRequestFormFactory;
