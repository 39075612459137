import { useSelector, useDispatch } from 'react-redux';

export const SET_SETTING = 'SET_PO_SETTING';

export type Settings = {
  [key: string]: any;
};

export const setSetting = (payload: any) => ({
  type: SET_SETTING,
  payload,
});

export interface PurchaseOrdersSettingState {
  areDeliversInvoicesVisible: boolean;
}

const initialState = {
  areDeliversInvoicesVisible: true,
};

export const purchaseOrderSettingsReducer = (state = initialState, action: any = {}) => {
  if (action.type === SET_SETTING) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

const usePurchaseOrderSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector(
    (state: { purchaseOrderSettings: PurchaseOrdersSettingState }) => state?.purchaseOrderSettings,
  );
  const { areDeliversInvoicesVisible }: Settings = settings;

  const updateSettings = (updatedSettings: Settings) => {
    dispatch(
      setSetting({
        ...settings,
        ...updatedSettings,
      }),
    );
  };

  const setDeliversInvoicesVisible = (isChecked: boolean) => {
    updateSettings({
      areDeliversInvoicesVisible: isChecked,
    });
  };

  return {
    setDeliversInvoicesVisible,
    areDeliversInvoicesVisible,
  };
};

export default usePurchaseOrderSettings;
