/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import React from 'react';
import { Collapsible, HtmlField } from 'shared/uibuilder/field';
import FeedbackFooter from 'erp/employee/Timeline/feedback/shared/layout/FeedbackFooter';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import TimelineSentFeedbackHeader from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineSentFeedbackHeader';
import FeedbackOnBehalf from 'erp/employee/Timeline/feedback/shared/layout/FeedbackOnBehalf';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const SentFeedbackTimelineRecordLayout = ({ ...props }) => {
  const { data: entity } = useShowContext();
  const feedbackText = entity.getValueBySource('feedbackText');

  return (
    <BaseTimelineRecordLayout
      permissionToCheck={null}
      actionName=""
      timelineType={TimelineType.FEEDBACK}
      buttons={[]}
      fields={
        feedbackText ? (
          <Collapsible>
            <HtmlField source="feedbackText" label="" />
          </Collapsible>
        ) : null
      }
      timelineHeader={rest => (
        <TimelineSentFeedbackHeader {...rest} actionName={behalfProps => <FeedbackOnBehalf {...behalfProps} />} />
      )}
      timelineActions={() => null}
      timelineFooter={rest => <FeedbackFooter {...rest} />}
      {...props}
    />
  );
};

export default SentFeedbackTimelineRecordLayout;
