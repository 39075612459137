/* istanbul ignore file */
import React, { ReactNode } from 'react';
import useArtifactService from 'artifact/artifactService';
import TmpAccessAwareSecurityContext, { Service } from 'security/TmpAccessAwareSecurityContext';
import { Permission } from 'shared/authorization/utils';

export const ARTIFACT_CONTEXT = 'Artifact';

interface ArtifactContextProps {
  permissionToCheck: Permission | Permission[];
  children: ReactNode;
  idSource?: string;
}

const ArtifactContext: React.FC<ArtifactContextProps> = ({
  permissionToCheck = null,
  children,
  idSource = undefined,
}) => {
  return (
    <TmpAccessAwareSecurityContext
      useEntityService={useArtifactService as unknown as () => Service}
      permissionToCheck={permissionToCheck}
      idSource={idSource}
      contextName={ARTIFACT_CONTEXT}
    >
      {children}
    </TmpAccessAwareSecurityContext>
  );
};

export default ArtifactContext;
