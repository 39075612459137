/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_PERFORMANCE_REPORT, PERFORMANCE_REPORTS_PATH } from 'erp/performanceReport/usePerformanceReportService';
import PerformanceReportList from 'erp/performanceReport/List';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const usePerformanceReportRoute = () => useRoute({ listRoute: PERFORMANCE_REPORTS_PATH });

export const usePerformanceReportUrl = () =>
  useEntityUrl({
    baseLink: PERFORMANCE_REPORTS_PATH,
  });

export const usePerformanceReportId = () => useParams<Dictionary<string>>().id!;

const PerformanceReportRouter = () => {
  const { listRoute } = usePerformanceReportRoute();

  return (
    <ProtectedRouter basePath={PERFORMANCE_REPORTS_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_PERFORMANCE_REPORT}>
              <PerformanceReportList />
            </GlobalProtectedRoute>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

PerformanceReportRouter.getRouterPath = () => PERFORMANCE_REPORTS_PATH;

export default PerformanceReportRouter;
