/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { LEAD_DISQUALIFICATION_REASON } from 'crm/lead/shared/leadService';

const DisqualificationReasonField = ({
  value: inputValue = null,
  source = null,
}: {
  value?: any;
  source: Nullable<string>;
}) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));

  return <EnumField label="Disqualification Reason" value={value} options={LEAD_DISQUALIFICATION_REASON} />;
};

export default DisqualificationReasonField;
