import React from 'react';
import { EnumField, FieldProps, TextField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { EMPLOYEE_CONTRACT_TYPES } from 'erp/employee/contracts/shared/input/EmployeeContractTypeDropdown';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import VacancyOfficeField from 'erp/recruitment/vacancy/shared/field/VacancyOfficeField';

const ExpectedCompensationField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const options = (getValue() || []) as any[];
  const getOptionInfo = (option: any) => {
    const office = <VacancyOfficeField value={option.office} label="Location (Office)" />;
    const contractType = (
      <EnumField value={option.contractType} options={EMPLOYEE_CONTRACT_TYPES} label="Contract Type" />
    );
    const valueRange = <TextField value={`${option.valueFrom} - ${option.valueTo}`} label="Value Range" />;
    const currency = <TextField value={option.currency} label="Currency" />;

    return (
      <div className="field__item-wrapper" key={`${option.office}-${option.contractType}`}>
        <SectionRow>
          {office}
          {contractType}
          {valueRange}
          {currency}
        </SectionRow>
      </div>
    );
  };

  const result = options.map((option: any) => getOptionInfo(option));

  return <div className="field__wrapper column">{result}</div>;
};

export default ExpectedCompensationField;
