import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import useDidUpdateEffect from 'shared/useDidUpdateEffect';
import moment from 'moment';
import 'moment-timezone';
// We need this line to support week starting with monday
// Source: https://github.com/YouCanBookMe/react-datetime/issues/111
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import enGb from 'moment/locale/en-gb';
import CalendarIcon from '@mui/icons-material/CalendarMonthOutlined';
import BaseInputLayout, {
  getBaseInputLayoutProps,
  getCommonInputProps,
} from 'uibuilder/layout/form/input/BaseInputLayout';
import DateTime from 'shared/lib/DateTime/DateTime';
import { DateInputLayoutProps, DateInputLayoutType } from 'shared/uibuilder/form/input/DateInput';
import { DEFAULT_TIMEZONE } from 'shared/uibuilder/dateService/constants';

const DefaultDateInputLayout: DateInputLayoutType = ({
  disabled,
  showCalendar = true,
  toggleButton = null,
  minDate: propsMinDate,
  maxDate: propsMaxDate,
  validRangeInclusivity = '[]',
  onBlurCallback,
  onFocusCallback,
  onChangeCallback,
  timezone = DEFAULT_TIMEZONE,
  className = '',
  readOnly,
  ...otherProps
}: DateInputLayoutProps) => {
  const rest = {
    label: '',
    errorMessages: [],
    isVisible: true,
    isRequired: false,
    helpText: null,
    tooltip: null,
    disabled: false,
    ...otherProps,
  };

  const dateTimeRef = useRef<typeof DateTime>(null);
  const [onBlurCalled, setOnBlurCalled] = useState(false);
  const commonInputProps = getCommonInputProps(rest, false);

  useDidUpdateEffect(() => {
    if (onFocusCallback && onBlurCallback) {
      onFocusCallback();
      onBlurCallback();
    }
    setOnBlurCalled(false);
  }, [onBlurCalled]);

  useDidUpdateEffect(() => {
    if (onFocusCallback && onBlurCallback) {
      onFocusCallback();
      onBlurCallback();
    }
  }, [rest.value]);

  const minDate =
    propsMinDate && ((propsMinDate instanceof moment ? propsMinDate : moment(propsMinDate)) as moment.Moment);
  const maxDate =
    propsMaxDate && ((propsMinDate instanceof moment ? propsMaxDate : moment(propsMaxDate)) as moment.Moment);

  /**
   * This method is responsible for disabling picker values
   * according to provided minDate and maxDate props
   * @param value -- date in opened calendar
   * @returns {boolean} -- if date should be 'pickable' or not.
   */
  const validateCalendar = (value: Date) => {
    const includeStart = validRangeInclusivity && validRangeInclusivity[0] === '[';
    const includeEnd = validRangeInclusivity && validRangeInclusivity[1] === ']';

    if (maxDate && minDate && maxDate.isValid() && minDate.isValid()) {
      return moment.tz(value, timezone).isBetween(minDate, maxDate, 'hour', validRangeInclusivity);
    }

    if (maxDate && maxDate.isValid()) {
      return includeEnd ? maxDate.isSameOrAfter(value) : maxDate.isAfter(value);
    }

    if (minDate && minDate.isValid()) {
      return includeStart ? minDate.isSameOrBefore(value) : minDate.isBefore(value);
    }

    return true;
  };

  return (
    <BaseInputLayout {...getBaseInputLayoutProps(rest)}>
      <div
        className="position-relative"
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
        }}
      >
        {showCalendar ? (
          <CalendarIcon
            sx={{
              marginRight: '10px',
              fontSize: '1.2rem',
              position: 'absolute',
              zIndex: 1,
              left: '10px',
              top: '10px',
              opacity: '0.8',
            }}
          />
        ) : null}
        {/* works only with dates, not time */}
        <DateTime
          {...commonInputProps}
          closeOnSelect
          ref={dateTimeRef}
          isValidDate={validateCalendar}
          inputProps={{
            disabled,
            onFocus: onFocusCallback,
            style: { paddingLeft: showCalendar ? '35px' : '0.75rem' },
            readOnly,
          }}
          onBlur={() => setOnBlurCalled(true)}
          onChange={(value: string) => {
            if (onChangeCallback) {
              onChangeCallback({ target: { value } } as React.ChangeEvent<HTMLInputElement>);
            }
          }}
          className={classnames(className, commonInputProps.className)}
          {...rest}
        />
      </div>
    </BaseInputLayout>
  );
};

export default DefaultDateInputLayout;
