/* istanbul ignore file */
import React from 'react';
import { CURRENCY_TYPES } from 'erp/employee/finance/shared/financeService';
import EnumDropdown, { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';

const CurrencyDropdown = (props: Omit<EnumDropdownProps, 'options'> & { options?: Dictionary<string> }) => {
  const options = { ...CURRENCY_TYPES };

  return <EnumDropdown options={options} placeholder="Select currency" {...props} />;
};

export default CurrencyDropdown;
