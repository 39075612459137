import React from 'react';
import { Button } from 'uibuilder/button';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import AddIcon from '@mui/icons-material/AddOutlined';
import RemoveIcon from '@mui/icons-material/RemoveOutlined';

interface RemovableInputWrapperProps extends DefaultInputPropTypes<boolean> {
  children: React.ReactElement;
  addText?: string;
  deleteText?: string;
  colSize?: number;
}

/**
 * Wrapper that will add remove button and will hide input and hide/show it based in value.
 * @constructor
 */
const RemovableInputWrapper = ({ children, addText = '', deleteText = '', ...props }: RemovableInputWrapperProps) => {
  const { getValue, getOnChangeCallback } = useInputHelper(props);
  const onChangeCallback = getOnChangeCallback();
  const isVisible = getValue();

  const toggleInput = () => {
    onChangeCallback({
      target: {
        value: !isVisible,
      },
    });
  };

  return isVisible ? (
    <>
      <Button
        id="remove"
        size="small"
        onClick={toggleInput}
        sx={{ padding: deleteText ? '5px 17px 5px 10px' : '4px', marginBottom: '20px' }}
      >
        <RemoveIcon sx={{ marginRight: deleteText ? '7px' : 0 }} />
        {deleteText}
      </Button>
      {children}
    </>
  ) : (
    <Button
      outline
      size="small"
      startIcon={<AddIcon />}
      id="add"
      type="button"
      onClick={toggleInput}
      sx={{
        paddingRight: addText ? '14px' : '9px',
        '& .MuiButton-startIcon': addText
          ? {}
          : {
              margin: 0,
            },
      }}
    >
      {addText}
    </Button>
  );
};

export default RemovableInputWrapper;
