import React from 'react';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import Board from '../shared/board/Board';

const RecruitingBoardContent = () => {
  return (
    <ListPageLayout
      sxPaper={{
        backgroundColor: 'transparent',
        padding: '20px 0px 0px 0px !important',
      }}
    >
      <Board
        sx={{ height: 'calc(100vh - 150px)', position: 'fixed', width: '-webkit-fill-available', marginRight: '10px' }}
      />
    </ListPageLayout>
  );
};

export default RecruitingBoardContent;
