/* istanbul ignore file */
import React from 'react';
import { DefaultInputPropTypes, TextInput } from 'shared/uibuilder/form/input';
import RelationInput from 'erp/employee/shared/input/RelationInput';

const FamilyMemberInput = (props: DefaultInputPropTypes<any>) => {
  return <RelationInput relationInput={TextInput} {...props} />;
};

export default FamilyMemberInput;
