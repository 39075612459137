import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, BaseFieldLayoutType } from 'shared/uibuilder/field';
import { useShowContext } from '../show/ShowContext';
import getLabel, { EMPTY_VALUE_INDICATOR, getValue } from 'shared/uibuilder/helper';
import useUiTheme from '../useUiTheme';

const NumberField = ({ source = '', value, label, displayZeroValue = true }: FieldProps<number>) => {
  const { data } = useShowContext();
  const { getValue: getValueFromFieldHelper } = useFieldHelper({ source });
  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const getFieldValue = () => {
    const valueFromShowContext = getValue(value, (src?: string) => data && data.getValueBySource(src!), source);

    if (valueFromShowContext) {
      return displayZeroValue && valueFromShowContext === EMPTY_VALUE_INDICATOR ? '0' : valueFromShowContext;
    }

    return displayZeroValue && !getValueFromFieldHelper() ? '0' : getValueFromFieldHelper();
  };

  return <BaseFieldLayout label={getLabel(label, source)} value={getFieldValue()} />;
};

export default NumberField;
