/* istanbul ignore file */
import React from 'react';
import { Route } from 'react-router';
import { Routes, useParams } from 'react-router-dom';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { CREATE_EMPLOYEE_OFFER, READ_LIST_EMPLOYEE_OFFER } from 'erp/employee/employeeService';
import { READ, UPDATE } from 'crm/crmService';
import EmployeeOfferContext from 'erp/employee/offers/EmployeeOfferContext';
import UpdateEmployeeOffer from 'erp/employee/offers/createupdate/Update';
import ViewEmployeeOfferPage from 'erp/employee/offers/Show/ViewEmployeeOfferPage';
import EmployeeOfferList from 'erp/employee/offers/List';
import CreateEmployeeOfferPage from 'erp/employee/offers/createupdate/Create';

export const EMPLOYEE_OFFER_ID = 'employeeOfferId';
export const EMPLOYEE_OFFER_PATH = '/offers';

export const useEmployeeOfferRoute = () => {
  return useRoute({
    listRoute: `${EMPLOYEE_OFFER_PATH}`,
    singleEntityRoute: `/:${EMPLOYEE_OFFER_ID}`,
  });
};

export const useEmployeeOfferUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${employeeLink}${EMPLOYEE_OFFER_PATH}`,
    idSource: EMPLOYEE_OFFER_ID,
  });
};

export const useEmployeeOfferId = () => {
  const params: any = useParams<Dictionary<string>>();
  return params.employeeOfferId;
};

const EmployeeOfferRouter = () => {
  const { listRoute, createRoute, updateRoute, singleEntityRoute } = useEmployeeOfferRoute();

  return (
    <Routes>
      <Route
        path={createRoute}
        element={
          <GlobalProtectedRoute globalPermissionToCheck={CREATE_EMPLOYEE_OFFER}>
            <CreateEmployeeOfferPage />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={listRoute}
        element={
          <GlobalProtectedRoute globalPermissionToCheck={READ_LIST_EMPLOYEE_OFFER}>
            <EmployeeOfferList />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={updateRoute}
        element={
          <EmployeeOfferContext permissionToCheck={UPDATE}>
            <UpdateEmployeeOffer />
          </EmployeeOfferContext>
        }
      />

      <Route
        path={singleEntityRoute}
        element={
          <EmployeeOfferContext permissionToCheck={READ}>
            <ViewEmployeeOfferPage />
          </EmployeeOfferContext>
        }
      />
    </Routes>
  );
};

export default EmployeeOfferRouter;
