/* istanbul ignore file */
import React from 'react';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useSkillsUrl } from 'erp/employee/skills/SkillsRouter';

const SkillsBreadcrumbs = ({ children = null }: { children?: React.ReactElement | React.ReactElement[] | null }) => {
  const { getListUrl } = useSkillsUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Skills" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default SkillsBreadcrumbs;
