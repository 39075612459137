import React, { useEffect, useState } from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import useResourcePoolService from 'erp/employee/resourcePool/resourcePoolService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const ResourcePoolDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => {
  const { findAll } = useResourcePoolService();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const pools = await findAll();
      const data = pools.map((pool: any) => ({
        value: pool.id.toString(),
        label: pool.name,
      }));
      setOptions(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Dropdown {...props} placeholder="Select pool" options={options} />;
};

export default ResourcePoolDropdown;
