import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useBudgetingService, {
  BUDGETING_APPROVE,
} from 'erp/serviceCatalog/budgets/budgetingService';
import { useParams } from 'react-router-dom';
import { BUDGET_VERSION_STATUSES } from 'erp/serviceCatalog/budgets/budgetVersionService';
import React from 'react';

const ApproveBudgetButton = () => {
  const { data, setData } = useShowContext();
  const { budgetId } = useParams<Dictionary<string>>();
  const { approveBudget } = useBudgetingService();

  const approveAndUpdate = async (approvedBudgetId: string) => {
    const version = await approveBudget(approvedBudgetId);
    setData!(version);
  };

  if (data.getValueBySource('status') !== BUDGET_VERSION_STATUSES.AWAITING_APPROVAL) {
    return (<></>);
  }

  return (
    <ButtonWithConfirmation permissionToCheck={BUDGETING_APPROVE}
      displayMessage="Do you really want to approve this budget?"
      submitMethod={() => approveAndUpdate(budgetId!)}
      afterSubmit={{
        successMessage: 'The budget has been successfully approved.',
        errorMessage: "Can't approve this budget.",
      }}
      color="primary"
    >
      Approve
    </ButtonWithConfirmation>
  );
};

export default ApproveBudgetButton;
