/* istanbul ignore file */
import React from 'react';
import SingleServiceCatalogBreadcrumbs
  from 'erp/serviceCatalog/shared/SingleServiceCatalogBreadcrumbs';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';
import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';

const ViewServiceBudgetsBreadcrumbs = ({ children } : { children? : React.ReactNode }) => {
  const id = useServiceCatalogId();
  const { getBudgetsUrl } = useServiceCatalogUrl();

  return (
    <SingleServiceCatalogBreadcrumbs>
      <SingleBreadcrumbItem entity='Budgets' link={getBudgetsUrl(id)} />
      {children}
    </SingleServiceCatalogBreadcrumbs>
  );
};

export default ViewServiceBudgetsBreadcrumbs;
