import React from 'react';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import { Routes, useParams } from 'react-router-dom';
import ProtectedRouter from 'routing/ProtectedRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import RecruitingDashboardListPage from './List';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { HIRING_REQUEST_DASHBOARD } from './useRecruitingDashboardService';
import RecruitingBoard from '../recruitingBoard/List/RecruitingBoard';
import useFeatureToggle, { Features } from 'featuretoggle';

export const RECRUITING_DASHBOARD_PATH = '/recruiting-dashboard';

export const useRecruitingDashboardRoute = () => {
  const routers = useRoute({ listRoute: RECRUITING_DASHBOARD_PATH });

  return {
    ...routers,
    newDashboard: '/new-dashboard',
  };
};

export const useRecruitingDashboardUrl = () => {
  const { ...restUrls } = useEntityUrl({
    baseLink: RECRUITING_DASHBOARD_PATH,
  });

  return {
    ...restUrls,
    newDashboard: `${restUrls.getListUrl()}/new-dashboard`,
  };
};

export const useRecruitingDashboardId = () => {
  const { id } = useParams<Dictionary<string>>();
  return id;
};

const RecruitingDashboardRouter = () => {
  const { listRoute, newDashboard } = useRecruitingDashboardRoute();
  const { isFeatureEnabled } = useFeatureToggle();

  const viewNewDashboard = isFeatureEnabled(Features.NEW_RECRUITING_DASHBOARD);

  return (
    <ProtectedRouter basePath={RECRUITING_DASHBOARD_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={HIRING_REQUEST_DASHBOARD}>
              <RecruitingDashboardListPage />
            </GlobalProtectedRoute>
          }
        />

        {viewNewDashboard ? (
          <Route
            path={newDashboard}
            element={
              // TODO: Update permissions to the correct as the Davinci team requires access for the first version.
              <GlobalProtectedRoute globalPermissionToCheck="VACANCY_READ_LIST">
                <RecruitingBoard />
              </GlobalProtectedRoute>
            }
          />
        ) : (
          <></>
        )}

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

RecruitingDashboardRouter.getRouterPath = () => `${RECRUITING_DASHBOARD_PATH}/*`;

export default RecruitingDashboardRouter;
