import React from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonProps } from 'uibuilder/button/Button';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

export interface LinkButtonProps extends ButtonProps {
  children: React.ReactNode | React.ReactNode[];
  url: string | ((id?: Nullable<StringOrNumber>) => string);
  value?: string | number;
  target?: string;
}

const LinkButton = ({ children, url: initialUrl, ...otherProps }: LinkButtonProps) => {
  const props: any = {
    value: '',
    ...otherProps,
  };
  const { key, ...filteredOther } = props;
  const { getValue } = useFieldHelper(props);
  let url = initialUrl;

  if (typeof initialUrl === 'function') {
    url = initialUrl(getValue());
  }

  return (
    <Button key={key} component={Link} to={url} {...filteredOther}>
      {children}
    </Button>
  );
};

export default LinkButton;
