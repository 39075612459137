/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import {
  ARTIFACT_STORAGE_PATH,
  FE_VIEW_ARTIFACT_STORAGE,
  UPLOAD_ARTIFACT_STORAGE_FILES,
} from 'artifactStorage/shared/artifactStorageService';
import ArtifactStorageList from 'artifactStorage/List';
import { CreateArtifact } from 'artifactStorage/createupdate';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';
import ViewArtifactStorageFile from 'artifactStorage/Show';
import ArtifactStorageFileContext from 'artifactStorage/shared/ArtifactStorageFileContext';

export const useArtifactStorageRoute = () => {
  return useRoute({
    listRoute: ARTIFACT_STORAGE_PATH,
    singleEntityRoute: `/file/:fileId`,
  });
};

export const useArtifactStorageUrl = () => {
  const entityUrl = useEntityUrl({
    baseLink: ARTIFACT_STORAGE_PATH,
  });
  const getSingleEntityUrl = (id?: any): string => `${ARTIFACT_STORAGE_PATH}/file/${id}`;

  return {
    ...entityUrl,
    getSingleEntityUrl,
  };
};

export const useArtifactStorageId = () => {
  const { fileId } = useParams<Dictionary<string>>();

  return fileId;
};

const ArtifactStorageRouter = () => {
  const { listRoute, createRoute, singleEntityRoute } = useArtifactStorageRoute();

  return (
    <ProtectedRouter basePath={ARTIFACT_STORAGE_PATH}>
      <Routes>
        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={UPLOAD_ARTIFACT_STORAGE_FILES}>
              <CreateArtifact />
            </GlobalProtectedRoute>
          }
        />
        <Route
          path={singleEntityRoute}
          element={
            <ArtifactStorageFileContext permissionToCheck={FE_VIEW_ARTIFACT_STORAGE}>
              <ViewArtifactStorageFile />
            </ArtifactStorageFileContext>
          }
        />

        <Route
          path={`${listRoute}/*`}
          element={
            <GlobalProtectedRoute path={listRoute} globalPermissionToCheck={FE_VIEW_ARTIFACT_STORAGE}>
              <ArtifactStorageList />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

ArtifactStorageRouter.getRouterPath = () => `${ARTIFACT_STORAGE_PATH}/*`;

export default ArtifactStorageRouter;
