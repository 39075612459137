/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import { LeadRouter } from 'crm/lead';
import { AccountRouter } from 'crm/account';
import { ContactRouter } from 'crm/contact';
import { OpportunityRouter } from 'crm/opportunity';
import { ProjectsDashboardRouter } from 'crm/projectsDashboard';
import { Router, Route } from 'shared/routing';
import UnlinkedCrmEmailActivitiesRouter from 'crm/unlinkedEmails/UnlinkedCrmEmailActivitiesRouter';

const CRM_PATH = '/crm';

const CrmRouter: Router = () => (
  <Routes>
    <Route path={LeadRouter.getRouterPath()} element={<LeadRouter />} />
    <Route path={AccountRouter.getRouterPath()} element={<AccountRouter />} />
    <Route path={ContactRouter.getRouterPath()} element={<ContactRouter />} />
    <Route path={OpportunityRouter.getRouterPath()} element={<OpportunityRouter />} />
    <Route path={ProjectsDashboardRouter.getRouterPath()} element={<ProjectsDashboardRouter />} />
    <Route path={UnlinkedCrmEmailActivitiesRouter.getRouterPath()} element={<UnlinkedCrmEmailActivitiesRouter />} />
    <Route path="*" element={<Page404 />} />
  </Routes>
);

CrmRouter.getRouterPath = () => `${CRM_PATH}/*`;

export default CrmRouter;
