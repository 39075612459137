import { useNavigate } from 'react-router-dom';
import Box from 'uibuilder/Box';
import { Button, SubmitButton } from 'uibuilder/button';
import React, { useCallback, useState, useMemo } from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { ProjectWeeklyReportStatus, UseCorporateModel } from 'erp/project/weeklyreports/ProjectWeeklyReportService';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import InvalidSectionsMessage from 'uibuilder/form/InvalidSectionsMessage';
import { getInvalidSections } from 'uibuilder/form/FloatingButtons';

const ProjectWeeklyReportFormButtonsLayout = ({ isSubmitEnabled }: { isSubmitEnabled?: boolean }) => {
  const navigate = useNavigate();
  const [length] = useState(navigate.length - 1);
  const [modalOpen, setModalOpen] = useState(false);
  const [displayInvalidSections, setDisplayInvalidSection] = useState(false);

  const { data = {}, submitForm = () => {}, formErrors } = useFormContext();

  const onSave = useCallback(
    (status: any) => {
      data.newStatus = status;
      submitForm();
      setDisplayInvalidSection(true);
      setModalOpen(false);
    },
    [data, submitForm],
  );

  const saveSubmittedReportButton = (
    <Button
      data-testid="btn-success"
      key="modal-submit-button"
      onClick={useCallback(() => {
        onSave(ProjectWeeklyReportStatus.SUBMITTED);
      }, [onSave])}
    >
      Submit
    </Button>
  );

  const saveReportInProgressButton = (
    <Button
      outline
      data-testid="modal-save-draft-button"
      key="modal-save-draft-button"
      onClick={useCallback(() => {
        onSave(ProjectWeeklyReportStatus.IN_PROGRESS);
      }, [onSave])}
    >
      Save draft
    </Button>
  );

  const isAlreadyContained = (sections: any, anchor: any) => {
    return sections.filter((sectionData: string) => sectionData.anchor === anchor).length > 0;
  };

  const invalidSections = useMemo(() => {
    const sections = getInvalidSections(formErrors as any);

    if (
      data.weeklyReportData.achievements.length === 0 &&
      !isAlreadyContained(sections, 'listOfAchievementsForTheLastWeek') &&
      data.corporateModel === UseCorporateModel.YES
    ) {
      sections.push({
        anchor: 'listOfAchievementsForTheLastWeek',
        title: 'List of achievements for the last week',
      });
    }
    if (
      data.weeklyReportData.plannedItems.length === 0 &&
      !isAlreadyContained(sections, 'listOfItemsPlannedForTheNextWeek') &&
      data.corporateModel === UseCorporateModel.YES
    ) {
      sections.push({
        anchor: 'listOfItemsPlannedForTheNextWeek',
        title: 'List of items planned for the next week',
      });
    }

    if (
      data.weeklyReportData.milestones.length === 0 &&
      !isAlreadyContained(sections, 'keyProjectMilestones') &&
      data.corporateModel === UseCorporateModel.YES
    ) {
      sections.push({
        anchor: 'keyProjectMilestones',
        title: 'Key project milestones',
      });
    }

    if (
      (!!formErrors?.internalReportData ||
        (data.internalReportData?.discoveryScopeBasedTypesData.length === 0 &&
          data.internalReportData?.agileProductMaintenanceSupportTypesData.length === 0)) &&
      !isAlreadyContained(sections, 'internalData')
    ) {
      sections.push({
        anchor: 'internalData',
        title: 'Internal data',
      });
    }

    return sections;
  }, [formErrors, data]);

  const messageComponent = (
    <InvalidSectionsMessage invalidSections={invalidSections} needToDisplay={displayInvalidSections} />
  );

  return (
    <>
      <Box className="form-buttons-wrapper mt-5">
        <Box className="btn-area" sx={{ minWidth: '200px', '&:not(:last-child)': { marginBottom: '10px' } }}>
          <SubmitButton
            key="btn-continue"
            onClick={() => {
              setModalOpen(true);
            }}
            isLoading={!isSubmitEnabled}
            data-testid="not-strict-rules-continue"
          />
          <Button
            data-testid="modal-cancel-button"
            outline
            key="modal-cancel-button"
            onClick={useCallback(() => {
              navigate(length - navigate.length);
            }, [navigate, length])}
          >
            Cancel
          </Button>
        </Box>
        {messageComponent}
      </Box>
      <ModalWindow
        isOpen={modalOpen}
        title="After you submit the report, you will not be able to edit it"
        hasCancelButton={false}
        buttons={[saveSubmittedReportButton, saveReportInProgressButton]}
        onToggle={() => {
          setModalOpen(false);
        }}
      >
        <span>Are you sure you want to submit this report? You can save the draft and return to it later.</span>
      </ModalWindow>
    </>
  );
};

export default ProjectWeeklyReportFormButtonsLayout;
