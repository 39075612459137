/* istanbul ignore file */
import React from 'react';
import { RadioButtonGroup } from 'shared/uibuilder/form/input';
import { RadioButtonGroupProps } from '../../../../../shared/uibuilder/form/input/RadioButtonGroup';

export const EMPLOYEE_CONTRACT_ASSIGMENT_ALIAS = {
  PART_TIME: 'PART_TIME',
  FULL_TIME: 'FULL_TIME',
};

export const EMPLOYEE_CONTRACT_ASSIGMENT_TYPES = {
  [EMPLOYEE_CONTRACT_ASSIGMENT_ALIAS.FULL_TIME]: 'Full-time',
  [EMPLOYEE_CONTRACT_ASSIGMENT_ALIAS.PART_TIME]: 'Part-time',
};

const options = Object.keys(EMPLOYEE_CONTRACT_ASSIGMENT_TYPES).map(key => ({
  value: key,
  label: EMPLOYEE_CONTRACT_ASSIGMENT_TYPES[key],
}));

const EmployeeContractAssignmentTypeRadios = (props: RadioButtonGroupProps) => {
  return <RadioButtonGroup {...props} options={options} />;
};

export default EmployeeContractAssignmentTypeRadios;
