import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import {
  DefaultInputLayoutProps,
  InputEventProps,
  InputLayoutStateProps,
  ValidationLayoutProps,
  DefaultInputPropTypes,
} from 'shared/uibuilder/form/input';

interface TogglerProps {
  color?: string;
  checkedValue?: string | number;
  uncheckedValue?: string | number;
  isControlledInput?: boolean;
}

export interface TogglerInputLayoutProps
  extends DefaultInputLayoutProps<boolean | number | string>,
    ValidationLayoutProps,
    InputEventProps<object>,
    InputLayoutStateProps,
    TogglerProps {}

export type TogglerInputLayoutType = ReactComponent<TogglerInputLayoutProps>;

export interface TogglerInputProps extends DefaultInputPropTypes<boolean | number | string>, TogglerProps {}

const TogglerInput = ({ isVisible = true, ...props }: TogglerInputProps) => {
  const inputHelper = useInputHelper(props);
  const { TogglerInputLayout } = useUiTheme<TogglerInputLayoutType>();

  return isVisible ? (
    <TogglerInputLayout
      {...props}
      className={inputHelper.getClassName()}
      label={inputHelper.getLabel()}
      source={inputHelper.getSource()}
      onChangeCallback={inputHelper.getRawOnChangeCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      value={inputHelper.getValue()}
      isRequired={inputHelper.getIsRequired()}
      disabled={inputHelper.getIsDisabled()}
      errorMessages={inputHelper.getErrorMessages()}
    />
  ) : null;
};

export default TogglerInput;
