/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEducationUrl } from 'erp/employee/Education/EducationRouter';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';

const EducationBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useEducationUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Educations" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default EducationBreadcrumbs;
