import CreateBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/CreateBreadcrumbItem';
import React from 'react';
import ViewServiceBudgetsBreadcrumbs
  from 'erp/serviceCatalog/budgets/List/ViewServiceBudgetsBreadcrumbs';

const CreateServiceBudgetBreadcrumbs = () => {
  return (
    <ViewServiceBudgetsBreadcrumbs>
      <CreateBreadcrumbItem entity="Budget Structure" />
    </ViewServiceBudgetsBreadcrumbs>
  );
};

export default CreateServiceBudgetBreadcrumbs;
