import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';

const useSkillsService = () => {
  const { search: findByName } = useBaseCrudService({
    listResourceUrl: `/skills`,
    singleResourceUrl: `/skills/:id`,
    apiService: useKernelApi,
  });

  const searchByName = async (query: any) => {
    const data = await findByName({
      pageNumber: 0,
      pageSize: 30,
      filter: {
        name: {
          ct: query,
        },
        isManagedByProcess: {
          eq: false,
        },
      },
    });

    return data.result;
  };

  return {
    searchByName,
  };
};

export default useSkillsService;
