/* istanbul ignore file */
import EnumField from 'shared/uibuilder/field/EnumField';
import React from 'react';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

export const CANDIDATE_STATUS = {
  DEFAULT: 'Default',
  CANDIDATE: 'Candidate',
  INTERVIEW: 'Interview',
  OFFER: 'Offer',
  ACCEPTED: 'Accepted',
};

const CandidateStatusField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);

  return <EnumField label="Status" value={getValue()} options={CANDIDATE_STATUS} />;
};

export default CandidateStatusField;
