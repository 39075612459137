import React from 'react';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import { HIRING_PATH, HIRING_REQUEST_READ, HIRING_REQUEST_UPDATE } from './useHiringService';
import ProtectedRouter from 'routing/ProtectedRouter';
import { Routes, useParams } from 'react-router-dom';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import ViewHiringRequest from './show/ViewHiringRequest';
import UpdateHiringPage from './createupdate/update/UpdateHiringPage';

export const useHiringRoute = () => {
  const routers = useRoute({ listRoute: HIRING_PATH });

  return {
    ...routers,
  };
};

export const useHiringUrl = () => {
  const { ...restUrls } = useEntityUrl({
    baseLink: HIRING_PATH,
  });

  return {
    ...restUrls,
  };
};

export const useHiringId = () => {
  const { id } = useParams<Dictionary<string>>();
  return id!;
};

const HiringRouter = () => {
  const { singleEntityRoute, updateRoute } = useHiringRoute();

  return (
    <ProtectedRouter basePath={HIRING_PATH}>
      <Routes>
        <Route
          path={updateRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={HIRING_REQUEST_UPDATE}>
              <UpdateHiringPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={HIRING_REQUEST_READ}>
              <ViewHiringRequest />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

HiringRouter.getRouterPath = () => `${HIRING_PATH}/*`;

export default HiringRouter;
