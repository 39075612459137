/* istanbul ignore file */
import React from 'react';
import { EnumField, FieldProps } from 'shared/uibuilder/field';

const statuses = {
  NOT_MARRIED: 'Single',
  MARRIED: 'Married',
};

const EmployeeMaritalStatusField = (props: FieldProps) => {
  return <EnumField {...props} options={statuses} label="Marital Status" />;
};

export default EmployeeMaritalStatusField;
