/* istanbul ignore file */
import React from 'react';
import EnumDropdown, { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';

export const FEEDBACK_FLAG_TYPES_ALIAS = {
  CORRECT: 'CORRECT',
  INAPPROPRIATE: 'INAPPROPRIATE',
};

const options = {
  [FEEDBACK_FLAG_TYPES_ALIAS.CORRECT]: 'Show only correct',
  [FEEDBACK_FLAG_TYPES_ALIAS.INAPPROPRIATE]: 'Show only inappropriate',
};

const EmployeeFeedbackFlagTypeDropdown = (props: Omit<EnumDropdownProps, 'options'>) => {
  return <EnumDropdown {...props} options={options} />;
};

export default EmployeeFeedbackFlagTypeDropdown;
