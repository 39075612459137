import serviceCatalogValidation from 'erp/serviceCatalog/createupdate/serviceCatalogValidation';
import { useServiceManagementApi } from 'api';
import useCrudService from 'shared/crud';
import { ResourceData } from 'shared/crud/baseCrudService';

export const SERVICE_CATALOG_PATH = '/service-catalog';
export const READ_SERVICE_CATALOG = 'GET_SERVICE';
export const READ_SERVICE_CATALOG_LIST = 'SERVICE_MANAGEMENT_SERVICE_LIST_GET_ALL_SERVICES';
export const CREATE_SERVICE_CATALOG = 'SERVICE_MANAGEMENT_SERVICE_CREATE_SERVICE';
export const ARCHIVE_SERVICE_CATALOG = 'SERVICE_MANAGEMENT_SERVICE_ARCHIVE_SERVICE';
export const ASSIGN_SERVICE_CATALOG_OWNER = 'SERVICE_MANAGEMENT_SERVICE_ASSIGN_SERVICE_OWNER';
export const RESOURCE_URL = '/services';

export const SERVICE_CATALOG_STATUSES = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export const SERVICE_CATALOG_STATUSES_OPTIONS = {
  [SERVICE_CATALOG_STATUSES.ACTIVE]: 'Active',
  [SERVICE_CATALOG_STATUSES.ARCHIVED]: 'Archived',
};

export const SERVICE_CATALOG_HEALTH_STATUSES = {
  NORMAL: 'NORMAL',
  WARNING: 'WARNING',
  CRITICAL: 'CRITICAL',
};

const useServiceCatalogService = () => {
  const { sendPostRequest, sendGetRequest } = useServiceManagementApi();

  const {
    search: baseSearch,
    create: initialCreate,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useServiceManagementApi,
  });

  const search = async (request: ResourceData) => {
    return baseSearch(request);
  };

  const getBudgetPermissions = async (id: string) => {
    const result = await sendGetRequest(`/permissions/ServiceBudget/${id}`);
    return result.json();
  };

  const getServiceCatalogPermissions = async (id: string) => {
    const result = await sendGetRequest(`/permissions/Service/${id}`);
    return result.json();
  };

  const create = async (data: any) => initialCreate(data);

  const archiveService = async (id: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/archive`);

    return result.json();
  };

  const assignServiceOwner = async (id: string, owner: string) => {
    const result = await sendPostRequest(`${RESOURCE_URL}/${id}/assign-owner`, {
      owner,
    });

    return result.json();
  };

  const getValidationSchema = () => Promise.resolve(serviceCatalogValidation);

  return {
    ...baseCrud,
    create,
    getValidationSchema,
    search,
    archiveService,
    assignServiceOwner,
    getBudgetPermissions,
    getServiceCatalogPermissions,
  };
};

export default useServiceCatalogService;
