// @ts-nocheck
// libs
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// services
import { useArtifactStorageApi, useFinanceApi } from 'api';
// constants
import { HTTP_METHOD } from 'shared/api/const';
import { API_FILE_TYPE, BYTES_IN_MB, FILE_CATEGORY } from 'artifact/const';
// redux actions
import { appendArtifacts, removeArtifacts, clearArtifacts } from 'artifact/artifactsActions';
import useStorageService from 'shared/uibuilder/storageService';
import useArtifactsInfoReceiver from 'artifact/artifactsInfoReceiver';
import useBaseCrudService from 'shared/crud/baseCrudService';
import moment from 'moment';
import useInvoiceArtifactsInfoReceiver from 'artifact/invoiceArtifactsInfoReceiver';

export const APPEND_ARTIFACTS = 'APPEND_ARTIFACTS';
export const REMOVE_ARTIFACTS = 'REMOVE_ARTIFACTS';
export const CLEAR_ARTIFACTS = 'CLEAR_ARTIFACTS';
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/;

export const getBytesFromMb = (num: number) => num * BYTES_IN_MB;
export const getMbFromBytes = (num: number) => num / BYTES_IN_MB;

export const getFileType = (file: Blob) => {
  const fileCategories = Object.values(FILE_CATEGORY);
  const isImage = FILE_CATEGORY.IMG.mimeTypes.includes(file.type);
  const isKnownType = fileCategories.some(category => category.mimeTypes.includes(file.type));

  let fileType = 'image';

  if (!isImage) {
    if (isKnownType) {
      fileCategories.forEach(category => {
        if (category.mimeTypes.includes(file.type)) {
          Object.values(API_FILE_TYPE).forEach(type => {
            if (type.category === category) {
              fileType = type.alias;
            }
          });
        }
      });
    } else {
      fileType = 'doc';
    }
  }

  return fileType;
};

export const getFilePreview = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const fileCategories = Object.values(FILE_CATEGORY);
    const isImage = FILE_CATEGORY.IMG.mimeTypes.includes(file.type);
    const isKnownType = fileCategories.some(category => category.mimeTypes.includes(file.type));
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event =>
      resolve(
        (() => {
          let filePreview = event.target.result;

          if (!isImage) {
            if (isKnownType) {
              fileCategories.forEach(category => {
                if (category.mimeTypes.includes(file.type)) {
                  filePreview = category.icon;
                }
              });
            } else {
              filePreview = FILE_CATEGORY.DOC.icon;
            }
          }
          return filePreview;
        })(),
      );
    reader.onerror = error => reject(error);
  });
};

const mapToFileData = ({ file, artifactTypeId }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('title', file.name);
  formData.append('artifact_type_id', artifactTypeId);
  return formData;
};

export const READ_ARTIFACT = 'READ';
export const DOWNLOAD_ARTIFACT = 'DOWNLOAD';

export const isLinkExpired = urlString => {
  const url = new URL(urlString);

  const creationDate = moment.utc(url.searchParams.get('X-Amz-Date'));
  const expiresInSecs = Number(url.searchParams.get('X-Amz-Expires'));

  const expiryDate = creationDate.add(expiresInSecs, 'seconds');
  const currentDate = moment.utc();

  return currentDate > expiryDate;
};

const useArtifactService = ({ uploadFile: uploadFileFromProps } = {}) => {
  const { collectArtifactsData } = useArtifactsInfoReceiver();
  const { collectInvoiceArtifactsData } = useInvoiceArtifactsInfoReceiver();

  const dispatch = useDispatch();
  const artifacts = useSelector(state => state.artifacts, shallowEqual);
  const { sendGetRequest, uploadFile } = useArtifactStorageApi();
  const { sendGetRequest: sendFinanceGetRequest } = useFinanceApi();

  const getById = async id => {
    const response = await sendGetRequest(`/artifacts/${id}/preview`);
    return response.json();
  };

  const getPermissions = async id => {
    const url = `/artifacts/${id}/permissions`;
    const response = await sendGetRequest(url);
    return response.json();
  };

  const setArtifactsToStore = artifactsData => dispatch(appendArtifacts(artifactsData));

  const removeArtifactsFromStore = artifactIds => dispatch(removeArtifacts(artifactIds));

  const getArtifactsDataByIds = async ids => {
    const missed = ids.filter(id => !artifacts[id]);

    let missedArtifacts = {};
    if (missed.length) {
      missedArtifacts = await collectArtifactsData(missed);
    }

    setArtifactsToStore(missedArtifacts);

    return (
      ids
        .map(id => artifacts[id] || missedArtifacts[id])
        .map(a => Object.freeze(a))
        .filter(file => file !== undefined) || []
    );
  };

  const getInvoiceArtifactsDataByIds = async (invoiceId, artifactIds) => {
    const missed = artifactIds.filter(id => !artifacts[id]);

    let missedArtifacts = {};
    if (missed.length) {
      missedArtifacts = await collectInvoiceArtifactsData(invoiceId, missed);
    }

    setArtifactsToStore(missedArtifacts);

    return (
      artifactIds
        .map(id => artifacts[id] || missedArtifacts[id])
        .map(a => Object.freeze(a))
        .filter(file => file !== undefined) || []
    );
  };

  const getArtifactDataById = async artifactId => {
    const result = await getArtifactsDataByIds([artifactId]);
    return result[0];
  };

  const clearArtifactsFromStore = () => dispatch(clearArtifacts());

  const getArtifactIcon = artifact => {
    const artifactType = artifact.type.toLowerCase();

    return API_FILE_TYPE[artifactType]
      ? API_FILE_TYPE[artifactType].category.icon
      : API_FILE_TYPE.default.category.icon;
  };

  const downloadArtifact = async id => {
    const response = await sendGetRequest(`/artifacts/${id}/file/content`, HTTP_METHOD.GET, null, null, null, [
      { name: 'responseType', value: 'blob' },
    ]);
    return response.blob();
  };

  const downloadInvoiceArtifact = async id => {
    const response = await sendFinanceGetRequest(
      `/invoices/${id}/artifacts/file/content`,
      HTTP_METHOD.GET,
      null,
      null,
      null,
      [{ name: 'responseType', value: 'blob' }],
    );

    return response.blob();
  };

  const downloadArtifactPreview = async id => {
    const response = await sendGetRequest(`/artifacts/${id}/preview/content`, HTTP_METHOD.GET, null, null, null, [
      { name: 'responseType', value: 'blob' },
    ]);
    return response.blob();
  };

  const downloadInvoiceArtifactPreview = async id => {
    const response = await sendFinanceGetRequest(
      `/invoices/${id}/artifacts/preview/content`,
      HTTP_METHOD.GET,
      null,
      null,
      null,
      [{ name: 'responseType', value: 'blob' }],
    );

    return response.blob();
  };

  const getArtifactThumbnailsFromApi = async id => {
    const artifactsObjects = await collectArtifactsData([id]);

    setArtifactsToStore(artifactsObjects);

    return (artifactsObjects[id] && artifactsObjects[id].thumbnails) || [];
  };

  const getInvoiceArtifactThumbnailsFromApi = async (invoiceId, artifactId) => {
    const artifactsObjects = await collectInvoiceArtifactsData(invoiceId, [artifactId]);

    setArtifactsToStore(artifactsObjects);

    return (artifactsObjects[artifactId] && artifactsObjects[artifactId].thumbnails) || [];
  };

  const getThumbnail = async (id, cropSize) => {
    if (!id) {
      return null;
    }

    const artifact = await getArtifactDataById(id);

    const url = artifact && artifact.thumbnails && artifact.thumbnails[cropSize];

    if (!url || isLinkExpired(url)) {
      const thumbnails = await getArtifactThumbnailsFromApi(id);
      return thumbnails[cropSize];
    } else {
      return url;
    }
  };

  const getInvoiceThumbnail = async (invoiceId, artifactId, cropSize) => {
    if (!artifactId) {
      return null;
    }

    const artifact = await getArtifactDataById(artifactId);

    const url = artifact && artifact.thumbnails && artifact.thumbnails[cropSize];

    if (!url || isLinkExpired(url)) {
      const thumbnails = await getInvoiceArtifactThumbnailsFromApi(invoiceId, artifactId);
      return thumbnails[cropSize];
    } else {
      return url;
    }
  };

  const { findAll: findAllFromApi } = useBaseCrudService({
    listResourceUrl: '/artifact-types',
    singleResourceUrl: '',
    apiService: useArtifactStorageApi,
  });

  const { getDataFromStorage: getArtifactTypeObjects } = useStorageService(
    'artifactTypesObject',
    findAllFromApi,
    '1.7',
  );

  const getArtifactTypeObject = async artifactTypeId => {
    const artifactTypesObject = await getArtifactTypeObjects();

    return artifactTypesObject && artifactTypesObject.find(obj => obj.id === artifactTypeId);
  };

  const uploadArtifact = async ({ file, signal, artifactTypeId, onProgress }) => {
    const { response } = await (uploadFileFromProps || uploadFile)({
      url: `/artifacts`,
      data: mapToFileData({ file, artifactTypeId }),
      eventTarget: signal,
      onProgress,
    });

    const data = JSON.parse(response);
    const { id, type } = data;

    const filePreview = await getFilePreview(file);

    setArtifactsToStore({
      [id]: {
        filePreview,
        id,
        type,
        name: file.name,
        title: file.name,
      },
    });

    return id || null;
  };

  return {
    getArtifactIcon,
    getArtifactsDataByIds,
    getInvoiceArtifactsDataByIds,
    getArtifactDataById,
    downloadArtifact,
    downloadInvoiceArtifact,
    downloadArtifactPreview,
    downloadInvoiceArtifactPreview,
    setArtifactsToStore,
    removeArtifactsFromStore,
    clearArtifactsFromStore,
    uploadArtifact,
    getArtifactTypeObject,
    getThumbnail,
    getInvoiceThumbnail,
    getById,
    getPermissions,
  };
};

export default useArtifactService;
