/* istanbul ignore file */
import { CreateForm } from 'shared/uibuilder/form';
import CreateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/CreateTimelineItemLayout';
import React from 'react';
import NoteForm from 'erp/employee/Timeline/note/form/NoteForm';
import useEmployeeNoteService from 'erp/employee/Timeline/note/employeeNoteService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';

interface CreateNoteFormProps extends FormTemplateProps {
  onCancel: () => void;
  submitFormFunc: (values: FormFieldsData) => Promise<object | void>;
}

const CreateNoteForm = ({ submitFormFunc, onCancel, ...props }: CreateNoteFormProps) => {
  const { getValidationSchema } = useEmployeeNoteService();
  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateTimelineItemLayout onCancel={onCancel}>
        <NoteForm />
      </CreateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateNoteForm;
