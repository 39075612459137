import React from 'react';
import { Action } from 'redux';
import './DataTimer.scss';

export interface DataTimerAction extends Action {
  timerId: string;
}

export type DataTimerState = Dictionary<boolean>;

export enum DataTimerActions {
  SAVE_TIMER = 'SAVE_TIMER',
  CLEAR_TIMER = 'CLEAR_TIMER',
}

export const saveTimer = (timerId: string) => ({
  type: DataTimerActions.SAVE_TIMER,
  timerId,
});

export const clearTimer = (timerId: string) => ({
  type: DataTimerActions.CLEAR_TIMER,
  timerId,
});

const initialState = {};

export const dataTimerReducer = (
  state: DataTimerState = initialState,
  action: DataTimerAction = {} as DataTimerAction,
) => {
  const { type, timerId } = action;

  if (type === DataTimerActions.SAVE_TIMER) {
    return {
      ...state,
      [timerId]: true,
    };
  }

  if (type === DataTimerActions.CLEAR_TIMER) {
    return {
      ...initialState,
      [timerId]: false,
    };
  }

  return state;
};

interface DataTimerProps {
  isActive?: boolean;
  children?: React.ReactElement;
  timerLifetime: number;
}

const BACK_ANIMATION_SPEED = 1000;

const DataTimer = ({ isActive = true, children, timerLifetime }: DataTimerProps) => {
  return (
    <div className="timer__wrapper">
      <span
        className="timer__counter"
        style={{
          transition: `${isActive ? timerLifetime : BACK_ANIMATION_SPEED}ms linear width`,
          width: `${isActive ? 0 : 100}%`,
        }}
      />
      <div className="button__wrapper">{children}</div>
    </div>
  );
};

export default DataTimer;
