import React from 'react';
import List from 'shared/uibuilder/list/List';
import useRecruitingDashboardService from '../useRecruitingDashboardService';
import RecruitingDashboardContent from './RecruitingDashboardContent';
import { HIRING_STATUS } from 'erp/recruitment/hiring/useHiringService';

const RecruitingDashboardListPage = () => {
  const { search } = useRecruitingDashboardService();

  return (
    <List
      getDataMethod={search}
      defaultSortOrder="DESC"
      defaultSortField="createdAt"
      defaultFilter={{
        status: {
          in: [HIRING_STATUS.OPEN],
        },
      }}
    >
      <RecruitingDashboardContent />
    </List>
  );
};

export default RecruitingDashboardListPage;
