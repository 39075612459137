/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useActivityService from 'crm/shared/timeline/activity/crmActivityService';
import useTransferActivityService from 'crm/shared/timeline/activity/shared/transferActivityService';
import TransferCrmActivityToAnotherEntityButton from 'crm/shared/timeline/activity/shared/Button/TransferCrmActivityToAnotherEntityButton';
import transferActivityValidation from 'crm/shared/timeline/activity/shared/transferActivityValidation';
import DeleteTimelineRecordButton from 'uibuilder/layout/timeline/buttons/DeleteTimelineRecordButton';
import PastDueMark from 'crm/shared/timeline/activity/ActivityTimelineRecordLayout/PastDueMark';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import { Collapsible } from 'shared/uibuilder/field';
import ActivityFields from 'crm/shared/timeline/activity/ActivityTimelineRecordLayout/ActivityFields';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import { READ_UNLINKED_CRM_ACTIVITY_PERMISSION } from 'crm/unlinkedEmails/shared/unlinkedCrmActivitiesService';
import AddToBlacklistCrmActivityEmail from 'crm/unlinkedEmails/shared/Button/AddToBlacklistCrmActivityEmail';

const UnlinkedActivityTimelineRecordLayout = ({ onEdit, ...props }: any) => {
  const { data } = useShowContext();
  const { deleteById, addToBlacklist } = useActivityService();
  const { transferActivity } = useTransferActivityService();

  const buttons = [
    <TransferCrmActivityToAnotherEntityButton
      key="transfer"
      permissionToCheck={READ_UNLINKED_CRM_ACTIVITY_PERMISSION}
      submitFormFunc={values => transferActivity(data.getValueBySource('id'), values)}
      timelineId={data.getValueBySource('timelineId')}
      modalTitle="Transfer CRM activity to another entity"
      getValidationSchemaFunc={() => Promise.resolve(transferActivityValidation)}
      isApplicable={!!data.getValueBySource('internetMessageId')}
    />,
    <AddToBlacklistCrmActivityEmail
      permissionToCheck={READ_UNLINKED_CRM_ACTIVITY_PERMISSION}
      deleteMethod={addToBlacklist}
      key="blacklist"
      id={data.getValueBySource('id')}
    />,
    <DeleteTimelineRecordButton
      permissionToCheck={READ_UNLINKED_CRM_ACTIVITY_PERMISSION}
      deleteMethod={deleteById}
      key="delete"
      id={data.getValueBySource('id')}
      timelineId={data.getValueBySource('timelineId')}
      entityName="activity"
    />,
  ];

  const actions = [<PastDueMark key="due-mark" />];

  const type = data.getValueBySource('activityType');

  return (
    <BaseTimelineRecordLayout
      fields={
        <Collapsible>
          <ActivityFields entity={data} />
        </Collapsible>
      }
      entity={data}
      buttons={buttons}
      actions={actions}
      timelineType={TimelineType[type]}
      {...props}
    />
  );
};

export default UnlinkedActivityTimelineRecordLayout;
