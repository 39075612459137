/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useFinanceUrl } from 'erp/employee/finance/FinanceRouter';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';

const FinancesBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useFinanceUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Finances" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default FinancesBreadcrumbs;
