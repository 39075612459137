import React from 'react';
import { get } from 'lodash';
import { AutosuggestDropdown } from 'shared/uibuilder/form/input';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';

export const PAGE_SIZE = 30;

export interface SearchInputProps {
  source?: string;
  initialLabel?: Nullable<string>;
  nameSource?: string;
  searchString?: any;
  defaultFilter?: Object;
  noResultMessage?: string;
  mapResults?: (data: any) => any;
  searchRequest: (Object: Object) => Object;
  props?: any;
  onChangeCallback?: (values: FormFieldsData) => void;
}

const SearchInput = ({
  defaultFilter = {},
  searchRequest,
  searchString = 'searchString',
  initialLabel,
  nameSource,
  ...props
}: SearchInputProps) => {
  const { data = {} } = useFormContext();
  const initialValue = initialLabel || (nameSource && get(data, nameSource)) || data[nameSource || 0];

  const loadSuggestions = (searchValue: any) => {
    const searchFilter = {
      [searchString]: { ct: searchValue },
    };

    return searchRequest({
      filter: {
        ...searchFilter,
        ...defaultFilter,
      },
      pageSize: PAGE_SIZE,
    });
  };

  return (
    // @ts-ignore
    <AutosuggestDropdown
      loadSuggestionsMethod={loadSuggestions}
      resultsPerPage={PAGE_SIZE}
      initialValue={initialValue}
      searchRequest={searchRequest}
      {...props}
    />
  );
};

export default SearchInput;
