// libs
import React from 'react';
// components
import FilePreviewLayout from 'artifact/shared/input/layout/preview/FilePreviewLayout';
// services
import useArtifactService from 'artifact/artifactService';
import useAsyncValue from 'shared/asyncHelper';
import InputLoader from 'shared/uibuilder/InputLoader';

import './FilePreviewListLayout.scss';

/**
 * Artifact
 * @param artifactsIdsList - list of artifacts id's
 * @param onRemove(id) - function that removes artifact from the context
 * @constructor
 */

type FilePreviewListLayoutProps = {
  artifactsIdsList: string[];
  onRemove: (id: string) => void;
  disabled?: boolean;
};

const FilePreviewListLayout = ({ artifactsIdsList, onRemove, disabled = false }: FilePreviewListLayoutProps) => {
  const { getArtifactsDataByIds } = useArtifactService();

  const artifactsObjects = useAsyncValue(() => getArtifactsDataByIds(artifactsIdsList), [artifactsIdsList]);

  if (!artifactsIdsList?.length) {
    return null;
  }

  return artifactsObjects ? (
    artifactsObjects.map((artifactObject: Dictionary<any>) => {
      return (
        <FilePreviewLayout
          key={artifactObject.id}
          onRemove={() => onRemove(artifactObject.id)}
          artifactObject={artifactObject}
          disabled={disabled}
        />
      );
    })
  ) : (
    <div className="file-preview__loading">
      <InputLoader />
    </div>
  );
};

export default FilePreviewListLayout;
