/* istanbul ignore file */
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import React from 'react';
import { getSourceOptions } from 'erp/candidate/applications/shared/candidateApplicationService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const CandidateApplicationSourceDropdown = ({
  label: labelInput = 'Candidate Application Source',
  ...props
}: DefaultDropdownProps) => {
  return (
    <Dropdown
      placeholder="Choose Candidate Application Source"
      options={Object.entries(getSourceOptions()).map(entry => {
        const [value, label] = entry;
        return {
          value,
          label,
        };
      })}
      label={labelInput}
      {...props}
    />
  );
};

export default CandidateApplicationSourceDropdown;
