import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

import EmploymentHistoryFieldLayout from '../layout/EmploymentHistoryFieldLayout';
import { FieldProps } from 'shared/uibuilder/field';

const EmploymentHistoryField = ({ source, value, label }: FieldProps) => {
  const { getValue, getLabel } = useFieldHelper<any>({ source, value, label });

  const current = getValue()?.current;
  const previous = getValue()?.previous;

  return <EmploymentHistoryFieldLayout label={getLabel()} current={current} previous={previous} />;
};

export default EmploymentHistoryField;
