/* istanbul ignore file */
import React from 'react';
import EmployeeContractsBreadCrumbs from 'erp/employee/contracts/shared/ContractsBreadcrumbs';
import { SingleBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useEmployeeContractId, useEmployeeContractUrl } from 'erp/employee/contracts/EmployeeContractsRouter';

const SingleContractsBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const id = useEmployeeContractId();
  const { getSingleEntityUrl } = useEmployeeContractUrl();

  return (
    <EmployeeContractsBreadCrumbs>
      <SingleBreadcrumbItem entity={id} link={getSingleEntityUrl(id)} />
      {children}
    </EmployeeContractsBreadCrumbs>
  );
};

export default SingleContractsBreadcrumbs;
