import useBudgetingService, {
  BUDGETING_CREATE_STRUCTURE,
} from 'erp/serviceCatalog/budgets/budgetingService';
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ServiceBudgetEntityHeader from 'erp/serviceCatalog/budgets/shared/ServiceBudgetEntityHeader';
import { useServiceCatalogId } from 'erp/serviceCatalog/ServiceCatalogRouter';
import useServiceCatalogService from 'erp/serviceCatalog/useServiceCatalogService';
import ViewServiceCatalogPageMenu from 'erp/serviceCatalog/Show/ViewServiceCatalogPageMenu';
import ViewServiceBudgetStructureBreadcrumbs from 'erp/serviceCatalog/budgets/structure/Show/ViewServiceBudgetStructureBreadcrumbs';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import useServiceBudgetStructureRegistry from '../serviceBudgetStructureRegistry';
import useAuthorization from 'shared/authorization/authorizationService';

const ViewServiceBudgetStructure = () => {
  const id = useServiceCatalogId();
  const { getById } = useServiceCatalogService();
  const { searchStructure } = useBudgetingService();
  const { isGranted } = useAuthorization();

  const budgetStructureRegistry = useServiceBudgetStructureRegistry();

  return (
    <Show getDataMethod={() => getById(id)}>
      <Timeline registries={[budgetStructureRegistry] as any} getDataMethod={async req => searchStructure(id, req)}>
        <TimelinePageLayout
          title="Budgets Structure"
          hasAddForm={isGranted(BUDGETING_CREATE_STRUCTURE)}
          breadcrumbs={<ViewServiceBudgetStructureBreadcrumbs />}
          entityHeader={<ServiceBudgetEntityHeader />}
          menu={<ViewServiceCatalogPageMenu />}
        >
          <TimelineLayout noEntriesMessage="No entries in this budgets structure yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default ViewServiceBudgetStructure;
