/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import useProjectStatusesService from 'erp/project/statuses/ProjectStatusesService';
import ProjectPlanningStatusForm from 'erp/project/statuses/planning/form/ProjectPlanningStatusForm';
import { UpdateFormProps } from 'shared/uibuilder/form/UpdateForm';

interface UpdateProjectPlanningStatusFormProps extends UpdateFormProps {
  onCancel: () => void;
}

const UpdateProjectPlanningStatusForm = ({ getDataFunc, onCancel, ...props }: UpdateProjectPlanningStatusFormProps) => {
  const { getDemoValidationSchema, update } = useProjectStatusesService();

  return (
    <UpdateForm
      {...props}
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      getValidationSchemaFunc={getDemoValidationSchema}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <ProjectPlanningStatusForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

export default UpdateProjectPlanningStatusForm;
