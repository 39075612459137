import { useLeadId } from 'crm/lead/LeadRouter';
import { useKernelApi } from 'api';
import { ResourceData } from 'shared/crud/baseCrudService';

export const TEMPLATE_TYPES = {
  LEAD_NDA: 'LEAD_NDA',
};

export const SEND_TEMPLATED_ENVELOPE = 'SEND_TEMPLATED_ENVELOPE';

const useLeadTemplatedEnvelopeService = () => {
  const { sendPostRequest } = useKernelApi();
  const leadId = useLeadId();
  const url = `/crm/lead/${leadId}/envelope/templated/send`;

  const sendTemplatedEnvelope = async (data: ResourceData) => {
    const response = await sendPostRequest(url, data);
    return response.json();
  };

  return {
    sendTemplatedEnvelope,
  };
};

export default useLeadTemplatedEnvelopeService;
