/* istanbul ignore file */
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import useAuthorization from 'shared/authorization/authorizationService';

type EditTimelineRecordButtonProps = {
  onEdit: () => void;
  permissionToCheck: string;
};

const EditTimelineRecordButton = ({ onEdit, permissionToCheck }: EditTimelineRecordButtonProps) => {
  const { isGranted } = useAuthorization();

  return isGranted(permissionToCheck) ? <MenuItem onClick={onEdit}>Edit</MenuItem> : null;
};

export default EditTimelineRecordButton;
