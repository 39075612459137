import React from 'react';
import AddIcon from '@mui/icons-material/AddOutlined';
import LinkButton from 'uibuilder/button/LinkButton';
import useAuthorization from 'shared/authorization/authorizationService';

interface CreateButtonProps {
  url: string | ((id?: Nullable<StringOrNumber>) => string);
  permissionToCheck: string | string[];
}

const UploadEmployeeExpensesButton = ({ url, permissionToCheck, ...props }: CreateButtonProps) => {
  const { isGranted } = useAuthorization();

  return isGranted(permissionToCheck) ? (
    <LinkButton
      {...props}
      url={url}
      className="button create-btn"
      color="primary"
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      variant="outlined"
      startIcon={<AddIcon />}
    >
      Upload employee expenses
    </LinkButton>
  ) : null;
};

export default UploadEmployeeExpensesButton;
