/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ServiceCatalogListPageContent from 'erp/serviceCatalog/List/ServiceCatalogListPageContent';
import useServiceCatalogService, { SERVICE_CATALOG_STATUSES } from 'erp/serviceCatalog/useServiceCatalogService';

const ServiceCatalogListPage = () => {
  const { search } = useServiceCatalogService();

  return (
    <List getDataMethod={search} defaultFilter={{ status: { ne: SERVICE_CATALOG_STATUSES.ARCHIVED } }}>
      <ServiceCatalogListPageContent />
    </List>
  );
};

export default ServiceCatalogListPage;
