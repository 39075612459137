import React, { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import invariant from 'tiny-invariant';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import type { DraggableChildrenParams } from './dndTypes';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';

interface DroppableItemProps {
  children: ({ isDragging, draggableRef }: DraggableChildrenParams) => React.ReactNode;
  initialData?: Record<string, unknown>;
  preview?: React.ReactNode;
  isHandleRef?: boolean;
  canDrag?: boolean;
  params?: {
    onDragStart?: () => void;
    onDrop?: () => void;
  };
}

type DraggableState =
  | {
      type: 'idle';
    }
  | {
      type: 'preview';
      container: HTMLElement;
    }
  | {
      type: 'dragging';
    };

const Draggable = memo(
  ({ children, initialData, params, preview, canDrag = true, isHandleRef }: DroppableItemProps) => {
    const draggableRef = useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = useState(false);
    const [state, setState] = useState<DraggableState>({ type: 'idle' });

    useEffect(() => {
      const el = draggableRef.current;
      invariant(el, 'draggable ref is not defined');

      return draggable({
        element: el,
        getInitialData: () => initialData || {},
        onDragStart: () => {
          setDragging(true);
          params?.onDragStart?.();
        },
        onDrop: () => {
          setDragging(false);
          params?.onDrop?.();
        },
        canDrag: () => canDrag,
        onGenerateDragPreview: preview
          ? ({ nativeSetDragImage }) => {
              setCustomNativeDragPreview({
                getOffset: pointerOutsideOfPreview({
                  x: '16px',
                  y: '8px',
                }),
                nativeSetDragImage,
                render({ container }) {
                  setState({ type: 'preview', container });
                  return () => setState({ type: 'dragging' });
                },
              });
            }
          : undefined,
      });
    }, [canDrag, initialData, params, preview]);

    return (
      <div ref={!isHandleRef ? draggableRef : null}>
        {children({ isDragging: dragging, draggableRef: isHandleRef ? draggableRef : null })}
        {state.type === 'preview' && preview ? createPortal(preview, state.container) : null}
      </div>
    );
  },
);

export default Draggable;
