import { useDispatch, useSelector } from 'react-redux';

export const SET_PROJECTS_SETTING = 'SET_PROJECTS_SETTING';

export type Settings = {
  [key: string]: any;
};

export const setSetting = (payload: any) => ({
  type: SET_PROJECTS_SETTING,
  payload,
});

export interface ProjectsSettingState {
  isArchivedProjectsVisible: boolean;
}

const initialState = {
  isArchivedProjectsVisible: false,
};

export const projectsSettingsReducer = (state = initialState, action: any = {}) => {
  if (action.type === SET_PROJECTS_SETTING) {
    return {
      ...state,
      ...action.payload,
    };
  }

  return state;
};

const useProjectsSettings = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state: { projectsSettings: ProjectsSettingState }) => state?.projectsSettings);
  const { isArchivedProjectsVisible }: Settings = settings;

  const updateSettings = (updatedSettings: Settings) => {
    dispatch(
      setSetting({
        ...settings,
        ...updatedSettings,
      }),
    );
  };

  const setArchivedProjectsVisible = (isChecked: boolean) => {
    updateSettings({
      isArchivedProjectsVisible: isChecked,
    });
  };

  return {
    setArchivedProjectsVisible,
    areArchivedProjectsVisible: isArchivedProjectsVisible,
  };
};

export default useProjectsSettings;
