/* istanbul ignore file */
import React from 'react';
import { useFilterContext } from '../../../../../shared/uibuilder/list/filter/FilterContext';
import FilterInputDecorator from '../../../../../shared/uibuilder/list/filter/FilterInputDecorator';
import DropDownFilter from '../../../../../shared/uibuilder/list/filter/DropDownFilter';
import CandidateTimelineRecordTypeDropdown from '../input/CandidateTimelineRecordTypeDropdown';

interface CandidateTimelineRecordTypeFilterProps {
  cleanableFilters: any[];
  source: string;
  label: string;
}

const CandidateTimelineRecordTypeFilter = ({
  cleanableFilters = [],
  source,
  ...props
}: CandidateTimelineRecordTypeFilterProps) => {
  const { filterBy } = useFilterContext();

  const onChangeCallback = (filter: any) => {
    const filters = {
      ...filter,
    };

    cleanableFilters.forEach(clearableFilter => {
      filters[clearableFilter] = {};
    });

    filterBy(filters);
  };

  return (
    <DropDownFilter {...props} source={source} isSingle onChangeCallback={onChangeCallback}>
      <FilterInputDecorator cleanableFilters={[...cleanableFilters, source]}>
        <CandidateTimelineRecordTypeDropdown />
      </FilterInputDecorator>
    </DropDownFilter>
  );
};

export default CandidateTimelineRecordTypeFilter;
