/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem, Breadcrumbs } from 'shared/uibuilder/BreadCrumbs';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

const CandidatesBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useCandidateUrl();

  return (
    <Breadcrumbs>
      <ListBreadcrumbItem entity="Candidates" link={getListUrl()} />
      {children}
    </Breadcrumbs>
  );
};

export default CandidatesBreadcrumbs;
