import React from 'react';
import { useParams } from 'react-router-dom';
import FormTemplate from 'shared/uibuilder/form/FormTemplate';
import { ResourceData } from 'shared/crud/baseCrudService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { AfterSubmitProps, CommonFormProps } from 'shared/uibuilder/form/formHelper';

export interface UpdateFormProps extends CommonFormProps {
  children: React.ReactElement | React.ReactElement[];
  submitFormFunc: (id: StringOrNumber, values: FormFieldsData) => Promise<object>;
  getDataFunc: (id: string) => Promise<ResourceData>;
  idSource?: Nullable<string>;
  entityId?: Nullable<string>;
  afterSubmit?: AfterSubmitProps<(id: string, data: object) => any, string | ((data: object) => any)>;
}

const UpdateForm = ({
  submitFormFunc,
  getDataFunc,
  children,
  entityId,
  idSource = null,
  afterSubmit,
  ...props
}: UpdateFormProps) => {
  const params: any = useParams<Dictionary<string>>();
  const id = entityId || params[idSource || 'id'];

  return (
    <FormTemplate
      {...props}
      getDataFunc={() => getDataFunc(id)}
      data-testId={id}
      afterSubmit={
        afterSubmit && {
          message: afterSubmit.message,
          redirect:
            typeof afterSubmit.redirect === 'function'
              ? afterSubmit.redirect
              : afterSubmit.redirect && afterSubmit.redirect.replace(':id', id),
          execute: afterSubmit.execute && (data => afterSubmit.execute && afterSubmit.execute(id, data)),
        }
      }
      submitFormFunc={data => submitFormFunc(id, data)}
      isCreateRequest={false}
    >
      {children}
    </FormTemplate>
  );
};

export default UpdateForm;
