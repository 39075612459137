/* istanbul ignore file */
import React, { useState } from 'react';
import moment from 'moment';
import useInvoiceService, {
  MARK_AS_PAID_INVOICE,
  INVOICE_STATUSES,
  MARK_AS_PAID_INVOICE_PARTIALLY,
} from 'financialAnalytic/invoice/useInvoiceService';
import { useParams } from 'react-router-dom';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { Checkbox, DateInput, TextInput } from 'shared/uibuilder/form/input';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';
import { useSensitiveDataContext } from 'shared/uibuilder/sensitiveData/SensitiveDataContext';

const MarkAsPaidInvoiceButton = ({
  statusSource,
  isSensitiveDataIsShown,
}: {
  statusSource: string;
  isSensitiveDataIsShown?: boolean;
}) => {
  const { id } = useParams<Dictionary<string>>();
  const { reloadSensitiveData } = useSensitiveDataContext();
  const { markAsPaidInvoice, invalidateCache } = useInvoiceService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();
  const [payoutDateErrors, setPayoutDateErrors] = useState<string[]>([]);
  const { getToday, formatDateForAPI } = useDateService();
  const [payoutDate, setPayoutDate] = useState(getToday());
  const [isPartialPayment, setPartialPayment] = useState(false);
  const [partialPaymentSum, setPartialPaymentSum] = useState<number>();
  const [partialPaymentSumErrors, setPaymentSumErrors] = useState<string[]>([]);

  const [bankTransactionId, setBankTransactionId] = useState<string>('');
  const { setData = () => {} } = useShowContext();

  if (status !== INVOICE_STATUSES.APPROVED && status !== INVOICE_STATUSES.PARTIALLY_PAID) {
    return null;
  }

  const onPayoutDateChange = (values: FormFieldsData) => {
    setPayoutDate(values.payoutDate);
    const isValid = moment(values.payoutDate, DATE_FORMAT.API_ONLY_DATE, true).isValid();

    if (!values.payoutDate) {
      setPayoutDateErrors(['Field is required']);
    } else if (!isValid) {
      setPayoutDateErrors(['Please, choose the correct date']);
    } else {
      setPayoutDateErrors([]);
    }
  };

  const onCheckboxChange = (values: FormFieldsData) => {
    if (!values.isPartialPayment) {
      setPartialPaymentSum(undefined);
      setPaymentSumErrors([]);
    }
    setPartialPayment(values.isPartialPayment);
  };

  const onPartialPaymentSum = (values: FormFieldsData) => {
    setPartialPaymentSum(values.partialPaymentSum);

    if (values.partialPaymentSum <= 0) {
      setPaymentSumErrors(['The payment sum must be greater than 0.']);
    } else {
      setPaymentSumErrors([]);
    }
  };

  const onBankTransactionIdCallback = (values: FormFieldsData) => {
    setBankTransactionId(values.bankTransactionId);
  };

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to mark this invoice as paid? Please fill out the inputs below."
      modalChildren={
        <div className="mt-5">
          <DateInput
            source="payoutDate"
            value={payoutDate as any}
            label="Payout date"
            onChangeCallback={onPayoutDateChange}
            errorMessages={payoutDateErrors}
            isRequired
          />
          <TextInput
            source="bankTransactionId"
            label="Bank Transaction ID"
            value={bankTransactionId}
            onChangeCallback={onBankTransactionIdCallback}
          />
          <>
            <Checkbox
              source="isPartialPayment"
              value={isPartialPayment}
              label="Partial Payment"
              onChangeCallback={onCheckboxChange}
            />

            <NumberInput
              label="Partial Payment Sum"
              source="partialPaymentSum"
              value={partialPaymentSum}
              onChangeCallback={onPartialPaymentSum}
              isVisible={isPartialPayment}
              errorMessages={partialPaymentSumErrors}
              isRequired
            />
          </>
        </div>
      }
      submitMethod={() =>
        markAsPaidInvoice(id!, {
          invoicePaidDate: formatDateForAPI(payoutDate),
          bankTransactionId,
          isPartialPayment,
          partialPaymentSum: isPartialPayment ? partialPaymentSum : undefined,
        })
      }
      afterSubmit={{
        successMessage: 'The Invoice has been successfully marked as paid.',
        errorMessage: "Can't mark invoice as paid.",
        execute: (data: any) => {
          invalidateCache();
          if (isSensitiveDataIsShown) {
            reloadSensitiveData();
          }
          setData(data);
        },
      }}
      permissionToCheck={[MARK_AS_PAID_INVOICE, MARK_AS_PAID_INVOICE_PARTIALLY]}
      variant="outlined"
      confirmBtnProps={{
        disabled:
          !payoutDate ||
          (isPartialPayment && !partialPaymentSum) ||
          payoutDateErrors.length > 0 ||
          partialPaymentSumErrors.length > 0,
      }}
    >
      Mark as paid
    </ButtonWithConfirmation>
  );
};

export default MarkAsPaidInvoiceButton;
