/* istanbul ignore file */
import React, { useState } from 'react';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useAuthorization from 'shared/authorization/authorizationService';
import MenuItem from '@mui/material/MenuItem';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { ValidationSchema } from 'validation-schema-library';

export type TransferTimelineRecordToAnotherEntityButtonProps = {
  permissionToCheck: Nullable<string | string[]>;
  submitFormFunc: (values: FormFieldsData) => Promise<object | void>;
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  timelineId: string;
  modalTitle: string;
  getValidationSchemaFunc?: () => Promise<ValidationSchema>;
  additionalAfterSubmit?: (response: any) => void;
};

const TransferTimelineRecordToAnotherEntityButton = ({
  permissionToCheck,
  submitFormFunc,
  timelineId,
  modalTitle,
  children,
  getValidationSchemaFunc,
  additionalAfterSubmit,
}: TransferTimelineRecordToAnotherEntityButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isGranted } = useAuthorization();
  const { deleteFromList } = useListContext();
  const closeModal = () => setModalOpen(false);

  return permissionToCheck && isGranted(permissionToCheck) ? (
    <>
      <MenuItem onClick={() => setModalOpen(true)}>Transfer</MenuItem>
      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title={modalTitle}
        onToggle={closeModal}
        hasCancelButton={false}
        className="modal-content"
      >
        <ModalForm
          modalOpen={modalOpen}
          closeModal={closeModal}
          submitFormFunc={submitFormFunc}
          afterSubmit={{
            execute: response => {
              if (deleteFromList) {
                deleteFromList(timelineId);
              }
              if (additionalAfterSubmit) {
                additionalAfterSubmit(response);
              }
            },
          }}
          getValidationSchemaFunc={getValidationSchemaFunc}
        >
          {children}
        </ModalForm>
      </ModalWindow>
    </>
  ) : null;
};

export default TransferTimelineRecordToAnotherEntityButton;
