import { useKernelApi } from 'api';
import { ResourceId } from 'shared/crud/baseCrudService';

export const READ_UNLINKED_CRM_ACTIVITY_PERMISSION = 'CRM_TIMELINE_ENTRY_READ_UNLINKED_CRM_ACTIVITIES';
const useUnlinkedCrmActivitiesService = () => {
  const { sendPostRequest } = useKernelApi();

  const searchTimelineItems = async (request: any) => {
    const response = await sendPostRequest('/crm/unlinked-activities/timeline/search', request);

    return response.json();
  };

  const getTimelinePageById = async (timelineId: ResourceId) => {
    const request = { pageSize: 30, filter: {}, sort: { createdAt: 'DESC' } };
    const response = await sendPostRequest(`/crm/unlinked-activities/timeline/${timelineId}`, request);

    return response.json();
  };

  return {
    searchTimelineItems,
    getTimelinePageById,
  };
};

export default useUnlinkedCrmActivitiesService;
