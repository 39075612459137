/* istanbul ignore file */
import React from 'react';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { Routes, useParams } from 'react-router-dom';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { Route } from 'shared/routing';
import { READ, UPDATE } from 'crm/crmService';
import Page404 from 'shared/uibuilder/Page404';
import {
  CREATE_CANDIDATE_OFFER,
  READ_LIST_CANDIDATE_OFFER,
  UPDATE_CANDIDATE_OFFER_ATTACHMENTS,
} from 'erp/candidate/shared/candidateService';
import CandidateOfferContext from 'erp/candidate/offercompensation/CandidateOfferContext';
import CandidateOfferList from 'erp/candidate/offercompensation/List';
import { Create, Update } from 'erp/candidate/offercompensation/createupdate';
import ViewCandidateOffer from 'erp/candidate/offercompensation/Show';

export const CANDIDATE_OFFER_ID = 'candidateOfferId';
export const PATH = '/offers';

export const useCandidateOfferRoute = () => {
  return useRoute({
    listRoute: `$${PATH}`,
    singleEntityRoute: `/:${CANDIDATE_OFFER_ID}`,
  });
};

export const useCandidateOfferUrl = () => {
  const { getSingleEntityUrl } = useCandidateUrl();
  const candidateLink = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${candidateLink}${PATH}`,
    idSource: CANDIDATE_OFFER_ID,
  });
};

export const useCandidateOfferId = () => {
  const params: any = useParams<Dictionary<string>>();
  return params.candidateOfferId;
};

const CandidateOfferRouter = () => {
  const { listRoute, createRoute, updateRoute, singleEntityRoute } = useCandidateOfferRoute();

  return (
    <Routes>
      <Route
        path={listRoute}
        element={
          <GlobalProtectedRoute globalPermissionToCheck={READ_LIST_CANDIDATE_OFFER}>
            <CandidateOfferList />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={createRoute}
        element={
          <GlobalProtectedRoute globalPermissionToCheck={CREATE_CANDIDATE_OFFER}>
            <Create />
          </GlobalProtectedRoute>
        }
      />

      <Route
        path={updateRoute}
        element={
          <CandidateOfferContext permissionToCheck={[UPDATE, UPDATE_CANDIDATE_OFFER_ATTACHMENTS]}>
            <Update />
          </CandidateOfferContext>
        }
      />

      <Route
        path={singleEntityRoute}
        element={
          <CandidateOfferContext permissionToCheck={READ}>
            <ViewCandidateOffer />
          </CandidateOfferContext>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default CandidateOfferRouter;
