/* istanbul ignore file */

import { ACCOUNTS_WITH_BUDGET_CATEGORIES } from 'erp/serviceCatalog/budgets/shared/service/budgetCatagorySuggestionService';

export default {
  invoiceNumber: {
    type: 'string',
    required: {
      value: true,
      when: {
        $isInvoiceDocumentWillArriveLater: {
          eq: false,
        },
      },
    },
  },
  name: {
    type: 'string',
    required: true,
  },
  office: {
    type: 'string',
    required: true,
  },
  description: {
    type: 'string',
    maxSize: 1024,
  },
  subTotal: {
    type: 'object',
    _divisible: false,
    properties: {
      amount: {
        type: 'string',
        required: true,
      },
      currency: {
        type: 'string',
        required: true,
      },
    },
  },
  invoiceDate: {
    type: 'date',
    required: true,
  },
  invoiceDocumentId: {
    type: 'array',
    required: {
      value: true,
      when: {
        $isInvoiceDocumentWillArriveLater: {
          eq: false,
        },
      },
    },
    maxSize: 1,
  },
  account: {
    type: 'string',
    required: {
      value: true,
      when: {
        $purchaseOrderId: {
          empty: true,
        },
      },
    },
  },
  budgetCategory: {
    type: 'string',
    required: {
      value: true,
      when: {
        $account: {
          in: ACCOUNTS_WITH_BUDGET_CATEGORIES,
        },
      },
    },
  },
  isPurchaseClassifiedAsAsset: {
    type: 'string',
    required: true,
  },
  references: {
    type: 'array',
    forEach: {
      type: 'object',
      properties: {
        referenceType: {
          type: 'string',
          required: true,
        },
        referenceValue: {
          type: 'string',
          required: true,
        },
      },
    },
  },
};
