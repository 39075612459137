/* istanbul ignore file */
import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ViewBudgetPageMenu from 'erp/budget/Show/ViewBudgetPageMenu';
import BudgetEntityHeader from 'erp/budget/shared/BudgetEntityHeader';
import SingleBudgetBreadcrumbs from 'erp/budget/shared/SingleBudgetBreadcrumbs';
import EditBudgetButton from 'erp/budget/shared/button/EditBudgetButton';
import DeleteBudgetButton from 'erp/budget/shared/button/DeleteBudgetButton';
import { DateField, NumberField, TextField } from 'shared/uibuilder/field';
import { EmployeeLinkField } from 'erp/employee';
import useBudgetService, { UPDATE_BUDGET } from 'erp/budget/shared/budgetService';
import { useBudgetId } from 'erp/budget/BudgetRouter';
import BudgetLinkField from 'erp/budget/shared/field/BudgetLinkField';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import BudgetChildLinkField from 'erp/budget/shared/field/BudgetChildLinkField';
import useFeatureToggle, { Features } from 'featuretoggle';

const ViewBudget = () => {
  const id = useBudgetId()!;
  const { getById } = useBudgetService();
  const { isFeatureEnabled } = useFeatureToggle();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        headerButtons={[
          <EditBudgetButton key="edit-budget" icon={<i className="fa fa-edit" />} permissionToCheck={UPDATE_BUDGET} />,
          <DeleteBudgetButton key="delete-budget" />,
        ]}
        menu={<ViewBudgetPageMenu />}
        breadcrumbs={<SingleBudgetBreadcrumbs />}
        entityHeader={<BudgetEntityHeader />}
        hasSeparateSections
      >
        <ShowSection title="Budget Information">
          <SectionRow>
            <TextField label="Name" source="name" />
            <EmployeeLinkField source="ownerId" nameSource="ownerName" label="Owner" />
          </SectionRow>
          <SectionRow>
            <DateField source="periodFrom" label="Start Date" />
            <DateField source="periodTo" label="End Date" />
          </SectionRow>
          <SectionRow>
            <DateField
              source="softPeriodFrom"
              label="Soft Start Date"
              isVisible={(data: any) => !!data.softPeriodFrom}
            />
            <DateField source="softPeriodTo" label="Soft End Date" isVisible={(data: any) => !!data.softPeriodTo} />
          </SectionRow>
          <SectionRow>
            <TextField label="Notes" source="purpose" />
          </SectionRow>
          <SectionRow>
            <FieldListLayout source="childBudgets" label="Child Budgets">
              <BudgetChildLinkField />
            </FieldListLayout>
            <BudgetLinkField label="Parent Budget" source="parentId" nameSource="parentName" />
            {isFeatureEnabled(Features.VIEW_BUDGETS_FTE) ? (
              <NumberField source="maxFte" label="Max FTE, people" displayZeroValue={false} />
            ) : (
              <></>
            )}
          </SectionRow>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewBudget;
