import { useProjectId, useProjectUrl } from '../ProjectRouter';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { useDeliveryApi } from 'api';
import { useCallback } from 'react';
import uploadWBSValidationSchema from './UploadWBS/uploadWBSValidationSchema';
import { CONTENT_TYPE } from 'shared/api/const';

export const PATH = '/wbs-dashboard';

export const CREATE_WBS = 'CREATE_WBS';
export const READ_WBS = 'READ_WBS';

export const useWBSProjectDashboardUrl = () => {
  const { getSingleEntityUrl } = useProjectUrl();
  const projectLink = getSingleEntityUrl();
  const listUrl = `${projectLink}${PATH}`;
  const baseUrls = useEntityUrl({
    baseLink: `${projectLink}${PATH}`,
  });

  const getListUrl = () => listUrl;

  return {
    ...baseUrls,
    getListUrl,
  };
};

const useProjectsWBSDashboardService = () => {
  const { getSingleEntityUrl } = useProjectUrl();
  const { sendPostRequest, sendGetRequest } = useDeliveryApi();
  const projectId = useProjectId();
  const projectLink = getSingleEntityUrl(projectId);

  const uploadWBS = useCallback(
    async (data: any) => {
      const formData = new FormData();
      formData.append('wbsFile', data.file[0].file);
      return sendPostRequest(`${projectLink}/wbs/upload`, formData, CONTENT_TYPE.MULTIPART);
    },
    [projectLink, sendPostRequest],
  );

  const getLastWBS = useCallback(async () => {
    try {
      const res = await sendGetRequest(`${projectLink}/wbs/last`);

      const text = await res.text();

      if (!text) {
        return {};
      }

      return JSON.parse(text);
    } catch (error) {
      return error;
    }
  }, [projectLink, sendGetRequest]);

  const getWBSDashboard = useCallback(
    async (wbsId: string) => {
      return sendGetRequest(`${projectLink}/wbs/${wbsId}/dashboard`);
    },
    [projectLink, sendGetRequest],
  );

  const getWBSDashboardList = useCallback(async () => {
    try {
      const resWbs = await getLastWBS();

      if (!resWbs.id) {
        return {};
      }

      const response = await getWBSDashboard(resWbs.id);

      return response.json();
    } catch (e) {
      return e;
    }
  }, [getLastWBS, getWBSDashboard]);

  const getValidationSchema = () => Promise.resolve(uploadWBSValidationSchema);

  return {
    uploadWBS,
    getLastWBS,
    getWBSDashboard,
    getWBSDashboardList,
    getValidationSchema,
  };
};

export default useProjectsWBSDashboardService;
