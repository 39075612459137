/* istanbul ignore file */
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import useNoteService from 'crm/shared/timeline/note/crmNoteService';
import NoteForm from 'crm/shared/timeline/note/form/NoteForm';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import { UpdateFormProps } from 'shared/uibuilder/form/UpdateForm';

interface UpdateNoteFormProps extends Omit<UpdateFormProps, 'submitFormFunc'> {
  service: () => any;
  onCancel: () => void;
}

const UpdateNoteForm = ({ getDataFunc, onCancel, ...props }: UpdateNoteFormProps) => {
  const { getValidationSchema, update } = useNoteService();

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      getValidationSchemaFunc={getValidationSchema}
      {...props}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <NoteForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

export default UpdateNoteForm;
