import React from 'react';
import { useHiringId } from 'erp/recruitment/hiring/HiringRouter';
import useHiringService from 'erp/recruitment/hiring/useHiringService';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

type CancelHiringButtonProps = {
  permissionToCheck: string,
};

const CancelHiringButton = ({ ...props }: CancelHiringButtonProps) => {
  const id = useHiringId();
  const { data, setData = () => null } = useShowContext();
  const { cancelRequest } = useHiringService();

  const isCancelled = () => data.getValueBySource('status') === 'CANCELED';

  const handleClosePosition = async () => {
    const response = await cancelRequest(id);
    setData(response);
  };

  if (isCancelled()) {
    return null;
  }

  return <ButtonWithConfirmation
    {...props}
    displayMessage="Do you really want to cancel this request?"
    submitMethod={handleClosePosition}
    afterSubmit={{
      successMessage: 'Request cancelled successfully!',
      errorMessage: "Request couldn't be cancelled!",
    }}
  >
    Cancel
  </ButtonWithConfirmation>;
};

export default CancelHiringButton;
