/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { useDateTimeService } from 'shared/uibuilder/dateService';

import TimelineRecordAuthor from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordAuthor';

const TimelineRecordFooter = ({ isUpdateAuthorHidden = false, noTopIndent = false }) => {
  const { data: entity } = useShowContext();
  const updatedAt = entity.getValueBySource('updatedAt');
  const updatedByName = entity.getValueBySource('updatedByName');
  const updatedById = entity.getValueBySource('updatedById');
  const { formatDateWithFullFormat } = useDateTimeService();

  return updatedAt && !isUpdateAuthorHidden ? (
    <div className={`${noTopIndent ? '' : 'mt-3'} align-items-end timeline__footer`}>
      <div className="ml-auto timeline__entry-time timeline__entry-time--upd">
        Last updated {formatDateWithFullFormat(updatedAt)} by{' '}
        <TimelineRecordAuthor id={updatedById} name={updatedByName} />
      </div>
    </div>
  ) : null;
};

export default TimelineRecordFooter;
