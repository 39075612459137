import { useEffect } from 'react';
import type { ElementDragType, MonitorArgs } from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types.js';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';

const useDragMonitor = (args: MonitorArgs<ElementDragType>) =>
  useEffect(() => {
    return combine(monitorForElements(args));
  }, [args]);

export default useDragMonitor;
