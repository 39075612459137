/* istanbul ignore file */
import { StatusContext } from 'privileged/externalaccounts/creation/accountsCreationResponseHandler';
import React from 'react';

const useCreateSharepointResponseHandler = () => {
  const handleSharepointCreationStatus = (sharepointCreationStatus: any, statusContext: StatusContext) => {
    if (sharepointCreationStatus.isCreated) {
      statusContext.successes.push(<li key="success-creation">Successfully created SharePoint document storage.</li>);
    } else {
      statusContext.errors.push(
        <li key="error-creation">
          Failed to create SharePoint document storage due to: {sharepointCreationStatus.errors.join(' ')}.
        </li>,
      );
    }
  };

  const handleProjectFieldUpdatedStatus = (projectFieldUpdatedStatus: any, statusContext: StatusContext) => {
    if (projectFieldUpdatedStatus.isUpdated) {
      statusContext.successes.push(
        <li key="success-creation">Successfully updated Project link to the SharePoint.</li>,
      );
    } else {
      statusContext.errors.push(
        <li key="error-creation">
          Failed to update Project link to the SharePoint due to: {projectFieldUpdatedStatus.errors.join(' ')}.
        </li>,
      );
    }
  };

  const handleSharepointResponse = (state: any, statusContext: StatusContext) => {
    if (state.sharepointCreationStatus) {
      handleSharepointCreationStatus(state.sharepointCreationStatus, statusContext);
    }
    if (state.projectFieldUpdatedStatus) {
      handleProjectFieldUpdatedStatus(state.projectFieldUpdatedStatus, statusContext);
    }
  };

  return {
    handleSharepointResponse,
  };
};

export default useCreateSharepointResponseHandler;
