/* istanbul ignore file */
import React from 'react';
import CreateButton, { CreateButtonProps } from 'uibuilder/button/CreateButton';
import { CREATE_ACCOUNT } from 'crm/account/accountService';
import { useAccountUrl } from 'crm/account/index';

const CreateAccountButton = (props: Partial<CreateButtonProps>) => {
  const { getCreateUrl } = useAccountUrl();

  return <CreateButton {...props} url={getCreateUrl()} permissionToCheck={CREATE_ACCOUNT} label="Create Account" />;
};

export default CreateAccountButton;
