/* istanbul ignore file */
import React from 'react';
import moment from 'moment';
import {
  METRICS,
  MetricsProps,
  DAY_TYPES,
  DAY_MARK,
  METRICS_CONFIG,
} from 'erp/employee/performanceReport/usePerformanceReportService';
import PerformanceHeatmapCell from 'erp/employee/performanceReport/shared/PerformanceHeatmap/PerformanceHeatmapCell';
import PerformanceHeatmapWeeksHeader from './PerformanceHeatmapWeeksHeader';
import PerformanceHeatmapLegend, {
  DAY_MARKS_LEGEND,
} from 'erp/employee/performanceReport/shared/PerformanceHeatmap/PerformanceHeatmapLegend';
import PerformanceHeatmapGraphLayout from 'erp/employee/performanceReport/shared/PerformanceHeatmap/layout/PerformanceHeatmapGraphLayout';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import usePerformanceHeatmap from 'erp/employee/performanceReport/shared/PerformanceHeatmap/usePerformanceHeatmap';

interface PerformanceHeatmapGraphProps {
  labels: Array<METRICS | 'DIVIDER'>;
  currentMonth: string;
  metrics: MetricsProps;
}

const PerformanceHeatmapGraph = (props: PerformanceHeatmapGraphProps) => {
  const { currentMonth, metrics } = props;
  const { getWeeksData, getStatistic, getWorkingMark, getSumByField, getSumByDayMark, getSumByDayType } =
    usePerformanceHeatmap(props);
  const weeks = getWeeksData();
  const today = moment();

  const getWeeklyMetrics = (metric: string) => {
    const values = metrics[metric];
    if (values) {
      return weeks.map(week => {
        const startIndex = values.findIndex(value => value.day === week.start.format(DATE_FORMAT.API_ONLY_DATE));
        const endIndex = values.findIndex(value => value.day === week.end.format(DATE_FORMAT.API_ONLY_DATE)) + 1;
        const weekValues = values.slice(startIndex, endIndex);

        return {
          ...week,
          sum: getSumByField(weekValues, 'metric.value'),
          marksStatistic: getStatistic(DAY_MARK, weekValues, getSumByDayMark),
          daysStatistic: getStatistic(DAY_TYPES, weekValues, getSumByDayType),
        };
      });
    }

    return [];
  };

  const getItems = (metric: string) => {
    const values = getWeeklyMetrics(metric);
    const getCellTooltip = METRICS_CONFIG[metric as METRICS]?.monthly?.getCellTooltip || (() => {});
    const getValue = METRICS_CONFIG[metric as METRICS]?.monthly?.getValue;

    if (values.length) {
      const monthlySum = getSumByField(values, 'sum');
      const monthlyWorkingNorm = getSumByField(values, `daysStatistic.${DAY_TYPES.WORKING}`);
      const monthlyData = {
        sum: monthlySum,
        marksStatistic: getStatistic(DAY_MARK, values, getSumByField, 'marksStatistic'),
        daysStatistic: getStatistic(DAY_TYPES, values, getSumByField, 'daysStatistic'),
      };

      return (
        <>
          {values.map(value => {
            const isActiveWeek =
              (value.start.isBefore(today) && today.isBefore(value.end)) || value.end.isBefore(today);
            const cellValue = isActiveWeek ? +value.sum.toFixed(2) : null;

            return (
              <PerformanceHeatmapCell
                key={`${metric}-${value.end.format(DATE_FORMAT.API_ONLY_DATE)}`}
                dayType={DAY_TYPES.WORKING}
                metric={{
                  message: isActiveWeek ? getCellTooltip(value) : null,
                  value: getValue ? getValue(value) : cellValue,
                  mark: getValue || !isActiveWeek ? null : getWorkingMark(value.sum, value.daysStatistic.WORKING),
                }}
                colored={METRICS_CONFIG[metric as METRICS]?.colored}
              />
            );
          })}
          <li style={{ border: 0 }} />
          <PerformanceHeatmapCell
            key={`${metric}-monthly`}
            dayType={DAY_TYPES.WORKING}
            metric={{
              message: getCellTooltip(monthlyData),
              // eslint-disable-next-line no-nested-ternary
              value: getValue ? getValue(monthlyData) : monthlySum ? +monthlySum.toFixed(2) : 0,
              mark: getValue ? null : getWorkingMark(monthlySum, monthlyWorkingNorm),
            }}
            colored={METRICS_CONFIG[metric as METRICS]?.colored}
          />
        </>
      );
    }

    return weeks.map(value => <PerformanceHeatmapCell key={`${metric}-${value.end}`} />);
  };

  return (
    <PerformanceHeatmapGraphLayout
      {...props}
      heatmapHeader={<PerformanceHeatmapWeeksHeader currentMonth={currentMonth} />}
      getItems={getItems}
      gridTemplateColumns={`repeat(${weeks.length}, var(--week-square-size)) var(--month-square-gap) var(--week-square-size)`}
      legend={<PerformanceHeatmapLegend data={[DAY_MARKS_LEGEND]} rowDirection />}
      className="performance-heatmap__days--weekly"
    />
  );
};

export default PerformanceHeatmapGraph;
