import React from 'react';
import { SearchInput } from 'shared/uibuilder/form/input';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { SearchInputProps } from 'shared/uibuilder/form/input/SearchInput';
import useServiceService from 'erp/service/shared/serviceService';

export interface ServiceInputProps extends Omit<SearchInputProps, 'searchRequest'> {
  source: string;
  label?: string;
  initialLabel?: string;
  searchString?: string;
  nameSource?: string;
  mapResults?: (service: any) => void;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  errorSource?: string;
  props?: any;
}

const ServiceInput = ({ mapResults, source, ...props }: ServiceInputProps) => {
  const { search } = useServiceService();

  const mapResponse = (service: any) => ({
    id: service.id,
    text: service.name,
  });

  return (
    <SearchInput
      {...props}
      source={source}
      searchRequest={search}
      noResultMessage="No Services are found."
      mapResults={mapResults || mapResponse}
    />
  );
};

export default ServiceInput;
