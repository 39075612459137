/* istanbul ignore file */
import React from 'react';
import { LIST_PAGE_SIZE } from 'shared/uibuilder/list/List';
import SimpleListWithSorting, { SimpleListWithSortingProps } from 'shared/uibuilder/list/SimpleListWithSorting';

const PAGE_SIZE = 30;

const RelatedListWithSorting = ({
  pageSize = LIST_PAGE_SIZE,
  defaultSortOrder = '',
  defaultSortField = '',
  onRemove = () => {},
  children,
  ...otherProps
}: SimpleListWithSortingProps) => {
  const props = {
    pageSize,
    defaultSortOrder,
    defaultSortField,
    onRemove,
    ...otherProps,
  };

  return (
    <SimpleListWithSorting {...props} pageSize={PAGE_SIZE}>
      {children}
    </SimpleListWithSorting>
  );
};

export default RelatedListWithSorting;
