import CreateForm from 'shared/uibuilder/form/CreateForm';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import React from 'react';
import { useServiceCatalogId, useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import CreateServiceBudgetBreadcrumbs from 'erp/serviceCatalog/budgets/Create/CreateServiceBudgetBreadcrumbs';
import ServiceBudgetForm from 'erp/serviceCatalog/budgets/ServiceBudgetForm';
import useBudgetVersionService from 'erp/serviceCatalog/budgets/budgetVersionService';

const CreateServiceBudget = () => {
  const id = useServiceCatalogId();
  const { getBudgetsUrl } = useServiceCatalogUrl();
  const { create, getValidationSchema } = useBudgetVersionService();

  return (
    <CreateForm
      submitFormFunc={create}
      afterSubmit={{
        redirect: getBudgetsUrl(id),
        message: 'You are awesome! The Budget has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchema}
    >
      <CreatePageLayout title="Create: Budget" breadcrumbs={<CreateServiceBudgetBreadcrumbs />}>
        <ServiceBudgetForm />
      </CreatePageLayout>
    </CreateForm>
  );
};

export default CreateServiceBudget;
