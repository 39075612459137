/* istanbul ignore file */
import React from 'react';
import { ListBreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import { useAssessmentUrl } from 'erp/employee/assessment/AssessmentRouter';
import SingleEmployeeBreadcrumbs from 'erp/employee/shared/SingleEmployeeBreadcrumbs';

const AssessmentsBreadcrumbs = ({ children = null }: { children?: React.ReactNode }) => {
  const { getListUrl } = useAssessmentUrl();

  return (
    <SingleEmployeeBreadcrumbs>
      <ListBreadcrumbItem entity="Assessments" link={getListUrl()} />
      {children}
    </SingleEmployeeBreadcrumbs>
  );
};

export default AssessmentsBreadcrumbs;
