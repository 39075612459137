/* istanbul ignore file */
import React from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { Button } from 'uibuilder/button';
import { ButtonProps } from 'uibuilder/button/Button';

const SubmitFormButton = (props: ButtonProps) => {
  const { submitForm = () => {}, isSubmitEnabled } = useFormContext();
  return <Button {...props} onClick={() => submitForm()} disabled={!isSubmitEnabled} />;
};

export default SubmitFormButton;
