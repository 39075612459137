/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { LEAD_MEDIUM } from 'crm/lead/shared/leadService';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

const LeadMediumDropdown = (props: Omit<DefaultDropdownProps, 'options'>) => {
  return (
    <Dropdown
      placeholder="Choose Lead Medium"
      options={Object.entries(LEAD_MEDIUM).map(entry => {
        const [value, label] = entry;
        return {
          value,
          label,
        };
      })}
      label="Lead Medium"
      {...props}
    />
  );
};

export default LeadMediumDropdown;
