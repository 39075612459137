import React, { useEffect, useState } from 'react';
import EmployeeFeedbackFlagTypeDropdown, {
  FEEDBACK_FLAG_TYPES_ALIAS,
} from 'erp/employee/Timeline/shared/input/EmployeeFeedbackFlagTypeDropdown';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import { string } from 'prop-types';
import EqCondition, { EQ_CONDITION_TYPE } from 'shared/uibuilder/list/filter/condition/EqCondition';
import { FEEDBACK_FLAGS } from 'erp/employee/Timeline/feedback/shared/input/FlagAsInappropriateRadioButtonGroup';
import { FEEDBACK_INAPPROPRIATE_DEFAULT_FLAG } from 'erp/employee/Timeline/feedback/shared/button/FeedbackFlagAsInappropriateButton';
import { IN_CONDITION_TYPE } from 'shared/uibuilder/list/filter/condition/InCondition';
import FilterInputDecorator from 'shared/uibuilder/list/filter/FilterInputDecorator';
import { DropDownFilterProps } from 'shared/uibuilder/list/filter/DropDownFilter';

const EmployeeTimelineFeedbackFlagTypeFilter = ({ source, ...props }: Omit<DropDownFilterProps, 'children'>) => {
  const [dropdownValue, setDropdownValue] = useState<any>(null);
  const { filterBy, getValue } = useFilterContext();
  const flags = Object.keys(FEEDBACK_FLAGS);

  const handleChange = (data: Dictionary<any>) => {
    const value = data[source][EQ_CONDITION_TYPE];

    const resultInValue = value === FEEDBACK_FLAG_TYPES_ALIAS.CORRECT ? [FEEDBACK_INAPPROPRIATE_DEFAULT_FLAG] : flags;

    filterBy({
      [source]: {
        [IN_CONDITION_TYPE]: resultInValue,
      },
    });
    setDropdownValue(value);
  };

  useEffect(() => {
    const value = getValue(source, IN_CONDITION_TYPE);
    let result;

    if (value) {
      if (value[0] === FEEDBACK_INAPPROPRIATE_DEFAULT_FLAG) {
        result = FEEDBACK_FLAG_TYPES_ALIAS.CORRECT;
      } else {
        result = FEEDBACK_FLAG_TYPES_ALIAS.INAPPROPRIATE;
      }
    }

    setDropdownValue(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResetCallback = () => {
    setDropdownValue(null);
  };

  return (
    <EqCondition {...props} source={source} onChangeCallback={handleChange} value={dropdownValue}>
      <FilterInputDecorator cleanableFilters={[source]} onResetCallback={onResetCallback}>
        <EmployeeFeedbackFlagTypeDropdown source={source} />
      </FilterInputDecorator>
    </EqCondition>
  );
};

EmployeeTimelineFeedbackFlagTypeFilter.propTypes = {
  source: string.isRequired,
};

export default EmployeeTimelineFeedbackFlagTypeFilter;
