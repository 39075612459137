import React, { useEffect, useState } from 'react';
import { ARTIFACT_ID } from 'artifact/const';
import SmallLoader from 'shared/uibuilder/SmallLoader';
import 'artifact/shared/input/WysiwygInputPasteArtifactDecorator/index.scss';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import InputLoader from 'shared/uibuilder/InputLoader';
import { Button } from 'uibuilder/button';
import useArtifactHelper from 'artifact/shared/input/WysiwygInputPasteArtifactDecorator/artifactHelper';
import useConvertingTagsHelper from 'artifact/shared/input/WysiwygInputPasteArtifactDecorator/artifactTagsConverted';
import useArtifactsGarbageCollector from 'artifact/shared/input/WysiwygInputPasteArtifactDecorator/artifactsGarbageCollector';

export type WysiwygInputPasteArtifactDecoratorProps = {
  children: React.ReactElement;
  artifactsSource: string;
  artifactTypeId?: number;
};

const WysiwygInputPasteArtifactDecorator: React.FC<WysiwygInputPasteArtifactDecoratorProps> = ({
  children,
  artifactsSource,
  artifactTypeId = ARTIFACT_ID.NOTE_ATTACHMENT,
}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [preparedValue, setPreparedValue] = useState('');

  const { getArtifactsDataByIds, uploadArtifact, isLoading, getThumbnail, cancel, validate, getArtifacts } =
    useArtifactHelper({
      artifactTypeId,
      artifactsSource,
    });

  const { getValue, getOnChangeCallback, getRawOnChangeCallback, setFormFieldErrors } = useInputHelper(children.props);

  const onChangeCallback = getOnChangeCallback();
  const { source } = children.props;

  const { replaceImagesWithArtifactTag, replaceArtifactWithImageTag } = useConvertingTagsHelper();

  const { setRefreshData } = useArtifactsGarbageCollector({
    artifactsIds: getArtifacts(),
    onChangeCallback: getRawOnChangeCallback() || (() => ({})),
    source,
    editorContent: getValue(),
  });

  const handlePaste = async (e: { files: any; content: any }) => {
    const { files, content } = e;

    if (files.length && !isLoading) {
      const error = validate(files);

      const areValidArtifacts = !error;

      if (areValidArtifacts) {
        const [artifactId, artifactSrc] = await uploadArtifact(files[0]);
        return `<img data-artifact-id="${artifactId}" src="${artifactSrc}" alt="" />`;
      } else {
        setFormFieldErrors([error]);
      }
    } else if (!isLoading) {
      return content;
    }

    return null;
  };

  const handleChange = (data: { [x: string]: any }) => {
    const value = data[source];

    const div = document.createElement('div');
    div.innerHTML = value;
    const images = div.querySelectorAll('img[data-artifact-id]');

    replaceImagesWithArtifactTag(images);

    onChangeCallback({ target: { value: div.innerHTML } });
  };

  // converting artifact tags to img on mount from initial html-string value
  useEffect(() => {
    (async () => {
      const value = getValue();
      const div = document.createElement('div');
      // @ts-ignore
      div.innerHTML = value;
      await replaceArtifactWithImageTag(div.querySelectorAll('artifact'), getArtifactsDataByIds, getThumbnail);

      setPreparedValue(div.innerHTML);
      setIsInitialized(true);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wysiwyg-wrapper">
      {isLoading && (
        <>
          <div className="wysiwyg-wrapper__loader">
            <SmallLoader />
          </div>
          <div className="wysiwyg-wrapper__cancel">
            <Button
              onClick={() => {
                cancel();
              }}
              data-testid="decorator-cancel-button"
            >
              Cancel uploading
            </Button>
          </div>
        </>
      )}
      {isInitialized ? (
        React.cloneElement(children, {
          onPasteCallback: handlePaste,
          onChangeCallback: handleChange,
          disabled: isLoading || children.props.disabled,
          value: preparedValue,
          setRefreshDataCallback: setRefreshData,
          helpText:
            'Press CTRL+V to insert the image into the text from the clipboard.' +
            '\n Notice that the clipboard image may be larger than the original image file itself.',
          errorMessages: isLoading ? [] : undefined,
          // override errorMessages from the context during uploading
        })
      ) : (
        <InputLoader />
      )}
    </div>
  );
};

export default WysiwygInputPasteArtifactDecorator;
