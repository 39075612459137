/* istanbul ignore file */
import React from 'react';
import ImageLayout from 'artifact/shared/field/layout/AttachmentFieldLayout/ImageLayout';
import DocumentLayout from 'artifact/shared/field/layout/AttachmentFieldLayout/DocumentLayout';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { ArtifactType } from './ArtifactPreviewWrapper';

type AttachmentFieldLayoutProps = {
  value: ArtifactType;
  handleClick?: () => void;
  handleDownload?: () => void;
  handleOpenPreview?: () => void;
  getThumbnail?: (id: number | string, size: string) => void;
  isDownloadAvailable?: boolean;
};

const AttachmentFieldLayout = ({
  value,
  handleClick = () => {},
  handleOpenPreview = () => {},
  getThumbnail = () => Promise.resolve(''),
  isDownloadAvailable = true,
  handleDownload = () => {},
}: AttachmentFieldLayoutProps) => {
  const { ArtifactImageLayout = ImageLayout, ArtifactDocumentLayout = DocumentLayout } = useUiTheme();

  return value && value.type === 'image' ? (
    <ArtifactImageLayout
      value={value}
      getThumbnail={getThumbnail}
      handleDownload={handleDownload}
      handleClick={handleClick}
    />
  ) : (
    <ArtifactDocumentLayout
      value={value}
      handleDownload={handleClick}
      handleOpenPreview={handleOpenPreview}
      isDownloadAvailable={isDownloadAvailable}
    />
  );
};

export default AttachmentFieldLayout;
