import FormRow from 'shared/layout/form/FormRow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import FormSection from 'shared/layout/form/FormSection';
import React from 'react';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import BudgetStructureDetailsSection from '../../shared/inputs/BudgetStructureDetailsSection';

const ServiceBudgetStructureForm = () => {
  const { getToday } = useDateService();

  return (
    <>
      <FormSection title="Budget Structure Information">
        <FormRow>
          <DateInput
            dateFormat={DATE_FORMAT.API_ONLY_MONTH}
            minDate={getToday()}
            source="effectiveDate"
            label="Effective Date"
            isRequired
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <BudgetStructureDetailsSection />
      </FormSection>
    </>
  );
};

export default ServiceBudgetStructureForm;
