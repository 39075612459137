/* istanbul ignore file */
import React from 'react';
import useUnlinkedCrmActivitiesService from 'crm/unlinkedEmails/shared/unlinkedCrmActivitiesService';
import useCrmUnlinkedActivityRegistry from 'crm/unlinkedEmails/crmUnlinkedActivityRegistry';
import TimelinePageLayout from 'shared/uibuilder/timeline/layout/TimelinePageLayout';
import TimelineLayout from 'shared/uibuilder/timeline/layout/TimelineLayout';
import Timeline from 'shared/uibuilder/timeline/Timeline';
import Show from 'shared/uibuilder/show/Show';

const UnlinkedCrmActivitiesTimelinePage = () => {
  const { searchTimelineItems, getTimelinePageById } = useUnlinkedCrmActivitiesService();

  const activityRegistry = useCrmUnlinkedActivityRegistry();

  return (
    <Show getDataMethod={() => Promise.resolve({})}>
      <Timeline
        registries={[activityRegistry] as any}
        getDataMethod={request => searchTimelineItems(request)}
        getPageByEntryIdMethod={id => getTimelinePageById(id)}
      >
        <TimelinePageLayout title="Unlinked CRM activities">
          <TimelineLayout noEntriesMessage="No entries in this opportunity timeline yet." />
        </TimelinePageLayout>
      </Timeline>
    </Show>
  );
};

export default UnlinkedCrmActivitiesTimelinePage;
