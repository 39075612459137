/* istanbul ignore file */
import React from 'react';
import './EmployeeFamilyMembersLayout.scss';

const EmployeeFamilyMembersLayout = ({
  otherMembers,
  childrenMembers,
}: {
  otherMembers: React.ReactNode[];
  childrenMembers: React.ReactNode[];
}) => {
  return (
    <>
      {!!otherMembers.length && <div>{otherMembers}</div>}
      {!!childrenMembers.length && (
        <div className="employee-children">
          <b>Children</b>
          {childrenMembers}
        </div>
      )}
    </>
  );
};

export default EmployeeFamilyMembersLayout;
