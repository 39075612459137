/* istanbul ignore file */
import React from 'react';
import { FieldProps } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';
import { DocuSignEnvelopeStatus, DocuSignEnvelopeStatusAlias } from 'shared/docusign/envelope/DocuSignEnvelopeBox';

const DocuSignEnvelopeStatusBadge = ({ value: inputValue, source, ...props }: FieldProps) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });
  const value = inputValue || getValue();
  const displayText = DocuSignEnvelopeStatusAlias[value];

  const getColor = () => {
    let result: BasicColors = 'info';
    if (value === DocuSignEnvelopeStatus.COMPLETED) {
      result = 'success';
    } else if (value === DocuSignEnvelopeStatus.DECLINED) {
      result = 'error';
    }

    return result;
  };

  return <CustomBadge text={displayText} color={getColor()} {...props} />;
};

export default DocuSignEnvelopeStatusBadge;
