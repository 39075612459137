/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import useRoute from 'shared/routing/useRoute';
import ShowArtifactPage from 'artifact/show';
import ArtifactContext from 'artifact/ArtifactContext';
import { READ_ARTIFACT } from 'artifact/artifactService';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const ARTIFACTS_PATH = '/artifacts';

export const useArtifactRoute = () => {
  return useRoute({ listRoute: ARTIFACTS_PATH });
};

const ArtifactRouter = () => {
  const { singleEntityRoute } = useArtifactRoute();

  return (
    <ProtectedRouter basePath={ARTIFACTS_PATH}>
      <Routes>
        <Route
          path={singleEntityRoute}
          element={
            <ArtifactContext permissionToCheck={READ_ARTIFACT}>
              <ShowArtifactPage />
            </ArtifactContext>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

ArtifactRouter.getRouterPath = () => `${ARTIFACTS_PATH}/*`;

export default ArtifactRouter;
