import React, { useEffect } from 'react';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getLastSource } from 'shared/uibuilder/helper';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { EMPLOYEE_CONTRACT_TYPES, LOCATION_FOR_HIRING_OPTIONS, RELOCATION_OPTION } from '../../constants';
import { EMPLOYEE_CONTRACT_ASSIGMENT_TYPES } from 'erp/employee/contracts/shared/input/EmployeeContractAssignmentTypeRadios';

const WorkingConditionsInputs = (props: DefaultInputPropTypes<any>) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const expectedCompensation = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});

  const source = getSource();

  const onChangeCallback = (value: { [x: string]: any }) => {
    rawOnChangeCallback({
      [source]: {
        ...expectedCompensation,
        ...getLastSource(value),
      },
    });
  };

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MediumFormRow sx={{ maxWidth: '50%' }}>
        <EnumDropdown
          options={LOCATION_FOR_HIRING_OPTIONS}
          source={`${source}.hiringLocation`}
          value={expectedCompensation.hiringLocation}
          label="Location for hiring"
          placeholder="Select location"
          tooltip="Office / Accommodation"
          onChangeCallback={onChangeCallback}
        />

        <EnumDropdown
          options={EMPLOYEE_CONTRACT_ASSIGMENT_TYPES}
          source={`${source}.employmentTypes`}
          value={expectedCompensation.employmentTypes}
          label="Employment type"
          multiple
          placeholder="Select employment type"
          onChangeCallback={onChangeCallback}
        />

        <EnumDropdown
          options={EMPLOYEE_CONTRACT_TYPES}
          source={`${source}.contractTypes`}
          value={expectedCompensation.contractTypes}
          label="Contract type"
          multiple
          placeholder="Select contract type"
          onChangeCallback={onChangeCallback}
        />

        <EnumDropdown
          options={RELOCATION_OPTION}
          source={`${source}.isRelocationAvailable`}
          value={expectedCompensation.isRelocationAvailable}
          label="Relocation option"
          placeholder="Select relocation option"
          onChangeCallback={onChangeCallback}
        />
      </MediumFormRow>
    </>
  );
};

export default WorkingConditionsInputs;
