import React from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { CommonInputLayoutProps, BaseChangeEvent, DefaultInputPropTypes } from 'shared/uibuilder/form/input';

export type NumberInputLayoutType = ReactComponent<CommonInputLayoutProps<BaseChangeEvent, number>>;

const NumberInput = ({ isVisible = true, ...otherProps }: DefaultInputPropTypes<number>) => {
  const props = {
    isVisible,
    ...otherProps,
  };

  const inputHelper = useInputHelper(props);
  const { NumberInputLayout } = useUiTheme<NumberInputLayoutType>();

  return (
    <NumberInputLayout
      isVisible={inputHelper.isVisible()}
      label={inputHelper.getLabel()}
      source={inputHelper.getSource()}
      onChangeCallback={inputHelper.getOnChangeCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      value={inputHelper.getValue()}
      errorMessages={inputHelper.getErrorMessages()}
      isRequired={inputHelper.getIsRequired()}
      labelHint={inputHelper.getLabelHint()}
      helpText={inputHelper.getHelpText()}
      disabled={inputHelper.getIsDisabled()}
      tooltip={inputHelper.getTooltip()}
      className={inputHelper.getClassName()}
    />
  );
};

export default NumberInput;
