/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { SICK_DAY_REASONS } from '../leaveService';

const SickDayReasonDropdown = (props: any) => {
  return <EnumDropdown options={SICK_DAY_REASONS} placeholder="Choose Sick Day Reason" {...props} />;
};

export default SickDayReasonDropdown;
