/* istanbul ignore file */
// libs
import React from 'react';
// layout
import EmployeeEntityHeader from 'erp/employee/shared/EmployeeEntityHeader';
import FinanceListBreadcrumbs from 'erp/employee/finance/List/Breadcrumbs';
import EmployeeMenu from 'erp/employee/shared/EmployeeMenu';
import { SensitiveRelationListPageLayout } from 'shared/uibuilder/sensitiveData';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import List from 'shared/uibuilder/list/List';
// fields
import { TextField, DateField, CapitalizedField } from 'shared/uibuilder/field';
import FinancePackageIdField from 'erp/employee/finance/shared/fields/FinancePackageIdField';
// buttons
import CreateButton from 'uibuilder/button/CreateButton';
import EditFinanceButton from 'erp/employee/finance/shared/buttons/EditFinanceButton';
import ViewFinanceButton from 'erp/employee/finance/shared/buttons/ViewFinanceButton';
// services
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import useFinanceService from 'erp/employee/finance/shared/financeService';
import {
  useFinanceUrl,
  FE_UPDATE_EMPLOYEE_COMPENSATION_PACKAGES,
  CREATE_COMPENSATION_PACKAGE,
} from 'erp/employee/finance/FinanceRouter';
import EmployeeOfficeField from 'erp/employee/shared/field/EmployeeOfficeField';
import useFeatureToggle, { Features } from 'featuretoggle';
import { ShowDataInstance } from '../../../../shared/uibuilder/show/Show';

const FinancesList = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const isNewPayrollEnabled = isFeatureEnabled(Features.PAYROLL);

  const { search, getSensitiveDataByIds, getIsCurrentPackage } = useFinanceService();
  const { getCreateUrl, getListUrl } = useFinanceUrl();
  const twoContractEnabled = useFeatureToggle().isFeatureEnabled(Features.TWO_CONTRACTS);

  return (
    <ShowPageLayout
      entityHeader={<EmployeeEntityHeader />}
      menu={<EmployeeMenu />}
      breadcrumbs={<FinanceListBreadcrumbs />}
    >
      <List getDataMethod={search} defaultSortOrder={DESC} defaultSortField="effectiveDateFrom">
        <SensitiveRelationListPageLayout
          header="Compensation packages"
          sensitiveDataIdSource={isNewPayrollEnabled ? 'compensationPackageDataId' : 'sensitiveDataId'}
          loadSensitiveDataMethod={getSensitiveDataByIds}
          actions={[
            <CreateButton
              url={getCreateUrl()}
              label="Add Package"
              key="add-package"
              variant="contained"
              permissionToCheck={CREATE_COMPENSATION_PACKAGE}
            />,
          ]}
          timerId={getListUrl()}
        >
          <DataGridLayout
            showTotal={false}
            isSmallTable
            buttons={[
              <EditFinanceButton
                source="id"
                key="edit-finance"
                outline
                permissionToCheck={FE_UPDATE_EMPLOYEE_COMPENSATION_PACKAGES}
              />,
              <ViewFinanceButton source="id" key="view-finance" />,
            ]}
            tableWrapperClassName="finance-list"
            rowProps={(data: ShowDataInstance) => (getIsCurrentPackage(data) ? { className: 'highlighted' } : {})}
          >
            <FinancePackageIdField
              source="id"
              label="Package ID"
              dateFromSource="effectiveDateFrom"
              dateToSource="effectiveDateTo"
              probationAmountSource="probationAmount"
              isSortable
            />
            <CapitalizedField source="type" label="Type" isSortable />
            <DateField source="effectiveDateFrom" label="Effective date from" isSortable />
            <DateField source="effectiveDateTo" label="Effective date to (including)" isSortable />
            <CapitalizedField source="baseAmountType" label="Base Amount Type" />
            <TextField source="grossAmount" label="Base Amount Gross" />
            <TextField source="grossProbationAmount" label="Probation Value Gross" />
            <TextField source="currency" label="Currency" />
            <EmployeeOfficeField source="officeId" label="Office" isVisible={twoContractEnabled} />
          </DataGridLayout>
        </SensitiveRelationListPageLayout>
      </List>
    </ShowPageLayout>
  );
};

export default FinancesList;
