import React from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { FieldProps } from 'shared/uibuilder/field';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';
import useAuthorization from '../../../../../shared/authorization/authorizationService';
import LinkField from '../../../../../shared/uibuilder/field/LinkField';
import { useServiceCatalogId, useServiceCatalogUrl } from '../../../ServiceCatalogRouter';
import { BUDGETING_READ } from 'erp/serviceCatalog/budgets/budgetingService';

type PeriodBudgetFieldProps = {
  startDateSource: string;
  endDateSource: string;
} & FieldProps;

const PeriodBudgetField = ({ startDateSource, endDateSource }: PeriodBudgetFieldProps) => {
  const { isGranted } = useAuthorization();
  const { formatDate } = useDateService();
  const { getValue: getBudgetVersionId } = useFieldHelper({
    source: 'id',
  });
  const { getValue: getStartDate } = useFieldHelper({ source: startDateSource });
  const { getValue: getEndDate } = useFieldHelper({ source: endDateSource });

  const isLink = isGranted(BUDGETING_READ);

  const { getBudgetUrl } = useServiceCatalogUrl();
  const serviceId = useServiceCatalogId();

  const path = getBudgetUrl(serviceId, getBudgetVersionId());
  const value = `${formatDate(getStartDate(), DATE_FORMAT.MONTH)} ${EMPTY_VALUE_INDICATOR} ${formatDate(getEndDate(), DATE_FORMAT.MONTH)}`;

  return isLink ? <LinkField linkText={value} isInternal value={path} /> : <TextField value={value} />;
};

export default PeriodBudgetField;
