/* istanbul ignore file */
import React from 'react';
import MaskedInput from 'react-text-mask';
import BaseInputLayout, { getBaseInputLayoutProps, getCommonInputProps } from './BaseInputLayout';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { CurrencyInputLayoutProps, ChangeEventType } from 'shared/uibuilder/form/input/CurrencyInput';
import Box from 'uibuilder/Box';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInputLayout = ({
  decimalLimit,
  integerLimit,
  allowNegative = false,
  onChangeCallback,
  placeholder: inputPlaceholder,
  autocomplete,
  suffix,
  ...rest
}: CurrencyInputLayoutProps) => {
  const maskOptions = {
    decimalLimit,
    integerLimit,
    allowNegative,
  };

  const placeholder = inputPlaceholder || `0.${'0'.repeat(decimalLimit)}`;

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  const suffixLength = (suffix?.length as number) || 0;

  return (
    <BaseInputLayout {...getBaseInputLayoutProps(rest)}>
      <Box sx={{ position: 'relative' }}>
        {suffix && (
          <Box
            sx={{
              position: 'absolute',
              top: '9px',
              right: '12px',
              color: '#9d999e',
              opacity: rest.disabled ? 0.38 : 1,
              '& + input': {
                paddingRight: `${12 + suffixLength * 9}px`,
              },
            }}
          >
            {suffix}
          </Box>
        )}
        <MaskedInput
          {...getCommonInputProps(rest)}
          mask={currencyMask}
          onChange={onChangeCallback}
          placeholder={placeholder}
          // onChange doesn't receive the event
          // so instead of using onChange we need to use onInput
          // https://github.com/text-mask/text-mask/pull/993
          onInput={(e: ChangeEventType) => {
            if (!(e.target as HTMLInputElement).value) {
              onChangeCallback(e);
            }
          }}
          autoComplete={autocomplete}
        />
      </Box>
    </BaseInputLayout>
  );
};

export default CurrencyInputLayout;
