import { MessageProps } from './messageReducer';

export const SET_MESSAGE = 'SET_MESSAGE';
export const DISMISS_MESSAGE = 'DISMISS_MESSAGE';

export const setMessage = (message: MessageProps, pathname: string, messageContext?: string) => ({
  type: SET_MESSAGE,
  payload: {
    message,
    pathname,
    messageContext,
  },
});

export const dismissMessage = (message?: MessageProps, pathname?: string, messageContext?: string) => ({
  type: DISMISS_MESSAGE,
  payload: {
    message,
    pathname,
    messageContext,
  },
});
