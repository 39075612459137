import {
  SERVICE_HEALTH_ISSUES,
  SERVICE_HEALTH_ISSUES_DESCRIPTION,
  SERVICE_HEALTH_ISSUES_IMPORTANCE,
  SERVICE_HEALTH_ISSUES_OPTIONS,
} from 'erp/serviceCatalog/health/useServiceHealthService';
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import HealthCheckList from 'erp/serviceCatalog/shared/field/HealthCheckList';

type ServiceHealthFieldProps = {
  label: string;
  source?: string;
};

const ServiceHealthField = ({ label, source = "" } : ServiceHealthFieldProps) => {
  const { data } = useShowContext();

  const getData = (value: string) => {
    if (source === '') {
      return data.getValueBySource(value);
    } else {
      return data.getValueBySource(`${source}.${value}`);
    }
  };

  return (
    <HealthCheckList
      allIssues={SERVICE_HEALTH_ISSUES}
      issuesOptions={SERVICE_HEALTH_ISSUES_OPTIONS}
      issuesImportance={SERVICE_HEALTH_ISSUES_IMPORTANCE}
      issuesDescription={SERVICE_HEALTH_ISSUES_DESCRIPTION}
      unresolvedIssues={getData('issues')}
    />
  );
};

export default ServiceHealthField;
