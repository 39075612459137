import UnlinkedActivityTimelineRecordLayout from 'crm/unlinkedEmails/UnlinkedActivityTimelineRecordLayout';
import { READ_UNLINKED_CRM_ACTIVITY_PERMISSION } from 'crm/unlinkedEmails/shared/unlinkedCrmActivitiesService';

const useCrmUnlinkedActivityRegistry = () => ({
  isApplicable: (item: { activity: any }) => item.activity,
  source: 'activity',
  urlPostfix: 'activities',
  RecordLayout: UnlinkedActivityTimelineRecordLayout,
  name: 'Activity',
  permissionToCheck: READ_UNLINKED_CRM_ACTIVITY_PERMISSION,
  withoutAddFunctionality: true,
});

export default useCrmUnlinkedActivityRegistry;
