import React from 'react';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getProbationReduction } from 'erp/employee/finance/shared/financeService';

type ProbationPeriodReductionInputProps = {
  source: string;
  probationAmountSource: string;
  baseAmountSource: string;
  [key: string]: any;
};

const ProbationPeriodReductionInput = ({
  probationAmountSource,
  baseAmountSource,
  ...props
}: ProbationPeriodReductionInputProps) => {
  const { getValue: getProbationAmountValue } = useInputHelper({ source: probationAmountSource });
  const { getValue: getBaseAmountValue } = useInputHelper({ source: baseAmountSource });

  const getFieldValue = () => {
    const baseAmount = getBaseAmountValue();
    const probationAmount = getProbationAmountValue();

    if (!baseAmount || !probationAmount) {
      return '';
    }

    return getProbationReduction(probationAmount, baseAmount);
  };

  return <TextInput value={getFieldValue() as string} disabled {...props} />;
};

export default ProbationPeriodReductionInput;
