/* istanbul ignore file */
// libraries
import React from 'react';
// components
import { DeleteButton } from 'uibuilder/button';
import { useCompanyRolesService, Role } from '../../shared/companyRolesService';
// services
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
// constants
import { useListContext } from 'shared/uibuilder/list/ListContext';

const RemoveEmployeeCompanyRoleButton = (props: any) => {
  const { removeRoleFromEmployee } = useCompanyRolesService();
  const { data: rowData } = useShowContext();
  const { setData } = useListContext();

  const role: Role = rowData.getData().name;

  const deleteFromListByName = () =>
    setData &&
    setData((prevData: { items: { name: string }[] }) => {
      const items = prevData.items.filter(item => item.name !== role);

      return {
        ...prevData,
        items,
        totalElements: items.length,
      };
    });

  return (
    <DeleteButton
      deleteMethod={() => removeRoleFromEmployee(role)}
      buttonText="Remove"
      displayMessage={`Do you really want to remove ${role} role from the employee?`}
      afterDelete={{
        successMessage: 'The company role has been successfully removed.',
        errorMessage: "Can't remove company role.",
        execute: deleteFromListByName,
      }}
      {...props}
    />
  );
};

export default RemoveEmployeeCompanyRoleButton;
