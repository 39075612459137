/* istanbul ignore file */
import React from 'react';
import { InputList } from 'shared/uibuilder/form/input';
import { InputListProps } from 'shared/uibuilder/form/input/InputList/InputList';

const FamilyMemberListInput: React.FC<InputListProps> = ({ inputTemplate, ...props }) => {
  return (
    <div className="family-member-input">
      <InputList {...props} inputTemplate={inputTemplate} />
    </div>
  );
};

export default FamilyMemberListInput;
