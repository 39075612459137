import { Dropdown } from 'shared/uibuilder/form/input';
import React from 'react';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import { isEqual } from 'lodash';
import FilterInputDecorator from 'shared/uibuilder/list/filter/FilterInputDecorator';

const SOURCE = 'entryType';

const options: any[] = [
  {
    label: 'Note',
    value: 'NOTE',
    state: { type: { eq: 'NOTE_CREATED' }, 'activity.activityType': {} },
  },
  {
    label: 'Activity: Call',
    value: 'ACTIVITY_CALL',
    state: {
      type: { eq: 'ACTIVITY_CREATED' },
      'activity.activityType': { eq: 'CALL' },
    },
  },
  {
    label: 'Activity: Meeting',
    value: 'ACTIVITY_MEETING',

    state: {
      type: { eq: 'ACTIVITY_CREATED' },
      'activity.activityType': { eq: 'MEETING' },
    },
  },
  {
    label: 'Activity: Email',
    value: 'ACTIVITY_EMAIL',
    state: {
      type: { eq: 'ACTIVITY_CREATED' },
      'activity.activityType': { eq: 'EMAIL' },
    },
  },
];

const CrmTimelineEntryTypeFilter = (props: { [key: string]: any }) => {
  const { filterBy, filters } = useFilterContext();

  const getState = (value: string) => options.find(option => option.value === value).state;

  const getValue = () => {
    const isConditionFromContext = (state: Dictionary<any>, source: string) => {
      return isEqual(filters[source] || {}, state[source]);
    };

    const resultOption = options.find(option => {
      const { state } = option;
      const optionSources = Object.keys(state);

      return optionSources.every(source => isConditionFromContext(state, source));
    });

    return resultOption && resultOption.value;
  };

  const onChangeCallback = (object: Dictionary<any>) => {
    const value = object[SOURCE];
    filterBy(getState(value));
  };

  return (
    <FilterInputDecorator cleanableFilters={[SOURCE, 'type', 'activity.activityType']}>
      <Dropdown onChangeCallback={onChangeCallback} options={options} value={getValue()} source={SOURCE} />
    </FilterInputDecorator>
  );
};

export default CrmTimelineEntryTypeFilter;
