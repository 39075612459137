import { useFinanceApi } from 'api';

export const budgetCategoryRequiredAccountsPatterns = ['Expenses/Recruiting'];

export const ACCOUNTS_WITH_BUDGET_CATEGORIES = ['Expenses/Recruiting/USD'];

export const isBudgetCategoryRequired = (accountId: any) =>
  !!accountId && budgetCategoryRequiredAccountsPatterns.some(account => accountId?.startsWith(account));

const useBudgetCategorySuggestionService = () => {
  const { sendPostRequest } = useFinanceApi();

  const suggestBudgetCategories = async (date: any, accountId: any) => {
    const response = await sendPostRequest(`/budgets/structures/categories/search`, {
      date,
      accountId,
    });
    const suggestions = await response.json();

    return { result: suggestions.categories };
  };

  return {
    suggestBudgetCategories,
  };
};

export default useBudgetCategorySuggestionService;
