import React, { useEffect } from 'react';
import { DefaultInputPropTypes, TextInput } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { getLastSource } from 'shared/uibuilder/helper';
import VacancyWysiwygInput from '../../../vacancy/shared/input/VacancyWysiwygInput';
import FormRow from 'shared/layout/form/FormRow';
import Box from 'uibuilder/Box';

const ProjectDescriptionInputs = (props: DefaultInputPropTypes<any>) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const expectedCompensation = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});

  const source = getSource();

  const onChangeCallback = (value: { [x: string]: any }) => {
    rawOnChangeCallback({
      [source]: {
        ...expectedCompensation,
        ...getLastSource(value),
      },
    });
  };

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ flexDirection: 'column', flexWrap: 'nowrap', width: '100%' }}>
      <FormRow>
        <TextInput onChangeCallback={onChangeCallback} label="Project name" source={`${source}.name`} />
      </FormRow>
      <FormRow colMd={12} colXl={12}>
        <VacancyWysiwygInput onChangeCallback={onChangeCallback} source={`${source}.description`} label="" />
      </FormRow>
    </Box>
  );
};

export default ProjectDescriptionInputs;
