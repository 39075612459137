// libs
import React from 'react';
// components
import Page403 from 'shared/authorization//Page403';
// services
import useAuthorization from 'shared/authorization/authorizationService';
import { Permission } from './utils';

type ProtectedRouteProps = {
  path?: string;
  exact?: boolean;
  permissionToCheck: Permission | Permission[];
  children: React.ReactElement;
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { isGranted } = useAuthorization();
  const { permissionToCheck, children } = props;

  return isGranted(permissionToCheck) ? children : <Page403 />;
};

export default ProtectedRoute;
