/* istanbul ignore file */
import React from 'react';
import { SidebarStickyMenu, SidebarMenuItem } from 'shared/uibuilder/menu';
import { SummaryMenuItem } from 'uibuilder/menuitem';
import { useProjectId, useProjectUrl } from 'erp/project/ProjectRouter';
import useStatusesUrl from '../statuses/shared/projectStatusUrl';
import useFeatureToggle, { Features } from 'featuretoggle';
import useAuthorization from 'shared/authorization/authorizationService';
import { READ_PROJECT_STATUS_LIST } from '../statuses/ProjectStatusesService';
import { READ_PROJECT_REPORTS_LIST } from '../weeklyreports/ProjectWeeklyReportService';
import { useProjectWeeklyReportsUrl } from 'erp/project/weeklyreports/ProjectWeeklyReportRouter';
import { PROJECT_CONTEXT } from '../ProjectContext';
import { useProjectDashboardUrl } from '../dashboard/ProjectDashboardRouter';
import { READ_PROJECT_DASHBOARD } from '../dashboard/ProjectDashboardService';
import { useWBSProjectDashboardUrl } from '../ProjectsWBSDashboard/useProjectsWBSDashboardService';
import { useSowUrl } from '../sow/SowRouter';
import { READ_SOW } from '../sow/useSowService';

const ViewProjectPageMenu = () => {
  const id = useProjectId();
  const { getSingleEntityUrl } = useProjectUrl();
  const { getListUrl: getStatusesListUrl } = useStatusesUrl();
  const { getListUrl: getReportsListUrl } = useProjectWeeklyReportsUrl();
  const { getListUrl: getDashboardListUrl } = useProjectDashboardUrl();
  const { getListUrl: getWBSDashboardUrl } = useWBSProjectDashboardUrl();
  const { getListUrl: getSowUrl } = useSowUrl();
  const { isFeatureEnabled } = useFeatureToggle();
  const { isGranted: globalIsGranted } = useAuthorization();
  const isGranted = (permission: string | string[]) => globalIsGranted(permission, PROJECT_CONTEXT);

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl(id)} />
      <SidebarMenuItem
        linkUrl={getStatusesListUrl()}
        name="Status"
        icon="calendar-check-o"
        isVisible={isFeatureEnabled(Features.PROJECT_STATUSES) && isGranted(READ_PROJECT_STATUS_LIST)}
      />
      <SidebarMenuItem
        linkUrl={getReportsListUrl()}
        name="Weekly Report"
        icon="area-chart"
        isVisible={isFeatureEnabled(Features.PROJECT_WEEKLY_REPORTS) && isGranted(READ_PROJECT_REPORTS_LIST)}
      />
      <SidebarMenuItem
        linkUrl={getDashboardListUrl()}
        name="Dashboard"
        icon="th-large"
        isVisible={isGranted(READ_PROJECT_DASHBOARD)}
      />
      {/* TODO Use READ_WBS permission when BE is ready */}
      <SidebarMenuItem
        linkUrl={getWBSDashboardUrl()}
        name="WBS Dashboard"
        icon="tasks"
        isVisible={isFeatureEnabled(Features.PROJECT_WBS) && isGranted(READ_PROJECT_DASHBOARD)}
      />
      <SidebarMenuItem
        linkUrl={getSowUrl()}
        name="SOW"
        icon="cogs"
        isVisible={isFeatureEnabled(Features.PROJECT_SOW) && isGranted(READ_SOW)}
      />
    </SidebarStickyMenu>
  );
};

export default ViewProjectPageMenu;
