/* istanbul ignore file */
import React from 'react';
import SidebarMenuItem, { SidebarMenuItemProps } from 'shared/uibuilder/menu/SidebarMenuItem';

interface ActivitiesMenuItemProps extends Omit<SidebarMenuItemProps, 'name'> {
  linkUrl: string;
  name?: string;
}

const ActivitiesMenuItem = ({ linkUrl, ...props }: ActivitiesMenuItemProps) => (
  <SidebarMenuItem linkUrl={linkUrl} name="Activities & Notes" icon="history" {...props} />
);

ActivitiesMenuItem.displayName = 'Activities & Notes';

export default ActivitiesMenuItem;
