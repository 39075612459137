import React from 'react';
import classnames from 'classnames';
import Typography from 'uibuilder/Typography';
import { EMPTY_VALUE_INDICATOR, isNotNullValue } from 'shared/uibuilder/helper';
import { FieldLayoutProps } from 'shared/uibuilder/field';

const LabeledFieldLayout = ({
  label,
  value,
  isColumnDirection = false,
  className,
}: FieldLayoutProps<string | number | React.ReactElement | React.ReactElement[]>) => {
  return (
    <div className={classnames('field__wrapper', className, { 'field__wrapper--column': isColumnDirection })}>
      {label && (
        <Typography className="field-label" variant="body1" component="h3">
          {label as any}
        </Typography>
      )}
      <Typography variant="body1" component="div">
        {isNotNullValue(value) ? value : EMPTY_VALUE_INDICATOR}
      </Typography>
    </div>
  );
};

export default LabeledFieldLayout;
