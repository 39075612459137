import { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import { parseJson } from 'shared/uibuilder/list/builder/useListParams';
import removeEmptyValuesFromObject from 'shared/objectHelper';
import useCacheService from 'shared/cache/cacheService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { CandidateData, ExtensionData } from 'erp/candidate/extension/candidateFromExtensionService';
import useArtifactService from 'artifact/artifactService';
import { FILE_CATEGORY } from 'artifact/const';
import useAdditionalContactsHelper from '../../../shared/input/AdditionalContactListInput/useAdditionalContactsHelper';

export const getCandidateFormData = (initialData: string | object, defaultData?: CandidateData) => {
  const data = (typeof initialData === 'string' ? parseJson(initialData) : initialData) || {};
  const defaultFirstName = get(defaultData, 'name.firstName');
  const defaultLastName = get(defaultData, 'name.lastName');
  const defaultMiddleName = get(defaultData, 'name.middleName');

  const formData = {
    name: {
      firstName: get(data, 'name.firstName', defaultFirstName),
      lastName: get(data, 'name.lastName', defaultLastName),
      middleName: get(data, 'name.middleName', defaultMiddleName),
    },
    dateOfBirth: data.dateOfBirth || defaultData?.dateOfBirth,
    personalPhoneNumber: data.personalPhoneNumber || defaultData?.personalPhoneNumber,
    otherPhoneNumber: data.otherPhoneNumber || defaultData?.otherPhoneNumber,
    personalEmail: data.personalEmail || defaultData?.personalEmail,
    additionalContacts: data.additionalContacts || defaultData?.additionalContacts,
    photosIds: data.photosIds || defaultData?.photosIds,
    resume: { ...(defaultData?.resume || {}), ...(data.resume || {}) },
    sourceComment: data.sourceComment || defaultData?.sourceComment,
  };

  return removeEmptyValuesFromObject(formData);
};

export const CANDIDATE_KEY_CACHE = 'candidateApplicationData';

const useCandidateApplicationFromExtensionService = () => {
  const [parsedProfileData, setParsedProfileData] = useState({});
  const [isParsingFinished, setIsParsingFinished] = useState(false);
  const [extensionData, setExtensionData] = useState<Nullable<ExtensionData>>(null);
  const { data: formData, setFormData = () => {} } = useFormContext();
  const { setArtifactsToStore } = useArtifactService();
  const { getPreparedAdditionalContacts } = useAdditionalContactsHelper();

  const { addToCache, getValue } = useCacheService(CANDIDATE_KEY_CACHE);

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent, false);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFormData = (formInitialData: CandidateData) => {
    const candidateData = formData?.candidate;
    const firstName = formInitialData?.name?.firstName;
    const lastName = formInitialData?.name?.lastName;
    const phone = formInitialData?.personalPhoneNumber;
    const email = formInitialData?.personalEmail;
    const additionalContacts = formInitialData?.additionalContacts;

    const updatedCandidateData = {
      ...candidateData,
      name: {
        firstName: firstName == null ? candidateData?.name?.firstName : firstName,
        lastName: lastName == null ? candidateData?.name?.lastName : lastName,
      },
      personalPhoneNumber: phone == null ? candidateData?.personalPhoneNumber : phone,
      personalEmail: email == null ? candidateData?.personalEmail : email,
      additionalContacts:
        additionalContacts == null
          ? getPreparedAdditionalContacts(candidateData?.additionalContacts)
          : getPreparedAdditionalContacts(additionalContacts),
      photosIds: formInitialData?.photosIds,
    };

    const updatedData = {
      ...formData,
      candidate: updatedCandidateData,
      resume: { ...(formData?.resume || {}), ...(formInitialData?.resume || {}) },
      sourceComment: formInitialData?.sourceComment,
    };

    const resumeId = formInitialData?.resume?.resumeArtifactIds?.[0];

    if (resumeId) {
      const file = {
        id: resumeId,
        name: 'Profile.pdf',
        type: 'pdf',
      };

      setArtifactsToStore({
        [file.id]: {
          filePreview: FILE_CATEGORY.PDF.icon,
          id: file.id,
          type: file.type,
          name: file.name,
          title: file.name,
        },
      });
    }

    setFormData(updatedData);
  };

  const handleMessageEvent = async ({ data, origin, source }: MessageEvent) => {
    if (origin.startsWith('chrome-extension://')) {
      const extensionEventData = { origin, source } as ExtensionData;
      const formInitialData = getCandidateFormData(JSON.parse(data));
      addToCache(CANDIDATE_KEY_CACHE, {
        formInitialData,
        extensionData: extensionEventData,
      });
      setParsedProfileData(formInitialData);
      setIsParsingFinished(true);

      sendMessageToExtension({ hasPermissions: true }, extensionEventData);
    }
  };

  const sendMessageToExtension = useCallback(
    (message: any, messageEvent?: ExtensionData) => {
      const { source, origin } = extensionData || messageEvent || {};

      if (source && origin) {
        source?.postMessage(message, origin);
      }

      if (!extensionData && messageEvent) {
        setExtensionData(messageEvent);
      }

      if (message?.created) {
        setIsParsingFinished(false);
      }
    },
    [extensionData],
  );

  useEffect(() => {
    const candidateData = getValue(CANDIDATE_KEY_CACHE);

    if (candidateData) {
      setParsedProfileData(candidateData.formInitialData || {});
      setIsParsingFinished(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFormData(parsedProfileData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedProfileData]);

  return {
    isParsingFinished,
    parsedProfileData,
    sendMessageToExtension,
  };
};

export default useCandidateApplicationFromExtensionService;
