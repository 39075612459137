import React from 'react';
import { BaseFilterProps } from 'shared/uibuilder/list/filter/FilterContext';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { KPI_STATUS_OPTIONS } from '../../useKPIService';

const KpiStatusFilter = ({ ...props }: BaseFilterProps & { label: string }) => {
  return (
    <DropDownFilter {...props}>
      <EnumDropdown options={KPI_STATUS_OPTIONS} {...props} />
    </DropDownFilter>
  );
};

export default KpiStatusFilter;
