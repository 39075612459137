/* istanbul ignore file */
import React from 'react';
import EnumDropdown, { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';

import { EDUCATION_OPTIONS } from 'erp/employee/Education/shared/educationService';

const EducationTypeInput = (props: Omit<EnumDropdownProps, 'options'>) => {
  return <EnumDropdown {...props} options={EDUCATION_OPTIONS} />;
};

export default EducationTypeInput;
