/* istanbul ignore file */
import React from 'react';
import { RadioButtonGroup } from 'shared/uibuilder/form/input';
import { RadioButtonGroupProps } from 'shared/uibuilder/form/input/RadioButtonGroup';

export const FEEDBACK_FLAGS: any = {
  SPAM: 'Spam and fake content',
  OFFTOPIC: 'Off-topic',
  RESTRICTED: 'Restricted content',
  ILLEGAL: 'Illegal content',
  SEXUALLY: 'Sexually explicit content',
  OFFENSIVE: 'Offensive content',
};

const options = Object.keys(FEEDBACK_FLAGS).map(key => ({
  value: key,
  label: FEEDBACK_FLAGS[key],
}));

const FlagAsInappropriateRadioButtonGroup = (props: RadioButtonGroupProps) => {
  return (
    <div className="inappropriate-radio-button-group">
      <RadioButtonGroup {...props} options={options} isColumnDirection />
    </div>
  );
};

export default FlagAsInappropriateRadioButtonGroup;
