/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import useOfficeService, { OFFICE_NAMES } from 'erp/employee/office/officeService';
import InputLoader from 'shared/uibuilder/InputLoader';
import { TextFieldValue } from 'shared/uibuilder/field/TextField';

const EmployeeOfficeField = (props: FieldProps<TextFieldValue>) => {
  const { additionalValues } = props;
  const [displayedValue, setDisplayedValue] = useState(null);
  const { getValue } = useFieldHelper(props);
  const { findAll } = useOfficeService();

  useEffect(() => {
    (async () => {
      let offices = await findAll();
      if (additionalValues) {
        offices = offices.concat(additionalValues);
      }
      const office = offices.find((item: { id: TextFieldValue }) => item.id === getValue()) || {};
      const officesNames = { ...OFFICE_NAMES };
      if (additionalValues) {
        additionalValues.forEach((value: { name: string | number; label: any }) => {
          officesNames[value.name] = value.label || value.name;
        });
      }
      setDisplayedValue(officesNames[office.name]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (displayedValue === null) {
    return <InputLoader />;
  }

  return <TextField {...props} value={displayedValue} />;
};

export default EmployeeOfficeField;
