import { useRecruitmentApi } from 'api';
import useCrudService from 'shared/crud';
import createVacancySchema from './createupdate/create/createVacancySchema';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { cloneDeep } from 'lodash';
import { Vacancy, VacancyFormType, VacancyGroupType } from './types';
import useHiringService, { HIRING_MODE } from '../hiring/useHiringService';
import { LOCATION_ACCOMMODATIONS, RELOCATION } from './constants';
import usePositionService from '../../position/positionService';

const VACANCIES_GROUPS = '/vacancies/groups';

const useVacancyGroupService = () => {
  const { sendPostRequest, sendGetRequest, sendPutRequest } = useRecruitmentApi();
  const { getByIds } = useHiringService();
  const { findAll: findAllPositions } = usePositionService();

  const { searchAll: baseSearchAll, ...baseCrud } = useCrudService({
    singleResourceUrl: `${VACANCIES_GROUPS}/:id`,
    listResourceUrl: VACANCIES_GROUPS,
    apiService: useRecruitmentApi,
  });

  const mapOfficeToLocation = (office: number) => {
    switch (office) {
      case 1:
        return { country: 'US', city: 'Austin' };
      case 2:
        return { country: 'BY', city: 'Minsk' };
      case 3:
        return { country: 'PL', city: 'Krakow' };
      case 4:
        return { country: 'GE', city: 'Tbilisi' };
      case 5:
      default:
        return null;
    }
  };

  const getSpecializationOptions = async () => {
    const positions = await findAllPositions();

    return positions.map((position: Dictionary<string>) => {
      return { alias: position.vacancyAlias, name: position.name };
    }, []);
  };

  const mapLegacyVacancy = async (vacancy: any) => {
    const hiringRequests = await getByIds(vacancy.hiringRequestIds);

    if (hiringRequests.length === 0) {
      return {
        error: 'Migration for vacancies without hiring requests is not supported. Please, contact DaVinci Support.',
      };
    }

    const group = {
      alias: vacancy.alias,
      specialization: vacancy.name,
      recruiter: null,
      sourcer: vacancy.sourcerAlias,
      createdAt: vacancy.createdAt,
      createdBy: vacancy.createdById,
      updatedAt: vacancy.updatedAt,
      updatedBy: vacancy.updatedById,
    };

    const vacancies = hiringRequests.map((request: any) => ({
      status: {
        DRAFT: 'REQUIRES_REVIEW',
        OPEN: 'OPEN',
        CLOSED: 'CLOSED',
        REJECTED: 'CANCELLED',
      }[vacancy.status as string],
      specialization: request.specialisation,
      competencyLevelMin: vacancy.competencyLevel === 'N/A' ? null : vacancy.competencyLevel,
      competencyLevelMax: vacancy.competencyLevel === 'N/A' ? null : vacancy.competencyLevel,
      isAsap: request.isAsap,
      targetHiringDate: request.targetDate,
      expirationDate: request.expirationDate,
      hiringMode: request.hiringMode,
      workingConditions: vacancy.vacancyDetails.map((details: any) => ({
        registration: {
          1: 'US',
          2: 'BY',
          3: 'PL',
          5: 'PL',
        }[details.office as number],
        accommodation: {
          1: 'ANYWHERE',
          2: 'BY',
          3: 'EU',
          5: 'ANYWHERE',
        }[details.office as number],
        employmentTypes: details.assignmentType,
        contractTypes: details.contractType.map(
          (type: any) =>
            ({
              EC: 'EMPLOYMENT_CONTRACT',
              CFS: 'CONTRACT_FOR_SERVICES',
              B2B: 'B2B_CONTRACT',
              INTERNSHIP: 'INTERNSHIP',
            })[type as string],
        ),
        isRelocationAvailable: {
          REQUIRED: true,
          OPTIONAL: true,
          NOT_APPLICABLE: false,
        }[details.relocationOption as string],
      })),
      projects: [],
      responsibilities: `<ul>${vacancy.description.responsibilities.map(
        (responsibility: any) => `<li>${responsibility.name}: ${responsibility.description}</li>`,
      )}</ul>`,
      mandatoryRequirements: {
        description: `<ul>${vacancy.description.requirements.map(
          (responsibility: any) => `<li>${responsibility.name}: ${responsibility.description}</li>`,
        )}</ul>`,
        skills: vacancy.description.mandatorySkills,
        requiredExperienceInYears: vacancy.minExperience,
        englishLevel: 'B1',
      },
      optionalRequirements: {
        description: `<ul>${vacancy.description.optionalRequirements.map(
          (responsibility: any) => `<li>${responsibility.name}: ${responsibility.description}</li>`,
        )}</ul>`,
        skills: vacancy.description.optionalSkills,
      },
      notes: {
        description: vacancy.description.projectText,
        attachmentIds: [],
      },
      positions: request.requestPositions.map((position: any) => ({
        type: {
          REQUIRED: 'REQUIRED',
          EXTRA: 'ADDITIONAL',
        }[position.necessityStatus as string],
        status: {
          OPEN: 'OPEN',
          CLOSED: 'CLOSED',
          CANCELED: 'CANCELED',
        }[position.positionStatus as string],
        forecastDate: position.forecastDates[0] ?? null,
        closeDate: position.closeDate ?? null,
        startWorkingDate: null,
      })),
      createdAt: request.createdAt,
      createdBy: request.createdBy,
    }));

    const vacancyDetails = vacancy.vacancyDetails.map((details: any) => ({
      location: mapOfficeToLocation(details.office),
      assignmentType: details.assignmentType,
      workingSchedule: details.workingSchedule,
      workplaceLocation: details.workplaceLocation,
      contractType: details.contractType.map(
        (type: any) =>
          ({
            EC: 'EMPLOYMENT_CONTRACT',
            CFS: 'CONTRACT_FOR_SERVICES',
            B2B: 'B2B_CONTRACT',
            INTERNSHIP: 'INTERNSHIP',
          })[type as string],
      ),
      relocationOption: details.relocationOption,
    }));

    const publications = vacancy.publications.map((publication: any) => ({
      description: publication.description,
      name: publication.name,
      type: publication.type,
      status: publication.status,
      englishLevels: publication.englishLevels,
      countryOfResidences: publication.countryOfResidences,
      formFields: publication.formFields,
      createdAt: publication.createdAt,
      createdBy: publication.createdBy,
      updatedAt: publication.updatedAt,
      updatedBy: publication.updatedBy,
      publicationDetails: vacancyDetails,
    }));

    return { group, vacancies, publications };
  };

  const migrateVacancy = async (group: any, vacancies: any[], publications: any[]) => {
    const result = await sendPostRequest('/vacancies/migrate', { group, vacancies, publications });
    return result.json();
  };

  const mapDataForCreate = (values: VacancyFormType) => {
    return {
      ...values,
      isCompetencyLevelRange: undefined,
      competencyLevel: undefined,
      competencyLevelMin: values.competencyLevelMin || values.competencyLevel,
      competencyLevelMax: values.competencyLevelMax || values.competencyLevel,
      workingConditions: values.workingConditions.map(condition => {
        return {
          ...condition,
          isRelocationAvailable: condition.isRelocationAvailable === RELOCATION.AVAILABLE,
        };
      }),
    };
  };

  const mapVacancyGroup = (
    values: VacancyGroupType,
    vacancies: Vacancy[],
    specializationsResponse: { alias: string; name: string }[],
  ) => {
    const {
      competencyLevelMax,
      hiringModes,
      competencyLevelMin,
      englishLevelMin,
      englishLevelMax,
      compensationMin,
      compensationMax,
      accommodations,
      ...details
    } = values.details;

    const formatCompensation = (min: number, max: number): string | null => {
      if (min === max) {
        return max ? `${max}$` : null;
      }
      return `${min}$ - ${max}$`;
    };

    return {
      ...values,
      specialization:
        specializationsResponse.find(item => item.alias === values.specialization)?.name || values.specialization,
      vacancies: vacancies.map((vacancy: Vacancy) => {
        const { competency, ...data } = vacancy;
        return {
          // TODO Update this fields when BE will be ready
          dir: '',
          coordinator: data.createdBy,
          compensation: '',

          ...data,
          competency:
            competency.maxLevel === competency.minLevel
              ? competency.maxLevel
              : `${competency.minLevel} - ${competency.maxLevel}`,
          specialization:
            specializationsResponse.find(item => item.alias === vacancy.specialization)?.name || vacancy.specialization,
        };
      }),
      details: {
        competencyLevel:
          competencyLevelMax === competencyLevelMin
            ? competencyLevelMax
            : `${competencyLevelMin} - ${competencyLevelMax}`,
        englishLevel: englishLevelMax === englishLevelMin ? englishLevelMax : `${englishLevelMin} - ${englishLevelMax}`,
        compensation: formatCompensation(compensationMin, compensationMax),
        hiringModes: hiringModes.map(mode => HIRING_MODE[mode]),
        accommodations: accommodations.find(item => item === LOCATION_ACCOMMODATIONS.ANYWHERE)
          ? [LOCATION_ACCOMMODATIONS.ANYWHERE]
          : accommodations,
        ...details,
      },
    };
  };

  const createVacancy = async (values: FormFieldsData) => {
    const tempValues = cloneDeep(values) as VacancyFormType;
    const res = await sendPostRequest('/vacancies/request', mapDataForCreate(tempValues));

    return res.json();
  };

  const searchVacanciesByGroup = async (groupId: StringOrNumber) => {
    const res = await sendPostRequest('/vacancies/search', {
      filter: {
        'vacancyGroup.id': {
          eq: groupId,
        },
      },
      pageSize: 30,
    });

    return res.json();
  };

  const getVacancyGroupById = async (groupId: StringOrNumber) => {
    try {
      const res = await sendGetRequest(`${VACANCIES_GROUPS}/${groupId}`);
      const groupDate = await res.json();
      const vacancies = await searchVacanciesByGroup(groupId);
      const setSpecializationsResponse = await getSpecializationOptions();

      return mapVacancyGroup(groupDate, vacancies.result || [], setSpecializationsResponse);
    } catch (error) {
      return error;
    }
  };

  const updateVacancyGroup = async (id: StringOrNumber, values: FormFieldsData) => {
    try {
      const res = await sendPutRequest(`${VACANCIES_GROUPS}/${id}`, {
        sourcers: values.sourcers.map((item: any) => item.value),
        recruiters: values.recruiters.map((item: any) => item.value),
      });

      return res.json();
    } catch (error) {
      return error;
    }
  };

  const getCreateValidationSchema = () => Promise.resolve(createVacancySchema);

  return {
    updateVacancyGroup,
    getVacancyGroupById,
    createVacancy,
    mapLegacyVacancy,
    migrateVacancy,
    getCreateValidationSchema,
    searchVacanciesByGroup,
    ...baseCrud,
  };
};

export default useVacancyGroupService;
