/* istanbul ignore file */
// removed from test coverage because it will be need to refactor

// libs
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
// services
import { LEAVE_PATH, READ_LEAVES_LIST, CREATE_LEAVE, UPDATE_LEAVE, EXTEND_LEAVE } from 'erp/leave/shared/leaveService';
import LeavesList from 'erp/leave/List';
import LeaveContext from 'erp/leave/LeaveContext';
import { CreateLeave, UpdateLeave } from 'erp/leave/createupdate';
import ViewLeave from 'erp/leave/Show';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import BalancesListPage from './BalancesListPage';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const LEAVE_BALANCES_PATH = '/balances/list';

export const useLeaveRoute = () => {
  return useRoute({ listRoute: LEAVE_PATH });
};

export const useLeaveUrl = () => {
  return useEntityUrl({
    baseLink: LEAVE_PATH,
  });
};

const LeaveRouter = () => {
  const { listRoute, singleEntityRoute, createRoute, updateRoute } = useLeaveRoute();

  return (
    <ProtectedRouter basePath={LEAVE_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_LEAVES_LIST}>
              <LeavesList />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute path={createRoute} globalPermissionToCheck={CREATE_LEAVE}>
              <CreateLeave />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <LeaveContext permissionToCheck="READ">
              <ViewLeave />
            </LeaveContext>
          }
        />

        <Route
          path={updateRoute}
          element={
            <LeaveContext permissionToCheck={[UPDATE_LEAVE, EXTEND_LEAVE]}>
              <UpdateLeave />
            </LeaveContext>
          }
        />

        <Route path={LEAVE_BALANCES_PATH} element={<BalancesListPage />} />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

LeaveRouter.getRouterPath = () => `${LEAVE_PATH}/*`;

export default LeaveRouter;
