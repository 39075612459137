/* istanbul ignore file */
import React from 'react';
import SummaryMenuItem from 'uibuilder/menuitem/SummaryMenuItem';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { useCompetencyUrl } from 'erp/qualification/competency/CompetencyRouter';

const CompetencyMenu = () => {
  const { getSingleEntityUrl } = useCompetencyUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl()} />
    </SidebarStickyMenu>
  );
};

export default CompetencyMenu;
