/* istanbul ignore file */
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import React from 'react';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import DeleteTimelineRecordButton from 'uibuilder/layout/timeline/buttons/DeleteTimelineRecordButton';
import useCrmNoteService, {
  DELETE_CRM_NOTE_PERMISSION,
  UPDATE_CRM_NOTE_PERMISSION,
} from 'crm/shared/timeline/note/crmNoteService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';

const NoteTimelineRecordLayout = ({ onEdit, ...props }: any) => {
  const { deleteById } = useCrmNoteService();
  const { data } = useShowContext();
  const id = data.getValueBySource('id');
  const timelineId = data.getValueBySource('timelineId');

  const buttons = [
    <EditTimelineRecordButton permissionToCheck={UPDATE_CRM_NOTE_PERMISSION} key="edit" onEdit={onEdit} />,
    <DeleteTimelineRecordButton
      permissionToCheck={DELETE_CRM_NOTE_PERMISSION}
      deleteMethod={deleteById}
      key="add"
      id={id}
      timelineId={timelineId}
      entityName="note"
    />,
  ];

  return <BaseTimelineRecordLayout timelineType={TimelineType.NOTE} buttons={buttons} {...props} />;
};

export default NoteTimelineRecordLayout;
