/* istanbul ignore file */
import { ProtectedRouter } from 'routing';
import { Routes } from 'react-router-dom';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import React from 'react';
import UnlinkedCrmActivitiesTimelinePage from 'crm/unlinkedEmails/UnlinkedCrmActivitiesTimelinePage';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_UNLINKED_CRM_ACTIVITY_PERMISSION } from 'crm/unlinkedEmails/shared/unlinkedCrmActivitiesService';

export const useUnlinkedCrmEmailActivitiesRoute = () => {
  return {
    unlinkedRoute: '/timeline/:timelineEntryId?',
  };
};

const UnlinkedCrmEmailActivitiesRouter = () => {
  const { unlinkedRoute } = useUnlinkedCrmEmailActivitiesRoute();

  return (
    <ProtectedRouter basePath="/crm/unlinked-activities/timeline">
      <Routes>
        <Route
          path={unlinkedRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_UNLINKED_CRM_ACTIVITY_PERMISSION}>
              <UnlinkedCrmActivitiesTimelinePage />
            </GlobalProtectedRoute>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

UnlinkedCrmEmailActivitiesRouter.getRouterPath = () => '/unlinked-activities/*';

export default UnlinkedCrmEmailActivitiesRouter;
