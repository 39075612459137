import React from 'react';
import EmployeeInput from 'erp/employee/shared/input/EmployeeInput';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

type OnBehalfOfInputProps = {
  onBehalfOfSource: string;
  onBehalfOfEmployeeSource: string;
  onBehalfOfEmployeeNameSource: string;
  label: string;
};

const OnBehalfOfInput = ({
  onBehalfOfSource,
  onBehalfOfEmployeeSource,
  onBehalfOfEmployeeNameSource,
  ...props
}: OnBehalfOfInputProps) => {
  const { getOnChangeCallback, getValue: getOnBehalfValue } = useInputHelper({ source: onBehalfOfSource });
  const { getOnChangeCallback: getEmployeeAliasOnChangeCallback } = useInputHelper({
    source: onBehalfOfEmployeeSource,
  });

  const onInputChange = getOnChangeCallback();
  const onEmployeeChange = getEmployeeAliasOnChangeCallback();

  // handler on suggestion selected from dropdown options
  const handleSuggestionSelected = (data: any) => {
    onEmployeeChange({ target: { value: data[onBehalfOfEmployeeSource] } });
    onInputChange({ target: { value: null } });
  };

  // handler for input change on typing (query string)
  const handleInputChange = (query: any) => {
    onEmployeeChange({ target: { value: null } });
    onInputChange({ target: { value: query } });
  };

  const decorateValue = (value: string) => value.replace(/[^a-zA-Z- ]/g, '');

  return (
    <EmployeeInput
      {...props}
      onChangeCallback={handleSuggestionSelected}
      source={onBehalfOfEmployeeSource}
      nameSource={getOnBehalfValue() ? onBehalfOfSource : onBehalfOfEmployeeNameSource}
      onInputChangeCallback={handleInputChange}
      valueDecorator={decorateValue}
    />
  );
};

export default OnBehalfOfInput;
