import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import TextField from 'shared/uibuilder/field/TextField';
import EnumField from 'shared/uibuilder/field/EnumField';
import {
  KPI_TYPES_UNIT_OPTIONS,
  KPI_VALUES_UPDATE,
  SERVICE_MANAGEMENT_KPI_VALUE_CREATE_KPI_VALUE,
} from '../../useKPIService';
import UpdateKpiValueButton from '../buttons/UpdateKpiValueButton';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import PublishKpiValueButton from '../buttons/PublishKpiValueButton';

const KPIValueListField = ({ source, dateSource }: { source: string; dateSource: string }) => {
  const { data } = useShowContext();
  const { filter, reloadData = () => {} } = useListContext();
  const kpis = data.getValueBySource(source);
  const effectiveDate = data.getValueBySource(dateSource);
  const kpiStructureId = data.getValueBySource('id');
  const currentFilterValue = filter?.period?.eq;

  return (
    <ControlledList data={{ items: kpis, totalPages: 1, totalElements: kpis?.length }}>
      <DataGridLayout
        customEmptyMessage="No parameters found"
        buttons={[
          <PublishKpiValueButton
            kpiStructureId={kpiStructureId}
            effectiveDate={effectiveDate}
            currentFilterValue={currentFilterValue}
            permissionToCheck={SERVICE_MANAGEMENT_KPI_VALUE_CREATE_KPI_VALUE}
            parenListReload={reloadData}
          />,
          <UpdateKpiValueButton permissionToCheck={KPI_VALUES_UPDATE} parenListReload={reloadData} />,
        ]}
      >
        <TextField label="Name" source="name" width="30%" />
        <TextField label="Value" source="value" width="30%" />
        <EnumField label="Type" source="type" options={KPI_TYPES_UNIT_OPTIONS} />
      </DataGridLayout>
    </ControlledList>
  );
};

export default KPIValueListField;
