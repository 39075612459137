/* istanbul ignore file */
import { useKernelApi } from 'api';
import { useLeadUrl } from '../LeadRouter';

export const READ_LEADS_DUPLICATES = 'READ_LEADS_DUPLICATES';

export type LeadDuplicate = {
  id: number;
  spam: boolean;
  url: string;
  accessGranted: boolean;
};

const useLeadDuplicateService = () => {
  const { sendGetRequest } = useKernelApi();
  const { getSingleEntityUrl } = useLeadUrl();

  const getDuplicates = async (id: StringOrNumber) => {
    const response = await sendGetRequest(`/crm/leads/${id}/duplicates`);
    const duplicates = await response.json();
    duplicates.forEach((duplicate: LeadDuplicate) => {
      // eslint-disable-next-line no-param-reassign
      duplicate.url = getSingleEntityUrl(duplicate.id);
    });
    return duplicates;
  };

  return {
    getDuplicates,
  };
};

export default useLeadDuplicateService;
