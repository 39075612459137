/* istanbul ignore file */
// libs
import React from 'react';
import Paper from 'uibuilder/Paper';
// components
import Message from 'shared/message/Message';
import Box from 'uibuilder/Box';
// context
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
// services
import { getVisibility } from 'shared/uibuilder/helper';
import { ShowPageLayoutWithoutMenuProps } from './ShowPageLayout';

const ShowPageLayoutWithoutMenu = ({
  breadcrumbs,
  buttons,
  children,
  entityHeader,
}: ShowPageLayoutWithoutMenuProps) => {
  const { data } = useShowContext();

  return (
    <Paper className="page-content" elevation={0}>
      {breadcrumbs && <div className="breadcrumbs__wrapper">{breadcrumbs}</div>}
      {(entityHeader || buttons) && (
        <div className="show-page__header-wrapper entity-header__wrapper">
          <div className="show-page__entity-header-wrapper">{entityHeader}</div>
          <div className="top-btns">
            {buttons &&
              buttons.map(button => {
                const { isVisible: isVisibleProp } = button.props;

                const isVisible = getVisibility(data, isVisibleProp);

                return isVisible ? button : null;
              })}
          </div>
        </div>
      )}
      <Box sx={{ position: 'relative' }}>
        <div className="alerts__wrapper">
          <Message />
        </div>

        {React.Children.map(children, child => {
          const { isVisible: isVisibleProp } = child.props;

          const isVisible = getVisibility(data, isVisibleProp);

          return isVisible ? child : null;
        })}
      </Box>
    </Paper>
  );
};

export default ShowPageLayoutWithoutMenu;
