import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import React, { useEffect } from 'react';
import { getLastSource } from 'shared/uibuilder/helper';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import CategoryUnitDropdown from '../dropdown/CategoryUnitDropdown';
import Box from 'uibuilder/Box';

const ObjectiveInput = (props: DefaultInputPropTypes<any>) => {
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const objective = getValue() || {};
  const rawOnChangeCallback = getRawOnChangeCallback() || (() => {});

  const source = getSource();

  useEffect(() => {
    if (!getValue()) {
      rawOnChangeCallback({
        [source]: {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCallback = (value: { [x: string]: any }) => {
    rawOnChangeCallback({
      [source]: {
        ...objective,
        ...getLastSource(value),
      },
    });
  };

  return (
    <Box display="flex" flexWrap="wrap" gap="20px">
      <Box width="200px">
        <TextInput label="Objective Name" source={`${source}.name`} onChangeCallback={onChangeCallback} isRequired />
      </Box>
      <Box>
        <CategoryUnitDropdown
          label="Unit"
          placeholder="Select unit"
          source={`${source}.unit`}
          onChangeCallback={onChangeCallback}
          isRequired
        />
      </Box>
    </Box>
  );
};

export default ObjectiveInput;
