/* istanbul ignore file */
const positiveNumberRegEx = /^((?!^0+$|0+[.]0+$)0|[1-9]\d{0,5})(?:[.]\d{1,2})?$/;

export const editOfferOnlyAttachmentValidation = {
  attachmentIds: {
    type: 'array',
    required: false,
    maxSize: 15,
  },
};

export default {
  offerCompensations: {
    type: 'array',
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        baseAmountType: {
          type: 'enum',
          required: false,
        },
        currency: {
          type: 'enum',
          required: true,
        },
        grossAmount: {
          type: 'string',
          required: false,
          minValue: {
            value: '$grossProbationAmount',
          },
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits and 2 decimals)',
          },
        },
        grossProbationAmount: {
          type: 'string',
          maxValue: {
            value: '$grossAmount',
            message: "Probation period value can't be greater than base amount value",
          },
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits and 2 decimals)',
          },
        },
        calculatedGrossAmount: {
          type: 'string',
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits and 2 decimals)',
          },
        },
        officeId: {
          type: 'number',
          required: true,
        },
        contractType: {
          type: 'enum',
          required: true,
        },
      },
    },
  },
};
