// @ts-nocheck
export default {
  name: 'John',
  lastName: 'Black',
  email: 'test@test.com',
  fullName: 'John Black',
  createdAt: '11.22.63',
  createdByName: 'Lenin',
  updatedByName: 'Lennon',
  updatedAt: '10.22.1995',
  address: {
    country: 'Belarus',
  },
};
