/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { BadgeField, DateField, ListColumnTotalField, PercentField, SearchLinkField } from 'shared/uibuilder/field';
import { useAssignmentUrl } from 'erp/assignment/AssignmentRouter';
import useAssignmentService, { ACTIVE_STATUS } from 'erp/assignment/shared/assignmentService';
import { EmployeeFilter, EmployeeLinkField } from 'erp/employee';
import { ResourceData } from 'shared/crud/baseCrudService';
import Filters from 'shared/uibuilder/list/filter/StaticFilters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import AssignmentStatusFilter from 'erp/assignment/shared/filter/AssignmentStatusFilter';
import EmployeePositionFilter from 'erp/employee/List/filter/EmployeePositionFilter';
import ProjectFilter from 'erp/project/List/filter/ProjectFilter';
import DateRangeFilter from 'shared/uibuilder/list/filter/DateRangeFilter';
import { getMaxPercentLabel, getMinPercentLabel } from 'erp/assignment/List/AssignmentListLayout';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import CreateEmployeeAssignmentButton from 'erp/employee/assignments/List/button/CreateEmployeeAssignmentButton';
import EditEmployeeAssignmentButton from 'erp/employee/assignments/List/button/EditEmployeeAssignmentButton';
import CopyEmployeeAssignmentButton from 'erp/employee/assignments/List/button/CopyEmployeeAssignmentButton';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { ListItem } from 'shared/uibuilder/list/ListContext';
import PositionInAssignmentField from 'erp/assignment/shared/field/PositionInAssignmentField';
import BenchBadge from 'erp/employee/assignments/shared/field/BenchBadge';
import AssignmentTargetLinkField from 'erp/assignment/target/shared/field/AssignmentTargetLinkField';

const EmployeeAssignmentsList = () => {
  const { search } = useAssignmentService();
  const employeeId = useEmployeeId();

  const getDataMethod = async (params: ResourceData) => {
    return search({
      ...params,
      filter: {
        'specification:assignmentsByEmployeeAlias': { eq: employeeId },
        ...params.filter,
      },
    });
  };

  const { getSingleEntityUrl } = useAssignmentUrl();

  const filters = (
    <Filters>
      <FiltersLayout>
        <AssignmentStatusFilter source="status" label="Status" />
        <EmployeeFilter source="owner.alias" label="Owner" />
        <EmployeeFilter source="createdBy.alias" label="Created by" />
        <EmployeePositionFilter source="specification:assignmentsByPositionIds" label="Position" />
        <ProjectFilter source="project.id" label="Project" />
        <DateRangeFilter canBeInFuture source="from" label="Start Date" />
        <DateRangeFilter canBeInFuture source="to" label="End Date" />
      </FiltersLayout>
    </Filters>
  );

  return (
    <List
      getDataMethod={getDataMethod}
      defaultFilter={{
        status: { in: [ACTIVE_STATUS] },
      }}
    >
      <RelationListLayout filter={filters} header="Assignments" actions={[<CreateEmployeeAssignmentButton />]}>
        <DataGridLayout
          buttons={[
            <CopyEmployeeAssignmentButton source="id" />,
            <EditEmployeeAssignmentButton
              isVisible={(data: ListItem) => {
                return data.canEdit;
              }}
            />,
          ]}
          isSmallTable
          showTotal={false}
        >
          <BadgeField
            badges={[<BenchBadge isVisible={assignment => assignment.bench} />]}
            source="id"
            label="ID"
            isSortable
            style={{ display: 'inline-flex' }}
          >
            <SearchLinkField url={getSingleEntityUrl} width={70} />
          </BadgeField>
          <AssignmentTargetLinkField
            label="Target"
            source="target.id"
            nameSource="target.displayName"
            typeSource="target.type"
          />
          <EmployeeLinkField source="ownerId" nameSource="ownerName" label="Owner" isSortable />
          <EmployeeLinkField
            source="resourceManagerId"
            nameSource="resourceManagerName"
            label="Resource Manager"
            width={140}
          />
          <PositionInAssignmentField label="Position" source="assignmentType" />
          <DateField source="from" label="Start Date" isSortable />
          <DateField source="to" label="End Date" isSortable />
          <PercentField
            source="minPercent"
            label={<ListColumnTotalField source="minPercent" value={getMinPercentLabel} />}
            isSortable
          />
          <PercentField
            source="maxPercent"
            label={<ListColumnTotalField source="maxPercent" value={getMaxPercentLabel} />}
            isSortable
          />
        </DataGridLayout>
      </RelationListLayout>
    </List>
  );
};

export default EmployeeAssignmentsList;
