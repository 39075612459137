import React from 'react';
import SwiperContainer from 'shared/Swiper/SwiperContainer';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Box from 'uibuilder/Box';
import { HtmlField } from 'shared/uibuilder/field';
import Typography from 'uibuilder/Typography';
import { VacancyProject } from '../../../types';

const VacancyProjectsSwiper = () => {
  const { data } = useShowContext();
  const { projects } = data.getData();

  return (
    <SwiperContainer>
      {projects.map((project: VacancyProject & { id: string }) => {
        return (
          <Box sx={{ height: '340px' }}>
            <Typography variant="h4" sx={{ marginBottom: { xs: '28px', sm: '30px' } }}>
              {project.name}
            </Typography>
            <Box sx={{ maxHeight: '255px', overflow: 'auto' }}>
              <HtmlField value={project.description} label="" />
            </Box>
          </Box>
        );
      })}
    </SwiperContainer>
  );
};

export default VacancyProjectsSwiper;
