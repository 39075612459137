import { BadgeField } from 'shared/uibuilder/field';
import React from 'react';
import StatusBadge from 'erp/serviceCatalog/budgets/shared/badge/StatusBadge';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const StatusBadgeField = () => {
  const ds = useDateService();

  const { getValue: getStatusList } = useFieldHelper<string>({ source: 'status' });
  const { getValue: getStartDateList } = useFieldHelper<string>({ source: 'startDate' });
  const { getValue: getEndDateList } = useFieldHelper<string>({ source: 'endDate' });

  const { data } = useShowContext();

  const getStatus = () => getStatusList() || data.getValueBySource('status');
  const getStartDate = () => getStartDateList() || data.getValueBySource('startDate');
  const getEndDate = () => getEndDateList() || data.getValueBySource('endDate');

  const isActive = () => {
    const isInPeriod = ds.isCurrentTimeInPeriod(getStartDate(), getEndDate());
    return getStatus() === 'APPROVED' && isInPeriod;
  };

  return (
    <BadgeField
      label="Status"
      badges={[
        <StatusBadge text="Approved" color="success" isVisible={getStatusList() === 'APPROVED'} />,
        <StatusBadge text="Awaiting Approval" color="warning" isVisible={getStatusList() === 'AWAITING_APPROVAL'} />,
        <StatusBadge text="Rejected" color="error" isVisible={getStatusList() === 'REJECTED'} />,
        <StatusBadge text="Active" color="primary" isVisible={isActive()} />,
      ]}
    />
  );
};

export default StatusBadgeField;
