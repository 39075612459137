/* istanbul ignore file */
// libs
import React from 'react';
import { useParams, Routes } from 'react-router-dom';
// components
import Page404 from 'shared/uibuilder/Page404';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import { READ_PERFORMANCE_REPORT } from 'erp/employee/performanceReport/usePerformanceReportService';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import PerformanceReportsListPage from 'erp/employee/performanceReport/List';
import ProtectedRoute from 'shared/authorization/ProtectedRoute';
import { Route } from 'shared/routing';

export const PERFORMANCE_REPORTS_PATH = '/performance-report';
export const PERFORMANCE_REPORT_ID_SOURCE = 'performanceReportId';

export const usePerformanceReportsRoute = () => {
  return useRoute({
    singleEntityRoute: `/:${PERFORMANCE_REPORT_ID_SOURCE}?`,
    listRoute: `${PERFORMANCE_REPORTS_PATH}`,
  });
};

export const usePerformanceReportUrl = () => {
  const { getSingleEntityUrl } = useEmployeeUrl();
  const employeeLink = getSingleEntityUrl();

  return useEntityUrl({
    baseLink: `${employeeLink}${PERFORMANCE_REPORTS_PATH}`,
    idSource: PERFORMANCE_REPORT_ID_SOURCE,
  });
};

export const usePerformanceReportId = () => {
  return useParams<Dictionary<string>>()[PERFORMANCE_REPORT_ID_SOURCE];
};

const PerformanceReportRouter = () => {
  const { singleEntityRoute } = usePerformanceReportsRoute();

  return (
    <Routes>
      <Route
        path={singleEntityRoute}
        element={
          <ProtectedRoute permissionToCheck={READ_PERFORMANCE_REPORT}>
            <PerformanceReportsListPage />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default PerformanceReportRouter;
