import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import CustomBadge from 'erp/recruitment/vacancy/shared/field/CustomBadge';
import { SERVICE_CATALOG_STATUSES, SERVICE_CATALOG_STATUSES_OPTIONS } from '../../useServiceCatalogService';

type ServiceCatalogStatusBadgeProps = {
  source: string;
};

const ServiceCatalogStatusBadge = ({ source, ...props }: ServiceCatalogStatusBadgeProps) => {
  const { getValue } = useFieldHelper({ source });
  const status = getValue() as keyof typeof SERVICE_CATALOG_STATUSES;

  if (status !== SERVICE_CATALOG_STATUSES.ARCHIVED) {
    return null;
  }

  const text = SERVICE_CATALOG_STATUSES_OPTIONS[status];
  const color = 'neutralLight';

  if (!text) {
    return null;
  }

  return <CustomBadge text={text} color={color} {...props} />;
};

export default ServiceCatalogStatusBadge;
