/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import CreateButton from 'uibuilder/button/CreateButton';
import { BooleanField, FullDateField, SearchLinkField, TextField } from 'shared/uibuilder/field';
import useProjectService, { CREATE_PROJECT, UPDATE_PROJECT } from 'erp/project/shared/projectService';
import { useProjectUrl } from 'erp/project/ProjectRouter';
import EditProjectButton from 'erp/project/List/button/EditProjectButton';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import BudgetLinkField from 'erp/budget/shared/field/BudgetLinkField';
import { CREATE_WASTE } from 'erp/costaccounting/waste/useWasteService';
import useWasteUrl from 'erp/costaccounting/waste/useWasteUrl';
import ProjectsStaticFilters from './filter/ProjectsStaticFilters';

const ProjectList = () => {
  const { search } = useProjectService();
  const { getCreateUrl, getSingleEntityUrl } = useProjectUrl();
  const { getCreateUrl: getWasteCreateUrl } = useWasteUrl();

  return (
    <List getDataMethod={search} defaultSortOrder="DESC" defaultSortField="updatedAt">
      <ListPageLayout
        header={<ProjectsStaticFilters title={<ListHeader label="Projects" icon="fa-folder-o" />} />}
        actions={[
          <CreateButton
            url={getWasteCreateUrl()}
            label="Report Waste"
            key="create-waste"
            permissionToCheck={CREATE_WASTE}
          />,
          <CreateButton
            url={getCreateUrl()}
            label="Create Project"
            key="create-project"
            permissionToCheck={CREATE_PROJECT}
          />,
        ]}
        search={<SearchInput label="Project Name" />}
      >
        <DataGridLayout
          buttons={[<EditProjectButton key="edit-project" source="id" outline permissionToCheck={UPDATE_PROJECT} />]}
        >
          <SearchLinkField source="id" label="Project ID" url={getSingleEntityUrl} isSortable width={140} />
          <TextField source="name" label="Project Name" isSortable />
          <BudgetLinkField
            label="Default Budget"
            source="defaultBudgetId"
            nameSource="defaultBudget.name"
            width={140}
          />
          <TextField source="sowId" label="SOW Number" isSortable />
          <FullDateField source="updatedAt" label="Last Modified Date" isSortable />
          <BooleanField source="assignmentForbidden" label="Assignment Forbidden" isSortable />
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default ProjectList;
