/* istanbul ignore file */
// All logic is located in helpers
import React from 'react';
import { ListProvider } from './ListContext';
import {
  useListCrud,
  useListParams,
  useListDataLoading,
  useListUrlStoring,
  useListLocalStorageStoring,
  useListDataUpdating,
  useListParamsConcatenation,
} from 'shared/uibuilder/list/builder';
import { Filter } from './filter/FilterContext';
import { SimpleListProps } from './SimpleList';

export const LIST_PAGE_SIZE = 30;

export interface ListProps extends SimpleListProps {
  defaultSortOrder?: string;
  defaultSortField?: string;
  defaultFilter?: Filter;
  pageSize?: number;
  onRemove?: (params: StringOrNumber) => void;
  localStorageKey?: string;
  uncontrolledFilters?: string[];
  shouldSaveUncontrolledFilters?: boolean;
}

const List = ({
  getDataMethod,
  children,
  localStorageKey,
  uncontrolledFilters,
  shouldSaveUncontrolledFilters,
  pageSize = LIST_PAGE_SIZE,
  defaultSortOrder = '',
  defaultSortField = '',
  defaultFilter = {},
  onRemove = () => {},
}: ListProps) => {
  const { params: urlParams, updateParamsInUrl } = useListUrlStoring();
  const { params: storageParams, updateParamsInLocalStorage } = useListLocalStorageStoring({
    localStorageKey,
    uncontrolledFilters,
    defaultFilter,
    shouldSaveUncontrolledFilters,
  });
  const { params: initialParams } = useListParamsConcatenation({ urlParams, localStorageParams: storageParams });
  const { params, sortBy, searchBy, filterBy, goToPage, resetParamsToDefault } = useListParams({
    initialParams,
    defaultSortOrder,
    defaultSortField,
    defaultFilter,
    onParamsChangeCallbacks: [updateParamsInUrl, updateParamsInLocalStorage],
  });

  const { loading, loadData, setLoading } = useListDataLoading({ getDataMethod, resetParamsToDefault });

  const { data, reloadData } = useListDataUpdating({ loadData, params, pageSize });
  const listCrud = useListCrud({ onRemove, inputData: data });

  const contextData = {
    ...params,
    filterBy,
    sortBy,
    goToPage,
    searchBy,
    loading,
    setLoading,
    reloadData,
    uncontrolledFilters,
    ...listCrud,
  };

  return <ListProvider value={contextData}>{children}</ListProvider>;
};

export default List;
