/* istanbul ignore file */
import React from 'react';
import List from 'shared/uibuilder/list/List';
import ListHeader from 'shared/uibuilder/list/layout/ListHeader';
import useEmployeeService, { EMPLOYEE_TYPES_ALIAS, ACTIVE_STATUS } from 'erp/employee/employeeService';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import SearchInput from 'shared/uibuilder/list/search/SearchInput';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import Filters from 'shared/uibuilder/list/filter/Filters';
import FiltersLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout';
import PositionField from 'erp/employee/shared/field/EmployeePositionField';
import EmployeeResourcePoolField from 'erp/employee/shared/field/EmployeeResourcePoolField';
import ViewEmployeeButton from 'erp/employee/shared/button/ViewEmployeeButton';
import CreateEmployeeButton from 'erp/employee/shared/button/CreateEmployeeButton';
import EmployeeWorkingStatusFilter from 'erp/employee/List/filter/EmployeeWorkingStatusFilter';
import { SearchResultField, FullDateField, SearchLinkField, TextField, BadgeField } from 'shared/uibuilder/field';
import NewBadge from 'shared/uibuilder/badge/NewBadge';
import { ASC } from 'shared/uibuilder/list/baseListHelper';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';
import ContractorBadge from 'shared/uibuilder/badge/ContractorBadge';
import EmployeeTypeFilter from 'erp/employee/List/filter/EmployeeTypeFilter';
import QuickFilter from 'shared/uibuilder/list/filter/QuickFilter';
import ActiveProbationBadge from 'shared/uibuilder/badge/ActiveProbationBadge';
import EmployeeWorkingStatusField from 'erp/employee/shared/field/EmployeeWorkingStatusField';
import ResourcePoolFilter from 'erp/employee/List/filter/ResourcePoolFilter';
import EmployeePositionFilter from './filter/EmployeePositionFilter';
import EmployeeProbationFilter from './filter/EmployeeProbationFilter';
import InternBadge from 'shared/uibuilder/badge/InternBange';
import OfficeDropdownFilter from '../../contracts/List/filter/OfficeDropdownFilter';
import EmployeeCommonSkillExperienceFilter from './filter/EmployeeCommonSkillExperienceFilter';
import { GetDataMethodType } from '../../../shared/uibuilder/list/builder/useListDataLoading';

const EmployeesListPage = () => {
  const { search, checkIsNewEmployee, getFullName } = useEmployeeService();
  const { getSingleEntityUrl } = useEmployeeUrl();

  const quickFilters = [
    <QuickFilter label="With active probation" state={{ 'specification:hasActiveProbation': { eq: true } }} />,
  ];

  const filters = (
    <Filters>
      <FiltersLayout quickFilters={quickFilters.length > 0 ? quickFilters : []}>
        <EmployeeWorkingStatusFilter source="workingStatus" label="Working Status" />
        <EmployeeTypeFilter source="employeeType" label="Type" />
        <ResourcePoolFilter source="resourcePool.id" label="Resource Pool" />
        <EmployeePositionFilter source="position.id" label="Position" />
        <EmployeeProbationFilter source="specification:hasActiveProbation" label="Probation" />
        <OfficeDropdownFilter source="specification:office" label="Current Office" />
        <EmployeeCommonSkillExperienceFilter
          source="specification:commonSkillExperience"
          label="Common Skill Experience"
        />
      </FiltersLayout>
    </Filters>
  );

  const fields = [
    <SearchLinkField source="alias" url={getSingleEntityUrl} label="Employee ID" isSortable width={135} />,
    <BadgeField
      badges={[
        <NewBadge
          id="newEmployee"
          isVisible={employee =>
            checkIsNewEmployee(employee.initializationState) && employee.employeeType !== EMPLOYEE_TYPES_ALIAS.INTERN
          }
        />,
        <InternBadge id="intern" isVisible={employee => employee.employeeType === EMPLOYEE_TYPES_ALIAS.INTERN} />,
        <ContractorBadge
          id="contractorEmployee"
          isVisible={employee => employee.employeeType === EMPLOYEE_TYPES_ALIAS.CONTRACTOR}
        />,
      ]}
      label="Name"
      source="nameEn.lastName"
      isSortable
    >
      <SearchResultField value={employee => getFullName(employee.nameEn)} />
    </BadgeField>,
    <PositionField label="Position" source="positionId" isSortable />,
    <TextField source="workingEmail" label="Syberry Email" isSortable />,
    <EmployeeResourcePoolField source="resourcePoolId" label="Resource Pool" isSortable />,
    <BadgeField
      label="Working Status"
      source="workingStatus"
      badges={[<ActiveProbationBadge isVisible={employee => employee.probation?.onProbation} />]}
      isSortable
    >
      <EmployeeWorkingStatusField source="workingStatus" />
    </BadgeField>,
    <TextField
      source="currentLocation"
      sortSource="extendedInformation.homeAddress.country"
      label="Current location"
      isSortable
    />,
    <FullDateField source="updatedAt" label="Last Modified" isSortable />,
  ];

  return (
    <List
      getDataMethod={search as GetDataMethodType}
      defaultSortField="nameEn.lastName"
      defaultSortOrder={ASC}
      defaultFilter={{ workingStatus: { in: [ACTIVE_STATUS] } }}
    >
      <ListPageLayout
        header={<ListHeader label="Employees" icon="fa-user" />}
        filter={filters}
        actions={[<CreateEmployeeButton />]}
        search={<SearchInput label="Employee Name or ID" />}
      >
        <DataGridLayout buttons={[<ViewEmployeeButton source="alias" />]} rowKeySource="alias">
          {fields}
        </DataGridLayout>
      </ListPageLayout>
    </List>
  );
};

export default EmployeesListPage;
