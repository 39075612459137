/* istanbul ignore file */
import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import { TextField, DateField } from 'shared/uibuilder/field';
import ExpenseHeader from 'financialAnalytic/expenses/shared/ExpenseHeader';
import SingleExpenseBreadcrumbs from 'financialAnalytic/expenses/shared/SingleExpenseBreadcrumbs';
import OfficeField from 'financialAnalytic/shared/field/OfficeField';
import InvoiceTotalField from 'financialAnalytic/invoice/shared/field/InvoiceTotalField';
import ExpenseStatusField from 'financialAnalytic/expenses/shared/field/ExpenseStatusField';
import ExpenseTypeField from 'financialAnalytic/expenses/shared/field/ExpenseTypeField';
import ExpenseSubTypeField from 'financialAnalytic/expenses/shared/field/ExpenseSubTypeField';
import MarkAsPaidExpenseButton from 'financialAnalytic/expenses/shared/button/MarkAsPaidExpenseButton';
import MarkAsPaidBonusButton from 'financialAnalytic/expenses/shared/button/MarkAsPaidBonusButton';
import RejectExpenseButton from 'financialAnalytic/expenses/shared/button/RejectExpenseButton';
import LeaveLink from 'erp/leave/shared/field/LeaveLink';
import {
  EXPENSE_STATUSES,
  EXPENSE_TYPES,
  EXPENSE_SUBTYPES_ALIASES,
} from 'financialAnalytic/expenses/useExpenseService';
import { useExpenseId } from 'financialAnalytic/expenses/ExpenseRouter';
import { BUSINESS_OBJECT_TYPES } from 'financialAnalytic/ledger/useLedgerService';
import TransfersListField from 'financialAnalytic/shared/field/TransfersListField';
import LedgerLink from 'financialAnalytic/ledger/shared/field/LedgerLink';
import AccountLinkField from 'crm/account/shared/field/AccountLinkField';
import { EmployeeLinkFieldWithNameLoading } from 'erp/employee';
import ProjectLinkField from 'erp/project/shared/field/ProjectLinkField';
import { READ_PROJECTS_LIST } from 'erp/project/shared/projectService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import CancelExpenseButton from '../shared/button/CancelExpenseButton';

const isSalesBonus = (data: any) =>
  [
    EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].SALES_BONUSES_AND_COMMISSIONS,
    EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].ACCOUNT_MANAGER_BONUSES_AND_COMMISSIONS,
  ].includes(data.getValueBySource('subtype'));

const isPerformanceBonus = (data: any) => data.getValueBySource('type') === EXPENSE_TYPES.PERFORMANCE_BASED_BONUS;

const isAccountRelatedInfoRequired = (data: any) =>
  data.getValueBySource('type') === EXPENSE_TYPES.DEDUCTION ||
  data.getValueBySource('subtype') === EXPENSE_SUBTYPES_ALIASES[EXPENSE_TYPES.ONE_TIME_PAYMENT].REIMBURSEMENTS;

const ViewExpensePageContent = ({
  isSensitiveDataLoading = false,
  isSensitiveDataIsShown = false,
  toggleSensitiveDataButton = <></>,
}: {
  isSensitiveDataLoading?: boolean;
  isSensitiveDataIsShown?: boolean;
  toggleSensitiveDataButton?: React.ReactElement;
}) => {
  const id = useExpenseId();

  return (
    <ShowPageLayout
      entityHeader={<ExpenseHeader />}
      breadcrumbs={<SingleExpenseBreadcrumbs />}
      hasSeparateSections
      headerButtons={[
        toggleSensitiveDataButton,
        <MarkAsPaidExpenseButton
          ids={[id]}
          isVisible={(data: any) =>
            data.getValueBySource('status') === EXPENSE_STATUSES.PENDING && !isPerformanceBonus(data)
          }
        />,
        <MarkAsPaidBonusButton
          isVisible={(data: any) =>
            data.getValueBySource('status') === EXPENSE_STATUSES.COMPLETED && isPerformanceBonus(data)
          }
        />,
        <RejectExpenseButton
          ids={[id]}
          isVisible={(data: any) =>
            (data.getValueBySource('status') === EXPENSE_STATUSES.PENDING ||
              data.getValueBySource('status') === EXPENSE_STATUSES.REGISTERED) &&
            !isPerformanceBonus(data)
          }
        />,
        <CancelExpenseButton statusSource="status" />,
      ]}
    >
      <ShowSection title="Main information">
        <SectionRow>
          <TextField source="id" label="Id" />
          <ExpenseTypeField source="type" label="Type" />
          <ExpenseSubTypeField
            source="subtype"
            typeSource="type"
            isVisible={(data: any) =>
              [
                EXPENSE_TYPES.LEAVE_COMPENSATION,
                EXPENSE_TYPES.ONE_TIME_PAYMENT,
                EXPENSE_TYPES.BENEFITS_EXPENSE,
                EXPENSE_TYPES.TAX,
                EXPENSE_TYPES.DEDUCTION,
              ].includes(data.getValueBySource('type'))
            }
          />
          <OfficeField source="employee.office" />
        </SectionRow>

        <SectionRow>
          <TextField source="registrationInfo.title" label="Title" />
          <InvoiceTotalField
            source="compensation.amount"
            currencySource="compensation.currency"
            label="Compensation"
            isVisible={isSensitiveDataIsShown}
          />
          <ExpenseStatusField source="status" label="Status" />
          <TextField
            label="Reject reason"
            source="rejectReason"
            isVisible={(data: any) => data.getValueBySource('status') === EXPENSE_STATUSES.REJECTED}
          />
          <TextField
            label="Cancel reason"
            source="rejectReason"
            isVisible={(data: any) => data.getValueBySource('status') === EXPENSE_STATUSES.CANCELLED}
          />
        </SectionRow>

        <SectionRow>
          <AccountLinkField
            label="Account"
            source="additionalInfo.project.accountId"
            idSource="additionalInfo.project.accountId"
            isInternal
            idValue={undefined}
            isVisible={isSalesBonus}
          />
          <TextField source="additionalInfo.project.sowId" label="SOW Number" isVisible={isSalesBonus} />
          <ProjectLinkField
            source="additionalInfo.project.projectId"
            nameSource="additionalInfo.project.projectId"
            label="Project"
            isVisible={isSalesBonus}
            permissionToCheck={READ_PROJECTS_LIST}
          />
        </SectionRow>

        <SectionRow>
          <DateField source="registrationInfo.registrationDate" label="Registration Date" />
          <DateField source="schedule.startDate" isVisible={isPerformanceBonus} />
          <DateField source="schedule.endDate" isVisible={isPerformanceBonus} />
          <DateField
            source="schedule.latestAccountingCycle"
            label="Last Accounting Cycle"
            isVisible={isPerformanceBonus}
            dateFormat={DATE_FORMAT.MONTH}
          />
          <DateField
            source="payoutInfo.payoutDate"
            label="Payout Date"
            isVisible={(data: any) => data.getValueBySource('status') === EXPENSE_STATUSES.PAID}
          />
          <EmployeeLinkFieldWithNameLoading
            source="employee.alias"
            label="Employee"
            isVisible={(data: any) => EXPENSE_TYPES.TAX !== data.getValueBySource('type')}
          />
          <LeaveLink
            source="additionalInfo.leaveId"
            nameSource="additionalInfo.leaveId"
            label="Leave"
            isVisible={(data: any) =>
              data.getValueBySource('additionalInfo')?.leaveId &&
              data.getValueBySource('type') === EXPENSE_TYPES.LEAVE_COMPENSATION
            }
          />
          <LedgerLink
            source="additionalInfo.transferAccount"
            nameSource="additionalInfo.transferAccount"
            label="Account to Debit"
            isVisible={isAccountRelatedInfoRequired}
          />
          <TextField
            source="registrationInfo.budgetCategory"
            nameSource="registrationInfo.budgetCategory"
            label="Budget Category"
            isVisible={isAccountRelatedInfoRequired}
          />
        </SectionRow>

        <BigSectionRow>
          <TextField source="registrationInfo.description" />
        </BigSectionRow>
      </ShowSection>
      <ShowSection isVisible={isSensitiveDataIsShown || isSensitiveDataLoading}>
        <TransfersListField
          businessObjectId={id}
          businessObjectType={BUSINESS_OBJECT_TYPES.EMPLOYEE_EXPENSE}
          startDateSource="registrationInfo.registrationDate"
          hasTransferIdField
        />
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewExpensePageContent;
