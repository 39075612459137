import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { CreateForm } from 'shared/uibuilder/form';
import ModalWindow from '../../../../shared/uibuilder/ModalWindow';

export interface IconMenuItem {
  key: string;
  text: string;
  color: string;
  modal?: React.ReactNode;
}

interface IconMenuProps {
  icon: React.ElementType;
  items: IconMenuItem[];
  defaultKey: string;
  onChange?: (selectedKey: string, payload: any) => void;
}

const IconMenu: React.FC<IconMenuProps> = ({ icon: Icon, items, defaultKey, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedKey, setSelectedKey] = useState<string>(defaultKey);
  const [pendingKey, setPendingKey] = useState<string | null>(null);
  const [ActiveModal, setActiveModal] = useState<ReactComponent<any> | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (key: string, modal?: React.ReactNode) => {
    if (modal) {
      setActiveModal(() => modal);
      setPendingKey(key);
    } else {
      handleStatusChange(key);
    }
    handleClose();
  };

  const handleStatusChange = (key: string, data: any = null) => {
    setSelectedKey(key);
    if (onChange) {
      onChange(key, data);
    }
  };

  const closeDialog = () => {
    setActiveModal(null);
  };

  const currentItem = items.find(item => item.key === selectedKey);

  return (
    <div>
      <Tooltip title={currentItem?.text || ''} arrow>
        <IconButton onClick={handleClick} sx={{ padding: 0 }}>
          {currentItem && <Icon style={{ color: currentItem.color, width: 16, height: 16 }} />}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {items.map(item => (
          <MenuItem key={item.key} onClick={() => handleMenuClick(item.key, item.modal)}>
            <ListItemIcon>
              <Icon style={{ color: item.color, width: 18, height: 18 }} />
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </Menu>

      {ActiveModal && (
        <ModalWindow
          key="modal-not-strict"
          modalSize="sm"
          backdrop="static"
          isOpen
          title="Additional info required"
          onToggle={closeDialog}
        >
          <CreateForm
            submitFormFunc={formData => {
              handleStatusChange(pendingKey!, formData);
              setPendingKey(null);
              return Promise.resolve();
            }}
            afterSubmit={{
              execute: () => setActiveModal(null),
            }}
          >
            <ActiveModal handleCancelClick={closeDialog} />
          </CreateForm>
        </ModalWindow>
      )}
    </div>
  );
};

export default IconMenu;
