import React from 'react';
import Sticky from 'react-sticky-el';
import { Button, SubmitButton } from 'uibuilder/button';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

type OneStopButtonsLayoutProps = {
  onCancelAction?: Nullable<() => void>;
  isFixed?: boolean;
};

const OneStopButtonsLayout = ({ onCancelAction, isFixed = true }: OneStopButtonsLayoutProps) => {
  const navigate = useNavigate();
  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();

  const onCancel = onCancelAction || (() => navigate(-1));

  const buttons = (
    <div className="btn-area">
      <SubmitButton isLoading={!isSubmitEnabled} onClick={submitForm} disabled={isLoadingArtifact} />
      <Button
        data-testid="cancel-btn"
        outline
        type="button"
        onClick={onCancel}
        disabled={!isSubmitEnabled || isLoadingArtifact}
      >
        Cancel
      </Button>
    </div>
  );

  if (isFixed) {
    return (
      <Sticky mode="bottom" stickyClassName="sticky-buttons-element sticky">
        <div className="floating-btns">{buttons}</div>
      </Sticky>
    );
  } else {
    return <>{buttons}</>;
  }
};

export default OneStopButtonsLayout;
