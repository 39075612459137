import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useHandbookService, { HandbookTableOfContentItem } from 'handbook/handbookService';
import useHandbookStructureService, { HandbookItemStructure } from 'handbook/handbookStructureService';
import { useHandbookUrl } from 'handbook/HandbookRouter';
import useHandbookMapper from 'handbook/handbookMapper';

const useHandbookPageService = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { getListUrl, getSingleEntityUrl } = useHandbookUrl();

  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [tableContent, setTableContent] = useState<HandbookTableOfContentItem[]>([]);
  const [pageStructure, setPageStructure] = useState<Nullable<HandbookItemStructure>>(null);

  const { getPage, isSearchMode, searching } = useHandbookService();
  const { getItemByPath } = useHandbookStructureService();
  const pagePathname = pathname.replace(getListUrl(), '');
  const { parseHtmlToReactElements } = useHandbookMapper();

  const loadPageData = async (pageStructureRes: Nullable<HandbookItemStructure>) => {
    try {
      const res = await getPage(pagePathname);
      const html = res.html ? parseHtmlToReactElements(res.html) : null;

      setTableContent(res.tableOfContent || []);
      setContent(html);
    } catch (e) {
      setContent(null);
      setTableContent([]);
    } finally {
      setPageStructure(pageStructureRes);
      setLoading(false);
    }
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const pageStructureRes: Nullable<HandbookItemStructure> = await getItemByPath(pagePathname);
      if (pagePathname) {
        await loadPageData(pageStructureRes);
      } else {
        const path = pageStructureRes?.pagePathname;
        const newPath = path ? getSingleEntityUrl(path.replace(/^\//, '')) : pathname;
        navigate(newPath, { replace: true });
      }
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    // Suppressed warnings because we only need to call useEffect callback after path updating.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagePathname]);

  return {
    loading,
    content,
    isSearchMode,
    searching,
    pageStructure,
    pagePathname,
    tableContent,
  };
};

export default useHandbookPageService;
