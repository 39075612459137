/* istanbul ignore file */
import React from 'react';
import SingleBreadcrumbItem from 'shared/uibuilder/BreadCrumbs/item/SingleBreadcrumbItem';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ViewServiceBudgetsBreadcrumbs
  from 'erp/serviceCatalog/budgets/List/ViewServiceBudgetsBreadcrumbs';

const ViewServiceBudgetBreadcrumbs = ({ children } : { children? : React.ReactNode }) => {
  const { data } = useShowContext();

  return (
    <ViewServiceBudgetsBreadcrumbs>
      <SingleBreadcrumbItem
        entity={`${data.getValueBySource('startDate')} — ${data.getValueBySource('endDate')}`}
      />
      {children}
    </ViewServiceBudgetsBreadcrumbs>
  );
};

export default ViewServiceBudgetBreadcrumbs;
