import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, TextField } from 'shared/uibuilder/field';
import useEmployeeService, { EMPLOYEE_WORKPLACE_LOCATION } from '../../employeeService';

const appendToName = (current: string, append?: any) => (append ? `${current}, ${append}` : current);

const EmployeeWorkplaceField = ({ value: initialValue, ...props }: FieldProps<any>) => {
  const { getValue, getData } = useFieldHelper({ ...props, value: initialValue });
  const { isWorkplaceLocationNotRemote } = useEmployeeService();

  const value = getValue();
  let displayedValue = null;

  if (value) {
    if (initialValue) {
      displayedValue = typeof value === 'function' ? value(getData()) : initialValue;
    } else {
      const { workplaceLocation, roomNumber, deskLocation } = value;
      displayedValue = EMPLOYEE_WORKPLACE_LOCATION[workplaceLocation];
      if (isWorkplaceLocationNotRemote(workplaceLocation)) {
        displayedValue = appendToName(displayedValue, roomNumber);
        displayedValue = appendToName(displayedValue, deskLocation);
      }
    }
  }

  return <TextField {...props} value={displayedValue} />;
};

export default EmployeeWorkplaceField;
