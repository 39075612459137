import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import EnumField from 'shared/uibuilder/field/EnumField';
import { getTimezonesOptions } from 'shared/timezone';
import { FieldProps } from 'shared/uibuilder/field';

const TimezoneField = ({ value: inputValue, source = null }: FieldProps) => {
  const { data } = useShowContext();

  const value = inputValue || (data && data.getValueBySource(source));
  const timezonesOptions = getTimezonesOptions();
  const options = timezonesOptions.reduce((o, { value: key, label }) => ({ ...o, [key]: label }), {});

  return <EnumField label="Timezone" value={value} options={options} />;
};

export default TimezoneField;
