/* istanbul ignore file */
import React from 'react';
import { BreadcrumbItem } from 'shared/uibuilder/BreadCrumbs';
import SingleLeadBreadcrumbs from 'crm/lead/shared/SingleLeadBreadcrumbs';

const ConvertLeadBreadcrumbs = () => {
  return (
    <SingleLeadBreadcrumbs>
      <BreadcrumbItem>Convert</BreadcrumbItem>
    </SingleLeadBreadcrumbs>
  );
};

export default ConvertLeadBreadcrumbs;
