import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

interface ClearAfterUnmountWrapperProps {
  children: React.ReactElement;
  isVisible?: ((data: any) => boolean) | boolean;
}

/**
 * Wrapper that will set value to null after unmount.
 * @constructor
 */
const ClearAfterUnmountWrapper = ({ children }: ClearAfterUnmountWrapperProps) => {
  const { getRawOnChangeCallback, getSource } = useInputHelper(children.props);
  const rawOnChangeCallback = getRawOnChangeCallback();

  /*
   * When is not mounted - value should be null
   */
  useEffect(() => {
    return () => {
      if (rawOnChangeCallback) {
        rawOnChangeCallback({
          [getSource()]: null,
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default ClearAfterUnmountWrapper;
