// libs
import React from 'react';
import Button, { ButtonProps } from 'uibuilder/button/Button';

interface SubmitButtonProps extends ButtonProps {
  isLoading?: boolean;
}

const SubmitButton = ({ isLoading = false, ...props }: SubmitButtonProps) => {
  return isLoading ? (
    <Button data-testid="submit-btn" color="primary" type="button" disabled>
      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
      Submitting...
    </Button>
  ) : (
    <Button data-testid="submit-btn" color="primary" type="submit" {...props}>
      Submit
    </Button>
  );
};

export default SubmitButton;
