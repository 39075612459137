import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import ReferenceDropdown, { ReferenceTypes } from './ReferenceDropdown';
import './ReferenceInput.scss';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import MultiSelectInput from 'shared/uibuilder/form/input/MultiSelectInput';
import useCorporateEventService, {
  CORPORATE_EVENT_STATUSES,
  CORPORATE_EVENT_TYPES,
} from 'financialAnalytic/corporateEvents/useCorporateEventService';

interface ReferenceInputProps {
  source: string;
  referenceType: string;
  value: {
    referenceValue: string;
  };
}

interface Option {
  value: string;
  label: string;
}

export const formatDataForMultiSelectInput = (data?: any[]): Option[] => {
  if (!data) {
    return [];
  }
  return data.map(item => ({ value: item.value, label: item.label }));
};

const ReferenceInput: React.FC<ReferenceInputProps> = ({ ...props }) => {
  const { searchAll: searchEvents } = useCorporateEventService();
  const { getSource, getRawOnChangeCallback, getValue } = useInputHelper(props);
  const parentOnChange = getRawOnChangeCallback() || (() => {});

  const onChangeCallback = (obj: Record<string, any>) => {
    const mappedValue = obj[`${props.source}.referenceValue`] || '';
    parentOnChange({
      [`${getSource()}.referenceValue`]: mappedValue.value,
    });
  };



  const loadEvent = async (params: any, type: string): Promise<Option[]> => {
    const request = {
      filter: {
        name: {
          ct: params,
        },
        type: {
          eq: type,
        },
        status: {
          eq: CORPORATE_EVENT_STATUSES.UPCOMING,
        },
      },
    };

    const events = await searchEvents(request);
    return events.map((c: { id: string; name: string }) => ({ value: c.name, label: c.name }));
  };

  const loadCompanyEvents = async (params: any): Promise<Option[]> =>
    loadEvent(params, CORPORATE_EVENT_TYPES.COMPANY_EVENT);
  const loadRecruitingEvents = async (params: any): Promise<Option[]> =>
    loadEvent(params, CORPORATE_EVENT_TYPES.RECRUITING_EVENT);
  const loadTeamEvents = async (params: any): Promise<Option[]> => loadEvent(params, CORPORATE_EVENT_TYPES.TEAM_EVENT);

  const getEnumValue = (key: string): ReferenceTypes | undefined => {
    return ReferenceTypes[key as keyof typeof ReferenceTypes];
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadFunctions: { [key in ReferenceTypes]: (params: any) => Promise<Option[]> } = {
    [ReferenceTypes.COMPANY_EVENT]: loadCompanyEvents,
    [ReferenceTypes.RECRUITING_EVENT]: loadRecruitingEvents,
    [ReferenceTypes.TEAM_EVENT]: loadTeamEvents,
  };

  const value = (getValue() as any) || '';
  const mappedValue: TypeOrFunction<any> | null = value.referenceValue
    ? { label: value.referenceValue, value: value.referenceValue }
    : null;

  return (
    <div className="reference-input-wrapper">
      <FormRow>
        <ReferenceDropdown source={`${props.source}.referenceType`} label="Reference type" />
        <MultiSelectInput
          isVisible={getEnumValue(props.referenceType) !== undefined}
          source={`${props.source}.referenceValue`}
          loadSuggestionsMethod={loadFunctions[getEnumValue(props.referenceType)!]}
          mapResults={formatDataForMultiSelectInput}
          value={mappedValue}
          onChangeCallback={onChangeCallback}
          isMulti={false}
        />
      </FormRow>
    </div>
  );
};

export default ReferenceInput;
