import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { cloneDeep } from 'lodash';
import { BUDGET_OBJECTIVE_UNIT_OPTIONS } from 'erp/serviceCatalog/budgets/budgetVersionService';
import List from 'shared/uibuilder/list/List';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import { TextField } from 'shared/uibuilder/field';
import AmountField from 'shared/uibuilder/field/AmountField';
import React from 'react';

const CategoriesAndObjectives = () => {
  const { data } = useShowContext();

  const getCategories = async () => {
    const categories = data.getValueBySource('categories');
    return {
      result: categories,
    };
  };

  const getObjectives = async () => {
    const objectives = data.getValueBySource('objectives');
    return {
      result: objectives.map((objective: any) => {
        const copy = cloneDeep(objective);
        copy.unit = BUDGET_OBJECTIVE_UNIT_OPTIONS[copy.unit];
        return copy;
      }),
    };
  };

  return (
    <>
      <List getDataMethod={getCategories}>
        <ShowSection title="Categories">
          <DataGridLayout>
            <TextField label="Name" source="name" width={30} />
            <AmountField label="Amount" source="amount" width={150} />
          </DataGridLayout>
          <br />
        </ShowSection>
      </List>
      <List getDataMethod={getObjectives}>
        <ShowSection title="Objectives">
          <DataGridLayout>
            <TextField label="Name" source="name" width={30} />
            <AmountField label="Value" source="value" width={10} />
            <TextField label="Unit" source="unit" width={150} />
          </DataGridLayout>
          <br />
        </ShowSection>
      </List>
    </>
  );
};

export default CategoriesAndObjectives;
