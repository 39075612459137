import React, { useState } from 'react';
import PreviousPayrollGenerationModal from './PreviousPayrollGenerationModal';
import useFeatureToggle, { Features } from '../../../featuretoggle';
import { Filter } from '../../../shared/uibuilder/list/filter/FilterContext';

interface ModalState {
  isOpened: boolean;
  data: {
    generationDate: string;
    isChanged: boolean;
  };
  onCloseCallback: (res: boolean) => any;
}

const initialState = {
  data: {
    generationDate: '',
    isChanged: false,
  },
  isOpened: false,
  onCloseCallback: () => {},
};

const usePayrollDataChangesHandler = (
  getReport: (filters: any) => Promise<any>,
  getLatestGeneration: (filters: any) => Promise<any>,
) => {
  const { isFeatureEnabled } = useFeatureToggle();
  const isNewPayrollEnabled = isFeatureEnabled(Features.PAYROLL);

  const [modalState, setModalState] = useState<ModalState>(initialState);

  const closeModal = (res: boolean) => {
    modalState.onCloseCallback(res);
    setModalState(initialState);
  };
  const openModal = (data: any, onCloseCallback: (res: boolean) => any) => {
    setModalState({
      ...initialState,
      isOpened: true,
      onCloseCallback,
      data,
    });
  };

  const modal = (
    <PreviousPayrollGenerationModal
      isOpened={modalState.isOpened}
      isChanged={modalState.data?.isChanged}
      close={closeModal}
      generationDate={modalState.data?.generationDate}
    />
  );

  const shouldOpenModal = (changeState: any) => {
    return changeState.exists && !changeState.changedSince;
  };
  const getDataMethod = async (filters: Filter): Promise<Response> => {
    if (isNewPayrollEnabled) {
      return new Promise(resolve => {
        getReport(filters).then(r => resolve(r));
      });
    }

    const changeState = await getLatestGeneration(filters);

    return new Promise(resolve => {
      if (shouldOpenModal(changeState)) {
        const modalData = {
          generationDate: changeState.generatedAt,
          isChanged: changeState.changedSince,
        };
        openModal(modalData, res => (res ? getReport(filters).then(r => resolve(r)) : resolve(null!)));
      } else {
        getReport(filters).then(r => resolve(r));
      }
    });
  };

  return {
    getDataMethod,
    modal,
  };
};

export default usePayrollDataChangesHandler;
