/* istanbul ignore file */
import React from 'react';
import RelationInput from 'erp/employee/shared/input/RelationInput';
import ChildRelationInput from 'erp/employee/shared/input/ChildrenInput/ChildRelationInput';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';

const ChildInput = (props: DefaultInputPropTypes<any>) => {
  return <RelationInput relationInput={ChildRelationInput} {...props} />;
};

export default ChildInput;
