/* istanbul ignore file */
import { func } from 'prop-types';
import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdateTimelineItemLayout from 'shared/uibuilder/timeline/layout/form/UpdateTimelineItemLayout';
import useResumeService from 'erp/candidate/Resume/shared/resumeService';
import ResumeForm from 'erp/candidate/Timeline/resume/form/ResumeForm';
import { UpdateFormProps } from 'shared/uibuilder/form/UpdateForm';

interface UpdateResumeFormProps extends Omit<UpdateFormProps, 'submitFormFunc'> {
  service: () => any;
  onCancel: () => void;
}

const UpdateResumeForm = ({ getDataFunc, onCancel, ...props }: UpdateResumeFormProps) => {
  const { getValidationSchema, update } = useResumeService();

  return (
    <UpdateForm
      getDataFunc={getDataFunc}
      submitFormFunc={update}
      getValidationSchemaFunc={getValidationSchema}
      {...props}
    >
      <UpdateTimelineItemLayout onCancel={onCancel}>
        <ResumeForm />
      </UpdateTimelineItemLayout>
    </UpdateForm>
  );
};

UpdateResumeForm.propTypes = {
  getDataFunc: func.isRequired,
  onCancel: func.isRequired,
};

export default UpdateResumeForm;
