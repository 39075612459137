/* istanbul ignore file */
import React from 'react';
import FieldListLayout, { FieldListLayoutProps } from 'uibuilder/layout/field/FieldListLayout';

const BalancesLeavesRowColumnLayout = ({ children, ...props }: FieldListLayoutProps) => {
  return (
    <div className="balances-leaves-column">
      <br />
      <FieldListLayout {...props}>{children}</FieldListLayout>
    </div>
  );
};

export default BalancesLeavesRowColumnLayout;
