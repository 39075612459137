import React, { useState } from 'react';
import Button, { ButtonProps } from 'uibuilder/button/Button';
import useAuthorization from 'shared/authorization/authorizationService';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import useSubmit, { SubmitProps } from 'shared/useSubmit';
import { Breakpoint } from '@mui/system';
import Loading from 'shared/uibuilder/Loading';

export interface ButtonWithConfirmationProps extends ButtonProps, SubmitProps {
  displayMessage: string | any[];
  permissionToCheck?: Nullable<string | string[]>;
  children?: React.ReactNode;
  modalConfirmButtonClassName?: string;
  modalConfirmButtonText?: React.ReactNode;
  onToggleCallback?: () => void;
  modalChildren?: React.ReactElement | React.ReactElement[];
  confirmBtnProps?: any;
  modalSize?: Breakpoint | false;
  closeOnSubmit?: boolean;
  disabledInput?: boolean;
}

const ButtonWithConfirmation = ({
  submitMethod,
  afterSubmit = {},
  children,
  displayMessage,
  permissionToCheck,
  modalConfirmButtonClassName,
  modalConfirmButtonText,
  color = 'primary',
  buttonTag: ButtonTag = Button,
  onToggleCallback = () => {},
  modalChildren,
  confirmBtnProps,
  modalSize,
  closeOnSubmit = true,
  ...props
}: ButtonWithConfirmationProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { isGranted } = useAuthorization();

  const { onSubmit, isLoading } = useSubmit({
    submitMethod,
    afterSubmit,
  });

  const confirmBtn = (
    <Button
      key="modal-confirm-button"
      className={modalConfirmButtonClassName}
      onClick={async () => {
        if (closeOnSubmit) {
          onSubmit();
        } else {
          await onSubmit();
        }

        setModalOpen(false);
      }}
      {...(confirmBtnProps || {})}
    >
      {modalConfirmButtonText || children}
    </Button>
  );

  return !permissionToCheck || isGranted(permissionToCheck) ? (
    <>
      <ButtonTag {...props} color={color} onClick={() => setModalOpen(true)}>
        {children}
      </ButtonTag>
      <ModalWindow
        isOpen={modalOpen}
        title="Are you sure?"
        buttons={[confirmBtn]}
        onToggle={() => {
          if (closeOnSubmit || !isLoading) {
            setModalOpen(false);
            onToggleCallback();
          }
        }}
        modalSize={modalSize}
      >
        <>
          <span>{displayMessage}</span>
          {modalChildren}

          {!closeOnSubmit && isLoading && <Loading hasBackground />}
        </>
      </ModalWindow>
    </>
  ) : null;
};

export default ButtonWithConfirmation;
