import React, { useMemo } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { HIRING_STATUS, RequestPosition } from 'erp/recruitment/hiring/useHiringService';
import { useDateService } from 'shared/uibuilder/dateService';
import moment from 'moment';
import Badge from 'shared/uibuilder/badge/Badge';
import HiringStatusField from 'erp/recruitment/hiring/shared/fields/HiringStatusField';

type CustomPositionStatusFieldProps = {
  value?: any;
  source: string;
  sourceStatus: string;
  label: string;
  width?: number;
};

const CustomPositionStatusField = ({
  value: inputValue,
  source,
  sourceStatus,
  ...props
}: CustomPositionStatusFieldProps) => {
  const { getValue } = useFieldHelper<RequestPosition[]>({ source });
  const { getValue: getValueStatus } = useFieldHelper<string>({ source: sourceStatus });
  const { getToday } = useDateService();
  const currentDate = getToday();

  const positions = useMemo(() => getValue() || [], [getValue]);
  const status = useMemo(() => getValueStatus() || '', [getValueStatus]);

  let resultColor: BasicColors = 'neutralLight';
  let resultValue;

  if (status === HIRING_STATUS.OPEN) {
    if (positions.some(item => !item.forecastDate)) {
      resultValue = 'No forecast';
      resultColor = 'error';
    } else if (
      positions.some(item => item.positionStatus === 'OPEN' && !moment(item.forecastDate).isAfter(currentDate))
    ) {
      resultValue = 'Delayed';
      resultColor = 'error';
    }
  }

  return [
    <HiringStatusField source={sourceStatus} label="Status" />,
    resultValue ? (
      <Badge sx={{ marginLeft: '0' }} color={resultColor} {...props}>
        {resultValue}
      </Badge>
    ) : (
      <></>
    ),
  ];
};

export default CustomPositionStatusField;
