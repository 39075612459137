import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import useHiringService, {
  HIRING_MODE,
  HIRING_REQUEST_CANCEL,
  HIRING_REQUEST_UPDATE,
  HIRING_STATUS_OPTIONS,
} from 'erp/recruitment/hiring/useHiringService';
import { useHiringId } from '../HiringRouter';
import SingleHiringRequestBreadcrumbs from '../shared/SingleHiringRequestBreadcrumbs';
import BigSectionRow from 'shared/uibuilder/layout/BigSectionRow';
import PositionsListFields from '../shared/fields/PositionsListFields';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import TextField from 'shared/uibuilder/field/TextField';
import EnumField from 'shared/uibuilder/field/EnumField';
import { COMPETENCY_LEVEL } from 'erp/recruitment/vacancy/shared/vacancyService';
import DateField from 'shared/uibuilder/field/DateField';
import EntityHeader from 'shared/layout/EntityHeader';
import VacanciesFiled from '../shared/fields/VacanciesFiled';
import ListField from 'shared/uibuilder/field/ListField';
import EditHiringButton from '../shared/button/EditHiringButton';
import CancelHiringButton from 'erp/recruitment/hiring/shared/button/CancelHiringButton';

const ViewHiringRequest = () => {
  const id = useHiringId();
  const { getById } = useHiringService();

  return (
    <Show getDataMethod={() => getById(id)}>
      <ShowPageLayout
        entityHeader={<EntityHeader entityTitleSource="specialisation" />}
        breadcrumbs={<SingleHiringRequestBreadcrumbs />}
        hasSeparateSections
        headerButtons={[
          <EditHiringButton permissionToCheck={HIRING_REQUEST_UPDATE} />,
          <CancelHiringButton permissionToCheck={HIRING_REQUEST_CANCEL} />
        ]}
      >
        <ShowSection titleVariant="h3" title="Request information">
          <SectionRow>
            <EmployeeLinkFieldWithNameLoading source="createdBy" label="Author" />
            <TextField source="specialisation" label="Specialisation" />
            <EnumField options={COMPETENCY_LEVEL} source="level" label="Competency level" />
          </SectionRow>

          <SectionRow>
            <EmployeeLinkFieldWithNameLoading source="owner" label="DRI" />
            <DateField
              source="targetDate"
              label="Target date"
              isVisible={(data: any) => !data.getValueBySource('isAsap')}
            />
            <TextField
              value="ASAP"
              source="isAsap"
              label="Target date"
              isVisible={(data: any) => data.getValueBySource('isAsap')}
            />
            <DateField source="expirationDate" label="Expiration date" />
          </SectionRow>

          <SectionRow>
            <ListField source="vacancies" label="Vacancies">
              <VacanciesFiled />
            </ListField>
            <EnumField options={HIRING_STATUS_OPTIONS} source="status" label="Status" />
            <EnumField options={HIRING_MODE} source="hiringMode" label="Hiring Mode" />
          </SectionRow>
        </ShowSection>

        <ShowSection titleVariant="h3" title="Positions">
          <BigSectionRow>
            <PositionsListFields source="requestPositions" />
          </BigSectionRow>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewHiringRequest;
