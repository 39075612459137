import React, { useMemo } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import TextField from 'shared/uibuilder/field/TextField';
import { POSITION_STATUS, RequestPosition } from 'erp/recruitment/hiring/useHiringService';

type ClosedPositionsFieldProps = {
  label: string | React.ReactElement;
  source: string;
  width?: StringOrNumber;
};

const ClosedPositionsField = ({ source, ...props }: ClosedPositionsFieldProps) => {
  const { getValue } = useFieldHelper<RequestPosition[]>({ source });

  const positions = useMemo(() => getValue() || [], [getValue]);

  const value = useMemo(() => {
    const closedPositions =
      positions.filter((position: RequestPosition) => position.positionStatus === POSITION_STATUS.CLOSED).length || 0;

    return `${closedPositions} / ${positions.length}`;
  }, [positions]);

  return <TextField {...props} value={value} />;
};

export default ClosedPositionsField;
