import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useAuthorization from 'shared/authorization/authorizationService';
import { ActivityType } from 'crm/shared/timeline/activity/crmActivityService';
import React from 'react';

export const TimelineType: any = {
  ...ActivityType,
  NOTE: 'Note',
  FEEDBACK: 'Feedback',
  RESUME: 'Resume',
};

const useTimelineLayoutHelper = () => {
  const { data } = useShowContext();
  const createdById = data.getValueBySource('createdById');
  const isInstantFeedback = data.getValueBySource('isInstantFeedback');
  const isProjectStatus = data.getValueBySource('projectStatusType');
  const isScrappedEmailActivity =
    data.getValueBySource('activityType') === 'EMAIL' && data.getValueBySource('internetMessageId') != null;
  const { isGranted } = useAuthorization();

  const canUpdateServiceNote = isGranted('COMPANY_UPDATE_SERVICE_NOTES');
  const getButtons = (buttons: React.ReactElement[]) => {
    return (isScrappedEmailActivity ||
      !!isProjectStatus ||
      (createdById === null && canUpdateServiceNote) ||
      createdById != null) &&
      !isInstantFeedback
      ? buttons.filter(button => {
          const { permissionToCheck } = button.props;
          return !permissionToCheck || isGranted(permissionToCheck);
        })
      : [];
  };

  return {
    getButtons,
  };
};

export default useTimelineLayoutHelper;
