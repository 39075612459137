import React, { useEffect, useState } from 'react';
import { DefaultDropdownProps, Option } from 'shared/uibuilder/form/input/dropdownHelper';
import usePositionService from 'erp/position/positionService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { flushSync } from 'react-dom';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';

type SpecializationDropdownProps = {
  mapResults?: (location: any) => any;
  filterOptions?: (location: any) => boolean;
} & DefaultDropdownProps;

const SpecializationDropdown = ({ ...props }: SpecializationDropdownProps) => {
  const [options, setOptions] = useState<Option[]>([]);
  const { findAll: findAllPositions } = usePositionService();

  const { getRawOnChangeCallback } = useInputHelper(props);
  const rawOnChangeCallback = getRawOnChangeCallback();

  const getSpecializationOptions = async () => {
    const positions = await findAllPositions();

    return positions.reduce((specializations: [], position: Dictionary<string>) => {
      return [...specializations, { value: position.vacancyAlias, label: position.name }];
    }, []);
  };

  useEffect(() => {
    const loadSpecialization = async () => {
      const positions = await getSpecializationOptions();
      setOptions(positions);
    };

    loadSpecialization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (newOption: { value: string; label: string }) => {
    if (rawOnChangeCallback) {
      rawOnChangeCallback({
        [props.source]: newOption.value,
      });
    }
  };

  const handleCreate = (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue };

    const newOptions = [...options, newOption];
    setOptions(newOptions);

    flushSync(() => handleChange(newOption));
  };

  return (
    <Dropdown
      {...props}
      placeholder="Select Specialization"
      isCreatable
      onCreateOption={handleCreate}
      formatCreateLabel={(inputValue: string) => `Create new specialization "${inputValue}"`}
      options={options}
    />
  );
};

export default SpecializationDropdown;
