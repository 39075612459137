import { useKernelApi } from 'api/index';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';
import { RESOURCE_URL as EMPLOYEES_RESOURCE_URL } from 'erp/employee/employeeService';
import useCrudService from 'shared/crud';
import useBaseCrudService from 'shared/crud/baseCrudService';

export const CREATE_EMPLOYEE_SKILLS = 'CREATE_EMPLOYEE_SKILLS';
export const DELETE_EMPLOYEE_SKILLS = 'DELETE_EMPLOYEE_SKILL';
export const READ_EMPLOYEE_SKILLS = 'READ_EMPLOYEE_SKILLS_LIST';

export const RESOURCE_URL = '/skills';

const useEmployeeSkillsService = () => {
  const { sendPostRequest } = useKernelApi();
  const employeeId = useEmployeeId();
  const listResourceUrl = `${EMPLOYEES_RESOURCE_URL}/${employeeId}${RESOURCE_URL}`;

  const { ...baseSkillsMethods } = useCrudService({
    singleResourceUrl: `${EMPLOYEES_RESOURCE_URL}${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const { create } = useCrudService({
    singleResourceUrl: `${listResourceUrl}`,
    listResourceUrl,
    apiService: useKernelApi,
  });

  const search = async (request: any) => {
    const url = `${listResourceUrl}/search`;

    const response = await sendPostRequest(url, {
      pageSize: `${encodeURIComponent(request.pageSize)}`,
      pageNumber: `${encodeURIComponent(request.pageNumber)}`,
      sort: request.sort,
    });

    return response.json();
  };

  const searchExistingSkillsForEmployee = async (request: any) => {
    const url = `${EMPLOYEES_RESOURCE_URL}/${employeeId}/dictionary/skills/search`;

    const response = await sendPostRequest(url, {
      filter: {
        isManagedByProcess: {
          eq: true,
        },
      },
      pageSize: `${encodeURIComponent(request.pageSize)}`,
      pageNumber: `${encodeURIComponent(request.pageNumber)}`,
      sort: request.sort,
    });

    return response.json();
  };

  const { search: findForEmployee } = useBaseCrudService({
    listResourceUrl: `${EMPLOYEES_RESOURCE_URL}/${employeeId}/dictionary/skills`,
    singleResourceUrl: '',
    apiService: useKernelApi,
  });

  const searchByNameForEmployee = async (query: any) => {
    const data = await findForEmployee({
      pageNumber: 0,
      pageSize: 30,
      filter: {
        name: {
          ct: query,
        },
        isManagedByProcess: {
          eq: true,
        },
      },
    });

    return data.result;
  };

  return {
    ...baseSkillsMethods,
    search,
    create,
    searchByNameForEmployee,
    searchExistingSkillsForEmployee,
  };
};

export default useEmployeeSkillsService;
