/* istanbul ignore file */
import { CREATE_EMPLOYEE_FEEDBACK } from 'erp/employee/Timeline/feedback/employeeFeedbackService';
import FeedbackRecordLayout from './feedbackList/FeedbackRecordLayout';

const useFeedbackAboutMeRegistry = () => ({
  isApplicable: (item: { feedback: any }) => item.feedback,
  source: 'feedback',
  urlPostfix: 'feedbacks',
  RecordLayout: FeedbackRecordLayout,
  name: 'Feedback',
  permissionToCheck: CREATE_EMPLOYEE_FEEDBACK,
});

export default useFeedbackAboutMeRegistry;
