/* istanbul ignore file */
import React from 'react';
import EnumField from 'shared/uibuilder/field/EnumField';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { OPPORTUNITY_STAGES } from '../opportunityService';
import { FieldProps } from '../../../../shared/uibuilder/field';

const OpportunityStageField = ({ value: inputValue = null, source = null, label = null }: FieldProps) => {
  const { getValue } = useFieldHelper({ value: inputValue, source });

  const value = inputValue || (getValue && getValue());

  return <EnumField label={label} value={value} options={OPPORTUNITY_STAGES} />;
};

export default OpportunityStageField;
