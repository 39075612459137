/* istanbul ignore file */
import React from 'react';
import OneStopButtons from 'uibuilder/layout/form/OneStopButtonsLayout';
import './OneStopPageLayout.scss';

type OneStopPageLayoutProps = {
  children: React.ReactElement[] | React.ReactElement;
};

const OneStopPageLayout = ({ children }: OneStopPageLayoutProps) => {
  return (
    <>
      <div className="position-relative one-stop-page">{children}</div>
      <OneStopButtons />
    </>
  );
};

export default OneStopPageLayout;
