/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import Grid from 'uibuilder/Grid';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import ServiceCatalogHeader from 'erp/serviceCatalog/shared/ServiceCatalogHeader';
import ArchiveServiceCatalogButton from 'erp/serviceCatalog/shared/button/ArchiveServiceCatalogButton';
import AssignServiceCatalogOwnerButton from 'erp/serviceCatalog/shared/button/AssignServiceCatalogOwnerButton';
import SingleServiceCatalogBreadcrumbs from 'erp/serviceCatalog/shared/SingleServiceCatalogBreadcrumbs';
import ViewServiceCatalogPageMenu from 'erp/serviceCatalog/Show/ViewServiceCatalogPageMenu';
import ServiceHealthField from 'erp/serviceCatalog/shared/field/ServiceHealthField';
import useFeatureToggle, { Features } from 'featuretoggle';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import TextField from 'shared/uibuilder/field/TextField';
import useBudgetVersionService, {
  BUDGET_OBJECTIVE_UNIT_OPTIONS,
} from 'erp/serviceCatalog/budgets/budgetVersionService';
import { useServiceCatalogId } from 'erp/serviceCatalog/ServiceCatalogRouter';
import LoadingDecorator from 'shared/uibuilder/LoadingDecorator';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import ActiveKpiStructure from '../kpi/Show/ActiveKpiStructure';

type ServiceCatalogBudgetProps = {
  activeBudget: null | {
    categories: { name: string; amount: number }[];
    objectives: { name: string; value: number; unit: string }[];
  };
};

const AmountField = ({ source, unitSource, label }: { source: string; label: string; unitSource?: string }) => {
  const { getValue: getAmount } = useFieldHelper<string>({ source });
  const { getValue: getUnit } = useFieldHelper<string>({ source: unitSource });
  return (
    <TextField label={label} value={`${getAmount()} ${unitSource ? BUDGET_OBJECTIVE_UNIT_OPTIONS[getUnit()] : '$'}`} />
  );
};

const ActiveCategoriesList = ({ activeBudget }: ServiceCatalogBudgetProps) => {
  return (
    <ControlledList
      data={{
        items: activeBudget!.categories,
        totalPages: 1,
      }}
    >
      <DataGridLayout>
        <TextField label="Category" source="name" width={200} />
        <AmountField label="Amount" source="amount" />
      </DataGridLayout>
    </ControlledList>
  );
};

const ActiveObjectivesList = ({ activeBudget }: ServiceCatalogBudgetProps) => {
  return (
    <ControlledList
      data={{
        items: activeBudget!.objectives,
        totalPages: 1,
      }}
    >
      <DataGridLayout>
        <TextField label="Objective" source="name" width={200} />
        <AmountField label="Value" source="value" unitSource="unit" />
      </DataGridLayout>
    </ControlledList>
  );
};

const ViewServiceCatalogPageContent = () => {
  const serviceId = useServiceCatalogId();
  const { isFeatureEnabled } = useFeatureToggle();
  const { searchActiveForService } = useBudgetVersionService();

  const [activeBudget, setActiveBudget] = useState(null);
  const [activeBudgetLoading, setActiveBudgetLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const searchResult = await searchActiveForService(serviceId);
      if (searchResult.result.length !== 0) {
        setActiveBudget(searchResult.result[0]);
      }
      setActiveBudgetLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ShowPageLayout
      entityHeader={
        <>
          <ServiceCatalogHeader />
          <EmployeeLinkFieldWithNameLoading source="owner" />
        </>
      }
      breadcrumbs={<SingleServiceCatalogBreadcrumbs />}
      headerButtons={[
        <AssignServiceCatalogOwnerButton statusSource="status" />,
        <ArchiveServiceCatalogButton statusSource="status" />,
      ]}
      menu={<ViewServiceCatalogPageMenu />}
    >
      <LoadingDecorator loading={activeBudgetLoading} deps={[activeBudgetLoading]}>
        <ShowSection isVisible={activeBudget !== null} title="Active budget">
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={5.5}>
              <ActiveCategoriesList activeBudget={activeBudget} />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <ActiveObjectivesList activeBudget={activeBudget} />
            </Grid>
          </Grid>
        </ShowSection>
      </LoadingDecorator>
      {isFeatureEnabled(Features.SERVICE_HEALTH) ? (
        <ShowSection title="Service Health">
          <ServiceHealthField label="Issues" source="currentHealth" />
        </ShowSection>
      ) : (
        <></>
      )}
      <ShowSection title="Active Kpi Structure">
        <ActiveKpiStructure />
      </ShowSection>
    </ShowPageLayout>
  );
};

export default ViewServiceCatalogPageContent;
