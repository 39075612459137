import React from 'react';
import { get } from 'lodash';
import ApplyCompetencyButton from 'erp/employee/qualification/List/button/ApplyCompetencyButton';
import useEmployeeQualificationService, {
  EmployeeCompetency,
  CompetencyStatus,
} from 'erp/employee/qualification/shared/employeeQualificationService';
import { useQualificationId } from 'erp/employee/qualification/QualificationRouter';
import { useEmployeeId } from 'erp/employee/EmployeesRouter';

const errorMessage = "Can't apply all competencies";

const getCompetenciesIdsToApply = (competencies: EmployeeCompetency[]) =>
  competencies.reduce((result: StringOrNumber[], competency) => {
    const { id } = competency;

    const appliedStatus = get(competency, 'appliedState.status', CompetencyStatus.NOT_CONFIRMED);
    const currentStatus = get(competency, 'rootState.status', CompetencyStatus.NOT_CONFIRMED);

    if (appliedStatus === currentStatus) {
      return result;
    } else {
      return [...result, id];
    }
  }, []);

const ApplyAllCompetenciesButton = ({
  totalWeight,
  qualificationId,
  competencies,
  permissionToCheck,
  isVisible,
}: {
  qualificationId: Nullable<StringOrNumber>;
  totalWeight?: number;
  competencies: EmployeeCompetency[];
  permissionToCheck?: string;
  isVisible?: boolean;
}) => {
  const employeeId = useEmployeeId() || '';
  const specializationId = useQualificationId()!;
  const { applyCompetencies } = useEmployeeQualificationService();

  return (
    <>
      {isVisible && qualificationId ? (
        <ApplyCompetencyButton
          submitMethod={() =>
            applyCompetencies({
              employeeId,
              specializationId,
              qualificationId,
              competenciesIds: getCompetenciesIdsToApply(competencies),
            })
          }
          displayMessage="Do you really want to apply all competencies?"
          permissionToCheck={permissionToCheck}
          afterSubmit={{ errorMessage }}
        >
          Apply All{totalWeight ? ` (${totalWeight}%)` : ''}
        </ApplyCompetencyButton>
      ) : null}
    </>
  );
};

export default ApplyAllCompetenciesButton;
