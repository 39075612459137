/* istanbul ignore file */
export default {
  entityType: {
    type: 'enum',
    required: true,
  },
  leadId: {
    type: 'string',
    required: {
      value: true,
      when: {
        $entityType: {
          eq: 'LEAD',
        },
      },
    },
  },
  accountId: {
    type: 'string',
    required: {
      value: true,
      when: {
        $entityType: {
          eq: 'ACCOUNT',
        },
      },
    },
  },
  opportunityId: {
    type: 'string',
    required: {
      value: true,
      when: {
        $entityType: {
          eq: 'OPPORTUNITY',
        },
      },
    },
  },
};
