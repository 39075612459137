import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import './ProjectDashboardCyclePeriodField.scss';
import { ProjectDashboardCycleStatus } from '../../ProjectDashboardService';

interface ProjectDashboardCyclePeriodFieldProps {
  sourceDateFrom: string;
  sourceDateTo: string;
  sourceCycleStatus: string;
  [key: string]: any;
}

const ProjectDashboardCyclePeriodField = ({
  sourceDateFrom,
  sourceDateTo,
  sourceCycleStatus,
}: ProjectDashboardCyclePeriodFieldProps) => {
  const { formatDate } = useDateService();
  const { getValue: getDateFrom } = useFieldHelper({ source: sourceDateFrom });
  const { getValue: getDateTo } = useFieldHelper({ source: sourceDateTo });
  const { getValue: getCycleStatus } = useFieldHelper({ source: sourceCycleStatus });
  const value = `${formatDate(getDateFrom(), DATE_FORMAT.FULL_DATE)} - ${formatDate(
    getDateTo(),
    DATE_FORMAT.FULL_DATE,
  )}`;

  return <span className={getCycleStatus() === ProjectDashboardCycleStatus.FUTURE ? 'future-cycle' : ''}>{value}</span>;
};

export default ProjectDashboardCyclePeriodField;
