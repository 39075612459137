/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate, { SecurityContextCommonProps } from 'shared/authorization/SecurityContextTemplate';
import useLeadService from 'crm/lead/shared/leadService';

export const LEAD_CONTEXT = 'Lead';

const LeadContext = ({ permissionToCheck, children }: SecurityContextCommonProps) => {
  const { getPermissions } = useLeadService() as any;

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={LEAD_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default LeadContext;
