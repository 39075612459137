/* istanbul ignore file */
import React, { useState } from 'react';
import { EditButtonProps } from 'uibuilder/button/EditButton';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import SetVacancyManagerForm from 'erp/recruitment/vacancy/shared/input/SetVacancyManagerForm';
import useAuthorization from 'shared/authorization/authorizationService';

const EditVacancyManagerButton = ({ permissionToCheck, ...props }: EditButtonProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const { isGranted } = useAuthorization();

  if (permissionToCheck && !isGranted(permissionToCheck)) {
    return null;
  }

  return (
    <>
      <Button {...props} onClick={openModal}>
        Assign
      </Button>

      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        onToggle={closeModal}
        title="Set vacancy manager"
        hasCancelButton
      >
        <SetVacancyManagerForm closeModal={closeModal} modalOpen={modalOpen} />
      </ModalWindow>
    </>
  );
};

export default EditVacancyManagerButton;
