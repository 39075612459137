/* istanbul ignore file */
// libs
import React from 'react';
// components
import LoadingFilePreviewLayout from 'artifact/shared/input/layout/preview/LoadingFilePreviewLayout';

const UploadingQueueLayout = ({
  loadingArtifactObjects,
  onRemove,
}: {
  loadingArtifactObjects: any[];
  onRemove: (loadingArtifactObject: any) => void;
}) => {
  return loadingArtifactObjects.map(loadingArtifactObject => (
    <LoadingFilePreviewLayout
      key={loadingArtifactObject.id}
      loadingArtifactObject={loadingArtifactObject}
      onRemove={() => onRemove(loadingArtifactObject)}
    />
  ));
};

export default UploadingQueueLayout;
