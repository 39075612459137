/* istanbul ignore file */
import React from 'react';
import { FieldProps, LinkField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';

const OpportunityLinkField = ({ idValue = '', idSource = undefined, ...props }: FieldProps) => {
  const { getSingleEntityUrl } = useOpportunityUrl();
  const { getValue: getId } = useFieldHelper({ source: idSource, value: idValue });
  const { getValue: getName } = useFieldHelper(props);

  const id = getId();

  return <LinkField {...props} isInternal linkText={getName()} value={getSingleEntityUrl(id)} />;
};

export default OpportunityLinkField;
