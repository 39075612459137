/* istanbul ignore file */
import React from 'react';
import EditButton, { EditButtonProps } from 'uibuilder/button/EditButton';
import { useOpportunityId, useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';

const EditOpportunityButton = (props: EditButtonProps) => {
  const paramId = useOpportunityId();
  const { getUpdateEntityUrl } = useOpportunityUrl();

  return <EditButton path={(id = paramId) => getUpdateEntityUrl(id)} {...props} />;
};

export default EditOpportunityButton;
