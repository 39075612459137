/* istanbul ignore file */
import React from 'react';
import FormTemplate, { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';

const CreateForm = ({ children, initialData = {}, ...props }: FormTemplateProps) => {
  return (
    <FormTemplate initialData={initialData} {...props}>
      {children}
    </FormTemplate>
  );
};

export default CreateForm;
