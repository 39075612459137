/* istanbul ignore file */
// libs
import React from 'react';
import { useParams } from 'react-router-dom';
// layout
import Show from 'shared/uibuilder/show/Show';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import ViewLeavePageMenu from 'erp/leave/Show/ViewLeavePageMenu';
import LeaveEntityHeader from 'erp/leave/shared/LeaveEntityHeader';
import SingleLeavesBreadcrumbs from 'erp/leave/shared/SingleLeavesBreadcrumbs';
// buttons
import EditLeaveButton from 'erp/leave/shared/button/EditLeaveButton';
import DeleteLeaveButton from 'erp/leave/shared/button/DeleteLeaveButton';
import RejectLeaveButton from 'erp/leave/shared/button/RejectLeaveButton';
import AddLeaveCompensationButton, {
  isLeaveCompensationButtonVisible,
} from 'financialAnalytic/expenses/shared/button/AddLeaveCompensationButton';
// fields
import DateField from 'shared/uibuilder/field/DateField';
import LeaveTypeField from 'erp/leave/shared/field/LeaveTypeField';
import LeaveStatusField from 'erp/leave/shared/field/LeaveStatusField';
import LeavePayoutOptionField from 'erp/leave/shared/field/LeavePayoutOptionField';
import { EmployeeLinkField } from 'erp/employee';
import LeaveDurationField from 'erp/leave/shared/field/LeaveDurationField';

import useLeaveService, {
  EXTEND_LEAVE,
  LEAVE_TYPES_ALIASES,
  READ_LEAVE_REQUEST_INFORMATION,
  UPDATE_LEAVE,
  READ_FULL_LEAVE_DATA,
  hasSignatureOption,
  IS_PHYSICAL_OPTION_ALIASES,
} from 'erp/leave/shared/leaveService';
import RulesViolationReasonField from 'erp/leave/shared/field/RulesViolationReasonField';
import { TextField } from 'shared/uibuilder/field';
import BusinessRulesViolatedField from 'erp/leave/shared/input/BusinessRulesViolatedField';
import BusinessRulesListField from 'erp/leave/shared/field/BusinessRulesListField';
import AttachmentsListField from '../../../artifact/shared/field/AttachmentsListField';
import useAuthorization from 'shared/authorization/authorizationService';
import SickDayReasonField from '../shared/field/SickDayReasonField';
import SignatureOptionField from '../shared/field/SignatureOptionField';
import ElectronicOptionAgreementField from '../shared/field/ElectronicOptionAgreementField';
import useLeavesErrorMap from '../createupdate/errorHandlers';

const ViewLeave = () => {
  const { id } = useParams<Dictionary<string>>();
  const { getByIdWithExpensesInfo, canBeExtended } = useLeaveService();
  const { isGranted } = useAuthorization();

  return (
    <Show getDataMethod={() => getByIdWithExpensesInfo(id!)}>
      <ShowPageLayout
        buttons={[
          <EditLeaveButton
            key="edit-leave"
            source="id"
            icon={<i className="fa fa-edit" />}
            isVisible={leave => isGranted(UPDATE_LEAVE) || (isGranted(EXTEND_LEAVE) && canBeExtended(leave))}
            permissionToCheck={null}
          />,
          <RejectLeaveButton key="reject-leave" />,
          <DeleteLeaveButton key="delete-leave" />,
        ]}
        menu={<ViewLeavePageMenu />}
        breadcrumbs={<SingleLeavesBreadcrumbs />}
        entityHeader={<LeaveEntityHeader />}
        headerButtons={[<AddLeaveCompensationButton isVisible={isLeaveCompensationButtonVisible} />]}
      >
        <ShowSection title="Leave Information">
          <SectionRow>
            <EmployeeLinkField label="Employee" nameSource="employeeName" source="employeeId" />
            <LeaveTypeField source="type" label="Type" />
            <LeaveStatusField source="status" label="Status" />
            <LeavePayoutOptionField
              source="payoutOption"
              label="Payout Option"
              isVisible={(leave: any) =>
                leave.getValueBySource('type') === LEAVE_TYPES_ALIASES.PAID_LEAVE && isGranted(READ_FULL_LEAVE_DATA)
              }
            />
            <SickDayReasonField
              source="sickDayReason"
              label="Sick Day Reason"
              isVisible={(leave: any) =>
                leave.getValueBySource('type') === LEAVE_TYPES_ALIASES.SICK_DAY && isGranted(READ_FULL_LEAVE_DATA)
              }
            />
          </SectionRow>
          <SectionRow>
            <DateField source="startDate" />
            <DateField source="actualEndDate" label="End Date" />
            <LeaveDurationField source="duration" />
          </SectionRow>
          <SectionRow>
            <AttachmentsListField
              source="applicationScansIds"
              label="Application Scans"
              isVisible={() => isGranted(READ_FULL_LEAVE_DATA)}
            />
          </SectionRow>
        </ShowSection>
        <ShowSection title="Leave Request Information" isVisible={isGranted(READ_LEAVE_REQUEST_INFORMATION)}>
          <SectionRow>
            <BusinessRulesViolatedField source="violatedBusinessRules" label="Business Rules Violated" />
            <BusinessRulesListField
              source="violatedBusinessRules"
              label="Violated Business Rules"
              isVisible={(leave: any) =>
                !!leave.getValueBySource('violatedBusinessRules') &&
                !!leave.getValueBySource('violatedBusinessRules').length
              }
              errorMap={useLeavesErrorMap()}
            />
            <RulesViolationReasonField source="rulesViolationReason" label="Reasons for Business Rules Violation" />
            <TextField source="comment" label="Description" />
          </SectionRow>
        </ShowSection>
        <ShowSection
          title="Signature option"
          isVisible={leave => hasSignatureOption(leave) && isGranted(READ_FULL_LEAVE_DATA)}
        >
          <SectionRow>
            <SignatureOptionField
              source="signatureOption"
              label="Employee will be able to arrive at the office for signing documents"
            />
            <TextField
              source="electronicOptionReason"
              label="Reason why employee will not be able to arrive at the office"
              isVisible={(data: any) =>
                data.getValueBySource('signatureOption') === IS_PHYSICAL_OPTION_ALIASES.ELECTRONIC
              }
            />
            <ElectronicOptionAgreementField
              source="electronicOptionAgreement"
              label="Employee confirms that the company can use a comment in the dedicated Redmine ticket as a replacement for their physical signature in leave documents"
              isVisible={(data: any) =>
                data.getValueBySource('signatureOption') === IS_PHYSICAL_OPTION_ALIASES.ELECTRONIC
              }
            />
          </SectionRow>
        </ShowSection>
      </ShowPageLayout>
    </Show>
  );
};

export default ViewLeave;
