/* istanbul ignore file */
import React, { useEffect, useMemo, useState } from 'react';
import { CONTACT_SUPPORT, SUPPORT_REQUEST_ID } from 'oneStop/OneStopRouterHelper';
import useAuthenticatedUserService, { UserName } from 'authentication/authenticatedUserService';
import FeedbackButton from 'oneStop/feedbackModal/FeedbackButton';
import useFeatureToggle, { Features } from 'featuretoggle';
import InstantFeedbackButton from 'instantFeedback/InstantFeedbackButton';
import Box from 'uibuilder/Box';
import HeaderMenuItems from 'shared/uibuilder/menu/HeaderMenu/HeaderMenuItems';
import CurrentUserActions from 'layout/Default/DefaultHeader/CurrentUserActions';
import CopyTokenButton from 'authentication/CopyTokenButton';
import LeadsNotificationHeaderButton from 'crm/lead/shared/LeadsNotificationHeaderButton/LeadsNotificationHeaderButton';
import useAuthorization from 'shared/authorization/authorizationService';
import { READ_LEAD_NOTIFICATION_FE } from 'crm/lead/shared/leadService';
import SupportRequestModalDecorator from 'oneStop/feedbackModal/SupportRequestModalDecorator';

const DefaultHeader = () => {
  const { isFeatureEnabled } = useFeatureToggle();
  const [userName, setUserName] = useState<Nullable<UserName>>(null);
  const { getUserName } = useAuthenticatedUserService();
  const { isGranted } = useAuthorization();

  const isShowLeadNotification = useMemo(() => isGranted(READ_LEAD_NOTIFICATION_FE), [isGranted]);

  useEffect(() => {
    (async () => {
      const name = await getUserName();
      setUserName(name);
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userName === null ? null : (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          '.instance-feedback-button': { marginRight: { xs: '5px', sm: '12px' } },
        }}
      >
        <Box sx={{ width: { md: `calc(100% - ${isShowLeadNotification ? '550' : '470'}px)` }, marginRight: 'auto' }}>
          <HeaderMenuItems />
        </Box>
        {isShowLeadNotification && <LeadsNotificationHeaderButton />}
        {isFeatureEnabled(Features.INSTANT_FEEDBACK) && <InstantFeedbackButton />}
        {isFeatureEnabled(Features.SUPPORT_REQUEST_SELECTING) ? (
          <SupportRequestModalDecorator />
        ) : (
          <FeedbackButton formId={SUPPORT_REQUEST_ID} formTitle={CONTACT_SUPPORT} />
        )}
        <CopyTokenButton />
        <CurrentUserActions userName={userName} />
      </Box>
    </>
  );
};

export default DefaultHeader;
