// @ts-nocheck
import { useEffect, useState } from 'react';

import { singletonHook } from 'react-singleton-hook';
import { UUID_REGEX } from './artifactService';
import useArtifactThumbnailService from 'artifact/artifactThumbnailService';

const initState = { hookLoading: true };

const WAITING_TIMEOUT = 100;

/**
 * Singleton hook that collects artifact data.
 *
 * It doesn't send a request immediately and wait some
 * time and collect artifacts ids from multiple
 * components.
 */
const useInvoiceArtifactsInfoReceiverImpl = () => {
  const { getInvoiceArtifactsInfo } = useArtifactThumbnailService();

  const [promisesTimeout, setPromisesTimeout] = useState(false);
  const [promises, setPromises] = useState([]);
  const [artifacts, setArtifacts] = useState([]);
  const [invoiceIds, setInvoiceIds] = useState([]);

  useEffect(() => {
    if (promisesTimeout) clearTimeout(promisesTimeout);

    if (promises.length && artifacts.length && invoiceIds.length) {
      const newTimeout = setTimeout(async () => {
        const uniqueInvoiceIds = [...new Set(invoiceIds)];

        const info = await getInvoiceArtifactsInfo(uniqueInvoiceIds.join(','));
        promises.forEach(p => p.resolve(info));

        setPromisesTimeout(false);
        setArtifacts([]);
        setPromises([]);
        setInvoiceIds([]);
      }, WAITING_TIMEOUT);
      setPromisesTimeout(newTimeout);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promises, artifacts, invoiceIds]);

  const collectInvoiceArtifactsData = async (invoiceId, initialAttachmentsIds) => {
    const attachmentsIds = initialAttachmentsIds.filter(id => id && id !== 'undefined' && UUID_REGEX.test(id));

    if (!attachmentsIds || !attachmentsIds.length) {
      return Promise.resolve({});
    }

    const promise = new Promise((resolve, reject) => setPromises(old => [...old, { resolve, reject }]));

    setArtifacts(old => [...old, ...attachmentsIds]);
    setInvoiceIds(old => [...old, ...[invoiceId]]);

    const missedArtifactsInfo = await promise;

    const missedArtifacts = missedArtifactsInfo;

    return missedArtifacts;
  };

  return {
    collectInvoiceArtifactsData,
  };
};

const useInvoiceArtifactsInfoReceiver = singletonHook(initState, useInvoiceArtifactsInfoReceiverImpl);

export default useInvoiceArtifactsInfoReceiver;
