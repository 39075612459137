import React from 'react';
import { Link } from 'react-router-dom';
import { useEmployeeUrl } from 'erp/employee/EmployeesRouter';

const EmployeeLink = ({ id, name }: { id?: Nullable<string>; name: string | React.ReactNode }) => {
  const { getSingleEntityUrl } = useEmployeeUrl();

  return (
    <>
      {id ? (
        <Link
          to={getSingleEntityUrl(id)}
          target="_blank"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {name}
        </Link>
      ) : (
        <span>{name}</span>
      )}
    </>
  );
};

export default EmployeeLink;
