import { useFormContext } from 'shared/uibuilder/form/FormContext';
import React, { useEffect, useState } from 'react';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { Button } from 'uibuilder/button';
import useErrorMapping from 'shared/uibuilder/form/errorsMapper';
import BusinessRulesListField from 'erp/leave/shared/field/BusinessRulesListField';
import SubmitButton from 'uibuilder/button/SubmitButton';

const NotStrictRulesHandler = (props: { nonStrictRules: any; title: any; errorMap: any; children: any }) => {
  const { nonStrictRules, title, errorMap, children } = props;

  const areOnlyNotStrictRulesViolated = (allErrorCodes: any[]) => {
    return allErrorCodes.length && allErrorCodes.every(code => nonStrictRules.includes(code));
  };

  const { addValidationErrorListener, removeValidationErrorListener, submitForm, onChangeCallback, isSubmitEnabled } =
    useFormContext();

  const { getErrorCode } = useErrorMapping();

  const [violatedRules, setViolatedRules] = useState([]);

  const closeModal = () => {
    setViolatedRules([]);
    if (onChangeCallback) {
      onChangeCallback(
        {
          ignoreNotStrictRules: false,
        },
        true,
      );
    }
  };

  const openModal = (rules: React.SetStateAction<never[]>) => {
    setViolatedRules(rules);

    if (onChangeCallback) {
      onChangeCallback(
        {
          ignoreNotStrictRules: true,
        },
        true,
      );
    }
  };

  const isModalOpened = () => {
    return !!violatedRules.length;
  };

  const handleError = (errors: any[]): void => {
    const errorCodes = errors.map(error => getErrorCode(error));

    if (areOnlyNotStrictRulesViolated(errorCodes)) {
      openModal(errors as never[]);
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    if (addValidationErrorListener) {
      // @ts-ignore
      addValidationErrorListener(handleError);
    }

    return () => {
      if (removeValidationErrorListener) {
        // @ts-ignore
        removeValidationErrorListener(handleError);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const continueButton = (
    <SubmitButton
      key="btn-continue"
      onClick={submitForm}
      isLoading={!isSubmitEnabled}
      data-testid="not-strict-rules-continue"
    />
  );

  const cancelButton = (
    <Button
      onClick={() => {
        closeModal();
      }}
      data-testid="not-strict-rules-continue"
      key="btn-cancel"
      color="secondary"
      disabled={!isSubmitEnabled}
    >
      Cancel
    </Button>
  );

  return (
    <ModalWindow
      isOpen={isModalOpened()}
      title="Business rules violated"
      buttons={[continueButton, cancelButton]}
      onToggle={() => closeModal()}
      key="modal-not-strict"
      hasCancelButton={false}
    >
      <span>{title}</span>

      <BusinessRulesListField value={violatedRules} errorMap={errorMap} />

      {children}
    </ModalWindow>
  );
};

export default NotStrictRulesHandler;
