/* istanbul ignore file */
import opportunityValidation from 'crm/opportunity/createupdate/opportunityValidation';
import useTimelineService from 'shared/crud/timelineService';
import useCrudService from 'shared/crud';
import { useKernelApi } from 'api';
import opportunityFilterValidation from 'crm/opportunity/list/filter/opportunityFilterValidation';

/**
 * Expected days for close: NEW - 1, PRESALE - 14, PROPOSAL - 10, CONTRACT - 7, SIGNING - 2, STARTING - 1.
 * @type {number}
 */
export const DAYS_TO_CLOSE = 35;
export const POSTPONED = 'POSTPONED';
export const NEW = 'NEW';
export const CLOSED_WON = 'CLOSED_WON';
export const CLOSED_LOST = 'CLOSED_LOST';
export const CONTRACT = 'CONTRACT';
export const SIGNING = 'SIGNING';

export const OPPORTUNITY_STAGES_ALIASES = {
  NEW,
  PRESALE: 'PRESALE',
  PROPOSAL: 'PROPOSAL',
  CONTRACT,
  SIGNING,
  STARTING: 'STARTING',
  CLOSED_LOST,
  CLOSED_WON,
  POSTPONED,
};

export const OPPORTUNITY_STAGES = {
  [NEW]: 'New',
  PRESALE: 'Presale',
  PROPOSAL: 'Proposal',
  CONTRACT: 'Contract',
  SIGNING: 'Signing',
  STARTING: 'Starting',
  [CLOSED_LOST]: 'Closed Lost',
  [CLOSED_WON]: 'Closed Won',
  [POSTPONED]: 'Postponed',
};

export const OPPORTUNITY_STAGE_STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  IN_PROGRESS_WITH_DELAY: 'IN_PROGRESS_WITH_DELAY',
  COMPLETED: 'COMPLETED',
  COMPLETED_WITH_DELAY: 'COMPLETED_WITH_DELAY',
  NOT_STARTED: 'NOT_STARTED',
  FAIL: 'FAIL',
};

export const OPPORTUNITY_STAGES_ALLOWED_TRANSITIONS = {
  [OPPORTUNITY_STAGES_ALIASES.NEW]: [OPPORTUNITY_STAGES_ALIASES.PRESALE, OPPORTUNITY_STAGES_ALIASES.CLOSED_LOST],
  [OPPORTUNITY_STAGES_ALIASES.PRESALE]: [
    OPPORTUNITY_STAGES_ALIASES.PROPOSAL,
    OPPORTUNITY_STAGES_ALIASES.POSTPONED,
    OPPORTUNITY_STAGES_ALIASES.CLOSED_LOST,
  ],
  [OPPORTUNITY_STAGES_ALIASES.PROPOSAL]: [
    OPPORTUNITY_STAGES_ALIASES.CONTRACT,
    OPPORTUNITY_STAGES_ALIASES.POSTPONED,
    OPPORTUNITY_STAGES_ALIASES.CLOSED_LOST,
  ],
  [OPPORTUNITY_STAGES_ALIASES.CONTRACT]: [
    OPPORTUNITY_STAGES_ALIASES.SIGNING,
    OPPORTUNITY_STAGES_ALIASES.POSTPONED,
    OPPORTUNITY_STAGES_ALIASES.CLOSED_LOST,
  ],
  [OPPORTUNITY_STAGES_ALIASES.SIGNING]: [
    OPPORTUNITY_STAGES_ALIASES.STARTING,
    OPPORTUNITY_STAGES_ALIASES.POSTPONED,
    OPPORTUNITY_STAGES_ALIASES.CLOSED_LOST,
  ],
  [OPPORTUNITY_STAGES_ALIASES.STARTING]: [
    OPPORTUNITY_STAGES_ALIASES.CLOSED_WON,
    OPPORTUNITY_STAGES_ALIASES.POSTPONED,
    OPPORTUNITY_STAGES_ALIASES.CLOSED_LOST,
  ],
};

export const isCurrentOpportunityStage = (stateStatus: string) =>
  [OPPORTUNITY_STAGE_STATUSES.IN_PROGRESS, OPPORTUNITY_STAGE_STATUSES.IN_PROGRESS_WITH_DELAY].includes(stateStatus);

export const isCompletedOpportunityStage = (stateStatus: string) =>
  [OPPORTUNITY_STAGE_STATUSES.COMPLETED, OPPORTUNITY_STAGE_STATUSES.COMPLETED_WITH_DELAY].includes(stateStatus);

export const isFailedOpportunityStage = (stateStatus: string) =>
  [OPPORTUNITY_STAGE_STATUSES.FAIL].includes(stateStatus);

export const PROBABILITY_MAP: any = {
  NEW: 10,
  PRESALE: 20,
  PROPOSAL: 30,
  CONTRACT: 80,
  SIGNING: 90,
  STARTING: 95,
  CLOSED_LOST: 0,
  CLOSED_WON: 100,
  [POSTPONED]: 20,
};

export const OPPORTUNITY_TYPE = {
  EXISTING_BUSINESS: 'Existing business',
  NEW_BUSINESS: 'New business',
};

const REASON = {
  UNRESPONSIVE_CONTACT: 'Contact became unresponsive',
  CANCELLED_OR_POSTPONED: 'Project cancelled or postponed',
  IN_HOUSE_IMPLEMENTATION: 'Decided to implement the project in-house',
  ANOTHER_VENDOR: 'Another vendor selected',
  ALREADY_WORKING_WITH: 'We are an incumbent supplier (Customer is already working with us)',
  PROPOSAL_QUALITY: 'Proposal quality',
  PRICE: 'Price of the service',
  DELIVERY_SPEED: 'Timeline (speed of delivery)',
  EXPERTISE: 'Expertise and/or qualifications',
  PROVIDED_REFERENCES: 'Provided References',
  GEOGRAPHY: 'Geography of our offices (provide details in the Summary)',
  SALESPERSON: 'Salesperson responsiveness and/or guidance',
  PROJECT_STARTED: 'Project has been started via process',
  OTHER: 'Other (provide details in the Summary)',
};
export const CLOSED_REASON_LIST = REASON;

export const CLOSED_REASON = {
  PRIMARY: {
    CLOSED_WON: {
      ALREADY_WORKING_WITH: REASON.ALREADY_WORKING_WITH,
      PROPOSAL_QUALITY: REASON.PROPOSAL_QUALITY,
      PRICE: REASON.PRICE,
      DELIVERY_SPEED: REASON.DELIVERY_SPEED,
      EXPERTISE: REASON.EXPERTISE,
      PROVIDED_REFERENCES: REASON.PROVIDED_REFERENCES,
      GEOGRAPHY: REASON.GEOGRAPHY,
      SALESPERSON: REASON.SALESPERSON,
      PROJECT_STARTED: REASON.PROJECT_STARTED,
      OTHER: REASON.OTHER,
    },
    CLOSED_LOST: {
      UNRESPONSIVE_CONTACT: REASON.UNRESPONSIVE_CONTACT,
      CANCELLED_OR_POSTPONED: REASON.CANCELLED_OR_POSTPONED,
      IN_HOUSE_IMPLEMENTATION: REASON.IN_HOUSE_IMPLEMENTATION,
      ANOTHER_VENDOR: REASON.ANOTHER_VENDOR,
      PROPOSAL_QUALITY: REASON.PROPOSAL_QUALITY,
      PRICE: REASON.PRICE,
      DELIVERY_SPEED: REASON.DELIVERY_SPEED,
      EXPERTISE: REASON.EXPERTISE,
      PROVIDED_REFERENCES: REASON.PROVIDED_REFERENCES,
      GEOGRAPHY: REASON.GEOGRAPHY,
      SALESPERSON: REASON.SALESPERSON,
      OTHER: REASON.OTHER,
    },
  },

  SECONDARY: {
    CLOSED_WON: {
      PROPOSAL_QUALITY: REASON.PROPOSAL_QUALITY,
      PRICE: REASON.PRICE,
      DELIVERY_SPEED: REASON.DELIVERY_SPEED,
      EXPERTISE: REASON.EXPERTISE,
      PROVIDED_REFERENCES: REASON.PROVIDED_REFERENCES,
      GEOGRAPHY: REASON.GEOGRAPHY,
      SALESPERSON: REASON.SALESPERSON,
      OTHER: REASON.OTHER,
    },
    get CLOSED_LOST() {
      return this.CLOSED_WON;
    },
  },
};

export const READ_FULL_OPPORTUNITIES_LIST = 'READ_FULL_OPPORTUNITIES_LIST';
export const READ_OPPORTUNITIES_LIST = 'READ_OPPORTUNITIES_LIST';
export const CREATE_OPPORTUNITY = 'CREATE_OPPORTUNITY';
export const READ_OPPORTUNITY = 'READ';
export const UPDATE_OPPORTUNITY = 'UPDATE';
export const UPDATE_OPPORTUNITY_FROM_LIST_PAGE = 'CREATE_OPPORTUNITY';
export const DELETE_OPPORTUNITY = 'DELETE';
export const CREATE_PRESALE_REQUEST = 'CREATE_PRESALE_REQUEST';
export const READ_PRESALE_REQUEST = 'READ_PRESALE_REQUEST';
export const UPDATE_PRESALE_REQUEST = 'UPDATE_PRESALE_REQUEST';
export const CREATE_PRESALE_OUTPUT = 'CREATE_PRESALE_OUTPUT';
export const UPDATE_PRESALE_OUTPUT = 'UPDATE_PRESALE_OUTPUT';
export const CREATE_CONTRACT_REQUEST = 'CREATE_CONTRACT_REQUEST';
export const UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST';

/**
 * Contact service.
 *
 * @returns {{getById: (function(*=): *), deleteById: ((function(*=): *)|Promise<any>), create: (function(*=): *), update: (function(*=, *=): *)}}
 */

const useOpportunityService = () => {
  const baseCrudRequests = useCrudService({
    singleResourceUrl: '/crm/opportunities/:id',
    listResourceUrl: '/crm/opportunities',
    apiService: useKernelApi,
  });

  const getValidationSchema = () => Promise.resolve(opportunityValidation);

  const {
    search: searchTimelineItems,
    create: createTimelineItem,
    getTimelinePageById,
  } = useTimelineService({
    parentEntityUrl: `/crm/opportunities/:id`,
    apiService: useKernelApi,
  });

  const getFilterValidationSchema = () => Promise.resolve(opportunityFilterValidation);

  return {
    getValidationSchema,
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
    ...baseCrudRequests,
    getFilterValidationSchema,
  };
};

export default useOpportunityService;
