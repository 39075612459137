/* istanbul ignore file */
import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import LinkField from 'shared/uibuilder/field/LinkField';
import { useServiceCatalogUrl } from 'erp/serviceCatalog/ServiceCatalogRouter';
import { FieldProps } from 'shared/uibuilder/field/index';

interface ServiceCatalogLinkProps extends FieldProps {
  nameSource: string;
}

const ServiceCatalogLink = ({ source, nameSource, ...props }: ServiceCatalogLinkProps) => {
  const { getValue } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getSingleEntityUrl } = useServiceCatalogUrl();
  const id = getValue();
  const name = getName();

  return <LinkField linkText={name || id} isInternal value={getSingleEntityUrl(id)} {...props} />;
};

export default ServiceCatalogLink;
