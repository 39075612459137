import React from 'react';
import { Card, Stack } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupIcon from '@mui/icons-material/Group';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';
import UniversalVacancyField from 'erp/recruitment/recruitingBoard/shared/field/UniversalVacancyField';
import { BadgeField, NumberField } from 'shared/uibuilder/field';
import Badge from 'shared/uibuilder/badge/Badge';
import PriorityBadge from 'erp/recruitment/recruitingBoard/shared/badge/PriorityBadge';
import DateTimerField from 'shared/uibuilder/field/DateTimerField';

const EntityHeader: React.FC = () => {
  return (
    <Card
      sx={{
        border: '1px solid #BF6A02',
        maxHeight: 'max-content',
        minHeight: '96px',
        borderRadius: 1,
        p: '8px',
        position: 'sticky',
        left: 0,
        zIndex: 100,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Stack direction="column">
        <Stack direction="row" alignItems="start" justifyContent="space-between" gap={1}>
          <Box sx={{ fontSize: 11, color: '#3f51b5' }}>
            <UniversalVacancyField isLegacySource="isLegacy" source="id" nameSource="name" />
          </Box>
          <BadgeField
            badges={[
              <Badge color="neutral" isVisible={(entity: any) => entity.isLegacy}>
                LEGACY
              </Badge>,
              <PriorityBadge prioritySource="priority" isVisible={(entity: any) => !entity.isLegacy} />,
            ]}
          />
        </Stack>
        <Typography variant="subtitle1" sx={{ fontSize: 9, color: '#757575' }}>
          (Without specific)
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" gap={2}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <AccessTimeIcon sx={{ color: '#757575', fontSize: 11 }} />
          <Typography sx={{ color: '#424242', fontSize: 9 }}>
            <DateTimerField source="forecastDate" />
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <GroupIcon sx={{ color: '#757575', fontSize: 11 }} />
          <Typography sx={{ color: '#424242', fontSize: 9 }}>
            <NumberField source="hired" /> / <NumberField source="needHire" />
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default EntityHeader;
