/* istanbul ignore file */
import React from 'react';
import useInvoiceService, { REJECT_INVOICE, INVOICE_STATUSES } from 'financialAnalytic/invoice/useInvoiceService';
import { useParams } from 'react-router-dom';
import DeleteButton from 'uibuilder/button/DeleteButton';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const deleteMessage = 'Do you really want to cancel this invoice payment?';

const CancelPaymentInvoiceButton = ({
  statusSource,
  isVisible,
}: {
  statusSource: string;
  isVisible?: (data: any) => boolean;
}) => {
  const { id } = useParams<Dictionary<string>>();
  const { cancelInvoicePayment, invalidateCache } = useInvoiceService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { setData = () => {} } = useShowContext();
  const status = getValue();

  if (status !== INVOICE_STATUSES.PAID) {
    return null;
  }

  return (
    <DeleteButton
      displayMessage={deleteMessage}
      deleteMethod={() => cancelInvoicePayment(id!)}
      afterDelete={{
        successMessage: 'The Invoice payment has been successfully canceled.',
        errorMessage: "Can't cancel invoice payment.",
        execute: (data: any) => {
          invalidateCache();
          setData(data);
        },
      }}
      permissionToCheck={REJECT_INVOICE}
      buttonText="Cancel Payment"
      color="error"
    />
  );
};

export default CancelPaymentInvoiceButton;
