/* istanbul ignore file */
import React, { useState } from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import NewQualificationMenu from 'erp/qualification/newCompetency/shared/NewQualificationMenu';
import { NewCompetencyType, NewCompetencyTypeLabels } from 'erp/qualification/newCompetency/newCompetencyService';
import NewCompetencyQualificationModel from 'erp/qualification/newCompetency/Show/NewCompetencyQualificationModel';

const NewQualificationModel = () => {
  const { data: competencies } = useShowContext();
  const [currentType, setCurrentType] = useState(NewCompetencyType.ORGANIZATIONAL);
  const currentData = competencies.data?.filter((comp: { type: any }) => String(comp.type) === String(currentType));

  return (
    <>
      <NewQualificationMenu setCurrentType={setCurrentType} currentType={currentType} />
      <NewCompetencyQualificationModel data={currentData} title={NewCompetencyTypeLabels[currentType]} />
    </>
  );
};

export default NewQualificationModel;
