import React from 'react';
import ProjectEntityHeader from 'erp/project/shared/ProjectEntityHeader';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import UpdateForm from 'shared/uibuilder/form/UpdateForm';
import UpdateSowBreadcrumbs from './UpdateSowBreadcrumbs';
import SowForm from '../SowForm';
import { useSowUrl } from '../../SowRouter';
import useSowService from '../../useSowService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ViewProjectPageMenu from 'erp/project/Show/ViewProjectPageMenu';

const EditSow = () => {
  const { data } = useShowContext();
  const sowId = data.getValueBySource('sow.id');
  const { update, getDataMethod, getValidationSchema } = useSowService();

  const { getListUrl } = useSowUrl();

  return (
    <UpdateForm
      submitFormFunc={update}
      getDataFunc={getDataMethod}
      entityId={sowId}
      getValidationSchemaFunc={getValidationSchema}
      afterSubmit={{
        redirect: getListUrl(),
        message: 'You are awesome! The SOW has been successfully updated.',
      }}
    >
      <UpdatePageLayout
        title="Edit: SOW"
        breadcrumbs={<UpdateSowBreadcrumbs />}
        entityHeader={<ProjectEntityHeader />}
        menu={<ViewProjectPageMenu />}
      >
        <SowForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default EditSow;
