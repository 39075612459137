import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useWasteService, { WASTE_STATUSES, APPROVE_WASTE } from '../../useWasteService';
import AccountInput from 'financialAnalytic/ledger/shared/input/AccountInput';
import BudgetCategoryDropdown from 'financialAnalytic/journalEntry/shared/input/BudgetCategoryDropdown';
import { useDateService } from 'shared/uibuilder/dateService';
import { isBudgetCategoryRequired } from '../../../../serviceCatalog/budgets/shared/service/budgetCatagorySuggestionService';

const ApproveWasteButton = ({ statusSource }: { statusSource: string }) => {
  const { id } = useParams<Dictionary<string>>();
  const { approveWaste, getAccounts } = useWasteService();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { setData = () => {} } = useShowContext();
  const [sourceAccountId, setSourceAccountId] = useState<any>();
  const [sourceBudgetCategory, setSourceBudgetCategory] = useState<any>();
  const [targetAccountId, setTargetAccountId] = useState<any>();
  const [targetBudgetCategory, setTargetBudgetCategory] = useState<any>();
  const [currentDate, setCurrentDate] = useState<any>();
  const status = getValue();
  const { getCurrentDateTime, formatDateForAPI } = useDateService();
  const now = formatDateForAPI(getCurrentDateTime());

  useEffect(() => {
    if (!currentDate) {
      setCurrentDate(now);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status !== WASTE_STATUSES.REGISTERED) {
    return null;
  }

  return (
    <ButtonWithConfirmation
      displayMessage="Do you really want to approve this waste?"
      submitMethod={() =>
        approveWaste(id!, { sourceAccountId, targetAccountId, sourceBudgetCategory, targetBudgetCategory })
      }
      afterSubmit={{
        successMessage: 'The Waste has been successfully approved.',
        errorMessage: "Can't approve waste.",
        execute: (data: any) => {
          setData(data);
        },
      }}
      permissionToCheck={APPROVE_WASTE}
      variant="outlined"
      modalChildren={
        <>
          <AccountInput
            onChangeCallback={(data: any) => setSourceAccountId(data.sourceAccountId)}
            value={sourceAccountId}
            source="sourceAccountId"
            label="Source Account"
            placeholder="Select Source Account"
            loadDataMethod={getAccounts}
            cacheKey="source-accounts"
            isRequired
          />
          <BudgetCategoryDropdown
            disabled={!isBudgetCategoryRequired(sourceAccountId)}
            onChangeCallback={(data: any) => setSourceBudgetCategory(data.sourceBudgetCategory)}
            value={sourceBudgetCategory}
            source="sourceBudgetCategory"
            label="Category"
            dateValue={currentDate}
            accountIdValue={sourceAccountId}
          />
          <AccountInput
            onChangeCallback={(data: any) => setTargetAccountId(data.targetAccountId)}
            value={targetAccountId}
            source="targetAccountId"
            label="Target Account"
            placeholder="Select Target Account"
            loadDataMethod={getAccounts}
            cacheKey="target-accounts"
            isRequired
          />
          <BudgetCategoryDropdown
            disabled={!isBudgetCategoryRequired(targetAccountId)}
            onChangeCallback={(data: any) => setTargetBudgetCategory(data.targetBudgetCategory)}
            value={targetBudgetCategory}
            source="targetBudgetCategory"
            label="Category"
            dateValue={currentDate}
            accountIdValue={targetAccountId}
          />
        </>
      }
      confirmBtnProps={{
        disabled:
          !sourceAccountId ||
          !targetAccountId ||
          (isBudgetCategoryRequired(targetAccountId) && !targetBudgetCategory) ||
          (isBudgetCategoryRequired(sourceAccountId) && !sourceBudgetCategory),
      }}
    >
      Approve
    </ButtonWithConfirmation>
  );
};

export default ApproveWasteButton;
