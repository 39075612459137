/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useMessageService, { SuccessMessage } from 'shared/message/messageService';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import FormSection from 'shared/layout/form/FormSection';
import CrmOwnerDropdownInput from 'crm/shared/input/CrmOwnerDropdownInput';
import LeadOwnerNotificationCheckbox from 'crm/lead/shared/input/LeadOwnerNotificationCheckbox';
import useLeadService from 'crm/lead/shared/leadService';

interface AssignLeadOwnerFormProps {
  closeModal: () => void;
  modalOpen: boolean;
  isGranted: boolean;
}

const AssignLeadOwnerForm = ({ closeModal, modalOpen, isGranted }: AssignLeadOwnerFormProps) => {
  const { data: lead, setData: updateLead } = useShowContext();
  const { update } = useLeadService() as any;
  const { addMessage } = useMessageService();
  const leadId = lead.getValueBySource('id');

  return (
    <ModalForm
      closeModal={closeModal}
      modalOpen={modalOpen}
      // @ts-ignore due to unknown issue with ts, code works as expected
      submitFormFunc={data => update(leadId, { ...lead.getData(), ...data })}
      afterSubmit={{
        execute: response => {
          if (updateLead) {
            updateLead(response);
          }
          addMessage(new SuccessMessage('Lead owner has been assigned!'));
        },
      }}
      initialData={{
        status: 'ASSIGNED',
      }}
    >
      <FormSection>
        <CrmOwnerDropdownInput
          source="ownerAlias"
          canUserChange={isGranted}
          dropdownPlaceholder="Choose Lead Owner"
          label="Lead Owner"
        />
        <LeadOwnerNotificationCheckbox source="ownerNotificationEnabled" label="Notify new Owner via Teams" />
      </FormSection>
    </ModalForm>
  );
};

export default AssignLeadOwnerForm;
