import React from 'react';
import useLeadService from 'crm/lead/shared/leadService';
import { SearchInput } from 'shared/uibuilder/form/input';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

const mapResponse = (lead: { id: any; firstName: any; lastName: any; status: any }) => {
  return {
    id: lead.id,
    text: `${lead.id}: ${lead.firstName} ${lead.lastName} (${lead.status})`,
  };
};

type LeadSearchInputProps = {
  leadNameSource?: string;
  leadNameValue?: Nullable<string>;
  label?: Nullable<string>;
  source?: string;
  onChangeCallback?: (values: FormFieldsData) => void;
  errorSource?: string;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  required?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  tooltip?: string;
  defaultFilter?: Object;
};

const LeadSearchInput = ({ leadNameSource = 'leadName', leadNameValue, ...props }: LeadSearchInputProps) => {
  const { search } = useLeadService();

  return (
    <SearchInput
      searchRequest={search}
      noResultMessage="No Leads are found"
      mapResults={mapResponse}
      nameSource={leadNameSource}
      initialLabel={leadNameValue}
      {...props}
    />
  );
};

export default LeadSearchInput;
