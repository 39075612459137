/* istanbul ignore file */
// All logic is located in helpers
import React from 'react';
import { ListProvider } from './ListContext';
import {
  useListCrud,
  useListParams,
  useListDataLoading,
  useListDataUpdating,
  useListUrlStoring,
} from 'shared/uibuilder/list/builder';
import { Filter } from './filter/FilterContext';
import { SimpleListProps } from './SimpleList';

export const LIST_PAGE_SIZE = 30;

export interface ListProps extends SimpleListProps {
  defaultSortOrder?: string;
  defaultSortField?: string;
  defaultFilter?: Filter;
  pageSize?: number;
  onRemove?: (params: StringOrNumber) => void;
  uncontrolledFilters?: string[];
}

const ListWithoutStoringParams = ({
  getDataMethod,
  children,
  uncontrolledFilters,
  pageSize = LIST_PAGE_SIZE,
  defaultSortOrder = '',
  defaultSortField = '',
  defaultFilter = {},
  onRemove = () => {},
}: ListProps) => {
  const { params: initialParams } = useListUrlStoring();

  const { params, sortBy, searchBy, filterBy, goToPage, resetParamsToDefault } = useListParams({
    initialParams: { filter: initialParams.filter, sort: initialParams.sort },
    defaultSortOrder,
    defaultSortField,
    defaultFilter,
  });

  const { loading, loadData, setLoading } = useListDataLoading({ getDataMethod, resetParamsToDefault });

  const { data, reloadData } = useListDataUpdating({ loadData, params, pageSize });
  const listCrud = useListCrud({ onRemove, inputData: data });

  const contextData = {
    ...params,
    filterBy,
    sortBy,
    goToPage,
    searchBy,
    loading,
    setLoading,
    reloadData,
    uncontrolledFilters,
    ...listCrud,
  };

  return <ListProvider value={contextData}>{children}</ListProvider>;
};

export default ListWithoutStoringParams;
