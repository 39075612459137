// @ts-nocheck

const promises = {};

const useStorageService = (key: string, setValueCallback: any, version: string) => {
  const isAlreadyHasData = () => {
    const json = localStorage.getItem(key);
    const data = json && JSON.parse(json);

    return data && data.version && data.version === version;
  };

  const setDataToStorage = (data: any) => {
    localStorage.setItem(
      key,
      JSON.stringify({
        version,
        data,
      }),
    );
  };

  /*
    If we have several identical components, which will call api request method to load data
    and will pass this method in this hook, we need to store this Promise in variable for
    prevention multiple api calling. In promises[key] will be stored a ref to Promise for
    each essence (for example: positions, offices...) and request will be executed only once.
   */
  const getDataFromStorage = async () => {
    if (promises[key]) {
      await promises[key];
    }

    if (!isAlreadyHasData()) {
      promises[key] = setValueCallback();

      try {
        const data = await promises[key];

        if (!isAlreadyHasData()) {
          setDataToStorage(data);
        }
      } finally {
        promises[key] = null;
      }
    }

    return JSON.parse(localStorage.getItem(key)).data;
  };

  const removeDataFromStorage = () => {
    localStorage.removeItem(key);
  };

  return {
    setDataToStorage,
    getDataFromStorage,
    removeDataFromStorage,
  };
};

export default useStorageService;
