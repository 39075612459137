/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import useServiceService from 'erp/service/shared/serviceService';

export const SERVICE_CONTEXT = 'Service';

const ServiceContext = ({
  permissionToCheck,
  children,
}: {
  permissionToCheck: string | string[];
  children: React.ReactNode;
}) => {
  const { getPermissions } = useServiceService();

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getPermissions}
      permissionToCheck={permissionToCheck}
      contextName={SERVICE_CONTEXT}
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default ServiceContext;
